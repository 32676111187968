import { LineStatusType } from '@generated-mg';

import { isNowInATimePeriod } from '@utils';

import { type UserCapitalProductSummary } from './types';

const verifyActiveDate = (activeDate: string, noApprovedApplicationDate: number) => {
  if (!activeDate) return false;
  return new Date(activeDate) >= new Date(noApprovedApplicationDate);
};

export const hasApprovedApplicationRecently = (
  { loanApplications, linesOfCredit, smallBusinessLoans }: UserCapitalProductSummary,
  noApprovedApplicationDays: number,
) => {
  const noApprovedApplicationDate = new Date().setDate(
    new Date().getDate() - noApprovedApplicationDays,
  );

  const hasApprovedApplication = loanApplications?.some(({ status = {} }) => {
    return (
      verifyActiveDate(status.decisionDate, noApprovedApplicationDate) &&
      status.decision === 'Approved'
    );
  });

  const hasApprovedLOC = linesOfCredit?.some(item =>
    verifyActiveDate(item.startDate, noApprovedApplicationDate),
  );
  const hasApprovedSBL = smallBusinessLoans?.some(item =>
    verifyActiveDate(item.startDate, noApprovedApplicationDate),
  );
  return hasApprovedApplication || hasApprovedLOC || hasApprovedSBL;
};

export const hasActiveCapitalProducts = ({
  linesOfCredit,
  smallBusinessLoans,
}: UserCapitalProductSummary) => {
  return (
    linesOfCredit.some(({ status }) => status === LineStatusType.LineActive) ||
    smallBusinessLoans.some(({ isRefinanceEligible }) => isRefinanceEligible)
  );
};

export const isBusinessLoanOfferActive = (countryCode, businessLoanOffer) => {
  const { startDate, endDate, targetCountry } = businessLoanOffer || {};
  return isNowInATimePeriod(startDate, endDate) && targetCountry === countryCode;
};
