import { DocumentFolded as PrintIcon } from '@prospa/icons';

import { SpinnerContainer } from '@components';

import { formatDateInFullWeekDayMonthYear } from '@utils';

export const TransactionTimeAndProofDetails = ({
  timestamp,
  getProofOfTransfer,
  getProofOfTransferLoading,
}: {
  timestamp: string;
  getProofOfTransfer?: () => Promise<{ url: string }>;
  getProofOfTransferLoading?: boolean;
}) => {
  return (
    <ul>
      <li className="d-flex justify-content-between mt-2 align-items-center">
        <div>{formatDateInFullWeekDayMonthYear(timestamp)}</div>
        {getProofOfTransfer && (
          <SpinnerContainer loading={getProofOfTransferLoading}>
            <div
              onClick={async e => {
                e.stopPropagation();
                const { url } = await getProofOfTransfer();
                window.open(url, '_blank');
              }}
            >
              <PrintIcon width={28} height={28} />
              Print
            </div>
          </SpinnerContainer>
        )}
      </li>
    </ul>
  );
};
