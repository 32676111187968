import { type FC } from 'react';

import { type TableBodyProps } from './types';

export const TableBody: FC<TableBodyProps> = ({ children, className, ...props }) => {
  return (
    <tbody className={className} {...props}>
      {children}
    </tbody>
  );
};
