import { type FormikProps } from 'formik';

import { type AlertProps } from '@all-in-one-web/common-ui';

import { type RepaymentsScheduleData } from './LineOfCreditRepaymentCalculator';

export type RepaymentCalculatorPageProps = {
  alertProps: AlertProps & { show: boolean };
};

export type BusinessLoanRepaymentCalculations = {
  weeklyRepayment: number;
  paybackAmount: number;
  loanTermYears: number;
  weeklyInstalments: number;
};

export type LineOfCreditRepaymentCalculations = {
  weeklyRepayment: number;
  repaymentsSchedule: RepaymentsScheduleData;
};

export type RepaymentCalculations =
  | BusinessLoanRepaymentCalculations
  | LineOfCreditRepaymentCalculations
  | null;

export type BusinessLoanRepaymentCalculatorFormValues = {
  amount: number;
  loanTermYears: number;
  fundingPurpose?: string;
  industry?: string;
};

export type LineOfCreditRepaymentCalculatorFormValues = {
  amount: number;
  loanTermYears: number;
  expectedDrawdownAmount: number;
  fundingPurpose?: string;
  industry?: string;
};

export type RepaymentCalculatorFormValues =
  | BusinessLoanRepaymentCalculatorFormValues
  | LineOfCreditRepaymentCalculatorFormValues;

export type RepaymentCalculatorFormRef = FormikProps<RepaymentCalculatorFormValues>;

export enum RepaymentCalculatorTab {
  BUSINESS_LOAN,
  LINE_OF_CREDIT,
}
