import type { FC } from 'react';

import { BrokenPot } from '@prospa/salt-illustrations';

import { Modal } from '@components';

import { GENERIC_ERROR_MESSAGES } from '@constants';

import styles from './InternalErrorModal.module.scss';
import type { InternalErrorModalProps } from './types';

// TODO: remove `toggle` and `okButtonCallback` deprecated props
export const InternalErrorModal: FC<InternalErrorModalProps> = ({
  isOpen,
  toggle = () => {
    //
  },
  onClose,
  okButtonCallback,
  primaryButtonProps,
  heading = GENERIC_ERROR_MESSAGES.GENERIC_ERROR_HEADER,
  ...props
}) => {
  const handleClose = onClose || toggle;
  // old behavior
  const defaultPrimaryButtonClick = () => {
    if (okButtonCallback) {
      okButtonCallback();
    }
    handleClose();
  };
  const {
    onClick: primaryButtonOnClick,
    label = 'Okay, thanks',
    ...primaryButtonRestProps
  } = primaryButtonProps || {};
  const handlePrimaryButtonClick = primaryButtonOnClick || defaultPrimaryButtonClick;
  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onClose={handleClose}
      showCloseModal
      primaryButtonProps={{
        label,
        onClick: handlePrimaryButtonClick,
        ...primaryButtonRestProps,
      }}
      body={
        <div className={styles.ErrorModal} data-testid="internal-error-modal">
          <div className={styles.ErrorModalContents}>
            <BrokenPot />
            <h4 className={styles.ErrorModalContentsHeading}>{heading}</h4>
            <p className={styles.ErrorModalContentsBody}>
              {GENERIC_ERROR_MESSAGES.GENERIC_ERROR_BODY}
            </p>
          </div>
        </div>
      }
      bodyClassName={styles.ErrorModalBody}
      {...props}
    />
  );
};
