import React from 'react';

import { ChevronLeft } from '@prospa/salt-icons';
import { vars } from '@prospa/salt-react-beta';

import { Button } from '@components';
import { TemporaryLogoutButton } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import styles from './DesktopHeader.module.scss';
import { useDesktopHeaderButtonConfig } from './hooks';
import { type DesktopHeaderButtonProps, type DesktopHeaderProps } from './types';

const DesktopHeaderButton = ({ label, onClick }: DesktopHeaderButtonProps) => {
  return (
    <div className={styles.DesktopHeaderButtonWrapper}>
      <ChevronLeft size={24} color={vars.color.neutral[500]} />
      <Button
        label={label}
        onClick={onClick}
        className={styles.DesktopHeaderButton}
        type={ButtonType.ICON_LINK}
        size={ButtonSize.MEDIUM}
        testId={`${label}-desktop-header-button`}
      />
    </div>
  );
};

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ logoutHandler }) => {
  const { buttonConfig } = useDesktopHeaderButtonConfig();

  return (
    <div className={styles.DesktopHeader}>
      <div className={styles.DesktopHeaderLeftSection}>
        {!!buttonConfig && <DesktopHeaderButton {...buttonConfig} />}
      </div>

      <TemporaryLogoutButton showLabel onClick={logoutHandler} />
    </div>
  );
};
