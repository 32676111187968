import { type ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { type BillPaymentMethodInput, BillStatus, Country, PaymentType } from '@generated-fg';

import type { AddNewPayeeModalProps, PaymentMethodSectionProps } from '@components/types';

import { BillConfirmFormErrorMessage, BillPaymentPayeeMapping } from '@constants';
import { type NewPayeeValues, type PayeeV2 } from '@models';
import {
  convertConfirmedBillPaymentMethodInputToNewPayeeValues,
  convertPayeeV2ToBillPaymentMethodInput,
  convertPreScannedBillPaymentMethodToNewPayeeValues,
  isValidBankTransferPayment,
  isValidBpayPayment,
  trackBAActionEvent,
} from '@utils';

import BpayBlackBg from '../../../../assets/BpayBlackBg.svg';
import { AppContext } from '../../../contexts';

type UsePaymentMethodSectionReturnType = {
  payeeModalLabel: string;
  openNewPayeeModal: () => void;
  payeeModalProps: AddNewPayeeModalProps;
  paymentMethodError?: string;
};

export const usePaymentMethodSection = ({
  setPaymentMethod,
  countryCode,
  billStatus,
  billerName,
  paymentMethod = {} as BillPaymentMethodInput,
}: Omit<PaymentMethodSectionProps, 'isNonEditable'>): UsePaymentMethodSectionReturnType => {
  // NOTE: as long as we have at least one payment type, we consider the payee as found
  // for the pre-scan result, it may have two payment types, if bpay is available, we will prioritize it over bank transfer
  // for the user-confirmed result, only confirmed payment type will be return, then the length will be 1 only
  const isBillerPayeeFound = paymentMethod?.paymentTypes?.length > 0;

  const payeeModalLabel = `${isBillerPayeeFound ? 'Edit' : 'Add'} payee`;
  const [openPayeeModal, setOpenPayeeModal] = useState(false);
  const { customerProducts } = useContext(AppContext);
  const handleAddPayeeCallback = useCallback(
    (payee: PayeeV2) => {
      const paymentMethod = convertPayeeV2ToBillPaymentMethodInput(payee);
      trackBAActionEvent(
        isBillerPayeeFound ? 'bill_edit_payee' : 'bill_add_new_payee',
        `payee-type:${BillPaymentPayeeMapping[paymentMethod?.paymentTypes?.[0]]}`,
      );
      setPaymentMethod(paymentMethod);
      setOpenPayeeModal(false);
    },
    [setPaymentMethod, isBillerPayeeFound],
  );

  const newPayee = useMemo<NewPayeeValues>(() => {
    const convertFunction =
      billStatus === BillStatus.Received
        ? convertPreScannedBillPaymentMethodToNewPayeeValues
        : convertConfirmedBillPaymentMethodInputToNewPayeeValues;
    return convertFunction(paymentMethod, billerName, countryCode);
  }, [billStatus, billerName, countryCode, paymentMethod]);

  const modalFooter = useMemo<ReactNode | undefined>(() => {
    const { BA, LOCM } = customerProducts;
    if (!BA && LOCM) {
      return (
        <div>
          <img src={BpayBlackBg} alt="bpay-black icon" role="presentation" />
          <span id="bills-bpay-inactivated-message">
            BPAY currently isn’t available for Line of Credit
          </span>
        </div>
      );
    }
    return undefined;
  }, [customerProducts]);

  const payeeType = useMemo<PaymentType | undefined>(() => {
    const { BA, LOCM } = customerProducts;
    if ((!BA && LOCM) || countryCode === Country.Nz) {
      return PaymentType.BankTransfer;
    }
    return undefined;
  }, [customerProducts, countryCode]);

  const payeeModalOnClose = useCallback(() => setOpenPayeeModal(false), []);
  const openNewPayeeModal = useCallback(() => {
    trackBAActionEvent(
      isBillerPayeeFound ? 'bill_confirm_edit_payee__click' : 'bill_confirm_add_new_payee__click',
    );
    setOpenPayeeModal(true);
  }, [isBillerPayeeFound]);

  const paymentMethodError = useMemo<string>(() => {
    if (
      paymentMethod?.paymentTypes?.length &&
      !isValidBankTransferPayment(paymentMethod) &&
      !isValidBpayPayment(paymentMethod)
    ) {
      return BillConfirmFormErrorMessage.ShouldAddBillerPayee;
    }
  }, [paymentMethod]);

  return {
    payeeModalLabel,
    openNewPayeeModal,
    paymentMethodError,
    payeeModalProps: {
      newPayee,
      payeeType,
      isOpen: openPayeeModal,
      onClose: payeeModalOnClose,
      countryCode,
      footer: modalFooter,
      primaryOnClick: handleAddPayeeCallback,
      hideSaveToAddressBook: true,
      resetFormBeforeClose: false,
    },
  };
};
