import { useMemo } from 'react';

import { DrawdownStatus, useGetDrawdownDetailsQuery } from '@generated-fg';
import { LineStatusType, useLoanSummaryQuery } from '@generated-mg';

import { businessLoanOffer } from '@constants';
import { useAppContextData } from '@hooks';

import { type LOCCapitalSummary, type TBusinessLoanOfferBanner } from './types';
import {
  hasActiveCapitalProducts,
  hasApprovedApplicationRecently,
  isBusinessLoanOfferActive,
} from './utils';

export const useBusinessLoanOffer = (): TBusinessLoanOfferBanner => {
  const {
    signInInfo: { countryCode },
  } = useAppContextData();

  const isOfferActive = useMemo(() => {
    return isBusinessLoanOfferActive(countryCode, businessLoanOffer);
  }, [countryCode]);

  // TODO: migrate the use loan summary query to frontend gateway [AW-1191]
  const {
    loading: summaryLoading,
    data: { accounts = [], loans = [], creditLines = [], loanApplications = [] } = {},
  } = useLoanSummaryQuery({ variables: { showAll: true }, skip: !isOfferActive });

  const {
    data: { user: { linesOfCredit: drawdownDetail = [] } = {} } = {},
    loading: drawdownLoading,
  } = useGetDrawdownDetailsQuery({
    skip: !isOfferActive,
  });

  const isEntitledBusinessLoanOffer = useMemo(() => {
    if (!isOfferActive) return false;
    const { noApprovedApplicationDays } = businessLoanOffer;
    if (!summaryLoading && !drawdownLoading) {
      const userCapitalProductSummary = {
        smallBusinessLoans: accounts
          ?.map(({ loanAccountId, startDate, status }) => ({
            id: loanAccountId,
            startDate,
            displayStatus: status,
            isRefinanceEligible: false,
          }))
          ?.concat(
            loans?.map(({ id, loanStartDate, displayStatus, refinanceCalculationState }) => ({
              id,
              startDate: loanStartDate,
              displayStatus,
              isRefinanceEligible: refinanceCalculationState === 'Eligible',
            })),
          ),
        linesOfCredit: creditLines?.map<LOCCapitalSummary>(({ id, startDate, status }) => {
          const {
            drawdown: { status: drawdownStatus },
          } = drawdownDetail.find(({ id: locId }) => locId === id);
          return {
            id,
            startDate: new Date(startDate),
            status: drawdownStatus !== DrawdownStatus.Active ? LineStatusType.NotActive : status,
          };
        }),
        loanApplications,
      };
      return (
        hasActiveCapitalProducts(userCapitalProductSummary) ||
        !hasApprovedApplicationRecently(userCapitalProductSummary, noApprovedApplicationDays)
      );
    }
  }, [
    isOfferActive,
    summaryLoading,
    drawdownLoading,
    accounts,
    loans,
    creditLines,
    loanApplications,
    drawdownDetail,
  ]);
  return { isEntitledBusinessLoanOffer };
};
