import { useCallback, useState } from 'react';

import { type MutationUpdaterFn } from '@apollo/client';

import { Cross } from '@prospa/salt-icons';

import {
  type BillDetails,
  GetBillDetailsDocument,
  type GetBillDetailsQuery,
  type GetBillDetailsQueryVariables,
  GetBillsDocument,
  type GetBillsQuery,
  type GetBillsQueryVariables,
  type LinkedTransaction,
  PaymentGateway,
  useCancelScheduledBillPaymentMutation,
} from '@generated-fg';

import { SecurityCodeModal } from '@components';

import {
  trackBAActionEvent,
  updateBillDetailCacheAfterCancelScheduledTransaction,
  updateBillListCacheAfterCancelScheduledTransaction,
} from '@utils';

import { useToastContext } from '../../../hooks';
import Api from '../../../services/Api';

export type TUseCancelScheduledBillTransactionProps = {
  updater: (billDetail?: TBillToCancelScheduledTransaction) => MutationUpdaterFn;
};

export type TUseCancelScheduledBillTransactionReturn = {
  isCancelScheduledBillLoading: boolean;
  modal: JSX.Element;
  cancelBillScheduledPayment: (bill: TBillToCancelScheduledTransaction) => void;
};

export type TBillToCancelScheduledTransaction =
  | (Pick<BillDetails, 'id'> &
      Pick<LinkedTransaction, 'referenceId' | 'paymentGateway' | 'accountId'> & {
        accountId: string;
      })
  | undefined;

const errorMessage = 'An error occurred while cancelled the transaction';

export const cancelScheduledBillTransactionOnDetailPage: TUseCancelScheduledBillTransactionProps['updater'] =

    ({ id, referenceId: cancelledReferenceId }) =>
    cache => {
      cache.updateQuery<GetBillDetailsQuery, GetBillDetailsQueryVariables>(
        {
          query: GetBillDetailsDocument,
          variables: { id },
          overwrite: true,
        },
        data => updateBillDetailCacheAfterCancelScheduledTransaction(data, cancelledReferenceId),
      );
    };

export const cancelScheduledBillTransactionInBillsList: TUseCancelScheduledBillTransactionProps['updater'] =

    ({ id: billId, referenceId: cancelledReferenceId }) =>
    cache => {
      cache.updateQuery<GetBillsQuery, GetBillsQueryVariables>(
        {
          query: GetBillsDocument,
          variables: { status: [] },
          overwrite: true,
        },
        data =>
          updateBillListCacheAfterCancelScheduledTransaction(data, billId, cancelledReferenceId),
      );
    };

export const useCancelScheduledBillTransaction = ({
  updater,
}: TUseCancelScheduledBillTransactionProps) => {
  const [requestSecurityCode, setRequestSecurityCode] = useState<boolean>(false);
  const [transactionDetail, setTransactionDetail] = useState<TBillToCancelScheduledTransaction>();
  const [cancelScheduledBillPayment, { loading: isCancelScheduledBillLoading }] =
    useCancelScheduledBillPaymentMutation();
  const { addToast } = useToastContext();

  const handleCloseModal = useCallback(() => {
    setRequestSecurityCode(false);
    setTransactionDetail(undefined);
  }, []);

  const handleCancelTransaction = useCallback(
    async (transaction: TBillToCancelScheduledTransaction) => {
      try {
        const response = await cancelScheduledBillPayment({
          variables: {
            input: {
              referenceId: transaction?.referenceId,
              verificationCode: Api.verificationCode,
              accountId: transaction?.accountId,
            },
          },
          update: updater(transaction),
        });
        const {
          data: {
            deleteBillPaymentReference: { success },
          },
        } = response;
        if (success) {
          handleCloseModal();
          return;
        }
        throw response;
      } catch (error) {
        trackBAActionEvent('bill_error', error.message);
        addToast(errorMessage, Cross);
      }
    },
    [addToast, cancelScheduledBillPayment, handleCloseModal, updater],
  );

  const cancelBillScheduledPayment = useCallback(
    ({ referenceId, accountId, id, paymentGateway }: TBillToCancelScheduledTransaction) => {
      if (!referenceId || !accountId || paymentGateway === PaymentGateway.Unknown) {
        trackBAActionEvent('bill_error', errorMessage);
        addToast(errorMessage, Cross);
        return;
      }
      setTransactionDetail({ referenceId, id, accountId, paymentGateway });
      switch (paymentGateway) {
        case PaymentGateway.Shaype:
          return setRequestSecurityCode(true);
        case PaymentGateway.TransactionsApi:
          return handleCancelTransaction({ referenceId, id, accountId, paymentGateway });
        default:
          return handleCloseModal();
      }
    },
    [addToast, handleCancelTransaction, handleCloseModal],
  );

  const on2FASuccess = useCallback(async () => {
    await handleCancelTransaction(transactionDetail);
  }, [handleCancelTransaction, transactionDetail]);

  return {
    isCancelScheduledBillLoading,
    modal: (
      <SecurityCodeModal
        isCheck2FAStarted={requestSecurityCode}
        closeModal={handleCloseModal}
        onSuccess={on2FASuccess}
      />
    ),
    cancelBillScheduledPayment,
  };
};
