import { useEffect, useState } from 'react';

import { FullScreenAnimation } from '@all-in-one-web/common-ui';

import { delay } from '@utils';

import BrokenLinkAnimation from '../../../../assets/animations/brokenLinkAnimation.json';
import { LottieAnimation } from '../../LottieAnimation';
import { type PromiseAnimationProps } from '../types';

export const XeroDisconnectAnimation = ({
  asyncRequest: disconnectXeroRequest,
  onResolve,
  onError,
}: PromiseAnimationProps) => {
  const [animationTitle, setAnimationTitle] = useState('Disconnecting...');

  useEffect(() => {
    delay(1000).then(() => {
      disconnectXeroRequest()
        .then(async () => {
          setAnimationTitle('Disconnected');
          delay(2000).then(() => {
            onResolve?.();
          });
        })
        .catch(async () => {
          delay(1000).then(() => {
            onError?.();
          });
        });
    });
  }, [disconnectXeroRequest, onError, onResolve]);

  return (
    <FullScreenAnimation
      animation={<LottieAnimation animationData={BrokenLinkAnimation} loop />}
      animationTitle={animationTitle}
    />
  );
};
