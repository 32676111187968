import { type ChangeEvent, type FC } from 'react';

import { type FieldProps } from 'formik';

import { Input } from '../Formik';
import './PinInput.scss';

interface Props {
  label: string;
  setFieldValue: (value: string) => void;
}

const MAXLENGTH = 4;

export const PinInput: FC<FieldProps & Props> = ({ label, field, setFieldValue, ...props }) => {
  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.slice(0, MAXLENGTH);
    setFieldValue(newValue);
  };

  return (
    <Input
      className="pin-input"
      {...props}
      type="number"
      pattern="[0-9]*"
      inputMode="numeric"
      field={{
        ...field,
        onChange: _onChange,
      }}
      maxLength={4}
      label={label}
    />
  );
};
