import { useState } from 'react';

import { MicroilloCardRepayment as ActivateCardIcon } from '@prospa/icons';

import {
  Banner,
  CardActivateModal,
  CardActivateSuccessModal,
  ChangePINModal,
  ErrorModal,
  SecurityCodeModal,
} from '@components';

import { trackBAActionEvent } from '@utils';

import { CardActivationStep, type CardAwaitBannerProps } from './types';

const messages = {
  headline: 'Activate your card',
  subheadline: 'Your card will arrive by mail. Once it arrives, enter the last 4 digits.',
};

export const CardAwaitingActivationBanner = ({
  cardMeta,
  accountId,
  refetchCardMeta,
}: CardAwaitBannerProps) => {
  const [cardActivationStep, setCardActivationStep] = useState<CardActivationStep>(null);
  const [showSecurityModal, setShowSecurityModal] = useState<boolean>(false);
  const { id: cardId } = cardMeta;

  const resetAndClose = () => {
    setCardActivationStep(null);
    setShowSecurityModal(false);
  };

  const goNextStep = () => {
    switch (cardActivationStep) {
      case CardActivationStep.CardActivate:
        setCardActivationStep(CardActivationStep.Pin);
        return;
      case CardActivationStep.Pin:
        setCardActivationStep(CardActivationStep.Success);
        return;
      default:
        setCardActivationStep(CardActivationStep.CardActivate);
        return;
    }
  };

  const handleBannerClick = () => {
    setShowSecurityModal(true);
    trackBAActionEvent('card_activate');
  };

  const handle2faSuccess = () => {
    setShowSecurityModal(false);
    goNextStep();
  };

  const handleActivateSuccess = () => {
    setShowSecurityModal(true);
  };

  const handlePinSuccess = () => {
    goNextStep();
  };

  const handleError = () => {
    setCardActivationStep(CardActivationStep.Error);
  };

  return (
    <>
      <Banner
        icon={<ActivateCardIcon />}
        title={messages.headline}
        subtitle={messages.subheadline}
        onClick={handleBannerClick}
        buttonText="Activate card"
      />

      <SecurityCodeModal
        isCheck2FAStarted={showSecurityModal}
        closeModal={resetAndClose}
        onSuccess={handle2faSuccess}
      />
      {!showSecurityModal && (
        <>
          <CardActivateModal
            isOpen={cardActivationStep === CardActivationStep.CardActivate}
            cardId={cardId}
            accountId={accountId}
            closeModal={resetAndClose}
            onSuccess={handleActivateSuccess}
            onError={handleError}
          />

          <ChangePINModal
            cardId={cardMeta?.id}
            isOpen={cardActivationStep === CardActivationStep.Pin}
            toggle={resetAndClose}
            onError={handleError}
            onSuccess={handlePinSuccess}
            newCard
          />
          <CardActivateSuccessModal
            isOpen={cardActivationStep === CardActivationStep.Success}
            toggle={() => {
              resetAndClose();
              refetchCardMeta();
            }}
          />
        </>
      )}
      <ErrorModal isOpen={cardActivationStep === CardActivationStep.Error} toggle={resetAndClose} />
    </>
  );
};
