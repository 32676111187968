import { type ReactNode } from 'react';

import { type MutationUpdaterFn } from '@apollo/client';

import { type DropdownProps } from '@all-in-one-web/common-ui';

import { type BillStatus, type LinkedTransaction } from '@generated-fg';

type TDropdownMenuActionUpdater = Record<DropdownActions, (...args) => MutationUpdaterFn>;
type TDropdownMenuActionOnSuccess = Record<DropdownActions.DELETE_BILL, () => void>;

export enum DropdownActions {
  DELETE_BILL = 'delete-bill',
  CANCEL_SCHEDULED_PAYMENT = 'cancel-scheduled-payment',
}

export type BillDropdownMenuProps = {
  billId: string;
  status?: BillStatus;
  billerName?: string;
  recentTransaction?: LinkedTransaction;
  dropdownActionUpdaters?: TDropdownMenuActionUpdater;
  dropdownActionOnSuccess?: TDropdownMenuActionOnSuccess;
};

export type TUseBillDropdownReturnValue = {
  dropdownItems: DropdownProps['items'];
  toggle: DropdownProps['toggle'];
  popupNotification: ReactNode;
};
