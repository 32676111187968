import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ButtonSize, ButtonType } from '@all-in-one-web/common-ui';
import { Cash } from '@prospa/salt-icons';

import { Button, CopyTextWithLabel } from '@components';

import { breakpoints } from '@constants';

import styles from './AddFundsFooter.module.scss';
import { type AddFundsFooterProps } from './types';

export const AddFundsFooter = ({ buttonProps, copyTexts, message }: AddFundsFooterProps) => {
  const isMobile = useMediaQuery({ query: breakpoints.mobile });
  const preventPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  const showButton = !!buttonProps;
  const showCopyTexts = !!copyTexts && copyTexts?.length > 0;
  return (
    <div className={showButton ? styles.ContainerWithBtn : styles.Container}>
      <div className={styles.LeftSection}>
        <Cash className={styles.Icon} color="black" />

        <p>{message}</p>
      </div>
      <div className={styles.RightSection} onClick={preventPropagation}>
        {showButton && (
          <Button
            className={styles.Button}
            label={buttonProps.label}
            type={ButtonType.PRIMARY}
            onClick={buttonProps.onClick}
            size={isMobile ? ButtonSize.EXTRA_SMALL : ButtonSize.MEDIUM}
          />
        )}

        {showCopyTexts &&
          copyTexts.map(copyTextProps => (
            <CopyTextWithLabel
              className={styles.CopyText}
              {...copyTextProps}
              key={`${copyTextProps.value}-${copyTextProps.label}`}
            />
          ))}
      </div>
    </div>
  );
};
