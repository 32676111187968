import React from 'react';

import { differenceInDays, format, isToday, isTomorrow } from 'date-fns';

import { ButtonSize, ButtonType } from '@all-in-one-web/common-ui';
import { DocumentCheck } from '@prospa/salt-illustrations';

import { ContractStatus } from '@generated-fg';

import { Banner } from '@components';
import type { ContractDetail } from '@components/types';

type ContractBannerProps = {
  contract: ContractDetail;
  onClick?: () => void;
};

export const ContractBanner = (props: ContractBannerProps): React.ReactElement => {
  switch (props.contract?.status) {
    case ContractStatus.NotSigned:
      return (
        <Banner
          icon={<DocumentCheck />}
          title={`Your ${props.contract.productName} agreement is ready`}
          subtitle={<ContractSubtitle contract={props.contract} />}
          buttonProps={{
            label: 'Review agreement',
            type: ButtonType.PRIMARY,
            size: ButtonSize.MEDIUM,
            onClick: props.onClick,
          }}
        />
      );

    case ContractStatus.WaitingForOthersToSign:
      return (
        <Banner
          icon={<DocumentCheck />}
          title="Thanks for signing your agreement"
          subtitle="We’re waiting on another director/shareholder to sign before releasing the funds"
        />
      );

    default:
      return null;
  }
};

type ContractSubtitleProps = {
  contract: ContractDetail;
};

const ContractSubtitle = (props: ContractSubtitleProps): React.ReactElement => {
  const DEFAULT_CONTRACT_SUBTITLE = 'To access funds, review and sign within the week';

  if (!props.contract?.expiryDateTime) {
    return <span>{DEFAULT_CONTRACT_SUBTITLE}</span>;
  }

  const parsedDate = Date.parse(props.contract.expiryDateTime);

  if (isToday(parsedDate)) {
    return (
      <>
        To access funds, review and sign by <b>today</b>
      </>
    );
  }

  if (isTomorrow(parsedDate)) {
    return (
      <>
        To access funds, review and sign by <b>tomorrow</b>
      </>
    );
  }

  const daysToGo = differenceInDays(parsedDate, new Date());

  if (daysToGo < 8) {
    return (
      <>
        To access funds, review and sign within <b>{daysToGo} days</b>
      </>
    );
  }

  return (
    <>
      To access funds, review and sign by <b>{format(parsedDate, 'dd MMM yyyy')}</b>
    </>
  );
};
