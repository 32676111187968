import { AttachFile, OpenInNew } from '@prospa/salt-icons';

import styles from './ExternalLink.module.scss';
import { type ExternalLinkProps } from './types';

export const ExternalLink = ({ title, onClick }: ExternalLinkProps) => {
  return (
    <div className={styles['ExternalLinkContainer']}>
      <AttachFile size={20} />
      <div className={styles['ExternalLinkRight']}>
        {title}
        {onClick ? (
          <a
            href="#0"
            onClick={e => {
              e.preventDefault();
              onClick();
            }}
          >
            <OpenInNew size={20} className={styles['OpenIcon']} />
          </a>
        ) : null}
      </div>
    </div>
  );
};
