import classNames from 'classnames';

import { vars } from '@prospa/salt-react-beta';

import styles from './Divider.module.scss';
import { type DividerProps } from './types';

export const Divider = ({
  borderWidth = 1,
  color = vars.color.neutral['200'],
  className,
}: DividerProps) => {
  return (
    <hr
      className={classNames(styles.Divider, className)}
      style={{
        height: borderWidth,
        borderWidth: borderWidth,
        borderStyle: 'solid',
        color,
      }}
    />
  );
};
