import { type FormikErrors } from 'formik';

import { Country } from '@generated-fg';

import { type ConfirmBillFormInput, type ConfirmBillFormValidationRule } from '@components/types';

import {
  type InputFieldValidationRule,
  dateValidator,
  numberFieldValidator,
  textFieldValidator,
} from '../input-field-validator';
import { isValidBankTransferPayment, isValidBpayPayment } from './billConfirmForm';

type ConfirmBillFormError = FormikErrors<ConfirmBillFormInput & { paymentMethodError: string }>;

const fieldValidator: Record<
  keyof Omit<ConfirmBillFormInput, 'paymentMethod' | 'billerABN'>,
  (value: string | number | Date, validationRule: InputFieldValidationRule) => string
> = {
  billerName: (value: string, validationRule: InputFieldValidationRule) => {
    return textFieldValidator(value, 'Biller name', validationRule);
  },
  description: (value: string, validationRule: InputFieldValidationRule) => {
    return textFieldValidator(value, 'Invoice number or description', validationRule);
  },
  amount: (value: number, validationRule: InputFieldValidationRule) => {
    return numberFieldValidator(value, 'Amount', validationRule);
  },
  dueDate: (value: Date, validationRule: InputFieldValidationRule) => {
    return dateValidator(value, 'Due date', validationRule);
  },
  reference: (value: string, validationRule: InputFieldValidationRule) => {
    return textFieldValidator(value, 'Reference', validationRule);
  },
};

export const billConfirmFormValidator =
  (validationRule: ConfirmBillFormValidationRule, countryCode = Country.Au) =>
  (values: ConfirmBillFormInput): ConfirmBillFormError => {
    const errors = {} as ConfirmBillFormError;
    Object.entries(values).forEach(([key, value]) => {
      switch (key) {
        case 'billerABN':
          break;
        case 'paymentMethod': {
          // NOTE: ignore validate paymentMethod field with pre-filled value
          if (
            !isValidBpayPayment(values.paymentMethod) &&
            !isValidBankTransferPayment(values.paymentMethod, countryCode)
          ) {
            errors.paymentMethod = {};
          }
          break;
        }
        default: {
          const error = fieldValidator[key]?.(value, validationRule[key]) || '';
          if (error.length) {
            errors[key] = error;
          }
          break;
        }
      }
    });
    return errors;
  };
