import type { ReactNode } from 'react';

import { type ComponentDefaultProps } from '@all-in-one-web/common-ui';

import {
  BusinessAccountBalance,
  CardStatus,
  Contract,
  LineOfCredit,
  LineOfCreditStatus,
  OverdraftDetail,
  SmallBusinessLoan,
} from '@generated-fg';

import { type AddFundsFooterProps } from '@components/types';

import { type PRODUCT_TYPES } from '@constants';

import type { IntegrationSyncError } from '../../hooks/useIntegrationsError/types';
import type { ProductTypes } from '../../models/Account';

export enum AccountStatusModals {
  LOAN_SECOND_SIGNATURE = 'loan-second-signature-modal',
  LOAN_AWAITING = 'loan-awaiting-modal',
}

export type AccountStatusModalSectionProps = {
  activatedModal: AccountStatusModals | null;
  closeModal: () => void;
};

export type AccountItem<T> = {
  accountDetail: T;
  integrationSyncError?: IntegrationSyncError;
  addFundsFooter?: AddFundsFooterProps;
  onClick?: () => void;
};

export type SblAccountDetail = Pick<
  SmallBusinessLoan,
  | 'id'
  | 'contractStatus'
  | 'isRefinanceEligible'
  | 'displayStatus'
  | 'isInArrears'
  | 'inArrearsAmount'
  | 'collectionsPaymentUrl'
> & {
  loanAmount: SmallBusinessLoan['loanAmount']['amount'];
  balance: SmallBusinessLoan['outstandingAmount']['amount'];
  available: 0;
  productType: ProductTypes;
  productName: (typeof PRODUCT_TYPES)['SBL'];
  accountStatus?: SmallBusinessLoan['displayStatus'];
};

export type BankAccountDetail = {
  id: string;
  businessAccountDetailId: string;
  bsb: string;
  accountNumber: string;
  productType: ProductTypes;
  businessAccountStatus: string;
  balance: {
    available: BusinessAccountBalance['available']['amount'];
    total: BusinessAccountBalance['total']['amount'];
  };
  overdraftLimit: OverdraftDetail['overdraftLimit'];
  cardStatus: CardStatus;
};

export type CreditLineDetail = Partial<
  Omit<LineOfCredit, 'status' | 'totalAmount' | 'availableAmount' | 'outstandingAmount'>
> & {
  total: LineOfCredit['totalAmount']['amount'];
  available: LineOfCredit['availableAmount']['amount'];
  outstanding?: LineOfCredit['outstandingAmount']['amount'];
  pending?: LineOfCredit['pendingAmount']['amount'];
  productType: ProductTypes;
  status?: LineOfCreditStatus;
  accountStatus?: string;
};

export type ContractDetail = Pick<Contract, 'id' | 'productName' | 'expiryDateTime' | 'status'>;

type LoadingProductList<T> = {
  list: AccountItem<T>[];
  loading: boolean;
};

export type AccountListProps = {
  businessAccount: AccountItem<BankAccountDetail> & {
    loading: boolean;
  };
  creditLines: LoadingProductList<CreditLineDetail>;
  loans: LoadingProductList<SblAccountDetail>;
  contracts: LoadingProductList<ContractDetail>;
  accountListUIProps: {
    countryCode: string;
  };
  statusModalControls?: AccountStatusModalSectionProps;
};

export type ProductListProps<T> = LoadingProductList<T> & {
  countryCode?: string;
};

export type AccountListItemProps = {
  id: string;
  title: string;
  subTitle?: string;
  balance?: number;
  pending?: number;
  onClick?: () => void;
  productType: ProductTypes;
  children?: ReactNode;
  available?: number;
  accountStatus?: string;
  status?: string;
  isExternalLink?: boolean;
  rightColumnProps?: AccountItemRightColumnProps;
} & ComponentDefaultProps;

export type AccountItemLabelValuePair = { label: string; value: string | number };

export type AccountItemRightColumnProps = { labelValuePairs?: AccountItemLabelValuePair[] };
