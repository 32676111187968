import { type NavigateFunction } from 'react-router-dom';

import { type PartialDeep } from 'type-fest';

import {
  type BusinessAccount,
  Country,
  CurrencyCode,
  DrawdownStatus,
  type LineOfCredit,
  PaymentType,
} from '@generated-fg';

import { currencyFormatter, formatBsb } from '@utils';

import { type AccountItem, ProductTypes } from '../models/Account';
import type { PayeeV2 } from '../models/PayAnyone';
import { isLOCMambu } from './loc';

const coreProducts = new Set([
  ProductTypes.SBL,
  ProductTypes.LOC,
  ProductTypes.SBLM,
  ProductTypes.LOCM,
]);

export const isBAOnly = (accountList: AccountItem[]): boolean => {
  return accountList.length === 1 && !isCoreProduct(accountList[0]);
};

export const isCoreProduct = (account: AccountItem): boolean => {
  return coreProducts.has(account.productType);
};

export const isLocId = (applicationId: string) => {
  const prefix = applicationId.slice(0, 4).toLocaleLowerCase();
  return prefix === 'lcnz' || prefix === 'lcau';
};

export const isSblmId = (applicationId: string) =>
  applicationId.slice(0, 4).toLocaleLowerCase() === 'sblm';

export const backToAccountPage = (applicationId: string, navigate: NavigateFunction) => {
  const applicationType = isSblmId(applicationId) ? 'sblm' : isLocId(applicationId) ? 'loc' : 'sbl';
  return navigate(`/${applicationType}/${applicationId}`);
};

export const isPayAnyoneAccountOptionDisabled = (account: AccountItem, payee?: PayeeV2) => {
  if (account.productType === ProductTypes.LOC || account.productType === ProductTypes.LOCM) {
    if (account.status !== DrawdownStatus.Active) return true;
    if (payee && payee.paymentType === PaymentType.Bpay) return true;
  }

  return false;
};

export const getAccountItem = (
  productType: ProductTypes,
  account: Partial<BusinessAccount> | PartialDeep<LineOfCredit>,
  checkAccountOptionDisabled?: (account: AccountItem) => boolean,
) => {
  switch (productType) {
    case ProductTypes.BA: {
      account = account as Partial<BusinessAccount>;
      const accountItem: AccountItem = {
        id: account?.id,
        name: 'Business Account',
        displayInfo: {
          total: currencyFormatter(account?.balance?.available?.amount),
          details: `${formatBsb(account?.branchNumber || '')} ${account?.accountNumber}`,
          available: 'Available',
        },
        balance: {
          total: Number(account?.balance?.total?.amount),
          available: Number(account?.balance?.available?.amount),
        },
        status: account?.status,
        productType: ProductTypes.BA,
        countryCode:
          account?.balance?.available?.currency === CurrencyCode.Aud ? Country.Au : Country.Nz,
      };
      if (checkAccountOptionDisabled) {
        accountItem.itemDisabled = checkAccountOptionDisabled(accountItem);
      }
      return accountItem;
    }
    case ProductTypes.LOC:
    case ProductTypes.LOCM: {
      account = account as Partial<LineOfCredit>;
      const accountItem: AccountItem = {
        id: account.id,
        name: 'Line of Credit',
        status: account?.drawdown?.status,
        displayInfo: {
          available: 'Available',
          total: currencyFormatter(account?.availableAmount?.amount),
          maskedLinkedAccountNumber: account?.maskedLinkedAccountNumber,
        },
        balance: {
          available: Number(account?.availableAmount?.amount),
          paymentAvailable: Number(account?.paymentLimits?.paymentAvailable?.amount),
          mfaThreshold: Number(account?.paymentLimits?.mfaThreshold?.amount),
          dailyPaidAmount: Number(account?.paymentLimits?.dailyPaidAmount?.amount),
        },
        productType: isLOCMambu(account.id) ? ProductTypes.LOCM : ProductTypes.LOC,
        countryCode:
          account?.availableAmount?.currency === CurrencyCode.Nzd ? Country.Nz : Country.Au,
      };
      if (checkAccountOptionDisabled) {
        accountItem.itemDisabled = checkAccountOptionDisabled(accountItem);
      }
      return accountItem;
    }
  }
};
