import { compareAsc } from 'date-fns';
import { Formik, type FormikErrors } from 'formik';

import { Card } from '@components';

import { ACCOUNT_STATUS, PRODUCT_TYPES, activeSBLStatusesSet } from '@constants';

import './CustomStatement.scss';
import { FormContent } from './FormContent';
import type { CustomStatementProps } from './types';

const initialFormValues = {
  fromDate: '',
  toDate: '',
};

type InitialFormType = typeof initialFormValues;

const validateForm = (values: InitialFormType): FormikErrors<InitialFormType> => {
  const errors = {} as FormikErrors<InitialFormType>;
  if (!values.fromDate) {
    errors.fromDate = 'Please enter or select a statement start date';
  }
  if (!values.toDate) {
    errors.toDate = 'Please enter or select a statement end date';
  }
  if (compareAsc(new Date(values.fromDate), new Date(values.toDate)) > 0) {
    errors.fromDate = 'Start date should be before end date';
  }

  return errors;
};

export const CustomStatement = ({ account, customDateRange }: CustomStatementProps) => {
  if (
    account.status !== ACCOUNT_STATUS.Active &&
    !(account.name === PRODUCT_TYPES.SBL && activeSBLStatusesSet.has(account.status))
  ) {
    return null;
  }

  return (
    <Card data-testid="custom-statement" className="custom-statement">
      <h3 className="pds-lead-semibold">Create summary</h3>
      <p className="custom-statement__text">
        Instantly create a summary for a period of up to 12 months.
      </p>
      <Formik
        initialValues={initialFormValues}
        validateOnBlur={false}
        validateOnChange={false}
        validate={validateForm}
        onSubmit={() => {
          //
        }}
      >
        <FormContent account={account} customDateRange={customDateRange} />
      </Formik>
    </Card>
  );
};
