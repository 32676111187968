import { type PropsWithChildren, createContext, useContext } from 'react';

import { type ClarityClient } from '../services/types';

export const CONTEXT_INITIAL_VALUE = {
  clarity: undefined,
};
interface ClarityProviderState {
  clarity?: ClarityClient;
}

interface ClarityProviderProps {
  clarity: ClarityClient;
}

const ClarityContext = createContext<ClarityProviderState>(CONTEXT_INITIAL_VALUE);

export const ClarityProvider = (props: PropsWithChildren<ClarityProviderProps>) => {
  return (
    <ClarityContext.Provider
      value={{
        clarity: props.clarity,
      }}
    >
      {props.children}
    </ClarityContext.Provider>
  );
};

export const useClarity = () => useContext(ClarityContext);
