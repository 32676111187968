import { useEffect } from 'react';

import { type UserProperties } from '@all-in-one-web/analytics';
import { useClarity } from '@all-in-one-web/clarity';

import { type AppContextData } from '@models';
import { buildUserProperties, setIntegrationsUser, trackBAActionEvent } from '@utils';

export const useSetIntegrationsUserWithAppContext = ({
  appData,
  isGtmLoaded,
}: {
  appData: AppContextData;
  isGtmLoaded: boolean;
}) => {
  const { clarity } = useClarity();

  const appContextLoading = appData?.appContextLoading;
  const prospaId = appData?.identityDetails?.id;

  useEffect(() => {
    if (appContextLoading || !prospaId || !isGtmLoaded) return;

    const userProperties: UserProperties = buildUserProperties(appData);
    if (!userProperties) return;

    setIntegrationsUser(userProperties, clarity);
    trackBAActionEvent('login');
  }, [appContextLoading, appData, clarity, isGtmLoaded, prospaId]);
};
