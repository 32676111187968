import { ButtonSize, ButtonType, CVPCard, CVPCardBody } from '@all-in-one-web/common-ui';

import { type BillCVPCardProps } from './types';

export const BillCVPCard = ({
  bodyProps,
  primaryButtonProps: { onClick, loading, label = 'Check it out' },
}: BillCVPCardProps) => {
  return (
    <CVPCard
      title=""
      body={<CVPCardBody {...bodyProps} />}
      primaryButtonProps={{
        type: ButtonType.PRIMARY,
        size: ButtonSize.MEDIUM,
        label,
        onClick,
        loading,
      }}
    />
  );
};
