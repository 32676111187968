import classnames from 'classnames';

import { BlockLoader } from '../BlockLoader';
import './AccountLoader.scss';
import type { AccountLoaderProps } from './types';

export const AccountLoader = ({ className }: AccountLoaderProps) => (
  <section data-testid="account-loader" className={classnames('account-loader', className)}>
    <div data-testid="balance-loader" className="account-loader-block balance">
      <BlockLoader />
    </div>
    <div data-testid="available-loader" className="account-loader-block available">
      <BlockLoader />
    </div>
  </section>
);
