import classNames from 'classnames';

import './Toast.scss';
import type { ToastProps } from './types';

export const Toast = ({ toasts }: ToastProps) => (
  <div className="toasts-container">
    <div>
      {toasts.map(({ message, icon: ToastIcon, id }) => (
        <div key={id}>
          <div className="toasts__toast">
            {!!ToastIcon && (
              <div className="toast-icon">
                <ToastIcon />
              </div>
            )}
            <p className={classNames('pds-text', 'toast-text')}>{message}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);
