import classNames from 'classnames';

import { Alert, Card, MissedRepaymentPhoneNumber } from '@all-in-one-web/common-ui';
import { UtilitiesPhone } from '@prospa/icons';

import { Country } from '@generated-fg';

import { currencyFormatter } from '@utils';

import type { MissedRepaymentModalBodyProps } from '../types';
import styles from './MissedRepaymentModalBody.module.scss';

export const MissedRepaymentModalBody = ({
  inArrearsAmount,
  countryCode = Country.Au,
  collectionsPaymentUrl,
}: MissedRepaymentModalBodyProps) => {
  const formattedInArrearsAmount = currencyFormatter(inArrearsAmount.amount);

  return (
    <div className="MissedRepaymentModal">
      <UtilitiesPhone />
      <h4 className={styles.MissedRepaymentModalHeading}>Get back on track</h4>

      {collectionsPaymentUrl ? (
        <CollectionDetails amount={formattedInArrearsAmount} />
      ) : (
        <DefaultDetails amount={formattedInArrearsAmount} countryCode={countryCode} />
      )}
    </div>
  );
};

const CollectionDetails = (props: { amount: string }) => (
  <>
    <p>
      Your missed direct debit means late fees are accruing. Let's make sure you don't fall further
      behind.
    </p>
    <Card
      className={classNames(
        styles.MissedRepaymentModalCard,
        styles.MissedRepaymentModalCardCollection,
      )}
    >
      <div className={styles.MissedRepaymentModalMissedPaymentDetailBodyRow}>
        <p
          className={classNames(
            styles.MissedRepaymentModalMissedPaymentDetailBodyRowHeading,
            'pds-text-semibold',
          )}
        >
          Total overdue balance
        </p>
        <div
          className={classNames(
            styles.MissedRepaymentModalMissedPaymentDetailBodyRowHeadingValue,
            'pds-text-semibold',
          )}
        >
          {props.amount}
        </div>
      </div>
    </Card>
    <Alert alertType="Warning">
      <span> Repayments can take 2-5 days to reflect in your account.</span>
    </Alert>
  </>
);

const DefaultDetails = (props: { amount: string; countryCode: Country }) => (
  <>
    <p>
      Repayments are easy to miss when you’re busy. Let’s make sure you don’t fall further behind.
    </p>
    <Card className={styles.MissedRepaymentModalCard}>
      <div className={styles.MissedRepaymentModalMissedPaymentDetailBodyRow}>
        <p
          className={classNames(
            styles.MissedRepaymentModalMissedPaymentDetailBodyRowHeading,
            'pds-text-semibold',
          )}
        >
          Total overdue balance
        </p>
        <p
          className={classNames(
            styles.MissedRepaymentModalMissedPaymentDetailBodyRowHeadingValue,
            'pds-text-semibold',
          )}
        >
          {props.amount}
        </p>
      </div>
    </Card>
    {props.countryCode === Country.Au ? (
      <p>
        Call us on{' '}
        <a className="pds-link" href={MissedRepaymentPhoneNumber[props.countryCode].url}>
          {MissedRepaymentPhoneNumber[props.countryCode].text}
        </a>{' '}
        to make a card payment over the phone anytime, or talk to an agent Monday - Friday 8:30am -
        7pm AEST.
      </p>
    ) : (
      <p>
        Call us to talk to an agent on{' '}
        <a className="pds-link" href={MissedRepaymentPhoneNumber[props.countryCode].url}>
          {MissedRepaymentPhoneNumber[props.countryCode].text}
        </a>{' '}
        Mon-Fri 9:30am-9pm, Sat 11:00am-8pm (NZST).
      </p>
    )}
    {props.countryCode === Country.Au && (
      <p className={styles.MissedRepaymentModalBold}>
        Your repayments should appear in your account within 48 hours.
      </p>
    )}
  </>
);
