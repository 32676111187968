import {
  type BankPayeeV2,
  type BpayPayeeV2,
  BusinessAccountStatus,
  Country,
  PaymentType,
} from '@generated-fg';

import { formatNZAccountDetails } from '@utils';

import { TABINDEX } from '../components/Modal/AddNewPayeeModal/types';
import { type AccountItem, ProductTypes } from '../models/Account';
import {
  type CustomPayee,
  type NewPayeeValues,
  type PayeeV2,
  PaymentTypeString,
} from '../models/PayAnyone';

const defaultCustomPayee = {
  name: '',
  bankAccountName: '',
  bankStateBranch: '',
  bankAccountNumber: '',
  bPayBillerCode: '',
  bPayReference: '',
  bPayBillerName: '',
};

export const transformBSBtoV2 = (bsb: string) => {
  return bsb.replace(/(\d{3})(\d{3})/i, '$1-$2');
};

export const mapNewPayeeValuesToCustomPayee = (
  newPayeeValues: NewPayeeValues,
  currentTab: TABINDEX,
): CustomPayee => {
  const { billerCode, referenceNumber, nickname, accountNumber, BSB, accountName } = newPayeeValues;
  const newPayee: CustomPayee = defaultCustomPayee;
  const isNewPayeeBpay = !!billerCode && currentTab === TABINDEX.BPAY;

  return isNewPayeeBpay
    ? {
        ...newPayee,
        paymentType: PaymentTypeString.BPay,
        bPayBillerCode: billerCode,
        bPayReference: referenceNumber,
        bPayBillerName: nickname,
      }
    : {
        ...newPayee,
        paymentType: PaymentTypeString.BankTransfer,
        bankAccountNumber: accountNumber,
        bankStateBranch: BSB,
        bankAccountName: accountName,
      };
};

export const mapCustomPayeeToPayeeV2 = (payee: CustomPayee): PayeeV2 => {
  if (payee.bPayBillerCode) {
    return {
      id: payee.id,
      accountName: payee.name,
      countryCode: Country.Au,
      paymentType: PaymentType.Bpay,
      bPayBillerCode: payee.bPayBillerCode,
      bPayReference: payee.bPayReference,
      billerName: '',
    };
  }
  // Only AU accounts have a BSB
  return payee.bankStateBranch
    ? {
        id: payee.id,
        countryCode: Country.Au,
        paymentType: PaymentType.BankTransfer,
        account: payee.bankAccountNumber,
        accountName: payee.bankAccountName,
        bsb: transformBSBtoV2(payee.bankStateBranch),
      }
    : {
        id: payee.bankAccountNumber,
        countryCode: Country.Nz,
        paymentType: PaymentType.BankTransfer,
        accountName: payee.bankAccountName,
        ...formatNZAccountDetails({ bankAccountNumber: payee.bankAccountNumber }),
      };
};

export const getPayeesV2FetchVariables = (
  payFromAccount: AccountItem,
  countryCode: Country,
  businessAccountStatus?: BusinessAccountStatus,
) => {
  switch (payFromAccount?.productType) {
    case ProductTypes.BA:
      return {
        paymentTypes: [PaymentType.BankTransfer, PaymentType.Bpay],
        countryCode: Country.Au,
      };
    case ProductTypes.LOC:
    case ProductTypes.LOCM:
      return {
        paymentTypes: [
          businessAccountStatus === BusinessAccountStatus.Active
            ? PaymentType.BusinessAccount
            : null,
          PaymentType.BankTransfer,
        ].filter(item => !!item),
        countryCode: countryCode,
      };
    case ProductTypes.SBL:
    case ProductTypes.SBLM:
    default:
      return null;
  }
};

export const isNZBankPayeeV2 = (
  payee: PayeeV2,
): payee is Omit<BankPayeeV2, 'countryCode' | 'bsb'> & { countryCode: Country.Nz } =>
  payee.countryCode === Country.Nz && payee.paymentType === PaymentType.BankTransfer;

export const isAuBankPayeeV2 = (
  payee: PayeeV2,
): payee is Omit<BankPayeeV2, 'countryCode' | 'bank' | 'branch' | 'suffix'> & {
  countryCode: Country.Au;
} =>
  payee.countryCode === Country.Au &&
  (payee.paymentType === PaymentType.BankTransfer ||
    payee.paymentType === PaymentType.BusinessAccount);

export const isBPayPayeeV2 = (payee: PayeeV2): payee is BpayPayeeV2 =>
  payee.countryCode === Country.Au && payee.paymentType === PaymentType.Bpay;
