import { useState } from 'react';

import { Alert, Button, LineOfCreditAccruedDetailModal } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { currencyFormatter, formatDateInDayMonthYear } from '@utils';

import styles from './DeferralLineOfCreditAlert.module.scss';
import { type DeferralLineOfCreditAlertProps as Props } from './types';

export const DeferralLineOfCreditAlert = ({ deferralDetail, accruedDetail }: Props) => {
  const [isAccruedDetailModalOpen, setIsAccruedDetailModalOpen] = useState(false);
  if (!deferralDetail || !accruedDetail) return null;
  return (
    <>
      <Alert alertType="Info">
        <div className={styles.DeferralLineOfCreditAlertContainer}>
          <span className={styles.DeferralLineOfCreditAlertCopy}>
            {`Current accrued amount is `}
            <b>{currencyFormatter(deferralDetail.totalDeferralAmount)}</b>
            {`. Your deferral period ends on ${formatDateInDayMonthYear(
              deferralDetail.deferralPeriodEndDate,
            )}.`}
          </span>
          <Button
            size={ButtonSize.SMALL}
            type={ButtonType.ICON_LINK_UNDERLINE}
            onClick={() => setIsAccruedDetailModalOpen(true)}
          >
            See details
          </Button>
        </div>
      </Alert>
      <LineOfCreditAccruedDetailModal
        isOpen={isAccruedDetailModalOpen}
        onClose={() => setIsAccruedDetailModalOpen(false)}
        accruedDetail={accruedDetail}
        deferralDetail={deferralDetail}
      />
    </>
  );
};
