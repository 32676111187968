import classNames from 'classnames';

import { ValueDeliverValueFast as RocketIcon } from '@prospa/salt-illustrations';

import { Banner } from '@components';
import type { BannerProps } from '@components/types';

import { BUSINESS_LOAN_OFFER_BANNER } from '@constants';

import styles from './BusinessLoanOfferBanner.module.scss';

export const BusinessLoanOfferBanner = ({ onClick }: Pick<BannerProps, 'onClick'>) => {
  return (
    <Banner
      key="business-loan-offer"
      className={classNames(styles['BusinessLoanOfferBanner'], 'banner')}
      icon={<RocketIcon />}
      title={BUSINESS_LOAN_OFFER_BANNER.TITLE}
      subtitle={BUSINESS_LOAN_OFFER_BANNER.SUBTITLE}
      onClick={onClick}
      buttonText={BUSINESS_LOAN_OFFER_BANNER.BUTTON}
    />
  );
};
