import type { FC } from 'react';

import { Field, Formik, type FormikConfig, type FormikErrors } from 'formik';

import { UtilitiesPadlock as PadlockIcon } from '@prospa/icons';

import { useUpdateCardPinMutation } from '@generated-fg';

import { Modal, PinInput } from '@components';

import Api from '../../../services/Api';
import './ChangePINModal.scss';
import type { ChangePINModalProps } from './types';

const initialFormValues = {
  newPin: '',
  confirmNewPin: '',
};

type InitialFormType = typeof initialFormValues;
type TErrors = FormikErrors<InitialFormType>;

const PIN_LENGTH = 4;

export const ChangePINModal: FC<ChangePINModalProps> = ({
  cardId,
  isOpen,
  toggle,
  onError,
  onSuccess,
  newCard,
}) => {
  const [updateCardPinMutation, { loading }] = useUpdateCardPinMutation();

  const handleSubmit: FormikConfig<InitialFormType>['onSubmit'] = async ({ newPin: cardPin }) => {
    try {
      const { errors, data } = await updateCardPinMutation({
        variables: {
          input: {
            stepUpToken: Api.prospaStepUpToken,
            cardId,
            cardPin,
          },
        },
      });

      if (errors?.length || !data.updateCardPin.success) throw errors;

      onSuccess();
    } catch (error) {
      onError();
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validateOnBlur
      validateOnChange={false}
      validate={validateUpdatePin}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit }) => {
        const allowSubmit =
          values.confirmNewPin.length === PIN_LENGTH && values.newPin.length === PIN_LENGTH;
        return (
          <Modal
            hideFooterBorder
            isOpen={isOpen}
            onClose={toggle}
            showCloseModal
            isLoading={loading}
            primaryCTALabel="Save"
            primaryCTAOnClick={() => handleSubmit()}
            allowSubmit={allowSubmit}
            body={
              <div className="change-pin-modal">
                <PadlockIcon className="change-pin-modal__icon" />
                <h4>{newCard ? `Set a ${PIN_LENGTH}-digit card PIN` : 'Change card PIN'}</h4>
                <p className="pds-text">
                  {newCard
                    ? 'Please enter and confirm your card PIN.'
                    : `Please enter and confirm your new ${PIN_LENGTH}-digit card PIN.`}
                </p>
                <Field name="newPin" label="Card PIN" component={PinInput} />
                <Field name="confirmNewPin" label="Confirm card PIN" component={PinInput} />
              </div>
            }
          />
        );
      }}
    </Formik>
  );
};

// *************

function validateUpdatePin(values: InitialFormType) {
  const errors: TErrors = {};
  if (values.newPin !== values.confirmNewPin) {
    errors.confirmNewPin = 'That PIN doesn’t seem to match';
  }
  return errors;
}
