import { useMemo } from 'react';

import { StatementFileType } from '@generated-fg';

export interface TUseDownloadOptionsProps {
  loaders: {
    pdf: boolean;
    csv: boolean;
  };
  selectedFileType: StatementFileType;
  showCsv: boolean;
}

export const useDownloadOptions = ({
  loaders: { pdf: pdfLoading, csv: csvLoading },
  selectedFileType,
  showCsv,
}: TUseDownloadOptionsProps) => {
  const options = useMemo(() => {
    const csvOption = showCsv
      ? [
          {
            value: StatementFileType.Csv,
            label: StatementFileType.Csv.toUpperCase(),
            loading: csvLoading && selectedFileType === StatementFileType.Csv,
          },
        ]
      : [];
    return [
      {
        value: StatementFileType.Pdf,
        label: StatementFileType.Pdf.toUpperCase(),
        loading: pdfLoading && selectedFileType === StatementFileType.Pdf,
      },
      ...csvOption,
    ];
  }, [csvLoading, pdfLoading, selectedFileType, showCsv]);

  return options;
};
