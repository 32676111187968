import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { Cross as CrossButton } from '@prospa/salt-icons';

import { IntegrationStatus } from '@generated-fg';

import { Alert, Button } from '@components';

import {
  ACCOUNTING_INTEGRATION_ERROR_MESSAGES,
  ButtonSize,
  ButtonType,
  DASHBOARD_ROUTES,
} from '@constants';
import { preventClickEventPopup } from '@utils';

import { SettingsSections } from '../../../containers/Settings/types';
import styles from './IntegrationErrorAlert.module.scss';
import type { IntegrationErrorAlertProps } from './types';

const displayableErrorStatuses = new Set([
  IntegrationStatus.AuthError,
  IntegrationStatus.SyncError,
]);

const alertClassNames = {
  [IntegrationStatus.AuthError]: styles.IntegrationErrorAlertAuth,
  [IntegrationStatus.SyncError]: styles.IntegrationErrorAlertSync,
};

export const integrationErrorAlertTestId = {
  [IntegrationStatus.AuthError]: 'integration-auth-error',
  [IntegrationStatus.SyncError]: 'integration-sync-error',
};

const IntegrationErrorAlertBody = ({
  integrationStatus,
  showButton = true,
}: {
  integrationStatus: IntegrationStatus;
  showButton?: boolean;
}) => {
  const navigate = useNavigate();
  const reconnectToXero = useCallback(() => {
    navigate(`${DASHBOARD_ROUTES.SETTINGS}/${SettingsSections.CONNECT_TO_XERO}`);
  }, [navigate]);

  const navigateToManageConnection = useCallback(() => {
    navigate(`${DASHBOARD_ROUTES.DATA_FEEDS}`);
  }, [navigate]);

  switch (integrationStatus) {
    case IntegrationStatus.AuthError:
      return (
        <p
          data-testid={integrationErrorAlertTestId[IntegrationStatus.AuthError]}
          className={styles.IntegrationErrorAlertAuthContents}
        >
          <span>{ACCOUNTING_INTEGRATION_ERROR_MESSAGES.AUTH_ERROR}</span>
          {showButton && (
            <Button
              type={ButtonType.ICON_LINK_UNDERLINE}
              size={ButtonSize.SMALL}
              label="Connect to Xero"
              onClick={reconnectToXero}
              testId="integration-auth-error-button"
            />
          )}
        </p>
      );
    case IntegrationStatus.SyncError: {
      const errorMessage = showButton
        ? ACCOUNTING_INTEGRATION_ERROR_MESSAGES.SYNC_ERROR_WITH_BUTTON
        : ACCOUNTING_INTEGRATION_ERROR_MESSAGES.SYNC_ERROR;
      return (
        <p
          data-testid={integrationErrorAlertTestId[IntegrationStatus.SyncError]}
          className={styles.IntegrationErrorAlertSyncContents}
        >
          <span>{errorMessage}</span>
          {showButton && (
            <span>
              <Button
                type={ButtonType.ICON_LINK_UNDERLINE}
                size={ButtonSize.SMALL}
                label="Manage Connection"
                onClick={e => {
                  preventClickEventPopup(navigateToManageConnection)(e);
                }}
              />
            </span>
          )}
        </p>
      );
    }
    default:
      return null;
  }
};

const IntegrationErrorAlert = ({
  integrationStatus,
  onClose,
  className,
  showButton,
}: IntegrationErrorAlertProps) => {
  const errorPresent = displayableErrorStatuses.has(integrationStatus);
  const containerClassName = alertClassNames[integrationStatus];
  const preventPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  return (
    errorPresent && (
      <Alert
        alertType="Warning"
        onClose={onClose}
        onClick={preventPropagation}
        className={classNames(className, containerClassName)}
        dismissIcon={<CrossButton data-testid="integration-error-cross-button" size={20} />}
      >
        <IntegrationErrorAlertBody integrationStatus={integrationStatus} showButton={showButton} />
      </Alert>
    )
  );
};

export default IntegrationErrorAlert;
