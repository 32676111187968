import { type FC, useMemo } from 'react';

import { CheckCircle, InfoCircle } from '@prospa/salt-icons';

import { BillStatus } from '@generated-fg';

import { type BillDetailAlertUIProps, type BillDetailsAlertProps } from '@components/types';

import {
  BillAlertMessage,
  BillStatusAndAlertTypeMapping,
  incompleteScannedBillAlert,
} from '@constants';
import { BillAlertMessageWithDate, formatDateInSpaceSplitDayMonthYear } from '@utils';

export const BillStatusAndAlertIconMapping: Record<BillStatus, FC> = {
  [BillStatus.Received]: InfoCircle,
  [BillStatus.Failed]: InfoCircle,
  [BillStatus.Paid]: CheckCircle,
  [BillStatus.Scheduled]: CheckCircle,
  [BillStatus.Pending]: CheckCircle,
  [BillStatus.Reviewed]: CheckCircle,
};

export function useBillDetailsAlert({
  status,
  isBillScanIncomplete,
  recentTransaction,
}: BillDetailsAlertProps): BillDetailAlertUIProps {
  const detail = useMemo<BillDetailAlertUIProps>(() => {
    const alertType = BillStatusAndAlertTypeMapping[status];
    const icon = BillStatusAndAlertIconMapping[status];
    const { transactionDate, scheduledDate } = recentTransaction || {};
    let message;
    switch (status) {
      case BillStatus.Scheduled: {
        const date = formatDateInSpaceSplitDayMonthYear(scheduledDate);
        message = date ? BillAlertMessageWithDate[status](date) : undefined;
        break;
      }
      case BillStatus.Paid:
      case BillStatus.Failed: {
        const date = formatDateInSpaceSplitDayMonthYear(transactionDate);
        message = date ? BillAlertMessageWithDate[status](date) : undefined;
        break;
      }
      case BillStatus.Received: {
        message = isBillScanIncomplete ? incompleteScannedBillAlert : BillAlertMessage[status];
        break;
      }
      case BillStatus.Pending:
      default:
        message = BillAlertMessage[status];
        break;
    }
    return {
      icon,
      message,
      alertType,
    };
  }, [status, recentTransaction, isBillScanIncomplete]);
  return detail;
}
