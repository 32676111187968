import { Field } from 'formik';

import { type BillPaymentMethodInput, BillPaymentType } from '@generated-fg';

import { AddNewPayeeModal, Button, Card } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { usePaymentMethodSection } from '@hooks';
import { isValidBpayPayment } from '@utils';

import BpayBlackBg from '../../../../../../assets/BpayBlackBg.svg';
import styles from '../../styles.module.scss';
import { PaymentMethodDetails } from './PaymentMethodDetails';
import { type PaymentMethodSectionProps } from './types';

export const PaymentMethodSection = ({
  countryCode,
  billerName,
  billStatus,
  paymentMethod = {} as BillPaymentMethodInput,
  isNonEditable,
  setPaymentMethod,
}: PaymentMethodSectionProps) => {
  const { openNewPayeeModal, payeeModalLabel, payeeModalProps, paymentMethodError } =
    usePaymentMethodSection({
      setPaymentMethod,
      countryCode,
      billerName,
      billStatus,
      paymentMethod,
    });

  const isBpayPayee =
    paymentMethod?.paymentTypes?.includes(BillPaymentType.Bpay) &&
    isValidBpayPayment(paymentMethod);

  const renderPayeeFormModal = () => {
    if (isNonEditable) {
      return null;
    }
    return (
      <>
        <Button
          className={styles.ConfirmBillFormPaymentMethodSectionAddPayeeButton}
          type={ButtonType.LINK}
          size={ButtonSize.MEDIUM}
          label={payeeModalLabel}
          onClick={openNewPayeeModal}
        />
        <AddNewPayeeModal {...payeeModalProps} />
      </>
    );
  };

  return (
    <Card className={styles.ConfirmBillFormPaymentMethodSection}>
      <h2 className={styles.ConfirmBillFormPaymentMethodSectionHeading}>
        {isBpayPayee && <img src={BpayBlackBg} alt="bpay-black icon" role="presentation" />}
        Biller details
      </h2>
      <Field name="paymentMethod" label="paymentMethod" key="paymentMethod" type="hidden" />
      <PaymentMethodDetails {...paymentMethod} countryCode={countryCode} />
      {renderPayeeFormModal()}
      {paymentMethodError && (
        <p className={styles.ConfirmBillFormPaymentMethodSectionNoPayeeError}>
          {paymentMethodError}
        </p>
      )}
    </Card>
  );
};
