import type { GetCardsMetaQuery } from '@generated-fg';

export interface CardAwaitBannerProps {
  cardMeta: GetCardsMetaQuery['user']['businessAccount']['cards'][number];
  accountId: GetCardsMetaQuery['user']['businessAccount']['id'];
  refetchCardMeta: () => void;
}

export enum CardActivationStep {
  CardActivate,
  Pin,
  Success,
  Error,
}
