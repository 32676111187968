import { useCallback, useMemo, useState } from 'react';

import { format } from 'date-fns';
import { Field, Formik, type FormikHelpers, type FormikValues } from 'formik';

import { supportEmails, supportPhoneNumbers } from '@all-in-one-web/common-ui';
import { Document } from '@prospa/salt-illustrations';

import {
  GenerateContractSignUrlErrorCode,
  type GenerateContractSignUrlErrorOutput,
  type GenerateContractSignUrlMutation,
  type GenerateContractSignUrlSuccessOutput,
  useGenerateContractSignUrlMutation,
} from '@generated-fg';

import { Button, Email, FormikDatePicker, PhoneNumber } from '@components';

import { ADULT_AGE, ButtonSize, ButtonType, MAX_AGE, dateFormatAPI } from '@constants';
import { externalRedirect, getEarliestDOB } from '@utils';

import styles from './Contract.module.scss';
import { type NotSignedContractProps } from './types';

const initialValues = { dateOfBirth: '' };

export const NotSignedContract = ({ contractId, countryCode }: NotSignedContractProps) => {
  const [generateContractSignUrlMutation, { loading }] = useGenerateContractSignUrlMutation();
  const [generateContractSignUrlErrorCode, setGenerateContractSignUrlErrorCode] =
    useState<GenerateContractSignUrlErrorCode | null>(null);

  const isSubmissionLocked = useMemo(
    () => generateContractSignUrlErrorCode === GenerateContractSignUrlErrorCode.Locked,
    [generateContractSignUrlErrorCode],
  );

  const onSubmit = useCallback(
    async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
      const { setErrors } = formikHelpers;
      const { dateOfBirth } = values;

      const onSuccess = (successOutput: GenerateContractSignUrlSuccessOutput) => {
        const { url } = successOutput;
        externalRedirect({ url, openInNewTab: false });
      };

      const onError = (errorOutput: GenerateContractSignUrlErrorOutput) => {
        const { code, message } = errorOutput;
        setGenerateContractSignUrlErrorCode(code);
        setErrors({ dateOfBirth: message });
      };

      generateContractSignUrlMutation({
        variables: {
          input: {
            dateOfBirth: format(dateOfBirth, dateFormatAPI),
            contractId: contractId,
          },
        },
        onCompleted: (data: GenerateContractSignUrlMutation) => {
          const { __typename } = data.generateContractSignUrl;
          switch (__typename) {
            case 'GenerateContractSignUrlSuccessOutput':
              onSuccess(data.generateContractSignUrl);
              break;
            case 'GenerateContractSignUrlErrorOutput':
              onError(data.generateContractSignUrl);
              break;
            default:
              break;
          }
        },
      }).catch(err => setErrors({ dateOfBirth: err.message }));
    },
    [contractId, generateContractSignUrlMutation],
  );
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values: { dateOfBirth }, handleSubmit }) => (
        <div className={styles.Contract} data-testid="not-signed-contract">
          <Document className={styles.ContractIllustration} />
          <h2>Review agreement</h2>
          <p className={styles.ContractSubtitle}>
            For security purposes, please enter your DOB in the format DD/MM/YYYY.
          </p>
          <span className={styles.ContractDatePicker}>
            <Field
              name="dateOfBirth"
              label="Date of birth"
              key="dateOfBirth"
              minDate={getEarliestDOB(MAX_AGE)}
              maxDate={getEarliestDOB(ADULT_AGE)}
              component={FormikDatePicker}
              disabled={isSubmissionLocked}
            />
          </span>
          <Button
            className={styles.ContractConfirmButton}
            size={ButtonSize.MEDIUM}
            type={ButtonType.PRIMARY}
            label="Confirm"
            htmlAttrs={{ type: 'submit' }}
            disabled={!dateOfBirth || isSubmissionLocked || loading}
            loading={loading}
            onClick={() => handleSubmit()}
          />
          <p>
            Questions? Get in touch on <br></br>{' '}
            <PhoneNumber phone={supportPhoneNumbers[countryCode]?.text} /> or{' '}
            <Email emailAddress={supportEmails[countryCode]?.text} />
          </p>
        </div>
      )}
    </Formik>
  );
};
