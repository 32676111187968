import classNames from 'classnames';

import {
  getActivePayeeIcon,
  getPayeeHeader,
  getPayeeSubheader,
} from '../PayAnyoneDropdown/helpers';
import styles from './PayeeDetails.module.scss';
import { type PayeeDetailsProps } from './types';

const PayeeDetails = ({ currentPayee, isAccountFrozen }: PayeeDetailsProps) => {
  return (
    <div className={styles.PayeeContainer}>
      <div
        className={classNames(
          isAccountFrozen ? [styles.PayeeIcon, styles.PayeeIconFreeze] : [styles.PayeeIcon],
        )}
      >
        {getActivePayeeIcon(currentPayee['paymentType'])}
      </div>
      <div className={styles.PayeeDetails}>
        <span
          className={classNames(
            isAccountFrozen
              ? [styles.PayeeDetailsFreeze]
              : [styles.PayeeDetailsDescription, 'pds-caption'],
          )}
        >
          To
        </span>
        {!currentPayee['maskedAccountNumber'] && (
          <span
            className={classNames(
              isAccountFrozen
                ? [styles.PayeeDetailsFreeze]
                : [styles.PayeeDetailsDescriptionHeader, 'pds-text'],
            )}
          >
            {getPayeeHeader(currentPayee)}
          </span>
        )}
        <span
          className={classNames(
            isAccountFrozen
              ? [styles.PayeeDetailsFreeze, styles.PayeeDetailsDescriptionMaskedNumber, 'pds-text']
              : currentPayee['maskedAccountNumber']
                ? [styles.PayeeDetailsDescriptionMaskedNumber, 'pds-text']
                : [styles.PayeeDetailsDescriptionNumber, 'pds-summary-text'],
          )}
        >
          {currentPayee['maskedAccountNumber']
            ? `Account ending in ${currentPayee['maskedAccountNumber']}`
            : getPayeeSubheader(currentPayee)}
        </span>
      </div>
    </div>
  );
};

export default PayeeDetails;
