import type { FC } from 'react';
import ReactSwitch from 'react-switch';

import tokens from '@prospa/tokens';

import type { SwitchProps } from './types';

const { color } = tokens.pds;

export const Switch: FC<SwitchProps> = props => (
  <ReactSwitch
    uncheckedIcon={false}
    checkedIcon={false}
    height={24}
    width={48}
    handleDiameter={18}
    onHandleColor={color.white}
    onColor={color.green[300]}
    offHandleColor={color.white}
    offColor={color.neutral[200]}
    boxShadow={`0px 3px 6px rgba(${color.neutral[700]}, 0.5)`}
    {...props}
  />
);
