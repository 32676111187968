import { type GlobalState } from 'little-state-machine';

import type { CardAddress, OpenModalType } from '../containers/Cards/CardSettings/types';

export type UpdateModalPayload = {
  flow: null | 'cardLost';
  modalStep: OpenModalType;
  addressToSendCard: CardAddress;
};

type UpdateModalStepPayload = { modalStep: OpenModalType };

export function updateModalAction(state: GlobalState, payload: UpdateModalPayload): GlobalState {
  return {
    modal: {
      ...state.modal,
      ...payload,
    },
  };
}

export function updateModalStepAction(
  state: GlobalState,
  payload: UpdateModalStepPayload,
): GlobalState {
  return {
    modal: {
      ...state.modal,
      flow: state.modal.flow,
      addressToSendCard: state.modal.addressToSendCard,
      ...payload,
    },
  };
}

export function clearGlobalStateAction(): GlobalState {
  return {
    modal: {
      addressToSendCard: null,
      flow: null,
      modalStep: null,
    },
  };
}
