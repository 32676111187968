import classNames from 'classnames';

import { type ContentCardLocations, useLocationContentCards } from '@all-in-one-web/braze';

import { ContentCardBanner } from '@components';

import styles from './LocationContentCards.module.scss';

export type LocationContentCardsProps = {
  location: ContentCardLocations;
};

export function LocationContentCards(props: LocationContentCardsProps) {
  const { cards, onView, onClick, onDismiss } = useLocationContentCards(props.location);

  if (!cards?.length) {
    return null;
  }

  return (
    <div>
      <div className={classNames(styles.SectionContainer)}>
        <div data-testid="content-card-banner-section" className={classNames(styles.SectionBody)}>
          {cards.map(card => (
            <ContentCardBanner
              key={card.id}
              contentCard={card}
              onView={onView}
              onClick={onClick}
              onDismiss={onDismiss}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
