import { useEffect } from 'react';

import { ButtonSize, ButtonType } from '../../../constants';
import { Banner } from '../../Banner/Banner';
import styles from './ClassicCardBanner.module.scss';
import type { ClassicCardBannerProps } from './types';

export const ClassicCardBanner = ({
  contentCard,
  onClick,
  onDismiss,
  onView,
}: ClassicCardBannerProps) => {
  useEffect(() => {
    onView?.(contentCard);
  }, [onView, contentCard]);

  return (
    <Banner
      className={`${styles.ClassicCardBanner} banner`}
      icon={<img src={contentCard.imageUrl} alt={contentCard.title} />}
      title={contentCard.title}
      subtitle={contentCard.description}
      buttonProps={{
        label: contentCard.linkText,
        type: ButtonType.ICON_LINK_UNDERLINE,
        size: ButtonSize.SMALL,
        onClick: () => onClick?.(contentCard),
      }}
      onClose={contentCard.dismissible ? () => onDismiss?.(contentCard) : undefined}
    />
  );
};
