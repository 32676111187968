import { useCallback, useId, useState } from 'react';
import { Collapse } from 'reactstrap';

import classNames from 'classnames';

import { ChevronDown as DropdownIcon } from '@prospa/salt-icons';

import styles from './ExpandedContent.module.scss';
import type { ExpandedContentProps, ExpandedContentTogglerProps } from './types';

export function ExpandedContent({
  headLabel,
  children,
  onToggleClick,
  testId = 'expanded-content',
  className = '',
  toggler: Toggler = ExpandedContentToggler,
}: ExpandedContentProps) {
  // create a random id for the collapse and use ref
  const refId = useId();
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleToggle = useCallback(() => {
    onToggleClick?.();
    setExpanded(value => !value);
    if (!expanded) {
      const collapse = document.getElementById(refId);
      if (collapse) {
        setTimeout(() => {
          collapse.focus();
        });
      }
    }
  }, [expanded, onToggleClick, refId]);

  return (
    <section
      className={classNames(`expanded-content`, styles['Expanded-content'], className)}
      data-testid={testId}
    >
      <Toggler
        label={headLabel}
        onClick={handleToggle}
        aria-expanded={expanded}
        aria-controls={refId}
      />
      <Collapse isOpen={expanded} id={refId} role="region" tabIndex={-1}>
        {children}
      </Collapse>
    </section>
  );
}

export const ExpandedContentToggler = ({ label, ...rest }: ExpandedContentTogglerProps) => (
  <button className={`${styles['Expanded-content__header']} pds-text-semibold`} {...rest}>
    <h4 className={`${styles['Expanded-content__title']} pds-text-semibold`}>{label}</h4>
    <DropdownIcon
      className={styles['Expanded-content__dropdown-icon']}
      data-open={rest['aria-expanded']}
    />
  </button>
);
