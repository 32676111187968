import { Fragment, useMemo } from 'react';

import classNames from 'classnames';

import {
  Card,
  LoadMoreButton,
  LoadMoreList,
  Section,
  TransactionItem,
  TransactionsLoader,
} from '@components';
import type { TransactionRecord } from '@components/types';

import { LOAN_TRANSACTION } from '@constants';
import { convertToMonth } from '@utils';

import { useLoadMore } from '../../components/LoadMoreSection/LoadMoreList/hooks';
import './LoanTransactions.scss';
import type { LoanTransactionsProps } from './types';

export const LoanTransactions = ({
  transactions = [],
  loading,
  transactionPageSize,
  showTitle = true,
}: LoanTransactionsProps) => {
  const [currentPage, loadNextPage] = useLoadMore(1);
  const months: { [key: string]: boolean } = {};
  const loadButtonProps = {
    total: transactions.length,
    pageSize: transactionPageSize,
    currentPage,
    onLoadMore: loadNextPage,
  };

  const renderTransactionItem = (loanTransaction: TransactionRecord) => {
    const currentMonth = convertToMonth(loanTransaction.date);
    if (!months[currentMonth]) {
      months[currentMonth] = true;
      return (
        <Fragment key={loanTransaction.id}>
          <li
            key={currentMonth}
            className={classNames('loan-transactions--month', 'pds-text-semibold')}
          >
            {currentMonth}
          </li>
          <TransactionItem transaction={loanTransaction} hideTime />
        </Fragment>
      );
    } else {
      return <TransactionItem key={loanTransaction.id} transaction={loanTransaction} hideTime />;
    }
  };

  const hasMore = useMemo(() => {
    const totalPage = Math.ceil(transactions.length / transactionPageSize);
    return totalPage !== currentPage && transactions.length > 0;
  }, [currentPage, transactions, transactionPageSize]);

  const emptyState = (
    <p className="loan-transactions__empty-list">{LOAN_TRANSACTION.EMPTY_STATUS}</p>
  );

  const listOfTransactions = (
    <ul className="loan-transactions__list">
      <LoadMoreList<TransactionRecord>
        {...{
          items: transactions.slice(0, currentPage * transactionPageSize),
          ...loadButtonProps,
          renderItem: renderTransactionItem,
        }}
      />
    </ul>
  );
  return (
    <Fragment>
      <Card
        className={classNames('loantransactions__card', {
          'loantransactions__card-no-title': !showTitle,
        })}
      >
        {showTitle && (
          <p className={classNames('loantransactions__heading pds-lead-semibold')}>Activity</p>
        )}
        <Section>
          <div className="loantransactions__wrapper">
            <span className="loan-transactions">
              {loading ? (
                <TransactionsLoader />
              ) : transactions.length > 0 ? (
                listOfTransactions
              ) : (
                emptyState
              )}
            </span>
          </div>
        </Section>
      </Card>
      {hasMore && <LoadMoreButton {...loadButtonProps} />}
    </Fragment>
  );
};

LoanTransactions.defaultProps = {
  transactionPageSize: 10,
};
