const addPrefixToKey = (tempStr: string, key: string) => {
  return !!tempStr ? `_${key}` : key;
};
/**
 * Transforms a tracking string into a parameters object.
 *
 * @param trackingString - The tracking string to transform.e.g. '_test_key1:value1_test_key2:value2'
 * @returns The parameters object with key-value pairs extracted from the tracking string.
 */
export const transformTrackingStringToParamsObject = (
  trackingString?: string,
): Record<string, string | number | boolean> => {
  if (!trackingString) {
    return {};
  }
  const result: Record<string, string | number | boolean> = {};
  const pairs = trackingString.split('_');

  let tempStr: string = '';

  pairs.forEach(pair => {
    if (pair.includes(':')) {
      const [key, value] = pair.split(':');

      const trackingProperty: string = tempStr + addPrefixToKey(tempStr, key);

      result[trackingProperty.toLowerCase()] = value;

      tempStr = '';
    } else {
      tempStr += addPrefixToKey(tempStr, pair);
    }
  });

  if (tempStr) {
    result[tempStr.toLowerCase()] = true;
  }

  return result;
};
