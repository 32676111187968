import { type Country } from '@generated-fg';

export type CampaignBody = () => JSX.Element | null;

export type FullPageCampaignHook = [
  showCampaign: boolean,
  onCampaignClose: () => void,
  body: CampaignBody,
];

export enum FullPageCampaignCode {
  NZ_MOBILE_APP = 'nz_mobile_app',
}

export type CampaignReleasePlan = {
  releaseDate: {
    startTime: string;
    endTime: string;
  };
  countries: Country[];
};

export type FullPageCampaignProps = {
  // set this code for track view the campaign in local storage
  campaignCode: FullPageCampaignCode;
  // this is mandatory, as the children will render if the campaign is not ready
  children: React.ReactElement;
};
