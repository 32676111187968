import { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { Close } from '@prospa/icons';

import {
  NotificationsPreferencesDocument,
  type PreferenceInput,
  useUpdateUserPreferenceMutation,
} from '@generated-mg';

import { Card, RowDetails, SpinnerContainer, Switch } from '@components';

import { PRODUCT_TYPES } from '@constants';
import { useToastContext } from '@hooks';
import { type EventNameType, getNotificationEventAction, trackBAActionEvent } from '@utils';

import styles from './NotificationsPreferences.module.scss';
import type { NotificationsPreferencesProps, PreferenceType } from './types';

const generatePreferencesFromFields = ({
  fields,
  product,
  onChange,
  loading,
  type,
}: PreferenceType) =>
  fields.length > 0
    ? fields.reduce((acc, preference, index) => {
        if (preference.product === product) {
          const { displayNotificationType, notificationType, enabled } = preference;
          const trackingEvents = getNotificationEventAction(type, product, displayNotificationType);
          acc.push(
            <div className={styles.NotificationsPreferencesOption} key={notificationType}>
              <span>{displayNotificationType}</span>
              <Switch
                onChange={checked => {
                  trackBAActionEvent(trackingEvents as EventNameType, checked ? 'on' : 'off');
                  onChange(checked, index);
                }}
                checked={enabled}
                disabled={loading}
              />
            </div>,
          );
        }
        return acc;
      }, [])
    : null;

const getDisplayProductsFromFields = (fields: PreferenceInput[]) =>
  fields.length > 0 ? Array.from(new Set(fields.map(item => item.product))) : [];

export const NotificationsPreferences = ({
  emailPreferences,
  smsPreferences,
  products,
  notificationsLoading,
}: NotificationsPreferencesProps) => {
  const [fields, setFields] = useState<PreferenceInput[]>([]);

  const type = useMemo(
    () => (emailPreferences ? 'email' : smsPreferences ? 'sms' : ''),
    [emailPreferences, smsPreferences],
  );

  const noPreferences = useMemo(
    () => !emailPreferences?.length && !smsPreferences?.length && !notificationsLoading,
    [emailPreferences, smsPreferences, notificationsLoading],
  );

  const displayProducts = useMemo(() => getDisplayProductsFromFields(fields), [fields]);
  const { addToast } = useToastContext();

  const onUpdateError = useCallback(() => {
    addToast('Failed to update preferences at this time, please try again', Close);
  }, [addToast]);

  const [sendUpdateUserPreferences, { loading }] = useUpdateUserPreferenceMutation({
    onError: onUpdateError,
    refetchQueries: [{ query: NotificationsPreferencesDocument, variables: { products } }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (!emailPreferences && !smsPreferences) {
      return;
    }
    setFields(
      (type === 'email' ? emailPreferences : smsPreferences).map(
        ({ __typename, id: _id, ...item }) => item,
      ),
    );
  }, [emailPreferences, smsPreferences, type]);

  const onChange = async (checked: boolean, index: number) => {
    const newPreferences = [...fields];
    newPreferences[index] = { ...newPreferences[index], enabled: checked };

    await sendUpdateUserPreferences({
      variables: {
        input: {
          preferences: newPreferences,
        },
      },
    });
  };

  if (noPreferences) {
    return null;
  }

  return (
    <Card>
      <SpinnerContainer loading={notificationsLoading}>
        {type ? (
          <>
            <p className={classNames(styles.NotificationsPreferencesHeader, 'pds-lead-semibold')}>
              {type === 'email' ? 'Email notifications' : 'SMS notifications'}
            </p>
            {displayProducts.map((product, index) => {
              return (
                <div key={`${PRODUCT_TYPES[product]}-${type}`}>
                  <RowDetails
                    header={PRODUCT_TYPES[product]}
                    body={generatePreferencesFromFields({
                      type,
                      fields,
                      product,
                      onChange,
                      loading,
                    })}
                  />
                  {index < displayProducts.length - 1 ? (
                    <hr className={styles.NotificationsPreferencesDivider} />
                  ) : null}
                </div>
              );
            })}
          </>
        ) : null}
      </SpinnerContainer>
    </Card>
  );
};
