import { isAfter } from 'date-fns';
import { type FormikErrors } from 'formik';

import { PayAnyoneRecurringPaymentStrings, PayAnyoneTabs } from '@constants';
import { type PayAnyoneFormValues, RecurringDurationType } from '@models';
import { validationConfig } from '@utils';

import { type Validator } from '../types';

export const paymentFrequency: Validator = ({ paymentFrequency }, { paymentFrequencyType }) => {
  const errors: FormikErrors<PayAnyoneFormValues> = {};

  if (paymentFrequencyType !== PayAnyoneTabs.RECURRING) {
    return errors;
  }

  if (!paymentFrequency) {
    errors.paymentFrequency = PayAnyoneRecurringPaymentStrings.PaymentFrequencyEmptyError;
  }

  return errors;
};

export const startDate: Validator = ({ startDate }, { paymentFrequencyType }) => {
  const errors: FormikErrors<PayAnyoneFormValues> = {};

  if (paymentFrequencyType !== PayAnyoneTabs.RECURRING) {
    return errors;
  }

  if (!startDate) {
    errors.startDate = PayAnyoneRecurringPaymentStrings.PaymentStartDateEmptyError;
  } else if (!isAfter(startDate, new Date())) {
    errors.startDate = PayAnyoneRecurringPaymentStrings.PaymentPastStartDateError;
  }

  return errors;
};

export const duration: Validator = (
  { duration, endDate, startDate, numberOfPayments },
  { paymentFrequencyType },
) => {
  const errors: FormikErrors<PayAnyoneFormValues> = {};

  if (paymentFrequencyType !== PayAnyoneTabs.RECURRING) {
    return errors;
  }

  if (!duration) {
    errors.duration = PayAnyoneRecurringPaymentStrings.PaymentDurationEmptyError;
  }

  switch (duration) {
    case RecurringDurationType.SPECIFIC: {
      if (!endDate) {
        errors.endDate = PayAnyoneRecurringPaymentStrings.PaymentEndDateEmptyError;
      } else if (!isAfter(endDate, startDate)) {
        errors.endDate = PayAnyoneRecurringPaymentStrings.PaymentEndDateEarlyError;
      }
      break;
    }
    case RecurringDurationType.EXACT: {
      if (!numberOfPayments) {
        errors.numberOfPayments = PayAnyoneRecurringPaymentStrings.NumberOfPaymentsEmpty;
      } else if (!validationConfig.isNumber.pattern.test(numberOfPayments)) {
        errors.numberOfPayments = PayAnyoneRecurringPaymentStrings.NumberOfPaymentsInvalid;
      } else if (Number(numberOfPayments) < 1) {
        errors.numberOfPayments = PayAnyoneRecurringPaymentStrings.NumberOfPaymentsInvalid;
      } else if (Number(numberOfPayments) > 99) {
        errors.numberOfPayments = PayAnyoneRecurringPaymentStrings.NumberOfPaymentsMaxInvalid;
      }
      break;
    }
  }

  return errors;
};
