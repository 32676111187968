import { useAuth } from 'react-oidc-context';

import UfoSVG from '@prospa/icons/dist/svg/MicroilloNotfound.svg';

import { Button, ErrorPageContainer, NeedHelpContact } from '@components';

import { ButtonSize, ButtonType } from '@constants';

export const AccountNotFound = () => {
  const auth = useAuth();
  const logout = () => {
    auth.signoutRedirect();
  };
  return (
    <ErrorPageContainer
      animationData={UfoSVG}
      assetType="svg"
      title={'Sorry, we can\u2019t find that account'}
      footer={<NeedHelpContact />}
    >
      <p>
        It seems you do not have an account with us. Otherwise, please try using a different email
        address.
      </p>
      <p>
        Don’t have an account?{' '}
        <a className="pds-link" href="https://www.prospa.com/">
          Apply now
        </a>
      </p>
      <Button type={ButtonType.PRIMARY} size={ButtonSize.MEDIUM} onClick={logout}>
        Return to Login
      </Button>
    </ErrorPageContainer>
  );
};

export default AccountNotFound;
