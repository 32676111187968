import { useContext } from 'react';

import classNames from 'classnames';

import {
  Alert,
  PhoneNumber,
  supportLOCPhoneNumbers,
  supportPhoneNumbers,
} from '@all-in-one-web/common-ui';

import { lineOfCreditAUBusinessTime, lineOfCreditNZBusinessTime } from '@constants';

import { AppContext } from '../../../contexts';
import styles from './SuspendedAccountAlert.module.scss';
import type { SuspendedAccountAlertProps } from './types';

function SuspendedAccountAlert({
  className,
  alertMessage,
  phoneNumberProps,
}: SuspendedAccountAlertProps) {
  const {
    signInInfo: { countryCode },
  } = useContext(AppContext);

  const phoneNumber =
    countryCode === 'NZ' ? supportPhoneNumbers['NZ'] : supportLOCPhoneNumbers['AU'];
  const businessTime =
    countryCode === 'NZ' ? lineOfCreditNZBusinessTime : lineOfCreditAUBusinessTime;

  return (
    <Alert className={classNames(styles.SuspendedAccountAlert, className)} alertType="Danger">
      <span data-testid="suspended-account-alert">
        {alertMessage} Please call <PhoneNumber phone={phoneNumber.text} {...phoneNumberProps} />{' '}
        {businessTime}
      </span>
    </Alert>
  );
}
export default SuspendedAccountAlert;
