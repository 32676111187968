import { useLocation, useSearchParams } from 'react-router-dom';

import { DASHBOARD_ROUTES, REDIRECTION_SOURCES } from '@constants';

import { XeroSearchParams } from '../../../containers/Redirection/controllers/XeroController/types';
import { type PageSpinnerData, PageSpinnerSources } from './types';

export function usePageSpinner(): PageSpinnerData {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  let source = PageSpinnerSources.DEFAULT;
  if (location.pathname === `${DASHBOARD_ROUTES.REDIRECTS}/${REDIRECTION_SOURCES.XERO}`) {
    source = searchParams.has(XeroSearchParams.CODE)
      ? PageSpinnerSources.XERO_CONNECTION_SUCCESS
      : PageSpinnerSources.DEFAULT;
  }
  return { source };
}
