import { type ReactNode } from 'react';

import classNames from 'classnames';

import './Section.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

export const Section = ({ children, className, ...restprops }: Props) => {
  return (
    <section className={classNames('section', { [className]: className })} {...restprops}>
      {children}
    </section>
  );
};
