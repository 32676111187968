import type { ApolloCache } from '@apollo/client';

import {
  type BusinessAccountTransaction,
  type BusinessAccountTransactionEdge,
  LineOfCreditStatus,
} from '@generated-fg';

import type { AccountListProps } from '@components/types';

import type { Transaction } from '@models';

import type { ClientCacheType } from '../apolloClient';
import { convertToIsoDate } from './date';

export const getTotalAvailableWithActiveProducts = (
  creditLines: AccountListProps['creditLines']['list'],
  accountDetail: AccountListProps['businessAccount']['accountDetail'],
): number => {
  const { available = 0 } = accountDetail?.balance || {};

  const sumOfAvailableCredit = creditLines.reduce(
    (acc, { accountDetail: { status, available = 0 } }) => {
      if (status === LineOfCreditStatus.LineActive) {
        return Number(available) + acc;
      }

      return acc;
    },
    0,
  );

  return sumOfAvailableCredit + Number(available);
};
export const evictBusinessAccountRelatedCache = ({
  productId,
  cache,
}: {
  productId: string;
  cache: ApolloCache<ClientCacheType | object>;
}): void => {
  cache.evict({
    id: `BusinessAccount:${productId}`,
  });
  cache.evict({
    id: `BankAccount:${productId}`,
  });
};

export const mapPendingBATransactions = (
  pendingTransactions: BusinessAccountTransaction[] = [],
): Transaction[] => {
  return pendingTransactions?.map(item => {
    const convertedDate = convertToIsoDate(item?.transactionTime);
    return {
      id: item.id,
      displayName: item.displayName,
      amount: item.amount.amount,
      description: item.description,
      date: item.transactionTime,
      pending: item.isPending,
      dateGroup: convertedDate,
      reference: item.reference,
    };
  });
};

export const mapSettledBATransactions = (
  settledTransactions: BusinessAccountTransactionEdge[] = [],
): Transaction[] => {
  return settledTransactions.map(
    ({
      node: {
        id,
        amount: { amount },
        description,
        initiatedBy,
        isPending,
        transactionTime,
        displayName,
        reference,
      },
    }) => {
      return {
        id,
        amount,
        description,
        date: transactionTime,
        displayName,
        dateGroup: transactionTime,
        pending: isPending,
        initiatedBy,
        reference,
      };
    },
  );
};
