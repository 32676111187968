import { type PartialDeep } from 'type-fest';

import { type LineOfCredit, LineOfCreditContractStatus } from '@generated-fg';

import { type LineOfCreditStatusAlertProps, type PayeeDetailsProps } from '@components/types';

import { PRODUCT_TYPES, locContractStatusesInDrawdownList } from '@constants';
import { type AccountItem } from '@models';

import { type DrawdownAccountSelectorProps } from '../containers/Drawdown/DrawdownContent/DrawdownAccountSelector/types';
import { type DrawdownSingleAccountProps } from '../containers/Drawdown/DrawdownContent/DrawdownSingleAccount/types';
import { type SelectedDrawdownAccount } from '../containers/Drawdown/hooks/types';
import { canLocPayAndDrawdown } from './loc';

export const buildLineOfCreditStatusAlertProps = (
  loc: PartialDeep<LineOfCredit>,
): LineOfCreditStatusAlertProps => {
  const details = loc?.drawdown || {};
  return { details };
};

export const buildDrawdownSingleAccountProps = (
  loc: PartialDeep<LineOfCredit>,
  loading: boolean,
): DrawdownSingleAccountProps => {
  const locData = loc;
  const accountName = PRODUCT_TYPES.LOC;
  const isLocAccountFrozenForDrawdown =
    !!loc && loc?.contractStatus !== LineOfCreditContractStatus.Active;
  return { locData, accountName, loading, isAccountFrozen: isLocAccountFrozenForDrawdown };
};

export const buildDrawdownAccountSelectorProps = (
  selectedDrawdownAccount: SelectedDrawdownAccount,
  locAccountList: AccountItem[],
  selectLocAccount: (locAccountItem: AccountItem) => void,
  loading: boolean,
): DrawdownAccountSelectorProps => {
  const selectedAccount = locAccountList.find(
    locAccount => locAccount.id === selectedDrawdownAccount?.id,
  );
  return {
    accountList: locAccountList,
    selectAccount: selectLocAccount,
    selectedAccount,
    loading,
  };
};

export const buildPayeeDetailsProps = (
  loc: PartialDeep<LineOfCredit>,
  loading: boolean,
): PayeeDetailsProps => {
  const currentPayee = {
    maskedAccountNumber: loc?.maskedLinkedAccountNumber,
    accountName: PRODUCT_TYPES.LOC,
  };
  const isDisabled = !loading && !canLocPayAndDrawdown(loc);
  return { currentPayee, isAccountFrozen: isDisabled };
};

export const canLocBeInDrawdownList = (loc: PartialDeep<LineOfCredit>): boolean => {
  return loc?.contractStatus && locContractStatusesInDrawdownList.has(loc?.contractStatus);
};
