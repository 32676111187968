import { useCallback, useState } from 'react';

import classNames from 'classnames';

import { Alert } from '@all-in-one-web/common-ui';
import { InfoCircle } from '@prospa/salt-icons';

import { BillStatus } from '@generated-fg';

import { Button } from '@components';

import { BillFormConstants, BillsPaidStatuses, ButtonSize, ButtonType } from '@constants';

import styles from '../../styles.module.scss';
import { BillFormAction } from '../../types';
import { type BillFormSubmissionSectionProps } from './types';

export const BillFormSubmissionSection = ({
  status,
  handleFormSubmission,
  isSubmitting,
  isValid,
}: BillFormSubmissionSectionProps) => {
  const [formAction, setFormAction] = useState<BillFormAction | null>(null);
  const handleFormSubmit = useCallback(
    (action: BillFormAction) => {
      setFormAction(action);
      handleFormSubmission(action);
    },
    [handleFormSubmission],
  );
  if (BillsPaidStatuses.includes(status)) return null;

  return (
    <div className={styles.ConfirmBillFormSubmissionSection}>
      {(() => {
        switch (status) {
          case BillStatus.Scheduled: {
            return (
              <Button
                type={ButtonType.SECONDARY_OUTLINE}
                size={ButtonSize.MEDIUM}
                disabled={isSubmitting}
                loading={isSubmitting}
                className={styles.ConfirmBillFormSubmissionSectionButton}
                onClick={() => handleFormSubmit(BillFormAction.CANCEL_SCHEDULED)}
                label={BillFormConstants.CancelButtonLabel}
              />
            );
          }

          case BillStatus.Received:
          case BillStatus.Reviewed: {
            const invalidFormButtonClassName = !isValid ? 'disabled' : null;
            const payNowButton = () => (
              <Button
                type={ButtonType.PRIMARY}
                size={ButtonSize.MEDIUM}
                disabled={isSubmitting}
                loading={isSubmitting}
                className={classNames(
                  styles.ConfirmBillFormSubmissionSectionButton,
                  invalidFormButtonClassName,
                )}
                onClick={() => handleFormSubmit(BillFormAction.CONFIRM_NOW)}
                label={BillFormConstants.ConfirmScheduleNowLabel}
              />
            );
            const payLaterButton = () => (
              <Button
                type={ButtonType.SECONDARY_OUTLINE}
                size={ButtonSize.MEDIUM}
                disabled={isSubmitting}
                loading={isSubmitting}
                className={classNames(
                  styles.ConfirmBillFormSubmissionSectionButton,
                  invalidFormButtonClassName,
                )}
                onClick={() => handleFormSubmit(BillFormAction.CONFIRM_LATER)}
                label={BillFormConstants.ConfirmScheduleLaterLabel}
              />
            );
            let buttons = [];
            if (!isSubmitting) {
              buttons = [payNowButton(), payLaterButton()];
            } else {
              if (formAction === BillFormAction.CONFIRM_NOW) {
                buttons = [payNowButton()];
              } else if (formAction === BillFormAction.CONFIRM_LATER) {
                buttons = [payLaterButton()];
              }
            }
            return (
              <>
                <Alert alertType="Notification" icon={<InfoCircle />}>
                  {BillFormConstants.PaymentProceedAlert}
                </Alert>
                <p className={styles.ConfirmBillFormSubmissionSectionDisclaimer}>
                  {BillFormConstants.PaymentDoubleCheckNotice}
                </p>
                {buttons.map(button => button)}
              </>
            );
          }

          case BillStatus.Failed:
            return (
              <Button
                type={ButtonType.PRIMARY}
                size={ButtonSize.MEDIUM}
                loading={isSubmitting}
                onClick={() => handleFormSubmit(BillFormAction.PAY_NOW)}
                className={styles.ConfirmBillFormSubmissionSectionButton}
                label={BillFormConstants.RetryPaymentLabel}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
