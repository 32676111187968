import type { UserManagerSettings } from 'oidc-client-ts';

import environments from './environments';

const oidcConfig: UserManagerSettings = {
  client_id: 'prospa_businessaccount_portal',
  redirect_uri: `${window.location.protocol}//${window.location.host}/callback`,
  response_type: 'code',
  scope:
    'openid profile hay.auth.tokenexchange IdentityServerApi phone_number email mobile.api.client sbl.api.read read-credit-lines file-gateway.read',
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
  automaticSilentRenew: true,
  filterProtocolClaims: ['nbf', 'jti', 'auth_time', 'nonce', 'acr', 'azp', 'at_hash'],
  authority: environments.identityServer.authority,
};

export default oidcConfig;
