import { Card, LottieAnimation, Modal } from '@components';

import animationData from '../../../../../assets/animations/brokenLinkAnimation.json';
import styles from './AccountUnsyncConfirmModal.module.scss';
import type { AccountUnsyncConfirmModalProps } from './types';

export const AccountUnsyncConfirmModal = ({
  account,
  isOpen,
  onClose,
  integrationProduct,
  primaryButtonProps,
  loading,
}: AccountUnsyncConfirmModalProps) => (
  <Modal
    size="medium"
    isOpen={isOpen}
    onClose={onClose}
    showCloseModal
    primaryButtonProps={{
      onClick: primaryButtonProps?.onClick,
      label: primaryButtonProps?.label || 'Confirm',
      loading,
      testId: 'account-unsync-confirm-button',
      disabled: loading,
    }}
    secondaryButtonProps={{
      onClick: onClose,
      label: 'Cancel',
      disabled: loading,
    }}
    bodyClassName={styles.AccountUnsyncConfirmModalBody}
    footerClassName={styles.AccountUnsyncConfirmModalFooter}
    body={
      <div className={styles.AccountUnsyncConfirmModal} data-testid="account-unsync-confirm-modal">
        <LottieAnimation animationData={animationData} height={128} width={128} />

        <h4 className={styles.AccountUnsyncConfirmModalHeading}>Switch off sync?</h4>
        <span className="pds-text mb-4">
          We will no longer sync transactions from this account to {integrationProduct}. You can
          switch on sync again for future transactions at any time.
        </span>
        <Card showGreyBackground className={styles.AccountUnsyncConfirmModalCard}>
          <div>
            <p key={`${account.name}-name`} className={styles.AccountUnsyncConfirmModalAccount}>
              {account?.name}
            </p>
          </div>
          <div>
            <p
              key={`${account.description}-description`}
              className={styles.AccountUnsyncConfirmModalDescription}
            >
              {account?.description}
            </p>
          </div>
        </Card>
        <span className={styles.AccountUnsyncConfirmModalNote}>
          Please note, this won’t turn off all data sharing between Prospa and {integrationProduct}.
          To revoke all data permissions, you can disconnect {integrationProduct}.
        </span>
      </div>
    }
  />
);
