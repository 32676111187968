import classNames from 'classnames';

import styles from './PhoneNumber.module.scss';
import type { PhoneNumberProps } from './types';

export function PhoneNumber({ phone, className, ...rest }: PhoneNumberProps) {
  return (
    <a className={classNames(styles.PhoneNumber, className)} href={`tel:${phone}`} {...rest}>
      {phone}
    </a>
  );
}
