import { type ReleasedNewFeatures } from '@components/types';

import { type LegacyEventActions } from '@utils';

import { type ProductTypes } from './Account';

export enum SettingKeyToFeatureFlagsKey {
  BILL_PAY = 'billPay',
  OVERDRAFT = 'overdraft',
  UNDER_MAINTENANCE = 'underMaintenance',
  NAV_BADGE = 'navBadge',
  MOBILE_APP_BANNERS = 'mobileAppBanners',
  PRODUCT_PAGE_ALERT = 'productPageAlert',
  XERO_INTEGRATION = 'xeroIntegration',
  LOC_PRODUCT_PAGE = 'locProductPage',
  NEW_FEATURE = 'newFeature',
  REPAYMENT_CALCULATOR = 'repaymentCalculator',
}

export type FeatureFlags = {
  [SettingKeyToFeatureFlagsKey.BILL_PAY]?: boolean;
  [SettingKeyToFeatureFlagsKey.OVERDRAFT]?: boolean;
  [SettingKeyToFeatureFlagsKey.UNDER_MAINTENANCE]?: TUnderMaintenanceFF;
  [SettingKeyToFeatureFlagsKey.NAV_BADGE]?: TNavBadgeFF;
  [SettingKeyToFeatureFlagsKey.MOBILE_APP_BANNERS]?: TMobileAppBannersFF;
  [SettingKeyToFeatureFlagsKey.PRODUCT_PAGE_ALERT]?: TProductPageAlertFF;
  [SettingKeyToFeatureFlagsKey.XERO_INTEGRATION]?: boolean;
  [SettingKeyToFeatureFlagsKey.LOC_PRODUCT_PAGE]?: boolean;
  /** @deprecated will remove once using braze notifications */
  [SettingKeyToFeatureFlagsKey.NEW_FEATURE]?: TNewFeatureFF;
  [SettingKeyToFeatureFlagsKey.REPAYMENT_CALCULATOR]?: boolean;
};

export type FeatureFlag = FeatureFlags[keyof FeatureFlags] | boolean | string | null;

export type TUnderMaintenanceFF = {
  startTime: string;
  endTime: string;
};

export type TNavBadgeFF = {
  /**
   * A route path. The badge will be shown against this item
   */
  path: string;
  /** ISO date when the badge should be hidden */
  endDate: string;
};

export type TMobileAppBannersFF = {
  startTime: string;
  endTime: string;
};

export type TProductPageAlertFF = {
  buttonText: string;
  messageText: string;
};

/** @deprecated will remove once using braze notifications */
export type TNewFeatureFF = {
  /** eventAction (for tracking) in LD variant */
  eventAction: keyof typeof LegacyEventActions;
  /** featureViewAction (for tracking) in LD variant */
  featureViewAction: keyof typeof LegacyEventActions;
  /** mediaUrl (animation in desktop) in LD variant */
  mediaUrl: string;
  /** mediaUrlMobile (animation in mobile) in LD variant */
  mediaUrlMobile: string;
  /** endDate (ending date) in LD variant */
  endDate: string;
  /** title (modal title) in LD variant */
  title: string;
  /** content (modal body copy) in LD variant */
  content: string;
  /** path (redirection url) in LD variant */
  path: string;
  /** ctaLabel (primary button label) in LD variant */
  ctaLabel: string;
  /** releasedFeature (to identify the notification popup) in LD variant */
  releasedFeature: ReleasedNewFeatures;
  /** targetProducts (identify user products) in LD variant */
  targetProducts: ProductTypes[];
};
