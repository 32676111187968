import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Date: any;
  DateTime: any;
  DateTimeOffset: any;
  Decimal: any;
  Guid: any;
  Long: any;
  Milliseconds: any;
  SByte: any;
  Seconds: any;
  Short: any;
  UInt: any;
  ULong: any;
  UShort: any;
  Uri: any;
};

export type Account = {
  __typename?: 'Account';
  available: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  contractDetails?: Maybe<ContractDetailsType>;
  countryCode?: Maybe<Scalars['String']>;
  daysToWaitLoanClosureAfterFullRepaid?: Maybe<Scalars['Int']>;
  earlyPayoutDetails?: Maybe<EarlyPayoutDetailsType>;
  earlyPayoutFee: Scalars['Decimal'];
  earlyPayoutFeeRate: Scalars['Decimal'];
  earlyPayoutOutstanding: Scalars['Decimal'];
  feesBalance: Scalars['Decimal'];
  feesPaid: Scalars['Decimal'];
  hasMissedPayment: Scalars['Boolean'];
  holdBalance: Scalars['Decimal'];
  id?: Maybe<Scalars['ID']>;
  installmentAmount?: Maybe<Scalars['Decimal']>;
  interestBalance: Scalars['Decimal'];
  interestPaid: Scalars['Decimal'];
  isEarlyPayoutEnabled: Scalars['Boolean'];
  lastDirectDebitDate?: Maybe<Scalars['DateTime']>;
  loanAccountId: Scalars['String'];
  loanAmount: Scalars['Decimal'];
  maskedRepaymentAccountNumber?: Maybe<Scalars['String']>;
  principalPaid: Scalars['Decimal'];
  productName: Scalars['String'];
  repaymentFrequency?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  statements?: Maybe<Statements>;
  status: Scalars['String'];
  termInMonths?: Maybe<Scalars['Int']>;
  totalBalance: Scalars['Decimal'];
  totalDue: Scalars['Decimal'];
  totalMissedAmount: Scalars['Decimal'];
  transactions?: Maybe<Array<Maybe<LoanActivity>>>;
  upcomingRepaymentAmount?: Maybe<Scalars['Decimal']>;
  upcomingRepaymentDate?: Maybe<Scalars['DateTime']>;
  updatedTimeStamp?: Maybe<Scalars['DateTime']>;
};

export type AccountNumberChecksumInput = {
  account: Scalars['String'];
  bank: Scalars['String'];
  branch: Scalars['String'];
  suffix: Scalars['String'];
};

export type AccountNumberChecksumOutput = {
  __typename?: 'AccountNumberChecksumOutput';
  isValid: Scalars['Boolean'];
};

export type AgreementSummaryResponseOutputType = {
  __typename?: 'AgreementSummaryResponseOutputType';
  annualInterestRate: Scalars['Decimal'];
  approvedLoanAmount: Scalars['Decimal'];
  borrower: Scalars['String'];
  disbursalAccount: Scalars['String'];
  disbursementAmount: Scalars['Decimal'];
  isApplicationActive?: Maybe<Scalars['Boolean']>;
  numberOfRepayments: Scalars['Decimal'];
  repaymentAmount: Scalars['Decimal'];
  repaymentFrequencyAmount: Scalars['Decimal'];
  repaymentFrequencyType: Scalars['String'];
  term: Scalars['Decimal'];
  totalInterest: Scalars['Decimal'];
};

export type AppNotification = {
  __typename?: 'AppNotification';
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<AppNotificationDataUnionType>;
  id?: Maybe<Scalars['ID']>;
  isUserDismissible: Scalars['Boolean'];
  notificationType: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<ProductTypes>;
  viewed: Scalars['Boolean'];
};

export type AppNotificationDataUnionType =
  | LocAccountStatementAnnualV1
  | LocAccountStatementMonthlyV1
  | LocAccountSuspendedV1
  | LocMissedPaymentV1
  | LocScheduledPaymentFailedV1
  | LocScheduledPaymentUpcomingV1
  | LocScheduledPaymentUpcomingWithInsufficientFundsV1
  | LocUpcomingPaymentBadStandingV1
  | LocUpcomingPaymentGoodStandingV1
  | SblAccountStatementAnnualV1
  | SblAccountStatementMonthlyV1;

export type ApplicationStatusType = {
  __typename?: 'ApplicationStatusType';
  applicationId?: Maybe<Scalars['String']>;
  status?: Maybe<StatusType>;
};

export type ApprovedApplicationDetails = {
  __typename?: 'ApprovedApplicationDetails';
  approvedFreshCapital?: Maybe<Scalars['String']>;
  approvedLoanAmount?: Maybe<Scalars['String']>;
  isApplicationActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repaymentFrequency?: Maybe<Scalars['String']>;
  termInMonths?: Maybe<Scalars['Int']>;
  totalRefinanceAmount?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountName?: Maybe<Scalars['String']>;
  bSB?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  isPayAnyone: Scalars['Boolean'];
};

export type BlogQuery = {
  __typename?: 'BlogQuery';
  categories?: Maybe<Array<Maybe<Category>>>;
  id?: Maybe<Scalars['ID']>;
  morePosts?: Maybe<PostsResponse>;
};

export type BlogQueryMorePostsArgs = {
  categoryId?: InputMaybe<Scalars['ID']>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type BsbDetails = {
  __typename?: 'BsbDetails';
  bSB: Scalars['String'];
  bSBAddress: Scalars['String'];
  bSBName: Scalars['String'];
  city: Scalars['String'];
  financialInstitutionCode: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  postCode: Scalars['String'];
  state: Scalars['String'];
};

export type BsbQuery = {
  __typename?: 'BsbQuery';
  bsb?: Maybe<BsbDetails>;
  id?: Maybe<Scalars['ID']>;
};

export type BsbQueryBsbArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CancelPayAnyoneInput = {
  applicationId: Scalars['String'];
  id: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type Charge = {
  __typename?: 'Charge';
  name: Scalars['String'];
  paymentAllocationOrder: Scalars['Int'];
  type: Scalars['String'];
  value: Scalars['Decimal'];
};

export type ConfigurationQuery = {
  __typename?: 'ConfigurationQuery';
  forceUpgrade?: Maybe<ForceUpgrade>;
  id?: Maybe<Scalars['ID']>;
};

export type ConfigurationQueryForceUpgradeArgs = {
  platform?: InputMaybe<Scalars['String']>;
  platformVersion?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  cancelled: Scalars['Boolean'];
  expired: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  isDeclined: Scalars['Boolean'];
  isSignedByAll: Scalars['Boolean'];
  signatories?: Maybe<Array<Maybe<Signatory>>>;
  userSigned?: Maybe<Scalars['Boolean']>;
};

export type ContractDetailsType = {
  __typename?: 'ContractDetailsType';
  contractId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum ContractSignStatus {
  Declined = 'Declined',
  NotSigned = 'NotSigned',
  Signed = 'Signed',
}

export type CreditLine = {
  __typename?: 'CreditLine';
  appNotifications?: Maybe<Array<Maybe<AppNotification>>>;
  applicationId: Scalars['String'];
  available: Scalars['Decimal'];
  bPayBillerCode?: Maybe<Scalars['String']>;
  bPayRefCode?: Maybe<Scalars['String']>;
  borrowersAgree: Scalars['Boolean'];
  businessName: Scalars['String'];
  canDrawdown: Scalars['Boolean'];
  contractAgreementAccepted: Scalars['Boolean'];
  contractId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  creditLineId?: Maybe<Scalars['String']>;
  currentPayment?: Maybe<Array<Maybe<Charge>>>;
  dailyPayAnyonePaidAmount: Scalars['Decimal'];
  declined?: Maybe<Scalars['Boolean']>;
  deferralDetails?: Maybe<DeferralDetails>;
  displayStatus?: Maybe<Scalars['String']>;
  dpdAgeLast30Days?: Maybe<Scalars['Decimal']>;
  dpdAgeYtd?: Maybe<Scalars['Decimal']>;
  dpdAgeYtdWithoutDiscount?: Maybe<Scalars['Decimal']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endOfTermChoice?: Maybe<EndOfTermOptions>;
  endOfTermDetails?: Maybe<EndOfTermDetailsType>;
  endOfTermStatus?: Maybe<EndOfTermStatus>;
  extensionContract?: Maybe<ExtensionContract>;
  extensionDetails?: Maybe<ExtensionDetails>;
  extensionStatus?: Maybe<TermExtensionStatus>;
  feesBalance?: Maybe<Scalars['Decimal']>;
  feesDue?: Maybe<Scalars['Decimal']>;
  guarantorsAgree: Scalars['Boolean'];
  hasDrawdown: Scalars['Boolean'];
  hasMissedPayment?: Maybe<Scalars['Boolean']>;
  hasUnreadNotifications?: Maybe<Scalars['Boolean']>;
  holdBalance: Scalars['Decimal'];
  id?: Maybe<Scalars['ID']>;
  interestBalance?: Maybe<Scalars['Decimal']>;
  interestRate: Scalars['Decimal'];
  isApplicant: Scalars['Boolean'];
  isContractExpired: Scalars['Boolean'];
  isDirectDebitPaused: Scalars['Boolean'];
  isGovtScheme: Scalars['Boolean'];
  isLockedForRefinance?: Maybe<Scalars['Boolean']>;
  isNzLoc?: Maybe<Scalars['Boolean']>;
  isRecurringPaymentEnabled?: Maybe<Scalars['Boolean']>;
  lastDirectDebitDate?: Maybe<Scalars['DateTime']>;
  legalEntityType: Scalars['String'];
  lineActivationFee: Scalars['Decimal'];
  /** @deprecated Use new field Transactions */
  lineActivityList?: Maybe<LineActivityList>;
  lmsType?: Maybe<LmsType>;
  maskedAccountNumber: Scalars['String'];
  maturityDate?: Maybe<Scalars['DateTime']>;
  missedPayment?: Maybe<Array<Maybe<Charge>>>;
  missedPaymentDate?: Maybe<Scalars['DateTime']>;
  nearEndOfTerm: Scalars['Boolean'];
  nextPaymentDate?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<Array<Maybe<NotificationType>>>;
  outstandingBalance?: Maybe<Scalars['Decimal']>;
  payAnyone2FAThreshold: Scalars['Decimal'];
  payAnyoneAvailable: Scalars['Decimal'];
  payments?: Maybe<PaymentList>;
  pendingAmount: Scalars['Decimal'];
  postEndOfTerm: Scalars['Boolean'];
  postEndOfTermDueDate?: Maybe<Scalars['DateTime']>;
  principalBalance?: Maybe<Scalars['Decimal']>;
  principalDue?: Maybe<Scalars['Decimal']>;
  principalMinAmount: Scalars['Decimal'];
  principalRate: Scalars['Decimal'];
  repaymentAmount: Scalars['Decimal'];
  signed: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  statements?: Maybe<Statements>;
  status?: Maybe<LineStatusType>;
  statusReason?: Maybe<LineStatusReason>;
  subscriptionFee: Scalars['Decimal'];
  subscriptionFeeFrequency?: Maybe<SubscriptionFeeFrequency>;
  subscriptionFeePercentage?: Maybe<Scalars['Decimal']>;
  total: Scalars['Decimal'];
  totalUpcomingRepaymentAmount?: Maybe<Scalars['Decimal']>;
  transactions?: Maybe<LineActivityList>;
  upcomingPayment?: Maybe<Array<Maybe<Charge>>>;
  used: Scalars['Decimal'];
};

export type CreditLineAppNotificationsArgs = {
  nativeVersion?: InputMaybe<Scalars['String']>;
};

export type CreditLineHasUnreadNotificationsArgs = {
  nativeVersion?: InputMaybe<Scalars['String']>;
};

export type CustomerFeedbackInput = {
  correlationId: Scalars['String'];
  feedback?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  page?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['String']>;
  ratingId?: InputMaybe<Scalars['Int']>;
  source: Scalars['String'];
};

export type CustomerFeedbackOutputType = {
  __typename?: 'CustomerFeedbackOutputType';
  success: Scalars['Boolean'];
};

export type DateOfBirth = {
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type DeferralDetails = {
  __typename?: 'DeferralDetails';
  accruedOutstandingAmount: Scalars['Decimal'];
  deferralAmount?: Maybe<Array<Maybe<Charge>>>;
  deferralInterestAmountDue: Scalars['Decimal'];
  deferralPeriodEndDate?: Maybe<Scalars['DateTime']>;
  deferralPeriodStartDate?: Maybe<Scalars['DateTime']>;
  isAccruedOutstandingAmountDue: Scalars['Boolean'];
  isDeferralInterestPaymentDue: Scalars['Boolean'];
  isOnDeferral: Scalars['Boolean'];
  paymentDueDate?: Maybe<Scalars['DateTime']>;
};

export type DigitalApplicationRefinanceCalculationLoanResponse = {
  __typename?: 'DigitalApplicationRefinanceCalculationLoanResponse';
  annualSimpleInterestRate: Scalars['Decimal'];
  repaymentAmount: Scalars['Decimal'];
  repaymentFrequency: Scalars['String'];
  totalRepaymentAmount: Scalars['Decimal'];
};

export type DigitalApplicationRefinanceCalculatorLoanInput = {
  applicationId: Scalars['String'];
  paymentFrequency: Scalars['String'];
  termInMonths: Scalars['Int'];
};

export type DigitalApplicationTermRangeModel = {
  __typename?: 'DigitalApplicationTermRangeModel';
  factorRateDetailList?: Maybe<Array<Maybe<FactorRateDetailType>>>;
  maxTerm?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
};

export type DrawdownInput = {
  amount: Scalars['Decimal'];
  applicationId: Scalars['String'];
  correlationId: Scalars['String'];
};

export type DrawdownPayAnyoneInput = {
  amount: Scalars['Decimal'];
  applicationId: Scalars['String'];
  bankAccountName: Scalars['String'];
  bankAccountNumber: Scalars['String'];
  bankBsbNumber: Scalars['String'];
  correlationId: Scalars['String'];
  reference?: InputMaybe<Scalars['String']>;
  rememberPayee: Scalars['Boolean'];
  verificationCode?: InputMaybe<Scalars['String']>;
};

export type EarlyPayoutDetailsType = {
  __typename?: 'EarlyPayoutDetailsType';
  disabledReason?: Maybe<Scalars['String']>;
  earlyPayoutFeeRate?: Maybe<Scalars['Decimal']>;
  earlyPayoutStatus?: Maybe<Scalars['String']>;
  hoursToWaitTransfer?: Maybe<Scalars['Int']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type EndOfTermConsentInput = {
  applicationId: Scalars['String'];
  consent: Scalars['Boolean'];
};

export type EndOfTermDetailsType = {
  __typename?: 'EndOfTermDetailsType';
  paymentPlanDetails?: Maybe<EndOfTermPaymentPlanDetailsType>;
  payoutDetails?: Maybe<EndOfTermPayoutDetailsType>;
};

export enum EndOfTermOptions {
  None = 'None',
  PaymentPlan = 'PaymentPlan',
  Payout = 'Payout',
  Refinance = 'Refinance',
}

export type EndOfTermPaymentPlanDetailsType = {
  __typename?: 'EndOfTermPaymentPlanDetailsType';
  firstRepaymentDueDate?: Maybe<Scalars['DateTime']>;
  firstWeekPaymentAmount: Scalars['Decimal'];
  maturityDate?: Maybe<Scalars['DateTime']>;
  numberOfWeeks: Scalars['Int'];
  repaidAmount: Scalars['Decimal'];
};

export type EndOfTermPayoutDetailsType = {
  __typename?: 'EndOfTermPayoutDetailsType';
  feesAmount?: Maybe<Scalars['Decimal']>;
  interestAmount?: Maybe<Scalars['Decimal']>;
  payoutAmount: Scalars['Decimal'];
  payoutAmountDueDate?: Maybe<Scalars['DateTime']>;
  principalAmount?: Maybe<Scalars['Decimal']>;
};

export enum EndOfTermStatus {
  NearEndOfTerm = 'NearEndOfTerm',
  None = 'None',
  PostEndOfTerm = 'PostEndOfTerm',
}

export type ExtensionContract = {
  __typename?: 'ExtensionContract';
  id?: Maybe<Scalars['ID']>;
  isDeclined: Scalars['Boolean'];
  isSignedByAll: Scalars['Boolean'];
  requireAction?: Maybe<Scalars['Boolean']>;
};

export type ExtensionDetails = {
  __typename?: 'ExtensionDetails';
  creditLimit: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  interestRate: Scalars['Float'];
  lineActivationFee: Scalars['Float'];
  loanTermWeeks: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  subscriptionFee?: Maybe<Scalars['Float']>;
  subscriptionFeeFrequency: Scalars['String'];
  subscriptionFeePercentage?: Maybe<Scalars['Float']>;
};

export type FactorRateDetailType = {
  __typename?: 'FactorRateDetailType';
  interestRate?: Maybe<Scalars['Decimal']>;
  term?: Maybe<Scalars['Int']>;
};

export type ForceUpgrade = {
  __typename?: 'ForceUpgrade';
  type: Scalars['String'];
};

export type GenerateContractSignUrlForBorrowerResult = {
  __typename?: 'GenerateContractSignUrlForBorrowerResult';
  signUrl?: Maybe<Scalars['String']>;
};

export type LocAccountStatementAnnualV1 = {
  __typename?: 'LOCAccountStatementAnnualV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LocAccountStatementMonthlyV1 = {
  __typename?: 'LOCAccountStatementMonthlyV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LocAccountSuspendedV1 = {
  __typename?: 'LOCAccountSuspendedV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LocMissedPaymentV1 = {
  __typename?: 'LOCMissedPaymentV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LocScheduledPaymentFailedV1 = {
  __typename?: 'LOCScheduledPaymentFailedV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  failureDate: Scalars['DateTimeOffset'];
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LocScheduledPaymentUpcomingV1 = {
  __typename?: 'LOCScheduledPaymentUpcomingV1';
  amount?: Maybe<Scalars['String']>;
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  date: Scalars['DateTimeOffset'];
  endDate: Scalars['DateTimeOffset'];
  frequency?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTimeOffset'];
  type?: Maybe<Scalars['String']>;
};

export type LocScheduledPaymentUpcomingWithInsufficientFundsV1 = {
  __typename?: 'LOCScheduledPaymentUpcomingWithInsufficientFundsV1';
  amount?: Maybe<Scalars['String']>;
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  date: Scalars['DateTimeOffset'];
  endDate: Scalars['DateTimeOffset'];
  frequency?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTimeOffset'];
  type?: Maybe<Scalars['String']>;
};

export type LocUpcomingPaymentBadStandingV1 = {
  __typename?: 'LOCUpcomingPaymentBadStandingV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LocUpcomingPaymentGoodStandingV1 = {
  __typename?: 'LOCUpcomingPaymentGoodStandingV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Lead = {
  __typename?: 'Lead';
  digitalLeadSource?: Maybe<Scalars['String']>;
  digitalLeadVariant?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  industry?: Maybe<Scalars['String']>;
  industryCategory?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  loanAmountRange?: Maybe<Scalars['String']>;
  loanPurpose?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['Boolean']>;
  timeInBusiness?: Maybe<Scalars['String']>;
  turnoverRange?: Maybe<Scalars['String']>;
};

export type LeadInput = {
  digitalLeadSource: Scalars['String'];
  digitalLeadVariant: Scalars['String'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  industry?: InputMaybe<Scalars['String']>;
  industryCategory?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  loanAmountRange: Scalars['String'];
  loanPurpose: Scalars['String'];
  phoneNumber: Scalars['String'];
  productName?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  termsAndConditions: Scalars['Boolean'];
  timeInBusiness: Scalars['String'];
  turnoverRange?: InputMaybe<Scalars['String']>;
};

export type LineActivity = {
  __typename?: 'LineActivity';
  amount: Scalars['Decimal'];
  bankAccount?: Maybe<BankAccount>;
  correlationId?: Maybe<Scalars['String']>;
  day: Scalars['String'];
  description: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  lineActivityType: Scalars['String'];
  month: Scalars['String'];
  payAnyoneReference?: Maybe<Scalars['String']>;
  requestReceivedDate?: Maybe<Scalars['DateTime']>;
  runningBalance: Scalars['Decimal'];
  subComponents?: Maybe<SubComponents>;
  timestamp?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  transactionKey?: Maybe<Scalars['String']>;
  transactionType: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};

export type LineActivityItem = {
  __typename?: 'LineActivityItem';
  data?: Maybe<LineActivity>;
  id?: Maybe<Scalars['ID']>;
  isEvenRow?: Maybe<Scalars['Boolean']>;
  year?: Maybe<Scalars['Int']>;
};

export type LineActivityList = {
  __typename?: 'LineActivityList';
  itemList?: Maybe<Array<Maybe<LineActivityItem>>>;
  stickyHeaderIndices: Array<Scalars['Int']>;
};

export enum LineStatusReason {
  None = 'None',
  PendingVerbalValidation = 'PendingVerbalValidation',
}

export enum LineStatusType {
  Closed = 'Closed',
  ContractDeclined = 'ContractDeclined',
  ContractExpired = 'ContractExpired',
  ContractPending = 'ContractPending',
  LineActive = 'LineActive',
  LineSuspended = 'LineSuspended',
  LineSuspendedNearMaturity = 'LineSuspendedNearMaturity',
  NotActive = 'NotActive',
  PaymentPlan = 'PaymentPlan',
  SettlementVerbalValidationPending = 'SettlementVerbalValidationPending',
  VerbalValidationPending = 'VerbalValidationPending',
  WrittenOff = 'WrittenOff',
}

export enum LmsType {
  CloudLending = 'CloudLending',
  Mambu = 'Mambu',
}

export type Loan = {
  __typename?: 'Loan';
  amountInArrears?: Maybe<Scalars['Decimal']>;
  appNotifications?: Maybe<Array<Maybe<AppNotification>>>;
  applicationType?: Maybe<Scalars['String']>;
  bPayCode?: Maybe<Scalars['String']>;
  bPayNumber?: Maybe<Scalars['String']>;
  contract?: Maybe<Contract>;
  countryCode?: Maybe<Scalars['String']>;
  deferralPeriodStartDate?: Maybe<Scalars['DateTime']>;
  displayStatus: Scalars['String'];
  dpdAgeLast30Days?: Maybe<Scalars['Decimal']>;
  dpdAgeYTD?: Maybe<Scalars['Decimal']>;
  dpdAgeYTDWithoutDiscount?: Maybe<Scalars['Decimal']>;
  expectedFirstPaymentDate?: Maybe<Scalars['DateTime']>;
  feesRemainingAmount?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['ID']>;
  interestRemainingAmount?: Maybe<Scalars['Decimal']>;
  isGovtScheme: Scalars['Boolean'];
  isLate?: Maybe<Scalars['Boolean']>;
  isOnDeferral?: Maybe<Scalars['Boolean']>;
  loanAccountName: Scalars['String'];
  loanAmount?: Maybe<Scalars['Decimal']>;
  loanMaturityDateCurrent?: Maybe<Scalars['String']>;
  loanStartDate?: Maybe<Scalars['String']>;
  loanStatus: Scalars['String'];
  nextDebitDate?: Maybe<Scalars['DateTime']>;
  nextDueDate?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentAmount?: Maybe<Scalars['Decimal']>;
  paymentFrequency: Scalars['String'];
  principalPaidPercentage?: Maybe<Scalars['Decimal']>;
  principalRemainingAmount?: Maybe<Scalars['Decimal']>;
  refinanceCalculationState?: Maybe<Scalars['String']>;
  rtrPaidPercentage?: Maybe<Scalars['Decimal']>;
  statements?: Maybe<Statements>;
  termInMonth?: Maybe<Scalars['Decimal']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  totalAmountPaid?: Maybe<Scalars['Decimal']>;
  totalOutstandingAmount?: Maybe<Scalars['Decimal']>;
  totalOwedAmount?: Maybe<Scalars['Decimal']>;
  totalPaidAmount?: Maybe<Scalars['Decimal']>;
  transactionList?: Maybe<TaxStatementsListList>;
  transactions?: Maybe<Array<Maybe<SblTransaction>>>;
};

export type LoanAppNotificationsArgs = {
  nativeVersion?: InputMaybe<Scalars['String']>;
};

export type LoanActivity = {
  __typename?: 'LoanActivity';
  description: Scalars['String'];
  displayAmount: Scalars['Decimal'];
  id: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
  transactionDate: Scalars['DateTime'];
  transactionDetails?: Maybe<Array<Maybe<TransactionDetailItemType>>>;
  transactionStatus: Scalars['String'];
  transactionTitle: Scalars['String'];
};

export type LocMutations = {
  __typename?: 'LocMutations';
  consentAndExtend?: Maybe<CreditLine>;
  id?: Maybe<Scalars['ID']>;
};

export type LocMutationsConsentAndExtendArgs = {
  input?: InputMaybe<EndOfTermConsentInput>;
};

export enum NotificationMedium {
  Email = 'Email',
  PushNotification = 'PushNotification',
  Sms = 'Sms',
}

export enum NotificationType {
  AccountSuspended = 'AccountSuspended',
  MissedRepayment = 'MissedRepayment',
  NoPrimaryBank = 'NoPrimaryBank',
  PartialRepayment = 'PartialRepayment',
  UpcomingRepayment = 'UpcomingRepayment',
}

export type NotificationsQuery = {
  __typename?: 'NotificationsQuery';
  appNotifications?: Maybe<Array<Maybe<AppNotification>>>;
  emailPreferences?: Maybe<Array<Maybe<UserPreference>>>;
  id?: Maybe<Scalars['ID']>;
  pushNotificationPreferences?: Maybe<Array<Maybe<UserPreference>>>;
  smsPreferences?: Maybe<Array<Maybe<UserPreference>>>;
};

export type NotificationsQueryAppNotificationsArgs = {
  filters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nativeVersion?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['ID']>;
};

export type NotificationsQueryEmailPreferencesArgs = {
  products?: InputMaybe<Array<InputMaybe<ProductTypes>>>;
};

export type NotificationsQueryPushNotificationPreferencesArgs = {
  products?: InputMaybe<Array<InputMaybe<ProductTypes>>>;
  version?: InputMaybe<Scalars['String']>;
};

export type NotificationsQuerySmsPreferencesArgs = {
  products?: InputMaybe<Array<InputMaybe<ProductTypes>>>;
};

export type PayAnyoneInput = {
  amount: Scalars['Decimal'];
  applicationId: Scalars['String'];
  bank?: InputMaybe<Scalars['String']>;
  bankAccountName: Scalars['String'];
  bankAccountNumber: Scalars['String'];
  bankBsbNumber?: InputMaybe<Scalars['String']>;
  branch?: InputMaybe<Scalars['String']>;
  correlationId: Scalars['String'];
  countryCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  paymentFrequencyType?: InputMaybe<PaymentFrequencyType>;
  reference?: InputMaybe<Scalars['String']>;
  rememberPayee: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Date']>;
  suffix?: InputMaybe<Scalars['String']>;
  verificationCode?: InputMaybe<Scalars['String']>;
};

export type Payee = {
  __typename?: 'Payee';
  bankAccountName: Scalars['String'];
  bankAccountNumber: Scalars['String'];
  bankBsbNumber: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  financialInstitution?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type PayeeAddressBook = {
  __typename?: 'PayeeAddressBook';
  payees?: Maybe<Array<Maybe<Payee>>>;
};

export type PayeesMutations = {
  __typename?: 'PayeesMutations';
  id?: Maybe<Scalars['ID']>;
  removePayee?: Maybe<RemovePayeeResult>;
};

export type PayeesMutationsRemovePayeeArgs = {
  input?: InputMaybe<RemovePayeeInput>;
};

export type PayeesQuery = {
  __typename?: 'PayeesQuery';
  id?: Maybe<Scalars['ID']>;
  payeeAddressBook?: Maybe<PayeeAddressBook>;
};

export type PayeesQueryPayeeAddressBookArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  isMambuServicing?: InputMaybe<Scalars['Boolean']>;
  isNzLoc?: InputMaybe<Scalars['Boolean']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Decimal'];
  bankAccount?: Maybe<BankAccount>;
  day: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  disbursalStatus: Scalars['String'];
  endDate?: Maybe<Scalars['Date']>;
  frequencyType?: Maybe<PaymentFrequencyType>;
  id?: Maybe<Scalars['ID']>;
  month: Scalars['String'];
  nextPaymentDate?: Maybe<Scalars['Date']>;
  recurringPaymentSubtitle?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  status: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transactionNumber: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};

export enum PaymentFrequencyType {
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
  Now = 'Now',
  Once = 'Once',
  Weekly = 'Weekly',
}

export type PaymentItem = {
  __typename?: 'PaymentItem';
  data?: Maybe<Payment>;
  id?: Maybe<Scalars['ID']>;
  isEvenRow?: Maybe<Scalars['Boolean']>;
  year?: Maybe<Scalars['Int']>;
};

export type PaymentList = {
  __typename?: 'PaymentList';
  itemList?: Maybe<Array<Maybe<PaymentItem>>>;
  stickyHeaderIndices: Array<Scalars['Int']>;
};

export type Post = {
  __typename?: 'Post';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  imageMedium?: Maybe<Scalars['String']>;
  imageSmall?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  publishDate?: Maybe<Scalars['String']>;
  timeToRead?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type PostsResponse = {
  __typename?: 'PostsResponse';
  cursor?: Maybe<Scalars['String']>;
  posts?: Maybe<Array<Maybe<Post>>>;
};

export type PreferenceInput = {
  displayNotificationType: Scalars['String'];
  enabled: Scalars['Boolean'];
  notificationMedium?: InputMaybe<NotificationMedium>;
  notificationType: Scalars['String'];
  product?: InputMaybe<ProductTypes>;
};

export enum ProductTypes {
  Loc = 'LOC',
  Partner = 'Partner',
  Sbl = 'SBL',
}

export type PromotionResponseType = {
  __typename?: 'PromotionResponseType';
  isEligible: Scalars['Boolean'];
};

export type ProofOfTransferPayAnyone = {
  __typename?: 'ProofOfTransferPayAnyone';
  url: Scalars['String'];
};

export type ProofOfTransferPayAnyoneInput = {
  applicationId: Scalars['String'];
  transactionKey: Scalars['String'];
};

/** Root query */
export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountNumberChecksum?: Maybe<AccountNumberChecksumOutput>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  agreementSummaryDetails?: Maybe<AgreementSummaryResponseOutputType>;
  approvedDigitalApplication?: Maybe<ApprovedApplicationDetails>;
  blog?: Maybe<BlogQuery>;
  configuration?: Maybe<ConfigurationQuery>;
  creditLine?: Maybe<CreditLine>;
  creditLines?: Maybe<Array<Maybe<CreditLine>>>;
  digitalApplicationPostApprovalCalculation?: Maybe<DigitalApplicationRefinanceCalculationLoanResponse>;
  digitalApplicationTermRange?: Maybe<DigitalApplicationTermRangeModel>;
  eoyPromotion?: Maybe<PromotionResponseType>;
  financials?: Maybe<BsbQuery>;
  lead?: Maybe<Lead>;
  loan?: Maybe<Loan>;
  loanApplications?: Maybe<Array<Maybe<ApplicationStatusType>>>;
  loans?: Maybe<Array<Maybe<Loan>>>;
  notifications?: Maybe<NotificationsQuery>;
  payees?: Maybe<PayeesQuery>;
  refinanceCalculatorPricing?: Maybe<RefinanceCalculatorPricingModel>;
  repaymentSchedules?: Maybe<SblRepaymentSchedulesUrl>;
  sblUserPreference?: Maybe<SblUserPreference>;
  statement?: Maybe<StatementUrl>;
  upcomingRepayment?: Maybe<Array<Maybe<Charge>>>;
};

/** Root query */
export type QueryAccountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Root query */
export type QueryAccountNumberChecksumArgs = {
  input?: InputMaybe<AccountNumberChecksumInput>;
};

/** Root query */
export type QueryAgreementSummaryDetailsArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
};

/** Root query */
export type QueryApprovedDigitalApplicationArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
};

/** Root query */
export type QueryCreditLineArgs = {
  id?: InputMaybe<Scalars['ID']>;
  showAll?: InputMaybe<Scalars['Boolean']>;
};

/** Root query */
export type QueryCreditLinesArgs = {
  showAll?: InputMaybe<Scalars['Boolean']>;
};

/** Root query */
export type QueryDigitalApplicationPostApprovalCalculationArgs = {
  input?: InputMaybe<DigitalApplicationRefinanceCalculatorLoanInput>;
};

/** Root query */
export type QueryDigitalApplicationTermRangeArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
};

/** Root query */
export type QueryLoanArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Root query */
export type QueryLoansArgs = {
  includePending?: InputMaybe<Scalars['Boolean']>;
};

/** Root query */
export type QueryRefinanceCalculatorPricingArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
};

/** Root query */
export type QueryRepaymentSchedulesArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
};

/** Root query */
export type QueryStatementArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  statementType?: InputMaybe<Scalars['String']>;
};

/** Root query */
export type QueryUpcomingRepaymentArgs = {
  additionalAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
};

export type RefinanceCalculationOutputType = {
  __typename?: 'RefinanceCalculationOutputType';
  approvedLoanAmount: Scalars['Decimal'];
  freshCapital: Scalars['Decimal'];
  isEligibleForDigitalApplication: Scalars['Boolean'];
  lastRepaymentDate: Scalars['DateTime'];
  numberOfRepayments: Scalars['Int'];
  paymentFrequency: Scalars['String'];
  repaymentAmount: Scalars['Decimal'];
  resultId: Scalars['String'];
};

export type RefinanceCalculatorInputType = {
  firstDisbursalDate?: InputMaybe<Scalars['DateTime']>;
  paymentFrequency: Scalars['String'];
  requestedLoanAmount: Scalars['Decimal'];
  termInMonths: Scalars['Int'];
};

export type RefinanceCalculatorPricingModel = {
  __typename?: 'RefinanceCalculatorPricingModel';
  factorRateDetailList?: Maybe<Array<Maybe<FactorRateDetailType>>>;
  maxTerm?: Maybe<Scalars['Int']>;
  minTerm?: Maybe<Scalars['Int']>;
  principalToBeRefinanced?: Maybe<Scalars['Decimal']>;
};

export type RefinanceCalculatorSubmissionOutput = {
  __typename?: 'RefinanceCalculatorSubmissionOutput';
  success: Scalars['Boolean'];
};

export type RefinanceSubmissionInputType = {
  resultId: Scalars['String'];
};

export type RegisterDeviceInput = {
  deviceName: Scalars['String'];
  deviceType: Scalars['String'];
  fcmToken: Scalars['String'];
  jsVersion: Scalars['String'];
  nativeVersion: Scalars['String'];
  register: Scalars['Boolean'];
};

export type RemovePayeeInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RemovePayeeResult = {
  __typename?: 'RemovePayeeResult';
  id: Scalars['String'];
};

export type RepaymentInput = {
  amount: Scalars['Decimal'];
  applicationId: Scalars['String'];
  idempotencyKey: Scalars['String'];
};

export type RepaymentOutput = {
  __typename?: 'RepaymentOutput';
  success: Scalars['Boolean'];
};

export type RequestCodeInput = {
  applicationId: Scalars['String'];
  correlationId: Scalars['String'];
};

export type RequestCodeResult = {
  __typename?: 'RequestCodeResult';
  maskedPhoneNumber: Scalars['String'];
};

export type RootMutation = {
  __typename?: 'RootMutation';
  cancelPayAnyone?: Maybe<CreditLine>;
  continueApplicationServicing?: Maybe<RefinanceCalculatorSubmissionOutput>;
  createApplicationContract?: Maybe<RefinanceCalculatorSubmissionOutput>;
  creditLine?: Maybe<LocMutations>;
  drawdown?: Maybe<CreditLine>;
  /** @deprecated use the new mutation payAnyone */
  drawdownPayAnyone?: Maybe<CreditLine>;
  drawdownRequestCode?: Maybe<RequestCodeResult>;
  makeRepayment?: Maybe<RepaymentOutput>;
  notifications?: Maybe<UserNotificationMutations>;
  payAnyone?: Maybe<CreditLine>;
  payees?: Maybe<PayeesMutations>;
  postApprovalSubmitCallback?: Maybe<SubmitCallbackSubmissionOutput>;
  proofOfTransferPayAnyone?: Maybe<ProofOfTransferPayAnyone>;
  saveApplicationReCalculation?: Maybe<RefinanceCalculatorSubmissionOutput>;
  saveUserPreference?: Maybe<SblUserPreferenceOutputType>;
  signContract?: Maybe<GenerateContractSignUrlForBorrowerResult>;
  signLocContract?: Maybe<SignContractAgreementResultType>;
  submitCallbackRequest?: Maybe<RefinanceCalculatorSubmissionOutput>;
  submitCustomerFeedbackRequest?: Maybe<CustomerFeedbackOutputType>;
  submitEndOfTermChoice?: Maybe<CreditLine>;
  submitEnquiryRequest?: Maybe<RefinanceCalculatorSubmissionOutput>;
  submitLead?: Maybe<Lead>;
  submitRefinanceCalculation?: Maybe<RefinanceCalculationOutputType>;
  userDevices?: Maybe<UserDevicesMutations>;
};

export type RootMutationCancelPayAnyoneArgs = {
  input?: InputMaybe<CancelPayAnyoneInput>;
};

export type RootMutationContinueApplicationServicingArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
};

export type RootMutationCreateApplicationContractArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
};

export type RootMutationDrawdownArgs = {
  input?: InputMaybe<DrawdownInput>;
};

export type RootMutationDrawdownPayAnyoneArgs = {
  input?: InputMaybe<DrawdownPayAnyoneInput>;
};

export type RootMutationDrawdownRequestCodeArgs = {
  input?: InputMaybe<RequestCodeInput>;
};

export type RootMutationMakeRepaymentArgs = {
  input?: InputMaybe<RepaymentInput>;
};

export type RootMutationPayAnyoneArgs = {
  input?: InputMaybe<PayAnyoneInput>;
};

export type RootMutationPostApprovalSubmitCallbackArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<SubmitCallbackInput>;
};

export type RootMutationProofOfTransferPayAnyoneArgs = {
  input?: InputMaybe<ProofOfTransferPayAnyoneInput>;
};

export type RootMutationSaveApplicationReCalculationArgs = {
  input?: InputMaybe<DigitalApplicationRefinanceCalculatorLoanInput>;
};

export type RootMutationSaveUserPreferenceArgs = {
  input?: InputMaybe<SblUserPreferenceInput>;
};

export type RootMutationSignContractArgs = {
  input?: InputMaybe<SignContractInput>;
};

export type RootMutationSignLocContractArgs = {
  input?: InputMaybe<ValidatedSignInput>;
};

export type RootMutationSubmitCallbackRequestArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<RefinanceSubmissionInputType>;
};

export type RootMutationSubmitCustomerFeedbackRequestArgs = {
  input?: InputMaybe<CustomerFeedbackInput>;
};

export type RootMutationSubmitEndOfTermChoiceArgs = {
  input?: InputMaybe<SubmitEndOfTermInput>;
};

export type RootMutationSubmitEnquiryRequestArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<RefinanceSubmissionInputType>;
};

export type RootMutationSubmitLeadArgs = {
  input?: InputMaybe<LeadInput>;
};

export type RootMutationSubmitRefinanceCalculationArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<RefinanceCalculatorInputType>;
};

export type SblAccountStatementAnnualV1 = {
  __typename?: 'SBLAccountStatementAnnualV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SblAccountStatementMonthlyV1 = {
  __typename?: 'SBLAccountStatementMonthlyV1';
  dataSchemaAndVersion?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SblTransaction = {
  __typename?: 'SBLTransaction';
  addAmount?: Maybe<Scalars['Boolean']>;
  contractBalance?: Maybe<Scalars['Decimal']>;
  contractBalanceFormatted: Scalars['String'];
  contractPayment?: Maybe<Scalars['Decimal']>;
  contractPaymentFormatted: Scalars['String'];
  day?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  disbursement?: Maybe<Scalars['Decimal']>;
  displayAmount?: Maybe<Scalars['Decimal']>;
  fee?: Maybe<Scalars['Decimal']>;
  feeFormatted: Scalars['String'];
  feePaid?: Maybe<Scalars['Decimal']>;
  feePaidFormatted: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  interest?: Maybe<Scalars['Decimal']>;
  interestFees?: Maybe<Scalars['Decimal']>;
  interestFormatted: Scalars['String'];
  month?: Maybe<Scalars['String']>;
  otherTxnAmount?: Maybe<Scalars['Decimal']>;
  otherTxnAmountFormatted: Scalars['String'];
  pendingPayment?: Maybe<Scalars['Decimal']>;
  principal?: Maybe<Scalars['Decimal']>;
  principalFormatted: Scalars['String'];
  subtractAmount: Scalars['Boolean'];
  transactionDate: Scalars['String'];
  transactionType: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};

export type SblRepaymentSchedulesUrl = {
  __typename?: 'SblRepaymentSchedulesUrl';
  error?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SblUserPreference = {
  __typename?: 'SblUserPreference';
  hideRefiCard: Scalars['Boolean'];
};

export type SblUserPreferenceInput = {
  preferenceKey: Scalars['String'];
  preferenceValue: Scalars['String'];
};

export type SblUserPreferenceOutputType = {
  __typename?: 'SblUserPreferenceOutputType';
  success: Scalars['Boolean'];
};

export type SignContractAgreementResultType = {
  __typename?: 'SignContractAgreementResultType';
  partyContractSignStatus?: Maybe<ContractSignStatus>;
  signUrl?: Maybe<Scalars['String']>;
};

export type SignContractInput = {
  contractId: Scalars['String'];
  dateOfBirth?: InputMaybe<DateOfBirth>;
  returnUrl: Scalars['String'];
};

export type Signatory = {
  __typename?: 'Signatory';
  userId: Scalars['String'];
};

export type Statement = {
  __typename?: 'Statement';
  applicationId: Scalars['String'];
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  title: Scalars['String'];
};

export type StatementUrl = {
  __typename?: 'StatementUrl';
  url: Scalars['String'];
};

export type Statements = {
  __typename?: 'Statements';
  annually?: Maybe<Array<Maybe<Statement>>>;
  monthly?: Maybe<Array<Maybe<Statement>>>;
};

export type StatusType = {
  __typename?: 'StatusType';
  decision?: Maybe<Scalars['String']>;
  decisionDate?: Maybe<Scalars['DateTime']>;
  rejectedReason?: Maybe<Scalars['String']>;
};

export type SubComponents = {
  __typename?: 'SubComponents';
  dishonorFee?: Maybe<Scalars['Decimal']>;
  excess: Scalars['Decimal'];
  fees: Scalars['Decimal'];
  interest: Scalars['Decimal'];
  penalty?: Maybe<Scalars['Decimal']>;
  principal: Scalars['Decimal'];
  weeklyServiceFee?: Maybe<Scalars['Decimal']>;
};

export type SubmitCallbackInput = {
  enquiryReason: Scalars['String'];
};

export type SubmitCallbackSubmissionOutput = {
  __typename?: 'SubmitCallbackSubmissionOutput';
  success: Scalars['Boolean'];
};

export type SubmitEndOfTermInput = {
  applicationId: Scalars['String'];
  correlationId: Scalars['String'];
  endOfTermChoice?: InputMaybe<EndOfTermOptions>;
  payoutAmount?: InputMaybe<Scalars['Decimal']>;
  requestedDifferentAmount?: InputMaybe<Scalars['Boolean']>;
};

export enum SubscriptionFeeFrequency {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export type TaxStatementsListItem = {
  __typename?: 'TaxStatementsListItem';
  data?: Maybe<SblTransaction>;
  id?: Maybe<Scalars['ID']>;
  isEvenRow?: Maybe<Scalars['Boolean']>;
  year?: Maybe<Scalars['Int']>;
};

export type TaxStatementsListList = {
  __typename?: 'TaxStatementsListList';
  itemList?: Maybe<Array<Maybe<TaxStatementsListItem>>>;
  stickyHeaderIndices: Array<Scalars['Int']>;
};

export enum TermExtensionStatus {
  Approved = 'Approved',
  InProgress = 'InProgress',
  Referred = 'Referred',
}

export type TransactionDetailItemType = {
  __typename?: 'TransactionDetailItemType';
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UnregisterDeviceInput = {
  deviceName: Scalars['String'];
  deviceType: Scalars['String'];
  fcmToken: Scalars['String'];
  jsVersion: Scalars['String'];
  nativeVersion: Scalars['String'];
};

export type UpdateAppNotificationInput = {
  dismissed?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  viewed?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAppNotificationsInput = {
  appNotifications?: InputMaybe<Array<InputMaybe<UpdateAppNotificationInput>>>;
  nativeVersion: Scalars['String'];
};

export type UpdateUserPreferencesInput = {
  preferences?: InputMaybe<Array<InputMaybe<PreferenceInput>>>;
};

export type UpdateUserPreferencesResult = {
  __typename?: 'UpdateUserPreferencesResult';
  preferences?: Maybe<Array<Maybe<UserPreference>>>;
};

export type UserDevicesMutations = {
  __typename?: 'UserDevicesMutations';
  id?: Maybe<Scalars['ID']>;
  registerDevice?: Maybe<Scalars['Boolean']>;
  unRegisterDevice?: Maybe<Scalars['Boolean']>;
};

export type UserDevicesMutationsRegisterDeviceArgs = {
  input?: InputMaybe<RegisterDeviceInput>;
};

export type UserDevicesMutationsUnRegisterDeviceArgs = {
  input?: InputMaybe<UnregisterDeviceInput>;
};

export type UserNotificationMutations = {
  __typename?: 'UserNotificationMutations';
  id?: Maybe<Scalars['ID']>;
  updateAppNotifications?: Maybe<Array<Maybe<AppNotification>>>;
  updateUserPreferences?: Maybe<UpdateUserPreferencesResult>;
};

export type UserNotificationMutationsUpdateAppNotificationsArgs = {
  input?: InputMaybe<UpdateAppNotificationsInput>;
};

export type UserNotificationMutationsUpdateUserPreferencesArgs = {
  input?: InputMaybe<UpdateUserPreferencesInput>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  displayNotificationType: Scalars['String'];
  enabled: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  notificationMedium?: Maybe<NotificationMedium>;
  notificationType: Scalars['String'];
  product?: Maybe<ProductTypes>;
};

export type ValidatedSignInput = {
  applicationId: Scalars['String'];
  contractId?: InputMaybe<Scalars['String']>;
  correlationId: Scalars['String'];
  dateOfBirth?: InputMaybe<DateOfBirth>;
  isExtensionContract?: InputMaybe<Scalars['Boolean']>;
  returnUrl?: InputMaybe<Scalars['String']>;
};

export type RequestRepaymentMutationVariables = Exact<{
  input: RepaymentInput;
}>;

export type RequestRepaymentMutation = {
  __typename?: 'RootMutation';
  makeRepayment?: { __typename?: 'RepaymentOutput'; success: boolean } | null;
};

export type UpdateUserPreferenceMutationVariables = Exact<{
  input: UpdateUserPreferencesInput;
}>;

export type UpdateUserPreferenceMutation = {
  __typename?: 'RootMutation';
  notifications?: {
    __typename?: 'UserNotificationMutations';
    updateUserPreferences?: {
      __typename?: 'UpdateUserPreferencesResult';
      preferences?: Array<{ __typename?: 'UserPreference'; id?: string | null } | null> | null;
    } | null;
  } | null;
};

export type AccountDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AccountDetailsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id?: string | null;
    productName: string;
    countryCode?: string | null;
    available: any;
    balance: any;
    totalBalance: any;
    principalPaid: any;
    interestPaid: any;
    feesPaid: any;
    loanAmount: any;
    status: string;
    startDate?: any | null;
    termInMonths?: number | null;
    repaymentFrequency?: string | null;
    installmentAmount?: any | null;
    lastDirectDebitDate?: any | null;
    upcomingRepaymentDate?: any | null;
    upcomingRepaymentAmount?: any | null;
    updatedTimeStamp?: any | null;
    totalMissedAmount: any;
    hasMissedPayment: boolean;
    isEarlyPayoutEnabled: boolean;
    daysToWaitLoanClosureAfterFullRepaid?: number | null;
    earlyPayoutDetails?: {
      __typename?: 'EarlyPayoutDetailsType';
      earlyPayoutStatus?: string | null;
      hoursToWaitTransfer?: number | null;
    } | null;
  } | null;
};

export type AccountRepaymentDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AccountRepaymentDetailsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id?: string | null;
    status: string;
    totalBalance: any;
    balance: any;
    maskedRepaymentAccountNumber?: string | null;
    totalMissedAmount: any;
    hasMissedPayment: boolean;
    isEarlyPayoutEnabled: boolean;
    feesBalance: any;
    interestBalance: any;
    earlyPayoutFee: any;
    earlyPayoutFeeRate: any;
    earlyPayoutOutstanding: any;
    earlyPayoutDetails?: {
      __typename?: 'EarlyPayoutDetailsType';
      hoursToWaitTransfer?: number | null;
      isDisabled?: boolean | null;
      disabledReason?: string | null;
    } | null;
  } | null;
};

export type LoadStatementQueryVariables = Exact<{
  applicationId: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  statementType: Scalars['String'];
}>;

export type LoadStatementQuery = {
  __typename?: 'Query';
  statement?: { __typename?: 'StatementUrl'; url: string } | null;
};

export type LoanActivitiesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LoanActivitiesQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    transactions?: Array<{
      __typename?: 'LoanActivity';
      id: string;
      transactionDate: any;
      transactionTitle: string;
      description: string;
      displayAmount: any;
      transactionStatus: string;
      reference?: string | null;
      transactionDetails?: Array<{
        __typename?: 'TransactionDetailItemType';
        title?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetLoanApplicationsIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoanApplicationsIdsQuery = {
  __typename?: 'Query';
  loanApplications?: Array<{
    __typename?: 'ApplicationStatusType';
    applicationId?: string | null;
    status?: {
      __typename?: 'StatusType';
      decision?: string | null;
      decisionDate?: any | null;
    } | null;
  } | null> | null;
};

export type LoanRepaymentDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LoanRepaymentDetailsQuery = {
  __typename?: 'Query';
  loan?: {
    __typename?: 'Loan';
    id?: string | null;
    paymentAmount?: any | null;
    paymentFrequency: string;
    nextDebitDate?: any | null;
    totalOutstandingAmount?: any | null;
    totalAmountPaid?: any | null;
    loanStartDate?: string | null;
    termInMonth?: any | null;
    loanAmount?: any | null;
    timestamp?: any | null;
    principalPaidPercentage?: any | null;
    rtrPaidPercentage?: any | null;
  } | null;
};

export type LoanSummaryQueryVariables = Exact<{
  showAll: Scalars['Boolean'];
}>;

export type LoanSummaryQuery = {
  __typename?: 'Query';
  loans?: Array<{
    __typename?: 'Loan';
    loanAmount?: any | null;
    id?: string | null;
    totalOutstandingAmount?: any | null;
    displayStatus: string;
    isLate?: boolean | null;
    dpdAgeYTD?: any | null;
    amountInArrears?: any | null;
    loanStartDate?: string | null;
    refinanceCalculationState?: string | null;
    countryCode?: string | null;
    contract?: {
      __typename?: 'Contract';
      id?: string | null;
      cancelled: boolean;
      expired: boolean;
      isDeclined: boolean;
      isSignedByAll: boolean;
      userSigned?: boolean | null;
    } | null;
  } | null> | null;
  creditLines?: Array<{
    __typename?: 'CreditLine';
    available: any;
    id?: string | null;
    total: any;
    status?: LineStatusType | null;
    startDate?: any | null;
    countryCode?: string | null;
    isApplicant: boolean;
    signed: boolean;
    statusReason?: LineStatusReason | null;
  } | null> | null;
  accounts?: Array<{
    __typename?: 'Account';
    loanAccountId: string;
    available: any;
    balance: any;
    productName: string;
    loanAmount: any;
    status: string;
    startDate?: any | null;
    totalMissedAmount: any;
    hasMissedPayment: boolean;
    countryCode?: string | null;
    contractDetails?: {
      __typename?: 'ContractDetailsType';
      contractId?: string | null;
      status?: string | null;
    } | null;
  } | null> | null;
  loanApplications?: Array<{
    __typename?: 'ApplicationStatusType';
    applicationId?: string | null;
    status?: {
      __typename?: 'StatusType';
      decision?: string | null;
      decisionDate?: any | null;
      rejectedReason?: string | null;
    } | null;
  } | null> | null;
};

export type LoadLoanTransactionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LoadLoanTransactionsQuery = {
  __typename?: 'Query';
  loan?: {
    __typename?: 'Loan';
    id?: string | null;
    contract?: { __typename?: 'Contract'; id?: string | null; userSigned?: boolean | null } | null;
    transactionList?: {
      __typename?: 'TaxStatementsListList';
      itemList?: Array<{
        __typename?: 'TaxStatementsListItem';
        id?: string | null;
        isEvenRow?: boolean | null;
        year?: number | null;
        data?: {
          __typename?: 'SBLTransaction';
          transactionDate: string;
          transactionType: string;
          description: string;
          contractBalance?: any | null;
          displayAmount?: any | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type TransactionListFragment = {
  __typename?: 'Loan';
  id?: string | null;
  transactionList?: {
    __typename?: 'TaxStatementsListList';
    itemList?: Array<{
      __typename?: 'TaxStatementsListItem';
      id?: string | null;
      isEvenRow?: boolean | null;
      year?: number | null;
      data?: {
        __typename?: 'SBLTransaction';
        transactionDate: string;
        transactionType: string;
        description: string;
        contractBalance?: any | null;
        displayAmount?: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetLocQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetLocQuery = {
  __typename?: 'Query';
  creditLine?: {
    __typename?: 'CreditLine';
    id?: string | null;
    total: any;
    available: any;
    used: any;
    pendingAmount: any;
    startDate?: any | null;
    countryCode?: string | null;
    endDate?: any | null;
    totalUpcomingRepaymentAmount?: any | null;
    canDrawdown: boolean;
    status?: LineStatusType | null;
    nextPaymentDate?: any | null;
    subscriptionFeeFrequency?: SubscriptionFeeFrequency | null;
    isLockedForRefinance?: boolean | null;
    outstandingBalance?: any | null;
    subscriptionFeePercentage?: any | null;
    upcomingPayment?: Array<{
      __typename?: 'Charge';
      name: string;
      value: any;
      paymentAllocationOrder: number;
      type: string;
    } | null> | null;
    currentPayment?: Array<{
      __typename?: 'Charge';
      name: string;
      type: string;
      value: any;
      paymentAllocationOrder: number;
    } | null> | null;
    deferralDetails?: {
      __typename?: 'DeferralDetails';
      isOnDeferral: boolean;
      deferralPeriodEndDate?: any | null;
    } | null;
    endOfTermDetails?: {
      __typename?: 'EndOfTermDetailsType';
      paymentPlanDetails?: {
        __typename?: 'EndOfTermPaymentPlanDetailsType';
        numberOfWeeks: number;
        firstWeekPaymentAmount: any;
        repaidAmount: any;
        maturityDate?: any | null;
      } | null;
      payoutDetails?: {
        __typename?: 'EndOfTermPayoutDetailsType';
        payoutAmount: any;
        payoutAmountDueDate?: any | null;
      } | null;
    } | null;
  } | null;
};

export type GetLocClRepaymentDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetLocClRepaymentDetailsQuery = {
  __typename?: 'Query';
  creditLine?: {
    __typename?: 'CreditLine';
    id?: string | null;
    bPayBillerCode?: string | null;
    bPayRefCode?: string | null;
  } | null;
};

export type GetLocMRepaymentDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetLocMRepaymentDetailsQuery = {
  __typename?: 'Query';
  creditLine?: {
    __typename?: 'CreditLine';
    id?: string | null;
    maskedAccountNumber: string;
    outstandingBalance?: any | null;
  } | null;
};

export type GetLocPaymentsAndTransactionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetLocPaymentsAndTransactionsQuery = {
  __typename?: 'Query';
  creditLine?: {
    __typename?: 'CreditLine';
    id?: string | null;
    payments?: {
      __typename?: 'PaymentList';
      stickyHeaderIndices: Array<number>;
      itemList?: Array<{
        __typename?: 'PaymentItem';
        id?: string | null;
        isEvenRow?: boolean | null;
        year?: number | null;
        data?: {
          __typename?: 'Payment';
          id?: string | null;
          title?: string | null;
          subTitle?: string | null;
          recurringPaymentSubtitle?: string | null;
          description?: string | null;
          amount: any;
          transactionNumber: string;
          startDate?: any | null;
          endDate?: any | null;
          day: string;
          month: string;
          year?: number | null;
          frequencyType?: PaymentFrequencyType | null;
          nextPaymentDate?: any | null;
          bankAccount?: {
            __typename?: 'BankAccount';
            isPayAnyone: boolean;
            bSB?: string | null;
            bankName?: string | null;
            bankAccountNumber?: string | null;
            accountName?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    transactions?: {
      __typename?: 'LineActivityList';
      stickyHeaderIndices: Array<number>;
      itemList?: Array<{
        __typename?: 'LineActivityItem';
        id?: string | null;
        isEvenRow?: boolean | null;
        year?: number | null;
        data?: {
          __typename?: 'LineActivity';
          id?: string | null;
          transactionKey?: string | null;
          title: string;
          description: string;
          amount: any;
          lineActivityType: string;
          timestamp?: any | null;
          requestReceivedDate?: any | null;
          payAnyoneReference?: string | null;
          subComponents?: {
            __typename?: 'SubComponents';
            principal: any;
            interest: any;
            fees: any;
            excess: any;
            weeklyServiceFee?: any | null;
            dishonorFee?: any | null;
            penalty?: any | null;
          } | null;
          bankAccount?: {
            __typename?: 'BankAccount';
            isPayAnyone: boolean;
            bSB?: string | null;
            bankName?: string | null;
            bankAccountNumber?: string | null;
            accountName?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type NotificationsPreferencesQueryVariables = Exact<{
  products?: InputMaybe<Array<InputMaybe<ProductTypes>> | InputMaybe<ProductTypes>>;
}>;

export type NotificationsPreferencesQuery = {
  __typename?: 'Query';
  notifications?: {
    __typename?: 'NotificationsQuery';
    emailPreferences?: Array<{
      __typename?: 'UserPreference';
      id?: string | null;
      notificationType: string;
      product?: ProductTypes | null;
      enabled: boolean;
      notificationMedium?: NotificationMedium | null;
      displayNotificationType: string;
    } | null> | null;
    smsPreferences?: Array<{
      __typename?: 'UserPreference';
      id?: string | null;
      notificationType: string;
      product?: ProductTypes | null;
      enabled: boolean;
      notificationMedium?: NotificationMedium | null;
      displayNotificationType: string;
    } | null> | null;
  } | null;
};

export type LoadAccountStatementsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LoadAccountStatementsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    statements?: {
      __typename?: 'Statements';
      monthly?: Array<{
        __typename?: 'Statement';
        id: string;
        title: string;
        applicationId: string;
        startDate?: any | null;
        endDate?: any | null;
      } | null> | null;
      annually?: Array<{
        __typename?: 'Statement';
        id: string;
        title: string;
        applicationId: string;
        startDate?: any | null;
        endDate?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export const TransactionListFragmentDoc = gql`
  fragment TransactionList on Loan {
    id
    transactionList {
      itemList {
        id
        isEvenRow
        year
        data {
          transactionDate
          transactionType
          description
          contractBalance
          displayAmount
        }
      }
    }
  }
`;
export const RequestRepaymentDocument = gql`
  mutation requestRepayment($input: RepaymentInput!) {
    makeRepayment(input: $input) {
      success
    }
  }
`;
export type RequestRepaymentMutationFn = Apollo.MutationFunction<
  RequestRepaymentMutation,
  RequestRepaymentMutationVariables
>;

/**
 * __useRequestRepaymentMutation__
 *
 * To run a mutation, you first call `useRequestRepaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRepaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRepaymentMutation, { data, loading, error }] = useRequestRepaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestRepaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestRepaymentMutation,
    RequestRepaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestRepaymentMutation, RequestRepaymentMutationVariables>(
    RequestRepaymentDocument,
    options,
  );
}
export type RequestRepaymentMutationHookResult = ReturnType<typeof useRequestRepaymentMutation>;
export type RequestRepaymentMutationResult = Apollo.MutationResult<RequestRepaymentMutation>;
export type RequestRepaymentMutationOptions = Apollo.BaseMutationOptions<
  RequestRepaymentMutation,
  RequestRepaymentMutationVariables
>;
export const UpdateUserPreferenceDocument = gql`
  mutation updateUserPreference($input: UpdateUserPreferencesInput!) {
    notifications {
      updateUserPreferences(input: $input) {
        preferences {
          id
        }
      }
    }
  }
`;
export type UpdateUserPreferenceMutationFn = Apollo.MutationFunction<
  UpdateUserPreferenceMutation,
  UpdateUserPreferenceMutationVariables
>;

/**
 * __useUpdateUserPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferenceMutation, { data, loading, error }] = useUpdateUserPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPreferenceMutation,
    UpdateUserPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserPreferenceMutation, UpdateUserPreferenceMutationVariables>(
    UpdateUserPreferenceDocument,
    options,
  );
}
export type UpdateUserPreferenceMutationHookResult = ReturnType<
  typeof useUpdateUserPreferenceMutation
>;
export type UpdateUserPreferenceMutationResult =
  Apollo.MutationResult<UpdateUserPreferenceMutation>;
export type UpdateUserPreferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPreferenceMutation,
  UpdateUserPreferenceMutationVariables
>;
export const AccountDetailsDocument = gql`
  query AccountDetails($id: ID!) {
    account(id: $id) {
      id
      productName
      countryCode
      available
      balance
      totalBalance
      principalPaid
      interestPaid
      feesPaid
      loanAmount
      status
      startDate
      termInMonths
      repaymentFrequency
      installmentAmount
      lastDirectDebitDate
      upcomingRepaymentDate
      upcomingRepaymentAmount
      updatedTimeStamp
      totalMissedAmount
      hasMissedPayment
      isEarlyPayoutEnabled
      daysToWaitLoanClosureAfterFullRepaid
      earlyPayoutDetails {
        earlyPayoutStatus
        hoursToWaitTransfer
      }
    }
  }
`;

/**
 * __useAccountDetailsQuery__
 *
 * To run a query within a React component, call `useAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(
    AccountDetailsDocument,
    options,
  );
}
export function useAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(
    AccountDetailsDocument,
    options,
  );
}
export type AccountDetailsQueryHookResult = ReturnType<typeof useAccountDetailsQuery>;
export type AccountDetailsLazyQueryHookResult = ReturnType<typeof useAccountDetailsLazyQuery>;
export type AccountDetailsQueryResult = Apollo.QueryResult<
  AccountDetailsQuery,
  AccountDetailsQueryVariables
>;
export const AccountRepaymentDetailsDocument = gql`
  query AccountRepaymentDetails($id: ID!) {
    account(id: $id) {
      id
      status
      totalBalance
      balance
      maskedRepaymentAccountNumber
      totalMissedAmount
      hasMissedPayment
      isEarlyPayoutEnabled
      feesBalance
      interestBalance
      earlyPayoutFee
      earlyPayoutFeeRate
      earlyPayoutOutstanding
      earlyPayoutDetails {
        hoursToWaitTransfer
        isDisabled
        disabledReason
      }
    }
  }
`;

/**
 * __useAccountRepaymentDetailsQuery__
 *
 * To run a query within a React component, call `useAccountRepaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountRepaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountRepaymentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountRepaymentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountRepaymentDetailsQuery,
    AccountRepaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountRepaymentDetailsQuery, AccountRepaymentDetailsQueryVariables>(
    AccountRepaymentDetailsDocument,
    options,
  );
}
export function useAccountRepaymentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountRepaymentDetailsQuery,
    AccountRepaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountRepaymentDetailsQuery, AccountRepaymentDetailsQueryVariables>(
    AccountRepaymentDetailsDocument,
    options,
  );
}
export type AccountRepaymentDetailsQueryHookResult = ReturnType<
  typeof useAccountRepaymentDetailsQuery
>;
export type AccountRepaymentDetailsLazyQueryHookResult = ReturnType<
  typeof useAccountRepaymentDetailsLazyQuery
>;
export type AccountRepaymentDetailsQueryResult = Apollo.QueryResult<
  AccountRepaymentDetailsQuery,
  AccountRepaymentDetailsQueryVariables
>;
export const LoadStatementDocument = gql`
  query loadStatement(
    $applicationId: String!
    $startDate: Date
    $endDate: Date
    $statementType: String!
  ) {
    statement(
      applicationId: $applicationId
      startDate: $startDate
      endDate: $endDate
      statementType: $statementType
    ) {
      url
    }
  }
`;

/**
 * __useLoadStatementQuery__
 *
 * To run a query within a React component, call `useLoadStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadStatementQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      statementType: // value for 'statementType'
 *   },
 * });
 */
export function useLoadStatementQuery(
  baseOptions: Apollo.QueryHookOptions<LoadStatementQuery, LoadStatementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadStatementQuery, LoadStatementQueryVariables>(
    LoadStatementDocument,
    options,
  );
}
export function useLoadStatementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadStatementQuery, LoadStatementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadStatementQuery, LoadStatementQueryVariables>(
    LoadStatementDocument,
    options,
  );
}
export type LoadStatementQueryHookResult = ReturnType<typeof useLoadStatementQuery>;
export type LoadStatementLazyQueryHookResult = ReturnType<typeof useLoadStatementLazyQuery>;
export type LoadStatementQueryResult = Apollo.QueryResult<
  LoadStatementQuery,
  LoadStatementQueryVariables
>;
export const LoanActivitiesDocument = gql`
  query LoanActivities($id: ID!) {
    account(id: $id) {
      transactions {
        id
        transactionDate
        transactionTitle
        description
        displayAmount
        transactionStatus
        transactionDetails {
          title
          value
        }
        reference
      }
    }
  }
`;

/**
 * __useLoanActivitiesQuery__
 *
 * To run a query within a React component, call `useLoanActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanActivitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoanActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<LoanActivitiesQuery, LoanActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoanActivitiesQuery, LoanActivitiesQueryVariables>(
    LoanActivitiesDocument,
    options,
  );
}
export function useLoanActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoanActivitiesQuery, LoanActivitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoanActivitiesQuery, LoanActivitiesQueryVariables>(
    LoanActivitiesDocument,
    options,
  );
}
export type LoanActivitiesQueryHookResult = ReturnType<typeof useLoanActivitiesQuery>;
export type LoanActivitiesLazyQueryHookResult = ReturnType<typeof useLoanActivitiesLazyQuery>;
export type LoanActivitiesQueryResult = Apollo.QueryResult<
  LoanActivitiesQuery,
  LoanActivitiesQueryVariables
>;
export const GetLoanApplicationsIdsDocument = gql`
  query getLoanApplicationsIds {
    loanApplications {
      applicationId
      status {
        decision
        decisionDate
      }
    }
  }
`;

/**
 * __useGetLoanApplicationsIdsQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationsIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanApplicationsIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLoanApplicationsIdsQuery,
    GetLoanApplicationsIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoanApplicationsIdsQuery, GetLoanApplicationsIdsQueryVariables>(
    GetLoanApplicationsIdsDocument,
    options,
  );
}
export function useGetLoanApplicationsIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanApplicationsIdsQuery,
    GetLoanApplicationsIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoanApplicationsIdsQuery, GetLoanApplicationsIdsQueryVariables>(
    GetLoanApplicationsIdsDocument,
    options,
  );
}
export type GetLoanApplicationsIdsQueryHookResult = ReturnType<
  typeof useGetLoanApplicationsIdsQuery
>;
export type GetLoanApplicationsIdsLazyQueryHookResult = ReturnType<
  typeof useGetLoanApplicationsIdsLazyQuery
>;
export type GetLoanApplicationsIdsQueryResult = Apollo.QueryResult<
  GetLoanApplicationsIdsQuery,
  GetLoanApplicationsIdsQueryVariables
>;
export const LoanRepaymentDetailsDocument = gql`
  query LoanRepaymentDetails($id: ID!) {
    loan(id: $id) {
      id
      paymentAmount
      paymentFrequency
      nextDebitDate
      totalOutstandingAmount
      totalAmountPaid
      loanStartDate
      termInMonth
      loanAmount
      timestamp
      principalPaidPercentage
      rtrPaidPercentage
    }
  }
`;

/**
 * __useLoanRepaymentDetailsQuery__
 *
 * To run a query within a React component, call `useLoanRepaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanRepaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanRepaymentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoanRepaymentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoanRepaymentDetailsQuery,
    LoanRepaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoanRepaymentDetailsQuery, LoanRepaymentDetailsQueryVariables>(
    LoanRepaymentDetailsDocument,
    options,
  );
}
export function useLoanRepaymentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoanRepaymentDetailsQuery,
    LoanRepaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoanRepaymentDetailsQuery, LoanRepaymentDetailsQueryVariables>(
    LoanRepaymentDetailsDocument,
    options,
  );
}
export type LoanRepaymentDetailsQueryHookResult = ReturnType<typeof useLoanRepaymentDetailsQuery>;
export type LoanRepaymentDetailsLazyQueryHookResult = ReturnType<
  typeof useLoanRepaymentDetailsLazyQuery
>;
export type LoanRepaymentDetailsQueryResult = Apollo.QueryResult<
  LoanRepaymentDetailsQuery,
  LoanRepaymentDetailsQueryVariables
>;
export const LoanSummaryDocument = gql`
  query LoanSummary($showAll: Boolean!) {
    loans(includePending: $showAll) {
      loanAmount
      id
      totalOutstandingAmount
      displayStatus
      isLate
      dpdAgeYTD
      amountInArrears
      loanStartDate
      refinanceCalculationState
      contract {
        id
        cancelled
        expired
        isDeclined
        isSignedByAll
        userSigned
      }
      countryCode
    }
    creditLines(showAll: $showAll) {
      available
      id
      total
      status
      startDate
      countryCode
      isApplicant
      signed
      statusReason
    }
    accounts {
      loanAccountId
      available
      balance
      productName
      loanAmount
      status
      startDate
      totalMissedAmount
      hasMissedPayment
      contractDetails {
        contractId
        status
      }
      countryCode
    }
    loanApplications {
      applicationId
      status {
        decision
        decisionDate
        rejectedReason
      }
    }
  }
`;

/**
 * __useLoanSummaryQuery__
 *
 * To run a query within a React component, call `useLoanSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanSummaryQuery({
 *   variables: {
 *      showAll: // value for 'showAll'
 *   },
 * });
 */
export function useLoanSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<LoanSummaryQuery, LoanSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoanSummaryQuery, LoanSummaryQueryVariables>(LoanSummaryDocument, options);
}
export function useLoanSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoanSummaryQuery, LoanSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoanSummaryQuery, LoanSummaryQueryVariables>(
    LoanSummaryDocument,
    options,
  );
}
export type LoanSummaryQueryHookResult = ReturnType<typeof useLoanSummaryQuery>;
export type LoanSummaryLazyQueryHookResult = ReturnType<typeof useLoanSummaryLazyQuery>;
export type LoanSummaryQueryResult = Apollo.QueryResult<
  LoanSummaryQuery,
  LoanSummaryQueryVariables
>;
export const LoadLoanTransactionsDocument = gql`
  query loadLoanTransactions($id: ID!) {
    loan(id: $id) {
      contract {
        id
        userSigned
      }
      ...TransactionList
    }
  }
  ${TransactionListFragmentDoc}
`;

/**
 * __useLoadLoanTransactionsQuery__
 *
 * To run a query within a React component, call `useLoadLoanTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLoanTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLoanTransactionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadLoanTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLoanTransactionsQuery,
    LoadLoanTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadLoanTransactionsQuery, LoadLoanTransactionsQueryVariables>(
    LoadLoanTransactionsDocument,
    options,
  );
}
export function useLoadLoanTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLoanTransactionsQuery,
    LoadLoanTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadLoanTransactionsQuery, LoadLoanTransactionsQueryVariables>(
    LoadLoanTransactionsDocument,
    options,
  );
}
export type LoadLoanTransactionsQueryHookResult = ReturnType<typeof useLoadLoanTransactionsQuery>;
export type LoadLoanTransactionsLazyQueryHookResult = ReturnType<
  typeof useLoadLoanTransactionsLazyQuery
>;
export type LoadLoanTransactionsQueryResult = Apollo.QueryResult<
  LoadLoanTransactionsQuery,
  LoadLoanTransactionsQueryVariables
>;
export const GetLocDocument = gql`
  query getLoc($id: ID!) {
    creditLine(id: $id, showAll: true) {
      id
      total
      available
      used
      pendingAmount
      startDate
      countryCode
      endDate
      totalUpcomingRepaymentAmount
      canDrawdown
      status
      nextPaymentDate
      subscriptionFeeFrequency
      isLockedForRefinance
      outstandingBalance
      upcomingPayment {
        name
        value
        paymentAllocationOrder
        type
      }
      currentPayment {
        name
        type
        value
        paymentAllocationOrder
      }
      deferralDetails {
        isOnDeferral
        deferralPeriodEndDate
      }
      subscriptionFeePercentage
      endOfTermDetails {
        paymentPlanDetails {
          numberOfWeeks
          firstWeekPaymentAmount
          repaidAmount
          maturityDate
        }
        payoutDetails {
          payoutAmount
          payoutAmountDueDate
        }
      }
      outstandingBalance
    }
  }
`;

/**
 * __useGetLocQuery__
 *
 * To run a query within a React component, call `useGetLocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocQuery(
  baseOptions: Apollo.QueryHookOptions<GetLocQuery, GetLocQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocQuery, GetLocQueryVariables>(GetLocDocument, options);
}
export function useGetLocLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocQuery, GetLocQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocQuery, GetLocQueryVariables>(GetLocDocument, options);
}
export type GetLocQueryHookResult = ReturnType<typeof useGetLocQuery>;
export type GetLocLazyQueryHookResult = ReturnType<typeof useGetLocLazyQuery>;
export type GetLocQueryResult = Apollo.QueryResult<GetLocQuery, GetLocQueryVariables>;
export const GetLocClRepaymentDetailsDocument = gql`
  query getLocCLRepaymentDetails($id: ID!) {
    creditLine(id: $id, showAll: true) {
      id
      bPayBillerCode
      bPayRefCode
    }
  }
`;

/**
 * __useGetLocClRepaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetLocClRepaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocClRepaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocClRepaymentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocClRepaymentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocClRepaymentDetailsQuery,
    GetLocClRepaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocClRepaymentDetailsQuery, GetLocClRepaymentDetailsQueryVariables>(
    GetLocClRepaymentDetailsDocument,
    options,
  );
}
export function useGetLocClRepaymentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocClRepaymentDetailsQuery,
    GetLocClRepaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocClRepaymentDetailsQuery, GetLocClRepaymentDetailsQueryVariables>(
    GetLocClRepaymentDetailsDocument,
    options,
  );
}
export type GetLocClRepaymentDetailsQueryHookResult = ReturnType<
  typeof useGetLocClRepaymentDetailsQuery
>;
export type GetLocClRepaymentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetLocClRepaymentDetailsLazyQuery
>;
export type GetLocClRepaymentDetailsQueryResult = Apollo.QueryResult<
  GetLocClRepaymentDetailsQuery,
  GetLocClRepaymentDetailsQueryVariables
>;
export const GetLocMRepaymentDetailsDocument = gql`
  query getLocMRepaymentDetails($id: ID!) {
    creditLine(id: $id, showAll: true) {
      id
      maskedAccountNumber
      outstandingBalance
    }
  }
`;

/**
 * __useGetLocMRepaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetLocMRepaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocMRepaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocMRepaymentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocMRepaymentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocMRepaymentDetailsQuery,
    GetLocMRepaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocMRepaymentDetailsQuery, GetLocMRepaymentDetailsQueryVariables>(
    GetLocMRepaymentDetailsDocument,
    options,
  );
}
export function useGetLocMRepaymentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocMRepaymentDetailsQuery,
    GetLocMRepaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocMRepaymentDetailsQuery, GetLocMRepaymentDetailsQueryVariables>(
    GetLocMRepaymentDetailsDocument,
    options,
  );
}
export type GetLocMRepaymentDetailsQueryHookResult = ReturnType<
  typeof useGetLocMRepaymentDetailsQuery
>;
export type GetLocMRepaymentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetLocMRepaymentDetailsLazyQuery
>;
export type GetLocMRepaymentDetailsQueryResult = Apollo.QueryResult<
  GetLocMRepaymentDetailsQuery,
  GetLocMRepaymentDetailsQueryVariables
>;
export const GetLocPaymentsAndTransactionsDocument = gql`
  query getLocPaymentsAndTransactions($id: ID!) {
    creditLine(id: $id, showAll: true) {
      id
      payments {
        stickyHeaderIndices
        itemList {
          id
          isEvenRow
          year
          data {
            id
            title
            subTitle
            recurringPaymentSubtitle
            description
            amount
            transactionNumber
            startDate
            endDate
            day
            month
            year
            frequencyType
            nextPaymentDate
            bankAccount {
              isPayAnyone
              bSB
              bankName
              bankAccountNumber
              accountName
            }
          }
        }
      }
      transactions {
        stickyHeaderIndices
        itemList {
          id
          isEvenRow
          year
          data {
            id
            transactionKey
            title
            description
            amount
            lineActivityType
            timestamp
            requestReceivedDate
            subComponents {
              principal
              interest
              fees
              excess
              weeklyServiceFee
              dishonorFee
              penalty
            }
            bankAccount {
              isPayAnyone
              bSB
              bankName
              bankAccountNumber
              accountName
            }
            payAnyoneReference
          }
        }
      }
    }
  }
`;

/**
 * __useGetLocPaymentsAndTransactionsQuery__
 *
 * To run a query within a React component, call `useGetLocPaymentsAndTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocPaymentsAndTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocPaymentsAndTransactionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocPaymentsAndTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocPaymentsAndTransactionsQuery,
    GetLocPaymentsAndTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLocPaymentsAndTransactionsQuery,
    GetLocPaymentsAndTransactionsQueryVariables
  >(GetLocPaymentsAndTransactionsDocument, options);
}
export function useGetLocPaymentsAndTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocPaymentsAndTransactionsQuery,
    GetLocPaymentsAndTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLocPaymentsAndTransactionsQuery,
    GetLocPaymentsAndTransactionsQueryVariables
  >(GetLocPaymentsAndTransactionsDocument, options);
}
export type GetLocPaymentsAndTransactionsQueryHookResult = ReturnType<
  typeof useGetLocPaymentsAndTransactionsQuery
>;
export type GetLocPaymentsAndTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetLocPaymentsAndTransactionsLazyQuery
>;
export type GetLocPaymentsAndTransactionsQueryResult = Apollo.QueryResult<
  GetLocPaymentsAndTransactionsQuery,
  GetLocPaymentsAndTransactionsQueryVariables
>;
export const NotificationsPreferencesDocument = gql`
  query notificationsPreferences($products: [ProductTypes]) {
    notifications {
      emailPreferences(products: $products) {
        id
        notificationType
        product
        enabled
        notificationMedium
        displayNotificationType
      }
      smsPreferences(products: $products) {
        id
        notificationType
        product
        enabled
        notificationMedium
        displayNotificationType
      }
    }
  }
`;

/**
 * __useNotificationsPreferencesQuery__
 *
 * To run a query within a React component, call `useNotificationsPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsPreferencesQuery({
 *   variables: {
 *      products: // value for 'products'
 *   },
 * });
 */
export function useNotificationsPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsPreferencesQuery,
    NotificationsPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsPreferencesQuery, NotificationsPreferencesQueryVariables>(
    NotificationsPreferencesDocument,
    options,
  );
}
export function useNotificationsPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsPreferencesQuery,
    NotificationsPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsPreferencesQuery, NotificationsPreferencesQueryVariables>(
    NotificationsPreferencesDocument,
    options,
  );
}
export type NotificationsPreferencesQueryHookResult = ReturnType<
  typeof useNotificationsPreferencesQuery
>;
export type NotificationsPreferencesLazyQueryHookResult = ReturnType<
  typeof useNotificationsPreferencesLazyQuery
>;
export type NotificationsPreferencesQueryResult = Apollo.QueryResult<
  NotificationsPreferencesQuery,
  NotificationsPreferencesQueryVariables
>;
export const LoadAccountStatementsDocument = gql`
  query loadAccountStatements($id: ID!) {
    account(id: $id) {
      statements {
        monthly {
          id
          title
          applicationId
          startDate
          endDate
        }
        annually {
          id
          title
          applicationId
          startDate
          endDate
        }
      }
    }
  }
`;

/**
 * __useLoadAccountStatementsQuery__
 *
 * To run a query within a React component, call `useLoadAccountStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAccountStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAccountStatementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadAccountStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAccountStatementsQuery,
    LoadAccountStatementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAccountStatementsQuery, LoadAccountStatementsQueryVariables>(
    LoadAccountStatementsDocument,
    options,
  );
}
export function useLoadAccountStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAccountStatementsQuery,
    LoadAccountStatementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadAccountStatementsQuery, LoadAccountStatementsQueryVariables>(
    LoadAccountStatementsDocument,
    options,
  );
}
export type LoadAccountStatementsQueryHookResult = ReturnType<typeof useLoadAccountStatementsQuery>;
export type LoadAccountStatementsLazyQueryHookResult = ReturnType<
  typeof useLoadAccountStatementsLazyQuery
>;
export type LoadAccountStatementsQueryResult = Apollo.QueryResult<
  LoadAccountStatementsQuery,
  LoadAccountStatementsQueryVariables
>;
