import { useCallback, useMemo } from 'react';

import { type DropdownProps } from '@all-in-one-web/common-ui';

import { BillStatus } from '@generated-fg';

import {
  type BillDropdownMenuProps,
  DropdownActions,
  type TUseBillDropdownReturnValue,
} from '@components/types';

import { BillPayDropdownAction, BillsPaidStatuses, billDropdownMenuItemLabel } from '@constants';
import { trackBAActionEvent } from '@utils';

import { useCancelScheduledBillTransaction, useDeleteBillAction } from '.';
import { BillsTableStyles } from '../../../components/BillsTable';

const statusWithoutDropdownAction = new Set(BillsPaidStatuses);

export function useBillDropdown({
  status,
  billId,
  billerName,
  recentTransaction,
  dropdownActionUpdaters,
  dropdownActionOnSuccess,
}: BillDropdownMenuProps): TUseBillDropdownReturnValue {
  const { deleteBill, modal: deleteBillModal } = useDeleteBillAction({
    updater: dropdownActionUpdaters?.[DropdownActions.DELETE_BILL],
    onSuccess: dropdownActionOnSuccess?.[DropdownActions.DELETE_BILL],
  });

  const { cancelBillScheduledPayment, modal: cancelScheduledPaymentModal } =
    useCancelScheduledBillTransaction({
      updater: dropdownActionUpdaters?.[DropdownActions.CANCEL_SCHEDULED_PAYMENT],
    });

  const toggle = useCallback(event => {
    event.stopPropagation();
  }, []);

  const dropdownItems = useMemo<DropdownProps['items']>(() => {
    if (statusWithoutDropdownAction.has(status)) return [];
    switch (status) {
      case BillStatus.Scheduled: {
        const { referenceId, paymentGateway, accountId } = recentTransaction || {};
        return [
          {
            children: billDropdownMenuItemLabel.cancelScheduleTransaction,
            onClick: () => {
              trackBAActionEvent(
                'bill_page_action__click',
                `action:${BillPayDropdownAction.CANCEL}`,
              );
              cancelBillScheduledPayment({
                referenceId,
                id: billId,
                paymentGateway,
                accountId,
              });
            },
          },
        ];
      }
      default:
        return [
          {
            children: billDropdownMenuItemLabel.deleteBill,
            onClick: () => {
              trackBAActionEvent(
                'bill_page_action__click',
                `action:${BillPayDropdownAction.DELETE}`,
              );
              deleteBill({ id: billId, billerName });
            },
            className: BillsTableStyles.DeleteAction,
          },
        ];
    }
  }, [status, recentTransaction, cancelBillScheduledPayment, billId, billerName, deleteBill]);

  const popupNotification = useMemo(() => {
    switch (status) {
      case BillStatus.Scheduled:
        return cancelScheduledPaymentModal;
      default:
        return deleteBillModal;
    }
  }, [status, deleteBillModal, cancelScheduledPaymentModal]);

  return { dropdownItems, toggle, popupNotification };
}
