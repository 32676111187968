import type { ReactNode } from 'react';

export interface TransactionItemProps {
  transaction: TransactionRecord;
  hideTime?: boolean;
}

export type TransactionRecord = {
  id: string;
  amount: number;
  date: string;
  description: string;
  pending: boolean;
  displayName: string;
  initiatedBy?: string;
  reference?: string;
  isFailure?: boolean;
  additionalDetails?: ReactNode;
  sign?: TransactionSign;
};

export enum TransactionSign {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}
