import { type Dispatch, type SetStateAction, createContext, useState } from 'react';

import { Country, PaymentType } from '@generated-fg';

import type { PayAnyoneFormValues, PayeeV2 } from '@models';

export type BillContextType = {
  payee: PayeeV2;
  values: Partial<PayAnyoneFormValues>;
};

const initialValue: BillContextType = {
  payee: {
    id: '',
    bsb: '',
    account: '',
    accountName: '',
    countryCode: Country.Au,
    financialInstitution: '',
    paymentType: PaymentType.BankTransfer,
  },
  values: {
    amount: '',
    description: '',
    reference: '',
    payLaterScheduledDate: new Date(),
  },
};

export const BillContext = createContext<BillContextType>(initialValue);
export const BillContextSetter =
  createContext<Dispatch<SetStateAction<BillContextType>>>(undefined);

export const BillContextProvider = ({ children }) => {
  const [bill, setBill] = useState<BillContextType>(initialValue);

  return (
    <BillContextSetter.Provider value={setBill}>
      <BillContext.Provider value={bill}>{children}</BillContext.Provider>
    </BillContextSetter.Provider>
  );
};
