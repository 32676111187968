import {
  BannerSection,
  DeferralLineOfCreditAlert,
  IntegrationErrorAlert,
  LoanUpcomingRepayment,
  LockedAccountAlert,
  SuspendedAccountAlert,
} from '@components';

import { lineOfCreditSuspendedMessage } from '@constants';
import { insertIf } from '@utils';

import { type LocBannersSectionProps as Props } from './types';

export function LocBannersSection({
  lockedLocAlertProps: { show: isLockedAccount, ...lockedAlertProps },
  isSuspendedAccount,
  deferralLocAlertProps: { show: isDeferralAccount, ...deferralAlertProps },
  loanUpcomingRepaymentProps: {
    showAdditionalRepaymentButton,
    showLoanUpcomingRepayment: showRepaymentAlert,
    ...repaymentProps
  },
  integrationSyncErrorAlert,
}: Props) {
  return (
    <BannerSection
      container="loc-product-page"
      alerts={[
        ...insertIf(isDeferralAccount, {
          Alert: () => <DeferralLineOfCreditAlert {...deferralAlertProps} />,
        }),
        ...insertIf(isLockedAccount, {
          Alert: () => <LockedAccountAlert {...lockedAlertProps} />,
        }),
        ...insertIf(isSuspendedAccount, {
          Alert: () => <SuspendedAccountAlert alertMessage={lineOfCreditSuspendedMessage} />,
        }),
        ...insertIf(showRepaymentAlert || showAdditionalRepaymentButton, {
          Alert: () => (
            <LoanUpcomingRepayment
              showLoanUpcomingRepayment={showRepaymentAlert}
              showAdditionalRepaymentButton={showAdditionalRepaymentButton}
              {...repaymentProps}
            />
          ),
        }),
        ...insertIf(integrationSyncErrorAlert?.show, {
          Alert: () => (
            <IntegrationErrorAlert
              integrationStatus={integrationSyncErrorAlert.integrationStatus}
              onClose={integrationSyncErrorAlert.remove}
            />
          ),
        }),
      ]}
    />
  );
}
