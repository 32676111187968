import { useMemo } from 'react';
import { type NavigateFunction } from 'react-router-dom';

import {
  GetIntegrationsDataV2Document,
  type IntegrationProduct,
  type useDisconnectIntegrationMutation,
} from '@generated-fg';

import { type PromiseAnimationProps } from '../components/types';
import { ACCOUNTING_INTEGRATION_MODALS, DASHBOARD_ROUTES } from '../constants';

export interface DisconnectAnimationOption {
  type: IntegrationProduct;
  navigate: NavigateFunction;
  setShowAnimation: (show: boolean) => void;
  setOpenModal: (modal: ACCOUNTING_INTEGRATION_MODALS | null) => void;
  disconnectIntegration: ReturnType<typeof useDisconnectIntegrationMutation>[0];
}

export const useDisconnectAnimationProps = (
  option: DisconnectAnimationOption,
): PromiseAnimationProps => {
  const { type, navigate, setShowAnimation, setOpenModal, disconnectIntegration } = option;
  const disconnectAnimationProps = useMemo(
    () => ({
      asyncRequest: async () => {
        const result = await disconnectIntegration({
          variables: { input: { integration: type } },
          refetchQueries: [GetIntegrationsDataV2Document],
          awaitRefetchQueries: true,
        });
        if (result.errors) {
          throw result.errors;
        }
      },
      onResolve: () => {
        navigate(DASHBOARD_ROUTES.SETTINGS);
        setShowAnimation(false);
        setOpenModal(null);
      },
      onError: () => {
        setShowAnimation(false);
        setOpenModal(ACCOUNTING_INTEGRATION_MODALS.INTERNAL_ERROR);
      },
    }),
    [disconnectIntegration, navigate, setOpenModal, setShowAnimation, type],
  );
  return disconnectAnimationProps;
};
