import { useEffect, useState } from 'react';

import Api from '../../services/Api';
import { useAppLogger } from '../useAppLogger';
import type { useHayTokenLoaderProps } from './types';

export const useHayTokenLoader = ({
  skip = false,
}: useHayTokenLoaderProps): { hayData: string; loading: boolean; error: boolean } => {
  const [hayData, setHayData] = useState<string>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { logMessage } = useAppLogger();

  useEffect(() => {
    const fetchToken = async () => await Api.getHayBearerToken();
    if (!skip) {
      setLoading(true);
      fetchToken()
        .then(setHayData)
        .catch(error => {
          setError(error);
          logMessage('[getHayBearerToken]: useHayTokenLoader', {
            extra: { error },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [logMessage, skip]);

  return {
    hayData,
    loading,
    error,
  };
};
