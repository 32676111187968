import classNames from 'classnames';

import styles from './RowDetails.module.scss';
import type { RowDetailsProps } from './types';

export const RowDetails = ({ header, body }: RowDetailsProps) => {
  return (
    <div className={styles.RowDetails} data-testid="row-details">
      <p
        className={classNames(styles.RowDetails__Heading, 'pds-text-semibold')}
        data-testid="row-details__heading"
      >
        {header}
      </p>
      <p
        className={classNames(styles.RowDetails__Body, 'pds-text')}
        data-testid="row-details__body"
      >
        {body}
      </p>
    </div>
  );
};
