/**
 * This util file contains functions to update the cache after a mutation
 */
import {
  BillStatus,
  type ConfirmBillDetailMutation,
  type GetBillDetailsQuery,
  type GetBillsQuery,
} from '@generated-fg';

export const updateBillDetailCacheAfterCancelScheduledTransaction = (
  data: GetBillDetailsQuery,
  transactionId,
): GetBillDetailsQuery => {
  return {
    ...data,
    user: {
      ...data.user,
      billPay: {
        ...data.user.billPay,
        bill: {
          ...data.user.billPay.bill,
          status: BillStatus.Reviewed,
          linkedTransactions: data.user.billPay.bill.linkedTransactions.filter(
            ({ referenceId }) => referenceId !== transactionId,
          ),
        },
      },
    },
  };
};

export const updateBillListCacheAfterCancelScheduledTransaction = (
  data: GetBillsQuery,
  billId: string,
  transactionId: string,
): GetBillsQuery => {
  return {
    ...data,
    user: {
      ...data.user,
      billPay: {
        ...data.user.billPay,
        bills: data.user.billPay.bills.map(bill => {
          const { id } = bill;
          if (id !== billId) return bill;
          return {
            ...bill,
            status: BillStatus.Reviewed,
            linkedTransactions: bill.linkedTransactions.filter(
              ({ referenceId }) => referenceId !== transactionId,
            ),
          };
        }),
      },
    },
  };
};

export const updateBillStatusAfterConfirmBillDetail = (
  data: GetBillDetailsQuery,
  changedBill: ConfirmBillDetailMutation['updateBill'],
  scheduleNow,
): GetBillDetailsQuery => {
  const { status } = data.user.billPay.bill;
  const isReviewed = !scheduleNow && status === BillStatus.Received;
  return {
    ...data,
    user: {
      ...data.user,
      billPay: {
        ...data.user.billPay,
        bill: {
          ...changedBill,
          status: isReviewed ? BillStatus.Reviewed : status,
        },
      },
    },
  };
};
