import classNames from 'classnames';

import { InfoCircle as InfoIcon } from '@prospa/salt-icons';

import { InformationTooltipLoader, Tooltip } from '@components';

import styles from './InformationTooltip.module.scss';
import type { InformationTooltipProps } from './types';

export function InformationTooltip({
  shouldRender,
  loading = true,
  title,
  content,
  tooltipText,
  position = 'right',
  className,
}: InformationTooltipProps) {
  if (!shouldRender && !loading) {
    return null;
  }
  return (
    <div
      className={classNames(
        { [styles.InformationTooltipLoading]: loading },
        styles.InformationTooltip,
        className,
      )}
    >
      {loading ? (
        <InformationTooltipLoader />
      ) : (
        <>
          <span className={styles.InformationTooltipTitle}>{title}</span>
          <span className={styles.InformationTooltipContent}>{content}</span>
          <Tooltip
            className={styles.InformationTooltipContainer}
            text={tooltipText}
            position={position}
          >
            <InfoIcon />
          </Tooltip>
        </>
      )}
    </div>
  );
}
