import type { FC } from 'react';

import { WebTick } from '@prospa/icons';

import { ButtonSize } from '@constants';

import { Modal } from '../Modal';
import styles from './LoanSecondSignatureModal.module.scss';
import type { LoanSecondSignatureModalProps } from './types';

export const LoanSecondSignatureModal: FC<LoanSecondSignatureModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      customStyle={{ overlay: { zIndex: 999 } }}
      isOpen={isOpen}
      onClose={onClose}
      showCloseModal
      size="medium"
      body={
        <div data-testid="loan-second-signature-modal">
          <WebTick className={styles.LoanSecondSignatureModalIcon} />
          <h3 className={styles.LoanSecondSignatureModalTitle}>Agreement received</h3>
          <p className={styles.LoanSecondSignatureModalText}>
            Thank you for signing the agreement. We require another director/shareholder to review
            and sign the agreement before this facility can be accessed. The other part has been
            notified via email.
          </p>
        </div>
      }
      primaryButtonProps={{
        label: 'OK',
        onClick: onClose,
        size: ButtonSize.LARGE,
      }}
    />
  );
};
