import { FullScreenAnimation } from '@all-in-one-web/common-ui';

import XeroAuthLock from '../../../../assets/animations/xeroAuthLock.json';
import { LottieAnimation } from '../../LottieAnimation';

export const XeroAuthAnimation = () => {
  return (
    <FullScreenAnimation
      animation={<LottieAnimation animationData={XeroAuthLock} />}
      animationTitle="Securely accessing Xero..."
      animationSubTitle="Your data is protected"
    />
  );
};
