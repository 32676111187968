import {
  type Dispatch,
  type SetStateAction,
  createContext,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from 'react-oidc-context';
import { useMediaQuery } from 'react-responsive';

import { useStateMachine } from 'little-state-machine';

import { ProspaFavicon } from '@prospa/icons';

import { Banner, LogoutModal, Nav, TimeoutModal } from '@components';

import { MOBILE_APP_BANNER, MOBILE_LINKS, MobileOSTypes, breakpoints } from '@constants';
import { useAppLogger } from '@hooks';
import {
  clearGlobalStateAction,
  externalRedirect,
  getMobileOperatingSystem,
  trackBAActionEvent,
} from '@utils';

import { useIntersect } from '../../../app/hooks/useIntersect';
import { AppContext } from '../../contexts/';
import { ToastContextProvider } from '../../contexts/ToastContext';
import { useStateInLocalStorage } from '../../hooks/useStateInLocalStorage';
import { useStateInSessionStorage } from '../../hooks/useStateInSessionStorage';
import { DesktopHeader } from './DesktopHeader/DesktopHeader';
import './Layout.scss';
import type { LayoutProps } from './types';

const MILLI_SECONDS = 1000 * 60;
const TIMEOUT_MODAL = 8; // min
const BUFFER_TIME = 2; // min
const WELCOME_MODAL_DELAY = 4000;
const WelcomeModal = lazy(() => import('../Modal/WelcomeModal/WelcomeModal'));

export type LayoutContextProps = {
  isIntersectingScrollableArea: boolean;
  stickyRef?: Dispatch<SetStateAction<Element>>;
};

export const LayoutContext = createContext<LayoutContextProps>({
  isIntersectingScrollableArea: true,
});

export const Layout = ({ logout, children }: LayoutProps) => {
  const { signinSilent } = useAuth();
  const [isTimeoutModalOpen, setIsTimeoutModalOpen] = useState<boolean>(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(false);
  const [isIntersectingScrollableArea, setIsIntersectingScrollableArea] = useState<boolean>(true);
  const { actions } = useStateMachine({ actions: { clearGlobalStateAction } });
  const operatingSystem = getMobileOperatingSystem();
  const { logMessage } = useAppLogger();

  const isMobile = useMediaQuery({ query: breakpoints.mobile });

  const {
    signInInfo: { isMobileAppUser, countryCode },
  } = useContext(AppContext);
  const {
    firstSignIn: { setIsFirstSignIn },
  } = useStateInLocalStorage();
  // Display this modal to all customers who:
  // 1. is not mobile app users (from appContext)
  // 2. is first time sign in (from local storage)
  // 3. not only have an SBLM product. (has any of BA, LOC, SBL) (from appContext)
  const enableWelcomeModal = false;

  useEffect(() => {
    if (enableWelcomeModal) {
      setTimeout(() => setIsWelcomeModalOpen(true), WELCOME_MODAL_DELAY);
    }
  }, [enableWelcomeModal]);

  const handleOnIdle = () => {
    setIsTimeoutModalOpen(true);
  };

  const handleRenew = async () => {
    try {
      await signinSilent();
      setIsTimeoutModalOpen(false);
    } catch (error) {
      logMessage('[signinSilent]: Error Renewing Token', { extra: { error } });
    }
  };

  useIdleTimer({
    timeout: MILLI_SECONDS * TIMEOUT_MODAL,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const logoutHandler = () => {
    setIsLogoutModalOpen(true);
    actions.clearGlobalStateAction();
  };
  const stickyRoot = useRef();
  const [stickyRef, entry] = useIntersect({
    root: stickyRoot.current,
    rootMargin: '0px',
    threshold: 0.01,
  });

  useEffect(() => {
    if (entry) {
      setIsIntersectingScrollableArea(entry.isIntersecting);
    }
    return () => setIsIntersectingScrollableArea(true);
  }, [entry]);

  const {
    appBanner: { displayMobileAppBanner, setDisplayMobileAppBanner },
    mobileAppModal: { displayMobileAppModal } = {},
  } = useStateInSessionStorage();
  const showAppBanner = useMemo(
    () =>
      countryCode === 'AU' &&
      !displayMobileAppModal &&
      !isMobileAppUser &&
      isMobile &&
      displayMobileAppBanner &&
      (operatingSystem === MobileOSTypes.IOS || operatingSystem === MobileOSTypes.Android),
    [
      countryCode,
      displayMobileAppModal,
      isMobileAppUser,
      isMobile,
      displayMobileAppBanner,
      operatingSystem,
    ],
  );

  const handleAppBannerButtonClick = useCallback(() => {
    trackBAActionEvent('app_banner_click');
    return operatingSystem === MobileOSTypes.IOS
      ? externalRedirect({ url: MOBILE_LINKS.IOS_APP_STORE_URL })
      : externalRedirect({ url: MOBILE_LINKS.ANDROID_GOOGLE_PLAY_STORE_URL });
  }, [operatingSystem]);

  return (
    <div data-testid="main-layout" className="layout">
      {showAppBanner && (
        <Banner
          className="mobile-banner"
          title={MOBILE_APP_BANNER.TITLE}
          subtitle={MOBILE_APP_BANNER.SUBTITLE}
          icon={<ProspaFavicon />}
          onClose={() => setDisplayMobileAppBanner(false)}
          buttonText="Open"
          onClick={handleAppBannerButtonClick}
        />
      )}
      <LayoutContext.Provider value={{ isIntersectingScrollableArea, stickyRef }}>
        <Nav logout={logoutHandler} />
        <div className="content-container">
          <DesktopHeader logoutHandler={logoutHandler} />
          <div className="content-scroll" ref={stickyRoot}>
            <ToastContextProvider>
              <div className="content">
                <main className="main">
                  {children}
                  {/* stable anchor to attach the qualtrics form to bottom of main*/}
                  <div id="qualtrics-form-main-footer" />
                </main>
              </div>
            </ToastContextProvider>
          </div>
        </div>
        <LogoutModal
          logout={logout}
          isOpen={isLogoutModalOpen}
          onClose={() => {
            setIsLogoutModalOpen(false);
          }}
        />
        <TimeoutModal
          isOpen={isTimeoutModalOpen}
          onClose={() => {
            setIsTimeoutModalOpen(false);
          }}
          countDownStartMinutes={BUFFER_TIME}
          handleRenew={handleRenew}
          logout={logout}
        />
        {enableWelcomeModal && (
          <WelcomeModal
            isOpen={isWelcomeModalOpen}
            onClose={() => {
              setIsFirstSignIn(false);
              setIsWelcomeModalOpen(false);
            }}
          />
        )}
      </LayoutContext.Provider>
    </div>
  );
};
