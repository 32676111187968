import { BusinessAccountStatus, DrawdownStatus } from '@generated-fg';

import { BalanceDetails } from '@components';

import { ProductTypes } from '../../models/Account';
import type { TPayAnyoneSingleAccount } from './types';

export const PayAnyoneSingleAccount = ({
  hasValidLOCs,
  validLOCs,
  payFromAccount,
  accountsLoading,
  businessAccountStatus,
}: TPayAnyoneSingleAccount) => {
  return hasValidLOCs ? (
    <BalanceDetails
      loading={accountsLoading}
      accountName="Line of Credit"
      locData={validLOCs[0]}
      isAccountFrozen={!!validLOCs[0] && validLOCs[0].drawdown.status !== DrawdownStatus.Active}
    />
  ) : (
    <BalanceDetails
      accountName="Business Account"
      accountData={payFromAccount}
      businessAccountStatus={businessAccountStatus}
      loading={accountsLoading}
      isAccountFrozen={
        !!payFromAccount &&
        payFromAccount.productType === ProductTypes.BA &&
        businessAccountStatus !== BusinessAccountStatus.Active
      }
    />
  );
};
