import { XeroAuthAnimation } from '@components';

import { DefaultSpinner } from './DefaultSpinner';
import type { DefaultSpinnerProps } from './DefaultSpinner/types';
import { PageSpinnerSources } from './hooks/types';
import { usePageSpinner } from './hooks/usePageSpinner';

export const PageSpinner = ({ caller = '' }: DefaultSpinnerProps): JSX.Element => {
  const { source } = usePageSpinner();
  switch (source) {
    case PageSpinnerSources.XERO_CONNECTION_SUCCESS:
      return <XeroAuthAnimation />;
    case PageSpinnerSources.DEFAULT:
    default:
      return <DefaultSpinner caller={caller} />;
  }
};
