/**
 * This util file is for all constants related to bill pay feature
 */
import { type AlertTypes } from '@all-in-one-web/common-ui';

import { BillPaymentType, BillStatus } from '@generated-fg';

import type { StatusLevels } from '@components/types';

export const BillStatusAndAlertTypeMapping: Record<BillStatus, AlertTypes> = {
  [BillStatus.Received]: 'Notification',
  [BillStatus.Failed]: 'Danger',
  [BillStatus.Paid]: 'Success',
  [BillStatus.Scheduled]: 'Success',
  [BillStatus.Pending]: 'Success',
  [BillStatus.Reviewed]: 'Success',
};

export const incompleteScannedBillAlert = `We were unable to scan your bill. Please enter missing details before confirming.`;

type BillStatusHasMessage = BillStatus.Received | BillStatus.Pending;
export const BillAlertMessage: Record<BillStatusHasMessage, string> = {
  [BillStatus.Received]:
    'We scanned your bill and pre-filled some details. Please check each field is correct before confirming.',
  [BillStatus.Pending]:
    'Bill payment is pending. We’ll update the status to ‘Paid’ once it has processed.',
};

export const BillStatusLevelMapping: Record<BillStatus, keyof typeof StatusLevels> = {
  [BillStatus.Failed]: 'warning',
  [BillStatus.Reviewed]: 'pending',
  [BillStatus.Scheduled]: 'cleared',
  [BillStatus.Paid]: 'cleared',
  [BillStatus.Pending]: 'cleared',
  [BillStatus.Received]: 'primary',
};

export const BillsAwaitingStatuses = [BillStatus.Failed, BillStatus.Reviewed, BillStatus.Scheduled];

export const BillsPaidStatuses = [BillStatus.Paid, BillStatus.Pending];

export enum BillsTableSectionTab {
  AwaitingPayment = 'AwaitingPayment',
  Paid = 'Paid',
}
export const BillsColumns = ['Due date', 'Biller', 'Status', 'Amount', ''];

export const BillFormConstants = {
  ConfirmScheduleNowLabel: 'Confirm and schedule payment',
  ConfirmScheduleLaterLabel: 'Confirm and schedule later',
  CancelButtonLabel: 'Cancel scheduled payment',
  RetryPaymentLabel: 'Pay now',
  PaymentProceedAlert: 'Payments can take up to 2 business days to process',
  PaymentDoubleCheckNotice:
    'Please double check all bill details before confirming as we may not be able to recover incorrect payments.',
};

export const NON_EDITABLE_STATUSES = [...BillsPaidStatuses, BillStatus.Scheduled];

export const billDropdownMenuItemLabel = {
  cancelScheduleTransaction: 'Cancel scheduled payment',
  deleteBill: 'Delete',
};

export const BillConfirmFormErrorMessage = {
  InvalidBillerName: `Use only letters, ', and -`,
  InvalidDescription: `Use only letters, numbers, and - / \\`,
  InvalidReference: `Use only letters, numbers, and . , : - _ / # & ?`,
  ShouldAddBillerPayee: `Add biller details`,
  NoBillerPayeeFound: `Biller payment details not found.`,
};

export enum PayeeType {
  BANK_ACCOUNT = 'bank account',
  BPAY = 'bpay',
}

export enum BillPayDropdownAction {
  CANCEL = 'cancel',
  DELETE = 'delete',
}

export const BillPaymentPayeeMapping: Record<BillPaymentType, PayeeType> = {
  [BillPaymentType.BankTransfer]: PayeeType.BANK_ACCOUNT,
  [BillPaymentType.Bpay]: PayeeType.BPAY,
};

export const BillsTableEmptyStateText = {
  AwaitingPayment: "You haven't confirmed any bills yet",
  Paid: "You haven't paid any bills yet",
  NoBillsCard: {
    ctaLabel: 'What is Bill pay?',
    title: 'You haven’t uploaded any bills yet',
  },
};

export enum BillPayStatus {
  NotAvailable = 'NotAvailable',
  EmailNotInit = 'EmailNotInit',
  NoBills = 'NoBills',
  HasReceivedBills = 'HasReceivedBills',
}
