import classNames from 'classnames';

import { Alert } from '@all-in-one-web/common-ui';

import styles from './DeferredAccountAlert.module.scss';
import type { DeferredAccountAlertProps } from './types';

function DeferredAccountAlert({ className, alertMessage }: DeferredAccountAlertProps) {
  return (
    <Alert alertType="Info" className={classNames(styles.DeferredAccountAlert, className)}>
      <span data-testid="deferred-account-alert">{alertMessage}</span>
    </Alert>
  );
}
export default DeferredAccountAlert;
