import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { SWITCH_BOX_SHADOW } from '@all-in-one-web/common-ui';
import { Spinner } from '@prospa/salt-react-beta';

import { AccountLoader, Button, Card, ConnectionStatus, Switch } from '@components';

import { ButtonSize, ButtonType, ProspaProductToNameMap } from '@constants';
import { breakpoints } from '@constants';
import { calculateSyncTimeByProductType } from '@utils';

import styles from './AccountingIntegrationList.module.scss';
import type { AccountingIntegrationListItemProps, AccountingIntegrationListProps } from './types';

const DESKTOP_SWITCH_STYLING = {
  width: 51,
  height: 32,
  handleDiameter: 28,
  boxShadow: SWITCH_BOX_SHADOW,
};

const MOBILE_SWITCH_STYLING = {
  width: 42,
  height: 24,
  handleDiameter: 18,
  boxShadow: SWITCH_BOX_SHADOW,
};

const AccountingIntegrationListItem = ({
  feedItem,
  isDisabled,
}: AccountingIntegrationListItemProps) => {
  const isMobile = useMediaQuery({ query: breakpoints.mobile });
  const [isLoading, setIsLoading] = useState(false);

  const {
    productId,
    productType,
    displaySubtitle,
    displayStatus,
    errorStatus,
    isSynced,
    syncDateTime,
    onChange,
  } = feedItem;

  return (
    <li className={styles.AccountListItem} key={productId}>
      <div className={styles.AccountListItemLeft}>
        <div className={styles.AccountListItemDetails}>
          <div className={styles.AccountListItemColumn}>
            <p className="pds-text-semibold">{`${ProspaProductToNameMap[productType]}`}</p>
            <p className={styles.AccountListItemDetail}>{displaySubtitle}</p>
          </div>
        </div>
        <div className={styles.AccountListItemStatus}>
          <ConnectionStatus displayStatus={displayStatus} status={errorStatus} />
        </div>
        <div className={styles.AccountListItemSyncTime}>
          {calculateSyncTimeByProductType({ syncDateTime, productType })}
        </div>
      </div>
      <div className={styles.AccountListItemRight} data-testid="integration-switch-toggle">
        {isLoading && (
          <div className={styles.AccountListItemSpinner}>
            <Spinner size={20} />
          </div>
        )}
        <Switch
          checked={isSynced}
          onChange={async checked => {
            setIsLoading(true);
            await onChange(checked, feedItem);
            setIsLoading(false);
          }}
          disabled={isDisabled}
          activeBoxShadow={null}
          {...(isMobile ? MOBILE_SWITCH_STYLING : DESKTOP_SWITCH_STYLING)}
        />
      </div>
    </li>
  );
};

export const AccountingIntegrationList = ({
  feeds,
  loading,
  footerButtonProps,
}: AccountingIntegrationListProps) => {
  return loading && !feeds?.length ? (
    <Card className={`${styles.AccountListLoader}`}>
      <AccountLoader className={`${styles.Skeleton}`} />
    </Card>
  ) : (
    <Card className={styles.AccountListCard}>
      <ul className={styles.AccountList}>
        {feeds.map(item => (
          <AccountingIntegrationListItem
            key={item.productId}
            feedItem={item}
            isDisabled={loading}
          />
        ))}
        <li className={styles.AccountListFooterButton}>
          <Button
            type={ButtonType.ICON_LINK_UNDERLINE}
            size={ButtonSize.MEDIUM}
            {...footerButtonProps}
          />
        </li>
      </ul>
    </Card>
  );
};
