import { common } from './common';

export const mock = {
  ...common,
  sentry: {
    ...common.sentry,
    environment: 'mock',
  },
  xero: {
    ...common.xero,
    clientId: '1618E543513444D58832D290DCAF869C',
  },
};
