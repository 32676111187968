import { LoanAwaitingModal, LoanSecondSignatureModal } from '@components';

import { type AccountStatusModalSectionProps, AccountStatusModals } from './types';

export function AccountStatusModalSection({
  activatedModal,
  closeModal,
}: AccountStatusModalSectionProps) {
  switch (activatedModal) {
    case AccountStatusModals.LOAN_SECOND_SIGNATURE:
      return <LoanSecondSignatureModal isOpen onClose={closeModal} />;
    case AccountStatusModals.LOAN_AWAITING:
      return <LoanAwaitingModal isOpen onClose={closeModal} />;
    default:
      return null;
  }
}
