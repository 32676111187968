import { useMemo } from 'react';

import { Card } from '@all-in-one-web/common-ui';
import { SBL as SBLIcon } from '@prospa/salt-illustrations';

import { currencyFormatter } from '@utils';

import { RepaymentCalculatorTab } from '../../containers/RepaymentCalculator/types';
import styles from './WeeklyRepayments.module.scss';

type WeeklyRepaymentsProps = {
  weeklyRepayment: number;
  repaymentCalculatorTab: RepaymentCalculatorTab;
};

export const WeeklyRepayments = ({
  weeklyRepayment,
  repaymentCalculatorTab,
}: WeeklyRepaymentsProps) => {
  const caption = useMemo(() => {
    switch (repaymentCalculatorTab) {
      case RepaymentCalculatorTab.BUSINESS_LOAN:
        return '*No upfront security required';
      case RepaymentCalculatorTab.LINE_OF_CREDIT:
        return 'Includes principal, interest and service fee';
      default:
        return;
    }
  }, [repaymentCalculatorTab]);

  const headerText = useMemo(() => {
    switch (repaymentCalculatorTab) {
      case RepaymentCalculatorTab.LINE_OF_CREDIT:
        return 'First weekly repayment';
      case RepaymentCalculatorTab.BUSINESS_LOAN:
        return 'Weekly repayments';
      default:
        return;
    }
  }, [repaymentCalculatorTab]);

  return (
    <Card className={styles.WeeklyRepayments}>
      <div>
        <span className="pds-lead">{headerText}</span>
        <h2 data-testid="weekly-repayments-value">{currencyFormatter(weeklyRepayment)}</h2>
        <span className="pds-text">{caption}</span>
      </div>
      <SBLIcon size={80} className={styles.WeeklyRepaymentsIcon} />
    </Card>
  );
};
