import { useEffect } from 'react';

import type { ApolloError } from '@apollo/client';

import { type BillStatus, type GetBillsQuery, useGetBillsQuery } from '@generated-fg';

import { trackBAActionEvent } from '@utils';

export type TUseBillsDataProps = {
  status: BillStatus[];
};

export type UseBillsDataReturnType = {
  dueThisMonthAmount: GetBillsQuery['user']['billPay']['dueThisMonthAmount'];
  dueNextMonthAmount: GetBillsQuery['user']['billPay']['dueNextMonthAmount'];
  bills: GetBillsQuery['user']['billPay']['bills'];
  loading: boolean;
  error?: ApolloError;
};

export const useBillsData = ({ status }: TUseBillsDataProps): UseBillsDataReturnType => {
  const {
    data: { user: { billPay } = {} } = {},
    loading,
    error,
  } = useGetBillsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { status },
  });

  useEffect(() => {
    if (error) trackBAActionEvent('bill_error', error.message);
  }, [error]);

  return {
    dueThisMonthAmount: billPay?.dueThisMonthAmount,
    dueNextMonthAmount: billPay?.dueNextMonthAmount,
    bills: billPay?.bills || [],
    loading,
    error,
  };
};
