import { onError } from '@apollo/client/link/error';
import { Observable } from 'zen-observable-ts';

import { captureMessage, getGQLDataSourceByOperation } from '@utils';

import { HayErrorTypes, type HayGraphQLError } from '../../models/HayErrorTypes';
import Api from '../../services/Api';

const promiseToObservable = <T>(promise: Promise<T>): Observable<T> => {
  return new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) {
          return;
        }
        subscriber.next(value);
        subscriber.complete();
      },
      err => {
        subscriber.error(err);
      },
    );
  });
};

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward, response }) => {
    const context = operation.getContext();
    const ignoreGraphQLErrors = context.ignoreGraphQLErrors;
    const ignoreNetworkErrors = context.ignoreNetworkErrors;
    const datasource = getGQLDataSourceByOperation(operation);
    const { operationName, query, variables } = operation;

    if (graphQLErrors?.length && !ignoreGraphQLErrors) {
      for (const error of graphQLErrors) {
        switch ((error as HayGraphQLError).type) {
          case HayErrorTypes.AccessDeniedException: {
            // INFO: Hay backend returns `AccessDeniedException` error type when the token is expired or unauthenticated
            // We're able to refresh the token and retry the request
            return promiseToObservable(Api.getHayBearerToken()).flatMap(accessToken => {
              operation.setContext({
                headers: {
                  ...operation.getContext().headers,
                  authorization: `Bearer ${accessToken}`,
                },
              });
              return forward(operation);
            });
          }

          default:
            captureMessage(`[GraphQL error][${datasource}][${operationName}]: ${error.message}`, {
              extra: {
                ...error,
                datasource,
                error: JSON.stringify(error),
                response: JSON.stringify(response),
                operationName,
                query: JSON.stringify(query),
                variables,
              },
              level: 'error',
            });
        }
      }

      return;
    }

    if (networkError && !ignoreNetworkErrors) {
      captureMessage(`[Network error][${datasource}][${operationName}]`, {
        extra: {
          ...networkError,
          datasource,
          error: JSON.stringify(networkError),
          response: JSON.stringify(response),
          operationName,
          query: JSON.stringify(query),
          variables,
        },
        level: 'warning',
      });
    }
  },
);
