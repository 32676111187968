import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { InfoCircle } from '@prospa/salt-icons/react';
import { vars } from '@prospa/salt-react-beta';

import { Alert, Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import styles from './ConfirmBillsAlert.module.scss';
import { type ConfirmBillsAlertProps } from './types';

const InfoCircleIcon = (
  <InfoCircle size={24} color={vars.color.icon.info} className={styles.ConfirmBillsAlertIcon} />
);

export const ConfirmBillsAlert = ({ className, numReceivedBills }: ConfirmBillsAlertProps) => {
  const navigate = useNavigate();
  const billsText = useMemo(() => (numReceivedBills === 1 ? 'bill' : 'bills'), [numReceivedBills]);
  const onLinkClick = useCallback(() => navigate(`/bills/review`), [navigate]);

  if (numReceivedBills === 0) return null;

  return (
    <Alert
      icon={InfoCircleIcon}
      className={classNames(styles.ConfirmBillsAlertContainer, className)}
    >
      <div className={styles.ConfirmBillsAlertBody} data-testid="confirm-bills-alert">
        <div>
          We received<b>{` ${numReceivedBills} new ${billsText}. `}</b> Confirm the bill details to
          schedule payment.
        </div>
        <div className={styles.ConfirmBillsAlertLink}>
          <Button
            type={ButtonType.ICON_LINK_UNDERLINE}
            size={ButtonSize.SMALL}
            onClick={onLinkClick}
          >
            Confirm {billsText}
          </Button>
        </div>
      </div>
    </Alert>
  );
};
