import { type FC, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown as ReactstrapDropdown,
} from 'reactstrap';

import classNames from 'classnames';

import { More as IconMore } from '@prospa/salt-icons';

import styles from './Dropdown.module.scss';
import type { DropdownProps } from './types';

export const Dropdown: FC<DropdownProps> = ({
  toggle,
  items,
  toggleProps = {},
  menuProps = {},
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<DropdownProps['isOpen']>(props.isOpen || false);
  const {
    className: toggleClassName,
    children = <IconMore size={24} />,
    ...restToggleProps
  } = toggleProps;
  const { className: menuClassName, ...restMenuProps } = menuProps;

  const handleToggle: DropdownProps['toggle'] = event => {
    setIsOpen(open => !open);

    if (toggle) {
      toggle(event);
    }
  };

  return (
    <ReactstrapDropdown a11y isOpen={isOpen} toggle={handleToggle} {...props}>
      <DropdownToggle
        className={classNames(styles.DropdownToggle, toggleClassName)}
        data-toggle="dropdown"
        tag="button"
        {...restToggleProps}
      >
        {children}
      </DropdownToggle>
      <DropdownMenu className={classNames(styles.DropdownMenu, menuClassName)} {...restMenuProps}>
        {items.map(({ children, className, ...props }, index) => (
          <DropdownItem
            key={index}
            className={classNames(styles.DropdownMenuItem, className)}
            {...props}
          >
            {children}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ReactstrapDropdown>
  );
};
