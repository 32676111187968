import { type ReactElement } from 'react';

import classNames from 'classnames';

import styles from './BannerSection.module.scss';
import type { BannerSectionProps } from './types';

export function BannerSection({
  banners = [],
  alerts = [],
  container,
  swappedSection = false,
}: BannerSectionProps): ReactElement<BannerSectionProps> {
  const bannerEmpty = banners.length === 0;
  const alertEmpty = alerts.length === 0;
  if (bannerEmpty && alertEmpty) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.SectionContainer,
        swappedSection && styles.SectionContainerSwapped,
      )}
      data-container={container}
    >
      {!bannerEmpty && (
        <div
          data-testid="banner-section"
          className={classNames(
            styles.SectionInnerBody,
            swappedSection && styles.SectionInnerBodyBottom,
          )}
        >
          {banners.map(({ Banner }, index) => (
            <Banner key={`${container}-at-${index}`} />
          ))}
        </div>
      )}
      {!alertEmpty && (
        <div
          data-testid="alert-section"
          className={classNames(
            styles.SectionInnerBody,
            !swappedSection && styles.SectionInnerBodyBottom,
          )}
        >
          {alerts.map(({ Alert }, index) => (
            <Alert key={`${container}-at-${index}`} />
          ))}
        </div>
      )}
    </div>
  );
}
