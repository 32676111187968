import { NavLink } from 'react-router-dom';

import classNames from 'classnames';

import { Close as CloseButton, MovingArrows as CollapseButton } from '@prospa/icons';
import Menu from '@prospa/icons/dist/svg/Menu.svg';

import { NavOptions, TemporaryLogoutButton, Tooltip } from '@components';

import logoMobile from '../../../assets/logo-mobile.svg';
import logo from '../../../assets/logo-prospa-dark.svg';
import './Nav.scss';
import { useNavData } from './hooks';
import type { NavBarProps } from './types';

export const Nav = ({ logout }: NavBarProps) => {
  const {
    isMobileAppUser,
    isIntersectingScrollableArea,
    isMobile,
    isOpen,
    isSubNavOpen,
    toggleNavOpen,
    closeMenu,
    setIsSubNavOpen,
  } = useNavData();

  // NOTE: hide the navigation bar on mobile app webview
  if (isMobileAppUser) {
    return null;
  }

  return (
    <>
      <nav
        className={classNames('top-nav', {
          'no-box-shadow': isIntersectingScrollableArea === false,
        })}
      >
        <div className="top-nav__menu-icon" onClick={toggleNavOpen}>
          <img src={Menu} alt="toggle menu" aria-hidden="true" role="presentation" />
        </div>
        <NavLink to="/" aria-label="Prospa" className="top-nav__logo" onClick={closeMenu}>
          <span>
            <img
              src={isMobile ? logoMobile : logo}
              alt="prospa logo"
              aria-hidden="true"
              role="presentation"
            />
          </span>
        </NavLink>
        <TemporaryLogoutButton onClick={logout} />
      </nav>
      <nav className={classNames('left-nav', { 'close-nav': isOpen === false })}>
        <div className="left-nav__logo-nav">
          <NavLink to="/" aria-label="Prospa" className="left-nav__logo" onClick={closeMenu}>
            <span>
              <img
                src={isMobile ? logoMobile : logo}
                alt="prospa logo"
                aria-hidden="true"
                role="presentation"
              />
            </span>
          </NavLink>
          <Tooltip text={isOpen ? 'Close sidebar' : 'Open sidebar'}>
            <div className="left-nav__toggle-nav" onClick={toggleNavOpen}>
              <CollapseButton width={20} height={20} />
              <CloseButton />
            </div>
          </Tooltip>
        </div>
        <NavOptions
          closeMenu={closeMenu}
          isOpen={isOpen}
          isSubNavOpen={isSubNavOpen}
          setIsSubNavOpen={setIsSubNavOpen}
        />
      </nav>
      {isOpen && (
        <nav
          onClick={() => {
            closeMenu();
          }}
          className={classNames('nav-backdrop')}
        ></nav>
      )}
    </>
  );
};
