import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { type User } from 'configcat-react';

import { useGetAppContextDataQuery } from '@generated-fg';

import { useHayTokenLoader } from '@hooks';
import { type AppContextData, ProductTypes } from '@models';
import {
  formatAddressToString,
  isLOCMambu,
  isMobileAppSession,
  isSblmId,
  isUnderMaintenance,
} from '@utils';

import { useFeatureFlags } from './useFeatureFlag';

/**
 * Set App Context in order
 * Step 1: get application context data by query (triggered when app is loaded)
 * Step 2: get hay token (depends on accountId, trigger when accountId is ready)
 * Step 3: set is mobile user by hook (triggered when app is loaded)
 * Step 4: get loan and business account summary by query (triggered when app is loaded)
 * Step 5: set up feature flags by hit config cat (triggered when app is loaded)
 * Step 6: set up redirection when error occurs (depends on appContextError)
 */

const useAppContextData = (): AppContextData => {
  const [appContextError, setAppContextError] = useState<boolean>(false);
  const { user } = useAuth();

  // Step 1: get application context data by query
  const {
    data: {
      user: {
        cdpId,
        businessAccount,
        country,
        smallBusinessLoans = [],
        linesOfCredit = [],
        contracts = [],
        ...identityDetails
      } = {},
    } = {},
    loading: contextDataLoading,
  } = useGetAppContextDataQuery({
    onError: () => {
      setAppContextError(true);
    },
  });

  const {
    id: accountId,
    businessAccountDetailId,
    abn,
    accountName,
    branchNumber: bsb,
    accountNumber,
    status,
    homeAddress,
    overdraft,
  } = businessAccount || {};

  const isMobileAppUser = useMemo(
    () => isMobileAppSession(user?.profile?.amr) || false,
    [user?.profile?.amr],
  );

  // Step 2: get hay token
  const { loading: hayDataLoading } = useHayTokenLoader({
    skip: contextDataLoading ? true : !accountId,
  });

  // TODO: should be migrated to the FE Gateway once SBLM is implemented
  // https://prospa.atlassian.net/browse/NHM-1493

  const businessAccountDetails = useMemo(() => {
    const { line1, townOrCity, administrativeRegion, postcode: postalCode } = homeAddress || {};

    return accountName
      ? {
          accountStatus: status,
          homeAddress: formatAddressToString({
            line1,
            townOrCity,
            administrativeRegion,
            postalCode,
          }),
          businessDetails: {
            abn,
            name: accountName,
          },
          // Migration - Phase 2
          // TODO: should be requested as part of a container-consumer
          ...overdraft,
        }
      : null;
  }, [status, homeAddress, abn, accountName, overdraft]);

  const customerProducts = useMemo(
    () => ({
      BA: !!businessAccountDetails,
      SBL: !!smallBusinessLoans?.length,
      LOCM: linesOfCredit?.some(({ id }) => isLOCMambu(id)) || false,
      LOC: !!linesOfCredit?.length,
      SBLM: smallBusinessLoans?.some(({ id }) => isSblmId(id)),
      CONTRACT: !!contracts?.length,
    }),
    [businessAccountDetails, contracts, linesOfCredit, smallBusinessLoans],
  );

  const productIds: AppContextData['signInInfo']['productIds'] = {};
  if (businessAccountDetails) {
    productIds['BA'] = businessAccountDetailId;
  }
  if (linesOfCredit?.length) {
    productIds['LOC'] = linesOfCredit.map(({ id }) => id);
  }
  if (smallBusinessLoans?.length) {
    productIds['SBL'] = smallBusinessLoans.map(({ id }) => id);
  }

  // Step 5: set up feature flags by hit config cat

  const audience: User = useMemo(() => {
    if (!contextDataLoading && 'id' in identityDetails) {
      const { id: identifier, email } = identityDetails;
      return {
        identifier,
        email,
        country,
        custom: {
          products: Object.keys(customerProducts)
            .filter(key => customerProducts[key])
            .map((key: string) => ProductTypes[key]),
        },
      };
    }
    return null;
  }, [contextDataLoading, country, customerProducts, identityDetails]);

  const {
    loading: featureFlagLoading,
    error: featureFlagError,
    flags: featureFlags,
  } = useFeatureFlags({ audience, skip: !audience });

  const isSystemUnderMaintenance = useMemo(() => {
    return !featureFlagLoading ? isUnderMaintenance(featureFlags.underMaintenance) : false;
  }, [featureFlagLoading, featureFlags.underMaintenance]);

  // Step 6: set up redirection when error occurs
  const navigate = useNavigate();
  useEffect(() => {
    if (appContextError || featureFlagError) {
      navigate('/error');
    }
  }, [appContextError, featureFlagError, navigate]);

  useEffect(() => {
    if (isSystemUnderMaintenance) {
      navigate('/maintenance');
    }
  }, [isSystemUnderMaintenance, navigate]);

  return {
    accountId,
    cdpId,
    signInInfo: {
      isMobileAppUser,
      countryCode: country,
      productIds,
    },
    bsb,
    accountNumber,
    businessAccountDetails,
    identityDetails,
    featureFlags,
    customerProducts,
    isSystemUnderMaintenance,
    appContextLoading: contextDataLoading || hayDataLoading || featureFlagLoading,
  };
};

export default useAppContextData;
