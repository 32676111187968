import { Section } from '@components';

import { BillsHeader } from './BillsHeader';
import type { BillsLayoutProps } from './types';

const BillsLayout = ({ testId, headerProps, children }: BillsLayoutProps) => {
  const { action, title } = headerProps || {};
  return (
    <Section data-testid={`bills-${testId}`}>
      <BillsHeader title={title} action={action} />
      {children}
    </Section>
  );
};
export default BillsLayout;
