import classNames from 'classnames';

import { DocumentArticles as ArticlesIcon } from '@prospa/icons';

import { Card } from '@components';

import { currencyFormatter } from '@utils';

import './StatementAccountInfo.scss';
import type { StatementAccountInfoProps } from './types';

export const StatementAccountInfo = ({
  accountName,
  accountNumber,
  available,
  total,
}: StatementAccountInfoProps) => {
  return (
    <Card className="statement-account-info">
      <ArticlesIcon className="statement-account-info__icon" />
      <div className="statement-account-info__details">
        <p className="pds-text-semibold">{accountName}</p>
        <p className="pds-text">{accountNumber}</p>
      </div>
      <div className="statement-account-info__details">
        <p className="pds-text-semibold">{currencyFormatter(total)}</p>
        <p className={classNames('statement-account-info__available', 'pds-text')}>
          Available: {currencyFormatter(available)}
        </p>
      </div>
    </Card>
  );
};
