import { type GetLocPaymentsAndTransactionsQuery } from '@generated-mg';

import { isLOCMambu } from '@utils';

import { FeesItem } from '../FeesItem/FeesItem';

export const TransactionRepaymentDetails = ({
  lineActivityId,
  subComponents,
  locId,
}: {
  lineActivityId: string;
  subComponents: GetLocPaymentsAndTransactionsQuery['creditLine']['transactions']['itemList'][number]['data']['subComponents'];
  locId: string;
}) => {
  const showRepaymentDetails =
    subComponents &&
    (subComponents.principal > 0 ||
      subComponents.interest > 0 ||
      subComponents.fees > 0 ||
      subComponents.weeklyServiceFee > 0 ||
      subComponents.dishonorFee > 0 ||
      subComponents.penalty > 0);
  if (!showRepaymentDetails) {
    return null;
  }
  const breakdownItems = isLOCMambu(locId)
    ? [
        { key: 'principal', label: 'Principal' },
        { key: 'interest', label: 'Interest' },
        { key: 'weeklyServiceFee', label: 'Weekly service fee' },
        { key: 'penalty', label: 'Late fee' },
        { key: 'dishonorFee', label: 'Dishonour fee' },
      ]
    : [
        { key: 'principal', label: 'Principal' },
        { key: 'interest', label: 'Interest' },
        { key: 'fees', label: 'Fees' },
      ];
  return subComponents ? (
    <ul className="mt-2" key={`${lineActivityId}-repaymentDetails`}>
      {breakdownItems.map(({ key, label }, index) => {
        return (
          subComponents[key] > 0 && (
            <FeesItem
              label={label}
              value={subComponents[key]}
              key={`${lineActivityId}-${key}-${index}`}
            />
          )
        );
      })}
    </ul>
  ) : null;
};
