import classnames from 'classnames';

import { Check } from '@prospa/salt-icons';

import styles from './CVPCardBody.module.scss';
import { type CVPCardBodyProps, type CVPCardBodyValuePointProps } from './types';

export const CVPCardBodyValuePoint = ({ valuePoint }: CVPCardBodyValuePointProps) => {
  return (
    <li className={styles.CVPCardBody__ValuePoints_Item}>
      <div className={styles.CVPCardBody__ValuePoints_Item_Check}>
        <Check size={20} />
      </div>
      <span>{valuePoint}</span>
    </li>
  );
};

export const CVPCardBody = ({
  mediaUrl,
  heading,
  description,
  valuePoints,
  disclaimer,
  className,
  ...props
}: CVPCardBodyProps) => {
  return (
    <>
      <div className={classnames(styles.CVPCardBody, className)} {...props}>
        {mediaUrl && (
          <img
            className={styles.CVPCardBody__Media}
            src={mediaUrl}
            alt="cvp logo"
            aria-hidden="true"
            role="presentation"
          />
        )}
        {heading && <h4 className={styles.CVPCardBody__Heading}>{heading}</h4>}
        {description && <p className={styles.CVPCardBody__Description}>{description}</p>}
      </div>
      {valuePoints && (
        <ul className={styles.CVPCardBody__ValuePoints}>
          {valuePoints.map(valuePoint => (
            <CVPCardBodyValuePoint valuePoint={valuePoint} key={valuePoint} />
          ))}
        </ul>
      )}
      {disclaimer && <div className={styles.CVPCardBody__Disclaimer}>{disclaimer}</div>}
    </>
  );
};
