import { MicroilloFlagMoon } from '@prospa/icons';

import { trackBAActionEvent } from '../../../utils';
import { Modal } from '../Modal';
import './WelcomeModal.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal
      customStyle={{ overlay: { zIndex: 999 } }}
      isOpen={isOpen}
      onClose={() => {
        trackBAActionEvent('welcome_close');
        onClose();
      }}
      showCloseModal
      size="medium"
      body={
        <div className="welcome-modal">
          <MicroilloFlagMoon className="welcome-modal__icon" />
          <h3 className="welcome-modal__title">Improving your online experience</h3>
          <p className="welcome-modal__text">
            Notice some changes to Prospa Online? We hope you like what we’ve done so far. It’s all
            about simplifying your online experience, and there’s more to come soon.
          </p>
        </div>
      }
      primaryCTALabel="OK, got it"
      primaryCTAOnClick={() => {
        trackBAActionEvent('welcome_ok_got_it');
        onClose();
      }}
    />
  );
};

export default WelcomeModal;
