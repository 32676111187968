import { useState } from 'react';

import { Formik } from 'formik';

import { billConfirmFormValidator } from '@utils';

import {
  BillFormSubmissionSection,
  BillPaymentFieldsSection,
  PaymentMethodSection,
} from './components';
import { type BillFormAction, type TConfirmBillFormProps } from './types';

const ConfirmBillForm = ({
  initialFormValues,
  status,
  isNonEditable,
  handleSubmit,
  popupNotification,
  isSubmitting,
  countryCode,
  validationRules,
}: TConfirmBillFormProps) => {
  const [formAction, setFormAction] = useState<BillFormAction>(null);
  return (
    <Formik
      validate={billConfirmFormValidator(validationRules, countryCode)}
      initialValues={{ ...initialFormValues }}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values, formAction, resetForm);
      }}
      validateOnBlur
      validateOnMount
    >
      {({ getFieldProps, setFieldValue, isValid, handleSubmit: defaultSubmit }) => {
        /*
         * @note: highlight payment method error when form is submitted
         */
        return (
          <div test-id="add-bill-form">
            <BillPaymentFieldsSection
              isNonEditable={isNonEditable}
              isReferenceFieldRequired={validationRules?.reference?.isRequired || false}
            />
            <PaymentMethodSection
              billStatus={status}
              countryCode={countryCode}
              isNonEditable={isNonEditable}
              billerName={getFieldProps('billerName').value}
              paymentMethod={getFieldProps('paymentMethod').value}
              setPaymentMethod={value => setFieldValue('paymentMethod', value)}
            />
            <BillFormSubmissionSection
              status={status}
              isValid={isValid}
              isSubmitting={isSubmitting}
              handleFormSubmission={(action: BillFormAction) => {
                setFormAction(action);
                defaultSubmit();
              }}
            />
            {popupNotification}
          </div>
        );
      }}
    </Formik>
  );
};

export default ConfirmBillForm;
