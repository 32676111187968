import { Button, Card, ConnectionStatus, SpinnerContainer } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import { ReactComponent as XeroLogo } from '../../../../assets/xero-logo-small.svg';
import styles from './ConnectToIntegrationCard.module.scss';
import { type ConnectToIntegrationCardProps } from './types';

export const ConnectToIntegrationCard = ({
  integrationProduct,
  status,
  displayStatus,
  description,
  buttonProps,
  integrationDataLoading,
}: ConnectToIntegrationCardProps) => {
  return (
    <Card>
      <SpinnerContainer loading={integrationDataLoading}>
        <p className="pds-lead-semibold">Connect to {integrationProduct}</p>
        <div className={styles.IntegrationCardContainer}>
          <div className={styles.IntegrationCardIcon}>
            <XeroLogo />
          </div>
          <div className={styles.IntegrationCardRight}>
            <ConnectionStatus status={status} displayStatus={displayStatus} />
            <div className={styles.IntegrationCardRightCopy}>
              <p>{description?.map(({ bold, content }) => (!bold ? content : <b>{content}</b>))}</p>
              <Button
                type={ButtonType.ICON_LINK_UNDERLINE}
                size={ButtonSize.MEDIUM}
                className={styles.IntegrationCardButton}
                testId="connect-to-integration-card-button"
                {...buttonProps}
              />
            </div>
          </div>
        </div>
      </SpinnerContainer>
    </Card>
  );
};
