import { type FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { UtilitiesShield as ShieldIcon, Tick as TickIcon } from '@prospa/icons';

import {
  type StepUpInitOutput,
  useStepUpInitMutation,
  useStepUpVerifyMutation,
} from '@generated-fg';

import { Button, CodeInput } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { trackBAActionEvent } from '@utils';

import { AppContext } from '../../../contexts';
import Api from '../../../services/Api';
import { Modal } from '../Modal';
import './SecurityCodeModal.scss';
import type { ModalFooterProps, SecurityCodeModalProps } from './types';

const PIN_LENGTH = 6;
const ERRORS = {
  init: 'Sorry. there seems to be a network error. Please try again later.',
  verify: 'Please check the code and try again',
} as const;

type TErrors = (typeof ERRORS)[keyof typeof ERRORS] | string | null;

export const SecurityCodeModal: FC<SecurityCodeModalProps> = ({
  isCheck2FAStarted,
  closeModal,
  onSuccess,
  loading = false,
  onResend,
  linkedPhoneNumber,
}) => {
  const { identityDetails } = useContext(AppContext);
  const mobileNumber = linkedPhoneNumber || identityDetails?.phoneNumber;

  const phoneNumber = useMemo(
    () => (mobileNumber ? mobileNumber.substring(mobileNumber.length - 3) : null),
    [mobileNumber],
  );
  const [securityCode, setSecurityCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<TErrors>();
  const [wasResent, setWasResent] = useState(false);
  const [stepUpRequest] = useStepUpInitMutation();
  const [stepUpVerifyRequest] = useStepUpVerifyMutation();
  const [twoFactorData, setTwoFactorData] = useState<StepUpInitOutput | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  const check2FA = useCallback(async () => {
    if (!Api.verificationCode) {
      setIsOpen(true);
      return;
    }

    try {
      const {
        data: { stepUpVerify },
      } = await stepUpVerifyRequest({ variables: { code: Api.verificationCode } });

      if (stepUpVerify.__typename === 'StepUpVerifyErrorOutput') {
        Api.verificationCode = null;
        setIsOpen(true);
        return;
      }

      const { token: stepUpVerifyToken } = stepUpVerify;
      Api.prospaStepUpToken = stepUpVerifyToken;
      if (onSuccess) await onSuccess(stepUpVerifyToken);
    } catch (error) {
      setIsOpen(true);
      return;
    }
  }, [onSuccess, stepUpVerifyRequest]);

  const init2FA = useCallback(async () => {
    try {
      setError(null);

      const {
        data: { stepUpInit: stepUpInitRes },
        errors,
      } = await stepUpRequest();

      if (errors) throw errors;
      setTwoFactorData(stepUpInitRes);
    } catch (error) {
      setError(ERRORS.init);
      trackBAActionEvent('pay-anyone_validation-error', 'event_action:pay-anyone_transfer-2FA');
    }
  }, [stepUpRequest]);

  useEffect(() => {
    if (isCheck2FAStarted) {
      check2FA();
    }
  }, [isCheck2FAStarted, check2FA]);

  useEffect(() => {
    if (isOpen) {
      init2FA();
    }
  }, [init2FA, isOpen]);

  const handleResend = useCallback(async () => {
    init2FA();
    setWasResent(true);
    if (onResend) onResend();
  }, [init2FA, onResend]);

  const resetModal = useCallback(() => {
    setIsLoading(false);
    setError(null);
    setSecurityCode(null);
    setTwoFactorData(null);
    setWasResent(false);
    setIsOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    if (closeModal) closeModal();
    resetModal();
  }, [closeModal, resetModal]);

  const handleSubmit = useCallback(async () => {
    try {
      if (twoFactorData) {
        setIsLoading(true);
        setError(null);
        Api.verificationCode = securityCode;

        const {
          data: { stepUpVerify },
        } = await stepUpVerifyRequest({ variables: { code: securityCode } });

        if (stepUpVerify.__typename === 'StepUpVerifyErrorOutput') {
          throw stepUpVerify.error;
        }

        const { token: stepUpVerifyToken } = stepUpVerify;
        Api.prospaStepUpToken = stepUpVerifyToken;
        if (onSuccess) await onSuccess(stepUpVerifyToken);

        resetModal();
      }
    } catch (error) {
      setIsLoading(false);

      setError(ERRORS.verify);
    }
  }, [twoFactorData, securityCode, onSuccess, resetModal, stepUpVerifyRequest]);

  const primaryButtonProps = useMemo(() => {
    const isButtonLoading = isLoading || loading;
    const isButtonDisabled = !securityCode || error === ERRORS.init || isButtonLoading;
    return {
      label: 'Verify',
      onClick: handleSubmit,
      loading: isButtonLoading,
      disabled: isButtonDisabled,
    };
  }, [handleSubmit, isLoading, loading, securityCode, error]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      testId="security-code-modal"
      body={
        <div className="security-code">
          <ShieldIcon />
          <h4 className="security-code__header">Enter your security code</h4>
          <p className="security-code__text pds-text">
            A text message with a {PIN_LENGTH}-digit security code has been sent to
            {phoneNumber ? (
              <>
                {` **** *** `}
                <span className="phone-number-text">{phoneNumber}</span>
              </>
            ) : (
              ' your registered mobile number.'
            )}
          </p>
          <CodeInput
            fields={6}
            onChange={setSecurityCode}
            isValid={error !== ERRORS.verify}
            autoFocus={isOpen}
            error={error}
          />
        </div>
      }
      primaryButtonProps={primaryButtonProps}
      showCloseModal
      footer={<MemoirizedModalFooter resent={wasResent} onResend={handleResend} />}
    />
  );
};

const ModalFooter: FC<ModalFooterProps> = ({ resent, onResend }) =>
  resent ? (
    <span>
      <TickIcon width={16} height={16} />
      <span className="security-code__footer-resent pds-text">Your new code has been resent</span>
    </span>
  ) : (
    <span data-testid="resend-code-message" className="security-code__footer pds-text">
      Didn't receive the code?{' '}
      <Button type={ButtonType.LINK} size={ButtonSize.MEDIUM} onClick={onResend} label="Resend" />
    </span>
  );

const MemoirizedModalFooter = memo(ModalFooter);
