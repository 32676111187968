import { type ChangeEvent, type FC, useCallback, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import classNames from 'classnames';

import {
  ChevronUp as DropdownIcon,
  Plus as PlusIcon,
  Search as SearchIcon,
} from '@prospa/salt-icons';

import { Button, PayAnyoneMenuOption, PayeeDetails, SpinnerContainer } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import './PayAnyoneDropdown.scss';
import { getFuzzyPayeeMatch } from './helpers';
import type { PayAnyoneDropdownProps } from './types';

const payAnyonePlaceholder = 'Select an account or add new payee';

export const PayAnyoneDropdown: FC<PayAnyoneDropdownProps> = ({
  payeeList,
  setCurrentPayee,
  onDeletePayee,
  addNewPayeeOnClick,
  currentPayee,
  isDropdownActive,
  payeeLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);

  const resetState = useCallback(() => {
    setSearchTerm('');
    setMenuOpen(false);
    setSearchOpen(false);
  }, []);

  const ref = useOnclickOutside(resetState);

  const showPayee = !!currentPayee && !searchOpen;

  const onDropdownClick = useCallback(() => {
    if (!menuOpen) {
      // eslint-disable-next-line no-constant-binary-expression
      !searchOpen && setSearchOpen(true && isDropdownActive);
    }
    setMenuOpen(!menuOpen && isDropdownActive);
  }, [isDropdownActive, menuOpen, searchOpen]);

  const onInputBlur = useCallback(() => {
    if (searchTerm === '' && !currentPayee) {
      searchOpen && setSearchOpen(false);
    }
  }, [currentPayee, searchOpen, searchTerm]);

  const onInputFocus = useCallback(() => {
    !menuOpen && setMenuOpen(true);
  }, [menuOpen]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      if (e.target.value !== '') {
        !menuOpen && setMenuOpen(true);
      }
    },
    [menuOpen],
  );

  const payeeActive = currentPayee && !searchOpen;

  return (
    <div
      className={classNames('payanyone-dropdown__container', {
        'payanyone-dropdown__container__disabled': !isDropdownActive,
      })}
      onClick={onDropdownClick}
      ref={ref}
    >
      <SpinnerContainer
        className="payanyone-dropdown"
        data-payee={showPayee}
        data-search-open={searchOpen}
        loading={payeeLoading}
      >
        {payeeActive ? (
          <PayeeDetails currentPayee={currentPayee} />
        ) : (
          <>
            <div className="payanyone-dropdown__icon">
              <SearchIcon
                size={24}
                className={classNames({
                  'payanyone-dropdown__search-icon-freeze': !isDropdownActive,
                })}
              />
            </div>
            {searchOpen ? (
              <div className="payanyone-dropdown__search">
                <span className={classNames('payanyone-dropdown__search-header', 'pds-caption')}>
                  To
                </span>
                <input
                  autoFocus
                  className={classNames('payanyone-dropdown__search-input', 'pds-text')}
                  onChange={onInputChange}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  value={searchTerm}
                />
              </div>
            ) : (
              <div
                className={classNames('payanyone-dropdown__search-placeholder', 'pds-text')}
                onClick={() => {
                  setSearchOpen(true);
                }}
              >
                <span>{payAnyonePlaceholder}</span>
              </div>
            )}
          </>
        )}
        {isDropdownActive && (
          <DropdownIcon
            size={24}
            data-open={menuOpen && isDropdownActive}
            data-payee={showPayee}
            className="payanyone-dropdown__dropdown-icon"
          />
        )}
      </SpinnerContainer>

      {menuOpen && (
        <ul className="payanyone-dropdown__menu">
          <li className="payanyone-dropdown__menu-button">
            <Button
              type={ButtonType.ICON_LINK}
              size={ButtonSize.MEDIUM}
              onClick={addNewPayeeOnClick}
              label="New payee"
            >
              <PlusIcon size={24} className="payanyone-dropdown__add-new-payee-icon" />
            </Button>
          </li>
          {payeeList
            ?.filter(payee => getFuzzyPayeeMatch(payee, searchTerm))
            .map(payee => (
              <PayAnyoneMenuOption
                onDelete={onDeletePayee}
                key={payee.id}
                payee={payee}
                onClick={() => {
                  setCurrentPayee(payee);
                  resetState();
                }}
              />
            ))}
        </ul>
      )}
    </div>
  );
};
