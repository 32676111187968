import { type ReactNode, createContext, useCallback, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Check as CheckIcon } from '@prospa/salt-icons';
import { vars } from '@prospa/salt-react-beta';

import { Toast } from '@components';

interface Props {
  children: ReactNode;
}

interface ToastContextModal {
  addToast: (message?: string, icon?: React.FC) => void;
}

export interface ToastType {
  message: string;
  id: string;
  icon?: React.FC;
}
const defaultValue: ToastContextModal = {
  addToast: () => {
    //
  },
};

export const ToastContext = createContext(defaultValue);

export const ToastContextProvider = ({ children }: Props) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const addToast = useCallback(
    (
      message: string,
      icon: React.FC = () => <CheckIcon color={vars.color.base.white} data-testid="tick-icon" />,
    ) => {
      setToasts(toasts => [...toasts, { message, icon, id: uuidv4() }]);

      setTimeout(
        () =>
          setToasts(([_toRemove, ...updatedToasts]) => {
            return updatedToasts;
          }),
        5000, // should match CSS animation duration
      );
    },
    [],
  );

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <Toast toasts={toasts} />
    </ToastContext.Provider>
  );
};
