import { HttpLink, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import fetch from 'cross-fetch';

import { feGatewayOperationsSet, mobileGatewayOperationsSet } from '@constants';

import environments from '../../environments';

export const batchHttpLink =
  // FE gateway doesn't allow to batch requests
  // https://www.apollographql.com/docs/react/api/link/introduction/#directional-composition
  split(
    ({ operationName }) => feGatewayOperationsSet.has(operationName),
    new HttpLink({
      uri: () => `${environments.feGateway.apiUrl}/graphql`,
    }),
    new BatchHttpLink({
      uri: ({ operationName }) => {
        const url = mobileGatewayOperationsSet.has(operationName)
          ? environments.mobileGateway.apiUrl
          : environments.hay.apiUrl;

        return `${url}/graphql`;
      },
      batchMax: 5,
      batchInterval: 20,
      fetch,
    }),
  );
