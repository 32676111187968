/* eslint-disable @typescript-eslint/no-unused-expressions */
export type TGetClaritySessionUrl = {
  sessionId?: string | number;
  projectId?: string;
  userId?: string | number;
};
export const getClaritySessionUrl = ({ sessionId, projectId, userId }: TGetClaritySessionUrl) => {
  if ((sessionId || userId) && projectId) {
    // Clarity recording checking url by sessionId, userId and projectId,
    // If official url adjusted need to update here as well.
    const url = new URL(
      'https://clarity.microsoft.com/projects/view/' + projectId + '/impressions?',
    );

    !!sessionId && url.searchParams.append(`CustomSessionId`, `is;${sessionId}`);
    !!userId && url.searchParams.append(`CustomUserId`, `is;${userId}`);

    return url.toString();
  }
  return '';
};
