import styles from './Status.module.scss';
import { StatusLevels, type StatusProps } from './types';

// TODO: should be merged with the Badge component with `pill: true` property
export function Status({ label, level, ...props }: StatusProps) {
  return (
    <span className={`status ${styles.Status} ${styles[StatusLevels[level]]}`} {...props}>
      {label}
    </span>
  );
}
