import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Cross } from '@prospa/salt-icons';

import {
  GetBillDetailsDocument,
  type GetBillDetailsQuery,
  type GetBillDetailsQueryVariables,
  GetBillsDocument,
  useConfirmBillDetailMutation,
} from '@generated-fg';

import { useToastContext } from '@hooks';
import { trackBAActionEvent, updateBillStatusAfterConfirmBillDetail } from '@utils';

import { type TUseConfirmBillActionProps } from './types';

export function useConfirmBillAction() {
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const [confirmBill, { loading: isConfirmBillLoading }] = useConfirmBillDetailMutation({
    refetchQueries: [GetBillsDocument],
  });

  const handleConfirmBill = useCallback(
    ({ scheduleNow, input, billId, onSuccess }: TUseConfirmBillActionProps) => {
      const values = input;
      confirmBill({
        variables: {
          id: billId,
          input: values,
        },
        update: (cache, changedFields) => {
          cache.updateQuery<GetBillDetailsQuery, GetBillDetailsQueryVariables>(
            {
              query: GetBillDetailsDocument,
              variables: { id: billId },
              overwrite: true,
            },
            data =>
              updateBillStatusAfterConfirmBillDetail(
                data,
                changedFields.data?.updateBill,
                scheduleNow,
              ),
          );
        },
        onCompleted: () => {
          onSuccess();
          if (scheduleNow) {
            trackBAActionEvent('bill_confirm__click', `amt:${values.amount}`);
            navigate(`/bill/${billId}/pay`);
          } else {
            // NOTE: solution for day 1 may changed in future [no-ticket-yet]
            trackBAActionEvent('bill_confirmed_unscheduled', `amt:${values.amount}`);
            navigate('/bills');
          }
        },
        onError: error => {
          trackBAActionEvent('bill_error', error.message);
          addToast('An error occurred. Please try again later.', Cross);
        },
      });
    },
    [confirmBill, navigate, addToast],
  );

  return {
    isConfirmBillLoading,
    handleConfirmBill,
  };
}
