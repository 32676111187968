// TODO: this is container component, should be moved to containers [AW-1120]
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';

import { Cross } from '@prospa/salt-icons';

import {
  BillStatus,
  BusinessAccountSchedulePaymentType,
  BusinessAccountScheduledPaymentStatus,
  useCancelScheduledPaymentMutation,
  useGetBusinessAccountScheduledPaymentsQuery,
} from '@generated-fg';

import { Button, Section, Tabs, TransactionsLoader } from '@components';

import { ButtonSize, ButtonType, INVALID_BA_SCHEDULE_TRANSACTION_STATUS_SET } from '@constants';
import { PayAnyoneTabs } from '@constants';
import { useAppLogger, useBillsData, useToastContext } from '@hooks';
import { getBillsReferenceIdsMap, getTransactionTrackingDetails, trackBAActionEvent } from '@utils';

import Api from '../../../services/Api';
import BusinessAccountTransactionList from '../Transactions/Transactions';
import { useBATransactionListData } from '../hooks';
import { type ScheduledPayment } from '../types';
import './Activity.scss';
import type { ActivityProps } from './types';

const ScheduledPayments = lazy(() => import('../ScheduledPayments/ScheduledPayments'));

export enum OpenModalType {
  CancelPaymentConfirm,
  SecurityModal,
  Error,
}

const Activity = ({ id: accountId, refetchBalance }: ActivityProps) => {
  const { addToast } = useToastContext();
  const { logMessage } = useAppLogger();
  const [loadingMore, setLoadingMore] = useState(false);

  const {
    loading: scheduledPaymentLoading,
    data: scheduledPaymentsData,
    refetch: refetchScheduledPayments,
  } = useGetBusinessAccountScheduledPaymentsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !accountId,
  });
  const { bills } = useBillsData({ status: [BillStatus.Scheduled] });
  const [cancelScheduledPayment] = useCancelScheduledPaymentMutation();

  const onCancelScheduledPayment = async (
    scheduledPayment: ScheduledPayment,
    setOpenModal: (openModalType: OpenModalType) => void,
  ) => {
    try {
      const { data } = await cancelScheduledPayment({
        variables: {
          input: {
            productId: accountId,
            transactionId: scheduledPayment.id,
            verificationCode: Api.verificationCode,
          },
        },
      });
      if (data.cancelScheduledPayment.success) {
        addToast('Scheduled payment cancelled');
        refetchScheduledPayments();
        trackBAActionEvent(
          'activity_cancelled',
          `${
            scheduledPayment.frequency
              ? ''
              : 'transaction_frequency:' + scheduledPayment.frequency + '_'
          }activity_type:scheduled`,
        );
        trackBAActionEvent(
          'pay-anyone_payment-delete',
          getTransactionTrackingDetails({
            amount: scheduledPayment.amount.toString(),
            currentTab:
              scheduledPayment.type === BusinessAccountSchedulePaymentType.OneTime
                ? PayAnyoneTabs.PAY_LATER
                : PayAnyoneTabs.RECURRING,
            paymentDate: scheduledPayment.startDate,
            paymentFrequency: scheduledPayment?.frequency?.toLowerCase() ?? '',
          }),
        );
      } else {
        logMessage('[onCancelScheduledPayment][cancelScheduledPayment]', {
          extra: {
            response: data,
            scheduledPayment,
          },
        });
        setOpenModal(OpenModalType.Error);
      }
    } catch (error) {
      setOpenModal(OpenModalType.Error);
    }
  };

  const billReferenceIdsMap = useMemo<ReturnType<typeof getBillsReferenceIdsMap>>(
    () => getBillsReferenceIdsMap(bills),
    [bills],
  );

  const scheduledPayments = scheduledPaymentsData?.user?.businessAccount?.scheduledTransactions
    ?.filter(({ status }) => !INVALID_BA_SCHEDULE_TRANSACTION_STATUS_SET.has(status))
    .map(scheduledPayment => ({
      ...scheduledPayment,
      billId: billReferenceIdsMap.get(scheduledPayment.id),
    }))
    .reverse();

  const onTabChange = (tab: number) => {
    if (tab === 0) {
      refetchBalance();
      setTabName('transactions');
    } else {
      setTabName('scheduled');
    }
  };

  useEffect(() => {
    if (scheduledPayments) {
      setScheduledPaymentsLength(
        scheduledPayments.filter(
          payment => payment.status === BusinessAccountScheduledPaymentStatus.Active,
        ).length,
      );
    }
  }, [scheduledPayments]);

  const [scheduledPaymentsLength, setScheduledPaymentsLength] = useState(0);

  const { hasMoreItems, fetchMore, loadingTransactions, transactions } = useBATransactionListData();

  const handleFetchMore = useCallback(() => {
    setLoadingMore(true);
    fetchMore()
      .catch(() => {
        addToast('Failed to load more transactions', Cross);
      })
      .finally(() => setLoadingMore(false));
  }, [addToast, fetchMore]);

  const [tabName, setTabName] = useState<'transactions' | 'scheduled'>('transactions');
  return (
    <span className="activity">
      <Section>
        <div className="activity__wrapper">
          <Tabs
            type="primary"
            onTabSelect={onTabChange}
            tabs={[
              {
                tabHeader: 'Transactions',
                tab: (
                  <BusinessAccountTransactionList
                    loading={loadingTransactions}
                    transactions={transactions}
                  />
                ),
              },
              {
                tabHeader: `Scheduled ${
                  !scheduledPaymentLoading ? `(${scheduledPaymentsLength})` : ''
                }`,
                tab: (
                  <Suspense fallback={<TransactionsLoader />}>
                    <ScheduledPayments
                      loading={scheduledPaymentLoading}
                      scheduledPaymentsData={scheduledPayments}
                      onCancelScheduledPayment={onCancelScheduledPayment}
                      useProspaStepUpFlow
                    />
                  </Suspense>
                ),
              },
            ]}
          />
        </div>
      </Section>

      {tabName === 'transactions' && hasMoreItems && transactions.length > 0 && (
        <Button
          type={ButtonType.ICON_LINK_UNDERLINE}
          size={ButtonSize.LARGE}
          className="nofocus"
          onClick={handleFetchMore}
          label="Load more"
          loading={loadingMore}
          disabled={loadingMore}
        />
      )}
    </span>
  );
};

export default Activity;
