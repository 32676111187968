import { type Location, matchPath } from 'react-router-dom';

const documentTitle = `Prospa Online`;

const pathToTitle = {
  '/': 'Home',
  '/ba': 'Business Account',
  '/add-funds': 'Add Funds',
  '/statements': 'Statements',
  '/settings': 'Settings',
  '/settings/connect-to-xero': 'Connect to Xero',
  '/cards': 'Cards',
  '/pay': 'Pay',
  '/sbl/:applicationId': 'Small Business Loan',
  '/drawdown': 'Drawdown',
  '/sblm/:applicationId': 'Small Business Loan',
  '/products': 'Products',
  '/loc/:applicationId': 'Line Of Credit',
  '/bills': 'Bills',
  '/bills/review': 'Confirm Bills',
  '/bill/:billId/pay': 'Pay',
  '/bill/:billId/*': 'Bill Details',
  '/repayment/loc/*': 'Repayments',
} as const;

const pagePaths = Object.keys(pathToTitle);

const pageTitles = Object.values(pathToTitle);

export const getPageTitleByPathname = (
  pathname: Location['pathname'],
): (typeof pageTitles)[number] => {
  const pageTitle = pagePaths.find(path => matchPath({ path, end: true }, pathname));
  return pathToTitle[pageTitle];
};

export const getDocumentTitleByPathname = ({ pathname }: Location): string => {
  if (!pathname) {
    return documentTitle;
  }
  const pageTitle = getPageTitleByPathname(pathname);
  return pageTitle ? `${pageTitle} - ${documentTitle}` : documentTitle;
};

export const clearHistoryState = (pathName: string): void => {
  window.history.replaceState({}, '', pathName);
};
