import type { HTMLAttributes, ReactNode } from 'react';

export enum StatusLevels {
  primary = 'Primary',
  warning = 'Warning',
  error = 'Error',
  cleared = 'Cleared',
  pending = 'Pending',
}

export interface StatusProps extends HTMLAttributes<HTMLElement> {
  label: ReactNode;
  level: keyof typeof StatusLevels;
}
