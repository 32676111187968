import { Analytics, GAService, SegmentService } from '@all-in-one-web/analytics';

import { BA_GTM_ID } from '@constants';

export const getSegmentWriteKey = () => {
  return window?.__env?.environment === 'prod'
    ? process.env.REACT_APP_SEGMENT_WRITE_KEY
    : process.env.REACT_APP_SEGMENT_WRITE_KEY_DEV;
};

export const SegmentSingleton = new SegmentService(
  {
    writeKey: getSegmentWriteKey(),
  },
  {
    initialPageview: false,
  },
);

export const GoogleAnalyticsSingleton = new GAService({ GTM_ID: BA_GTM_ID });

const AnalyticsSingleton = new Analytics(SegmentSingleton, GoogleAnalyticsSingleton);

export default AnalyticsSingleton;
