import { CalendarAlert } from '@prospa/salt-illustrations';

import { currencyFormatter, formatDateInDayMonthYear } from '@utils';

import { Modal as ReactModal } from '../Modal';
import styles from './LineOfCreditAccruedDetailModal.module.scss';
import type {
  AccountDeferralDetail,
  LineOfCreditAccruedDetailModalProps as Props,
  TAccruedDetail,
} from './types';

const ModalHead = ({ totalDeferralAmount, deferralPeriodEndDate }: AccountDeferralDetail) => {
  return (
    <div className={styles.ModalHeadContainer}>
      <CalendarAlert size={80} />
      <p className={styles.ModalHeadTitle}>{currencyFormatter(totalDeferralAmount)} accrued</p>
      <span className={styles.ModalHeadCopy}>
        The accrued amount will be direct debited when your deferral period ends on{' '}
        {formatDateInDayMonthYear(deferralPeriodEndDate)}
      </span>
    </div>
  );
};

const ModalBody = ({ accruedDetails = [] }: { accruedDetails?: TAccruedDetail[] }) => {
  if (!accruedDetails) return null;
  return (
    <div className={styles.ModalBodyContainer}>
      <ul className={styles.ModalBodyList}>
        {accruedDetails.map(item => (
          <li className={styles.ModalBodyListItem} key={item.label}>
            <span className={styles.ModalBodyListItemLabel}>{item.label}</span>
            <span className={styles.ModalBodyListItemValue}>{currencyFormatter(item.value)}</span>
          </li>
        ))}
      </ul>
      <p className={styles.ModalBodyFooter}>
        This figure will increase due to accrued interest over your deferral period
      </p>
    </div>
  );
};

export const LineOfCreditAccruedDetailModal = ({
  isOpen = false,
  onClose,
  deferralDetail,
  accruedDetail = [],
}: Props) => {
  return (
    <ReactModal
      size="medium"
      isOpen={isOpen}
      onClose={onClose}
      showCloseModal
      primaryCTALabel="OK, got it"
      primaryCTAOnClick={onClose}
      className={styles.Modal}
      body={
        <div data-testid="loc-accrued-detail">
          <ModalHead {...deferralDetail} />
          <ModalBody accruedDetails={accruedDetail} />
        </div>
      }
    />
  );
};
