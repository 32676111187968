import { Button, Card, RowDetails } from '@components';

import { ButtonSize, ButtonType, HELP_CENTRE_URL } from '@constants';
import { externalRedirect, trackBAActionEvent } from '@utils';

import type { SupportDetailsProps } from './types';

export const SupportDetails = ({ className }: SupportDetailsProps) => {
  const onClick = () => {
    trackBAActionEvent('settings_support_help_click');
    externalRedirect({ url: HELP_CENTRE_URL, openInNewTab: true });
  };

  return (
    <Card className={className}>
      <p className="pds-lead-semibold">Support</p>
      <RowDetails
        header="Help Centre"
        body={
          <p>
            Have questions about your account? Check out our useful{' '}
            <Button
              size={ButtonSize.MEDIUM}
              type={ButtonType.ICON_LINK_UNDERLINE}
              onClick={onClick}
            >
              Help Centre
            </Button>
          </p>
        }
      />
    </Card>
  );
};
