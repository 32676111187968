import { useContext, useEffect, useMemo, useState } from 'react';

import { compareAsc, compareDesc, format } from 'date-fns';

import { Country } from '@generated-fg';

import { AppContext } from '../../contexts';
import { useStateInLocalStorage } from '../../hooks/useStateInLocalStorage';
import { NzMobileCampaign } from './campaigns/NzMobileCampaign/NzMobileCampaign';
import {
  type CampaignBody,
  type CampaignReleasePlan,
  FullPageCampaignCode,
  type FullPageCampaignHook,
} from './types';

// NOTES: to add new campaign, add the campaign code and the campaign body to the mapCodeWithCampaign
const campaignCodeBodyMap = new Map<FullPageCampaignCode, CampaignBody>([
  [FullPageCampaignCode.NZ_MOBILE_APP, NzMobileCampaign],
]);

export function useFullPageCampaign(campaignCode: FullPageCampaignCode): FullPageCampaignHook {
  const {
    fullPageCampaigns: { viewedFullPageCampaigns, setFullPagedCampaigns },
  } = useStateInLocalStorage();

  const {
    signInInfo: { countryCode, isMobileAppUser },
    featureFlags: { mobileAppBanners },
  } = useContext(AppContext);

  const releasePlan = useMemo(() => {
    return new Map<FullPageCampaignCode, CampaignReleasePlan>([
      [
        FullPageCampaignCode.NZ_MOBILE_APP,
        {
          releaseDate: {
            ...mobileAppBanners,
          },
          countries: [Country.Nz],
        },
      ],
    ]);
  }, [mobileAppBanners]);
  const [showCampaign, setShowCampaign] = useState(false);
  const theReleasePlan = releasePlan.get(campaignCode);

  useEffect(() => {
    if (!theReleasePlan) {
      return;
    }
    const {
      releaseDate: { startTime, endTime },
      countries,
    } = theReleasePlan;

    const isActiveInCountry = countries?.includes(countryCode as Country);
    const isInReleaseTimeRange =
      compareAsc(new Date(format(new Date(), 'yyyy-MM-dd')), new Date(startTime)) >= 0 &&
      compareDesc(new Date(format(new Date(), 'yyyy-MM-dd')), new Date(endTime)) >= 0;
    const neverVisitBefore = !viewedFullPageCampaigns?.includes(campaignCode);
    const showCampaign =
      isInReleaseTimeRange && neverVisitBefore && isActiveInCountry && !isMobileAppUser;
    setShowCampaign(showCampaign);
  }, [campaignCode, countryCode, theReleasePlan, viewedFullPageCampaigns, isMobileAppUser]);

  const onCampaignClose = () => {
    setFullPagedCampaigns(campaignCode);
    setShowCampaign(false);
  };

  const campaignBody = campaignCodeBodyMap.get(campaignCode);

  return [showCampaign, onCampaignClose, campaignBody];
}
