import type { ReactElement } from 'react';

import classNames from 'classnames';

import { Button } from '@components';

import { ButtonSize, ButtonType, LOAN_TRANSACTION } from '@constants';

import styles from './LoadMoreButton.module.scss';
import type { LoadMoreButtonProps } from './types';

export const LoadMoreButton = ({
  total,
  pageSize,
  currentPage,
  onLoadMore,
}): ReactElement<LoadMoreButtonProps> => {
  const totalPages = Math.ceil(total / pageSize);
  if (totalPages === currentPage || totalPages === 0) {
    return null;
  }
  return (
    <div className={classNames(styles.LoadMoreButton)}>
      <Button
        type={ButtonType.ICON_LINK_UNDERLINE}
        size={ButtonSize.LARGE}
        onClick={onLoadMore}
        className={`${styles.Button} nofocus`}
      >
        {LOAN_TRANSACTION.LOAD_MORE}
      </Button>
    </div>
  );
};

export default LoadMoreButton;
