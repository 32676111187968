import { Fragment, useEffect } from 'react';

import classNames from 'classnames';

import { Card, TransactionsLoader } from '@components';

import { convertToMonth, trackBAActionEvent } from '@utils';

import ScheduledPaymentItem from './ScheduledPaymentItem/ScheduledPaymentItem';
import './ScheduledPayments.scss';
import type { ScheduledPaymentsProps } from './types';

const ScheduledPayments = ({
  scheduledPaymentsData = [],
  onCancelScheduledPayment,
  useProspaStepUpFlow = true,
  loading,
}: ScheduledPaymentsProps) => {
  useEffect(() => {
    trackBAActionEvent('activity_view', 'activity_type:scheduled_view_depth:summary');
  }, []);

  const renderScheduledList = () => {
    const months: { [key: string]: boolean } = {};
    return scheduledPaymentsData.map(payment => {
      const currentMonth = convertToMonth(payment.startDate);
      if (!months[currentMonth]) {
        months[currentMonth] = true;
        return (
          <Fragment key={payment.id}>
            <li
              key={currentMonth}
              className={classNames('payments__list--item-month', 'pds-text-semibold')}
            >
              {currentMonth}
            </li>
            <ScheduledPaymentItem
              key={payment.id}
              scheduledPayment={payment}
              onCancelScheduledPayment={onCancelScheduledPayment}
              useProspaStepUpFlow={useProspaStepUpFlow}
            />
          </Fragment>
        );
      } else {
        return (
          <ScheduledPaymentItem
            key={payment.id}
            scheduledPayment={payment}
            useProspaStepUpFlow={useProspaStepUpFlow}
            onCancelScheduledPayment={onCancelScheduledPayment}
          />
        );
      }
    });
  };

  return (
    <Card className="payments">
      {loading ? (
        <TransactionsLoader />
      ) : scheduledPaymentsData && scheduledPaymentsData.length ? (
        <ul className="payments__list">{renderScheduledList()}</ul>
      ) : (
        <p className="payments__empty-list">You have 0 Scheduled Payments</p>
      )}
    </Card>
  );
};

export default ScheduledPayments;
