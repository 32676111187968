import type { PropsWithChildren } from 'react';

import { Spinner } from '../Spinner/Spinner';
import styles from './SpinnerContainer.module.scss';
import type { SpinnerContainerProps } from './types';

export const SpinnerContainer = ({
  loading = false,
  children,
  ...props
}: PropsWithChildren<SpinnerContainerProps>) => (
  <div {...props}>
    {loading ? (
      <div className={styles.Spinner}>
        <Spinner />
      </div>
    ) : (
      children
    )}
  </div>
);
