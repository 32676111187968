import { type RefObject, useCallback, useEffect, useRef, useState } from 'react';

/**
 * Detect whether the mouse is hovering an element.
 * T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
 * ref: https://usehooks.com/useHover/
 * @returns tuple(array) with type [RefObject<T>, boolean]
 */
function useHover<T extends HTMLElement>(): [RefObject<T> | null, boolean] {
  const [isHover, setIsHover] = useState<boolean>(false);
  const ref = useRef<T | null>(null);
  const handleMouseOver = useCallback(() => setIsHover(true), []);
  const handleMouseOut = useCallback(() => setIsHover(false), []);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current], // Recall only if ref changes
  );
  return [ref, isHover];
}

export { useHover };
