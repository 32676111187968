import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as Braze from '@braze/web-sdk';
import type { InitializationOptions, subscribeToContentCardsUpdates } from '@braze/web-sdk';
import type { CaptionedImage, ClassicCard, ImageOnly } from '@braze/web-sdk';

import { filterContentCards, noop, removeCardById, sortContentCards } from './utils';

export type ContentCard = ClassicCard | CaptionedImage | ImageOnly;

export type ContentCards = ContentCard[];

export type ContentCardPlatforms = 'app' | 'web_customer' | 'web_partner';

export type ContentCardLocations =
  | 'customer_home_top'
  | 'customer_home_below_products'
  | 'customer_explore_top'
  | 'partner_home_top'
  | 'anon_explore_top';

const API_URL = 'https://sdk.iad-05.braze.com';

type BrazeContextProps = {
  apiKey: string;
  platform: ContentCardPlatforms;
  userId?: string; // aka cdpId
  enableInAppMessages?: boolean;
  initialisationOptions?: InitializationOptions;
  onContentCardClick: (card: ContentCard) => void;
  onContentCardDismiss: (card: ContentCard) => void;
  onContentCardView: (card: ContentCard) => void;
};

type BrazeContextState = {
  cards: ContentCards;
  onClick: (card: ContentCard) => void;
  onDismiss: (card: ContentCard) => void;
  onView: (card: ContentCard) => void;
};

const BrazeContext = createContext<BrazeContextState>({
  cards: [],
  onClick: noop,
  onDismiss: noop,
  onView: noop,
});

export const BrazeProvider = (props: PropsWithChildren<BrazeContextProps>) => {
  const [cards, setCards] = useState<ContentCard[]>([]);
  const [isBrazeInit, setIsBrazeInit] = useState(false);

  const subscriber: Parameters<typeof subscribeToContentCardsUpdates>[0] = useCallback(
    updates => {
      setCards(filterContentCards(updates.cards as ContentCards, props.platform));
    },
    [props.platform],
  );

  // If `requestContentCardsRefresh` fails (e.g. due to rate limit reached), we try to get the cached data
  const refreshErrorCallback = useCallback(() => {
    const cards = Braze.getCachedContentCards();
    subscriber(cards);
  }, [subscriber]);

  useEffect(() => {
    if (!isBrazeInit) {
      const initialized = Braze.initialize(props.apiKey, {
        baseUrl: API_URL,
        allowUserSuppliedJavascript: true,
        ...props.initialisationOptions,
      });

      if (!initialized) {
        throw new Error('Braze SDK failed to initialize');
      }

      Braze.subscribeToContentCardsUpdates(subscriber);

      if (props.enableInAppMessages) {
        Braze.automaticallyShowInAppMessages();
      }
      Braze.openSession();

      Braze.requestContentCardsRefresh(undefined, refreshErrorCallback);

      setIsBrazeInit(true);
    }
  }, [
    isBrazeInit,
    props.apiKey,
    subscriber,
    props.enableInAppMessages,
    props.initialisationOptions,
    refreshErrorCallback,
  ]);

  useEffect(() => {
    if (isBrazeInit && props.userId) {
      Braze.changeUser(props.userId);
      Braze.requestContentCardsRefresh(undefined, refreshErrorCallback);
    }
  }, [isBrazeInit, refreshErrorCallback, subscriber, props.userId]);

  const onView = useCallback(
    async (card: ContentCard) => {
      Braze.logContentCardImpressions([card]);
      props.onContentCardView(card);
    },
    [props],
  );

  const onClick = useCallback(
    async (card: ContentCard) => {
      Braze.logContentCardClick(card);
      props.onContentCardClick(card);
    },
    [props],
  );

  const onDismiss = useCallback(
    async (card: ContentCard) => {
      setCards(prevCards => removeCardById(prevCards, card.id));
      Braze.logCardDismissal(card);
      Braze.requestContentCardsRefresh();
      props.onContentCardDismiss(card);
    },
    [props],
  );

  return (
    <BrazeContext.Provider
      value={{
        cards,
        onView,
        onClick,
        onDismiss,
      }}
    >
      {props.children}
    </BrazeContext.Provider>
  );
};

const useBraze = () => useContext(BrazeContext);

/**
 * Hook to fetch content cards specific to a location.
 *
 * Usage: const { cards, onView, onClick, onDismiss } = useLocationContentCards('customer_home_top');
 *
 * @param location
 */
export function useLocationContentCards(location: ContentCardLocations) {
  const { cards, onView, onClick, onDismiss } = useBraze();

  const locationCards = useMemo(
    () => cards.filter(c => c.extras?.location === location).sort(sortContentCards),
    [cards, location],
  );

  return {
    cards: locationCards,
    onView,
    onClick,
    onDismiss,
  };
}
