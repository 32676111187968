import { useCallback } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { useGetBaPendingTransactionsQuery, useGetBaSettledTransactionsQuery } from '@generated-fg';

import type { Transaction } from '@models';
import { mapPendingBATransactions, mapSettledBATransactions, trackBAActionEvent } from '@utils';

interface UseBATransactionListDataReturnType {
  hasMoreItems: boolean;
  fetchMore: () => Promise<void>;
  loadingTransactions: boolean;
  transactions: Transaction[];
}

const TRANSACTIONS_LIMIT = 10;

export function useBATransactionListData(): UseBATransactionListDataReturnType {
  const {
    loading: settledTransactionsLoading,
    data: settledTransactionsData,
    fetchMore,
  } = useGetBaSettledTransactionsQuery({
    variables: {
      after: undefined,
      first: TRANSACTIONS_LIMIT,
    },
  });

  const {
    user: {
      businessAccount: {
        transactions: {
          edges: transactions = [],
          pageInfo: { hasNextPage = undefined, endCursor = undefined } = {},
        } = {},
      } = {},
    } = {},
  } = settledTransactionsData || {};

  const settledTransactions = mapSettledBATransactions(transactions ?? []);

  // NOTE: can be merged with useGetBaSettledTransactionsQuery into a single query (e.g. useGetBaTransactionsQuery)
  const { loading: pendingTransactionsLoading, data: pendingTransactionData } =
    useGetBaPendingTransactionsQuery();

  const pendingTransactions = mapPendingBATransactions(
    pendingTransactionData?.user?.businessAccount?.pendingTransactions ?? [],
  );

  const handleFetchMore = useCallback(async () => {
    await fetchMore({
      variables: {
        after: endCursor,
        first: TRANSACTIONS_LIMIT,
      },
      updateQuery(previousData, { fetchMoreResult }) {
        const updatedFeed = cloneDeep(previousData);
        const { edges, pageInfo } = fetchMoreResult.user.businessAccount.transactions;

        updatedFeed.user.businessAccount.transactions.edges = [
          ...updatedFeed.user.businessAccount.transactions.edges,
          ...edges,
        ];
        updatedFeed.user.businessAccount.transactions.pageInfo = pageInfo;
        return updatedFeed;
      },
    });

    trackBAActionEvent('activity_view', 'activity_type:transactions_view_depth:detail');
  }, [fetchMore, endCursor]);

  return {
    hasMoreItems: hasNextPage,
    fetchMore: handleFetchMore,
    loadingTransactions: settledTransactionsLoading || pendingTransactionsLoading,
    transactions: [...settledTransactions, ...pendingTransactions],
  };
}
