import classNames from 'classnames';

import { CopyText } from '../CopyText/CopyText';
import styles from './CopyTextWithLabel.module.scss';
import { type CopyTextWithLabelProps } from './types';

export const CopyTextWithLabel = ({ label, value, onCopy, className }: CopyTextWithLabelProps) => {
  return (
    <div className={classNames(styles.CopyTextWithLabelContainer, className)}>
      <div className={styles.CopyTextWithLabelContent}>
        <span className={styles.CopyTextWithLabelLabel}>{label}</span>
        <span className={styles.CopyTextWithLabelValue}>{value}</span>
      </div>
      <CopyText className={styles.CopyTextWithLabelCopyIcon} toCopy={value} onCopy={onCopy} />
    </div>
  );
};
