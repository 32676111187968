import { useMemo, useState } from 'react';

import classNames from 'classnames';
import { format } from 'date-fns';

import './TransactionItem.scss';
import type { TransactionItemProps } from './types';
import { formatTransactionAmount, getExpandedTransactionDetails } from './utils';

export const TransactionItem = ({ transaction, hideTime = false }: TransactionItemProps) => {
  const [expandDetails, setExpandDetails] = useState<boolean>(false);
  const transactionMonth = format(new Date(transaction?.date), 'MMM');
  const transactionDate = format(new Date(transaction?.date), 'dd');

  const expandedTransactionDetails = useMemo(() => {
    if (transaction.additionalDetails) {
      return transaction.additionalDetails;
    }
    return getExpandedTransactionDetails(transaction, hideTime);
  }, [transaction, hideTime]);

  return (
    <li
      className={classNames('transaction-item-container', { expanded: expandDetails })}
      onClick={() => {
        setExpandDetails(!expandDetails);
      }}
    >
      <div className="transaction-item__hover-container">
        <div className="transaction-item">
          <div className="transaction-item__date transaction-item__left">
            <div className={classNames('pds-lead', { 'pds-lead-semibold': expandDetails })}>
              {transactionDate}
            </div>
            <div className={classNames('transaction-item__date__month', { active: expandDetails })}>
              {transactionMonth}
            </div>
          </div>
          <div className="transaction-item__right">
            <div className="transaction-item__main">
              <div
                className={classNames('transaction-item__data', {
                  'transaction-item__failure': transaction.isFailure,
                })}
              >
                <div className={classNames('pds-lead', { 'pds-lead-semibold': expandDetails })}>
                  {transaction?.displayName}
                </div>
                <div className="transaction-item__transferPeerDetails">
                  <div
                    className={classNames('transaction-item__description', 'pds-text', {
                      active: expandDetails,
                    })}
                  >
                    {transaction?.description}
                  </div>
                </div>
              </div>
              <div className="transaction-item__values">
                <div
                  className={classNames('transaction-item__amount', 'pds-lead', {
                    'pds-lead-semibold': expandDetails,
                  })}
                >
                  {transaction.isFailure
                    ? ''
                    : formatTransactionAmount(transaction.amount, transaction.sign)}
                </div>
                {transaction?.pending && (
                  <div
                    className={classNames('transaction-item__status', 'pds-text', {
                      'pds-lead-semibold': expandDetails,
                    })}
                  >
                    Pending
                  </div>
                )}
              </div>
            </div>
            {expandDetails && expandedTransactionDetails}
          </div>
        </div>
      </div>
    </li>
  );
};
