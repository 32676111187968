import { useNavigate } from 'react-router-dom';

import SlothSVG from '@prospa/icons/dist/svg/MicroilloSloth.svg';

import { Button, ErrorPageContainer, NeedHelpContact } from '@components';

import { ButtonSize, ButtonType } from '@constants';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <ErrorPageContainer
      animationData={SlothSVG}
      assetType="svg"
      title="This page is going nowhere"
      footer={<NeedHelpContact />}
    >
      <p>
        For the information you need, try your dashboard by clicking on ‘Return home’ or contact
        Prospa directly.
      </p>
      <Button type={ButtonType.PRIMARY} size={ButtonSize.LARGE} onClick={() => navigate('/')}>
        Return home
      </Button>
    </ErrorPageContainer>
  );
};

export default PageNotFound;
