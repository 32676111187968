import type { FC } from 'react';
import ReactModal from 'react-modal';

import classNames from 'classnames';

import { Cross as CrossIcon } from '@prospa/salt-icons';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import './Modal.scss';
import type { ModalProps } from './types';

export const Modal: FC<ModalProps> = ({
  isOpen,
  shouldCloseOnOverlayClick = false,
  size = 'small',
  heading,
  isLoading = false,
  allowSubmit = true,
  primaryCTAOnClick,
  primaryCTALabel,
  primaryButtonProps = {},
  secondaryCTALabel,
  secondaryCTAOnClick,
  secondaryButtonProps = {},
  footer,
  body,
  showCloseModal,
  onClose,
  hideFooterBorder = false,
  customStyle = {},
  className,
  headerClassName = '',
  bodyClassName = '',
  footerClassName = '',
  ...rest
}) => {
  const showFooter = Boolean(
    footer ||
      secondaryCTALabel ||
      secondaryButtonProps.label ||
      primaryCTALabel ||
      primaryButtonProps.label,
  );

  return (
    <ReactModal
      style={customStyle}
      onRequestClose={onClose}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={classNames({ 'react-modal': true, [size]: true }, className)}
      overlayClassName="react-modal__overlay"
      {...rest}
    >
      <div className={classNames('react-modal__header', 'h4', headerClassName)}>
        {heading}
        {showCloseModal && (
          <Button
            type={ButtonType.ICON_LINK}
            size={ButtonSize.MEDIUM}
            className="react-modal__header-icon"
            onClick={onClose}
          >
            <CrossIcon />
          </Button>
        )}
      </div>
      <div
        className={classNames(
          'react-modal__body',
          { 'hide-footer-border': hideFooterBorder },
          bodyClassName,
        )}
      >
        {body}
      </div>
      {showFooter && (
        <div className={classNames('react-modal__footer', footerClassName)}>
          {footer}
          <div className="react-modal__footer-buttons">
            {(secondaryCTALabel || secondaryButtonProps.label) && (
              <Button
                type={ButtonType.SECONDARY}
                size={ButtonSize.MEDIUM}
                onClick={secondaryCTAOnClick}
                label={secondaryCTALabel}
                {...secondaryButtonProps}
              />
            )}
            {(primaryCTALabel || primaryButtonProps.label) && (
              <Button
                type={ButtonType.PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={primaryCTAOnClick}
                label={primaryCTALabel}
                loading={isLoading}
                disabled={!allowSubmit}
                {...primaryButtonProps}
              />
            )}
          </div>
        </div>
      )}
    </ReactModal>
  );
};

// Bind for accessibility to hide main content (https://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== 'test' && document.getElementById('root')) {
  ReactModal.setAppElement(document.getElementById('root'));
}
