import Api from '../services/Api';

export type TDownloadFileUsingLink = {
  name: string;
  content: Blob | MediaSource;
  extension?: string;
};

export const downloadFileUsingLink = ({ name, content, extension }: TDownloadFileUsingLink) => {
  const url = window.URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}${extension ? `.${extension}` : ''}`);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};

export type TDownloadFileUsingForm = { url: string; fileName: string; openInNewTab: boolean };

export const downloadFileUsingForm = ({ url, fileName, openInNewTab }: TDownloadFileUsingForm) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.target = openInNewTab ? '_blank' : '_self';
  form.action = url;
  form.innerHTML = `<input type="hidden" name="__authToken" value="${Api.token}">`;
  form.innerHTML += `<input type="hidden" name="fileName" value="${fileName}">`;
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
