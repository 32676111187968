import { type FC } from 'react';

import { Check } from '@prospa/salt-pictograms';

import { Button, Card } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import './PaymentConfirmed.scss';
import type { PaymentConfirmedProps } from './types';

export const PaymentConfirmed: FC<PaymentConfirmedProps> = ({
  title,
  subTitle,
  displayData: body = [],
  submitButtonProps,
}) => {
  return (
    <Card className="paymentconfirmed">
      <header className="paymentconfirmed__header">
        <Check />
        <p className="paymentconfirmed__header-details">
          <span className="pds-lead">{title}</span>
          {subTitle && <span className="pds-text">{subTitle}</span>}
        </p>
      </header>

      {body.length ? (
        <div className="paymentconfirmed__body">
          {body.map(({ text, value }) => (
            <div key={text} className="paymentconfirmed__body-row">
              <p className="paymentconfirmed__body-row-heading pds-text-semibold">{text}</p>
              <p className="paymentconfirmed__body-row-heading-value pds-text">{value}</p>
            </div>
          ))}
        </div>
      ) : null}
      <footer className="paymentconfirmed__footer">
        <Button type={ButtonType.PRIMARY} size={ButtonSize.MEDIUM} {...submitButtonProps} />
      </footer>
    </Card>
  );
};

export default PaymentConfirmed;
