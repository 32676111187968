import { Fragment, useEffect, useMemo } from 'react';

import classNames from 'classnames';
import orderBy from 'lodash/orderBy';

import { Card, TransactionItem, TransactionsLoader } from '@components';

import { type Transaction } from '@models';
import { convertToMonth, trackBAActionEvent } from '@utils';

import './Transactions.scss';
import { type BusinessAccountTransactionListProps } from './types';

const BusinessAccountTransactionList = ({
  loading,
  transactions,
}: BusinessAccountTransactionListProps) => {
  const groupedTransactions: Transaction[] = useMemo(
    () => orderBy(transactions, transaction => new Date(transaction.dateGroup), ['desc']),
    [transactions],
  );

  useEffect(() => {
    trackBAActionEvent('activity_view', 'activity_type:transactions_view_depth:summary');
  }, []);

  const renderTransactionsList = () => {
    const months: { [key: string]: boolean } = {};
    return groupedTransactions.map(transactionRecord => {
      const currentMonth = convertToMonth(transactionRecord.date);
      if (!months[currentMonth]) {
        months[currentMonth] = true;
        return (
          <Fragment key={transactionRecord.id}>
            <li
              key={currentMonth}
              className={classNames('transactions__list--item-month', 'pds-text-semibold')}
            >
              {currentMonth}
            </li>
            <TransactionItem key={transactionRecord.id} transaction={transactionRecord} />
          </Fragment>
        );
      } else {
        return <TransactionItem key={transactionRecord.id} transaction={transactionRecord} />;
      }
    });
  };

  const emptyState = <p className="transactions__empty-list">You have 0 Transactions</p>;

  const listOfTransactions = <ul className="transactions__list">{renderTransactionsList()}</ul>;

  return (
    <Card className="transactions">
      {loading ? (
        <TransactionsLoader />
      ) : groupedTransactions.length > 0 ? (
        listOfTransactions
      ) : (
        emptyState
      )}
    </Card>
  );
};

export default BusinessAccountTransactionList;
