import { useCallback } from 'react';

import { Cross } from '@prospa/icons';

import {
  type StatementFileType,
  StatementProductType,
  StatementType,
  useGetStatementLazyQuery,
} from '@generated-fg';

import { Card, Spinner } from '@components';

import { PRODUCT_TYPES, SAVE_AS_RESPONSE } from '@constants';
import { useAppLogger, useToastContext } from '@hooks';
import { saveAs, trackBAActionEvent } from '@utils';

import { ProductTypes } from '../../models/Account';
import styles from './PastStatements.module.scss';
import { StatementRow } from './StatementRow';
import type { PastStatementsProps, THandleRowClickProps } from './types';

export const PastStatements = ({
  monthly = [],
  yearly = [],
  loading,
  account,
}: PastStatementsProps) => {
  const { addToast } = useToastContext();
  const { logMessage } = useAppLogger();
  const [getStatement] = useGetStatementLazyQuery({
    context: {
      ignoreNetworkErrors: true,
      ignoreGraphQLErrors: true,
    },
    onCompleted: ({
      user: {
        statement: { url, filename },
      },
    }) => {
      saveAs(url, filename).catch(err => addToast(err, Cross));
    },
    onError: error => {
      addToast(SAVE_AS_RESPONSE.ERROR, Cross);
      logMessage(`[getStatement]: ${SAVE_AS_RESPONSE.FAILURE_LOG}`, {
        extra: { error },
      });
    },
    fetchPolicy: 'network-only',
  });

  const handleRowClick = useCallback(
    ({ title, startDate, endDate, statementType }: THandleRowClickProps) =>
      async (fileType: StatementFileType) => {
        trackBAActionEvent('bank_statements_past-statements', title);

        let type;
        switch (account.productType) {
          case ProductTypes.SBL:
            type = StatementProductType.SmallBusinessLoan;
            break;
          case ProductTypes.LOC:
          case ProductTypes.LOCM:
            type = StatementProductType.LineOfCredit;
            break;
          case ProductTypes.SBLM:
            type = StatementProductType.SmallBusinessLoanMambu;
            break;
          case ProductTypes.BA:
            type = StatementProductType.BusinessAccount;
            break;
        }

        await getStatement({
          variables: {
            id: type === StatementProductType['BusinessAccount'] ? undefined : account.id,
            startDate,
            endDate,
            fileType,
            type,
            statementType,
          },
        });
      },
    [account.id, account.productType, getStatement],
  );

  const showCsv = account.name !== PRODUCT_TYPES.SBL && account.name !== PRODUCT_TYPES.LOC;
  const noStatements = !monthly.length && !yearly.length;

  return (
    <Card className={styles.PastStatements}>
      <h3 className="pds-lead-semibold">Past statements</h3>
      {loading && <Spinner />}
      {!!noStatements && !loading && (
        <p className={styles.PastStatementsEmpty}>
          There are no statements for this account just yet.
        </p>
      )}
      {yearly.map(({ id, title, startDate = '', endDate = '' }) => (
        <StatementRow
          key={id}
          title={title}
          onClick={handleRowClick({
            title,
            startDate,
            endDate,
            statementType: StatementType.Yearly,
          })}
          showCsv={showCsv}
        />
      ))}
      {monthly.map(({ id, title, startDate = '', endDate = '' }) => (
        <StatementRow
          key={id}
          title={title}
          onClick={handleRowClick({
            title,
            startDate,
            endDate,
            statementType: StatementType.Monthly,
          })}
          showCsv={showCsv}
        />
      ))}
    </Card>
  );
};
