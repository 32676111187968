import { type FC } from 'react';

import classNames from 'classnames';

import { Cross as CrossButton, Info, Warning } from '@prospa/salt-icons';

import styles from './Alert.module.scss';
import type { AlertProps, TMessageIconProps } from './types';

const MessageIcon: FC<TMessageIconProps> = ({ alertType }: TMessageIconProps) => {
  switch (alertType) {
    case 'Default': // different background color
    case 'Info':
      return <Info size={24} />;
    case 'Warning': // different background color
    case 'Danger':
    default:
      return <Warning size={24} />;
  }
};

function Alert({
  icon,
  alertType = 'Default',
  children,
  className,
  onClose: onDismiss,
  onClick,
  dismissIcon = <CrossButton size={24} />,
}: AlertProps) {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.Alert, styles[alertType], className)}
      data-testid={`alert-${alertType.toLowerCase()}`}
    >
      <div className={styles.AlertLogo}>{icon || <MessageIcon alertType={alertType} />}</div>
      {children}
      {onDismiss && (
        <div className={styles.AlertCloseButton} role="button" onClick={onDismiss}>
          {dismissIcon}
        </div>
      )}
    </div>
  );
}

export default Alert;
