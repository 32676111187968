import { type Integration } from '@sentry/types';

import { getClaritySessionUrl } from '../utils';

const INTEGRATION_NAME = 'ClaritySentryIntegration';
export const ClaritySentryIntegration = (projectId: string): Integration => {
  return {
    name: INTEGRATION_NAME,
    setupOnce() {
      // Do nothing. Keeping it here only for
      // complying with the backward type definition under v7
    },
    processEvent(event) {
      const sessionId = event.user?.sessionId;
      const userId = event.user?.id;
      const clarityUrl = getClaritySessionUrl({ projectId, sessionId, userId });
      if (typeof window.clarity === 'function' && clarityUrl) {
        window.clarity('upgrade', 'Sentry Error Session');
        event.contexts = {
          ...event.contexts,
          clarity: {
            clarityUrl,
          },
        };
      }
      return event;
    },
  };
};
