import { useMemo } from 'react';

import { useGetContractQuery } from '@generated-fg';

import { type ContractData, type UseContractProps } from './types';

export const useContract = ({ contractId }: UseContractProps): ContractData => {
  const { data: { user: { contract } = {} } = {}, loading } = useGetContractQuery({
    variables: { contractId: contractId },
    errorPolicy: 'all',
  });

  const status = useMemo(() => (!contract ? null : contract.status), [contract]);

  return { status, loading };
};
