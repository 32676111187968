import { createContext } from 'react';

import type { AppData } from '../models/AppData';

const APP_CONTEXT_INITIAL_VALUE: AppData = {
  accountId: '',
  signInInfo: {
    isMobileAppUser: false,
    countryCode: 'AU',
    productIds: {},
  },
  bsb: '',
  accountNumber: '',
  businessAccountDetails: null,
  identityDetails: null,
  customerProducts: {
    BA: false,
    SBL: false,
    LOC: false,
  },
  featureFlags: null,
  appContextLoading: true,
  isSystemUnderMaintenance: false,
};

export const AppContext = createContext<AppData>(APP_CONTEXT_INITIAL_VALUE);
