import { Banner } from '@components';

import { ButtonType } from '@constants';

import { ReactComponent as XeroLogo } from '../../../../assets/xero-logo-small.svg';
import { type XeroBannerProps } from './types';

export const XeroBanner = ({ onPrimaryClick, onSecondaryClick }: XeroBannerProps) => {
  return (
    <Banner
      icon={<XeroLogo />}
      title="Connect to Xero"
      subtitle="Automatically sync your future transactions to Xero"
      buttonProps={{
        label: 'Connect',
        onClick: onPrimaryClick,
      }}
      secondaryButtonProps={{
        label: 'Not now',
        onClick: onSecondaryClick,
        type: ButtonType.SECONDARY_OUTLINE,
      }}
    />
  );
};
