import { useContext } from 'react';

import { IntegrationProduct, useGetIntegrationsDataV2Query } from '@generated-fg';

import { AppContext } from '../../contexts';
import { type IntegrationsDataProps } from './types';

export const useIntegrationsDataV2 = (type = IntegrationProduct.Xero): IntegrationsDataProps => {
  const {
    featureFlags: { xeroIntegration },
  } = useContext(AppContext);

  const {
    data: { user: { integrationsV2 } = {} } = {},
    loading,
    refetch: refetchIntegrationsDataV2,
  } = useGetIntegrationsDataV2Query({
    fetchPolicy: 'cache-and-network',
    variables: {
      type,
    },
    skip: !xeroIntegration,
  });

  const dataCalculation = {
    syncedAccountsNumber: integrationsV2?.products?.filter(item => item.isSynced).length || 0,
    totalEligibleAccountsNumber: integrationsV2?.products?.length || 0,
  };

  return {
    loading,
    dataCalculation,
    integrationsV2,
    refetchIntegrationsDataV2,
  };
};
