export const validationConfig = {
  currencyInput: {
    pattern: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    maxDecimalPlacesPattern: /^(\d*\.{0,1}\d{0,2}$)/,
  },
  fourDigitNumber: {
    pattern: /\d{4}/,
  },
  sixDigitNumber: {
    pattern: /\d{6}/,
  },
  positiveFloatNumber: {
    pattern: /(^[+]?([0-9]+)(\.[0-9]+)?)$/,
  },
  floatNumber: {
    pattern: /(^[-+]?([0-9]+)(\.[0-9]+)?)$/,
  },
  isNumber: {
    pattern: /^\d+$/,
  },
  isNZBankAccount: {
    pattern: /^\d{2}-\d{4}-\d{7}-\d{2,3}$/,
  },
  noSpecialCharacters: {
    pattern: /^([a-zA-Z0-9])[\w \\/-]*$/,
  },
  paymentDescription: {
    pattern: /^[a-zA-Z0-9\w \\/-]*$/,
  },
  paymentReference: {
    pattern: /^[\w\s#&?,:\-/.]*$/,
  },
  bankAccountName: {
    pattern: /^([a-zA-Z0-9&])[\w &'-]*$/,
  },
  numberOfPayments: {
    pattern: /^[1-9]\d*$/,
  },
  bsbWithDash: {
    pattern: /^\d{3}-\d{3}$/,
  },
};
