import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getDesktopHeaderButtonConfig } from '@utils';

export const useDesktopHeaderButtonConfig = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const config = useMemo(() => getDesktopHeaderButtonConfig(navigate), [navigate]);
  const buttonConfig = useMemo(() => config[location?.pathname], [config, location?.pathname]);
  return { buttonConfig };
};
