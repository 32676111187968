import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  type GetAccountsListDataQuery,
  LineOfCreditContractStatus,
  SmallBusinessLoanContractStatus,
  SmallBusinessLoanType,
  useGetAccountsListDataQuery,
} from '@generated-fg';

import {
  type AccountItem,
  type AccountListProps,
  AccountStatusModals,
  type BankAccountDetail,
  type ContractDetail,
  type CreditLineDetail,
  type SblAccountDetail,
} from '@components/types';

import { PRODUCT_TYPES, creditLinesRejectedStatus } from '@constants';
import { ProductTypes } from '@models';
import {
  type LegacyEventActions,
  externalRedirect,
  filterSBLDashboardItems,
  getCustomerPortalRoute,
  getInternalPortalRouteUrl,
  isLOCMambu,
  trackBAActionEvent,
} from '@utils';

import { AppContext } from '../../contexts';
import { type TSblHandleClick } from './types';

/**
 * use these method to generate a common structure for AccountList component
 * @returns
 */
export const useAccountItems = (): AccountListProps => {
  const [activatedModal, setActivatedModal] = useState<AccountStatusModals | null>(null);
  const navigate = useNavigate();

  const {
    featureFlags: { locProductPage },
    accountNumber,
    bsb,
    businessAccountDetails,
    signInInfo: { countryCode },
  } = useContext(AppContext);

  const {
    data: {
      user: {
        businessAccount,
        smallBusinessLoans: loans = [],
        linesOfCredit: creditLines = [],
        contracts = [],
      } = {},
    } = {},
    loading: productsLoading,
  } = useGetAccountsListDataQuery({
    errorPolicy: 'all',
  });

  const {
    id: accountId,
    businessAccountDetailId,
    overdraft: { overdraftLimit = undefined } = {},
    balance: {
      available: { amount: available = undefined } = {},
      total: { amount: total = undefined } = {},
    } = {},
  } = businessAccount || {};

  const businessAccountHandleClick = useCallback(() => {
    trackBAActionEvent('ba_tile_click');
    navigate('ba');
  }, [navigate]);

  const businessAccountItem = useMemo(
    () =>
      accountNumber
        ? {
            accountDetail: {
              id: accountId,
              businessAccountDetailId,
              bsb,
              accountNumber,
              productType: ProductTypes.BA,
              businessAccountStatus: businessAccountDetails?.accountStatus,
              balance: {
                total,
                available,
              },
              overdraftLimit,
            } as BankAccountDetail,
            onClick: businessAccountHandleClick,
          }
        : null,
    [
      accountNumber,
      accountId,
      businessAccountDetailId,
      bsb,
      businessAccountDetails?.accountStatus,
      total,
      available,
      overdraftLimit,
      businessAccountHandleClick,
    ],
  );

  const creditLinesHandleClick = useCallback(
    ({
      id,
      contractStatus,
    }: Pick<
      GetAccountsListDataQuery['user']['linesOfCredit'][number],
      'id' | 'contractStatus'
    >) => {
      return () => {
        trackBAActionEvent('loc_tile_click');

        switch (contractStatus) {
          case LineOfCreditContractStatus.PendingFirstSignature:
            externalRedirect({ url: getCustomerPortalRoute('loc', id) });
            return;
          case LineOfCreditContractStatus.PendingSecondSignature:
            setActivatedModal(AccountStatusModals.LOAN_SECOND_SIGNATURE);
            return;
          case LineOfCreditContractStatus.Awaiting:
            setActivatedModal(AccountStatusModals.LOAN_AWAITING);
            return;
          default: {
            if (locProductPage) {
              navigate(`/loc/${id}`);
              return;
            }

            // TODO: can be removed now
            externalRedirect({ url: getCustomerPortalRoute('loc', id) });
          }
        }
      };
    },
    [locProductPage, navigate],
  );

  const locs = useMemo(
    () =>
      creditLines.reduce<AccountItem<CreditLineDetail>[]>(
        (
          acc,
          {
            id,
            status,
            displayStatus,
            contractStatus,
            isInArrears,
            inArrearsAmount,
            totalAmount: { amount: total },
            availableAmount: { amount: available },
            pendingAmount: { amount: pending },
            outstandingAmount,
          },
        ) => {
          if (!creditLinesRejectedStatus.has(status)) {
            const { amount: outstanding } = outstandingAmount || {};
            const locType = isLOCMambu(id) ? ProductTypes.LOCM : ProductTypes.LOC;

            acc.push({
              accountDetail: {
                id,
                total,
                available,
                outstanding,
                pending,
                productType: locType,
                status,
                accountStatus: displayStatus,
                contractStatus,
                isInArrears,
                inArrearsAmount,
              },
              onClick: creditLinesHandleClick({ id, contractStatus }),
            });
          }

          return acc;
        },
        [],
      ),

    [creditLines, creditLinesHandleClick],
  );

  const sblHandleClick: TSblHandleClick = useCallback(
    ({ id, contractStatus, type }) => {
      return () => {
        const productPrefix = type === SmallBusinessLoanType.CloudLending ? 'sbl' : 'sblm';
        const trackEvent: keyof typeof LegacyEventActions = `${productPrefix}_tile_click`;

        trackBAActionEvent(trackEvent);

        switch (contractStatus) {
          case SmallBusinessLoanContractStatus.Pending:
            return externalRedirect({
              url: getCustomerPortalRoute(productPrefix, id),
              openInNewTab: false,
            });
          case SmallBusinessLoanContractStatus.PendingSecondSignature:
            return setActivatedModal(AccountStatusModals.LOAN_SECOND_SIGNATURE);
          case SmallBusinessLoanContractStatus.Awaiting:
            return setActivatedModal(AccountStatusModals.LOAN_AWAITING);
          default:
            return navigate(getInternalPortalRouteUrl(productPrefix, id));
        }
      };
    },
    [navigate],
  );

  const smallBusinessLoans = useMemo(
    () =>
      loans.reduce<AccountItem<SblAccountDetail>[]>(
        (
          acc,
          {
            id,
            type,
            displayStatus,
            contractStatus,
            loanAmount: { amount: loanAmount },
            outstandingAmount: { amount: outstandingAmount },
            isInArrears,
            inArrearsAmount,
            collectionsPaymentUrl,
            isRefinanceEligible,
          },
        ) => {
          if (filterSBLDashboardItems(contractStatus)) {
            acc.push({
              accountDetail: {
                id,
                loanAmount,
                balance: outstandingAmount,
                available: 0,
                productType:
                  type === SmallBusinessLoanType.CloudLending
                    ? ProductTypes.SBL
                    : ProductTypes.SBLM,
                productName: PRODUCT_TYPES.SBL,
                accountStatus: displayStatus,
                contractStatus,
                isInArrears,
                inArrearsAmount,
                collectionsPaymentUrl,
                isRefinanceEligible,
                displayStatus,
              },
              onClick: sblHandleClick({ id, contractStatus, type }),
            });
          }
          return acc;
        },
        [],
      ),
    [loans, sblHandleClick],
  );

  const mappedContracts: AccountItem<ContractDetail>[] = (contracts || []).map(contract => {
    return {
      accountDetail: contract,
    };
  });

  return {
    businessAccount: {
      ...businessAccountItem,
      loading: productsLoading,
    },
    creditLines: { list: locs, loading: productsLoading },
    loans: { list: smallBusinessLoans, loading: productsLoading },
    contracts: { list: mappedContracts, loading: productsLoading },
    statusModalControls: {
      activatedModal,
      closeModal: () => setActivatedModal(null),
    },
    accountListUIProps: {
      countryCode,
    },
  };
};
