import classNames from 'classnames';

import { ButtonType } from '../../constants';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { Section } from '../Section/Section';
import styles from './CVPCard.module.scss';
import { type CVPCardProps } from './types';

export const CVPCard = ({ title, body, primaryButtonProps }: CVPCardProps) => (
  <Section>
    <h2 className={styles.CVPCard__Heading}>{title}</h2>
    <Card className={styles.CVPCard__Card}>
      {body}
      {!!primaryButtonProps && (
        <div className={styles.CVPCard__Footer}>
          <Button
            {...primaryButtonProps}
            type={primaryButtonProps.type || ButtonType.PRIMARY}
            className={classNames(styles.CVPCard__Footer_Button, primaryButtonProps.className)}
          />
        </div>
      )}
    </Card>
  </Section>
);
