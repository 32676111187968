import { BusinessAccountStatus } from '@generated-fg';

import styles from './AccountList.module.scss';
import { AccountStatusModalSection } from './AccountStatusModalSection';
import {
  BusinessAccountListCard,
  BusinessAccountListCardAlerts,
  BusinessAccountListCardFooter,
} from './ProductLists/BusinessAccountCard';
import { LineOfCreditList } from './ProductLists/LineOfCreditList';
import { SmallBusinessLoanList } from './ProductLists/SmallBusinessLoanList';
import { type AccountListProps } from './types';

const AccountList = ({
  creditLines,
  loans,
  businessAccount,
  accountListUIProps: { countryCode },
  statusModalControls,
}: AccountListProps) => {
  const {
    accountDetail,
    onClick,
    loading: baLoading,
    integrationSyncError,
    addFundsFooter,
  } = businessAccount || {};
  return (
    <div className={styles.AccountList}>
      <BusinessAccountListCard
        accountDetail={accountDetail}
        onClick={onClick}
        loading={baLoading}
        alerts={
          !!(
            accountDetail?.businessAccountStatus === BusinessAccountStatus.Frozen ||
            integrationSyncError?.show
          ) && (
            <BusinessAccountListCardAlerts
              displayStatus={accountDetail.businessAccountStatus}
              integrationSyncError={integrationSyncError}
              hasFooter={!!addFundsFooter}
            />
          )
        }
        footer={
          !!addFundsFooter && <BusinessAccountListCardFooter addFundsFooter={addFundsFooter} />
        }
      />
      <LineOfCreditList {...creditLines} countryCode={countryCode} />
      <SmallBusinessLoanList {...loans} countryCode={countryCode} />
      <AccountStatusModalSection {...statusModalControls} />
    </div>
  );
};

export default AccountList;
