import type { Location } from 'react-router-dom';

import {
  type LogEvent,
  transformTrackingParamsObjectToString,
  transformTrackingStringToParamsObject,
} from '@all-in-one-web/analytics';

import type { PreferenceType } from '@components/types';

import { CURRENT_TAB_TO_PAYMENT_TYPE_MAP } from '@constants';
import type { PayAnyoneTabs } from '@constants';
import type { AccountItem } from '@models';
import { calculateOverdraft, captureMessage, getPageTitleByPathname } from '@utils';

import Analytics from '../services/Analytics';

/**
 * Event Names
 */
export enum EventActions {
  ACTIVITY_CANCELLED = 'Activity Cancelled',
  ACTIVITY_PRINTED = 'Activity Printed',
  ACTIVITY_SEARCHED = 'Activity Searched',
  ACTIVITY_VIEWED = 'Activity Viewed',
  APP_BANNER_CLICKED = 'App Banner Clicked',
  BA_GET_STARTED_CLICKED = 'BA Get Started Clicked',
  BA_PRODUCT_BANNER_CLICKED = 'BA Product Banner Clicked',
  BA_TILE_CLICKED = 'BA Tile Clicked',
  BANK_STATEMENTS_CLICKED = 'Bank Statements Clicked',
  BANK_STATEMENTS_CUSTOM_STATEMENT_CLICKED = 'Bank Statements Custom Statement Clicked',
  BANK_STATEMENTS_PAST_STATEMENTS_CLICKED = 'Bank Statements Past Statements Clicked',
  BILL_TAB_CLICKED = 'Bill Tab Clicked',
  BILL_CONFIRM_ADD_NEW_PAYEE_CLICKED = 'Bill Confirm Add New Payee Clicked',
  BILL_CONFIRM_CLICKED = 'Bill Confirm Clicked',
  BILL_CONFIRM_EDIT_PAYEE_CLICKED = 'Bill Confirm Edit Payee Clicked',
  BILL_CONFIRM_VIEWED = 'Bill Confirm Viewed',
  BILL_CONFIRMED_UNSCHEDULED = 'Bill Confirmed Unscheduled',
  BILL_EDIT_PAYEE_CLICKED = 'Bill Edit Payee Clicked',
  BILL_EMAIL_BUTTON_CLICKED = 'Bill Email Button Clicked',
  BILL_ERROR = 'Bill Error',
  BILL_FEATURE_MODAL_CLICKED = 'Bill Feature Modal Clicked',
  BILL_FEATURE_MODAL_VIEWED = 'Bill Feature Modal Viewed',
  BILL_NEW_PAYEE_ADDED = 'Bill New Payee Added',
  BILL_OPEN_PREVIEW_CLICKED = 'Bill Open Preview Clicked',
  BILL_PAGE_ACTION_CLICKED = 'Bill Page Action Clicked',
  BILL_PAYMENT_SCHEDULED = 'Bill Payment Scheduled',
  BILL_PAYMENT_SUBMITTED = 'Bill Payment Submitted',
  BILL_REVIEW_VIEWED = 'Bill Review Viewed',
  BLP_GET_STARTED_CLICKED = 'BLP Get Started Clicked',
  BLP_PRODUCT_BANNER_CLICKED = 'BLP Product Banner Clicked',
  BRAZE_CONTENT_CARD_CLICKED = 'Braze Content Card Clicked',
  BRAZE_CONTENT_CARD_DISMISS = 'Braze Content Card Dismiss',
  BRAZE_CONTENT_CARD_VIEWED = 'Braze Content Card Viewed',
  CARD_ACTIVATE_CLICKED = 'Card Activate Clicked',
  CARD_ACTIVATED = 'Card Activated',
  CARD_CONTACTLESS_CLICKED = 'Card Contactless Clicked',
  CARD_LOCK_CLICKED = 'Card Lock Clicked',
  CARD_NEW_CLICKED = 'Card New Clicked',
  CARD_PIN_CHANGE_CLICKED = 'Card Pin Change Clicked',
  CARD_PIN_CHANGED = 'Card Pin Changed',
  CARD_VERIFIED = 'Card Verified',
  DRAWDOWN_BUTTON_CLICKED = 'Drawdown Button Clicked',
  FUNDS_ADD_CLICKED = 'Funds Add Clicked',
  FUNDS_BANK_TRANSFER_COPIED = 'Funds Bank Transfer Copied',
  HOME_PRODUCT_BANNER_CLICKED = 'Home Product Banner Clicked',
  LOC_BA_DRAWDOWN = 'LOC BA Drawdown',
  LOC_BA_PAYNOW_CONFIRMATION = 'LOC BA Paynow Confirmation',
  LOC_DRAWDOWN_SUBMITTED = 'Drawdown Submitted',
  LOC_EMAIL_ANNUAL_STATEMENT_NOTIFICATION = 'LOC Email Annual Statement Notification',
  LOC_EMAIL_MONTHLY_STATEMENT_NOTIFICATION = 'LOC Email Monthly Statement Notification',
  LOC_EMAIL_SCHEDULED_PAYMENT_NOTIFICATION = 'LOC Email Scheduled Payment Notification',
  LOC_GET_STARTED_CLICKED = 'LOC Get Started Clicked',
  LOC_MISSED_REPAYMENT_TAKE_ACTION_NOW = 'LOC Missed Repayment Take Action Now',
  LOC_PRODUCT_BANNER_CLICKED = 'LOC Product Banner Clicked',
  LOC_REVIEW_AGREEMENT = 'LOC Review Agreement',
  LOC_SMS_ACCOUNT_SUSPENDED_NOTIFICATION = 'LOC Sms Account Suspended Notification',
  LOC_SMS_MISSED_REPAYMENT_NOTIFICATION = 'LOC Sms Missed Repayment Notification',
  LOC_SMS_OVERDUE_PAYMENT_REMINDER_NOTIFICATION = 'LOC Sms Overdue Payment Reminder Notification',
  LOC_SMS_PAYMENT_REMINDER_NOTIFICATION = 'LOC Sms Payment Reminder Notification',
  LOC_TILE_CLICKED = 'LOC Tile Clicked',
  LOGGED_IN = 'Logged In',
  NZ_PROMO_BANNER_CLICKED = 'Nz Promo Banner Clicked',
  PAY_ANYONE_CONFIRMED = 'Pay Anyone Confirmed',
  PAY_ANYONE_CONTACTS_DELETED = 'Pay Anyone Contacts Deleted',
  PAY_ANYONE_CONTACTS_SAVED = 'Pay Anyone Contacts Saved',
  PAY_ANYONE_CONTACTS_SELECTED = 'Pay Anyone Contacts Selected',
  PAY_ANYONE_PAYMENT_DELETE_CONFIRMED = 'Pay Anyone Payment Delete Confirmed',
  PAY_ANYONE_PAYMENT_DELETED = 'Pay Anyone Payment Deleted',
  PAY_ANYONE_SELECTED = 'Pay Anyone Selected',
  PAY_ANYONE_TRANSFER_2FA_CLICKED = 'Pay Anyone Transfer 2FA Clicked',
  PAY_ANYONE_TRANSFER_2FA_RESENT = 'Pay Anyone Transfer 2FA Resent',
  PAY_ANYONE_TRANSFER_CONFIRMATION = 'Pay Anyone Transfer Confirmation',
  PAY_ANYONE_TRANSFER_REQUESTED = 'Pay Anyone Transfer Requested',
  PAY_ANYONE_VALIDATION_ERROR = 'Pay Anyone Validation Error',
  PRODUCT_DETAILS_VIEWED = 'Product Details Viewed',
  PRODUCT_TAB_CLICKED = 'Product Tab Clicked',
  SBL_EMAIL_ANNUAL_STATEMENT_NOTIFICATION = 'SBL Email Annual Statement Notification',
  SBL_EMAIL_MONTHLY_STATEMENT_NOTIFICATION = 'SBL Email Monthly Statement Notification',
  SBL_GET_STARTED_CLICKED = 'SBL Get Started Clicked',
  SBL_PRODUCT_BANNER_CLICKED = 'SBL Product Banner Clicked',
  SBL_REFI_CALCULATED = 'SBL Refi Calculated',
  SBL_REFI_REVIEW_THE_OFFER = 'SBL Refi Review The Offer',
  SBL_REPAYMENT_CTA = 'SBL Repayment Cta',
  SBL_REPAYMENT_SCHEDULE_BUTTON_CLICKED = 'SBL Repayment Schedule Button Clicked',
  SBL_REPAYMENT_TAKE_ACTION_NOW_CLICKED = 'SBL Repayment Take Action Now Clicked',
  SBL_REVIEWED_AGREEMENT = 'SBL Reviewed Agreement',
  SBL_TILE_CLICKED = 'SBL Tile Clicked',
  SBLM_TILE_CLICKED = 'SBLM Tile Clicked',
  SETTINGS_EMAIL_CHANGE = 'Settings Email Change',
  SETTINGS_EMAIL_CHANGED = 'Settings Email Changed',
  SETTINGS_MOBILE_CHANGE = 'Settings Mobile Change',
  SETTINGS_MOBILE_CHANGED = 'Settings Mobile Changed',
  SETTINGS_NOTIFICATIONS_EMAIL_TOGGLED = 'Settings Notifications Email Toggled',
  SETTINGS_NOTIFICATIONS_SMS_TOGGLED = 'Settings Notifications SMS Toggled',
  SETTINGS_PASSWORD_CHANGE = 'Settings Password Change',
  SETTINGS_PASSWORD_CHANGED = 'Settings Password Changed',
  SETTINGS_PASSWORD_VERIFIED = 'Settings Password Verified',
  SETTINGS_SUPPORT_HELP_CLICKED = 'Settings Support Help Clicked',
  STATEMENT_PRODUCT_SELECTED = 'Statement Product Selected',
  STATEMENTS_CSV_DOWNLOADED = 'Statements CSV Downloaded',
  STATEMENTS_ERROR = 'Statements Error',
  STATEMENTS_PDF_DOWNLOADED = 'Statements PDF Downloaded',
  STATEMENTS_VIEWED = 'Statements Viewed',
  WELCOME_CLOSED = 'Welcome Closed',
  WELCOME_OK_GOT_IT_CLICKED = 'Welcome Ok Got It Clicked',
  XERO_ACCOUNT_SELECT_CLICKED = 'Xero Account Select Clicked',
  XERO_CONNECT_CLICKED = 'Xero Connect Clicked',
  XERO_CONNECT_SUCCESS = 'Xero Connect Success',
  XERO_CONNECTION_ERROR_BANNER_CLICKED = 'Xero Connection Error Banner Clicked',
  XERO_DISCONNECT_CONFIRM_CLICKED = 'Xero Disconnect Confirm Clicked',
  XERO_GET_STARTED_BUTTON_CLICKED = 'Xero Get Started Button Clicked',
  XERO_MANAGE_CONNECTION_CLICKED = 'Xero Manage Connection Clicked',
  XERO_SETTINGS_CLICKED = 'Xero Settings Clicked',
  XERO_UPDATE_CLICKED = 'Xero Update Clicked',

  // HOME page
  BA_ACTIVATION_HOME_PAGE_ADD_FUNDS_COPY_BANK_DETAILS_BUTTON_CLICKED = 'BA Activation Home Page Add Funds Copy Bank Details Button Clicked',
  BA_ACTIVATION_HOME_PAGE_ADD_FUNDS_BUTTON_CLICKED = 'BA Activation Home Page Add Funds Button Clicked',

  // ADD FUNDS page
  BA_ACTIVATION_ADD_FUNDS_COPY_BANK_DETAILS_PAGE_VIEWED = 'BA Activation Add Funds Copy Bank Details Page Viewed',
  BA_ACTIVATION_ADD_FUNDS_COPY_BANK_DETAILS_BUTTON_CLICKED = 'BA Activation Add Funds Copy Bank Details Button Clicked',

  // ADD FUNDS page BA + LOC
  BA_ACTIVATION_ADD_FUNDS_TO_YOUR_BUSINESS_ACCOUNT_LOC_VARIANT_PAGE_VIEWED = 'BA Activation Add Funds To Your Business Account LOC Variant Page Viewed',
  BA_ACTIVATION_ADD_FUNDS_LOC_VARIANT_COPY_BANK_DETAILS_BUTTON_CLICKED = 'BA Activation Add Funds LOC Variant Copy Bank Details Button Clicked',
  BA_ACTIVATION_ADD_FUNDS_LOC_VARIANT_INSTANTLY_FUND_BUTTON_CLICKED = 'BA Activation Add Funds LOC Variant Instantly Fund Button Clicked',

  // Product - BA CVP
  BA_ACTIVATION_BUSINESS_ACCOUNT_PRODUCT_PAGE_VIEWED = 'BA Activation Business Account Product Page Viewed',
  BA_ACTIVATION_BUSINESS_ACCOUNT_PRODUCT_PAGE_CONTINUE_BUTTON_CLICKED = 'BA Activation Business Account Product Page Continue Button Clicked',

  // Repayment calculator
  REPAYMENT_CALCULATOR_PRODUCT_BANNER_VIEWED = 'Repayment Calculator Product Banner Viewed',
  REPAYMENT_CALCULATOR_PRODUCT_BANNER_CLICKED = 'Repayment Calculator Product Banner Clicked',
  REPAYMENT_CALCULATOR_CALCULATE_PAGE_VIEWED = 'Repayment Calculator Calculate Page Viewed',
  REPAYMENT_CALCULATOR_CALCULATE_PAGE_LOAN_TYPE_TAB_CLICKED = 'Repayment Calculator Calculate Page Loan Type Tab Clicked',
  REPAYMENT_CALCULATOR_CALCULATE_PAGE_CALCULATE_BUTTON_CLICKED = 'Repayment Calculator Calculate Page Calculate Button Clicked',
  REPAYMENT_CALCULATOR_CALCULATED_PAGE_QUALIFY_APPLICATION_BUTTON_CLICKED = 'Repayment Calculator Calculated Page Qualify Application Button Clicked',
}

/**
 * Google Analytics Events Names (legacy)
 */
export enum LegacyEventActions {
  'activity_cancelled' = 'activity_cancelled',
  'activity_print' = 'activity_print',
  'activity_search' = 'activity_search',
  'activity_view' = 'activity_view',
  'app_banner_click' = 'app_banner_click',
  'ba_get_started_click' = 'ba_get_started_click',
  'ba_product_banner_click' = 'ba_product_banner_click',
  'ba_tile_click' = 'ba_tile_click',
  'bank_statements' = 'bank_statements',
  'bank_statements_custom-statement' = 'bank_statements_custom-statement',
  'bank_statements_custom_statement_csv' = 'bank_statements_custom_statement_csv',
  'bank_statements_custom_statement_error' = 'bank_statements_custom_statement_error',
  'bank_statements_custom_statement_pdf' = 'bank_statements_custom_statement_pdf',
  'bank_statements_past-statements' = 'bank_statements_past-statements',
  'bill_add_new_payee' = 'bill_add_new_payee',
  'bill_bills__view' = 'bill_bills__view',
  'bill_confirm__click' = 'bill_confirm__click',
  'bill_confirm__view' = 'bill_confirm__view',
  'bill_confirm_add_new_payee__click' = 'bill_confirm_add_new_payee__click',
  'bill_confirm_edit_payee__click' = 'bill_confirm_edit_payee__click',
  'bill_confirmed_unscheduled' = 'bill_confirmed_unscheduled',
  'bill_edit_payee' = 'bill_edit_payee',
  'bill_email__click' = 'bill_email__click',
  'bill_error' = 'bill_error',
  'bill_feature_modal__click' = 'bill_feature_modal__click',
  'bill_feature_modal__view' = 'bill_feature_modal__view',
  'bill_open_preview__click' = 'bill_open_preview__click',
  'bill_page_action__click' = 'bill_page_action__click',
  'bill_payment_scheduled' = 'bill_payment_scheduled',
  'bill_payment_submitted' = 'bill_payment_submitted',
  'bill_review__view' = 'bill_review__view',
  'blp_get_started_click' = 'blp_get_started_click',
  'blp_product_banner_click' = 'blp_product_banner_click',
  'braze_cc__click' = 'braze_cc__click',
  'braze_cc__dismiss' = 'braze_cc__dismiss',
  'braze_cc__view' = 'braze_cc__view',
  'card_activate' = 'card_activate',
  'card_activated' = 'card_activated',
  'card_contactless' = 'card_contactless',
  'card_lock' = 'card_lock',
  'card_new' = 'card_new',
  'card_pin-change' = 'card_pin-change',
  'card_pin-changed' = 'card_pin-changed',
  'card_verify' = 'card_verify',
  'drawdown_button' = 'drawdown_button',
  'funds_add' = 'funds_add',
  'funds_bank-transfer-copy' = 'funds_bank-transfer-copy',
  'home_product_banner_click' = 'home_product_banner_click',
  'loans_loc_link' = 'loans_loc_link',
  'loans_sbl_link' = 'loans_sbl_link',
  'loc_ba_drawdown' = 'loc_ba_drawdown',
  'loc_ba_paynow_confirmation' = 'loc_ba_paynow_confirmation',
  'loc_drawdown' = 'loc_drawdown',
  'loc_email_annual_statement_notification' = 'loc_email_annual_statement_notification',
  'loc_email_monthly_statement_notification' = 'loc_email_monthly_statement_notification',
  'loc_email_scheduled_payment_notification' = 'loc_email_scheduled_payment_notification',
  'loc_get_started_click' = 'loc_get_started_click',
  'loc_missed_repayment_take_action_now' = 'loc_missed_repayment_take_action_now',
  'loc_product_banner_click' = 'loc_product_banner_click',
  'loc_review_agreement' = 'loc_review_agreement',
  'loc_sms_account_suspended_notification' = 'loc_sms_account_suspended_notification',
  'loc_sms_missed_repayment_notification' = 'loc_sms_missed_repayment_notification',
  'loc_sms_overdue_repayment_reminder_notification' = 'loc_sms_overdue_repayment_reminder_notification',
  'loc_sms_payment_reminder_notification' = 'loc_sms_payment_reminder_notification',
  'loc_tile_click' = 'loc_tile_click',
  'login' = 'login',
  'nz_promo_banner__click' = 'nz_promo_banner__click',
  'pay-anyone_confirm' = 'pay-anyone_confirm',
  'pay-anyone_contacts-delete' = 'pay-anyone_contacts-delete',
  'pay-anyone_contacts-save' = 'pay-anyone_contacts-save',
  'pay-anyone_contacts-select' = 'pay-anyone_contacts-select',
  'pay-anyone_payment-delete' = 'pay-anyone_payment-delete',
  'pay-anyone_payment-delete-confirmation' = 'pay-anyone_payment-delete-confirmation',
  'pay-anyone_select' = 'pay-anyone_select',
  'pay-anyone_transfer-2FA' = 'pay-anyone_transfer-2FA',
  'pay-anyone_transfer-2FA-resend' = 'pay-anyone_transfer-2FA-resend',
  'pay-anyone_transfer-confirmation' = 'pay-anyone_transfer-confirmation',
  'pay-anyone_transfer-request' = 'pay-anyone_transfer-request',
  'pay-anyone_validation-error' = 'pay-anyone_validation-error',
  'product_see_details' = 'product_see_details',
  'product_tab_click' = 'product_tab_click',
  'sbl_email_annual_statement_notification' = 'sbl_email_annual_statement_notification',
  'sbl_email_monthly_statement_notification' = 'sbl_email_monthly_statement_notification',
  'sbl_get_started_click' = 'sbl_get_started_click',
  'sbl_product_banner_click' = 'sbl_product_banner_click',
  'sbl_refi_calculate' = 'sbl_refi_calculate',
  'sbl_refi_review_the_offer' = 'sbl_refi_review_the_offer',
  'sbl_repayment__cta' = 'sbl_repayment__cta',
  'sbl_repayment_schedule' = 'sbl_repayment_schedule',
  'sbl_repayment_take_action_now' = 'sbl_repayment_take_action_now',
  'sbl_review_agreement' = 'sbl_review_agreement',
  'sbl_tile_click' = 'sbl_tile_click',
  'sblm_tile_click' = 'sblm_tile_click',
  'settings_email-change' = 'settings_email-change',
  'settings_email-changed' = 'settings_email-changed',
  'settings_mobile-change' = 'settings_mobile-change',
  'settings_mobile-changed' = 'settings_mobile-changed',
  'settings_notifications_email' = 'settings_notifications_email',
  'settings_notifications_sms' = 'settings_notifications_sms',
  'settings_password-change' = 'settings_password-change',
  'settings_password-changed' = 'settings_password-changed',
  'settings_password-verify' = 'settings_password-verify',
  'settings_support_help_click' = 'settings_support_help_click',
  'statement_product_selected' = 'statement_product_selected',
  'statements_view' = 'statements_view',
  'welcome_close' = 'welcome_close',
  'welcome_ok_got_it' = 'welcome_ok_got_it',
  'xero_account_select__click' = 'xero_account_select__click',
  'xero_connect__click' = 'xero_connect__click',
  'xero_connect_success' = 'xero_connect_success',
  'xero_connection-error_banner__click' = 'xero_connection-error_banner__click',
  'xero_disconnect_confirm__click' = 'xero_disconnect_confirm__click',
  'xero_get-started__click' = 'xero_get-started__click',
  'xero_manage_connection__click' = 'xero_manage_connection__click',
  'xero_settings__click' = 'xero_settings__click',
  'xero_update__click' = 'xero_update__click',
  'activation_add_funds_details_btn__click' = 'activation_add_funds_details_btn__click',
  'activation_add_funds_prompt_btn__click' = 'activation_add_funds_prompt_btn__click',
  'activation_add_fund_bank_details__view' = 'activation_add_fund_bank_details__view',
  'activation_add_fund_bank_details__click' = 'activation_add_fund_bank_details__click',
  'activation_add_funds_loc__view' = 'activation_add_funds_loc__view',
  'activation_add_funds_loc_copy__click' = 'activation_add_funds_loc_copy__click',
  'activation_add_funds_loc_instant__click' = 'activation_add_funds_loc_instant__click',
  'activation_ba_product_page__view' = 'activation_ba_product_page__view',
  'activation_ba_product_continue__click' = 'activation_ba_product_continue__click',
  'repay_calc_product_banner_viewed' = 'repay_calc_product_banner_viewed',
  'repay_calc_product_banner_clicked' = 'repay_calc_product_banner_clicked',
  'repay_calc_qualify_btn__clicked' = 'repay_calc_qualify_btn__clicked',
  'repay_calc_calculate_page_tab__clicked' = 'repay_calc_calculate_page_tab__clicked',
  'repay_calc_calculate_btn__clicked' = 'repay_calc_calculate_btn__clicked',
  'repay_calc_calculate_page_viewed' = 'repay_calc_calculate_page_viewed',
}

/**
 * New/Segment Events mapped to Google Analytics Events
 * @description In the future, we should align on the naming between platforms and won't need to use LegacyEventActions or pass `legacy_event_name`
 * @deprecated
 */
const LegacyEventMapper: Record<EventActions, LegacyEventActions> = {
  [EventActions.ACTIVITY_CANCELLED]: LegacyEventActions.activity_cancelled,
  [EventActions.ACTIVITY_PRINTED]: LegacyEventActions.activity_print,
  [EventActions.ACTIVITY_SEARCHED]: LegacyEventActions.activity_search,
  [EventActions.ACTIVITY_VIEWED]: LegacyEventActions.activity_view,
  [EventActions.APP_BANNER_CLICKED]: LegacyEventActions.app_banner_click,
  [EventActions.BA_GET_STARTED_CLICKED]: LegacyEventActions.ba_get_started_click,
  [EventActions.BA_PRODUCT_BANNER_CLICKED]: LegacyEventActions.ba_product_banner_click,
  [EventActions.BA_TILE_CLICKED]: LegacyEventActions.ba_tile_click,
  [EventActions.BANK_STATEMENTS_CLICKED]: LegacyEventActions.bank_statements,
  [EventActions.BANK_STATEMENTS_CUSTOM_STATEMENT_CLICKED]:
    LegacyEventActions['bank_statements_custom-statement'],
  [EventActions.BANK_STATEMENTS_PAST_STATEMENTS_CLICKED]:
    LegacyEventActions['bank_statements_past-statements'],
  [EventActions.BILL_TAB_CLICKED]: LegacyEventActions.bill_bills__view,
  [EventActions.BILL_CONFIRM_ADD_NEW_PAYEE_CLICKED]:
    LegacyEventActions.bill_confirm_add_new_payee__click,
  [EventActions.BILL_CONFIRM_CLICKED]: LegacyEventActions.bill_confirm__click,
  [EventActions.BILL_CONFIRM_EDIT_PAYEE_CLICKED]: LegacyEventActions.bill_confirm_edit_payee__click,
  [EventActions.BILL_CONFIRM_VIEWED]: LegacyEventActions.bill_confirm__view,
  [EventActions.BILL_CONFIRMED_UNSCHEDULED]: LegacyEventActions.bill_confirmed_unscheduled,
  [EventActions.BILL_EDIT_PAYEE_CLICKED]: LegacyEventActions.bill_edit_payee,
  [EventActions.BILL_EMAIL_BUTTON_CLICKED]: LegacyEventActions.bill_email__click,
  [EventActions.BILL_ERROR]: LegacyEventActions.bill_error,
  [EventActions.BILL_FEATURE_MODAL_CLICKED]: LegacyEventActions.bill_feature_modal__click,
  [EventActions.BILL_FEATURE_MODAL_VIEWED]: LegacyEventActions.bill_feature_modal__view,
  [EventActions.BILL_NEW_PAYEE_ADDED]: LegacyEventActions.bill_add_new_payee,
  [EventActions.BILL_OPEN_PREVIEW_CLICKED]: LegacyEventActions.bill_open_preview__click,
  [EventActions.BILL_PAGE_ACTION_CLICKED]: LegacyEventActions.bill_page_action__click,
  [EventActions.BILL_PAYMENT_SCHEDULED]: LegacyEventActions.bill_payment_scheduled,
  [EventActions.BILL_PAYMENT_SUBMITTED]: LegacyEventActions.bill_payment_submitted,
  [EventActions.BILL_REVIEW_VIEWED]: LegacyEventActions.bill_review__view,
  [EventActions.BLP_GET_STARTED_CLICKED]: LegacyEventActions.blp_get_started_click,
  [EventActions.BLP_PRODUCT_BANNER_CLICKED]: LegacyEventActions.blp_product_banner_click,
  [EventActions.BRAZE_CONTENT_CARD_CLICKED]: LegacyEventActions.braze_cc__click,
  [EventActions.BRAZE_CONTENT_CARD_DISMISS]: LegacyEventActions.braze_cc__dismiss,
  [EventActions.BRAZE_CONTENT_CARD_VIEWED]: LegacyEventActions.braze_cc__view,
  [EventActions.CARD_ACTIVATE_CLICKED]: LegacyEventActions.card_activate,
  [EventActions.CARD_ACTIVATED]: LegacyEventActions.card_activated,
  [EventActions.CARD_CONTACTLESS_CLICKED]: LegacyEventActions.card_contactless,
  [EventActions.CARD_LOCK_CLICKED]: LegacyEventActions.card_lock,
  [EventActions.CARD_NEW_CLICKED]: LegacyEventActions.card_new,
  [EventActions.CARD_PIN_CHANGE_CLICKED]: LegacyEventActions['card_pin-change'],
  [EventActions.CARD_PIN_CHANGED]: LegacyEventActions['card_pin-changed'],
  [EventActions.CARD_VERIFIED]: LegacyEventActions.card_verify,
  [EventActions.DRAWDOWN_BUTTON_CLICKED]: LegacyEventActions.drawdown_button,
  [EventActions.FUNDS_ADD_CLICKED]: LegacyEventActions.funds_add,
  [EventActions.FUNDS_BANK_TRANSFER_COPIED]: LegacyEventActions['funds_bank-transfer-copy'],
  [EventActions.HOME_PRODUCT_BANNER_CLICKED]: LegacyEventActions.home_product_banner_click,
  [EventActions.LOC_BA_DRAWDOWN]: LegacyEventActions.loc_ba_drawdown,
  [EventActions.LOC_BA_PAYNOW_CONFIRMATION]: LegacyEventActions.loc_ba_paynow_confirmation,
  [EventActions.LOC_DRAWDOWN_SUBMITTED]: LegacyEventActions.loc_drawdown,
  [EventActions.LOC_EMAIL_ANNUAL_STATEMENT_NOTIFICATION]:
    LegacyEventActions.loc_email_annual_statement_notification,
  [EventActions.LOC_EMAIL_MONTHLY_STATEMENT_NOTIFICATION]:
    LegacyEventActions.loc_email_monthly_statement_notification,
  [EventActions.LOC_EMAIL_SCHEDULED_PAYMENT_NOTIFICATION]:
    LegacyEventActions.loc_email_scheduled_payment_notification,
  [EventActions.LOC_GET_STARTED_CLICKED]: LegacyEventActions.loc_get_started_click,
  [EventActions.LOC_MISSED_REPAYMENT_TAKE_ACTION_NOW]:
    LegacyEventActions.loc_missed_repayment_take_action_now,
  [EventActions.LOC_PRODUCT_BANNER_CLICKED]: LegacyEventActions.loc_product_banner_click,
  [EventActions.LOC_REVIEW_AGREEMENT]: LegacyEventActions.loc_review_agreement,
  [EventActions.LOC_SMS_ACCOUNT_SUSPENDED_NOTIFICATION]:
    LegacyEventActions.loc_sms_account_suspended_notification,
  [EventActions.LOC_SMS_MISSED_REPAYMENT_NOTIFICATION]:
    LegacyEventActions.loc_sms_missed_repayment_notification,
  [EventActions.LOC_SMS_OVERDUE_PAYMENT_REMINDER_NOTIFICATION]:
    LegacyEventActions.loc_sms_overdue_repayment_reminder_notification,
  [EventActions.LOC_SMS_PAYMENT_REMINDER_NOTIFICATION]:
    LegacyEventActions.loc_sms_payment_reminder_notification,
  [EventActions.LOC_TILE_CLICKED]: LegacyEventActions.loc_tile_click,
  [EventActions.LOGGED_IN]: LegacyEventActions.login,
  [EventActions.NZ_PROMO_BANNER_CLICKED]: LegacyEventActions.nz_promo_banner__click,
  [EventActions.PAY_ANYONE_CONFIRMED]: LegacyEventActions['pay-anyone_confirm'],
  [EventActions.PAY_ANYONE_CONTACTS_DELETED]: LegacyEventActions['pay-anyone_contacts-delete'],
  [EventActions.PAY_ANYONE_CONTACTS_SAVED]: LegacyEventActions['pay-anyone_contacts-save'],
  [EventActions.PAY_ANYONE_CONTACTS_SELECTED]: LegacyEventActions['pay-anyone_contacts-select'],
  [EventActions.PAY_ANYONE_PAYMENT_DELETE_CONFIRMED]:
    LegacyEventActions['pay-anyone_payment-delete-confirmation'],
  [EventActions.PAY_ANYONE_PAYMENT_DELETED]: LegacyEventActions['pay-anyone_payment-delete'],
  [EventActions.PAY_ANYONE_SELECTED]: LegacyEventActions['pay-anyone_select'],
  [EventActions.PAY_ANYONE_TRANSFER_2FA_CLICKED]: LegacyEventActions['pay-anyone_transfer-2FA'],
  [EventActions.PAY_ANYONE_TRANSFER_2FA_RESENT]:
    LegacyEventActions['pay-anyone_transfer-2FA-resend'],
  [EventActions.PAY_ANYONE_TRANSFER_CONFIRMATION]:
    LegacyEventActions['pay-anyone_transfer-confirmation'],
  [EventActions.PAY_ANYONE_TRANSFER_REQUESTED]: LegacyEventActions['pay-anyone_transfer-request'],
  [EventActions.PAY_ANYONE_VALIDATION_ERROR]: LegacyEventActions['pay-anyone_validation-error'],
  [EventActions.PRODUCT_DETAILS_VIEWED]: LegacyEventActions.product_see_details,
  [EventActions.PRODUCT_TAB_CLICKED]: LegacyEventActions.product_tab_click,
  [EventActions.SBL_EMAIL_ANNUAL_STATEMENT_NOTIFICATION]:
    LegacyEventActions.sbl_email_annual_statement_notification,
  [EventActions.SBL_EMAIL_MONTHLY_STATEMENT_NOTIFICATION]:
    LegacyEventActions.sbl_email_monthly_statement_notification,
  [EventActions.SBL_GET_STARTED_CLICKED]: LegacyEventActions.sbl_get_started_click,
  [EventActions.SBL_PRODUCT_BANNER_CLICKED]: LegacyEventActions.sbl_product_banner_click,
  [EventActions.SBL_REFI_CALCULATED]: LegacyEventActions.sbl_refi_calculate,
  [EventActions.SBL_REFI_REVIEW_THE_OFFER]: LegacyEventActions.sbl_refi_review_the_offer,
  [EventActions.SBL_REPAYMENT_CTA]: LegacyEventActions.sbl_repayment__cta,
  [EventActions.SBL_REPAYMENT_SCHEDULE_BUTTON_CLICKED]: LegacyEventActions.sbl_repayment_schedule,
  [EventActions.SBL_REPAYMENT_TAKE_ACTION_NOW_CLICKED]:
    LegacyEventActions.sbl_repayment_take_action_now,
  [EventActions.SBL_REVIEWED_AGREEMENT]: LegacyEventActions.sbl_review_agreement,
  [EventActions.SBL_TILE_CLICKED]: LegacyEventActions.sbl_tile_click,
  [EventActions.SBLM_TILE_CLICKED]: LegacyEventActions.sblm_tile_click,
  [EventActions.SETTINGS_EMAIL_CHANGE]: LegacyEventActions['settings_email-change'],
  [EventActions.SETTINGS_EMAIL_CHANGED]: LegacyEventActions['settings_email-changed'],
  [EventActions.SETTINGS_MOBILE_CHANGE]: LegacyEventActions['settings_mobile-change'],
  [EventActions.SETTINGS_MOBILE_CHANGED]: LegacyEventActions['settings_mobile-changed'],
  [EventActions.SETTINGS_NOTIFICATIONS_EMAIL_TOGGLED]:
    LegacyEventActions.settings_notifications_email,
  [EventActions.SETTINGS_NOTIFICATIONS_SMS_TOGGLED]: LegacyEventActions.settings_notifications_sms,
  [EventActions.SETTINGS_PASSWORD_CHANGE]: LegacyEventActions['settings_password-change'],
  [EventActions.SETTINGS_PASSWORD_CHANGED]: LegacyEventActions['settings_password-changed'],
  [EventActions.SETTINGS_PASSWORD_VERIFIED]: LegacyEventActions['settings_password-verify'],
  [EventActions.SETTINGS_SUPPORT_HELP_CLICKED]: LegacyEventActions.settings_support_help_click,
  [EventActions.STATEMENT_PRODUCT_SELECTED]: LegacyEventActions.statement_product_selected,
  [EventActions.STATEMENTS_CSV_DOWNLOADED]: LegacyEventActions.bank_statements_custom_statement_csv,
  [EventActions.STATEMENTS_ERROR]: LegacyEventActions.bank_statements_custom_statement_error,
  [EventActions.STATEMENTS_PDF_DOWNLOADED]: LegacyEventActions.bank_statements_custom_statement_pdf,
  [EventActions.STATEMENTS_VIEWED]: LegacyEventActions.statements_view,
  [EventActions.WELCOME_CLOSED]: LegacyEventActions.welcome_close,
  [EventActions.WELCOME_OK_GOT_IT_CLICKED]: LegacyEventActions.welcome_ok_got_it,
  [EventActions.XERO_ACCOUNT_SELECT_CLICKED]: LegacyEventActions.xero_account_select__click,
  [EventActions.XERO_CONNECT_CLICKED]: LegacyEventActions.xero_connect__click,
  [EventActions.XERO_CONNECT_SUCCESS]: LegacyEventActions.xero_connect_success,
  [EventActions.XERO_CONNECTION_ERROR_BANNER_CLICKED]:
    LegacyEventActions['xero_connection-error_banner__click'],
  [EventActions.XERO_DISCONNECT_CONFIRM_CLICKED]: LegacyEventActions.xero_disconnect_confirm__click,
  [EventActions.XERO_GET_STARTED_BUTTON_CLICKED]: LegacyEventActions['xero_get-started__click'],
  [EventActions.XERO_MANAGE_CONNECTION_CLICKED]: LegacyEventActions.xero_manage_connection__click,
  [EventActions.XERO_SETTINGS_CLICKED]: LegacyEventActions.xero_settings__click,
  [EventActions.XERO_UPDATE_CLICKED]: LegacyEventActions.xero_update__click,
  // HOME page
  [EventActions.BA_ACTIVATION_HOME_PAGE_ADD_FUNDS_COPY_BANK_DETAILS_BUTTON_CLICKED]:
    LegacyEventActions['activation_add_funds_details_btn__click'],
  [EventActions.BA_ACTIVATION_HOME_PAGE_ADD_FUNDS_BUTTON_CLICKED]:
    LegacyEventActions['activation_add_funds_prompt_btn__click'],
  // ADD FUNDS page
  [EventActions.BA_ACTIVATION_ADD_FUNDS_COPY_BANK_DETAILS_PAGE_VIEWED]:
    LegacyEventActions['activation_add_fund_bank_details__view'],
  [EventActions.BA_ACTIVATION_ADD_FUNDS_COPY_BANK_DETAILS_BUTTON_CLICKED]:
    LegacyEventActions['activation_add_fund_bank_details__click'],
  // ADD FUNDS page BA + LOC
  [EventActions.BA_ACTIVATION_ADD_FUNDS_TO_YOUR_BUSINESS_ACCOUNT_LOC_VARIANT_PAGE_VIEWED]:
    LegacyEventActions['activation_add_funds_loc__view'],
  [EventActions.BA_ACTIVATION_ADD_FUNDS_LOC_VARIANT_COPY_BANK_DETAILS_BUTTON_CLICKED]:
    LegacyEventActions['activation_add_funds_loc_copy__click'],
  [EventActions.BA_ACTIVATION_ADD_FUNDS_LOC_VARIANT_INSTANTLY_FUND_BUTTON_CLICKED]:
    LegacyEventActions['activation_add_funds_loc_instant__click'],
  // Product - BA CVP
  [EventActions.BA_ACTIVATION_BUSINESS_ACCOUNT_PRODUCT_PAGE_VIEWED]:
    LegacyEventActions['activation_ba_product_page__view'],
  [EventActions.BA_ACTIVATION_BUSINESS_ACCOUNT_PRODUCT_PAGE_CONTINUE_BUTTON_CLICKED]:
    LegacyEventActions['activation_ba_product_continue__click'],

  // Repayment Calculator
  [EventActions.REPAYMENT_CALCULATOR_PRODUCT_BANNER_VIEWED]:
    LegacyEventActions['repay_calc_product_banner_viewed'],
  [EventActions.REPAYMENT_CALCULATOR_PRODUCT_BANNER_CLICKED]:
    LegacyEventActions['repay_calc_product_banner_clicked'],
  [EventActions.REPAYMENT_CALCULATOR_CALCULATE_PAGE_VIEWED]:
    LegacyEventActions['repay_calc_calculate_page_viewed'],
  [EventActions.REPAYMENT_CALCULATOR_CALCULATE_PAGE_LOAN_TYPE_TAB_CLICKED]:
    LegacyEventActions['repay_calc_calculate_page_tab__clicked'],
  [EventActions.REPAYMENT_CALCULATOR_CALCULATE_PAGE_CALCULATE_BUTTON_CLICKED]:
    LegacyEventActions['repay_calc_calculate_btn__clicked'],
  [EventActions.REPAYMENT_CALCULATOR_CALCULATED_PAGE_QUALIFY_APPLICATION_BUTTON_CLICKED]:
    LegacyEventActions['repay_calc_qualify_btn__clicked'],
};

export const BusinessAccountEventCategory = 'account';

export type EventNameType = EventActions | keyof typeof LegacyEventActions;

export type TTrackBAActionEvent = (
  eventName: EventNameType,
  /**
   * @example 'test_key1:value1'
   * @example 'start_date_after_end_date'
   * @example { test_key1: 'value1' }
   * @example { start_date_after_end_date: true }
   */
  eventLabel?: Parameters<LogEvent>[0]['eventParams'],
  options?: Parameters<LogEvent>[1],
) => void;

export const trackBAActionEvent: TTrackBAActionEvent = (eventName, eventLabel = null, options) => {
  const [newEventName, legacyEventName] = getMappedEventNames(eventName);
  let eventParams = eventLabel;
  if (typeof eventLabel === 'string') {
    eventParams = transformTrackingStringToParamsObject(eventLabel);
  } else {
    eventParams = eventLabel;
  }

  let legacyEventLabel: string | undefined;
  if (legacyEventName) {
    if (typeof eventLabel === 'string') {
      legacyEventLabel = eventLabel;
    } else {
      legacyEventLabel = transformTrackingParamsObjectToString(eventLabel);
    }
  }

  Analytics.logEvent(
    {
      eventCategory: BusinessAccountEventCategory,
      eventName: newEventName,
      eventParams: {
        legacy_event_name: legacyEventName,
        legacy_event_label: legacyEventLabel,
        ...eventParams,
      },
    },
    options,
  );
};

const getMappedEventNames = (
  eventName: EventNameType,
): [EventActions, LegacyEventActions | undefined] => {
  let mappedEvent: [EventActions, LegacyEventActions];
  if (LegacyEventMapper[eventName]) {
    return [eventName as EventActions, LegacyEventMapper[eventName]];
  }
  if (LegacyEventActions[eventName]) {
    mappedEvent = Object.entries(LegacyEventMapper).find(
      ([_newEventName, legacyEventName]) => legacyEventName === eventName,
    ) as [EventActions, LegacyEventActions];
    if (!mappedEvent) {
      const message = `Please map ${eventName} event in LegacyEventMapper`;
      captureMessage(message);
      console.error(message);
      return [undefined, eventName as LegacyEventActions];
    }
  } else {
    mappedEvent = [eventName as EventActions, undefined];
  }

  return mappedEvent;
};

export const trackUser = Analytics.identifyUser;

export const trackPageViewEvent = (pathName: Location['pathname']) =>
  Analytics.logPageView(getPageTitleByPathname(pathName));

export type TGetTransactionTrackingDetails = {
  amount: string;
  currentTab?: PayAnyoneTabs;
  paymentFrequency?: string;
  duration?: string;
  numberOfPayments?: string;
  payFromAccount?: AccountItem;
  fromAccount?: string;
  type?: 'ba-payanyone' | 'loc-payanyone' | 'loc-ba-drawdown';
  paymentDate?: string;
};

export const getTransactionTrackingDetails = ({
  amount,
  currentTab = null,
  paymentFrequency = null,
  duration = null,
  numberOfPayments = null,
  payFromAccount = null,
  fromAccount = null,
  type = null,
  paymentDate = null,
}: TGetTransactionTrackingDetails) => {
  let trackingDetails = 'transaction_amount:' + amount;

  const overdraft = calculateOverdraft(
    amount,
    payFromAccount?.balance?.total,
    payFromAccount?.balance?.available,
  );

  if (currentTab !== null) {
    trackingDetails += '_payment_type:' + CURRENT_TAB_TO_PAYMENT_TYPE_MAP[currentTab];
  }

  if (paymentFrequency) {
    trackingDetails += '_transaction_frequency:' + paymentFrequency.toLowerCase();
  }

  if (duration) {
    trackingDetails += '_payment_duration:' + duration.toLowerCase();
  }

  if (numberOfPayments) {
    trackingDetails += '_payment_recurring_count:' + numberOfPayments;
  }

  if (overdraft) {
    trackingDetails += '_overdraft_amount:' + overdraft || '0';
  }

  if (fromAccount) {
    trackingDetails += '_from:' + fromAccount;
  }

  if (type) {
    trackingDetails += '_type:' + type;
  }

  if (paymentDate) {
    trackingDetails += '_payment_date:' + paymentDate;
  }

  return trackingDetails;
};

export function getNotificationEventAction(
  type: PreferenceType['type'],
  product: string,
  displayNotificationType: string,
): LegacyEventActions {
  const notificationType = displayNotificationType.replaceAll(' ', '_').toLowerCase();
  return `${product.toLowerCase()}_${type}_${notificationType}_notification` as LegacyEventActions;
}
