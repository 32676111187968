import { type FC, type ReactNode } from 'react';
import ReactSelect from 'react-select';

import classNames from 'classnames';
import { type FieldProps } from 'formik';

import tokens from '@prospa/tokens';

import './Select.scss';
import type { Option, SelectProps } from './types';

const { color } = tokens.pds;

export const Select: FC<FieldProps & SelectProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  className,
  noOptionsPlaceholder,
  label,
  message = '',
  disabled = false,
  setFieldValue,
}) => {
  const { value } = field;
  const error = !!touched[field.name] && errors[field.name];
  return (
    <div className="react-select">
      {label && <label className={classNames('react-select__label', 'pds-caption')}>{label}</label>}
      <ReactSelect
        onChange={(option: Option) => {
          setFieldValue(option.value);
        }}
        menuPlacement="bottom"
        className={classNames('react-select__container', className, { error: !!error })}
        styles={customStyle({ disabled })}
        options={options}
        classNamePrefix="react-select"
        noOptionsMessage={() => noOptionsPlaceholder}
        value={options.filter(function (option) {
          return option.value === value;
        })}
        maxMenuHeight={176}
        menuShouldBlockScroll={false}
        menuShouldScrollIntoView={false}
        isDisabled={disabled}
      />
      {message && (
        <div className={classNames('react-select__message', 'pds-caption')}>{message}</div>
      )}
      {error && (
        <div className={classNames('react-select__error', 'pds-caption')}>{error as ReactNode}</div>
      )}
    </div>
  );
};

const customStyle = ({ disabled }: { disabled: boolean }) => ({
  control: (styles, { isFocused, selectProps }) => {
    const base = {
      display: 'flex',
      boxShadow: isFocused
        ? `inset 0 -2px 0 ${color.green[300]}`
        : `inset 0 -2px 0 ${color.neutral[100]}`,
      color: color.neutral[600],
      '&:hover': {
        boxShadow: `inset 0 -2px 0 ${color.green[300]}`,
      },
      backgroundColor: color.white,
    };
    if (selectProps.invalid) {
      return {
        ...base,
        border: `2px solid ${color.red[500]}`,
        backgroundColor: color.red[50],
        '&:hover': {
          border: `2px solid ${color.red[500]}`,
        },
      };
    }
    return base;
  },
  option: (styles, { isFocused }) => ({
    boxShadow: `inset 0 -1px 0 ${color.neutral[100]}`,
    height: 44,
    backgroundColor: isFocused ? color.neutral[100] : color.white,
    color: color.neutral[800],
    cursor: 'pointer',
  }),
  menu: styles => ({
    ...styles,
    marginTop: 8,
    background: color.white,
    boxShadow: `0px 7px 24px rgba(${color.black}, 0.3)`,
    borderRadius: 2,
  }),
  menuList: styles => ({ ...styles, padding: 0 }),
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles, { selectProps }) => ({
    ...styles,
    transition: 'all .2s ease',
    transform: selectProps.menuIsOpen && 'rotate(180deg)',
    color: color.green[700],
    padding: '0 8px',
    ...(disabled && { display: 'none' }),
  }),
  input: styles => ({ ...styles, margin: 0 }),
  placeholder: provided => ({
    ...provided,
    color: color.neutral[500],
    marginRight: 0,
    padding: '0 0 0.25rem 0',
    marginLeft: 0,
  }),
  singleValue: styles => ({ ...styles, padding: '0 0 0.25rem 0' }),
  valueContainer: styles => ({ ...styles, padding: 0, overflow: 'visible' }),
});
