import classNames from 'classnames';

import { Alert, Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import style from './MissedRepayment.module.scss';
import type { MissedRepaymentProps } from './types';

export const MissedRepayment = ({
  show = true,
  className = '',
  onClick,
  message,
}: MissedRepaymentProps) => {
  if (!show) {
    return null;
  }
  return (
    <Alert alertType="Danger" className={classNames(className, style.MissedRepayment)}>
      <div className={style.MissedRepaymentContainer}>
        <span className={style.MissedRepaymentMessage}>{message}</span>
        {!!onClick && (
          <Button
            type={ButtonType.ICON_LINK_UNDERLINE}
            size={ButtonSize.SMALL}
            onClick={onClick}
            className={style.MissedRepaymentSeeDetailsButton}
          >
            Make a repayment
          </Button>
        )}
      </div>
    </Alert>
  );
};
