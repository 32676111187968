import classNames from 'classnames';

import { Card, LottieAnimation } from '@components';

import './ErrorPageContainer.scss';
import type { ErrorPageContainerProps } from './types';

export const ErrorPageContainer = ({
  className,
  children,
  animationData,
  noLoop,
  assetType,
  title,
  footer,
  hideHeader,
}: ErrorPageContainerProps) => (
  <div
    className={classNames('page-container', {
      'page-container--noheader': hideHeader,
    })}
  >
    <Card className="page-container__card">
      <main className={classNames('page-container__wrapper', className)}>
        {assetType === 'lottie' && (
          <LottieAnimation
            className="page-container__animation"
            loop={!noLoop}
            animationData={animationData}
          />
        )}
        {assetType === 'svg' && (
          <img
            role="img"
            src={animationData as string}
            className="page-container__animation"
            alt=""
          />
        )}
        <h1 className="h3">{title}</h1>
        {children}
        {footer && <div className="page-container__wrapper__text">{footer}</div>}
      </main>
    </Card>
  </div>
);

ErrorPageContainer.defaultProps = {
  assetType: 'lottie',
};
