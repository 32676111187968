import { isValidElement } from 'react';

import type { MatcherFunction } from 'expect';
import { v4 as uuidv4 } from 'uuid';

export const getFutureDate = (addDays = 10): Date => {
  const targetDate = new Date('12/12/2050');
  targetDate.setDate(targetDate.getDate() + addDays);
  return targetDate;
};

export const getPastDate = (): Date => {
  const targetDate = new Date('12/12/2000');
  targetDate.setDate(targetDate.getDate() - 10);
  return targetDate;
};

export const toBeValidReactElement: MatcherFunction = function (actual) {
  const isValid = isValidElement(actual);
  return {
    pass: isValid,
    message: () => {
      return `${actual} is not a valid React element`;
    },
  };
};

export const generateUuidV4 = () => uuidv4();

export const getRandomIntegerInRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
