import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { type TUseQualtricsRunTrigger } from './types';

export const useQualtricsRunTrigger = ({ shouldTriggerRun }: TUseQualtricsRunTrigger) => {
  const location = useLocation();

  useEffect(() => {
    if (shouldTriggerRun && window?.QSI?.API) {
      // to re-evaluate project-level targeting on same page:
      window.QSI.API.unload();
      window.QSI.API.load();
      window.QSI.API.run();
    }
  }, [shouldTriggerRun, location]);
};
