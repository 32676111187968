import { type FC } from 'react';

import { type TableHeadProps } from './types';

export const TableHead: FC<TableHeadProps> = ({ children, className, ...props }) => {
  return (
    <thead className={className} {...props}>
      {children}
    </thead>
  );
};
