import { type AnalyticsService, type LogEvent, type UserProperties } from '../../types';
import { initialiseGoogleTagManager } from './gtm';
import { trackActionEvent, trackPageViewEvent, trackUser } from './tracking';
import { transformTrackingParamsObjectToString } from './utils';

export class GAService implements AnalyticsService {
  constructor(config: { GTM_ID: string; skipInitialisation?: boolean }) {
    if (config.skipInitialisation) {
      return this;
    } else initialiseGoogleTagManager(config.GTM_ID);
  }

  logPageView = async () => {
    trackPageViewEvent();
  };

  logEvent: LogEvent = async ({ eventName, eventParams, eventCategory }) => {
    const paramsIsString = typeof eventParams === 'string';
    const hasLegacyEventName = !paramsIsString && eventParams?.legacy_event_name;

    const trackingName = hasLegacyEventName ? eventParams?.legacy_event_name : eventName;

    // GA service expects the event params to be a valid tracking string
    const trackingParams = paramsIsString
      ? eventParams
      : eventParams?.legacy_event_label || transformTrackingParamsObjectToString(eventParams);

    const trackingCategory = eventCategory || '';
    if (trackingName) {
      trackActionEvent(trackingCategory, trackingName, trackingParams);
    } else {
      console.warn(
        'GAService.logEvent called without an ga_event_name. Please make sure this is intentional or add the right event to send.',
      );
    }
  };

  identifyUser = async (properties: UserProperties) => {
    trackUser({
      prospaId: properties.userId || '',
      product: properties.products || '',
      hayAccountId: properties.hayAccountId,
      platform: properties.platform || '',
      extras: properties?.extras,
    });
  };

  reset = async () => {};
}
