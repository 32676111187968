import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BillStatus, useGetBillsEmailQuery, useGetBillsQuery } from '@generated-fg';

import { BillPayStatus } from '@constants';
import { useBillPaySplitTesting, useCopyText } from '@hooks';
import { calculateBillPayStatus, isBillPayEntitledUser } from '@utils';

import { AppContext } from '../../../contexts';
import { type BillPaySectionData } from './types';

export const useBillPaySectionData = (): BillPaySectionData => {
  const navigate = useNavigate();
  const { customerProducts } = useContext(AppContext);
  const isBillPayEntitled = isBillPayEntitledUser(customerProducts);
  const {
    data: { user: { billPay: { uploadEmailAddress: billsEmail = '' } = {} } = {} } = {},
    loading: isEmailFetching,
  } = useGetBillsEmailQuery({
    skip: !isBillPayEntitled,
  });
  const { getStartedBanner: { title = '', description = '' } = {} } =
    useBillPaySplitTesting() || {};
  const { handleCopy: handleCopyEmailAddressClick } = useCopyText({
    toCopy: billsEmail,
    toastMessage: 'Copied bill email',
    errorMessage: 'Copy bill email failed',
  });

  const {
    data: {
      user: {
        billPay: {
          bills = [],
          dueThisMonthAmount = { amount: 0 },
          dueNextMonthAmount = { amount: 0 },
        } = {},
      } = {},
    } = {},
    loading: billsLoading,
  } = useGetBillsQuery({
    variables: { status: [] },
    skip: !isBillPayEntitled,
  });

  const billPayStatus = useMemo(() => {
    if (!isBillPayEntitled) {
      return BillPayStatus.NotAvailable;
    }
    return calculateBillPayStatus({ email: billsEmail || '', bills: bills || [] });
  }, [isBillPayEntitled, billsEmail, bills]);

  return {
    billDetailLoading: billsLoading || isEmailFetching,
    billPayStatus,
    billSummaryProps: {
      dueThisMonthAmount: dueThisMonthAmount?.amount || 0,
      dueNextMonthAmount: dueNextMonthAmount?.amount || 0,
    },
    confirmBillsAlertProps: {
      loading: billsLoading,
      numReceivedBills: bills?.filter(({ status }) => status === BillStatus.Received)?.length || 0,
    },
    getStartedBannerProps: {
      title,
      subtitle: description,
      buttonProps: {
        label: 'Learn more',
        onClick: () => navigate('/bills'),
      },
    },
    emailBillBannerProps: {
      title: 'Email a bill to get started',
      subtitle: 'Forward an email with a bill attached to your unique address',
      buttonProps: {
        label: 'Copy email address',
        onClick: handleCopyEmailAddressClick,
      },
    },
  };
};
