import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BillStatus, Country, CurrencyCode } from '@generated-fg';

import {
  BillFormAction,
  type ConfirmBillFormValidationRule,
  type TConfirmBillFormProps,
  type TConfirmBillFormSubmitHandler,
  type TUseConfirmBillFormProps,
} from '@components/types';

import { BillConfirmFormErrorMessage, NON_EDITABLE_STATUSES } from '@constants';
import {
  convertConfirmBillFormInputToBillPayContext,
  convertConfirmBillFormInputToUpdateBillInput,
  getPreFillConfirmBillFormInput,
  validationConfig,
} from '@utils';

import { AppContext } from '../../../contexts';
import { BillContextSetter } from '../../../contexts/BillContext';
import {
  cancelScheduledBillTransactionOnDetailPage,
  useCancelScheduledBillTransaction,
} from './useCancelScheduledBillTransaction';
import { useConfirmBillAction } from './useConfirmBillAction';

export function useConfirmBillForm({
  bill,
  recentTransaction,
}: TUseConfirmBillFormProps): TConfirmBillFormProps {
  const navigate = useNavigate();

  const billContextSetter = useContext(BillContextSetter);

  const { customerProducts } = useContext(AppContext);

  const countryCode = useMemo(
    () => (bill?.amount?.currency === CurrencyCode.Aud ? Country.Au : Country.Nz),
    [bill],
  );

  const isBpayAllowed = useMemo(
    () => customerProducts.BA && countryCode === Country.Au,
    [customerProducts.BA, countryCode],
  );

  const initialFormValues = useMemo(
    () => bill && getPreFillConfirmBillFormInput(bill, isBpayAllowed, countryCode),
    [bill, isBpayAllowed, countryCode],
  );

  const { id: billId, status } = bill || {};

  const {
    isCancelScheduledBillLoading,
    cancelBillScheduledPayment,
    modal: cancelScheduledPaymentModal,
  } = useCancelScheduledBillTransaction({ updater: cancelScheduledBillTransactionOnDetailPage });

  const { isConfirmBillLoading, handleConfirmBill } = useConfirmBillAction();

  const handleSubmit: TConfirmBillFormSubmitHandler = useCallback(
    (values, action, resetForm) => {
      // Step-1: update bill context before form submission (required)
      const billContextValues = convertConfirmBillFormInputToBillPayContext(values, countryCode);
      billContextSetter(billContextValues);
      // Step-2: confirm bill with bill update input
      const handleConfirmBillInput = convertConfirmBillFormInputToUpdateBillInput(values);
      const confirmBill = (scheduleNow = true) =>
        handleConfirmBill({
          input: handleConfirmBillInput,
          billId,
          onSuccess: () => resetForm({ values: initialFormValues }),
          scheduleNow,
        });
      switch (action) {
        case BillFormAction.CONFIRM_NOW:
          return confirmBill(true);
        case BillFormAction.CONFIRM_LATER:
          return confirmBill(false);
        case BillFormAction.PAY_NOW:
          return navigate(`/bill/${billId}/pay`);
        case BillFormAction.CANCEL_SCHEDULED: {
          const { referenceId, paymentGateway, accountId } = recentTransaction;
          return cancelBillScheduledPayment({
            referenceId,
            paymentGateway,
            id: billId,
            accountId,
          });
        }
      }
    },
    [
      billContextSetter,
      billId,
      cancelBillScheduledPayment,
      countryCode,
      handleConfirmBill,
      initialFormValues,
      navigate,
      recentTransaction,
    ],
  );

  const popupNotification = useMemo<JSX.Element | null>(() => {
    //NOTES: add more popup if required
    if (status === BillStatus.Scheduled) {
      return cancelScheduledPaymentModal;
    }
    return null;
  }, [cancelScheduledPaymentModal, status]);

  // NOTE: refer to https://github.com/prospa-group/frontend-gateway/blob/0368f9b2f2f5fa86523322dfb4aa2adb6a43c3b5/apps/gateway-api/src/resolvers/mutations/payments/BankTransferResolver.ts#L57

  const validationRules = useMemo<ConfirmBillFormValidationRule>(() => {
    const { LOCM, BA } = customerProducts;
    return {
      billerName: {
        isRequired: true,
        maxLength: 32,
        regExpValidator: {
          pattern: validationConfig.bankAccountName.pattern,
          errorMessage: BillConfirmFormErrorMessage.InvalidBillerName,
        },
      },
      amount: {
        isRequired: true,
        // NOTE: 10 is min value for user only have locm
        // if user have BA then they can proceed with 0.01
        minValue: LOCM && !BA ? 10 : 0.01,
        displayAsCurrency: true,
      },
      dueDate: {
        isRequired: true,
      },
      description: {
        isRequired: true,
        maxLength: customerProducts.LOCM ? 18 : 35,
        regExpValidator: {
          pattern: validationConfig.paymentDescription.pattern,
          errorMessage: BillConfirmFormErrorMessage.InvalidDescription,
        },
      },
      reference: {
        isRequired: customerProducts.LOCM,
        maxLength: countryCode === Country.Au ? 18 : 12,
        regExpValidator: {
          pattern: validationConfig.paymentReference.pattern,
          errorMessage: BillConfirmFormErrorMessage.InvalidReference,
        },
      },
    };
  }, [customerProducts, countryCode]);

  return {
    billId,
    status,
    countryCode,
    handleSubmit,
    recentTransaction,
    popupNotification,
    initialFormValues,
    validationRules,
    isNonEditable: NON_EDITABLE_STATUSES.includes(status),
    isSubmitting: isConfirmBillLoading || isCancelScheduledBillLoading,
  };
}
