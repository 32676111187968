import { currencyFormatter } from '@utils';

import styles from './FeesItem.module.scss';
import { type FeesItemProps } from './types';

export const FeesItem = ({ label, value }: FeesItemProps) => {
  return (
    <li className={styles.FeesItem}>
      <div className="pds-text">{label}</div>
      {value !== null && value !== undefined && (
        <div className="pds-text">{currencyFormatter(value)}</div>
      )}
    </li>
  );
};
