import { useEffect } from 'react';

import { initialiseQualtrics } from '@utils';

export type TUseQualtricsLoader = {
  loadScript: boolean;
  onLoadCallback?: () => void;
};
export const useQualtricsLoader = ({ loadScript, onLoadCallback }: TUseQualtricsLoader) => {
  useEffect(() => {
    loadScript && initialiseQualtrics(onLoadCallback);
  }, [loadScript, onLoadCallback]);
};
