import { type FC, type ReactNode } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isBrowser, isMobile } from 'react-device-detect';

import classNames from 'classnames';
import { isValid } from 'date-fns';
import { addDays, addYears, endOfYear, parseISO } from 'date-fns';
import { type FieldProps } from 'formik';

import calendar from '@prospa/icons/dist/svg/UtilitiesCalendar3mons.svg';

import { dateToString } from '@utils';

import './FormikDatePicker.scss';
import type { FormikDatePickerProps } from './types';

export const MAXDATE = endOfYear(addYears(new Date(), 1));
export const MINDATE = addDays(new Date(), 1);

export const FormikDatePicker: FC<FieldProps & FormikDatePickerProps> = ({
  field,
  form: { touched, errors, setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  minDate = MINDATE,
  maxDate = MAXDATE,
  showErrors = true,
  disabled = false,
}) => {
  return (
    <div
      className={classNames('datepicker', { error: !!touched[field.name] && errors[field.name] })}
    >
      <label className={classNames('datepicker__label', 'pds-caption')}>{label}</label>
      {isMobile && (
        <div className="datepicker__mobile">
          <input
            className={classNames('datepicker__mobile-input', field.value && 'has-value')}
            type="date"
            placeholder="dd/mm/yyyy"
            min={dateToString(minDate)}
            max={dateToString(maxDate)}
            id={field.name}
            name={field.name}
            value={(field.value && dateToString(field.value)) || ''}
            onChange={event => {
              setFieldValue(field.name, parseISO(event.target.value));
            }}
            onBlur={() => {
              setFieldTouched(field.name, true);
            }}
            disabled={disabled}
          />
          <img
            src={calendar}
            className={classNames('datepicker__mobile-icon', 'calendar-icon')}
            alt="calendar icon"
            aria-hidden="true"
            role="presentation"
          />
        </div>
      )}
      {isBrowser && (
        <ReactDatePicker
          placeholderText="dd/mm/yyyy"
          autoComplete="off"
          id={field.name}
          name={field.name}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat="dd/MM/yyyy"
          selected={field.value}
          onChange={async (date: Date | null) => {
            if (!date) return;
            // validation out of sync https://github.com/jaredpalmer/formik/issues/2059
            await setFieldValue(field.name, date);
            setFieldTouched(field.name, true, true);
          }}
          onChangeRaw={async event => {
            // NOTE: this handle user input event
            if (event?.target) {
              const date = new Date((event.target as HTMLInputElement).value);
              if (isValid(date)) {
                await setFieldValue(field.name, date);
                setFieldTouched(field.name, true, true);
              }
            }
          }}
          onBlur={() => {
            setFieldTouched(field.name, true);
          }}
          disabled={disabled}
        />
      )}
      {!!touched[field.name] && errors[field.name] && showErrors && (
        <div className={classNames('datepicker__error', 'pds-caption')}>
          {errors[field.name] as ReactNode}
        </div>
      )}
    </div>
  );
};
