import type { ReactNode } from 'react';

import { type ContractStatus, type Country, type DrawdownStatus } from '@generated-fg';
import { type LineStatusType } from '@generated-mg';

type AccountStatus = `${LineStatusType}`;

export interface AccountItem {
  id: string;
  name: string;
  displayInfo: {
    total: string;
    available?: string;
    details?: ReactNode;
    maskedLinkedAccountNumber?: string;
  };
  balance: {
    total?: number;
    available: number;
    paymentAvailable?: number;
    dailyPaidAmount?: number;
    mfaThreshold?: number;
  };
  status: AccountStatus | DrawdownStatus | ContractStatus | string;
  productType: ProductTypes;
  countryCode?: Country;
  itemDisabled?: boolean;
}

export enum ProductTypes {
  SBL = 'sbl',
  SBLM = 'sblm',
  LOC = 'loc',
  LOCM = 'locm',
  BA = 'ba',
  CONTRACT = 'contract',
}
