import { type KeyboardEvent, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import classNames from 'classnames';

import { DocumentArticlesBg as AccountIcon } from '@prospa/icons';
import { ChevronDown } from '@prospa/salt-icons';

import type { AccountItem } from '../../models/Account';
import './AccountSelector.scss';
import { AccountSelectorOption } from './AccountSelectorOption/AccountSelectorOption';
import { RenderSelectedDefault } from './AccountSelectorRenders';
import type { AccountSelectorProps } from './types';

export const AccountSelector = ({
  accountList,
  selectedAccount,
  renderSelectedAccount: RenderSelectedAccount = RenderSelectedDefault,
  accountSelectorOptionProps = {},
  className = '',
  icon = <AccountIcon className="account-selector__icon" width={44} height={44} />,
}: AccountSelectorProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const { optionOnClick, setSelectedAccountIndex, ...accountSelectorOptionPropsRest } =
    accountSelectorOptionProps;

  const hasAccounts = accountList.length > 0;

  const dropdownDisabled = selectedAccount && accountList.length === 1;

  const handleOptionClick = (account: AccountItem, index: number) => {
    optionOnClick(account);
    if (setSelectedAccountIndex) setSelectedAccountIndex(index);
    resetState();
  };

  const ref = useOnclickOutside(() => {
    resetState();
  });

  const resetState = () => {
    setMenuOpen(false);
  };

  const onDropdownClick = () => {
    if (hasAccounts && !dropdownDisabled) setMenuOpen(!menuOpen);
  };

  const handleListKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    const currentElement: string = (e.target as HTMLElement).id;
    const firstOption = document.getElementById('account-selector-option-0');

    let nextElement = firstOption,
      previousElement = firstOption;

    if (currentElement.includes('account-selector-option')) {
      const currentIndex = Number(currentElement[currentElement.lastIndexOf('-') + 1]);

      nextElement =
        currentIndex === accountList.length - 1
          ? firstOption
          : document.getElementById(`account-selector-option-${currentIndex + 1}`);

      previousElement =
        currentIndex === 0
          ? document.getElementById(`account-selector-option-${accountList.length - 1}`)
          : document.getElementById(`account-selector-option-${currentIndex - 1}`);
    } else {
      setMenuOpen(true);
    }

    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        resetState();
        break;
      case 'ArrowUp':
        e.preventDefault();
        previousElement?.focus();
        break;
      case 'ArrowDown':
        e.preventDefault();
        nextElement?.focus();
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={classNames('account-selector__container', {
        'account-selector__dropdown-disabled': dropdownDisabled,
      })}
      onClick={onDropdownClick}
      ref={ref}
    >
      {dropdownDisabled ? (
        <div className={classNames('account-selector', className)}>
          {icon}
          <div
            className={classNames(
              'account-selector__selected-option',
              'account-selector__account-selected',
            )}
          >
            <RenderSelectedAccount selectedAccount={selectedAccount} />
          </div>
        </div>
      ) : (
        <button
          className={classNames(
            'account-selector',
            !hasAccounts && 'account-selector-disabled',
            className,
          )}
          aria-haspopup="listbox"
          aria-expanded={menuOpen}
          data-open={menuOpen}
          onKeyDown={handleListKeyDown}
        >
          {icon}
          <div
            className={classNames('account-selector__selected-option', {
              'account-selector__account-selected': selectedAccount,
            })}
          >
            {selectedAccount ? (
              <RenderSelectedAccount selectedAccount={selectedAccount} />
            ) : (
              <p className="account-selector__select">Please select an account</p>
            )}
          </div>
          <ChevronDown
            size={24}
            data-open={menuOpen}
            className={classNames(
              'account-selector__dropdown-icon',
              !hasAccounts && 'account-selector-disabled-svg',
            )}
          />
        </button>
      )}
      {menuOpen && (
        <ul className="account-selector__menu" tabIndex={-1} onKeyDown={handleListKeyDown}>
          {accountList.map((account, index) => (
            <AccountSelectorOption
              key={account.id}
              index={index}
              accountItem={account}
              onClick={() => handleOptionClick(account, index)}
              {...accountSelectorOptionPropsRest}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
