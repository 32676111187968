import { useEffect, useMemo } from 'react';

import {
  type BillPay,
  GetBillsEmailDocument,
  useGetBillsEmailLazyQuery,
  useInitialiseBillPayMutation,
} from '@generated-fg';

import { BILL_PAY_MODALS } from '@constants';
import { trackBAActionEvent } from '@utils';

import { type BillsEmail, type TUseBillsEmail } from './types';

/**
 * Handles logic related to Bill Pay emails and inboxes.
 */
export const useBillsEmail = ({ openModal, setOpenModal }: TUseBillsEmail): BillsEmail => {
  const [
    getBillsEmail,
    { data: { user: { billPay = null as BillPay } = {} } = {}, loading: isBillPayEmailFetching },
  ] = useGetBillsEmailLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [
    initialiseBillPayEmail,
    {
      data: { initialiseBillPay: initialisedEmail = '' } = {},
      loading: isBillPayEmailInitialising = false,
    },
  ] = useInitialiseBillPayMutation({
    refetchQueries: [{ query: GetBillsEmailDocument }],
    awaitRefetchQueries: true,
    onError: error => {
      trackBAActionEvent('bill_error', error.message);
      setOpenModal(BILL_PAY_MODALS.INTERNAL_ERROR);
    },
  });

  useEffect(() => {
    try {
      getBillsEmail();
    } catch (error) {
      trackBAActionEvent('bill_error', error.message);
    }
  }, [getBillsEmail]);

  const email = useMemo((): string => {
    if (initialisedEmail) return initialisedEmail;
    return billPay?.uploadEmailAddress || '';
  }, [billPay, initialisedEmail]);

  const handleCloseModal = () => setOpenModal(null);

  return {
    email: email,
    isEmailFetching: isBillPayEmailFetching,
    isEmailInitialising: isBillPayEmailInitialising,
    openModal: openModal,
    handleCloseModal: handleCloseModal,
    initEmail: initialiseBillPayEmail,
  };
};
