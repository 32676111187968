import { useCallback } from 'react';

import { Cross as ErrorIcon } from '@prospa/salt-icons';
import { Copy as CopyIcon } from '@prospa/salt-icons';

import { useToastContext } from '../useToastContext';

type UseCopyTextProps = {
  toCopy: string;
  toastMessage?: string;
  errorMessage?: string;
  icon?: React.FC;
};

export const useCopyText = ({
  toCopy,
  toastMessage = 'Copied',
  errorMessage = 'Copy failed',
  icon = CopyIcon,
}: UseCopyTextProps) => {
  const { addToast } = useToastContext();
  const handleCopy = useCallback(() => {
    try {
      navigator.clipboard.writeText(toCopy);
      addToast(toastMessage, icon);
    } catch (error) {
      addToast(errorMessage, ErrorIcon);
    }
  }, [toCopy, addToast, toastMessage, errorMessage, icon]);
  return { handleCopy };
};
