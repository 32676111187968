import type { FC } from 'react';

import { Card } from '@components';

import { currencyFormatter } from '@utils';

import styles from './BillsSummary.module.scss';
import type { BillsSummaryProps } from './types';

export const BillsSummary: FC<BillsSummaryProps> = ({ dueThisMonthAmount, dueNextMonthAmount }) => (
  <div className={styles.BillsSummary}>
    <Card className={styles.Card}>
      <p>To pay this month</p>
      <span className={styles.Amount} data-testid="dueThisMonth">
        {currencyFormatter(dueThisMonthAmount || 0)}
      </span>
    </Card>
    <Card className={styles.Card}>
      <p>Due next month</p>
      <span className={styles.Amount} data-testid="dueNextMonth">
        {currencyFormatter(dueNextMonthAmount || 0)}
      </span>
    </Card>
  </div>
);

export default BillsSummary;
