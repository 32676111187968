import classNames from 'classnames';

import styles from './index.module.scss';
import { type BillsHeaderProps } from './types';

export const BillsHeader = ({ action, title }: BillsHeaderProps) => {
  return (
    <div className={classNames(styles.BillsHeader)} data-testid="bills-header">
      {title}
      {action && <div className={styles.BillsPageActions}>{action}</div>}
    </div>
  );
};
