import classNames from 'classnames';

import { Cross as CrossButton } from '@prospa/salt-icons';

import { ButtonSize, ButtonType } from '../../constants';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
// TODO: migrate to CSS modules
import './Banner.scss';
import type { BannerProps } from './types';

// TODO: refactoring needed https://prospa.atlassian.net/browse/AW-776
export const Banner = ({
  icon,
  infoIcon,
  title,
  subtitle,
  onClose,
  className = 'banner',
  buttonType = ButtonType.PRIMARY,
  onClick,
  buttonText,
  buttonSize,
  buttonProps,
  secondaryButtonProps,
  ...props
}: BannerProps) => {
  const shouldRenderButton = Boolean(
    (buttonText || buttonProps?.label) && (onClick || buttonProps?.onClick),
  );
  const shouldRenderSecondaryButton = !!(
    secondaryButtonProps?.label && secondaryButtonProps?.onClick
  );

  return (
    <Card className={className} {...props}>
      {onClose && (
        <div className={classNames(`${className}__close-btn`)} role="button" onClick={onClose}>
          <CrossButton size={24} />
        </div>
      )}
      <div data-testid="data-qa-banner" className={classNames(`${className}__info-icon`)}>
        {icon}
      </div>
      <span className={classNames(`${className}__body`)}>
        <div className={classNames(`${className}__info`)}>
          <span
            className={classNames([
              className !== 'mobile-banner' && 'pds-lead',
              `${className}__info-heading`,
            ])}
          >
            {title}
          </span>
          <span className={classNames([`${className}__info-arrival`])}>{subtitle}</span>
        </div>
        <div className={classNames(`${className}__right-info-icon`)}>{infoIcon}</div>
        {(shouldRenderButton || shouldRenderSecondaryButton) && (
          <div className={`${className}__button-section`}>
            {shouldRenderSecondaryButton && (
              <Button
                className={classNames(`${secondaryButtonProps?.className || className}__btn`)}
                label={secondaryButtonProps.label}
                onClick={secondaryButtonProps.onClick}
                type={secondaryButtonProps?.type || ButtonType.SECONDARY}
                size={
                  secondaryButtonProps?.size ||
                  (className === 'mobile-banner' ? ButtonSize.SMALL : ButtonSize.MEDIUM)
                }
              />
            )}
            {shouldRenderButton && (
              <Button
                className={classNames(`${className}__btn`)}
                label={buttonText}
                onClick={onClick}
                type={buttonType}
                size={
                  buttonSize ||
                  (className === 'mobile-banner' ? ButtonSize.SMALL : ButtonSize.MEDIUM)
                }
                {...buttonProps}
              />
            )}
          </div>
        )}
      </span>
    </Card>
  );
};
