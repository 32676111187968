import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetRepaymentCalculatorDataQuery } from '@generated-fg';

import {
  RepaymentBreakdown,
  RepaymentCalculatorForm,
  Section,
  WeeklyRepayments,
} from '@components';

import { DLS_TYPES } from '@constants';
import { useOpenProductUrl } from '@hooks';
import { ProductCVPType } from '@models';
import { EventActions, trackBAActionEvent } from '@utils';

import { RepaymentsSchedule } from '../../components/RepaymentsSchedule/RepaymentsSchedule';
import { AppContext } from '../../contexts';
import { useProductsPageData } from '../Products/hooks';
import { BusinessLoanRepaymentCalculator } from './BusinessLoanRepaymentCalculator';
import { LineOfCreditRepaymentCalculator } from './LineOfCreditRepaymentCalculator';
import styles from './RepaymentCalculator.module.scss';
import {
  type BusinessLoanRepaymentCalculations,
  type BusinessLoanRepaymentCalculatorFormValues,
  type LineOfCreditRepaymentCalculations,
  type LineOfCreditRepaymentCalculatorFormValues,
  type RepaymentCalculations,
  type RepaymentCalculatorFormRef,
  type RepaymentCalculatorFormValues,
  RepaymentCalculatorTab,
} from './types';

enum RepaymentCalculatorProduct {
  BUSINESS_LOAN = 'sbl',
  LINE_OF_CREDIT = 'loc',
}

type RepaymentCalculatorURLParams = {
  productType: RepaymentCalculatorProduct;
};

export const isLocCalculations = (
  calculations: RepaymentCalculations,
): calculations is LineOfCreditRepaymentCalculations =>
  !('paybackAmount' in calculations) ||
  !('loanTermYears' in calculations) ||
  !('weeklyInstalments' in calculations);

const isLoCCalculatorFormValues = (
  calculatorInputs: RepaymentCalculatorFormValues,
): calculatorInputs is LineOfCreditRepaymentCalculatorFormValues =>
  'expectedDrawdownAmount' in calculatorInputs;

const buildCalculationsEventLabel = (calculations: RepaymentCalculations): string => {
  if (!calculations) return;

  if (isLocCalculations(calculations)) {
    const { weeklyRepayment } = calculations;
    const fixedWeeklyRepayment = weeklyRepayment.toFixed(2);
    return `weekly_repayment:${fixedWeeklyRepayment}`;
  }

  if (!isLocCalculations(calculations)) {
    const { weeklyRepayment, paybackAmount, weeklyInstalments } = calculations;
    const fixedWeeklyRepayment = weeklyRepayment.toFixed(2);
    const fixedPaybackAmount = paybackAmount.toFixed(2);
    return `weekly_repayment:${fixedWeeklyRepayment}_payback_amount:${fixedPaybackAmount}_weekly_instalments:${weeklyInstalments}`;
  }

  return '';
};

const buildCalculatorInputsEventLabel = (calculatorInputs: RepaymentCalculatorFormValues) => {
  const { amount, loanTermYears, fundingPurpose, industry } = calculatorInputs;
  let eventLabel = `amount:${amount}_loan_term_years:${loanTermYears}`;

  if (isLoCCalculatorFormValues(calculatorInputs)) {
    const { expectedDrawdownAmount } = calculatorInputs;
    eventLabel += `_expected_drawdown_amount:${expectedDrawdownAmount}`;
  }

  if (fundingPurpose) {
    eventLabel += `_funding_purpose:${fundingPurpose}`;
  }

  if (industry) {
    eventLabel += `_industry:${industry}`;
  }

  return eventLabel;
};

const buildTabEventLabel = (tab: RepaymentCalculatorTab) => {
  switch (tab) {
    case RepaymentCalculatorTab.BUSINESS_LOAN:
      return `tab:${RepaymentCalculatorProduct.BUSINESS_LOAN}`;
    case RepaymentCalculatorTab.LINE_OF_CREDIT:
      return `tab:${RepaymentCalculatorProduct.LINE_OF_CREDIT}`;
    default:
      return null;
  }
};

export const RepaymentCalculator = () => {
  const formRef = useRef<RepaymentCalculatorFormRef>(null);

  const navigate = useNavigate();
  const [calculations, setCalculations] = useState<RepaymentCalculations>(null);

  const { productType } = useParams<RepaymentCalculatorURLParams>();
  const [currentTab, setCurrentTab] = useState<RepaymentCalculatorTab>(
    productType === RepaymentCalculatorProduct.LINE_OF_CREDIT
      ? RepaymentCalculatorTab.LINE_OF_CREDIT
      : RepaymentCalculatorTab.BUSINESS_LOAN,
  );

  const {
    signInInfo: { countryCode, isMobileAppUser },
  } = useContext(AppContext);

  const dls: (typeof DLS_TYPES)[keyof typeof DLS_TYPES] = useMemo(() => {
    if (!countryCode || !isMobileAppUser) return DLS_TYPES.CAPITAL_REPAY_CALC;

    switch (countryCode) {
      case 'AU':
        return DLS_TYPES.AU_MOBILE_REPAY_CALC;
      case 'NZ':
        return DLS_TYPES.NZ_MOBILE_REPAY_CALC;
      default:
        return DLS_TYPES.CAPITAL_REPAY_CALC;
    }
  }, [countryCode, isMobileAppUser]);

  const openProductUrl = useOpenProductUrl(dls);

  const { data: repaymentCalculatorData, loading: isGetRepaymentCalculatorDataLoading } =
    useGetRepaymentCalculatorDataQuery();

  const {
    repaymentCalculatorPageProps: { alertProps },
    loading: isAlertPropsLoading,
  } = useProductsPageData();

  const loading = isGetRepaymentCalculatorDataLoading || isAlertPropsLoading;

  // What APR is: https://prospa.atlassian.net/wiki/x/iweQGg
  const apr = useMemo(() => {
    const DEFAULT_APR = 31;
    const repaymentCalculatorRates = repaymentCalculatorData?.user?.repaymentCalculator;

    switch (currentTab) {
      case RepaymentCalculatorTab.BUSINESS_LOAN:
        return repaymentCalculatorRates?.smallBusinessLoanRate;
      case RepaymentCalculatorTab.LINE_OF_CREDIT:
        return repaymentCalculatorRates?.lineOfCreditRate;
      default:
        return DEFAULT_APR;
    }
  }, [currentTab, repaymentCalculatorData?.user?.repaymentCalculator]);

  const onTabSelect = useCallback(
    (tabIndex: RepaymentCalculatorTab) => {
      if (tabIndex === currentTab) return;

      const newRepaymentCalculatorTab: RepaymentCalculatorTab = tabIndex;
      setCurrentTab(newRepaymentCalculatorTab);

      const tabEventLabel = buildTabEventLabel(newRepaymentCalculatorTab);
      trackBAActionEvent(
        EventActions.REPAYMENT_CALCULATOR_CALCULATE_PAGE_LOAN_TYPE_TAB_CLICKED,
        tabEventLabel,
      );
      setCalculations(null);
    },
    [currentTab],
  );

  const onCalculateClick = useCallback(() => {
    const calculatorInputs = formRef?.current?.values;
    const isCalculatorInputsValid = formRef?.current?.isValid;

    if (!isCalculatorInputsValid) return;

    const calculateBusinessLoanRepayments = () => {
      const { amount, loanTermYears } =
        calculatorInputs as BusinessLoanRepaymentCalculatorFormValues;

      const businessLoanRepaymentCalculator = new BusinessLoanRepaymentCalculator(
        amount,
        loanTermYears,
        apr,
      );

      const businessLoanCalculations: BusinessLoanRepaymentCalculations = {
        weeklyRepayment: businessLoanRepaymentCalculator.calculateWeeklyRepayment(),
        paybackAmount: businessLoanRepaymentCalculator.calculatePaybackAmount(),
        loanTermYears: businessLoanRepaymentCalculator.getLoanTerm(),
        weeklyInstalments: businessLoanRepaymentCalculator.calculateWeeklyInstalments(),
      };

      setCalculations(businessLoanCalculations);

      const calculatorInputsEventLabel = buildCalculatorInputsEventLabel(calculatorInputs);
      const calculationsEventLabel = buildCalculationsEventLabel(businessLoanCalculations);
      const tabEventLabel = buildTabEventLabel(currentTab);

      trackBAActionEvent(
        EventActions.REPAYMENT_CALCULATOR_CALCULATE_PAGE_CALCULATE_BUTTON_CLICKED,
        tabEventLabel + '_' + calculatorInputsEventLabel + '_' + calculationsEventLabel,
      );
    };

    const calculateLineOfCreditRepayments = () => {
      const { amount, expectedDrawdownAmount } =
        calculatorInputs as LineOfCreditRepaymentCalculatorFormValues;

      const lineOfCreditRepaymentCalculator = new LineOfCreditRepaymentCalculator(
        amount,
        expectedDrawdownAmount,
        apr,
      );

      const lineOfCreditCalculations: LineOfCreditRepaymentCalculations = {
        weeklyRepayment: lineOfCreditRepaymentCalculator.calculateWeeklyRepayment(),
        repaymentsSchedule: lineOfCreditRepaymentCalculator.buildRepaymentsSchedule(),
      };

      setCalculations(lineOfCreditCalculations);

      const calculatorInputsEventLabel = buildCalculatorInputsEventLabel(calculatorInputs);
      const calculationsEventLabel = buildCalculationsEventLabel(lineOfCreditCalculations);
      const tabEventLabel = buildTabEventLabel(currentTab);

      trackBAActionEvent(
        EventActions.REPAYMENT_CALCULATOR_CALCULATE_PAGE_CALCULATE_BUTTON_CLICKED,
        tabEventLabel + '_' + calculatorInputsEventLabel + '_' + calculationsEventLabel,
      );
    };

    switch (currentTab) {
      case RepaymentCalculatorTab.BUSINESS_LOAN:
        calculateBusinessLoanRepayments();
        break;
      case RepaymentCalculatorTab.LINE_OF_CREDIT:
        calculateLineOfCreditRepayments();
        break;
      default:
        break;
    }
  }, [apr, currentTab]);

  const onQualifyClick = useCallback(() => {
    trackBAActionEvent(
      EventActions.REPAYMENT_CALCULATOR_CALCULATED_PAGE_QUALIFY_APPLICATION_BUTTON_CLICKED,
    );

    switch (currentTab) {
      case RepaymentCalculatorTab.BUSINESS_LOAN:
        openProductUrl(ProductCVPType.SBL);
        break;
      case RepaymentCalculatorTab.LINE_OF_CREDIT:
        openProductUrl(ProductCVPType.LOC);
        break;
      default:
        break;
    }
  }, [currentTab, openProductUrl]);

  const renderDisclaimer = (apr: number, currentTab: RepaymentCalculatorTab) => {
    if (currentTab === RepaymentCalculatorTab.BUSINESS_LOAN) {
      return (
        <span className={styles.RepaymentCalculatorDisclaimer}>
          Calculations are based on your input and are for illustrative purposes only. They are not
          forecasts, offers of credit or financial advice and do not imply product suitability.
          Subject to applicable law, Prospa will not be liable for any loss or damage arising from
          your use of the calculator. Terms and conditions, standard credit criteria, and applicable
          fees apply to Prospa products.
          <br />
          This calculator uses a fixed annual percentage rate (APR) of {apr}% as the interest rate
          to calculate repayments, which include only the principal and interest, with a 3.5%
          origination fee.
        </span>
      );
    }

    if (currentTab === RepaymentCalculatorTab.LINE_OF_CREDIT) {
      return (
        <span className={styles.RepaymentCalculatorDisclaimer}>
          Calculations are based on your input and are for illustrative purposes only. They are not
          forecasts, offers of credit or financial advice and do not imply product suitability.
          Subject to applicable law, Prospa will not be liable for any loss or damage arising from
          your use of the calculator. Terms and conditions, standard credit criteria, and applicable
          fees apply to Prospa products.
          <br />
          To calculate repayments, this calculator uses a fixed annual percentage rate (APR) of{' '}
          {apr}% as the interest rate and a weekly service fee of 0.046% of the facility limit.
          Calculated repayments include principal, interest, and fees. Repayments are calculated
          based on the first drawdown amount and do not consider the facility limit. The calculated
          repayments may not be sufficient to repay the total loan amount. At the end of the term,
          any outstanding balance must be repaid in full or over an additional two-year payment
          plan.
        </span>
      );
    }

    return null;
  };

  useEffect(() => {
    trackBAActionEvent(EventActions.REPAYMENT_CALCULATOR_CALCULATE_PAGE_VIEWED);
  }, []);

  useEffect(() => {
    switch (currentTab) {
      case RepaymentCalculatorTab.BUSINESS_LOAN:
        navigate(`/repayment-calculator/${RepaymentCalculatorProduct.BUSINESS_LOAN}`);
        break;
      case RepaymentCalculatorTab.LINE_OF_CREDIT:
        navigate(`/repayment-calculator/${RepaymentCalculatorProduct.LINE_OF_CREDIT}`);
        break;
      default:
        navigate(`/repayment-calculator/${RepaymentCalculatorProduct.BUSINESS_LOAN}`);
        break;
    }
  }, [currentTab, navigate]);

  return (
    <Section>
      <h2 className={styles.RepaymentCalculatorHeading}>Calculate your repayments</h2>
      <span className={styles.RepaymentCalculatorHeadingInfo}>
        Adjust the loan and term amounts to find the best repayment option for you.
      </span>

      <RepaymentCalculatorForm
        ref={formRef}
        onCalculateClick={onCalculateClick}
        onTabSelect={onTabSelect}
        loading={loading}
        tab={currentTab}
      />

      {calculations && (
        <>
          <WeeklyRepayments
            weeklyRepayment={calculations.weeklyRepayment}
            repaymentCalculatorTab={currentTab}
          />

          {currentTab === RepaymentCalculatorTab.BUSINESS_LOAN && (
            <>
              <RepaymentBreakdown
                alertProps={alertProps}
                loading={loading}
                onQualifyClick={onQualifyClick}
                calculations={calculations}
              />
              {renderDisclaimer(apr, currentTab)}
            </>
          )}

          {currentTab === RepaymentCalculatorTab.LINE_OF_CREDIT && (
            <>
              <RepaymentsSchedule
                alertProps={alertProps}
                loading={loading}
                onQualifyClick={onQualifyClick}
                calculations={calculations}
              />
              {renderDisclaimer(apr, currentTab)}
            </>
          )}
        </>
      )}
    </Section>
  );
};

export default RepaymentCalculator;
