import { type FC } from 'react';

import { type TableRowProps } from './types';

export const TableRow: FC<TableRowProps> = ({ children, className, ...props }) => {
  return (
    <tr className={className} {...props}>
      {children}
    </tr>
  );
};
