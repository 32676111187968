import { type ReactElement } from 'react';

import LoadMoreButton from '../LoadMoreButton/LoadMoreButton';
import type { LoadMoreListProps } from './types';

function LoadMoreList<T>({
  items,
  renderItem,
  hasLoadButton = false,
  currentPage,
  pageSize = 10,
  total,
  onLoadMore,
}): ReactElement<LoadMoreListProps<T>> {
  return (
    <div className="load-more-list">
      {items.map(renderItem)}
      {hasLoadButton && <LoadMoreButton {...{ currentPage, total, pageSize, onLoadMore }} />}
    </div>
  );
}

export default LoadMoreList;
