import { useEffect } from 'react';

import { useLocalStorage } from '@all-in-one-web/common-ui';

import { LOCAL_STORAGE_STATE } from '@constants';

import { type FullPageCampaignCode } from '../components/FullPageCampaign/types';
import { ReleasedNewFeatures } from '../components/Modal/types';
import type { LocalStorageStatesInApp } from '../models/AppData';

export type SyncErrorIds = string[];

export type LocalStorageState = {
  isFirstSignIn: boolean;
  viewedFullPageCampaigns: FullPageCampaignCode[];
  syncErrorsDismissed?: SyncErrorIds;
  authErrorDismissed?: boolean;
  /**
   * @deprecated Use acknowledgedNewFeatures instead
   */
  isNewFeatureAcknowledged?: boolean;
  acknowledgedNewFeatures: ReleasedNewFeatures[];
};

type LocalStorageStateTypes = boolean | FullPageCampaignCode | ReleasedNewFeatures | SyncErrorIds;

const initState = {
  isFirstSignIn: true,
  viewedFullPageCampaigns: [],
  syncErrorsDismissed: [],
  authErrorDismissed: undefined,
  isNewFeatureAcknowledged: false,
  acknowledgedNewFeatures: [],
};

export function useStateInLocalStorage(): LocalStorageStatesInApp {
  const [localStorageState, setLocalStorageStates] = useLocalStorage(
    LOCAL_STORAGE_STATE,
    initState,
  );

  useEffect(() => {
    if (localStorageState.isNewFeatureAcknowledged) {
      delete localStorageState.isNewFeatureAcknowledged;
      setLocalStorageStates({
        ...localStorageState,
        acknowledgedNewFeatures: [ReleasedNewFeatures.XERO],
      });
    }
  }, [localStorageState, setLocalStorageStates]);

  const setLocalStorageState =
    (key: keyof LocalStorageState) => (value: LocalStorageStateTypes) => {
      const state = localStorageState;
      let updatedState = state;
      switch (key) {
        case 'isFirstSignIn':
        case 'authErrorDismissed':
        case 'syncErrorsDismissed':
        case 'isNewFeatureAcknowledged': {
          updatedState = {
            ...state,
            [key]: value,
          };
          break;
        }
        case 'viewedFullPageCampaigns': {
          updatedState = {
            ...state,
            [key]: state.viewedFullPageCampaigns?.includes(value)
              ? state.viewedFullPageCampaigns
              : [...(state.viewedFullPageCampaigns || []), value],
          };
          break;
        }
        case 'acknowledgedNewFeatures': {
          // NOTE: ensure isNewFeatureAcknowledged will be removed if it is default value
          delete state.isNewFeatureAcknowledged;
          updatedState = {
            ...state,
            [key]: state.acknowledgedNewFeatures?.includes(value)
              ? state.acknowledgedNewFeatures
              : [...(state.acknowledgedNewFeatures || []), value],
          };
          break;
        }
      }
      return setLocalStorageStates(updatedState);
    };

  const {
    isFirstSignIn,
    viewedFullPageCampaigns,
    syncErrorsDismissed,
    authErrorDismissed,
    isNewFeatureAcknowledged,
    acknowledgedNewFeatures,
  } = localStorageState;

  return {
    firstSignIn: {
      isFirstSignIn,
      setIsFirstSignIn: setLocalStorageState('isFirstSignIn'),
    },
    fullPageCampaigns: {
      viewedFullPageCampaigns,
      setFullPagedCampaigns: setLocalStorageState('viewedFullPageCampaigns'),
    },
    integrationErrors: {
      syncErrorsDismissed,
      setSyncErrorsDismissed: setLocalStorageState('syncErrorsDismissed'),
      authErrorDismissed,
      setAuthErrorDismissed: setLocalStorageState('authErrorDismissed'),
    },
    newFeatureAcknowledged: {
      isNewFeatureAcknowledged,
      setIsNewFeatureAcknowledged: setLocalStorageState('isNewFeatureAcknowledged'),
    },
    acknowledgedNewFeatures: {
      acknowledgedNewFeatures,
      setAcknowledgedNewFeatures: setLocalStorageState('acknowledgedNewFeatures'),
    },
  };
}
