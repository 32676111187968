import type { FC } from 'react';

import classNames from 'classnames';

import { AccountSuspended } from '@prospa/salt-illustrations';

import { Modal } from '@components';

import styles from './styles.module.scss';
import type { DeleteBillModalProps } from './types';

export const DeleteBillModal: FC<DeleteBillModalProps> = ({
  billerName,
  primaryButtonProps,
  secondaryButtonProps,
  headerClassName,
  ...props
}) => {
  return (
    <Modal
      size="medium"
      showCloseModal
      heading={
        <div>
          <AccountSuspended className={styles.Icon} size={128} />
          <h4>Delete bill</h4>
        </div>
      }
      headerClassName={classNames(styles.Header, headerClassName)}
      primaryButtonProps={{
        label: 'Delete bill',
        ...primaryButtonProps,
      }}
      secondaryButtonProps={{
        label: 'Cancel',
        ...secondaryButtonProps,
      }}
      body={
        <p className={styles.Description}>
          Are you sure you want to delete the bill
          {billerName ? (
            <>
              {' '}
              from <b>{billerName}</b>
            </>
          ) : (
            ''
          )}
          ? It will no longer appear in your bills list.
        </p>
      }
      {...props}
    />
  );
};
