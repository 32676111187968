import { FullScreenContainer } from '../FullScreenContainer';
import styles from './FullScreenAnimation.module.scss';
import { type FullScreenAnimationProps } from './types';

export const FullScreenAnimation = ({
  animationTitle,
  animationSubTitle,
  animation: lottieAnimation,
}: FullScreenAnimationProps) => {
  return (
    <FullScreenContainer>
      <div className={styles.FullScreenAnimation}>{lottieAnimation}</div>
      {animationTitle && <h2 className={styles.FullScreenAnimationTitle}>{animationTitle}</h2>}
      {animationSubTitle && (
        <p className={styles.FullScreenAnimationSubtitle}>{animationSubTitle}</p>
      )}
    </FullScreenContainer>
  );
};
