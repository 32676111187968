import {
  type IReactAutoPollOptions,
  type IReactLazyLoadingOptions,
  type IReactManualPollOptions,
  LogLevel,
  type PollingMode,
  type SettingKeyValue,
  type SettingValue,
  createConsoleLogger,
} from 'configcat-react';

import { type FeatureFlag, type FeatureFlags, SettingKeyToFeatureFlagsKey } from '@models';

export type FeatureFlagConfig = {
  sdkKey: string;
  pollingMode?: PollingMode;
  options?: IReactAutoPollOptions | IReactLazyLoadingOptions | IReactManualPollOptions;
};

export const getFeatureFlagConfig = (): FeatureFlagConfig => {
  const logLevel = process.env.REACT_APP_CONFIG_CAT_LOG_LEVEL;
  const configCatLogLevel = LogLevel[logLevel] || LogLevel.Off;
  const logger = createConsoleLogger(configCatLogLevel);
  let sdkKey = null;

  switch (window?.__env?.environment) {
    case 'prod':
      sdkKey = process.env.REACT_APP_CONFIG_CAT_CLIENT_ID;
      break;
    case 'staging':
    case 'demo':
    default:
      sdkKey = process.env.REACT_APP_CONFIG_CAT_CLIENT_ID_DEV;
      break;
  }

  return {
    sdkKey,
    options: {
      logger,
    },
  };
};

export const settingKeyValueToFeatureFlag = (
  settingKeyValue: SettingKeyValue<SettingValue>,
): FeatureFlag => {
  const { settingKey, settingValue } = settingKeyValue;
  const featureFlagsKey: keyof FeatureFlags = SettingKeyToFeatureFlagsKey[settingKey];
  if (!featureFlagsKey || !settingKey || !settingValue) return null;
  switch (typeof settingValue) {
    case 'string': {
      try {
        return JSON.parse(settingValue) as FeatureFlags[typeof featureFlagsKey];
      } catch {
        return settingValue;
      }
    }
    case 'boolean': {
      return settingValue;
    }
    case 'number':
    default: {
      return null;
    }
  }
};

export const settingKeyValuesToFeatureFlags = (
  settingKeyValues: SettingKeyValue<SettingValue>[],
): FeatureFlags => {
  const featureFlags: FeatureFlags = {};
  settingKeyValues.forEach((settingKeyValue: SettingKeyValue<SettingValue>) => {
    const { settingKey } = settingKeyValue;
    const featureFlagsKey: keyof FeatureFlags = SettingKeyToFeatureFlagsKey[settingKey];
    const featureFlag = settingKeyValueToFeatureFlag(settingKeyValue);
    featureFlags[featureFlagsKey as string] = featureFlag;
  });
  return featureFlags;
};
