import classNames from 'classnames';

import { UofCashflow as CashflowIcon } from '@prospa/icons';

import { BusinessAccountStatus } from '@generated-fg';

import { Card, Spinner, Status } from '@components';

import { currencyFormatter } from '@utils';

import './BalanceDetails.scss';
import { type BalanceDetailsProps } from './types';

const BalanceDetails = ({
  accountData,
  loading,
  accountName,
  businessAccountStatus,
  locData,
  isAccountFrozen,
}: BalanceDetailsProps) => {
  const balance = currencyFormatter(
    accountData?.balance?.available || locData?.availableAmount.amount || 0,
  ); //   (total + overdraft limit)

  return loading ? (
    <div className="balance-details-spinner">
      <Spinner className="balance-details-spinner__component" />
    </div>
  ) : (
    <Card
      className={classNames('balance-details', {
        'balance-details__details-freeze': isAccountFrozen,
      })}
    >
      <div className="balance-details__icon">
        <CashflowIcon width={28} height={28} />
      </div>

      <div className="balance-details__details">
        <div className="balance-details__details">
          <div className="balance-details__details-row">
            <span
              className={classNames(
                'pds-caption',
                'pds-summary-text',
                'balance-details__details-description',
              )}
            >
              From
            </span>
            <div className={classNames('balance-details__details-row')}>
              <span className={classNames('pds-text', 'balance-details__details-row-details')}>
                <span className="balance-details__details-row-account">{accountName}</span>
                {businessAccountStatus === BusinessAccountStatus.Frozen && (
                  <Status label={businessAccountStatus} level="error" />
                )}
              </span>
              {!!accountData && (
                <span className={classNames('pds-summary-text')}>
                  {accountData?.displayInfo?.details}
                </span>
              )}
            </div>
          </div>
          <div className="balance-details__details-row">
            <span
              className={classNames(
                'pds-caption',
                'pds-text',
                'balance-details__details-description-mobile',
              )}
            >
              Available
            </span>
            <span className={classNames('pds-text', 'balance-details__details-row-details')}>
              {balance}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BalanceDetails;
