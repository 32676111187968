import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';

import { internalOperations } from '@constants';

import Api from '../../services/Api';

export const contextLink = setContext(({ operationName }, { headers }) => {
  // Currently, the hayToken is sent to all the gql requests except internal which may be a security issue if we have another gql provider
  const accessToken = internalOperations.has(operationName) ? Api.token : Api.hayToken;
  const authHeader = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

  const correlationId = uuidv4();
  const correlationIdHeader = { 'X-Correlation-ID': correlationId };
  Sentry.setTag('X-Correlation-ID', correlationId);

  return {
    headers: {
      ...headers,
      ...authHeader,
      ...correlationIdHeader,
    },
  };
});
