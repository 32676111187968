import type React from 'react';
import { useEffect, useState } from 'react';

export const useIntersect = ({
  root = null,
  rootMargin,
  threshold = 0,
}): [React.Dispatch<React.SetStateAction<Element>>, IntersectionObserverEntry] => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry>();
  const [node, setNode] = useState<Element>(null);

  useEffect(() => {
    const hasIOSupport = !!window?.IntersectionObserver;

    if (!hasIOSupport || !node) return;

    const observer = new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold,
    });
    observer.observe(node);

    return () => observer.disconnect();
  }, [node, root, rootMargin, threshold]);

  return [setNode, entry];
};
