import { type FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { type FieldProps } from 'formik';
import numeral from 'numeral';

import { numberFormats } from '@constants';
import { validationConfig } from '@utils';

import { Input } from '../Formik';
import type { CurrencyInputProps } from './types';

export const CurrencyInput: FC<FieldProps & CurrencyInputProps> = ({
  label,
  field,
  maxLength,
  disabled,
  className,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState<string>(
    field.value
      ? `$${numeral(field.value).format(numberFormats.amountFormatWithDecimal, Math.floor)}`
      : '',
  );
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const { onChange, onBlur } = field;

  const _onChange = event => {
    const inputText = event.target.value;
    const validated = inputText.match(validationConfig.currencyInput.maxDecimalPlacesPattern);
    if (validated) {
      setInternalValue(inputText);
      if (onChange) {
        onChange(event);
      }
    }
  };

  const _onBlur = event => {
    setIsFocus(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const _onFocus = () => {
    const numeralValue = numeral(internalValue);
    const parsedValue = numeralValue.value();
    if (validationConfig.currencyInput.pattern.test(internalValue) && parsedValue) {
      setInternalValue(parsedValue.toString());
    }
    setIsFocus(true);
  };

  useEffect(() => {
    if (!isFocus) {
      const numeralValue = numeral(internalValue);
      const parsedValue = numeralValue.value();
      if (validationConfig.currencyInput.pattern.test(internalValue) && parsedValue) {
        setInternalValue(
          `$${numeralValue.format(numberFormats.amountFormatWithDecimal, Math.floor)}`,
        );
      }
    }
  }, [internalValue, isFocus]);

  return (
    <Input
      className={classNames('currency-input', className)}
      {...props}
      type="text"
      maxLength={maxLength}
      field={{
        ...field,
        onChange: _onChange,
        value: internalValue,
        onBlur: _onBlur,
      }}
      onFocus={_onFocus}
      label={label}
      disabled={disabled}
    />
  );
};
