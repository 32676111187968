import classNames from 'classnames';

import { ExpandedContentToggler } from '@all-in-one-web/common-ui';

import styles from './LoanDetailsToggler.module.scss';
import type { LoanDetailsTogglerProps } from './types';

export const LoanDetailsToggler = ({
  togglerProps,
  rightLabel,
  className,
}: LoanDetailsTogglerProps) => {
  return (
    <div
      data-testid="loan-details-toggler"
      className={classNames(
        styles.TogglerContainer,
        { [styles.TogglerContainerWithRightLabel]: !!rightLabel },
        className,
      )}
    >
      <ExpandedContentToggler {...togglerProps} />
      {!!rightLabel && <div>{rightLabel}</div>}
    </div>
  );
};
