import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';

import { WebDownload as Download, Statement as StatementIcon } from '@prospa/icons';

import { BUTTON } from '@constants';
import { downloadFileUsingLink, trackBAActionEvent } from '@utils';

import environments from '../environments';
import { ProductTypes } from '../models/Account';
import Api from '../services/Api';

export interface LoanButtonOptions {
  productType: ProductTypes;
  applicationId: string;
  onDownloadRepaymentScheduleError: (error: unknown, endpoint: string) => void;
}

export interface LoanButtonOption {
  label: string;
  onClick: () => Promise<void> | void;
  icon: React.FC;
  loading?: boolean;
}

export const useLoanButtonOptions = (options: LoanButtonOptions): LoanButtonOption[] => {
  const { productType, applicationId, onDownloadRepaymentScheduleError } = options;
  const getRepaymentEndpoint =
    productType === ProductTypes.SBL
      ? `${environments.repaymentSchedule.baseUri}/${applicationId}`
      : `${environments.lineOfCreditManagement.loanAccountBaseUrl}/accounts/${applicationId}/repayment-schedule/pdf`;
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const handleRepaymentScheduleClick = async () => {
    try {
      setIsDownloadingFile(true);

      const response = await axios.get(getRepaymentEndpoint, {
        headers: {
          Authorization: `Bearer ${Api.token}`,
        },
        responseType: 'blob',
      });

      const content = response.data;
      downloadFileUsingLink({ name: 'RepaymentSchedule', content, extension: 'pdf' });
    } catch (error) {
      onDownloadRepaymentScheduleError(error, getRepaymentEndpoint);
    } finally {
      setIsDownloadingFile(false);
    }
  };

  const buttonOptions = [
    {
      label: BUTTON.NAVIGATE_TO_STATEMENT,
      onClick: async () => {
        navigate(`/statements${search}`);
        trackBAActionEvent('bank_statements');
      },
      icon: StatementIcon,
    },
    {
      label: BUTTON.DOWNLOAD_REPAYMENT_SCHEDULE,
      onClick: handleRepaymentScheduleClick,
      icon: Download,
      loading: isDownloadingFile,
    },
  ];

  return buttonOptions;
};
