import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BillStatus, type LinkedTransaction } from '@generated-fg';

import { BillDropdownMenu, TitleWithStatus } from '@components';
import { type BillsHeaderProps, DropdownActions } from '@components/types';

import { BillStatusLevelMapping } from '@constants';
import { mapBillDisplayStatus } from '@utils';

import { cancelScheduledBillTransactionOnDetailPage } from './useCancelScheduledBillTransaction';
import { deleteBillOnDetailPageUpdater } from './useDeleteBillAction';

const dropdownMenuActionUpdaterMapping = {
  [DropdownActions.CANCEL_SCHEDULED_PAYMENT]: cancelScheduledBillTransactionOnDetailPage,
  [DropdownActions.DELETE_BILL]: deleteBillOnDetailPageUpdater,
};

export type TUseBillDetailsHeaderProps = {
  status?: BillStatus;
  id?: string;
  billerName?: string;
  recentTransaction?: LinkedTransaction;
};

export function useBillDetailsHeaderProps({
  status,
  id,
  billerName = '',
  recentTransaction,
}: TUseBillDetailsHeaderProps): BillsHeaderProps | undefined {
  const navigation = useNavigate();

  const headerProps = useMemo<BillsHeaderProps>(() => {
    if (!status) return undefined;
    const dropdownMenuActionOnSuccessMapping = {
      [DropdownActions.DELETE_BILL]: () => navigation('/bills'),
    };
    const needConfirm = BillStatus.Received === status;
    return {
      title: (
        <TitleWithStatus
          title={needConfirm ? 'Confirm bill details' : 'Bill details'}
          statusProps={
            needConfirm
              ? undefined
              : {
                  label: mapBillDisplayStatus(status),
                  level: BillStatusLevelMapping[status],
                }
          }
        />
      ),
      action: (
        <BillDropdownMenu
          status={status}
          billId={id}
          billerName={billerName}
          recentTransaction={recentTransaction}
          dropdownActionUpdaters={dropdownMenuActionUpdaterMapping}
          dropdownActionOnSuccess={dropdownMenuActionOnSuccessMapping}
        />
      ),
    };
  }, [billerName, id, navigation, recentTransaction, status]);
  return headerProps;
}
