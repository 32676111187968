import classNames from 'classnames';

import { Button, CopyText } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import styles from './styles.module.scss';
import { type NoBillsCardProps } from './types';

export const NoBillsCard = ({
  email,
  className,
  onCopyClick,
  title,
  linkButtonProps,
}: NoBillsCardProps) => {
  return (
    <div className={classNames(className, styles.NoBillsCardContainer)} data-testid="no-bills-card">
      <div className={styles.NoBillsCardContent}>
        <p className={styles.Title}>{title}</p>

        <p className={styles.Description}>
          To get started, forward an email with a bill attachment to your unique address:
        </p>
        <div className={styles.Email}>
          <span>{email}</span>
          <CopyText toCopy={email} onCopy={onCopyClick} className={styles.CopyText} />
        </div>
        <p className={styles.Support}>
          <Button
            size={ButtonSize.SMALL}
            type={ButtonType.ICON_LINK_UNDERLINE}
            label={linkButtonProps.label}
            onClick={linkButtonProps.onClick}
            className={styles.InlineButton}
          />
        </p>
      </div>
    </div>
  );
};
