import { type NavigateFunction, type RouteProps } from 'react-router-dom';

import { type DesktopHeaderButtonProps } from '@components/types';

import { DASHBOARD_ROUTES, HEADER_BUTTON_ROUTES } from '@constants';

type TGetDesktopHeaderButtonConfig = (
  navigate: NavigateFunction,
) => Record<RouteProps['path'], DesktopHeaderButtonProps>;

/**
 * Retrieves the configuration object for a desktop header button.
 *
 * @param navigate - The navigation function.
 * @returns The configuration object.
 */
export const getDesktopHeaderButtonConfig: TGetDesktopHeaderButtonConfig = navigate => {
  return {
    [HEADER_BUTTON_ROUTES.DATA_FEEDS]: {
      label: 'Back',
      onClick: () => navigate(DASHBOARD_ROUTES.SETTINGS),
    },
    [HEADER_BUTTON_ROUTES.BUSINESS_ACCOUNT_PRODUCT]: {
      label: 'Back',
      onClick: () => navigate(DASHBOARD_ROUTES.PRODUCTS),
    },
    [HEADER_BUTTON_ROUTES.LINE_OF_CREDIT_PRODUCT]: {
      label: 'Back',
      onClick: () => navigate(DASHBOARD_ROUTES.PRODUCTS),
    },
    [HEADER_BUTTON_ROUTES.SMALL_BUSINESS_LOAN_PRODUCT]: {
      label: 'Back',
      onClick: () => navigate(DASHBOARD_ROUTES.PRODUCTS),
    },
    [HEADER_BUTTON_ROUTES.BUSINESS_LOAN_PLUS_PRODUCT]: {
      label: 'Back',
      onClick: () => navigate(DASHBOARD_ROUTES.PRODUCTS),
    },
    [HEADER_BUTTON_ROUTES.REPAYMENT_CALCULATOR]: {
      label: 'Back',
      onClick: () => navigate(DASHBOARD_ROUTES.PRODUCTS),
    },
  };
};
