import { type ReactElement } from 'react';

import { BlockLoader } from '../BlockLoader';
import styles from './RepaymentProgressLoader.module.scss';

export const RepaymentProgressLoader = (): ReactElement => (
  <section data-testid="repayment-progress-loader">
    <div data-testid="title-loader" className={styles.TitleLoader}>
      <BlockLoader />
    </div>
    <div data-testid="balance-loader" className={styles.PercentageLoader}>
      <BlockLoader />
    </div>
    <div data-testid="progress-bar-loader" className={styles.ProgressBarLoader}>
      <BlockLoader />
    </div>
  </section>
);
