import { useState } from 'react';

import './Tooltip.scss';
import { type ToolTipProps } from './types';

export const Tooltip = ({
  position = 'right',
  text,
  className,
  children,
  hide = false,
}: ToolTipProps) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  return (
    <span className={`tooltip-info ${className}`} onMouseLeave={() => setDisplayTooltip(false)}>
      {displayTooltip && !hide && (
        <div className={`tooltip-info__bubble tooltip-info__${position}`}>
          <div className="tooltip-info__text pds-caption">{text}</div>
        </div>
      )}
      <span className="tooltip-info__trigger" onMouseOver={() => setDisplayTooltip(true)}>
        {children}
      </span>
    </span>
  );
};
