import type { PropsWithChildren } from 'react';

import classNames from 'classnames';

import { ButtonSize } from '../../constants';
import { Spinner } from '../Spinner';
// TODO: migrate to CSS modules
import './Button.scss';
import type { ButtonProps } from './types';
import { useSpinnerColor } from './useSpinnerColor';

export const Button = ({
  children,
  size = ButtonSize.MEDIUM,
  type,
  loading = false,
  label,
  className,
  htmlAttrs,
  testId,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const [ref, spinnerColor] = useSpinnerColor(type);

  return (
    <button
      ref={ref}
      className={classNames('ba-button', { loading }, size, type, className)}
      data-testid={testId}
      {...htmlAttrs}
      {...props}
    >
      {loading && (
        <Spinner className={classNames('button-spinner', { loading })} color={spinnerColor} />
      )}
      {children}
      {label && <span className={classNames({ loading })}>{label}</span>}
    </button>
  );
};
