import { ApolloClient, InMemoryCache, from } from '@apollo/client';

import { batchHttpLink, contextLink, errorLink, sentryLink } from './middlewares';

export const memoryCache = new InMemoryCache({
  typePolicies: {
    BusinessAccountTransactionEdge: {
      keyFields: ['cursor'],
    },
    BusinessAccountTransactionListItem: {
      keyFields: ['id', 'transactionTime'], // to prevent cache overwriting for transactions with the same id in staging
    },
    OverdraftDetail: {
      merge: true,
    },
    IntegrationsV2: {
      keyFields: ['type'],
    },
  },
});

export type ClientCacheType = typeof memoryCache;

export const createApolloClient = () =>
  new ApolloClient({
    link: from([errorLink, contextLink, sentryLink, batchHttpLink]),
    cache: memoryCache,
  });
