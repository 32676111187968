import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import flow from 'lodash/flow';

import { ClaritySentryIntegration } from '@all-in-one-web/clarity';

import { GraphQLDataSources } from '@constants';
import { getClarityId } from '@utils';

import environments from '../environments';

const IGNORE_ERRORS = [
  /^.*The operation is insecure..*$/,
  /^.*Failed to read the 'localStorage' property from 'Window'.*$/,
];

const decreaseLogLevel: Sentry.BrowserOptions['beforeSend'] = event => {
  // Decision: https://prospa.slack.com/archives/C03UTKJQ4QY/p1717630761239799
  if (
    event.extra?.datasource === GraphQLDataSources.FrontendGateway &&
    event.extra?.message === 'Invalid token'
  ) {
    event.level = 'info';
  }

  return event;
};

const beforeSendHandlers: Sentry.BrowserOptions['beforeSend'] = flow(
  cloneDeep,
  decreaseLogLevel,
  // Add your handler here
);

const isLoggerEnabled = Boolean(process.env.REACT_APP_SENTRY_RELEASE);

export const initLogger = () => {
  if (isLoggerEnabled) {
    initSentry();
  }
};

function initSentry() {
  return Sentry.init({
    dsn: environments.sentry.url,
    beforeSend: beforeSendHandlers,
    integrations: [
      Sentry.browserTracingIntegration({
        traceFetch: false, // https://github.com/DiederikvandenB/apollo-link-sentry?tab=readme-ov-file#exclude-redundant-fetch-breadcrumbs
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.breadcrumbsIntegration({
        fetch: false,
        xhr: false,
      }),
      ClaritySentryIntegration(getClarityId()),
    ],
    environment: environments.sentry.environment,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    tracesSampleRate: 0.1,
    normalizeDepth: 5,
    ignoreErrors: IGNORE_ERRORS,
  });
}

/**
 * It's recommended to use `useAppLogger` instead, unless you don't have access to AppContext
 */
export const captureMessage = Sentry.captureMessage;

/**
 * It's recommended to use `useAppLogger` instead, unless you don't have access to AppContext
 */
export const captureException = Sentry.captureException;
