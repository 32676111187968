import type { ReactElement } from 'react';

import classNames from 'classnames';

import './ProgressBar.scss';
import type { ProgressBarProps } from './types';

export const ProgressBar = ({
  percentage,
  className = '',
}: ProgressBarProps): ReactElement | null => {
  if (!percentage && percentage !== 0) {
    return null;
  }

  return (
    <div className={classNames('progress-bar', className)}>
      <div className="progress-bar__progressed" style={{ width: `${percentage}%` }} />
    </div>
  );
};
