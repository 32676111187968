import { useEffect } from 'react';

import { type GetBillDetailsQueryHookResult, useGetBillDetailsQuery } from '@generated-fg';

import { trackBAActionEvent } from '@utils';

export type TUseBillDataProps = {
  id: string;
};

export type UseBillsDataReturnType = {
  bill: GetBillDetailsQueryHookResult['data']['user']['billPay']['bill'];
  loading: GetBillDetailsQueryHookResult['loading'];
  error?: GetBillDetailsQueryHookResult['error'];
};

export const useBillDetailsData = ({ id }: TUseBillDataProps): UseBillsDataReturnType => {
  const { data, loading, error } = useGetBillDetailsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  useEffect(() => {
    if (error) trackBAActionEvent('bill_error', error.message);
  }, [error]);

  return {
    bill: data?.user?.billPay?.bill,
    loading,
    error,
  };
};
