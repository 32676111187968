import { SmbHouse as HouseIcon } from '@prospa/icons';

import { Button, Card, Modal } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { formatAddress } from '@utils';

import './AddressCheckModal.scss';
import type { AddressCheckModalProps } from './types';

export const AddressCheckModal = ({
  isOpen,
  toggle,
  handleSubmit,
  address,
  handleUpdateAddress,
}: AddressCheckModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      showCloseModal
      size="medium"
      primaryCTALabel="Next"
      primaryCTAOnClick={handleSubmit}
      body={
        <div className="address-check-modal">
          <HouseIcon className="address-check-modal__icon" />
          <h4>Check your address</h4>
          <Card showGreyBackground>
            <p className="pds-text">We'll send a new card to:</p>
            <p className="pds-lead">{formatAddress(address)}</p>
            <Button
              label="Update address?"
              onClick={handleUpdateAddress}
              type={ButtonType.ICON_LINK_UNDERLINE}
              size={ButtonSize.MEDIUM}
            />
          </Card>
          <p className="pds-text">
            Your new card may take around 5-7 business days to be delivered.
          </p>
        </div>
      }
    />
  );
};
