import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { NZ_MOBILE_APP_CAMPAIGN_COPY, NZ_MOBILE_LINKS } from '@constants';

import ProspaQRCode from '../../../../../assets/nz-mobile-app-QR-code.png';
import MobileAppPromo from '../../../../../assets/nz-mobile-app-promo.png';
import AppStoreDownload from '../../../../../assets/symbols/AppStore.svg';
import GoogleStoreDownload from '../../../../../assets/symbols/GooglePlayStore.svg';
import type { CampaignBody } from '../../types';
import styles from './NzMobileCampaign.module.scss';
import NzMobileCampaignAnimation from './NzMobileCampaignAnimation';

const MobileAppQrCode = () => (
  <div className={styles.NzMobileCampaign__QR_Code}>
    <img className="qrcode" src={ProspaQRCode} alt="Prospa App QR Code" />
    <span>{NZ_MOBILE_APP_CAMPAIGN_COPY.SCAN_QR_CODE}</span>
  </div>
);

const AppStoreLogoLink = () => (
  <div className={styles.NzMobileCampaign__App_Store__Logo}>
    <a
      className={styles.NzMobileCampaign__App_Store__Logo__Link}
      href={NZ_MOBILE_LINKS.IOS_APP_STORE_URL}
    >
      <img
        src={AppStoreDownload}
        alt={NZ_MOBILE_APP_CAMPAIGN_COPY.IOS_APP_STORE_ALT_TEXT}
        className="download"
        role="presentation"
      />
    </a>

    <a
      className={styles.NzMobileCampaign__App_Store__Logo__Link}
      href={NZ_MOBILE_LINKS.ANDROID_GOOGLE_PLAY_STORE_URL}
    >
      <img
        src={GoogleStoreDownload}
        alt={NZ_MOBILE_APP_CAMPAIGN_COPY.ANDROID_APP_STORE_ALT_TEXT}
        className="download"
        role="presentation"
      />
    </a>
  </div>
);

export const NzMobileCampaign: CampaignBody = () => {
  const imgRef = useRef<HTMLImageElement>();
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    imgRef.current?.addEventListener('load', () => {
      setPlayAnimation(true);
    });
  }, []);

  return (
    <div className={styles.NzMobileCampaign__Container}>
      <NzMobileCampaignAnimation play={playAnimation} />
      <div
        className={classNames(
          styles.NzMobileCampaign__Content,
          styles.NzMobileCampaign__Content__Image,
        )}
      >
        <img src={`${MobileAppPromo}`} alt="Prospa App" ref={imgRef} />
      </div>
      <div
        className={classNames(
          styles.NzMobileCampaign__Content,
          styles.NzMobileCampaign__Content__Copy,
        )}
      >
        <div className={styles.NzMobileCampaign__Content__Copy_Logo}>
          <img src="https://static.prospa.com/pds/logo/v2/Logo-black.svg" alt="Prospa logo" />
        </div>
        <div>
          <h2 className={styles.NzMobileCampaign__Content__Copy_Heading}>
            {NZ_MOBILE_APP_CAMPAIGN_COPY.HEADING}
          </h2>
          <p className={styles.NzMobileCampaign__Content__Copy_Body}>
            {NZ_MOBILE_APP_CAMPAIGN_COPY.BODY}
          </p>
        </div>
        <div className={styles.NzMobileCampaign__Content_Logo}>
          <MobileAppQrCode />
          <AppStoreLogoLink />
        </div>
      </div>
    </div>
  );
};
