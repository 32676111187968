export const toFixedPointNumber = (value: number): number | null => {
  const fixedPointNumber = Number(value?.toFixed(2));

  if (isNaN(fixedPointNumber)) {
    return null;
  }
  if (fixedPointNumber === 0) {
    return 0;
  }
  return fixedPointNumber;
};
