import { v4 as uuidv4 } from 'uuid';

import { BlockLoader } from '@components';

import './TransactionsLoader.scss';

const transactions = new Array(5).fill('').map(() => uuidv4());

export const TransactionsLoader = () => (
  <section data-testid="transactions-loader" className="transactions-loader">
    {transactions.map((key, index) => (
      <div className="item" key={key}>
        {!(index % 3) && (
          <div className="title">
            <BlockLoader />
          </div>
        )}
        <div className="top-line">
          <div className="top-line__date">
            <BlockLoader />
          </div>
          <div className="top-line__desc">
            <BlockLoader />
          </div>
          <div className="top-line__amount">
            <BlockLoader />
          </div>
        </div>
        <div className="bottom-line">
          <div className="bottom-line__date">
            <BlockLoader />
          </div>
          <div className="bottom-line__desc">
            <BlockLoader />
          </div>
          <div className="bottom-line__amount">
            <BlockLoader />
          </div>
        </div>
      </div>
    ))}
  </section>
);
