import { format } from 'date-fns';
import indefinite from 'indefinite';

import { currencyFormatter } from '@utils';

type InputFieldValidator<ValueType, ValidationRule> = (
  value: ValueType,
  fieldLabel: string,
  validationRule: ValidationRule,
) => string;

export interface InputFieldValidationRule {
  isRequired: boolean;
}

interface TextFieldValidationRule extends InputFieldValidationRule {
  minLength?: number;
  maxLength?: number;
  regExpValidator?: {
    pattern: RegExp;
    errorMessage: string;
  };
}

interface NumberFieldValidationRule extends InputFieldValidationRule {
  minValue?: number;
  maxValue?: number;
  displayAsCurrency?: boolean;
}

interface DateFieldValidationRule extends InputFieldValidationRule {
  startAt?: Date;
  endAt?: Date;
  formatter?: (date: Date) => string;
}

export const textFieldValidator: InputFieldValidator<string, TextFieldValidationRule> = (
  value: string,
  fieldLabel: string,
  { isRequired, minLength, maxLength, regExpValidator: { pattern, errorMessage } = {} },
) => {
  if (isRequired && (!value || value.length === 0)) {
    return `Enter ${indefinite(fieldLabel.toLowerCase())}`;
  }

  if (!!maxLength && value.length > maxLength) {
    return `${fieldLabel} has a max of ${maxLength} characters`;
  }

  if (!!minLength && value.length < minLength) {
    return `${fieldLabel} has a min of ${minLength} characters`;
  }

  if (!!pattern && !pattern.test(value)) {
    return errorMessage;
  }

  return '';
};

export const numberFieldValidator: InputFieldValidator<number, NumberFieldValidationRule> = (
  value: number,
  fieldLabel: string,
  { isRequired, minValue, maxValue, displayAsCurrency: isCurrency },
) => {
  const displayMinValue = isCurrency ? currencyFormatter(minValue) : minValue;
  const displayMaxValue = isCurrency ? currencyFormatter(maxValue) : maxValue;

  if (isRequired && !value) {
    return `Enter ${indefinite(fieldLabel.toLowerCase())}`;
  }

  if (!!maxValue && !!minValue && (value > maxValue || value < minValue)) {
    return `${fieldLabel} must be between ${displayMinValue} and ${displayMaxValue}`;
  }

  if (!!maxValue && value > maxValue) {
    return `${fieldLabel} must be at most ${displayMaxValue}`;
  }

  if (!!minValue && value < minValue) {
    return `${fieldLabel} must be at least ${displayMinValue}`;
  }

  return '';
};

export const dateValidator: InputFieldValidator<Date, DateFieldValidationRule> = (
  value: Date,
  fieldLabel: string,
  { isRequired, startAt, endAt, formatter = date => format(new Date(date), 'dd MMM yyyy') },
): string => {
  if (isRequired && !value) {
    return `Enter ${indefinite(fieldLabel.toLowerCase())}`;
  }

  if (!!endAt && !!startAt && (value > endAt || value < startAt)) {
    return `${fieldLabel} must be between ${formatter(startAt)} and ${formatter(endAt)}`;
  }

  if (!!endAt && value > endAt) {
    return `${fieldLabel} must be earlier than ${formatter(endAt)}`;
  }

  if (!!startAt && value < startAt) {
    return `${fieldLabel} must be later than ${formatter(startAt)}`;
  }

  return '';
};
