import { useEffect, useMemo } from 'react';

import { IntegrationProductErrorStatus, IntegrationStatus } from '@generated-fg';

import { useIntegrationsDataV2, useStateInLocalStorage } from '@hooks';
import { preventClickEventPopup, trackBAActionEvent } from '@utils';

import { type IntegrationErrors } from './types';

export function useIntegrationsError(): IntegrationErrors {
  const { integrationsV2, loading: isIntegrationLoading } = useIntegrationsDataV2();

  const {
    integrationErrors: {
      syncErrorsDismissed = [],
      setSyncErrorsDismissed,
      authErrorDismissed,
      setAuthErrorDismissed,
    },
  } = useStateInLocalStorage();

  const authError = useMemo(
    () => ({
      show:
        !isIntegrationLoading &&
        !authErrorDismissed &&
        integrationsV2?.status === IntegrationStatus.AuthError,
      remove: e => {
        preventClickEventPopup(() => setAuthErrorDismissed?.(true))(e);
      },
      integrationStatus: IntegrationStatus.AuthError,
    }),
    [authErrorDismissed, setAuthErrorDismissed, integrationsV2?.status, isIntegrationLoading],
  );

  const syncErrors = useMemo(() => {
    const syncErrors: IntegrationErrors['syncErrors'] = {};
    if (!isIntegrationLoading && integrationsV2?.status === IntegrationStatus.SyncError) {
      integrationsV2?.products.forEach(({ productId, productType, errorStatus }) => {
        if (errorStatus === IntegrationProductErrorStatus.SyncError) {
          syncErrors[productId] = {
            productType,
            productId,
            show: !syncErrorsDismissed.includes(productId),
            remove: e => {
              preventClickEventPopup(() => {
                trackBAActionEvent('xero_connection-error_banner__click');
                setSyncErrorsDismissed?.([...syncErrorsDismissed, productId]);
              })(e);
            },
            integrationStatus: IntegrationStatus.SyncError,
          };
        }
      });
    }
    return syncErrors;
  }, [
    integrationsV2?.products,
    integrationsV2?.status,
    syncErrorsDismissed,
    setSyncErrorsDismissed,
    isIntegrationLoading,
  ]);

  useEffect(() => {
    if (
      !isIntegrationLoading &&
      integrationsV2?.status !== IntegrationStatus.AuthError &&
      authErrorDismissed
    ) {
      setAuthErrorDismissed(false);
    }
    if (
      !isIntegrationLoading &&
      integrationsV2?.status !== IntegrationStatus.SyncError &&
      syncErrorsDismissed?.length > 0
    ) {
      setSyncErrorsDismissed([]);
    }
  }, [
    authErrorDismissed,
    isIntegrationLoading,
    integrationsV2?.status,
    setAuthErrorDismissed,
    setSyncErrorsDismissed,
    syncErrorsDismissed,
  ]);

  return {
    isIntegrationErrorLoading: isIntegrationLoading,
    authError,
    syncErrors,
  };
}
