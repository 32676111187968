import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { Alert } from '@all-in-one-web/common-ui';
import { Sparkle } from '@prospa/salt-icons/react';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { trackBAActionEvent } from '@utils';

import styles from './ProductPageAlert.module.scss';
import type { TProductPageAlert } from './types';

export const ProductPageAlert = ({ className, messageText, buttonText }: TProductPageAlert) => {
  const navigate = useNavigate();

  const navigateToProducts = () => {
    trackBAActionEvent('home_product_banner_click');
    navigate('/products');
  };

  return (
    <Alert
      icon={<Sparkle size={24} />}
      alertType="Info"
      className={classNames(styles.ProductPageAlert, className)}
    >
      <div className={styles.ProductPageAlertFullBody} data-testid="product-page-alert">
        <span className={styles.ProductPageAlertDescription}>
          {messageText ? messageText : 'You’ve got a plan, let’s make it happen.'}
        </span>
        <Button
          size={ButtonSize.SMALL}
          type={ButtonType.ICON_LINK_UNDERLINE}
          onClick={navigateToProducts}
          className={styles.ProductPageAlertCTA}
        >
          {buttonText ? buttonText : 'Explore our products'}
        </Button>
      </div>
    </Alert>
  );
};
