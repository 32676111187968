import { useEffect, useRef } from 'react';
import ReactSelect, { type OptionProps, type SingleValueProps, components } from 'react-select';

import classNames from 'classnames';

import tokens from '@prospa/tokens';

import { StatementFileType } from '@generated-fg';

import { Button, Spinner } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import animationData from '../../../assets/animations/downloadAnimation.json';
import { getLottie } from '../LottieAnimation';
import './DownloadButtons.scss';
import type { DownloadButtonsProps, options } from './types';

export const { color, grey, brand } = tokens.pds;

export const DownloadButtons = ({ options, onClick }: DownloadButtonsProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const animRef = useRef(null);

  let timer = null;

  const SingleValue = ({ ...props }: SingleValueProps<options>) => (
    <components.SingleValue {...props}>Download</components.SingleValue>
  );

  const Option = ({ data, ...props }: OptionProps<options, false>) => {
    return (
      <div className="option__label" onClick={() => onClick(data.value)}>
        <components.Option {...props} data={data} className="option">
          <p>{data.label}</p>
          {data.loading && <Spinner className="option__spinner" />}
        </components.Option>
      </div>
    );
  };

  const onHover = () => {
    animRef?.current?.play();
    timer = setTimeout(() => animRef?.current?.stop(), 2000);
  };

  useEffect(() => {
    animRef.current = getLottie({ containerRef, loop: true, animationData, autoplay: false });
    return () => {
      clearTimeout(timer);
      animRef?.current?.destroy();
    };
  }, [timer, options.length]);

  if (options.length > 1) {
    return (
      <ReactSelect
        placeholder="Download"
        isSearchable={false}
        components={{ Option, SingleValue }}
        options={options}
        styles={customStyle}
        closeMenuOnSelect={false}
      />
    );
  }

  return (
    <Button
      className="download-buttons"
      type={ButtonType.ICON_LINK}
      onClick={() => onClick(StatementFileType.Pdf)}
      size={ButtonSize.MEDIUM}
      loading={options[0].loading}
    >
      <p className={classNames('download-buttons__label', 'pds-text')}>{options[0].label}</p>
      <div className="download-buttons__icon" onMouseEnter={onHover} ref={containerRef} />
    </Button>
  );
};

const customStyle = {
  control: () => ({
    display: 'flex',
    color: grey.fg.base,
    backgroundColor: color.white,
    width: '120px',
    cursor: 'pointer',
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0px',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    boxShadow: `inset 0 -1px 0 ${color.neutral[100]}`,
    height: 44,
    backgroundColor: isFocused ? color.neutral[100] : color.white,
    color: color.neutral[800],
    cursor: 'pointer',
    display: 'flex',
  }),
  menuList: styles => ({ ...styles, padding: 0 }),
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles, { selectProps }) => ({
    ...styles,
    transition: 'all .2s ease',
    transform: selectProps.menuIsOpen && 'rotate(180deg)',
    color: brand.secondary,
    padding: '0 8px',
  }),
  placeholder: styles => ({
    ...styles,
    color: color.neutral[800],
    textAlign: 'right',
    margin: '0px',
  }),
  singleValue: styles => ({
    ...styles,
    textAlign: 'right',
    margin: '0px',
  }),
  menu: styles => ({
    ...styles,
    width: '100px',
    marginLeft: '10px',
  }),
};
