import { useContext } from 'react';

import { Country, LineOfCreditContractStatus, LineOfCreditStatus } from '@generated-fg';

import { ArrearsAlert, IntegrationErrorAlert, SuspendedAccountAlert } from '@components';
import type { SuspendedAccountAlertProps } from '@components/types';

import { PRODUCT_TYPES, lineOfCreditSuspendedMessage } from '@constants';

import { AppContext } from '../../../contexts';
import { ProductTypes } from '../../../models/Account';
import { AccountLoader } from '../../BlockLoader';
import styles from '../AccountList.module.scss';
import AccountListItem from '../AccountListItem';
import type { CreditLineDetail, ProductListProps } from '../types';

const handlePhoneNumberClick: SuspendedAccountAlertProps['phoneNumberProps']['onClick'] = event => {
  event.stopPropagation();
};

export function LineOfCreditList({
  list = [],
  loading = true,
  countryCode,
}: ProductListProps<CreditLineDetail>): JSX.Element {
  const {
    featureFlags: { locProductPage },
  } = useContext(AppContext);
  return loading ? (
    <AccountLoader className={`${styles.AccountListItem} ${styles.Skeleton}`} />
  ) : (
    <>
      {list.map(({ accountDetail: loc, integrationSyncError, onClick }) => {
        const balance =
          loc.status === LineOfCreditStatus.PaymentPlan ||
          loc.status === LineOfCreditStatus.WrittenOff
            ? loc.outstanding
            : -(loc.total - loc.available - (loc.pending || 0));

        const isExternalLink =
          !locProductPage ||
          loc.contractStatus === LineOfCreditContractStatus.PendingFirstSignature;

        return (
          <AccountListItem
            id={loc.id}
            productType={loc.productType}
            key={loc.id}
            title={loc.productType === ProductTypes.LOC ? PRODUCT_TYPES.LOC : PRODUCT_TYPES.LOCM}
            subTitle={null}
            balance={balance}
            available={loc.available}
            pending={Number(loc.pending || 0)}
            onClick={onClick}
            status={loc.status}
            accountStatus={loc.accountStatus}
            isExternalLink={isExternalLink}
          >
            {loc.status === LineOfCreditStatus.LineSuspended && (
              <SuspendedAccountAlert
                className={styles.SuspendedAlert}
                alertMessage={lineOfCreditSuspendedMessage}
                phoneNumberProps={{
                  onClick: handlePhoneNumberClick,
                }}
              />
            )}
            <ArrearsAlert
              showAlert={loc.isInArrears}
              inArrearsAmount={loc.inArrearsAmount}
              productType={loc.productType}
              productId={loc.id}
              countryCode={countryCode === 'AU' ? Country.Au : Country.Nz}
            />
            {integrationSyncError?.show && (
              <IntegrationErrorAlert
                integrationStatus={integrationSyncError.integrationStatus}
                onClose={integrationSyncError.remove}
                className={styles.AccountListItemConnectionErrorAlert}
              />
            )}
          </AccountListItem>
        );
      })}
    </>
  );
}
