const getPeriodOfDay = (hour: number): 'morning' | 'afternoon' | 'evening' => {
  // hour range is 0-23
  if (hour >= 0 && hour <= 11) {
    return 'morning';
  }
  if (hour >= 12 && hour <= 17) {
    return 'afternoon';
  }
  if (hour >= 18 && hour <= 23) {
    return 'evening';
  }
};

export const displayGreeting = (firstName = '', hour: number) => {
  const time = `Good ${getPeriodOfDay(hour)}`;
  return firstName?.length ? [time, firstName].join(', ') : time;
};
