import { type FC } from 'react';

import classNames from 'classnames';

import styles from './TableCell.module.scss';
import { type TableHeaderCellProps } from './types';

export const TableHeaderCell: FC<TableHeaderCellProps> = ({ className, children, ...props }) => {
  return (
    <th className={classNames(className, styles.TableHeaderCell)} {...props}>
      {children}
    </th>
  );
};
