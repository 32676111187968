import { type FormikErrors } from 'formik';

import { PayAnyoneFormErrorStrings, PayAnyoneTabs } from '@constants';
import { type PayAnyoneFormValues, ProductTypes } from '@models';
import { currencyFormatter, validationConfig } from '@utils';

import { type Validator } from '../types';

export const amount: Validator = (
  { amount },
  { payFromAccount, paymentFrequencyType, options },
) => {
  const { minAmount, dailyLimit } = options || {};
  const errors: FormikErrors<PayAnyoneFormValues> = {};
  const isBASchedulePayment =
    (paymentFrequencyType === PayAnyoneTabs.RECURRING ||
      paymentFrequencyType === PayAnyoneTabs.PAY_LATER) &&
    payFromAccount.productType === ProductTypes.BA;

  const parsedAmount = parseFloat(amount?.replace(/,/g, ''));

  if (!isBASchedulePayment && parsedAmount > payFromAccount?.balance?.available) {
    errors.amount = `${PayAnyoneFormErrorStrings.BankAccountBalanceError} ${currencyFormatter(
      payFromAccount?.balance?.available,
    )}`;
  }
  // cannot type in value greater than limit set
  else if (!!dailyLimit && parsedAmount > dailyLimit) {
    errors.amount = `${PayAnyoneFormErrorStrings.DailyLimitError} ${currencyFormatter(dailyLimit)}`;
  }
  // after a "PayAnyone" operation, warn user of remaining daily transfer limit
  else if (!!dailyLimit && parsedAmount > payFromAccount?.balance?.paymentAvailable) {
    errors.amount = `${PayAnyoneFormErrorStrings.RemainingDailyLimitError} ${currencyFormatter(
      payFromAccount?.balance?.paymentAvailable,
    )}`;
  } else if (parsedAmount < minAmount) {
    errors.amount = `${PayAnyoneFormErrorStrings.MinimumAmountError} ${currencyFormatter(
      minAmount,
    )}`;
  } else if (!amount || !validationConfig.currencyInput.pattern.test(amount)) {
    errors.amount = PayAnyoneFormErrorStrings.AmountEmptyError;
  }
  return errors;
};

export const description: Validator = ({ description }, { options }) => {
  const { maxDescriptionLength } = options || {};
  const errors: FormikErrors<PayAnyoneFormValues> = {};

  if (!description) {
    errors.description = PayAnyoneFormErrorStrings.DescriptionEmptyError;
  } else if (description?.length > maxDescriptionLength) {
    errors.description = PayAnyoneFormErrorStrings.DescriptionMaxLengthError(maxDescriptionLength);
  } else if (!validationConfig.paymentDescription.pattern.test(description)) {
    errors.description = PayAnyoneFormErrorStrings.DescriptionInvalidError;
  }
  return errors;
};

export const reference: Validator = ({ reference }, { options }) => {
  const { referenceRequired, maxReferenceLength } = options || {};
  const errors: FormikErrors<PayAnyoneFormValues> = {};

  if (referenceRequired && !reference) {
    errors.reference = PayAnyoneFormErrorStrings.ReferenceEmptyError;
  } else if (reference?.length > maxReferenceLength) {
    errors.reference = PayAnyoneFormErrorStrings.ReferenceMaxLengthError(maxReferenceLength);
  } else if (!validationConfig.paymentReference.pattern.test(reference)) {
    errors.reference = PayAnyoneFormErrorStrings.ReferenceInvalidError;
  }
  return errors;
};
