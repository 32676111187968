import classNames from 'classnames';

import styles from './Email.module.scss';
import { type EmailProps } from './types';

function Email({ emailAddress, className, ...rest }: EmailProps) {
  return (
    <a className={classNames(styles.Email, className)} href={`mailto:${emailAddress}`} {...rest}>
      {emailAddress}
    </a>
  );
}

export default Email;
