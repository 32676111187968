import classNames from 'classnames';

import { Card, Table, TableBody, TableHead } from '@all-in-one-web/common-ui';
import { Spinner } from '@prospa/salt-react-beta';

import styles from './BillsTable.module.scss';
import { type BillsTableProps } from './types';

export const BillsTable = ({
  loading,
  bills,
  columns,
  emptyState,
  renderHeaderRow,
  renderTableRow,
  className,
}: BillsTableProps) => {
  return (
    <Card className={classNames(styles.Card, className)}>
      {loading ? (
        <div className={classNames(styles.Spinner, styles.CardPadding)}>
          <Spinner size={32} />
        </div>
      ) : emptyState ? (
        <p className={classNames(styles.EmptyList, styles.CardPadding)}>{emptyState}</p>
      ) : (
        <Table
          responsive={false}
          hasShadow
          className={styles.Table}
          wrapperClassName={styles.CardPadding}
        >
          <TableHead>{renderHeaderRow(columns)}</TableHead>
          <TableBody>
            {bills.map(bill => {
              return renderTableRow(bill);
            })}
          </TableBody>
        </Table>
      )}
    </Card>
  );
};

export const BillsTableStyles = styles;
