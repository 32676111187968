import UfoSVG from '@prospa/icons/dist/svg/MicroilloNotfound.svg';

import { Button, ErrorPageContainer, NeedHelpContact } from '@components';

import { ButtonSize, ButtonType } from '@constants';

const PageError = () => {
  return (
    <ErrorPageContainer
      className="page-error"
      animationData={UfoSVG}
      assetType="svg"
      title="Uh oh!"
      footer={<NeedHelpContact />}
    >
      <p>Something strange happened here. We are working on a rescue mission.</p>
      <Button
        type={ButtonType.PRIMARY}
        size={ButtonSize.LARGE}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Return home
      </Button>
    </ErrorPageContainer>
  );
};

export default PageError;
