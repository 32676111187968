import { type FC, useCallback } from 'react';

import classNames from 'classnames';

import { BillPay as BillPayIcon } from '@prospa/salt-illustrations';

import { Button, CopyText, Modal } from '@components';

import {
  BILL_PAY_HELP_CENTRE_URL,
  ButtonSize,
  ButtonType,
  TERMS_AND_CONDITIONS_URL,
} from '@constants';
import { useToastContext } from '@hooks';
import { externalRedirect } from '@utils';

import styles from './styles.module.scss';
import type { EmailBillModalProps } from './types';

export const EmailBillModal: FC<EmailBillModalProps> = ({
  email,
  primaryButtonProps = {},
  headerClassName,
  footerClassName,
  ...props
}) => {
  const { addToast } = useToastContext();

  const { onClick, ...buttonProps } = primaryButtonProps;

  const showToast = useCallback(() => {
    addToast('Copied email address');
  }, [addToast]);

  const handlePrimaryBtnClick: EmailBillModalProps['primaryButtonProps']['onClick'] = useCallback(
    async event => {
      try {
        if (onClick) onClick(event);
        await navigator.clipboard.writeText(email);
        showToast();
      } catch (err) {
        //
      }
    },
    [email, onClick, showToast],
  );

  return (
    <Modal
      size="medium"
      showCloseModal
      hideFooterBorder
      heading={
        <div>
          <BillPayIcon className={styles.Icon} size={80} />
          <h4>Email a bill to Prospa</h4>
        </div>
      }
      headerClassName={classNames(styles.Header, headerClassName)}
      body={
        <div data-testid="email-bill-modal">
          <p>Forward your bill to this email address:</p>

          <div className={styles.Email}>
            <span>{email}</span>
            <CopyText toCopy={email} onCopy={showToast} className={styles.CopyText} />
          </div>

          <p className={styles.Description}>
            Your bill should appear in minutes with some details pre-filled, you’ll just need to
            confirm these before you can schedule payment.
          </p>

          <p className={styles.Support}>
            To learn more, visit our{' '}
            <Button
              size={ButtonSize.SMALL}
              type={ButtonType.ICON_LINK_UNDERLINE}
              label="Help Centre"
              onClick={() =>
                externalRedirect({
                  url: BILL_PAY_HELP_CENTRE_URL,
                  openInNewTab: true,
                })
              }
              className={styles.InlineButton}
            />{' '}
            and see our{' '}
            <Button
              size={ButtonSize.SMALL}
              type={ButtonType.ICON_LINK_UNDERLINE}
              label="terms"
              onClick={() =>
                externalRedirect({
                  url: TERMS_AND_CONDITIONS_URL,
                  openInNewTab: true,
                })
              }
              className={styles.InlineButton}
            />
            .
          </p>
        </div>
      }
      footerClassName={classNames(styles.Footer, footerClassName)}
      primaryButtonProps={{
        size: ButtonSize.LARGE,
        label: 'Copy email address',
        onClick: handlePrimaryBtnClick,
        ...buttonProps,
      }}
      {...props}
    />
  );
};
