import { type ReactNode } from 'react';

import { type FormikState } from 'formik';

import {
  type BillDetails,
  type BillStatus,
  type Country,
  type LinkedTransaction,
  type UpdateBillInput,
} from '@generated-fg';

import { type InputFieldValidationRule } from '@utils';

export type { PaymentMethodSectionProps } from './components/PaymentMethodSection/types';

export interface TUseConfirmBillFormProps {
  bill?: BillDetails;
  recentTransaction?: LinkedTransaction;
}

export type TConfirmBillFormSubmitHandler = (
  values: ConfirmBillFormInput,
  action: BillFormAction,
  resetForm: TResetFormAction<ConfirmBillFormInput>,
) => void;

export type TResetFormAction<Values> = (nextState?: Partial<FormikState<Values>>) => void;

export enum BillFormAction {
  CONFIRM_NOW,
  CONFIRM_LATER,
  CANCEL_SCHEDULED,
  PAY_NOW,
}

export type ConfirmBillFormValidationRule = Record<
  keyof Omit<ConfirmBillFormInput, 'paymentMethod' | 'billerABN'>,
  InputFieldValidationRule
>;

export type TConfirmBillFormProps = {
  billId: string;
  countryCode: Country;
  status: BillStatus;
  isSubmitting: boolean;
  isNonEditable: boolean;
  initialFormValues: ConfirmBillFormInput;
  handleSubmit: TConfirmBillFormSubmitHandler;
  popupNotification?: ReactNode;
  recentTransaction?: LinkedTransaction;
  validationRules?: ConfirmBillFormValidationRule;
};

export type ConfirmBillFormInput = Omit<UpdateBillInput, 'dueDate' | 'amount'> & {
  amount: string;
  dueDate: Date;
  reference: string;
};
