import { Info as WarningIcon } from '@prospa/icons';

import { PaymentType, useDeletePayeeV2Mutation } from '@generated-fg';

import { Modal } from '@components';

import { useToastContext } from '@hooks';
import { trackBAActionEvent } from '@utils';

import type { PayeeV2 } from '../../../models/PayAnyone';
import './DeletePayeeModal.scss';
import type { DeletePayeeModalProps } from './types';

const renderAccountDetails = (payee: PayeeV2) => {
  const { accountName } = payee;
  return `Delete ${accountName}?`;
};
export const DeletePayeeModal = ({
  payee,
  onClose,
  primaryOnClick,
  onError,
}: DeletePayeeModalProps) => {
  const { addToast } = useToastContext();

  const [deletePayee, { loading }] = useDeletePayeeV2Mutation({
    onCompleted() {
      primaryOnClick();
      addToast(`${payee.accountName} has been deleted`);
    },
    onError,
  });

  const getSubheaderContactType = (): string =>
    payee?.paymentType === PaymentType.Bpay ? 'biller' : 'contact';

  return (
    <Modal
      size="large"
      isOpen={!!payee}
      onClose={onClose}
      showCloseModal
      body={
        <div className="delete-payee-modal">
          <WarningIcon className="delete-payee-modal__icon" />
          <h4 className="delete-payee-modal__accountdetails">
            {payee && renderAccountDetails(payee)}
          </h4>
          <p className="pds-text">{`We\u2019ll delete this ${getSubheaderContactType()} from your address book.`}</p>
        </div>
      }
      isLoading={loading}
      primaryCTALabel="OK"
      primaryCTAOnClick={() => {
        const { id } = payee;
        trackBAActionEvent(
          'pay-anyone_contacts-delete',
          `payee_type:${payee.paymentType === PaymentType.Bpay ? 'bpay' : 'bank account'}`,
        );
        deletePayee({
          variables: {
            input: {
              payeeId: id,
            },
          },
        });
      }}
    />
  );
};
