import { Modal } from '@components';

import { MOBILE_LINKS, MobileOSTypes } from '@constants';
import { externalRedirect, getMobileOperatingSystem } from '@utils';

import styles from './MobileAppModal.module.scss';
import { MobileAppModalBody } from './MobileAppModalBody';
import type { TMobileAppModal } from './types';

export const MobileAppModal = ({
  isOpen,
  onClose,
  countryCode,
  rating = 0,
  numStars = 5,
}: TMobileAppModal) => {
  const operatingSystem = getMobileOperatingSystem();
  const handleMobileAppModalClick = () => {
    return operatingSystem === MobileOSTypes.IOS
      ? externalRedirect({ url: MOBILE_LINKS.IOS_APP_STORE_URL })
      : externalRedirect({ url: MOBILE_LINKS.ANDROID_GOOGLE_PLAY_STORE_URL });
  };
  const appRating = countryCode === 'AU' ? rating : 0;

  return (
    <Modal
      primaryCTALabel="Download app"
      primaryCTAOnClick={handleMobileAppModalClick}
      body={<MobileAppModalBody rating={appRating} numStars={numStars} />}
      size="medium"
      showCloseModal
      onClose={onClose}
      isOpen={isOpen}
      className={styles.MobileAppModal}
      bodyClassName={styles.ModalBody}
      footerClassName={styles.ModalFooter}
    />
  );
};
