import { DrawdownStatus } from '@generated-fg';

import { Status } from '@components';
import type { AccountSelectorProps } from '@components/types';

import { type AccountItem, ProductTypes } from '@models';
import { formatToTitleCase } from '@utils';

import './AccountSelector.scss';
import type { AccountSelectorOptionProps } from './AccountSelectorOption/types';

// Renders if no functional components are passed through to <AccountSelector />
export const RenderSelectedDefault: AccountSelectorProps['renderSelectedAccount'] = () => {
  return <p>Default selected option</p>;
};

export const RenderOptionDefault: AccountSelectorOptionProps['renderSelectorOption'] = () => {
  return <p>Default selector option</p>;
};

export const RenderAccountItemSelected: AccountSelectorProps['renderSelectedAccount'] = ({
  selectedAccount,
}: {
  selectedAccount?: AccountItem;
}) => {
  return (
    <>
      <div className="account-selector__col" data-testid="account-item-selected">
        <p className="pds-caption account-selector__select">From</p>
        <p className="account-selector__selected-info">
          <span className="pds-text-semibold account-selector__selected-info-name">
            {selectedAccount.name}
          </span>
          <span className="pds-summary-text account-selector__selected-info-details">
            {selectedAccount.displayInfo.details}
          </span>
        </p>
      </div>
      <div className="account-selector__col">
        <p className="pds-caption account-selector__select">Available</p>
        <p className="pds-summary-text-semibold">{selectedAccount.displayInfo.total}</p>
      </div>
    </>
  );
};

export const RenderAccountItemOption: AccountSelectorOptionProps['renderSelectorOption'] = ({
  accountItem,
}: {
  accountItem: AccountItem;
}) => {
  return (
    <div className="account-selector-option__selected-option">
      <div className="account-selector-option__col">
        <div className="account-selector-option__status-badge">
          <p className="pds-text-semibold">{accountItem.name}</p>
          {accountItem.status &&
            accountItem.productType !== ProductTypes.BA &&
            accountItem.status !== DrawdownStatus.Active && (
              <Status
                label={formatToTitleCase(accountItem.status)}
                level={accountItem.status !== DrawdownStatus.Suspended ? 'primary' : 'warning'}
                data-testid="account-item-option-status"
              />
            )}
        </div>
        <p className="account-selector-option__text">{accountItem.displayInfo.details}</p>
      </div>
      <div className="account-selector-option__col col-details">
        <p className="pds-caption account-selector-option__available">Available</p>
        <p className="account-selector-option__total account-selector-option__total-right-pad">
          {accountItem.displayInfo.total}
        </p>
      </div>
    </div>
  );
};
