import { type PropsWithChildren } from 'react';

import { BillPay } from '@prospa/salt-illustrations';

import { Banner, BillsSummary, BlockLoader, ConfirmBillsAlert } from '@components';

import { BillPayStatus } from '@constants';

import styles from './BillPaySection.module.scss';
import { useBillPaySectionData } from './hook';

const BillPaySectionContainer = ({ children }: PropsWithChildren) => {
  return (
    <div data-testid="bill-pay-section">
      <p className={styles.BillPaySectionHeading}>Bills</p>
      {children}
    </div>
  );
};

const BillPaySectionSkeleton = () => {
  return (
    <div>
      <div className={styles.BillPaySectionSkeletonSectionTitle}>
        <BlockLoader className={styles.BillPaySectionSkeletonLoader} />
      </div>
      <div className={styles.BillPaySectionSkeletonSectionBody}>
        <BlockLoader className={styles.BillPaySectionSkeletonLoader} />
      </div>
    </div>
  );
};

export const BillPaySection = () => {
  const {
    billDetailLoading,
    confirmBillsAlertProps,
    billSummaryProps,
    billPayStatus,
    getStartedBannerProps,
    emailBillBannerProps,
  } = useBillPaySectionData();

  if (billDetailLoading) {
    return <BillPaySectionSkeleton />;
  }

  switch (billPayStatus) {
    case BillPayStatus.NotAvailable:
      return null;
    case BillPayStatus.EmailNotInit:
      return (
        <BillPaySectionContainer>
          <Banner icon={<BillPay />} {...getStartedBannerProps} data-testid="get-start-banner" />
        </BillPaySectionContainer>
      );
    case BillPayStatus.NoBills:
      return (
        <BillPaySectionContainer>
          <Banner icon={<BillPay />} {...emailBillBannerProps} data-testid="email-bill-banner" />
        </BillPaySectionContainer>
      );
    default:
      return (
        <BillPaySectionContainer>
          <ConfirmBillsAlert {...confirmBillsAlertProps} />
          <BillsSummary {...billSummaryProps} />
        </BillPaySectionContainer>
      );
  }
};
