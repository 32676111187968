import type { MouseEvent } from 'react';

import type { Operation } from '@apollo/client';
import { format } from 'date-fns';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import numeral from 'numeral';

import {
  GraphQLDataSources,
  MobileOSTypes,
  SAVE_AS_RESPONSE,
  dateFormatAPI,
  feGatewayOperationsSet,
  mobileGatewayOperationsSet,
} from '@constants';

import type { NavConfig } from '../components/Nav/NavOptions/types';
import { toFixedPointNumber } from './calculations';
import { isNowPastADate } from './date';
import { downloadFileUsingForm, downloadFileUsingLink } from './download';
import { validationConfig } from './validations';

export const currencyFormatter = (value: string | number, includeDecimal = true): string => {
  if (value === null || value === undefined) return null;
  const fixedPointNumber = toFixedPointNumber(Number(value));
  if (fixedPointNumber === null) return null;
  const format = includeDecimal ? '$0,00.00' : '$0,00';
  return numeral(fixedPointNumber).format(format);
};

export const isValidBsbFormat = (bsb: string): boolean =>
  validationConfig.bsbWithDash.pattern.test(bsb);

export const formatBsb = (bsb: string): string => bsb?.replace(/([0-9]{3})([0-9]{3})/, '$1-$2');

export const formatAccountNumber = (accountNumber: string): string =>
  accountNumber?.replace(/(\w{4})(\w{4})/, '$1 $2 ');

export const formatMobileNumber = (mobileNumber: string): string =>
  mobileNumber?.replace(/([0-9]{3})([0-9]{3})([0-9]{3})/, '$1 $2 $3');

export const getInitials = string => {
  const names = string?.split(' ');

  if (!names) {
    return '';
  }

  let initials = names[0]?.substring(0, 1)?.toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const upperCaseWordAtIdx = (stringToFormat: string, idx: number) => {
  const words = stringToFormat.split(' ');
  const secondLastWord = words[words.length - idx];
  words[words.length - idx] = secondLastWord.toUpperCase();
  return words.join(' ');
};

export const getChartData = transactionList => {
  const chartData = {};
  transactionList &&
    transactionList.forEach(transaction => {
      const key = transaction.dateTime.slice(0, 10);
      if (chartData[key]) {
        chartData[key] = chartData[key] + transaction.amount;
      } else {
        chartData[key] = transaction.amount;
      }
    });
  return chartData;
};

// Expected input string: yyyy-mm-dd
export const toDate = (dateString: string): Date => {
  const [year, month, day] = dateString.split('-');
  return new Date(numeral(year).value(), numeral(month).value() - 1, numeral(day).value());
};

export const businessNumberFormatter = (businessNumber: string) => {
  const _businessNumber = businessNumber ? businessNumber.replace(/[^\d]/g, '') : '';

  if (_businessNumber.length === 11) {
    return _businessNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
  }
  if (_businessNumber.length === 13) {
    return _businessNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
  }

  return _businessNumber;
};

export const dateToString = (date: Date) => format(date, dateFormatAPI);

export const dateToStringDisplay = (date: Date) => format(date, 'dd MMMM yyyy');

export const calculateOverdraft = (
  amount: string,
  balance: number,
  total: number,
): string | null => {
  if (amount && amount !== '0') {
    const parsedAmount = Number.parseFloat(amount);
    if (parsedAmount > balance && parsedAmount <= total) {
      const overdraft = Math.abs(parsedAmount - balance);
      // if account is in negative, the actual overdraft should be the minimum
      // of either the calculated overdraft or the account balance
      return numeral(Math.min(Number(overdraft), Math.abs(Number(amount)))).format('$0,00.00');
    }
  }
  return null;
};

export const formatPaymentHeader = (amount: string, nickname?: string) => {
  if (nickname) {
    return `${amount} to ${nickname}`;
  }
  return `${amount}`;
};

export const formatToTitleCase = (stringToFormat: string) =>
  startCase(toLower(stringToFormat || ''));

/**
 * Helper for determining if a fileName signals it has come from the SBL api
 * @param fileName - Desired file name
 */
const isSmallBusinessLoanApi = (fileName: string) => {
  return fileName.match(/api\/statement/gi);
};

/**
 * Helper for determining if a fileName signals it has come from the File Gateway api
 * @param fileName - Desired file name
 */
const isFileGatewayApi = (fileName: string) => {
  return fileName.match(/contracts\/(contract|declaration)-pdf/gi);
};

export function saveAs(url: string, fileName?: string, openInNewTab = true): Promise<string> {
  if (isSmallBusinessLoanApi(url) || isFileGatewayApi(url)) {
    try {
      downloadFileUsingForm({ url, fileName, openInNewTab });
      return Promise.resolve(SAVE_AS_RESPONSE.SUCCESS);
    } catch (error) {
      return Promise.reject(SAVE_AS_RESPONSE.ERROR);
    }
  }

  return fetch(url)
    .then(res => res.blob())
    .then(file => {
      downloadFileUsingLink({ name: fileName, content: file });
      return SAVE_AS_RESPONSE.SUCCESS;
    })
    .catch(() => {
      return Promise.reject(SAVE_AS_RESPONSE.ERROR);
    });
}

export const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isJWTExpired = ({ exp }) => {
  return exp * 1000 < Date.now();
};

export const getSubnavRoute = (pathName): string => {
  switch (pathName) {
    case '/':
      return 'Home';
    case '/ba':
      return 'Business Account';
    case '/sbl':
      return 'Small Business Loan';
    case '/sblm':
      return 'Small Business Loan';
    case '/pay':
      return 'Pay';
    case '/add-funds':
      return 'Add funds';
    case '/statements':
      return 'Statements';
  }
};

export const isSubnavActive = (pathName: string): boolean => {
  switch (pathName) {
    case '/pay':
      return true;
    case '/add-funds':
      return true;
    case '/statements':
      return true;
    default:
      return false;
  }
};

export const isProd = () => window?.__env?.environment === 'prod';

export const externalRedirect = ({
  url,
  openInNewTab = true,
}: {
  url: string;
  openInNewTab?: boolean;
}) => {
  const target = openInNewTab ? '_blank' : '_self';
  return window.open(url, target);
};

export const getDashConnectedStr = (str: string, separator = ' ') =>
  (str || '').toLocaleLowerCase().replaceAll(separator, '-');

export const isLocal = () => process.env.NODE_ENV === 'development';

export const isDemo = () => window?.__env?.environment === 'demo';

export const getNavOptionsConfig = ({
  dashboardToolTip,
  pathname,
  search,
  show,
  badgeText,
  isBadgeLoading,
}: {
  dashboardToolTip: string;
  pathname: string;
  search: string;
  show: { drawdown: boolean; pay: boolean; bills: boolean; funds: boolean; cards: boolean };
  badgeText: { bills: string };
  isBadgeLoading: { bills: boolean };
}): NavConfig[] => [
  {
    label: 'Home',
    to: '/',
    tooltipLabel: dashboardToolTip,
    navSubConfigs: [
      {
        label: 'Pay',
        to: '/pay',
        pathname,
        show: show.pay,
      },
      {
        label: 'Drawdown',
        to: '/drawdown',
        pathname,
        show: show.drawdown,
      },
      {
        label: 'Add funds',
        to: '/add-funds',
        pathname,
        show: show.funds,
      },
      {
        label: 'Statements',
        to: `/statements${search}`,
        pathname,
      },
    ],
  },
  {
    label: 'Bills',
    to: '/bills',
    caseSensitive: true,
    show: show.bills,
    badgeText: badgeText.bills,
    isBadgeLoading: isBadgeLoading.bills,
  },
  {
    label: 'Cards',
    to: '/cards',
    caseSensitive: true,
    show: show.cards,
  },
  {
    label: 'Products',
    to: '/products',
    caseSensitive: true,
  },
  {
    label: 'Settings',
    to: '/settings',
    caseSensitive: true,
  },
];

export const getFileNameFromUrl = (url: string): string => {
  return url.replace(/[#?].*$/, '').substring(url.lastIndexOf('/') + 1);
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

  if (/windows phone/i.test(userAgent)) {
    return MobileOSTypes.WindowsPhone;
  }

  if (/android/i.test(userAgent)) {
    return MobileOSTypes.Android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
    return MobileOSTypes.IOS;
  }
  return MobileOSTypes.Unknown;
};

export const shouldShowMobileAppModal = (countryCode: string, endTime: string) => {
  if (countryCode === 'AU') return true;
  else if (!endTime) return false;

  return isNowPastADate(endTime);
};

export const buildQueryString = (
  data: string | string[][] | Record<string, string> | URLSearchParams,
  encoded = true,
) => {
  const params = new URLSearchParams(data);
  const query = params.toString();
  return encoded ? query : decodeURIComponent(query);
};

export function preventClickEventPopup(callback?: () => void) {
  return (e: MouseEvent<HTMLElement>) => {
    if (!callback) return;
    e.stopPropagation();
    callback?.();
  };
}

// Cypress runs websites in an iframe
export const isAppLaunchedInIframe = () =>
  Boolean(window['Cypress']) || Boolean(window.parent?.['Cypress']);

export function getGQLDataSourceByOperation({ operationName }: Operation): GraphQLDataSources {
  if (feGatewayOperationsSet.has(operationName)) return GraphQLDataSources.FrontendGateway;
  if (mobileGatewayOperationsSet.has(operationName)) return GraphQLDataSources.MobileGateway;
  return GraphQLDataSources.Shaype;
}
export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
