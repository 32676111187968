import tokens from '@prospa/tokens';

import {
  BusinessAccountScheduledPaymentStatus,
  InputProductType,
  LineOfCreditContractStatus,
  LineOfCreditStatus,
  ProspaProduct,
  SmallBusinessLoanContractStatus,
} from '@generated-fg';
import { LineStatusType } from '@generated-mg';

import { ProductTypes } from './models/Account';
import { type BusinessLoanOfferDetail } from './models/AppData';
import { currencyFormatter } from './utils/utils';

export * from './utils/billPay/constants';

export const mobileGatewayOperations = [
  'LoanSummary',
  'loadStatement',
  'LoanRepaymentDetails',
  'loadLoanTransactions',
  'notificationsPreferences',
  'updateUserPreference',
  'AccountDetails',
  'LoanActivities',
  'AccountRepaymentDetails',
  'requestRepayment',
  'loadAccountStatements',
  'getLoanApplicationsIds',
  'getLoc',
  'getLocPaymentsAndTransactions',
  'getLocCLRepaymentDetails',
  'getLocMRepaymentDetails',
];

export const feGatewayOperations = [
  'GetIdentity',
  'stepUpInit',
  'stepUpVerify',
  'getProductsSummary',
  'getBusinessAccountDetails',
  'getStatementsSummary',
  'getStatementsByProduct',
  'getStatement',
  'getCardsMeta',
  'setCardFrozen',
  'setCardContactless',
  'updateCardPin',
  'requestNewCard',
  'getDrawdownDetails',
  'createDrawdown',
  'getUpcomingRepayment',
  'getLoansStatuses',
  'getAppContextData',
  'getAccountsListData',
  'getProductsPageData',
  'getPayAnyoneData',
  'getUserPayeesV2',
  'createPayeeV2',
  'deletePayeeV2',
  'getSmallBusinessLoansData',
  'verifyNZAccountNumber',
  'exchangeAuthCode',
  'updateDataFeeds',
  'getNavData',
  'getProofOfTransfer',
  'initialiseBillPay',
  'getBillsEmail',
  'getBills',
  'deleteBill',
  'getUserCdpId',
  'updateBillPaymentReference',
  'getBillDetails',
  'cancelScheduledBillPayment',
  'confirmBillDetail',
  'activateBAPhysicalCard',
  'verifyBACardNumber',
  'getBusinessAccountPageData',
  'getIntegrationsDataV2',
  'disconnectIntegration',
  'getContract',
  'generateContractSignUrl',
  'bsbLookup',
  'getBAPendingTransactions',
  'getBASettledTransactions',
  'scheduledTransfer',
  'transfer',
  'cancelScheduledPayment',
  'getBusinessAccountScheduledPayments',
  'getRepaymentCalculatorData',
];

export const internalOperations = new Set([...mobileGatewayOperations, ...feGatewayOperations]);

export const feGatewayOperationsSet = new Set(feGatewayOperations);
export const mobileGatewayOperationsSet = new Set(mobileGatewayOperations);

export enum GraphQLDataSources {
  FrontendGateway = 'FrontendGateway',
  MobileGateway = 'MobileGateway',
  Shaype = 'Shaype',
}

export const inputStyles = {
  fontFamily: 'Inter',
  borderRadius: '4px',
  fontSize: '20px',
  display: 'flex',
};

export const invalidInputStyles = {
  ...inputStyles,
  borderBottom: `2px solid ${tokens.pds.color.red[600]}`,
};

export const numberFormats = {
  amountFormatWithDecimal: '0,0.00',
  amountFormatWithoutDecimal: '0,0',
};

export const breakpoints = {
  extraSmall: '(max-width: 576px)',
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1024px)',
  small: '(min-width: 1024px) and (max-width: 1280px)',
  large: '(min-width: 1440px)',
};

export const dateFormatAPI = 'yyyy-MM-dd';

export const dateFormat = 'dd/MM/yyyy';

export const BASupportPhoneNumber = {
  text: `1300 714 530`,
  url: `tel:1300714530`,
};
export const BASupportEmail = {
  text: 'business_account@prospa.com',
  url: `mailto:business_account@prospa.com`,
};

export const LOANS = {
  CARD_TITLE_BUSINESS_LOAN: 'Business Loan',
  BUSINESS_LOAN_OUTSTANDING_BALANCE: 'Outstanding balance',
  LOAN_AMOUNT: 'Loan amount:',
  CARD_TITLE_LINE_OF_CREDIT: 'Line of Credit',
  AVAILABLE_AMOUNT: 'Available amount',
  FACILITY_LIMIT: 'Facility limit:',
};

export const TOTAL_AVAILABLE_TOOLTIP = {
  TITLE: 'Total available',
  TOOLTIP_LINE1: 'Total available across',
  TOOLTIP_LINE2: 'your accounts',
};
export const MAMBU_OUTSTANDING_TOOLTIP = {
  TITLE: `Total outstanding:`,
  TOOLTIP_LINE1: 'Includes principal, interest, fees',
  TOOLTIP_LINE2: 'and pending amounts.',
};

export const PRODUCT_TYPES = {
  BA: 'Business Account',
  LOC: 'Line of Credit',
  LOCM: 'Line of Credit',
  SBL: 'Small Business Loan',
  SBLM: 'Small Business Loan',
  CONTRACT: 'Contract',
} as const;

export const ACCOUNT_STATUS = {
  ...LineStatusType,
  Active: 'Active',
};

export const PAGE_HEADER = {
  SBL: 'Small Business Loan',
};

export const SECTION_HEADING = {
  UPCOMING_REPAYMENT: 'Upcoming repayment',
  SEE_DETAILS: 'See details',
};

export const BUTTON = {
  DOWNLOAD_REPAYMENT_SCHEDULE: 'Repayment schedule',
  NAVIGATE_TO_STATEMENT: 'Statements',
};

export const LOAN_DETAILS = {
  PAYMENT_PLAN: 'Payment plan',
  UPCOMING_REPAYMENT: 'Upcoming repayment',
  REPAYMENT_BREAKDOWN: 'Repayment breakdown',
  FACILITY_LIMIT: 'Facility limit',
  INITIAL_TERM: 'Initial term',
  WEEKLY_SERVICE_FEE: 'Weekly Service Fee',
  TOTAL_OUTSTANDING: 'Total outstanding',
  TOTAL_OUTSTANDING_WITH_ASTERISK: 'Total outstanding*',
  PAYMENT_FREQUENCY: 'repayments',
  TOTAL_REPAID: 'Total repaid',
  START_DATE: 'Start date',
  LOAN_TERM: 'Loan term',
  LOAN_AMOUNT: 'Loan amount',
  TOTAL_REPAY_AMOUNT: 'Total repayment amount',
  FINAL_REPAYMENT_DATE: 'Final repayment date',
  INCLUDES_PRINCIPAL: 'Includes principal',
  INCLUDES_ALL_FEES: 'Includes principal, interest and fees',
  INCLUDES_ORIGINATION_FEE: 'Includes the Origination fee',
  SUBJECT_TO_MEETING_FUTURE_PAYMENTS: 'Subject to meeting future payments',
};

export const MAMBU_LOAN_DETAIL_LABELS = {
  ...LOAN_DETAILS,
  OUTSTANDING: 'Outstanding',
  PRINCIPAL_AMOUNT_ONLY: 'Principal amount only',
};

export const REFINANCE_CALC_BANNER = {
  TITLE: 'Looking for additional funds?',
  SUBTITLE: 'Check out our refinance calculator to see what your new repayments could be.',
  BUTTON: 'Explore',
};

export const REVIEW_AGREEMENT_BANNER = {
  TITLE: 'Your agreement is ready',
  SUBTITLE: 'Review the agreement for your Small Business Loan',
  BUTTON: 'Review agreement',
  ACTIVE_STATUS: 'Active',
};

export const REVIEW_LOC_AGREEMENT_BANNER = {
  TITLE: 'Your agreement is ready',
  SUBTITLE: 'Review the agreement for your Line of Credit',
  BUTTON: 'Review agreement',
  ACTIVE_STATUS: 'Active',
};

export const APPROVED_REFINANCE_APPLICATION_BANNER = {
  TITLE: 'Refinance application approved',
  SUBTITLE: 'Continue to review the loan offer',
  BUTTON: 'Review the offer',
};

export const LOAN_TRANSACTION = {
  EMPTY_STATUS: 'You have 0 Loan transactions',
  LOAD_MORE: 'Load more',
};

export const NEED_HELP = 'Got questions? We’re here to help';

export const SBL = {
  UNSUCCESSFUL_TRANSACTION_LABEL: 'Direct debit unsuccessful',
  UNSUCCESSFUL_TRANSACTION_DESC: 'Late fees may apply',
};

export const SAVE_AS_RESPONSE = {
  SUCCESS: 'File has been downloaded!',
  ERROR: 'Something went wrong! Please try again',
  FAILURE_LOG: 'Download past statement failed',
};

/**
 * @deprecated Will be removed. Use loan's (loc/sbl) `contractStatus` or `status` fields for comparison.
 */
export const DISPLAY_STATUS = {
  PENDING: 'Pending',
  AWAITING: 'Awaiting',
  ACTIVE: 'Active',
  CLOSED: 'Closed',
};

// Note(kj): This is a bad idea, locale should not be
// fixed but be based on if they are an AU or NZ
// customer otherwise we will not be able to correctly
// handle things like currency formatting.
export const locale: Intl.LocalesArgument = 'en-AU';

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SECONDARY_OUTLINE = 'secondary-outline',
  LINK = 'link',
  ICON_LINK = 'iconlink',
  ICON_LINK_UNDERLINE = 'iconlinkunderline',
}

export const LOCAL_STORAGE_STATE = 'LOCAL_STORAGE_STATE';
export const SESSION_STORAGE_STATE = 'SESSION_STORAGE_STATE';

export const repaymentContactNumber = '02 8317 2185';

// Business time for BusinessAccount
export const businessTime = 'Mon–Fri 8:30am – 7pm (AEST)';

export const businessAccountContactNumber = '1300 882 867';

// FE Gateway returns displayStatus: '' when it's equal DISPLAY_STATUS.ACTIVE
export const activeSBLStatuses = ['', DISPLAY_STATUS.ACTIVE];
export const activeSBLStatusesSet = new Set(activeSBLStatuses);
const includeSBLStatuses = [...activeSBLStatuses, DISPLAY_STATUS.CLOSED];
export const includeSBLStatusesSet = new Set(includeSBLStatuses);

export const unsettledSblStatuses = new Set([
  SmallBusinessLoanContractStatus.Pending,
  SmallBusinessLoanContractStatus.PendingSecondSignature,
  SmallBusinessLoanContractStatus.Awaiting,
]);

export const unsettledLocStatuses = new Set([
  LineOfCreditContractStatus.Awaiting,
  LineOfCreditContractStatus.PendingFirstSignature,
  LineOfCreditContractStatus.PendingSecondSignature,
]);

export enum GraphQLErrorCodes {
  MissingScopesError = 'MissingScopesError',
}

export enum LineActivityType {
  AssociatedUserToApplication = 'AssociatedUserToApplication',

  ScheduledPaymentSuccess = 'ScheduledPaymentSuccess',
  ScheduledPaymentPending = 'ScheduledPaymentPending',
  ScheduledPaymentFailure = 'ScheduledPaymentFailure',

  FundsRequestSuccess = 'FundsRequestSuccess',
  FundsRequestPending = 'FundsRequestPending',
  FundsRequestFailure = 'FundsRequestFailure',

  LoanPaymentSuccess = 'LoanPaymentSuccess',
  LoanPaymentPending = 'LoanPaymentPending',
  LoanPaymentFailure = 'LoanPaymentFailure',
  InternalTransferDebit = 'InternalTransferDebit',
  InternalTransferCredit = 'InternalTransferCredit',
}

export const freezeAccountMessage = 'Your Business Account has been frozen.';

export const lineOfCreditSuspendedMessage = 'Line of Credit has been suspended.';

export const lineOfCreditAUBusinessTime = 'Mon-Fri 7:30am-7pm, Sat 9:00am-6pm (AEST)';

export const lineOfCreditNZBusinessTime = 'Mon-Fri 7:30am-7pm, Sat 9:00am-6pm (NZST)';

export const lineOfCreditCloudLendingLockedMessage =
  'Your Line of Credit will be unavailable for up to 2 business days while we review your application.';

export const lineOfCreditMambuLockedMessage =
  'Your Line of Credit is currently unavailable. Please contact us if you need assistance.';

export const lineOfCreditDeferredMessage = 'You are on a deferral.';

export const EMPTY_STATE = {
  URL: 'https://www.prospa.com/',
  TITLE: 'No products found',
  TEXT_ONE: 'Looks like you no longer have any products with us.',
  TEXT_TWO: 'Need funds? We have a range of lending solutions to match your business needs.',
  BUTTON_TEXT: 'Explore our products',
};
export const MOBILE_LINKS = {
  IOS_APP_STORE_URL: 'https://apps.apple.com/au/app/prospa/id1465528935',
  ANDROID_GOOGLE_PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.prospa.mobile&hl=en',
};
export const MOBILE_APP_BANNER = {
  TITLE: 'Access on the Prospa App',
  SUBTITLE: 'Fast, easy and secure',
};

export const MOBILE_APP_BANNER_STATEMENTS_PAGE = {
  TITLE: 'Access statements wherever you are',
  SUBTITLE_DESKTOP: 'Scan the QR code with your mobile to get the Prospa App',
  SUBTITLE_MOBILE: 'Download the Prospa App',
};

export enum MobileOSTypes {
  IOS = 'IOS',
  Android = 'Android',
  WindowsPhone = 'WindowsPhone',
  Unknown = 'Unknown',
}

export const smallBusinessLoansNZBusinessTime = 'Mon–Fri 9:30am – 9pm, Sat 11:00am – 8pm (NZST)';

export const smallBusinessLoansAUMissedPaymentMessage =
  'Looks like you’ve missed a repayment. Late fees may be accruing.';

export const HELP_CENTRE_URL = 'https://prospa.zendesk.com/hc/en-au';

export const BILL_PAY_HELP_CENTRE_URL =
  'https://prospa.zendesk.com/hc/en-au/sections/21064714993177-Product-information';

export const BankAccountLengthAU = {
  Min: 6,
  Max: 9,
};

export const BankAccountLengthNZ = {
  Min: 15,
  Max: 16,
};

export const PayAnyoneNewBankAccountErrorStrings = {
  BsbInvalidError: 'Please enter a valid BSB',
  BankAccountEmptyError: 'Please enter an account number',
  BankAccountInvalidError: 'Please enter numbers only',
  BankAccountInvalidLengthErrorAU: `Please enter between ${BankAccountLengthAU.Min}-${BankAccountLengthAU.Max} digits`,
  BankAccountInvalidLengthErrorNZ: `Please enter between ${BankAccountLengthNZ.Min}-${BankAccountLengthNZ.Max} digits`,
  BankAccountNameEmptyError: 'Please enter an account name',
  BankAccountNameInvalidError: "Only use letters, ', and -",
  NZBankAccountNumberInvalidError: 'Please enter a valid account number',
};

export const MOBILE_APP_RATINGS = {
  IOS: 4.7,
  ANDROID: 4.4,
};

export const NZ_MOBILE_LINKS = {
  IOS_APP_STORE_URL: 'https://apps.apple.com/nz/app/prospa/id1465528935',
  ANDROID_GOOGLE_PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.prospa.mobile&hl=en_NZ',
};

export const NZ_MOBILE_APP_CAMPAIGN_COPY = {
  SCAN_QR_CODE: 'Scan the QR code with your mobile to download the Prospa App.',
  HEADING: 'Manage your cash flow on the go with the new Prospa App.',
  BODY: 'The Prospa App gives small business owners a simple way to manage business finances and boost productivity. Now available on iOS and Android.',
  IOS_APP_STORE_ALT_TEXT: 'Download Prospa Mobile App from App Store',
  ANDROID_APP_STORE_ALT_TEXT: 'Download Prospa Mobile App from Google Play Store',
};

export const GENERIC_ERROR_MESSAGES = {
  GENERIC_ERROR_HEADER: 'We’re having technical difficulties',
  GENERIC_ERROR_BODY:
    'Sorry, we ran into a problem and couldn’t process your request. Try refresh the page or log in again later if the problem persists.',
};

export const CONNECTION_STATUS = {
  NEW: '',
  NOT_CONNECTED: 'Not connected',
  CONNECTED: 'Connected',
  CONNECTION_ERROR: 'Connection error',
  AUTH_ERROR: 'Authentication error',
};

export const PRODUCT_ROUTES = {
  BUSINESS_ACCOUNT: 'business-account',
  LINE_OF_CREDIT: 'line-of-credit',
  SMALL_BUSINESS_LOAN: 'small-business-loan',
  BUSINESS_LOAN_PLUS: 'business-loan-plus',
};

// Routes that have a button in the header
export const HEADER_BUTTON_ROUTES = {
  DATA_FEEDS: '/data-feeds',
  BUSINESS_ACCOUNT_PRODUCT: `/products/${PRODUCT_ROUTES.BUSINESS_ACCOUNT}`,
  LINE_OF_CREDIT_PRODUCT: `/products/${PRODUCT_ROUTES.LINE_OF_CREDIT}`,
  SMALL_BUSINESS_LOAN_PRODUCT: `/products/${PRODUCT_ROUTES.SMALL_BUSINESS_LOAN}`,
  BUSINESS_LOAN_PLUS_PRODUCT: `/products/${PRODUCT_ROUTES.BUSINESS_LOAN_PLUS}`,
  REPAYMENT_CALCULATOR: '/repayment-calculator',
};

export const DASHBOARD_ROUTES = {
  SETTINGS: '/settings',
  REDIRECTS: '/redirects',
  DATA_FEEDS: '/data-feeds',
  PRODUCTS: '/products',
  BILLS: '/bills',
  DRAWDOWN: '/drawdown',
  PAY_ANYONE: '/pay',
};

export enum REDIRECTION_SOURCES {
  XERO = 'xero',
}

export const ACCOUNTING_INTEGRATION_ERROR_MESSAGES = {
  SYNC_ERROR:
    'Xero has a sync error. We’ll keep trying to fix this, or you can try switching sync off and on again.',
  SYNC_ERROR_WITH_BUTTON:
    'Xero has a sync error. We’ll keep trying to fix this, or you can try toggle sync.',
  AUTH_ERROR:
    "We've lost connection to Xero. To resume syncing your transactions, please connect again.",
};
export enum ACCOUNTING_INTEGRATION_MODALS {
  ACCOUNT_DISCONNECT_CONFIRM = 'ACCOUNT_DISCONNECT_CONFIRM',
  ACCOUNT_UNSYNC_CONFIRM = 'ACCOUNT_UNSYNC_CONFIRM',
  ACCOUNT_SYNCED_SUCCESS = 'ACCOUNT_SYNCED_SUCCESS',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export const TERMS_AND_CONDITIONS_URL =
  'https://www.prospa.com/important-documents#online-terms-conditions';

export const CONNECT_TO_XERO_COPY = {
  HEADING: 'Automatically sync your transactions to Xero',
  DESCRIPTION:
    'Connecting allows Prospa to send and receive data from Xero, so we can automatically sync future transactions to your Xero account',
  POINT_1: 'Save time on admin by managing cash flow in one place',
  POINT_2: 'Choose which Prospa accounts you want to sync',
  POINT_3: 'Keep your data protected with bank-level security and encrypted connection',
  TERMS_AND_CONDITIONS_URL,
  IMAGE: {
    MOBILE: 'https://static.prospa.com/all-in-one-portal/xero-get-started-animation-mobile.gif',
    DESKTOP: 'https://static.prospa.com/all-in-one-portal/xero-get-started-animation-desktop.gif',
  },
};

export const ADD_CARD_TO_DIGITAL_WALLET_COPY = {
  HEADING: 'Make fast, secure payments from your phone',
  DESCRIPTION:
    'With the Prospa App, you can add your card to your Apple Wallet or your Google Wallet and start spending with ease. No physical card required.',
  FOOTER: 'Scan the QR code or visit the App Store or Google Play to download instantly.',
  IMAGE: {
    MOBILE: 'https://static.prospa.com/all-in-one-portal/add-to-digital-wallet-mobile.gif',
    DESKTOP: 'https://static.prospa.com/all-in-one-portal/add-to-digital-wallet-desktop.gif',
  },
};

export const BILL_CVP_CARD_COPY = {
  HEADING: 'Effortlessly automate your bills admin',
  DESCRIPTION:
    'Simply upload bills via email and we’ll automatically scan the details to save you time',
  POINT_1: 'View, schedule and pay for upcoming bills all in one place',
  POINT_2: 'Flexibility to make payments now or schedule them for later',
  POINT_3: 'Get payment notifications and insights about your bill expenses',
  MEDIA: {
    MOBILE: 'https://static.prospa.com/all-in-one-portal/new-bill-pay-feature-mobile.gif',
    DESKTOP: 'https://static.prospa.com/all-in-one-portal/new-bill-pay-feature-desktop.gif',
  },
};
export const BUSINESS_ACCOUNT_CVP_CARD_COPY = {
  HEADING: 'Control your business finances from anywhere, anytime',
  DESCRIPTION: 'A simple, fee-free Business Account that you can access from the palm of your hand',
  POINT_1: 'Add your card to Apple pay or Google Pay',
  POINT_2: 'Effortlessly manage and pay bills ',
  POINT_3: 'Seamless account integration with Xero',
  MEDIA: {
    MOBILE: 'https://static.prospa.com/all-in-one-portal/ba-cvp-animation-mobile.gif',
    DESKTOP: 'https://static.prospa.com/all-in-one-portal/ba-cvp-animation-desktop.gif',
  },
};

export const NUMBER_OF_WEEKS_PER_YEAR = 52;
export const ONE_DAY = 24 * 60 * 60 * 1000;

export const BUSINESS_LOAN_OFFER_BANNER = {
  TITLE: 'Small Business loan offer: 4 weeks no \n repayments and no interest',
  SUBTITLE: 'Offer ends 31 Mar 2024. T&Cs apply.',
  BUTTON: 'Find out more',
};

export const businessLoanOffer: BusinessLoanOfferDetail = {
  startDate: '2024-02-01',
  endDate: '2024-03-27',
  targetCountry: 'NZ',
  noApprovedApplicationDays: 90,
};

export enum FileGatewayType {
  Contract = 'contract',
  Declaration = 'declaration',
}

export enum BILL_PAY_MODALS {
  EMAIL_BILL = 'EMAIL_BILL',
  CVP = 'CVP',
  DELETE_BILL = 'DELETE_BILL',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export const PRODUCT_BANNERS = {
  BA: {
    TITLE: 'Business Account',
    SUBTITLE: 'A simple way to control your business finances from one place.',
  },
  LOC: {
    TITLE: 'Line of Credit',
    SUBTITLE: 'Ongoing access up to $500K to help simplify your cash flow.',
  },
  SBL: {
    TITLE: 'Small Business Loan',
    SUBTITLE: 'Lump sum from $5K to $150K to keep your business moving.',
  },
  SBLP: {
    TITLE: 'Business Loan Plus',
    SUBTITLE: 'Lump sum funding from $150K to $500K to take your business further.',
  },
  BUTTON_TEXT: 'Learn more',
};

export const TOOL_BANNERS = {
  REPAYMENT_CALCULATOR: {
    TITLE: 'Repayment Calculator',
    SUBTITLE: 'Find out what your repayments could look like with our simple calculator.',
    BUTTON_TEXT: 'Try it out',
  },
};

export const PRODUCT_MODALS = {
  LOC: {
    TITLE: 'Line of Credit',
    SUBTITLE:
      'Build cash flow confidence with ongoing access to up to $500K. Use what you need, when you need it.',
  },
  SBL: {
    TITLE: 'Small Business Loan',
    SUBTITLE: 'Same day access to funds up to $150K to keep your business moving.',
  },
  SBLP: {
    TITLE: 'Business Loan Plus',
    SUBTITLE: 'Borrow from $150K up to $500K to invest in a plan.',
  },
  BUTTON_TEXT: 'Get started',
};

export const PRODUCT_MODALS_ALERT_TEXT = {
  LOC_SUSPENDED: 'You can’t apply while your Line of Credit is suspended.',
  LOC_DEFERRED: 'Ready to apply? You’ll need to wait until your deferral period ends',
  CAPITALPRODUCTS_IN_ARREARS: 'Want to apply? Make sure your repayments are up to date.',
};

export const creditLinesRejectedStatus = new Set([
  LineOfCreditStatus.Closed,
  LineOfCreditStatus.ContractExpired,
  LineOfCreditStatus.ContractDeclined,
]);

// DLS - digital lead source
export const DLS_TYPES = {
  BA: 'portal-ba',
  CAPITAL: 'portal-capital',
  CAPITAL_REPAY_CALC: 'portal-capital-repay-calc',
  AU_MOBILE_REPAY_CALC: 'au--mobile-repay-calc',
  NZ_MOBILE_REPAY_CALC: 'nz--mobile-repay-calc',
};

export const MinimumDrawdownAmount = 10;

export const DrawdownFormErrorStrings = {
  MinimumDrawdownAmountError: `The minimum drawdown amount is ${currencyFormatter(
    MinimumDrawdownAmount,
  )}`,
  AmountEmptyError: 'Please enter an amount',
  AvailableBalanceError: 'Your available balance is',
};

export const MinAmount = 0.01;
export const DescriptionMaxLength = 35;
export const ReferenceMaxLength = 35;
export const BillerCodeMinLength = 4;
export const BillerCodeMaxLength = 6;
export const BpayReferenceNumberMinLength = 2;
export const BpayReferenceNumberMaxLength = 20;
export const PayAnyoneDailyLimitLOCAU = 10000;
export const PayAnyoneDailyLimitLOCNZ = 5000;

export const PayAnyoneFormErrorStrings = {
  BankAccountBalanceError: 'Your available balance is',
  AmountEmptyError: 'Please enter an amount',
  DescriptionEmptyError: 'Please enter a description',
  DescriptionMaxLengthError: (value: string | number = DescriptionMaxLength) =>
    `Description cannot exceed ${value} characters`,
  DescriptionInvalidError: 'Letters, numbers, spaces, and - / \\ only',
  ReferenceInvalidError: 'Letters, numbers, spaces, and . , : - _ / # & ? only',
  PayeeEmptyError: 'Please select a contact',
  ReferenceEmptyError: 'Please enter a reference number',
  ReferenceMaxLengthError: (value: string | number = ReferenceMaxLength) =>
    `Reference cannot exceed ${value} characters`,
  MinimumAmountError: 'Please enter an amount of at least',
  DailyLimitError: 'Please enter an amount with a maximum of',
  RemainingDailyLimitError: 'Your remaining daily transfer limit is',
};

export const PayAnyoneNewBpayErrorStrings = {
  BillerCodeEmptyError: 'Please enter a Biller code',
  BillerCodeInvalid: 'Please enter a valid Biller code',
  BillerCodeNotFound: 'This Biller code is invalid',
  ReferenceNumberEmptyError: 'Please enter a reference number',
  NicknameEmptyError: 'Please enter a nickname',
  ReferenceNumberInvalid: 'Please enter a valid reference number',
  NicknameDuplicateError: 'This Biller nickname already exists',
  NicknameInvalid: 'Please enter a valid nickname',
};

export const PayAnyoneRecurringPaymentStrings = {
  PaymentDateEmptyError: 'Please enter or select a scheduled date',
  PaymentInvalidDateError: 'Please enter or select a scheduled date (dd/mm/yyyy)',
  PaymentPastDateError: 'Please enter a valid scheduled date (dd/mm/yyyy)',
  PaymentFrequencyEmptyError: 'Please select a payment frequency',
  PaymentDurationEmptyError: 'Please select a duration',
  PaymentStartDateEmptyError: 'Please enter or select an start date',
  PaymentInvalidStartDateError: 'Please enter or select a start date (dd/mm/yyyy)',
  PaymentPastStartDateError: 'Please enter a valid start date (dd/mm/yyyy)',
  PaymentEndDateEmptyError: 'Please enter or select an end date',
  PaymentInvalidEndDateError: 'Please enter or select a end date (dd/mm/yyyy)',
  PaymentEndDateEarlyError: 'End date must be after the start date',
  NumberOfPaymentsEmpty: 'Please enter number of payments',
  NumberOfPaymentsInvalid: 'Please enter a valid number of payments',
  NumberOfPaymentsMaxInvalid: 'Please enter a number less than 100',
};

export enum PayeeError {
  DeletePayeeError = 'DeletePayeeError',
  AddNewPayeeError = 'AddNewPayeeError',
}

export const PayeeErrorStrings = {
  AddNewPayeeError:
    'We are unable to add your payee due to an error with the account details you provided. Please check the details and try again.',
  DeletePayeeError:
    'We are unable to delete your payee due to an error. Please check your network status and try again.',
};

export const locTypes = new Set([ProductTypes.LOC, ProductTypes.LOCM]);

export enum PAYMENT_TYPE {
  PAY_NOW = 'pay now',
  PAY_LATER = 'pay later',
  RECURRING = 'recurring',
}

export enum PayAnyoneTabs {
  PAY_NOW = 0,
  PAY_LATER = 1,
  RECURRING = 2,
}

export const CURRENT_TAB_TO_PAYMENT_TYPE_MAP: { [key in PayAnyoneTabs]: PAYMENT_TYPE } = {
  [PayAnyoneTabs.PAY_NOW]: PAYMENT_TYPE.PAY_NOW,
  [PayAnyoneTabs.PAY_LATER]: PAYMENT_TYPE.PAY_LATER,
  [PayAnyoneTabs.RECURRING]: PAYMENT_TYPE.RECURRING,
};

export const PAYMENT_LAG_TIME = 1200;

export const locContractStatusesInDrawdownList = new Set([
  LineOfCreditContractStatus.Active,
  LineOfCreditContractStatus.Suspended,
  LineOfCreditContractStatus.Deferred,
  LineOfCreditContractStatus.Locked,
  LineOfCreditContractStatus.WrittenOff,
  LineOfCreditContractStatus.SuspendedNearMaturity,
]);

export const locContractStatusesCanPayAndDrawdown = new Set([
  LineOfCreditContractStatus.Active,
  LineOfCreditContractStatus.SuspendedNearMaturity,
]);

export const ProspaProductToNameMap = {
  [ProspaProduct.BusinessAccount]: PRODUCT_TYPES.BA,
  [ProspaProduct.LineOfCreditMambu]: PRODUCT_TYPES.LOCM,
};
export const ProspaProductToInputProductTypeMap = {
  [ProspaProduct.BusinessAccount]: InputProductType.BusinessAccount,
  [ProspaProduct.LineOfCreditMambu]: InputProductType.LineOfCreditMambu,
};

export const RANDOM_LARGE_BALANCE = 60000;

export const ADULT_AGE = 18;

export const MAX_AGE = 120;

export const BA_GTM_ID = 'GTM-ND94R6L';

export const INVALID_BA_SCHEDULE_TRANSACTION_STATUS_SET = new Set([
  BusinessAccountScheduledPaymentStatus.Deleted,
  BusinessAccountScheduledPaymentStatus.Replaced,
]);
