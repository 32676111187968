import classNames from 'classnames';

import { Delete as TrashIcon } from '@prospa/salt-icons';

import { PaymentType } from '@generated-fg';

import type { PayeeV2 } from '../../../models/PayAnyone';
import { getPayeeHeader, getPayeeIcon, getPayeeSubheader } from '../helpers';
import './PayAnyoneMenuOption.scss';

type Props = {
  payee: PayeeV2;
  onClick: () => void;
  onDelete: (option: PayeeV2) => void;
};

export const PayAnyoneMenuOption = ({ payee, onClick, onDelete }: Props) => {
  return (
    <li
      className="payanyone-dropdown__menu__option"
      onClick={() => {
        onClick();
      }}
    >
      {getPayeeIcon(payee.paymentType)}
      <div className="payanyone-dropdown__menu__option-description">
        <span className={classNames('payanyone-dropdown__menu__option-header', 'pds-text')}>
          {getPayeeHeader(payee)}
        </span>
        <span className={classNames('payanyone-dropdown__menu__option-subheader', 'pds-caption')}>
          {getPayeeSubheader(payee)}
        </span>
      </div>
      {payee.paymentType !== PaymentType.BusinessAccount && (
        <TrashIcon
          onClick={event => {
            onDelete(payee);
            event.stopPropagation();
          }}
          className="payanyone-dropdown__menu__option-delete"
        />
      )}
    </li>
  );
};
