import { Document } from '@prospa/salt-illustrations';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { externalRedirect } from '@utils';

import styles from './Contract.module.scss';
import { type DeclinedContractProps } from './types';

export const DeclinedContract = ({ showButton = true }: DeclinedContractProps) => {
  return (
    <div className={styles.Contract} data-testid="declined-contract">
      <Document className={styles.ContractIllustration} />
      <h2>Sorry you're leaving</h2>
      <p className={styles.ContractSubtitle}>
        We're constantly reviewing the terms and conditions of our products, please keep us in mind
        next time.
      </p>
      {showButton && (
        <Button
          className={styles.ContractConfirmButton}
          size={ButtonSize.MEDIUM}
          type={ButtonType.PRIMARY}
          label="Return to dashboard"
          onClick={() => externalRedirect({ url: '/', openInNewTab: false })}
        />
      )}
    </div>
  );
};
