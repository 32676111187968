export const common = {
  identityServer: {
    authority: 'https://staging-identity.prospa.com',
  },
  hay: {
    apiUrl: 'https://api.staging.hay.co',
    authUrl: 'https://auth.staging.hay.co',
  },
  feGateway: {
    apiUrl: 'https://staging-eng2-mobile-frontend-gateway-api.dev.prospa.com',
  },
  mobileGateway: {
    apiUrl: 'https://staging-mobile-gateway.prospa.com',
  },
  fileGateway: {
    apiUrl: 'https://staging-eng2-documents-docmgmt-file-gateway-api.dev.prospa.com',
  },
  sentry: {
    url: 'https://89d1b45d989a42b58d426b585682ec28@o246285.ingest.sentry.io/5880419',
    environment: 'staging',
  },
  customerPortal: {
    dashboardUrl: 'https://staging-my.prospa.com/',
    refinanceCalculator: 'https://staging-my.prospa.com/sbl-refinance-calculator',
  },
  addressFinderKey: '7GPHTEB9D6KXJ8M4NACU',
  repaymentSchedule: {
    baseUri: 'https://staging-sbl-api.prospa.com/v1/api/repaymentschedules/pdf',
  },
  lineOfCreditManagement: {
    loanAccountBaseUrl: 'https://staging-api-loc-management.prospa.com',
  },
  businessAccountOnboarding: {
    baseUrl: 'https://staging-ba-application.prospa.com',
  },
  cxApplicationWeb: {
    AU: {
      baseUrl: 'https://staging-cx-web.prospa.com',
    },
    NZ: {
      baseUrl: 'https://staging-cx-web.prospa.co.nz',
    },
  },
  limits: {
    BPAYSpend: 50000,
  },
  xero: {
    authenticationEndpoint: 'https://login.xero.com/identity/connect/authorize',
    scopes: ['offline_access', 'bankfeeds', 'accounting.settings'],
  },
  clarity: {
    projectId: 'o81ckavcyx',
  },
};
