import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { format } from 'date-fns';
import upperFirst from 'lodash/upperFirst';

import {
  BusinessAccountSchedulePaymentType,
  BusinessAccountScheduledPaymentStatus,
} from '@generated-fg';

import {
  Button,
  CancelScheduledPaymentsModal,
  ErrorModal,
  SecurityCodeModal,
  formatTransactionAmount,
} from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { PayAnyoneTabs } from '@constants';
import { formatBsb, getTransactionTrackingDetails, trackBAActionEvent } from '@utils';

import { OpenModalType } from '../../Activity/Activity';
import { type ScheduledPayment } from '../types';
import './ScheduledPaymentItem.scss';
import type { ScheduledPaymentItemProps } from './types';

const getSubtitle = (scheduledPayment: ScheduledPayment): string => {
  const paymentFrequency = scheduledPayment.frequency
    ? upperFirst(scheduledPayment.frequency.toLowerCase())
    : 'Once';

  switch (true) {
    // One time
    case scheduledPayment.type === BusinessAccountSchedulePaymentType.OneTime: {
      return paymentFrequency;
    }
    // End after exact number of payments
    case scheduledPayment.type === BusinessAccountSchedulePaymentType.Recurring &&
      !!scheduledPayment.numberOfPayments: {
      return `${paymentFrequency} for ${scheduledPayment.numberOfPayments} payments`;
    }
    // End on specific date
    case scheduledPayment.type === BusinessAccountSchedulePaymentType.Recurring &&
      !!scheduledPayment.endDate: {
      return `${paymentFrequency} until ${format(new Date(scheduledPayment.endDate), 'dd LLL yy')}`;
    }
    // No end date
    case scheduledPayment.type === BusinessAccountSchedulePaymentType.Recurring &&
      !scheduledPayment.endDate: {
      return paymentFrequency;
    }
    default:
      return '';
  }
};

const ScheduledPaymentItem = ({
  scheduledPayment,
  onCancelScheduledPayment,
  useProspaStepUpFlow,
}: ScheduledPaymentItemProps) => {
  const [openModal, setOpenModal] = useState<OpenModalType>(null);
  const [expandDetails, setExpandDetails] = useState<boolean>(false);
  const navigate = useNavigate();

  const paymentMonth = format(new Date(scheduledPayment?.startDate), 'MMM');
  const paymentDate = format(new Date(scheduledPayment?.startDate), 'dd');
  const paymentSubtitle = getSubtitle(scheduledPayment);

  const onCancelScheduledPaymentsModalSubmit = () => {
    trackBAActionEvent(
      'pay-anyone_payment-delete-confirmation',
      getTransactionTrackingDetails({
        amount: scheduledPayment.amount.toString(),
        currentTab:
          scheduledPayment.type === BusinessAccountSchedulePaymentType.OneTime
            ? PayAnyoneTabs.PAY_LATER
            : PayAnyoneTabs.RECURRING,
        paymentDate: scheduledPayment.startDate,
        paymentFrequency: scheduledPayment?.frequency?.toLowerCase() ?? '',
      }),
    );
    !useProspaStepUpFlow
      ? onCancelScheduledPayment(scheduledPayment, setOpenModal)
      : setOpenModal(OpenModalType.SecurityModal);
  };

  const recipientDetails =
    scheduledPayment.details.__typename === 'BPayDetails'
      ? `${scheduledPayment.details?.billerCode} ${scheduledPayment.details?.reference}`
      : `${formatBsb(scheduledPayment.details?.bsb)} ${scheduledPayment.details?.accountNumber}`;

  const recipientName =
    scheduledPayment.details.__typename === 'BPayDetails'
      ? scheduledPayment.details?.nickname
      : scheduledPayment.details?.name;

  return (
    <li
      data-testid="expand-details"
      className={classNames('payment-item-container', { expanded: expandDetails })}
      onClick={() => {
        setExpandDetails(!expandDetails);
      }}
    >
      <div className="payment-item__hover-container">
        <div className="payment-item">
          <div className="payment-item__date">
            <div
              className={classNames('payment-item__date__day', 'pds-lead', {
                'pds-lead-semibold': expandDetails,
              })}
            >
              {paymentDate}
            </div>
            <div className={classNames('payment-item__date__month', { active: expandDetails })}>
              {paymentMonth}
            </div>
          </div>
          <div className="payment-item__data">
            <div className={classNames('pds-lead', { 'pds-lead-semibold': expandDetails })}>
              {recipientName}
            </div>
            <div className="payment-item__transferPeerDetails">
              <div
                className={classNames('payment-item__description', 'pds-text', {
                  active: expandDetails,
                })}
              >
                {scheduledPayment.description}
              </div>
            </div>
            {expandDetails && (
              <>
                <div className="pds-text">{`Payment to ${recipientDetails}`}</div>
                <div className="pds-text">
                  {format(new Date(scheduledPayment.startDate), 'E, dd MMM yyyy')}
                </div>
              </>
            )}
          </div>
          <div className="payment-item__values">
            <div
              className={classNames('payment-item__amount', 'pds-lead', {
                'pds-lead-semibold': expandDetails,
              })}
            >
              {formatTransactionAmount(-Math.abs(scheduledPayment?.amount.amount))}
            </div>
            <div
              className={classNames('payment-item__rolling-balance', 'pds-text', {
                active: expandDetails,
              })}
            >
              {paymentSubtitle}
            </div>
            {expandDetails && !!scheduledPayment.billId && (
              <Button
                type={ButtonType.ICON_LINK_UNDERLINE}
                size={ButtonSize.MEDIUM}
                onClick={() => navigate(`/bill/${scheduledPayment.billId}`)}
                label="View Bill"
              />
            )}
            {scheduledPayment.status === BusinessAccountScheduledPaymentStatus.Active &&
              expandDetails &&
              !scheduledPayment.billId && (
                <Button
                  type={ButtonType.ICON_LINK_UNDERLINE}
                  size={ButtonSize.MEDIUM}
                  onClick={() => setOpenModal(OpenModalType.CancelPaymentConfirm)}
                  label="Cancel"
                />
              )}
          </div>
        </div>
      </div>
      <CancelScheduledPaymentsModal
        isOpen={openModal === OpenModalType.CancelPaymentConfirm}
        onClose={() => {
          setOpenModal(null);
        }}
        recipientName={recipientName}
        handleSubmit={onCancelScheduledPaymentsModalSubmit}
      />
      {useProspaStepUpFlow && (
        <SecurityCodeModal
          isCheck2FAStarted={openModal === OpenModalType.SecurityModal}
          closeModal={() => setOpenModal(null)}
          onSuccess={async () => {
            setOpenModal(null);
            await onCancelScheduledPayment(scheduledPayment, setOpenModal);
          }}
        />
      )}

      <ErrorModal
        isOpen={openModal === OpenModalType.Error}
        toggle={() => setOpenModal(null)}
        okButtonCallback={() => setOpenModal(null)}
      />
    </li>
  );
};

export default ScheduledPaymentItem;
