import { type ReactElement, useCallback, useEffect } from 'react';

import classNames from 'classnames';

import { Banner } from '@components';

import { ButtonType } from '@constants';
import { EventActions, trackBAActionEvent } from '@utils';

import styles from './ProductBanner.module.scss';
import type { ProductBannerProps } from './types';

export const ProductBanner = ({
  icon,
  title,
  subtitle,
  buttonProps: restButtonProps,
  id,
}: ProductBannerProps): ReactElement => {
  const logProductBannerViewEvent = useCallback(() => {
    switch (id) {
      case 'repayment-calculator-banner':
        trackBAActionEvent(EventActions.REPAYMENT_CALCULATOR_PRODUCT_BANNER_VIEWED);
        break;
      default:
    }
  }, [id]);

  useEffect(() => {
    logProductBannerViewEvent();
  }, [logProductBannerViewEvent]);

  return (
    <Banner
      icon={icon}
      title={title}
      subtitle={subtitle}
      buttonProps={{ type: ButtonType.ICON_LINK_UNDERLINE, ...restButtonProps }}
      className={classNames(styles['ProductBanner'], 'banner')}
    />
  );
};
