import { type FC, type KeyboardEvent } from 'react';

import classNames from 'classnames';

import { type AccountItem } from '@models';

import { RenderOptionDefault } from '../AccountSelectorRenders';
import './AccountSelectorOption.scss';

type Props = {
  accountItem: AccountItem;
  onClick: () => void;
  index: number;
  renderSelectorOption?: FC<{ accountItem: AccountItem }>;
};

export const AccountSelectorOption = ({
  accountItem,
  index,
  onClick,
  renderSelectorOption: RenderSelectorOption = RenderOptionDefault,
}: Props) => {
  // Event handler for keydowns
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        onClick();
        break;
      default:
        break;
    }
  };
  return (
    <li
      className={classNames('account-selector-option', { disabled: !!accountItem.itemDisabled })}
      id={`account-selector-option-${index}`}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={onClick}
    >
      <RenderSelectorOption accountItem={accountItem} />
    </li>
  );
};
