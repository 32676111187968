import { MicroilloEmailOnway as EmailIcon } from '@prospa/icons';

import { Modal } from '@components';

import { formatAddress } from '@utils';

import './RequestNewCardSuccessModal.scss';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  address?: string;
  defaultAddress?: string;
  handleSubmit: () => void;
}

export const RequestNewCardSuccessModal = ({ isOpen, toggle, address, handleSubmit }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      showCloseModal
      size="medium"
      primaryCTALabel="OK, thanks"
      primaryCTAOnClick={() => {
        handleSubmit();
        toggle();
      }}
      body={
        <div className="new-card-success-modal">
          <EmailIcon className="new-card-success-modal__icon" />
          <h4>Your new card is on its way</h4>

          <p className="pds-text">
            Your card will be sent to{' '}
            <span className="new-card-success-modal__address">
              {address && formatAddress(address)}
            </span>
          </p>
        </div>
      }
    />
  );
};
