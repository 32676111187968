import { MicroilloDollarTick as OverdraftIcon } from '@prospa/icons';

import { Banner } from '@components';

import { OVERDRAFT_PORTAL_URL } from '@utils';
import { externalRedirect } from '@utils';

import './OverdraftBanner.scss';
import type { OverdraftBannerProps } from './types';

const messages = {
  headline: 'Get Business Overdraft',
  subheadline: 'Manage your cash flow as you need it.',
};

export const OverdraftBanner = ({ renderOverdraft = false }: OverdraftBannerProps) => {
  if (!renderOverdraft) {
    return null;
  }

  return (
    <Banner
      className="banner overdraft"
      icon={<OverdraftIcon />}
      title={messages.headline}
      subtitle={messages.subheadline}
      onClick={() => {
        externalRedirect({ url: OVERDRAFT_PORTAL_URL });
      }}
      buttonText="Apply now"
    />
  );
};
