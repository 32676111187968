import type { AlertProps } from '@all-in-one-web/common-ui';
import { Info } from '@prospa/icons';
import { Bolt, Calendar, CrossCircle, DollarCycle, Lock, Tune } from '@prospa/salt-icons';
import { LOC as LOCIcon, SBL as SBLIcon, SBLPlus as SBLPlusIcon } from '@prospa/salt-illustrations';

import { BusinessAccountStatus } from '@generated-fg';

import { DLS_TYPES, PRODUCT_MODALS, PRODUCT_MODALS_ALERT_TEXT } from '@constants';
import { ProductCVPType } from '@models';
import { externalRedirect, formatDateInDayMonthYear, trackBAActionEvent } from '@utils';

import environments from '../../environments';
import styles from './Products.module.scss';
import { type ProductCVPPageProps } from './types';

const { businessAccountOnboarding, cxApplicationWeb } = environments;

type OpenProductUrl = (
  countryCode: string,
  token: string,
  dls: (typeof DLS_TYPES)[keyof typeof DLS_TYPES],
  product: ProductCVPType | ProductCVPType,
  cdpId?: string,
) => Window;

type BAOnboardingParams = {
  flow: string;
  dls: string;
  cdpId?: string;
};

type CXApplicationParams = {
  flow: string;
  dls: string;
  token: string;
  product: ProductCVPType;
  cdpId?: string;
};

export const buildBAOnboardingProductUrl = (dls: string, cdpId?: string) => {
  const params: BAOnboardingParams = { flow: 'aio', dls, ...(cdpId && { cdpId }) };
  const searchParams = new URLSearchParams(params);
  return `${businessAccountOnboarding.baseUrl}/welcome?${searchParams.toString()}`;
};

export const buildCxApplicationProductUrl = (
  countryCode: string,
  token: string,
  dls: string,
  product: ProductCVPType,
  cdpId?: string,
) => {
  const params: CXApplicationParams = {
    token,
    flow: 'aio',
    dls,
    product,
    ...(cdpId && { cdpId }),
  };
  const searchParams = new URLSearchParams(params);
  return `${cxApplicationWeb[countryCode].baseUrl}/qualification?${searchParams.toString()}`;
};

export const openProductUrl: OpenProductUrl = (countryCode, token, dls, product, cdpId) => {
  const url =
    product === ProductCVPType.BA
      ? buildBAOnboardingProductUrl(dls, cdpId)
      : buildCxApplicationProductUrl(countryCode, token, dls, product, cdpId);

  return externalRedirect({ openInNewTab: false, url });
};
export const getAlertText = ({
  isCapitalProductsInArrears,
  deferralPeriodEndDate,
  isLOCSuspended,
  isLOCDeferred,
}) => {
  if (isLOCSuspended) {
    return PRODUCT_MODALS_ALERT_TEXT.LOC_SUSPENDED;
  }

  if (isLOCDeferred) {
    return deferralPeriodEndDate
      ? `${PRODUCT_MODALS_ALERT_TEXT.LOC_DEFERRED} on ${formatDateInDayMonthYear(
          deferralPeriodEndDate,
        )}.`
      : `${PRODUCT_MODALS_ALERT_TEXT.LOC_DEFERRED}.`;
  }

  if (isCapitalProductsInArrears) {
    return PRODUCT_MODALS_ALERT_TEXT.CAPITALPRODUCTS_IN_ARREARS;
  }

  return '';
};
export const getProductCVPPageProps = ({
  productsPageDataLoading,
  baAccountStatus,
  isCapitalProductsInArrears,
  isLOCSuspended,
  isLOCDeferred,
  deferralPeriodEndDate,
  countryCode,
  token,
  cdpId,
  openProductUrl,
}: {
  productsPageDataLoading: boolean;
  baAccountStatus: BusinessAccountStatus | null;
  isCapitalProductsInArrears: boolean;
  isLOCSuspended: boolean;
  isLOCDeferred: boolean;
  deferralPeriodEndDate: string;
  countryCode: string;
  token: string;
  cdpId?: string;
  openProductUrl: OpenProductUrl;
}): ProductCVPPageProps => {
  const isBAFrozenOrBlocked =
    baAccountStatus === BusinessAccountStatus.Frozen ||
    baAccountStatus === BusinessAccountStatus.Blocked;
  const showCapitalProductsAlert = isCapitalProductsInArrears || isLOCDeferred || isLOCSuspended;
  const showCapitalProductsButton = !showCapitalProductsAlert && !isBAFrozenOrBlocked;

  const alertProps: AlertProps = {
    alertType: 'Info',
    icon: <Info width={24} height={24} />,
    children: getAlertText({
      isCapitalProductsInArrears,
      isLOCDeferred,
      deferralPeriodEndDate,
      isLOCSuspended,
    }),
    className: styles.ProductsModalAlert,
  };
  return [
    {
      icon: <LOCIcon />,
      title: PRODUCT_MODALS.LOC.TITLE,
      subTitle: PRODUCT_MODALS.LOC.SUBTITLE,
      items: [
        {
          icon: <Bolt />,
          title: 'Easy application',
          subTitle: 'Apply in 10 minutes with minimal paperwork.',
        },
        {
          icon: <DollarCycle />,
          title: 'Access from $2K to $500K',
          subTitle: 'Fast application and a quick decision with funding possible in hours.',
        },
        {
          icon: <Calendar />,
          title: 'Flexible repayments',
          subTitle: 'Only pay interest on the funds you use.',
        },
        {
          icon: <CrossCircle />,
          title: 'No upfront security',
          subTitle: 'No property security required to access up to $150K in Prospa funding.',
        },
      ],
      type: ProductCVPType.LOC,
      ...(showCapitalProductsButton && {
        primaryButtonProps: {
          onClick: () => {
            trackBAActionEvent('loc_get_started_click');
            openProductUrl(countryCode, token, DLS_TYPES.CAPITAL, ProductCVPType.LOC, cdpId);
          },
          label: PRODUCT_MODALS.BUTTON_TEXT,
          loading: productsPageDataLoading,
          disabled: productsPageDataLoading,
        },
      }),
      ...(showCapitalProductsAlert && { alertProps }),
    },
    {
      icon: <SBLIcon />,
      title: PRODUCT_MODALS.SBL.TITLE,
      subTitle: PRODUCT_MODALS.SBL.SUBTITLE,
      items: [
        {
          icon: <Bolt />,
          title: 'Easy application',
          subTitle: 'Apply in 10 minutes with minimal paperwork.',
        },
        {
          icon: <DollarCycle />,
          title: 'Borrow from $5K up to $150K',
          subTitle: 'Same day decision and fixed repayments for peace of mind.',
        },
        {
          icon: <Calendar />,
          title: 'Flexible repayment terms',
          subTitle:
            'Up to 3 years, with the freedom to make unlimited extra repayments to save on interest and pay off your loan faster.',
        },
        {
          icon: <CrossCircle />,
          title: 'No upfront security',
          subTitle: 'No property security required to access up to $150K in Prospa funding.',
        },
      ],
      type: ProductCVPType.SBL,
      ...(showCapitalProductsButton && {
        primaryButtonProps: {
          onClick: () => {
            trackBAActionEvent('sbl_get_started_click');
            openProductUrl(countryCode, token, DLS_TYPES.CAPITAL, ProductCVPType.SBL, cdpId);
          },
          label: PRODUCT_MODALS.BUTTON_TEXT,
          loading: productsPageDataLoading,
          disabled: productsPageDataLoading,
        },
      }),
      ...(showCapitalProductsAlert && { alertProps }),
    },
    {
      icon: <SBLPlusIcon />,
      title: PRODUCT_MODALS.SBLP.TITLE,
      subTitle: PRODUCT_MODALS.SBLP.SUBTITLE,
      items: [
        {
          icon: <DollarCycle />,
          title: 'Easy application',
          subTitle: 'Get fast approvals on funding up to $500K.',
        },
        {
          icon: <Tune />,
          title: 'Up to 5 year terms',
          subTitle: 'Choose longer terms for lower weekly repayments.',
        },
        {
          icon: <Calendar />,
          title: 'Flexible repayments',
          subTitle:
            'Make unlimited extra repayments to save on interest and pay down your loan faster.',
        },
        {
          icon: <Lock />,
          title: 'Asset security',
          subTitle: 'Security may be required when accessing over $150K in Prospa funding.',
        },
      ],
      type: ProductCVPType.SBLP,
      ...(showCapitalProductsButton && {
        primaryButtonProps: {
          onClick: () => {
            trackBAActionEvent('blp_get_started_click');
            openProductUrl(countryCode, token, DLS_TYPES.CAPITAL, ProductCVPType.SBLP, cdpId);
          },
          label: PRODUCT_MODALS.BUTTON_TEXT,
          loading: productsPageDataLoading,
          disabled: productsPageDataLoading,
        },
      }),
      ...(showCapitalProductsAlert && { alertProps }),
    },
  ];
};
