import { LottieAnimation, Modal } from '@components';

import animationData from '../../../../../assets/animations/brokenLinkAnimation.json';
import styles from './DisconnectIntegrationModal.module.scss';
import type { DisconnectIntegrationModalProps } from './types';

export const DisconnectIntegrationModal = ({
  isOpen,
  onClose,
  primaryButtonProps,
  integrationProduct,
  loading,
}: DisconnectIntegrationModalProps) => (
  <Modal
    size="medium"
    isOpen={isOpen}
    onClose={onClose}
    showCloseModal
    primaryButtonProps={{
      onClick: primaryButtonProps?.onClick,
      label: primaryButtonProps?.label || 'Confirm',
      loading,
      testId: 'disconnect-integration-modal-confirm-button',
    }}
    secondaryButtonProps={{
      onClick: onClose,
      label: 'Cancel',
      disabled: loading,
      testId: 'disconnect-integration-modal-cancel-button',
    }}
    body={
      <div className={styles.DisconnectIntegrationModal} data-testid="disconnect-integration-modal">
        <LottieAnimation animationData={animationData} height={128} width={128} />
        <h4
          className={styles.DisconnectIntegrationModalHeading}
          data-testid="disconnect-integration-modal-heading"
        >
          Disconnect from {integrationProduct}?
        </h4>
        <span className="pds-text mb-4">
          All access between Prospa and {integrationProduct} will be revoked and we’ll no longer
          send or receive data from Xero. You can reconnect at any time.
        </span>
      </div>
    }
  />
);
