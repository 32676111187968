import type { Dispatch, SetStateAction } from 'react';
import { WidgetInput } from 'react-addressfinder';
import { type UseControllerProps, useController, useFormContext } from 'react-hook-form';

import classNames from 'classnames';

import { Question as QuestionMarkIcon } from '@prospa/icons';

import { Modal } from '@components';

import { formatAddressToAddressObject } from '@utils';

import type {
  CardAddress,
  CardSettingsFormData,
} from '../../../../app/containers/Cards/CardSettings/types';
import environments from '../../../environments';
import './AddressUpdateModal.scss';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  handleSubmit: (arg) => void;
  handleBack: () => void;
  addressToSendCard?: CardAddress;
  setAddressToSendCard?: Dispatch<SetStateAction<CardAddress>>;
}

export const AddressUpdateModal = ({
  isOpen,
  toggle,
  handleSubmit,
  handleBack,
  name,
}: Props & UseControllerProps<CardSettingsFormData>) => {
  const formContext = useFormContext();
  const { control } = formContext;
  const {
    field: { value, onChange },
  } = useController({ control, name });

  return (
    <Modal
      hideFooterBorder
      isOpen={isOpen}
      onClose={toggle}
      size="medium"
      showCloseModal
      primaryCTALabel="Update"
      secondaryCTALabel="Back"
      allowSubmit={!!value}
      secondaryCTAOnClick={handleBack}
      primaryCTAOnClick={() => handleSubmit(value)}
      body={
        <div className="address-update-modal">
          <QuestionMarkIcon className="address-update-modal__icon" />
          <h4>Update your address</h4>
          <div className="address-update-modal__widget">
            <WidgetInput
              id="address-widget"
              name="address"
              inputClassName="address-input"
              addressFinderKey={environments.addressFinderKey}
              onSelected={(fullAddress: string) => {
                onChange(formatAddressToAddressObject(fullAddress));
              }}
            />
            <label className={classNames(value && 'filled', 'pds-text')} htmlFor="address">
              Search for your address
            </label>
          </div>
        </div>
      }
    />
  );
};
