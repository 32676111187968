import { type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Formik } from 'formik';

import { useBsbLookupLazyQuery } from '@generated-fg';
import { PaymentType, useVerifyNzAccountNumberLazyQuery } from '@generated-fg';

import { BPayTab, BankTab, Modal, SecurityCodeModal, Tabs } from '@components';

import { locTypes } from '@constants';
import {
  isNZAccount,
  mapCustomPayeeToPayeeV2,
  mapNewPayeeValuesToCustomPayee,
  trackBAActionEvent,
} from '@utils';

import BpayBlackBg from '../../../../assets/BpayBlackBg.svg';
import BpayGreyBg from '../../../../assets/BpayGreyBg.svg';
import {
  validateNewBankAU,
  validateNewBankNZ,
  validateNewBpay,
} from '../../../containers/PayAnyone/PayAnyoneValidators/PayAnyoneValidators';
import { type CustomPayee, type NewPayeeValues, type PayeeV2 } from '../../../models/PayAnyone';
import './AddNewPayeeModal.scss';
import { type AddNewPayeeModalProps, TABINDEX } from './types';
import { useSaveToAddressBookAction } from './useSaveToAddressBookAction';

const AddNewPayeeFooter = ({ children }: { children?: ReactNode }): JSX.Element => {
  if (!children) return null;
  return (
    <div data-testid="add-new-payee-footer-message" className="add-new-payee-modal-footer-message">
      {children}
    </div>
  );
};

const BPayTabHeader = ({ selected }: { selected: boolean }) => (
  <div className="add-new-payee-modal__tab-header">
    {selected ? (
      <img src={BpayBlackBg} alt="bpay-black icon" role="presentation" />
    ) : (
      <img src={BpayGreyBg} alt="bpay-grey icon" role="presentation" />
    )}
    <span className={`add-new-payee-modal${selected ? '__tab-header__selected' : ''}`}>BPAY</span>
  </div>
);

const BankAccountTabHeader = ({ selected }: { selected: boolean }) => (
  <div className="add-new-payee-modal__tab-header">
    <span className={`add-new-payee-modal${selected ? '__tab-header__selected' : ''}`}>
      Bank account
    </span>
  </div>
);

export const AddNewPayeeModal = ({
  isOpen,
  onClose,
  primaryOnClick,
  onError,
  onResend,
  payFromAccount,
  newPayee,
  countryCode,
  payeeType,
  hideSaveToAddressBook,
  footer,
  resetFormBeforeClose = true,
}: AddNewPayeeModalProps) => {
  const selectedTab = useMemo(() => {
    if (newPayee?.accountNumber) {
      return TABINDEX.BANK;
    }
    if (newPayee?.billerCode) {
      return TABINDEX.BPAY;
    }
    return TABINDEX.BANK;
  }, [newPayee]);
  const [currentTab, setCurrentTab] = useState<TABINDEX>(selectedTab);
  const [bsbLookup] = useBsbLookupLazyQuery();
  const [verifyNzAccountNumber] = useVerifyNzAccountNumberLazyQuery();
  const newPayeeRef = useRef(null);

  const isPayFromAccountLoc = locTypes.has(payFromAccount?.productType);

  const isPayeeDetailsPrefilled = useMemo(() => !!newPayee, [newPayee]);

  const headingText = useMemo(
    () => (!isPayeeDetailsPrefilled ? 'Add new payee' : 'Edit payee'),
    [isPayeeDetailsPrefilled],
  );

  const buttonText = useMemo(
    () => (!isPayeeDetailsPrefilled ? 'Add payee' : 'Edit payee'),
    [isPayeeDetailsPrefilled],
  );

  const onSuccess = useCallback(
    (newPayee: PayeeV2, isBankPayee: boolean) => {
      primaryOnClick(newPayee);
      trackBAActionEvent(
        'pay-anyone_contacts-save',
        `payee_type:${isBankPayee ? 'bank account' : 'bpay'}`,
      );
    },
    [primaryOnClick],
  );
  const {
    onSaveToAddressBook,
    handleCloseModal,
    showSecurityCodeModal,
    isSavingPayeeToAddressBook,
    addPayeeToAddressBook,
  } = useSaveToAddressBookAction({
    countryCode,
    onSuccess,
    payeeType,
    onClose,
    onError,
    newPayeeRef,
    payFromAccount,
    currentTab,
  });

  const onSubmit = async () => {
    const { values: newPayeeValues }: { values: NewPayeeValues } = newPayeeRef.current;

    if (newPayeeValues.saveToAddressBook && !hideSaveToAddressBook) {
      addPayeeToAddressBook();
      return;
    }

    const newPayee: CustomPayee = mapNewPayeeValuesToCustomPayee(newPayeeValues, currentTab);
    const newPayeeV2: PayeeV2 = mapCustomPayeeToPayeeV2(newPayee);
    primaryOnClick(newPayeeV2);
    onClose();
  };

  const validateNewPayeeValues = async (newPayeeValues: NewPayeeValues) => {
    const bankTabActive = currentTab === TABINDEX.BANK;
    if (bankTabActive) {
      return isNZAccount(payFromAccount?.id, countryCode)
        ? await validateNewBankNZ(newPayeeValues, verifyNzAccountNumber)
        : await validateNewBankAU(newPayeeValues, bsbLookup);
    }
    return validateNewBpay(newPayeeValues);
  };

  useEffect(() => {
    if (payeeType === PaymentType.Bpay) setCurrentTab(TABINDEX.BPAY);
    if (payeeType === PaymentType.BankTransfer) setCurrentTab(TABINDEX.BANK);
  }, [payeeType]);

  return (
    <Formik
      initialValues={{ ...defaultNewPayeeValues, ...newPayee }}
      validate={validateNewPayeeValues}
      onSubmit={onSubmit}
      isInitialValid
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange={false}
      innerRef={newPayeeRef}
    >
      {({ handleSubmit, resetForm, setFieldValue, setFieldError }) => {
        const BANK_TAB: JSX.Element = (
          <BankTab
            isNZAccount={isNZAccount(payFromAccount?.id, countryCode)}
            hideSaveToAddressBook={hideSaveToAddressBook}
          />
        );

        const BPAY_TAB: JSX.Element = (
          <BPayTab setFieldError={setFieldError} hideSaveToAddressBook={hideSaveToAddressBook} />
        );

        const renderModalBody = () => {
          if (
            payeeType === PaymentType.BankTransfer ||
            isNZAccount(payFromAccount?.id, countryCode) ||
            isPayFromAccountLoc
          )
            return BANK_TAB;
          if (payeeType === PaymentType.Bpay) return BPAY_TAB;

          return (
            <Tabs
              type="secondary-large"
              onTabSelect={index => {
                setCurrentTab(index);
                if (index === TABINDEX.BPAY) setFieldValue('saveToAddressBook', true);
              }}
              tabs={[
                {
                  tabHeader: <BankAccountTabHeader selected={currentTab === TABINDEX.BANK} />,
                  tab: BANK_TAB,
                },
                {
                  tabHeader: <BPayTabHeader selected={currentTab === TABINDEX.BPAY} />,
                  tab: BPAY_TAB,
                },
              ]}
              showTabs={!isPayFromAccountLoc || (payeeType ? false : true)}
              tabIndex={selectedTab}
              testId="add-new-payee-tabs"
            />
          );
        };

        return (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              resetFormBeforeClose && resetForm();
              onClose();
            }}
            showCloseModal
            heading={headingText}
            size="medium"
            primaryButtonProps={{
              loading: isSavingPayeeToAddressBook,
              label: buttonText,
              onClick: () => handleSubmit(),
            }}
            body={
              <div className="add-new-payee-modal">
                {renderModalBody()}
                <SecurityCodeModal
                  loading={isSavingPayeeToAddressBook}
                  isCheck2FAStarted={showSecurityCodeModal}
                  closeModal={() => {
                    handleCloseModal();
                  }}
                  onSuccess={onSaveToAddressBook}
                  onResend={onResend}
                />
              </div>
            }
            footer={<AddNewPayeeFooter>{footer}</AddNewPayeeFooter>}
          />
        );
      }}
    </Formik>
  );
};

const defaultNewPayeeValues: NewPayeeValues = {
  billerCode: '',
  referenceNumber: '',
  nickname: '', // bpay
  accountNumber: '',
  BSB: '',
  accountName: '', // bankaccount
  saveToAddressBook: true,
};
