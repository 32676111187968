import { IceCreamFlop as IcecreamIcon } from '@prospa/salt-illustrations';

import { Modal as ReactModal } from '../Modal';
import styles from './ErrorModal.module.scss';
import type { ErrorModalProps } from './types';

const errorBodyDefault =
  'There seems to be a network issue and we are unable to process your request. Please connect to the internet or try logging in again.';
const errorTitleDefault = 'Whoops, something went wrong';
const confirmMessageDefault = 'OK, thanks';

export const ErrorModal = ({
  isOpen,
  toggle,
  okButtonCallback,
  errorConfig: {
    errorBody = errorBodyDefault,
    errorTitle = errorTitleDefault,
    confirmMessage = confirmMessageDefault,
    errorIcon = <IcecreamIcon />,
  } = {},
}: ErrorModalProps) => (
  <ReactModal
    size="large"
    isOpen={isOpen}
    onClose={toggle}
    showCloseModal
    primaryButtonProps={{
      label: confirmMessage,
      onClick: () => {
        toggle();
        okButtonCallback?.();
      },
    }}
    body={
      <div className={styles.ErrorModal} data-testid="error-modal">
        {errorIcon}
        <h4 className={styles.ErrorModalHeading} data-testid="error-modal-heading">
          {errorTitle}
        </h4>
        <span className={styles.ErrorModalBody} data-testid="error-modal-body">
          {errorBody}
        </span>
      </div>
    }
  />
);
