import { CaptionedImage, ClassicCard, ImageOnly } from '@braze/web-sdk';

import { ClassicCardBanner } from './ClassicCardBanner/ClassicCardBanner';
import { ImageOnlyBanner } from './ImageOnlyBanner/ImageOnlyBanner';
import type { ContentCardBannerProps } from './types';

export const ContentCardBanner = ({ contentCard, ...props }: ContentCardBannerProps) => {
  if (contentCard instanceof ClassicCard) {
    return <ClassicCardBanner {...props} contentCard={contentCard} />;
  }

  if (contentCard instanceof ImageOnly) {
    return <ImageOnlyBanner {...props} contentCard={contentCard} />;
  }

  if (contentCard instanceof CaptionedImage) {
    return null;
  }

  return null;
};
