import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@all-in-one-web/common-ui';

import { type Country, Money } from '@generated-fg';

import { Button, MissedRepaymentModal } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { ProductTypes } from '@models';
import { currencyFormatter, trackBAActionEvent } from '@utils';

import styles from './ArrearsAlert.module.scss';

export type ArrearsAlertProps = {
  showAlert: boolean;
  inArrearsAmount: Money;
  productId: string;
  productType: ProductTypes;
  position?: 'List' | 'Page';
  collectionsPaymentUrl?: string;
  countryCode?: Country;
};

export const ArrearsAlert = (props: ArrearsAlertProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const position = props.position ?? 'List';

  const onLinkClick = useCallback(() => {
    switch (props.productType) {
      case ProductTypes.SBLM:
        trackBAActionEvent('sbl_repayment_take_action_now');
        navigate(`/repayment/sblm/${props.productId}`);
        break;
      case ProductTypes.LOCM:
        trackBAActionEvent('loc_missed_repayment_take_action_now');
        navigate(`/repayment/locm/${props.productId}`);
        break;
      case ProductTypes.SBL:
        trackBAActionEvent('sbl_repayment_take_action_now');
        setIsOpen(true);
        break;
      case ProductTypes.LOC:
        trackBAActionEvent('loc_missed_repayment_take_action_now');
        setIsOpen(true);
        break;
    }
  }, [props, setIsOpen]);

  const onClose = useCallback(() => setIsOpen(false), []);

  if (!props.showAlert) {
    return null;
  }

  const formattedInArrearsAmount = currencyFormatter(props.inArrearsAmount.amount);

  return (
    <Alert className={`${styles.ArrearsAlert} ${styles[position]}`} alertType="Danger">
      <div className={styles.ArrearsAlertFullBody}>
        <span className={styles.ArrearsAlertDescription}>
          Looks like you missed one or more repayments of {formattedInArrearsAmount}. Late fees may
          be accruing.
        </span>
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Button
            type={ButtonType.ICON_LINK_UNDERLINE}
            size={ButtonSize.SMALL}
            onClick={onLinkClick}
            className={styles.ArrearsAlertCTA}
          >
            Get back on track
          </Button>
          <MissedRepaymentModal
            isOpen={isOpen}
            onClose={onClose}
            inArrearsAmount={props.inArrearsAmount}
            collectionsPaymentUrl={props.collectionsPaymentUrl}
            countryCode={props.countryCode}
          />
        </div>
      </div>
    </Alert>
  );
};
