import { useMemo } from 'react';

import classNames from 'classnames';

import { Status } from '@all-in-one-web/common-ui';
import { WebOpenTab as ExportIcon } from '@prospa/icons';

import { LineOfCreditStatus } from '@generated-fg';

import { ProductTypes } from '@models';
import { currencyFormatter } from '@utils';

import styles from './AccountList.module.scss';
import type {
  AccountItemLabelValuePair,
  AccountItemRightColumnProps,
  AccountListItemProps,
} from './types';

const AccountItemRightColumn = ({ labelValuePairs }: AccountItemRightColumnProps) => {
  return (
    <div className={`${styles.AccountListItemColumnRight} ${styles.AccountListItemColumn}`}>
      {labelValuePairs.map(({ label, value }, index) => (
        <p className={styles.AccountListItemColumnRightRow} key={label}>
          <span className={styles.AccountListItemLabel}>{label}</span>
          <span
            className={classNames(
              styles.AccountListItemValue,
              index === 0 ? styles.AccountListItemValueBold : '',
            )}
          >
            {currencyFormatter(value)}
          </span>
        </p>
      ))}
    </div>
  );
};

const AccountListItem = ({
  productType,
  title,
  subTitle,
  onClick,
  accountStatus: displayStatus,
  isExternalLink = false,
  children,
  available,
  balance,
  status,
  rightColumnProps,
  className,
}: AccountListItemProps) => {
  const { labelValuePairs: customLabelValuePairs } = rightColumnProps || {};

  const labelValuePairs: Array<AccountItemLabelValuePair> = useMemo(() => {
    if (customLabelValuePairs) return customLabelValuePairs;

    const newLabelValuePairs = [];
    const isProductBa = productType === ProductTypes.BA;
    const isProductLoc = productType === ProductTypes.LOC || productType === ProductTypes.LOCM;
    const isLocAndHasUsableBalance =
      isProductLoc &&
      status !== LineOfCreditStatus.PaymentPlan &&
      status !== LineOfCreditStatus.WrittenOff;

    if (isProductBa || isLocAndHasUsableBalance) {
      newLabelValuePairs.push({ label: 'Available', value: available });
      newLabelValuePairs.push({ label: 'Balance', value: balance });
    } else {
      newLabelValuePairs.push({ label: 'Outstanding', value: balance });
    }

    return newLabelValuePairs;
  }, [available, balance, customLabelValuePairs, productType, status]);

  return (
    <div
      className={classNames(
        styles.AccountListItem,
        onClick ? styles.AccountListItemClickable : '',
        isExternalLink ? styles.AccountListItemWithExternalLink : '',
        className,
      )}
      onClick={onClick}
      role="button"
    >
      <div className={`${styles.AccountListItemColumnLeft} ${styles.AccountListItemColumn}`}>
        <div
          className={classNames(
            styles.AccountListItemTitleStatus,
            isExternalLink ? styles.AccountListItemTitleStatusWithExternalLink : '',
          )}
        >
          {/* badge for business account status will be covered on AVA-480 */}
          <p className="pds-lead">{title}</p>
          {displayStatus && productType !== ProductTypes.BA && (
            <Status
              label={displayStatus}
              level={displayStatus === 'Suspended' ? 'warning' : 'primary'}
            />
          )}
        </div>
        <span className={styles.AccountListItemSubTitle}>{subTitle}</span>
      </div>
      <AccountItemRightColumn labelValuePairs={labelValuePairs} />
      {isExternalLink && (
        <div className={`${styles.AccountListItemColumnIcon} ${styles.AccountListItemColumn}`}>
          <ExportIcon width={28} height={28} />
        </div>
      )}
      {children}
    </div>
  );
};

export default AccountListItem;
