import { forwardRef } from 'react';

import { Field, Formik, type FormikErrors } from 'formik';
import noop from 'lodash/noop';

import { Button, Card, CurrencyInput, Select, Tabs } from '@components';

import { ButtonType } from '@constants';

import {
  type BusinessLoanRepaymentCalculatorFormValues,
  type LineOfCreditRepaymentCalculatorFormValues,
  type RepaymentCalculatorFormRef,
  type RepaymentCalculatorFormValues,
  type RepaymentCalculatorTab,
} from '../../containers/RepaymentCalculator/types';
import { type Option } from '../Formik/Select/types';
import styles from './RepaymentCalculatorForm.module.scss';

const defaultBusinessLoanRepaymentCalculatorFormValues: BusinessLoanRepaymentCalculatorFormValues =
  {
    amount: 30000,
    loanTermYears: 2,
    fundingPurpose: undefined,
    industry: undefined,
  };

const defaultLineOfCreditRepaymentCalculatorFormValues: LineOfCreditRepaymentCalculatorFormValues =
  {
    amount: 30000,
    loanTermYears: 2,
    fundingPurpose: undefined,
    industry: undefined,
    expectedDrawdownAmount: 5000,
  };

const MIN_LOAN_TERM_YEARS = 1;
const MAX_LOAN_TERM_YEARS = 5;

const MINIMUM_LOAN_AMOUNT = 5000;
const MAXIMUM_LOAN_AMOUNT = 500000;

const MAXIMUM_SHORT_TERM_LOAN_AMOUNT = 150000;

const MAXIMUM_SMALL_BUSINESS_LOAN_YEARS = 3;
const MINIMUM_BUSINESS_LOAN_YEARS = 4;

const LOAN_TERM_YEARS_OPTIONS: { label: string; value: number }[] = Array.from(
  { length: MAX_LOAN_TERM_YEARS - MIN_LOAN_TERM_YEARS + 1 },
  (_, i) => i + MIN_LOAN_TERM_YEARS,
).map(year => ({
  label: `${year} year${year > 1 ? 's' : ''}`,
  value: year,
}));

const FUNDING_PURPOSE_OPTIONS: Option[] = [
  { label: 'Hire or purchase equipment', value: 'Hire or purchase equipment' },
  { label: 'Grow or expand my business', value: 'Grow or expand my business' },
  { label: 'Pay suppliers', value: 'Pay suppliers' },
  { label: 'Purchasing inventory', value: 'Purchasing inventory' },
  { label: 'Renovation', value: 'Renovation' },
  { label: 'Working capital', value: 'Working capital' },
  { label: 'Pay off non-tax debt', value: 'Pay off non-tax debt' },
  { label: 'Pay off tax debt', value: 'Pay off tax debt' },
  { label: 'Other', value: 'Other' },
];

const INDUSTRY_OPTIONS: Option[] = [
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Building and Trade', value: 'Building and Trade' },
  { label: 'Health and Beauty', value: 'Health and Beauty' },
  { label: 'Partner/Professional services', value: 'Partner/Professional services' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Retail & Wholesale', value: 'Retail & Wholesale' },
  { label: 'Other', value: 'Other' },
];

const validateBusinessLoanForm = (values: BusinessLoanRepaymentCalculatorFormValues) => {
  const amount = Number(values.amount);
  const loanTermYears = Number(values.loanTermYears);
  const errors = {} as FormikErrors<BusinessLoanRepaymentCalculatorFormValues>;

  if (
    loanTermYears <= MAXIMUM_SMALL_BUSINESS_LOAN_YEARS &&
    (amount < MINIMUM_LOAN_AMOUNT || amount > MAXIMUM_LOAN_AMOUNT)
  ) {
    errors.amount = 'Borrowing amount must be between $5,000 and $500,000.';
  }

  if (
    loanTermYears >= MINIMUM_BUSINESS_LOAN_YEARS &&
    (amount < MAXIMUM_SHORT_TERM_LOAN_AMOUNT || amount > MAXIMUM_LOAN_AMOUNT)
  ) {
    errors.amount =
      'Currently, loan terms over 3 years are only available for amounts between $150,000 and $500,000.';
  }

  return errors;
};

const validateLineOfCreditForm = (values: LineOfCreditRepaymentCalculatorFormValues) => {
  const amount = Number(values.amount);
  const loanTermYears = Number(values.loanTermYears);
  const expectedDrawdownAmount = Number(values.expectedDrawdownAmount);
  const errors = {} as FormikErrors<LineOfCreditRepaymentCalculatorFormValues>;

  if (
    loanTermYears <= MAXIMUM_SMALL_BUSINESS_LOAN_YEARS &&
    (amount < MINIMUM_LOAN_AMOUNT || amount > MAXIMUM_LOAN_AMOUNT)
  ) {
    errors.amount = 'Borrowing amount must be between $5,000 and $500,000.';
  }

  if (
    loanTermYears >= MINIMUM_BUSINESS_LOAN_YEARS &&
    (amount < MAXIMUM_SHORT_TERM_LOAN_AMOUNT || amount > MAXIMUM_LOAN_AMOUNT)
  ) {
    errors.amount =
      'Currently, loan terms over 3 years are only available for amounts between $150,000 and $500,000.';
  }

  if (expectedDrawdownAmount > amount) {
    errors.expectedDrawdownAmount =
      'Drawdown amount must be less than the total amount of the Line of Credit.';
  }

  if (expectedDrawdownAmount <= 0) {
    errors.expectedDrawdownAmount = 'Drawdown amount must be greater than 0.';
  }

  return errors;
};

const BusinessLoanRepaymentCalculatorForm = forwardRef<RepaymentCalculatorFormRef>(
  (__props, ref) => {
    return (
      <Formik<RepaymentCalculatorFormValues>
        initialValues={defaultBusinessLoanRepaymentCalculatorFormValues}
        onSubmit={noop}
        validate={validateBusinessLoanForm}
        innerRef={ref}
        validateOnChange
      >
        {({ setFieldValue }) => (
          <div
            className={styles.RepaymentCalculatorFormGrid}
            data-testid="sbl-repayment-calculator-form"
          >
            <Field
              component={CurrencyInput}
              name="amount"
              label="Amount"
              key="amount"
              type="number"
              maxLength={6}
            />
            <div
              className={styles.RepaymentCalculatorFormGridDropdown}
              data-testid="loan-term-years"
            >
              <Field
                component={Select}
                name="loanTermYears"
                label="Duration of fund access"
                key="loanTermYears"
                type="text"
                options={LOAN_TERM_YEARS_OPTIONS}
                setFieldValue={value => {
                  setFieldValue('loanTermYears', value);
                }}
                className="loanTermYears"
              />
            </div>
            <div className={styles.RepaymentCalculatorFormGridDropdown}>
              <Field
                component={Select}
                name="fundingPurpose"
                label="Funding purpose"
                key="fundingPurpose"
                type="text"
                options={FUNDING_PURPOSE_OPTIONS}
                setFieldValue={value => {
                  setFieldValue('fundingPurpose', value);
                }}
              />
            </div>
            <div className={styles.RepaymentCalculatorFormGridDropdown}>
              <Field
                component={Select}
                name="industry"
                label="Industry"
                key="industry"
                type="text"
                options={INDUSTRY_OPTIONS}
                setFieldValue={value => {
                  setFieldValue('industry', value);
                }}
              />
            </div>
          </div>
        )}
      </Formik>
    );
  },
);

const LineOfCreditRepaymentCalculatorForm = forwardRef<RepaymentCalculatorFormRef>(
  (__props, ref) => {
    return (
      <Formik<RepaymentCalculatorFormValues>
        initialValues={defaultLineOfCreditRepaymentCalculatorFormValues}
        onSubmit={noop}
        validate={validateLineOfCreditForm}
        innerRef={ref}
        validateOnChange
      >
        {({ setFieldValue }) => (
          <div
            className={styles.RepaymentCalculatorFormGrid}
            data-testid="loc-repayment-calculator-form"
          >
            <Field
              component={CurrencyInput}
              name="amount"
              label="Amount"
              key="amount"
              type="number"
              maxLength={6}
            />
            <div
              className={styles.RepaymentCalculatorFormGridDropdown}
              data-testid="loan-term-years"
            >
              <Field
                component={Select}
                name="loanTermYears"
                label="Duration of fund access"
                key="loanTermYears"
                type="text"
                options={LOAN_TERM_YEARS_OPTIONS}
                setFieldValue={value => {
                  setFieldValue('loanTermYears', value);
                }}
                className="loanTermYears"
                message="Max 2 years, with option to extend"
                disabled
              />
            </div>
            <div className={styles.RepaymentCalculatorFormGridDropdown}>
              <Field
                component={Select}
                name="fundingPurpose"
                label="Funding purpose"
                key="fundingPurpose"
                type="text"
                options={FUNDING_PURPOSE_OPTIONS}
                setFieldValue={value => {
                  setFieldValue('fundingPurpose', value);
                }}
              />
            </div>
            <div className={styles.RepaymentCalculatorFormGridDropdown}>
              <Field
                component={Select}
                name="industry"
                label="Industry"
                key="industry"
                type="text"
                options={INDUSTRY_OPTIONS}
                setFieldValue={value => {
                  setFieldValue('industry', value);
                }}
              />
            </div>
            <Field
              component={CurrencyInput}
              name="expectedDrawdownAmount"
              label="How much do you expect to drawdown?"
              key="expectedDrawdownAmount"
              type="number"
              maxLength={6}
            />
          </div>
        )}
      </Formik>
    );
  },
);

type RepaymentCalculatorFormProps = {
  tab: RepaymentCalculatorTab;
  loading: boolean;
  onTabSelect: (tabIndex: number) => void;
  onCalculateClick: () => void;
};

export const RepaymentCalculatorForm = forwardRef<
  RepaymentCalculatorFormRef,
  RepaymentCalculatorFormProps
>(({ tab, loading, onTabSelect, onCalculateClick }: RepaymentCalculatorFormProps, ref) => {
  return (
    <Card>
      <Tabs
        tabs={[
          {
            tabHeader: 'Business Loan',
            tab: <BusinessLoanRepaymentCalculatorForm ref={ref} />,
          },
          {
            tabHeader: `Line of Credit`,
            tab: <LineOfCreditRepaymentCalculatorForm ref={ref} />,
          },
        ]}
        type="primary-fitted"
        tabIndex={tab}
        onTabSelect={onTabSelect}
      />
      <Button
        type={ButtonType.PRIMARY}
        onClick={onCalculateClick}
        className={styles.RepaymentCalculatorCalculateButton}
        loading={loading}
        disabled={loading}
        label={loading ? null : 'Calculate'}
        data-testid="calculate-button"
      />
    </Card>
  );
});
