import classNames from 'classnames';

import styles from './StarRatings.module.scss';
import type { TStarRatings } from './types';

export const StarRatings = ({ numStars, rating, className = '' }: TStarRatings) => {
  const stars: number[] = [];
  const filledStars = Math.floor(rating);

  for (let i = 0; i < filledStars; i++) stars.push(1);
  if (rating < numStars) {
    const preciseStar = rating - filledStars;
    stars.push(preciseStar);
    const emptyStars = numStars - stars.length;
    for (let i = 0; i < emptyStars; i++) stars.push(0);
  }

  return (
    <div className={classNames(styles.StarRatings, className)}>
      {stars.map((value, index) => {
        return (
          <span key={index} style={{ clipPath: `inset(0% ${100 - value * 100}% 0% 0%)` }}>
            ★
          </span>
        );
      })}
    </div>
  );
};
