import { type FormikContextType, connect, getIn } from 'formik';

interface Props {
  formik?: FormikContextType<unknown>;
}

const ErrorMessage = ({ formik: { errors, touched } }: Props) => {
  const fromError = getIn(errors, 'fromDate');
  const toError = getIn(errors, 'toDate');
  const fromTouch = getIn(touched, 'fromDate');
  const toTouch = getIn(touched, 'toDate');

  let errorMessage = '';

  if (fromError && toError && fromTouch && toTouch) {
    errorMessage = 'Please enter or select a start and an end date';
  } else if (fromError && fromTouch) {
    errorMessage = fromError;
  } else if (toError && toTouch) {
    errorMessage = toError;
  }

  return errorMessage ? (
    <p className="custom-statement__error pds-caption">{errorMessage}</p>
  ) : null;
};

export const Errors = connect(ErrorMessage);
