import { MicroilloNotfound } from '@prospa/icons';

import { TimerCountdown } from '@components';

import { Modal } from '../Modal';
import './TimeoutModal.scss';

interface Props {
  isOpen: boolean;
  countDownStartMinutes: number;
  countDownStartSeconds?: number;
  onClose: () => void;
  handleRenew: () => void;
  logout: () => void;
}

export const TimeoutModal = ({
  isOpen,
  countDownStartMinutes,
  countDownStartSeconds = 0,
  onClose,
  handleRenew,
  logout,
}: Props) => {
  return (
    <Modal
      // show timeout modal above other modals
      customStyle={{ overlay: { zIndex: 999 } }}
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      size="medium"
      body={
        <div className="timeout-modal">
          <MicroilloNotfound className="timeout-modal__icon" />
          <h3 className="timeout-modal__title h5">You're about to be logged out</h3>
          <p className="timeout-modal__text">
            Your session will expire in{' '}
            <TimerCountdown
              onCountdownEnd={logout}
              initialMinute={countDownStartMinutes}
              initialSeconds={countDownStartSeconds}
            />{' '}
            minutes for security reasons. Please select the 'Stay logged in' button to extend your
            session.
          </p>
        </div>
      }
      primaryCTALabel="Stay logged in"
      primaryCTAOnClick={handleRenew}
    />
  );
};
