import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import classNames from 'classnames';

import { Copy as CopyIcon } from '@prospa/salt-icons';

import styles from './CopyText.module.scss';
import type { CopyTextProps } from './types';

export const CopyText = ({ toCopy, onCopy, className }: CopyTextProps) => {
  const [copyText, setCopyText] = useState('Copy');

  const handleCopy = () => {
    setCopyText('Copied!');
    setTimeout(() => {
      setCopyText('Copy');
    }, 1800);

    if (onCopy) {
      onCopy();
    }
  };

  return (
    <div data-testid="copy-text" className={classNames(styles.CopyText, className)}>
      <CopyToClipboard
        text={toCopy}
        onCopy={handleCopy}
        options={{
          format: 'text/plain',
        }}
        data-testid={copyText}
      >
        <CopyIcon />
      </CopyToClipboard>
    </div>
  );
};
