import { type FC, useEffect, useState } from 'react';

import { type FieldProps, useFormikContext } from 'formik';

import { useBsbLookupLazyQuery } from '@generated-fg';

import { Input } from '@components';

import { validationConfig } from '@utils';

import { type NewPayeeValues } from '../../../models/PayAnyone';
import './BSB.scss';

interface Props {
  label: string;
  type: 'number' | 'text' | 'email' | 'password' | 'tel';
  formatValue: (value: string) => string;
  errorMessage?: string;
}

const MAX_BSB_LENGTH = 6;

export const BSB: FC<FieldProps & Props> = ({ label, field, errorMessage, ...props }) => {
  const { setFieldValue, setFieldError } = useFormikContext<NewPayeeValues>();

  const [bsbLookup, { data }] = useBsbLookupLazyQuery();

  const [showBsbLabel, setShowBsbLabel] = useState<boolean>(false);
  const { value } = field;

  const bsbLabel = `${data?.bsb?.financialInstitutionCode} ${data?.bsb?.city}`;

  useEffect(() => {
    if ((value as string).includes('-') || (value as string).length > 6) {
      const newValue = value.replace('-', '');
      setFieldValue('BSB', newValue.substring(0, MAX_BSB_LENGTH));
    }
  }, [setFieldValue, value]);

  const _onBlur = event => {
    if (validationConfig.sixDigitNumber.pattern.test(value)) {
      bsbLookup({
        variables: { bsbNumber: value },
      })
        .then(res => {
          if (!res.data.bsb) {
            setFieldError(field.name, errorMessage);
            return;
          }
          setShowBsbLabel(true);
        })
        .catch(() => {
          setShowBsbLabel(false);
        });
    } else {
      setShowBsbLabel(false);
    }
    if (field.onBlur) {
      field.onBlur(event);
    }
  };
  return (
    <Input
      {...props}
      field={{ ...field, onBlur: _onBlur }}
      label={showBsbLabel && data?.bsb ? bsbLabel : label}
      clearErrorOnChange
    />
  );
};
