import classnames from 'classnames';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import './DashboardButtons.scss';
import type { DashboardButtonsProps } from './types';

export const DashboardButtons = ({
  isFixed = false,
  buttonOptions = [],
  className = '',
}: DashboardButtonsProps) => {
  if (buttonOptions.length === 0) {
    return null;
  }
  const mobileFlexGrow = buttonOptions.length > 2;

  return (
    <div
      data-testid="dashboard-buttons"
      className={classnames(
        isFixed ? 'dashboard-buttons-fixed' : 'dashboard-buttons',
        mobileFlexGrow ? 'dashboard-buttons__mobile-flex-grow' : '',
        className,
      )}
    >
      {buttonOptions.map(({ label, icon: Icon, ...others }) => {
        return (
          <Button
            key={label}
            className="dashboard-buttons__button"
            type={ButtonType.SECONDARY}
            size={ButtonSize.MEDIUM}
            label={label}
            {...others}
          >
            <Icon />
          </Button>
        );
      })}
    </div>
  );
};
