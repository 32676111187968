import { type TestGroupConfig, TestGroups } from './types';

const billCVPModalImages = {
  mobile: 'https://static.prospa.com/all-in-one-portal/new-bill-pay-feature-mobile.gif',
  desktop: 'https://static.prospa.com/all-in-one-portal/new-bill-pay-feature-desktop.gif',
};

export const config: Record<TestGroups, TestGroupConfig> = {
  [TestGroups.A]: {
    group: TestGroups.A,
    getStartedBanner: {
      title: 'Automate your bills admin',
      description: 'We’ll scan your bill details so you can schedule payments with ease',
    },
    valuePropositionPage: {
      title: 'Effortlessly automate your bills admin',
      description:
        'Simply upload bills via email and we’ll automatically scan the details to save you time',
      pointOne: 'View, schedule and pay for upcoming bills all in one place',
      pointTwo: 'Flexibility to make payments now or schedule them for later',
      pointThree: 'Get payment notifications and insights about your bill expenses',
    },
    billCVPModal: {
      title: 'Effortlessly automate your bills admin',
      description:
        'Forward your bill emails and we’ll automatically scan the details to save you time on admin. Once confirmed, you’ll have the flexibility to pay bills now or schedule them for later.',
      images: billCVPModalImages,
    },
  },
  [TestGroups.B]: {
    group: TestGroups.B,
    getStartedBanner: {
      title: 'Manage all your bills in one place',
      description: 'Schedule, pay and keep track of your upcoming and paid bills',
    },
    valuePropositionPage: {
      title: 'Keep track of your bills in one simple place',
      description: 'Simply upload bills via email to schedule, pay and manage them all together',
      pointOne: 'Automated bill scanning saves you time on admin',
      pointTwo: 'Flexibility to make payments now or schedule them for later', // the same as ATest
      pointThree: 'Get payment notifications and insights about your bill expenses', // the same as ATest
    },
    billCVPModal: {
      title: 'Keep track of your bills in one simple place',
      description:
        'Forward your bill emails to schedule, pay and track all your upcoming and paid bills. We’ll automatically scan the details, so all you need to do is confirm the bills and schedule payment. ',
      images: billCVPModalImages,
    },
  },
};
