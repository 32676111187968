import classNames from 'classnames';

import styles from './Section.module.scss';
import { type SectionProps } from './types';

export const Section = ({ children, className, ...restprops }: SectionProps) => {
  return (
    <section className={classNames(styles.Section, className)} {...restprops}>
      {children}
    </section>
  );
};
