import classNames from 'classnames';

import { ProgressBar } from '@components';

import { RepaymentProgressLoader } from '../BlockLoader';
import styles from './RepaymentProgress.module.scss';
import type { RepaymentProgressProps } from './types';

export const RepaymentProgress = ({
  repaymentLoading,
  percentage,
  leftLabel,
  leftSubLabel,
  rightLabel,
  className = '',
}: RepaymentProgressProps) => {
  if (repaymentLoading) {
    return <RepaymentProgressLoader />;
  }
  return (
    <div
      className={classNames(styles.RepaymentProgress, className)}
      data-testid="repayment-progress"
    >
      <div className={styles.LabelContainer}>
        <div className={styles.LabelContainerLeftSide}>
          {leftLabel && (
            <>
              <p className={styles.LabelTitle}>{leftLabel.title}</p>
              <p className={styles.LabelValue}>{leftLabel.value}</p>
            </>
          )}
          {leftSubLabel && <p>{leftSubLabel}</p>}
        </div>
        {rightLabel && (
          <div className={styles.LabelContainerRightSide}>
            <p className={styles.LabelTitle}>{rightLabel.title}</p>
            <p className={styles.LabelValue}>{rightLabel.value}</p>
          </div>
        )}
      </div>
      <ProgressBar className={styles.ProgressBar} percentage={percentage} />
    </div>
  );
};
