import classNames from 'classnames';

import { Colors } from '../../constants';
import styles from './Badge.module.scss';
import type { BadgeProps } from './types';

export function Badge({ children, color = 'primary', className, ...props }: BadgeProps) {
  return (
    <span className={classNames(styles.Badge, styles[Colors[color]], className)} {...props}>
      {children}
    </span>
  );
}

export default Badge;
