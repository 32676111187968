import { type GetLocPaymentsAndTransactionsQuery } from '@generated-mg';

import { isAULOCMambu, isLOCMambu, isNZLOCMambu } from '@utils';

import { FeesItem } from '../FeesItem/FeesItem';

export const TransactionDisbursalDetails = ({
  lineActivity,
  locId,
}: {
  lineActivity: GetLocPaymentsAndTransactionsQuery['creditLine']['transactions']['itemList'][number]['data'];
  locId: string;
}) => {
  if (!lineActivity || !lineActivity.bankAccount?.isPayAnyone) {
    return null;
  }

  if (isLOCMambu(locId)) {
    return (
      <ul className="mt-3" key={`${lineActivity.id}-disbursalDetails`}>
        <FeesItem
          label={lineActivity.bankAccount?.bankName || 'Bank information not available'}
          key={`${lineActivity.id}-bankName`}
        />
        {isNZLOCMambu(locId) && (
          <FeesItem
            label={lineActivity.bankAccount?.bankAccountNumber}
            key={`${lineActivity.id}-bankAccountNumber`}
          />
        )}
        {isAULOCMambu(locId) && (
          <FeesItem
            label={`${lineActivity.bankAccount?.bSB} ${lineActivity.bankAccount?.bankAccountNumber}`}
            key={`${lineActivity.id}-BSB-bankAccountNumber`}
          />
        )}
        <FeesItem
          label={lineActivity.payAnyoneReference}
          key={`${lineActivity.id}-payAnyoneReference`}
        />
      </ul>
    );
  }
  return (
    <ul className="mt-3" key={`${lineActivity.id}-disbursalDetails`}>
      <FeesItem
        label={lineActivity.bankAccount?.bankName || 'Bank information not available'}
        key={`${lineActivity.id}-bankName`}
      />
      <FeesItem
        label={lineActivity.bankAccount?.bankAccountNumber}
        key={`${lineActivity.id}-bankAccountNumber`}
      />
    </ul>
  );
};
