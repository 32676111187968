import { useLocation, useNavigate } from 'react-router-dom';

import {
  BackArrow,
  MoneyCashCoin as DrawdownIcon,
  AddFunds as PlusIcon,
  Statement as StatementIcon,
} from '@prospa/icons';

import type { ButtonOption } from '@components/types';

import { trackBAActionEvent } from '@utils';

import type { CustomerProducts } from '../models/AppData';

const RightArrowIcon = () => <BackArrow style={{ transform: 'rotate(180deg)' }} />;

type TUseDashboardButtonOptionsProps = {
  customerProducts: CustomerProducts;
  showPay?: boolean;
  showDrawdown?: boolean;
  showStatements?: boolean;
};

type TUseDashboardButtonOptions = (props: TUseDashboardButtonOptionsProps) => ButtonOption[];

export const useDashboardButtonOptions: TUseDashboardButtonOptions = ({
  customerProducts: { BA = false, LOC = false, SBL = false, SBLM = false },
  showStatements = false,
  showDrawdown = false,
  showPay = true,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const buttons = [
    ...(BA || (LOC && showPay)
      ? [
          {
            label: 'Pay',
            onClick: () => {
              navigate('/pay');
              trackBAActionEvent('pay-anyone_select', `pay_anyone-select_type:button`);
            },
            icon: RightArrowIcon,
          },
        ]
      : []),
    ...(showDrawdown
      ? [
          {
            label: 'Drawdown',
            onClick: () => {
              navigate('/drawdown');
              trackBAActionEvent('drawdown_button', `drawdown-select_type:button`);
            },
            icon: DrawdownIcon,
          },
        ]
      : []),
    ...(BA
      ? [
          {
            label: 'Add funds',
            onClick: () => {
              navigate('/add-funds');
              trackBAActionEvent('funds_add');
            },
            icon: PlusIcon,
          },
        ]
      : []),
    ...(showStatements || ((SBL || SBLM) && !LOC && !BA) || (!BA && !showDrawdown)
      ? [
          {
            label: 'Statements',
            onClick: () => {
              navigate(`/statements${search}`);
              trackBAActionEvent('bank_statements');
            },
            icon: StatementIcon,
          },
        ]
      : []),
  ];
  return buttons;
};
