import { type FC } from 'react';

import { LogOut as LogoutIcon } from '@prospa/icons';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import './TemporaryLogoutButton.scss';

export const TemporaryLogoutButton: FC<{ showLabel?: boolean; onClick: () => void }> = ({
  showLabel = false,
  onClick,
}) => (
  <Button
    className={showLabel ? 'logout-button' : 'logout-button-minimal'}
    label={showLabel ? 'Log out' : ''}
    type={ButtonType.LINK}
    size={ButtonSize.MEDIUM}
    onClick={onClick}
  >
    <LogoutIcon width={28} height={28} />
  </Button>
);
