import classNames from 'classnames';

import { Alert, PhoneNumber } from '@all-in-one-web/common-ui';

import { BusinessAccountStatus } from '@generated-fg';

import { businessAccountContactNumber, businessTime, freezeAccountMessage } from '@constants';

import styles from './FreezeAccountAlert.module.scss';
import type { FreezeAccountAlertProps } from './types';

function FreezeAccountAlert({ accountStatus, className }: FreezeAccountAlertProps) {
  if (accountStatus !== BusinessAccountStatus.Frozen) {
    return null;
  }

  return (
    <Alert className={classNames(styles.FreezeAccountAlert, className)} alertType="Danger">
      <span>
        {freezeAccountMessage} Call <PhoneNumber phone={businessAccountContactNumber} />{' '}
        {businessTime}
      </span>
    </Alert>
  );
}

export default FreezeAccountAlert;
