import { MailDocument } from '@prospa/salt-illustrations';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { externalRedirect } from '@utils';

import styles from './Contract.module.scss';
import { type WaitingForOthersToSignContractProps } from './types';

export const WaitingForOthersToSignContract = ({
  showButton = true,
}: WaitingForOthersToSignContractProps) => {
  return (
    <div className={styles.Contract} data-testid="waiting-for-others-to-sign-contract">
      <MailDocument className={styles.ContractIllustration} />
      <h2>Agreement received</h2>
      <p className={styles.ContractSubtitle}>
        Thank you for signing the agreement. <br /> We require another director/shareholder to
        review and sign the agreement before this facility can be accessed. <br /> The other party
        has been notified via email.
      </p>
      {showButton && (
        <Button
          className={styles.ContractConfirmButton}
          size={ButtonSize.MEDIUM}
          type={ButtonType.PRIMARY}
          label="Return to dashboard"
          onClick={() => externalRedirect({ url: '/', openInNewTab: false })}
        />
      )}
    </div>
  );
};
