import { Country } from '@generated-fg';

import { ArrearsAlert } from '@components';

import { AccountLoader } from '../../BlockLoader';
import styles from '../AccountList.module.scss';
import AccountListItem from '../AccountListItem';
import { type ProductListProps, type SblAccountDetail } from '../types';

export function SmallBusinessLoanList({
  list = [],
  loading = true,
  countryCode = '',
}: ProductListProps<SblAccountDetail>): JSX.Element {
  /* NOTE: sblm and sbl accounts combined together in loans */
  return loading ? (
    <AccountLoader className={`${styles.AccountListItem} ${styles.Skeleton}`} />
  ) : (
    <>
      {list.map(({ accountDetail: sbl, onClick }) => {
        return (
          <AccountListItem
            id={sbl.id}
            key={sbl.id}
            title={sbl.productName}
            balance={sbl.balance}
            onClick={onClick}
            productType={sbl.productType}
          >
            <ArrearsAlert
              showAlert={sbl.isInArrears}
              inArrearsAmount={sbl.inArrearsAmount}
              productType={sbl.productType}
              productId={sbl.id}
              collectionsPaymentUrl={sbl.collectionsPaymentUrl}
              countryCode={countryCode === 'AU' ? Country.Au : Country.Nz}
            />
          </AccountListItem>
        );
      })}
    </>
  );
}
