import { differenceInDays, format, formatDistanceStrict, isSameDay, parse } from 'date-fns';

import { ADULT_AGE } from '@constants';

type Unit = 'day' | 'second' | 'minute' | 'hour' | 'month' | 'year';

export const getDateDistanceTextInDays = (date: string, addSuffix = true, unit: Unit = 'day') => {
  if (!date) {
    return;
  }

  const today = new Date();
  const nextDate = new Date(date);
  const isDueDateToday = differenceInDays(nextDate, today) === 0;

  if (isDueDateToday) {
    return 'Today';
  }

  return formatDistanceStrict(nextDate, today, { unit, addSuffix });
};

export const getDaysBetweenTodayAndNextDebitDate = (date: string) => {
  if (!date) {
    return;
  }

  const today = new Date();
  const nextDate = new Date(date);
  today.setHours(0, 0, 0, 0);
  nextDate.setHours(0, 0, 0, 0);

  if (isSameDay(today, nextDate)) {
    return 'Today';
  }

  return formatDistanceStrict(nextDate, today, { unit: 'day', addSuffix: true });
};

export const formatDateInWeekDayMonth = (date: string) => {
  return format(new Date(date), 'eee, dd MMM');
};

export const formatDateInDayMonthYear = (date: string) => {
  return format(new Date(date), 'dd MMM, yyyy');
};

export const formatDateInSpaceSplitDayMonthYear = (date: string) => {
  return isValidDateStr(date) ? format(new Date(date), 'dd MMM yyyy') : '';
};

export const formatDateInWeekDayMonthYearTime = (date: string) => {
  return format(new Date(date), 'eee, dd MMM yyyy h:mm aaa');
};

export const formatDateInDayMonthYearTime = (date: string) => {
  return format(new Date(date), 'dd MMM yyyy HH:mmaaa');
};

export const convertToMonth = (dateString: string) =>
  new Date(dateString).toLocaleString('en', { month: 'long', year: 'numeric' });

export const convertToIsoDate = (utcDateString: string) => {
  return format(Date.parse(utcDateString), 'yyyy-MM-dd HH:mm:ss');
};

export const formatDateInMonthYear = (date: string) => {
  return isValidDateStr(date) ? format(new Date(date), 'dd MMM') : '';
};

export const isValidDateStr = (date: string): boolean => {
  return !!date && !!Date.parse(date);
};

export const formatDateInFullWeekDayMonth = (date: string) => {
  return format(new Date(date), 'eeee, dd MMM HH:mm');
};

export const formatDateInFullWeekDayMonthYear = (date: string) => {
  return format(new Date(date), 'eeee, dd MMM yyyy HH:mm');
};

export const isNowInATimePeriod = (startAt: string, endAt: string) => {
  const startUTCTime = new Date(startAt).getTime();
  const endUTCTime = new Date(endAt).getTime();
  const now = new Date().getTime();
  return now >= startUTCTime && now <= endUTCTime;
};

export const isNowPastADate = (endTime: string) => {
  if (!endTime) return false;
  const endUTCTime = new Date(endTime).getTime();
  const now = new Date().getTime();
  return now >= endUTCTime;
};

export const hoursInDays = (hours: number) => {
  return Math.ceil(hours / 24);
};

/**
 * parse date Str with ISO8601 format to local date
 * @param dateStr format yyyy-MM-dd
 * @returns Date
 */
export const parseISODateStringToLocalDate = (dateStr: string): Date => {
  return parse(dateStr, 'yyyy-MM-dd', new Date());
};

export const ONE_MINUTE = 60 * 1000;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;

export const getEarliestDOB = (youngestAge = ADULT_AGE): Date => {
  const currentDate = new Date();
  const earliestDOB = new Date(
    currentDate.getFullYear() - youngestAge,
    currentDate.getMonth(),
    currentDate.getDate(),
  );
  return earliestDOB;
};
