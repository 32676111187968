import { MicroilloParty as PartyIcon } from '@prospa/icons';

import { Modal } from '@components';

import './CardActivateSuccessModal.scss';
import type { CardActivateSuccessModalProps } from './types';

// CAS: Card Activate Success
export const CAS_LABELS = {
  PRIMARY_CTA: 'OK, thanks',
  MODAL_HEADING: 'Yay! Your card is ready to use',
};

export const CardActivateSuccessModal = ({
  isOpen,
  toggle,
  okButtonCallback,
}: CardActivateSuccessModalProps) => (
  <Modal
    isOpen={isOpen}
    onClose={toggle}
    showCloseModal
    primaryCTALabel={CAS_LABELS.PRIMARY_CTA}
    primaryCTAOnClick={() => {
      toggle();
      if (okButtonCallback) {
        okButtonCallback();
      }
    }}
    body={
      <div className="card-activate-success-modal">
        <PartyIcon />
        <h4 className="card-activate-success-modal__heading">{CAS_LABELS.MODAL_HEADING}</h4>
      </div>
    }
  />
);
