import { EventAction, EventCategory, TrackUser } from './types';

export const pushToDataLayer = (event: object) => {
  if (!!window.dataLayer) {
    window.dataLayer.push(event);
  }
};

export const trackActionEvent = (
  eventCategory: EventCategory,
  eventAction: EventAction,
  eventLabel: string | null = null,
) => {
  pushToDataLayer({
    event: 'dataLayerEvent',
    eventCategory,
    eventAction,
    eventLabel,
  });
};

export const trackPageViewEvent = () => {
  pushToDataLayer({
    event: 'dataLayerPageview',
  });
};

export const trackUser = ({ prospaId, product, hayAccountId, platform, extras }: TrackUser) => {
  const userData: TrackUser = {
    prospaId,
    product,
    platform,
    ...extras,
  };

  if (hayAccountId) userData.hayAccountId = hayAccountId;

  pushToDataLayer({
    user: userData,
  });

  return userData;
};
