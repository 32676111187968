import { Suspense, lazy, useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ErrorBoundary, type ErrorBoundaryProps } from '@sentry/react';
import { ConfigCatProvider } from 'configcat-react';
import { createStore } from 'little-state-machine';
import { DevTool } from 'little-state-machine-devtools';

import { Clarity, ClarityProvider } from '@all-in-one-web/clarity';

import { HtmlMetaHelmet, PageSpinner } from '@components';

import { getFeatureFlagConfig } from '@hooks';
import { useSetIntegrationsUserWithAuth } from '@hooks';
import { captureException, getClarityId, isAppLaunchedInIframe } from '@utils';

import PageError from './containers/PageError/PageError';

const Callback = lazy(() => import('./Callback'));
const Unauthorized = lazy(() => import('./Unauthorized'));
const Dashboard = lazy(() => import('./Dashboard'));
const PageNotFound = lazy(() => import('./containers/PageNotFound/PageNotFound'));
const AccountNotFound = lazy(() => import('./containers/AccountNotFound/AccountNotFound'));
const AddToWallet = lazy(() => import('./containers/MobileLandingPages/AddToWallet'));
const ProspaApp = lazy(() => import('./containers/MobileLandingPages/ProspaApp'));
const Setup = lazy(() => import('./containers/MobileLandingPages/Setup/Setup'));
const { sdkKey, options } = getFeatureFlagConfig();

const clarity = new Clarity(getClarityId());

const App = () => {
  const { user } = useAuth();
  const userId = user?.profile?.sub;

  useSetIntegrationsUserWithAuth({ clarity });

  const onError: ErrorBoundaryProps['onError'] = useCallback(
    error => {
      captureException(error, {
        user: {
          id: userId,
        },
        tags: {
          context: 'ErrorBoundary',
        },
      });
    },
    [userId],
  );

  createStore({
    modal: {
      flow: null,
      modalStep: null,
      addressToSendCard: null,
    },
  });

  return (
    <ErrorBoundary fallback={<PageError />} onError={onError}>
      <ConfigCatProvider sdkKey={sdkKey} options={options}>
        <ClarityProvider clarity={clarity}>
          <BrowserRouter>
            {process.env.NODE_ENV !== 'production' && !isAppLaunchedInIframe() && <DevTool />}
            <Suspense fallback={<PageSpinner />}>
              <HtmlMetaHelmet />
              <Routes>
                <Route path="/callback" element={<Callback />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/error" element={<PageError />} />
                <Route path="/404" element={<PageNotFound />} />
                <Route path="/noaccount" element={<AccountNotFound />} />
                <Route path="/addtowallet" element={<AddToWallet />} />
                <Route path="/explore" element={<ProspaApp />} />
                <Route path="/tap-to-pay" element={<ProspaApp />} />
                <Route path="/get-business-account" element={<ProspaApp />} />
                <Route
                  path="/connect-to-xero"
                  element={<Navigate replace to="/settings/connect-to-xero" />}
                />
                <Route path="/setup/:code/:userid/:source" element={<Setup />} />
                <Route path="/*" element={<Dashboard />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </ClarityProvider>
      </ConfigCatProvider>
    </ErrorBoundary>
  );
};

export default App;
