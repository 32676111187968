import { useMemo } from 'react';

import {
  Alert,
  Button,
  ButtonType,
  Card,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@all-in-one-web/common-ui';
import { Sparkle } from '@prospa/salt-icons/react';

import { currencyFormatter } from '@utils';

import { type LineOfCreditRepaymentCalculations } from '../../containers/RepaymentCalculator/types';
import {
  type RepaymentCalculations,
  type RepaymentCalculatorPageProps,
} from '../../containers/RepaymentCalculator/types';
import styles from './RepaymentsSchedule.module.scss';

const COLUMNS = [
  'Week',
  'Repayment Amount',
  'Interest',
  'Principal',
  'Weekly Service Fee',
  'Starting Balance',
  'Principal Outstanding',
];

export type RepaymentsScheduleProps = {
  alertProps: RepaymentCalculatorPageProps['alertProps'];
  loading: boolean;
  onQualifyClick: () => void;
  calculations: RepaymentCalculations;
};

export const RepaymentsSchedule = ({
  alertProps,
  loading,
  onQualifyClick,
  calculations,
}: RepaymentsScheduleProps) => {
  const { show: showAlert, ...restAlertProps } = alertProps;

  const lineOfCreditCalculations: LineOfCreditRepaymentCalculations =
    calculations as LineOfCreditRepaymentCalculations;

  const formattedRepaymentsSchedule = useMemo(
    () =>
      lineOfCreditCalculations.repaymentsSchedule.map(value => ({
        week: value.week,
        weeklyRepayment: currencyFormatter(value.weeklyRepayment),
        interest: currencyFormatter(value.interest),
        principal: currencyFormatter(value.principal),
        weeklyServiceFee: currencyFormatter(value.weeklyServiceFee),
        startingBalance: currencyFormatter(value.startingBalance),
        balanceWithdrawn: currencyFormatter(value.balanceWithdrawn),
      })),
    [lineOfCreditCalculations],
  );

  return (
    <Card className={styles.RepaymentsSchedule}>
      <div className={styles.RepaymentsScheduleHeader}>
        <span className="pds-lead-semibold">Estimated repayments schedule (first 10 weeks)</span>
      </div>
      <Table className={styles.RepaymentsScheduleTable}>
        <TableHead>
          <TableRow>
            {COLUMNS.map(column => (
              <TableHeaderCell key={column} className={styles.TableHeaderCell}>
                {column}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedRepaymentsSchedule.map((value, key) => {
            return (
              <TableRow className={styles.TableRow} key={key}>
                {Object.entries(value).map(([key, value]) => (
                  <TableDataCell className={styles.TableCell} key={key}>
                    {value}
                  </TableDataCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {showAlert && <Alert className="mt-4" {...restAlertProps} />}
      <div className={styles.RepaymentsScheduleButtonSection}>
        {!alertProps.show && (
          <div className={styles.RepaymentsScheduleButtonSectionCaption}>
            <Sparkle className={styles.RepaymentsScheduleButtonSectionIcon} />
            Ready for the next step?
          </div>
        )}

        <Button
          className={styles.RepaymentsScheduleButtonSectionButton}
          label="See if you qualify"
          type={ButtonType.PRIMARY}
          onClick={onQualifyClick}
          loading={loading}
          disabled={alertProps.show || loading}
          data-testid="qualify-button"
        />
      </div>
    </Card>
  );
};
