import { type FC } from 'react';

import classNames from 'classnames';

import { UofCashflow as CashFlowIcon, Edit as EditIcon } from '@prospa/icons';

import { Button, Card, getPayeeHeader, getPayeeSubheader } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import './PaymentConfirmation.scss';
import type { PaymentConfirmationProps } from './types';

export const PaymentConfirmation: FC<PaymentConfirmationProps> = ({
  displayData: body,
  payee,
  payer,
  footerText,
  onEdit,
  onCancel,
  onFormSubmit,
  submitButtonProps = {},
}) => {
  return (
    <Card className="paymentconfirmation">
      <header className="paymentconfirmation__header">
        <CashFlowIcon className="paymentconfirmation__icon" width={28} height={28} />
        <div className="paymentconfirmation__details">
          {payer && (
            <>
              <span className={classNames('pds-caption', 'paymentconfirmation__details-header')}>
                From
              </span>
              <div className="paymentconfirmation__details-row mb-4">
                <span className="paymentconfirmation__details-payer">{payer}</span>
              </div>
            </>
          )}

          <span className={classNames('pds-caption', 'paymentconfirmation__details-header')}>
            To
          </span>
          <div className="paymentconfirmation__details-row">
            <span className="paymentconfirmation__details-payer">{getPayeeHeader(payee)}</span>
          </div>
          <div className="paymentconfirmation__details-row">
            <span className="paymentconfirmation__details-account">{getPayeeSubheader(payee)}</span>
          </div>
        </div>
        <Button type={ButtonType.ICON_LINK} size={ButtonSize.MEDIUM} onClick={onEdit}>
          <EditIcon name="Edit" className="me-0" />
        </Button>
      </header>

      {body.length ? (
        <div className="paymentconfirmation__body">
          {body.map(({ text, value }) => (
            <div key={text} className="paymentconfirmation__body-row">
              <p
                className={classNames('paymentconfirmation__body-row-heading', 'pds-text-semibold')}
              >
                {text}
              </p>
              <p className={classNames('paymentconfirmation__body-row-heading-value', 'pds-text')}>
                {value}
              </p>
            </div>
          ))}
        </div>
      ) : null}

      <footer
        className={`paymentconfirmation__footer justify-content-${footerText ? 'between' : 'end'}`}
      >
        {footerText && <div className="paymentconfirmation__footer__text">{footerText}</div>}

        <div className="paymentconfirmation__footer__actions">
          <Button
            type={ButtonType.SECONDARY}
            size={ButtonSize.MEDIUM}
            label="Cancel"
            onClick={onCancel}
          />
          <Button
            type={ButtonType.PRIMARY}
            size={ButtonSize.MEDIUM}
            label="Confirm"
            onClick={onFormSubmit}
            {...submitButtonProps}
          />
        </div>
      </footer>
    </Card>
  );
};

export default PaymentConfirmation;
