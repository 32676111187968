import classNames from 'classnames';

import styles from './Spinner.module.scss';
import type { SpinnerProps } from './types';

export const Spinner = ({ className, color = 'default', ...props }: SpinnerProps) => (
  <div
    className={classNames(styles.Spinner, className, {
      [styles.White]: color === 'white',
      [styles.Black]: color === 'black',
    })}
    aria-live="polite"
    data-testid="spinner"
    {...props}
  >
    <div className={classNames(styles.Circle, styles.One)} />
    <div className={classNames(styles.Circle, styles.Two)} />
    <span className="visually-hidden">loading</span>
  </div>
);
