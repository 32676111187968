import { type ClarityClient } from '@all-in-one-web/clarity';

import { BusinessAccountStatus } from '@generated-fg';

import { type FeatureFlags } from '@models';

import { ReleasedNewFeatures } from '../components/types';
import { ProductTypes } from '../models/Account';
import type { AppData } from '../models/AppData';
import { getFutureDate } from './testUtils';

export const mockedFeatureFlags: FeatureFlags = {
  overdraft: false,
  billPay: true,
  underMaintenance: {
    startTime: '2023-05-12T00:00:00',
    endTime: '2023-05-12T02:00:00',
  },
  navBadge: {
    path: '/bills',
    endDate: '2030-05-12T00:00:00',
  },
  mobileAppBanners: {
    startTime: '2023-10-18T00:00:00Z',
    endTime: '2023-12-24T13:00:00Z',
  },
  productPageAlert: {
    buttonText: 'Discover funding solutions',
    messageText: 'Need extra funds for end of year opportunities?',
  },
  xeroIntegration: true,
  locProductPage: true,
  newFeature: {
    eventAction: 'bill_feature_modal__click',
    featureViewAction: 'bill_feature_modal__view',
    mediaUrl: 'https://static.prospa.com/all-in-one-portal/new-bill-pay-feature-desktop.gif',
    mediaUrlMobile: 'https://static.prospa.com/all-in-one-portal/new-bill-pay-feature-mobile.gif',
    endDate: getFutureDate().toISOString(),
    title: 'Manage your bills with Bill Pay',
    content:
      'Simplify your workflow and take charge of your bills. View, organise and schedule payments for your upcoming bills all in one easy place.',
    path: `/bills`,
    ctaLabel: 'Check it out',
    releasedFeature: ReleasedNewFeatures.BILL_PAY,
    targetProducts: [ProductTypes.BA, ProductTypes.LOCM],
  },
};

export const mockAppContext: AppData = {
  accountId: 'mock-account-id',
  cdpId: 'p1ces-cf5bdc06-e1ad-4369-3417-08da05905b6c',
  signInInfo: {
    isMobileAppUser: false,
    countryCode: 'AU',
    productIds: {
      BA: '288e514a-739a-4627-a11d-09c280038f8c',
    },
  },
  bsb: 'mock-bsb',
  accountNumber: 'mock-account-number',
  businessAccountDetails: {
    annualInterestRate: 0,
    businessDetails: {
      name: 'FIRST CROP COFFEE PTY LTD',
      abn: 'mock-abn',
    },
    homeAddress: '4 Salt Ash Avenue, SALT ASH NSW 2318',
    dailyInterestRate: 0,
    overdraftTermEndDateUtc: '2025-01-16T03:52:07.6456014',
    annualServiceRate: 0,
    accountStatus: BusinessAccountStatus.Active,
  },
  identityDetails: {
    id: 'cf5bdc06-e1ad-4369-3417-08da05905b6c',
    phoneNumber: 'mock-phone-number',
    email: 'mock-email',
    givenName: 'mock-first-name',
    familyName: 'mock-last-name',
  },
  customerProducts: {
    BA: true,
    SBL: true,
    LOC: true,
    LOCM: true,
  },
  featureFlags: mockedFeatureFlags,
  appContextLoading: false,
  isSystemUnderMaintenance: false,
};

export const mockOidcContext = {
  client_id: '',
  redirect_uri: '',
  response_type: '',
  scope: '',
  post_logout_redirect_uri: '',
  automaticSilentRenew: true,
  loadUserInfo: true,
  authority: '',
};

export const mockLayoutContext = {
  isIntersectingScrollableArea: true,
  stickyRef: typeof jest !== 'undefined' && jest.fn(),
};
export const mockClarity = (): ClarityClient => ({
  init: jest.fn(),
  hasStarted: jest.fn(),
  identify: jest.fn(),
  consent: jest.fn(),
  setTags: jest.fn(),
  upgrade: jest.fn(),
  getSessionUrl: jest.fn(),
});
export const mockSystemTime = (systemTime = '2023-01-01T00:00') => {
  jest.useFakeTimers().setSystemTime(new Date(systemTime));
};

export const mockVerificationCode = '123456';

export const mockInvalidVerificationCode = '654321';
