import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { BillStatus, LineOfCreditStatus, useGetNavDataQuery } from '@generated-fg';

import { getNavOptionsConfig, getSubnavRoute } from '@utils';

import { useBillsData } from '../../../containers/Bills/hooks/useBillsData';
import { AppContext } from '../../../contexts';
import type { NavConfig, NavSubConfig, TUseNavOptionsProps, UseNavOptionsType } from './types';

const dashboardRoutes = [
  '/',
  '/pay',
  '/drawdown',
  '/add-funds',
  '/statements',
  '/ba',
  '/sbl',
  '/sblm',
];
const dashboardRoutesSet = new Set(dashboardRoutes);

export function useNavOptions({ isSubNavOpen = false }: TUseNavOptionsProps): UseNavOptionsType {
  const { pathname = '', search } = useLocation();
  const { customerProducts } = useContext(AppContext);
  // TODO: replace with separate specific for this purpose query
  const { data: { user: { linesOfCredit: locs = [] } = {} } = {}, loading: locLoading } =
    useGetNavDataQuery();
  const { bills: receivedBills, loading: billsLoading } = useBillsData({
    status: [BillStatus.Received],
  });

  const rootPath = `/${pathname.split('/')[1]}`;
  const subNavToolTip = useMemo(() => getSubnavRoute(rootPath), [rootPath]);
  const isDashboardActive = useMemo(() => {
    return dashboardRoutesSet.has(rootPath) && !isSubNavOpen;
  }, [rootPath, isSubNavOpen]);

  const showDrawdown = useMemo(
    () =>
      !locLoading &&
      locs.some(
        ({ status }) =>
          status === LineOfCreditStatus.LineActive || status === LineOfCreditStatus.LineSuspended,
      ),
    [locLoading, locs],
  );

  const showPay = useMemo(
    () =>
      !locLoading &&
      (customerProducts.BA ||
        (customerProducts.LOC &&
          locs.some(
            ({ status }) =>
              status === LineOfCreditStatus.LineActive ||
              status === LineOfCreditStatus.LineSuspended,
          ))),
    [locLoading, locs, customerProducts],
  );

  const showBillPay: boolean = useMemo(
    () => customerProducts.BA || customerProducts.LOCM,
    [customerProducts],
  );

  const billsBadgeText = useMemo(
    () => (receivedBills.length > 0 ? `${receivedBills.length}` : ''),
    [receivedBills.length],
  );

  const allLinks = useMemo(
    () =>
      getNavOptionsConfig({
        dashboardToolTip: subNavToolTip,
        pathname,
        search,
        show: {
          funds: customerProducts.BA,
          drawdown: showDrawdown,
          pay: showPay,
          bills: showBillPay,
          cards: customerProducts.BA,
        },
        badgeText: {
          bills: billsBadgeText,
        },
        isBadgeLoading: {
          bills: billsLoading,
        },
      }),
    [
      subNavToolTip,
      pathname,
      search,
      customerProducts.BA,
      showDrawdown,
      showPay,
      showBillPay,
      billsBadgeText,
      billsLoading,
    ],
  );

  const visibleLinks = useMemo(() => filterNavLinks<NavConfig>(allLinks), [allLinks]);

  return { navConfigs: visibleLinks, isDashboardActive };
}

function filterNavLinks<Config extends NavConfig | NavSubConfig>(config: Config[]): Config[] {
  return config.reduce((acc, link) => {
    const parentLink = link as NavConfig;

    if (parentLink.navSubConfigs) {
      parentLink.navSubConfigs = filterNavLinks<NavSubConfig>(parentLink.navSubConfigs);
    }

    if (link.show !== false) {
      acc.push(link);
    }

    return acc;
  }, []);
}
