import type { LazyQueryExecFunction } from '@apollo/client';
import type { FormikErrors } from 'formik';

import {
  AccountType,
  type BsbLookupQuery,
  type Exact,
  type PaymentType,
  type Scalars,
  type VerifyAccountDetails,
  type VerifyNzAccountNumberQuery,
} from '@generated-fg';

import {
  BankAccountLengthAU,
  BankAccountLengthNZ,
  BillerCodeMaxLength,
  BillerCodeMinLength,
  BpayReferenceNumberMaxLength,
  BpayReferenceNumberMinLength,
  DescriptionMaxLength,
  MinAmount,
  PayAnyoneNewBankAccountErrorStrings,
  PayAnyoneNewBpayErrorStrings,
  ReferenceMaxLength,
} from '@constants';
import { type PayAnyoneTabs } from '@constants';
import type { AccountItem, NewPayeeValues, PayAnyoneFormValues } from '@models';
import { formatNZAccountDetails, validationConfig } from '@utils';

import { amount, description, reference } from './Validators/Common';
import { payLaterScheduledDate } from './Validators/PayLater';
import { duration, paymentFrequency, startDate } from './Validators/PayRecurring';

export type TPayAnyoneFormValidatorOptions = {
  minAmount?: number;
  maxDescriptionLength?: number;
  referenceRequired?: boolean;
  maxReferenceLength?: number;
  dailyLimit?: number | null;
};
const defaultPayAnyoneFormValidatorOptions: TPayAnyoneFormValidatorOptions = {
  minAmount: MinAmount,
  maxDescriptionLength: DescriptionMaxLength,
  referenceRequired: false,
  maxReferenceLength: ReferenceMaxLength,
  dailyLimit: null,
};

export const PayAnyoneFormValidator = (
  values: PayAnyoneFormValues,
  payFromAccount: AccountItem,
  paymentFrequencyType: PayAnyoneTabs,
  paymentType: PaymentType,
  options: TPayAnyoneFormValidatorOptions = {},
): FormikErrors<PayAnyoneFormValues> => {
  const mappedOptions = { ...defaultPayAnyoneFormValidatorOptions, ...options };

  // fields that are common to all payment types
  const commonValidators = [amount, description, reference];

  // fields that are specific to payLater
  const payLaterValidators = [payLaterScheduledDate];

  // fields that are specific to payRecurring
  const payRecurringValidators = [paymentFrequency, startDate, duration];

  const validators = [...commonValidators, ...payLaterValidators, ...payRecurringValidators];

  const errors = validators.reduce<FormikErrors<PayAnyoneFormValues>>((acc, validator) => {
    const result = validator(values, {
      payFromAccount,
      paymentFrequencyType,
      paymentType,
      options: mappedOptions,
    });
    return { ...acc, ...result };
  }, {});

  return errors;
};

export const validateNewBpay = (values: NewPayeeValues): FormikErrors<NewPayeeValues> => {
  const errors = {} as FormikErrors<NewPayeeValues>;

  if (!values.billerCode) {
    errors.billerCode = PayAnyoneNewBpayErrorStrings.BillerCodeEmptyError;
  } else if (
    !validationConfig.isNumber.pattern.test(values.billerCode) ||
    values.billerCode?.length < BillerCodeMinLength ||
    values.billerCode?.length > BillerCodeMaxLength
  ) {
    errors.billerCode = PayAnyoneNewBpayErrorStrings.BillerCodeInvalid;
  }
  if (!values.referenceNumber) {
    errors.referenceNumber = PayAnyoneNewBpayErrorStrings.ReferenceNumberEmptyError;
  } else if (
    !validationConfig.isNumber.pattern.test(values.referenceNumber) ||
    values.referenceNumber?.length < BpayReferenceNumberMinLength ||
    values.referenceNumber?.length > BpayReferenceNumberMaxLength
  ) {
    errors.referenceNumber = PayAnyoneNewBpayErrorStrings.ReferenceNumberInvalid;
  }

  if (!values.nickname) {
    errors.nickname = PayAnyoneNewBpayErrorStrings.NicknameEmptyError;
  } else if (!validationConfig.bankAccountName.pattern.test(values.nickname)) {
    errors.nickname = PayAnyoneNewBankAccountErrorStrings.BankAccountNameInvalidError;
  }

  return errors;
};

export const validateNewBankAU = async (
  values: NewPayeeValues,
  bsbLookupFunction: LazyQueryExecFunction<
    BsbLookupQuery,
    Exact<{
      bsbNumber: Scalars['String'];
    }>
  >,
): Promise<FormikErrors<NewPayeeValues>> => {
  const errors = {} as FormikErrors<NewPayeeValues>;

  if (!values.BSB) {
    errors.BSB = PayAnyoneNewBankAccountErrorStrings.BsbInvalidError;
  } else if (!validationConfig.isNumber.pattern.test(values.BSB)) {
    errors.BSB = PayAnyoneNewBankAccountErrorStrings.BsbInvalidError;
  } else if (values.BSB.length !== 6) {
    errors.BSB = PayAnyoneNewBankAccountErrorStrings.BsbInvalidError;
  }
  const bsbLookup = await bsbLookupFunction({ variables: { bsbNumber: values.BSB } });
  if (!bsbLookup.data?.bsb) {
    errors.BSB = PayAnyoneNewBankAccountErrorStrings.BsbInvalidError;
  }
  if (!values.accountNumber) {
    errors.accountNumber = PayAnyoneNewBankAccountErrorStrings.BankAccountEmptyError;
  } else if (!validationConfig.isNumber.pattern.test(values.accountNumber)) {
    errors.accountNumber = PayAnyoneNewBankAccountErrorStrings.BankAccountInvalidError;
  } else if (
    values.accountNumber.length < BankAccountLengthAU.Min ||
    values.accountNumber.length > BankAccountLengthAU.Max
  ) {
    errors.accountNumber = PayAnyoneNewBankAccountErrorStrings.BankAccountInvalidLengthErrorAU;
  }
  if (!values.accountName) {
    errors.accountName = PayAnyoneNewBankAccountErrorStrings.BankAccountNameEmptyError;
  } else if (!validationConfig.bankAccountName.pattern.test(values.accountName)) {
    errors.accountName = PayAnyoneNewBankAccountErrorStrings.BankAccountNameInvalidError;
  }

  return errors;
};

export const validateNewBankNZ = async (
  values: Pick<NewPayeeValues, 'accountNumber' | 'accountName'>,
  nzAccountValidator: LazyQueryExecFunction<
    VerifyNzAccountNumberQuery,
    Exact<{
      accountDetails: VerifyAccountDetails;
      accountType: AccountType;
    }>
  >,
): Promise<FormikErrors<NewPayeeValues>> => {
  const errors = {} as FormikErrors<NewPayeeValues>;
  if (!values.accountNumber) {
    errors.accountNumber = PayAnyoneNewBankAccountErrorStrings.BankAccountEmptyError;
  } else if (
    // NZ LOCM account numbers automatically add hyphens for user convenience, so we want to remove them
    values.accountNumber.replace(/\D/g, '').length < BankAccountLengthNZ.Min ||
    values.accountNumber.replace(/\D/g, '').length > BankAccountLengthNZ.Max
  ) {
    errors.accountNumber = PayAnyoneNewBankAccountErrorStrings.BankAccountInvalidLengthErrorNZ;
  } else if (!validationConfig.isNZBankAccount.pattern.test(values.accountNumber)) {
    errors.accountNumber = PayAnyoneNewBankAccountErrorStrings.BankAccountInvalidError;
  } else {
    // checking the account number is valid
    const { bank, suffix, account, branch } = formatNZAccountDetails({
      bankAccountNumber: values.accountNumber,
    });
    const accountValidation = await nzAccountValidator({
      variables: {
        accountDetails: {
          bank,
          suffix,
          accountNumber: account,
          branch,
        },
        accountType: AccountType.BankAccount,
      },
    });
    if (!accountValidation.data?.verifyAccountNumber.isValid) {
      errors.accountNumber = PayAnyoneNewBankAccountErrorStrings.NZBankAccountNumberInvalidError;
    }
  }
  if (!values.accountName) {
    errors.accountName = PayAnyoneNewBankAccountErrorStrings.BankAccountNameEmptyError;
  } else if (!validationConfig.bankAccountName.pattern.test(values.accountName)) {
    errors.accountName = PayAnyoneNewBankAccountErrorStrings.BankAccountNameInvalidError;
  }

  return errors;
};
