import { useCallback, useState } from 'react';

import { type MutationUpdaterFn } from '@apollo/client';

import { Cross } from '@prospa/salt-icons';

import {
  type BillStatus,
  GetBillDetailsDocument,
  type GetBillDetailsQuery,
  type GetBillDetailsQueryVariables,
  GetBillsDocument,
  type GetBillsQuery,
  type GetBillsQueryVariables,
  useDeleteBillMutation,
} from '@generated-fg';

import { DeleteBillModal } from '@components';
import type { DeleteBillModalProps } from '@components/types';

import { useToastContext } from '@hooks';
import { trackBAActionEvent } from '@utils';

export type TUseDeleteBillActionProps = {
  onSuccess?: () => void;
  updater: (billToDelete: TBillToDelete) => MutationUpdaterFn;
};

export type TBillToDelete =
  | Pick<GetBillsQuery['user']['billPay']['bills'][0], 'billerName' | 'id'>
  | undefined;

export type TDeleteBillMutationUpdater = {
  status: BillStatus[];
};

export const deleteBillMutationUpdater = ({
  status,
}: TDeleteBillMutationUpdater): TUseDeleteBillActionProps['updater'] => {
  return (billToDelete: TBillToDelete) => cache => {
    cache.updateQuery<GetBillsQuery, GetBillsQueryVariables>(
      {
        query: GetBillsDocument,
        variables: { status },
        overwrite: true,
      },
      data => ({
        ...data,
        user: {
          ...data.user,
          billPay: {
            ...data.user.billPay,
            bills: data.user.billPay.bills.filter(({ id }) => id !== billToDelete?.id),
          },
        },
      }),
    );
  };
};

export const deleteBillOnDetailPageUpdater: TUseDeleteBillActionProps['updater'] =
  billToDelete => cache => {
    cache.updateQuery<GetBillDetailsQuery, GetBillDetailsQueryVariables>(
      {
        query: GetBillDetailsDocument,
        variables: { id: billToDelete?.id },
        overwrite: true,
      },
      data => {
        return {
          ...data,
          user: {
            ...data.user,
            billPay: {
              ...data.user.billPay,
              bill: null,
            },
          },
        };
      },
    );
  };

export const useDeleteBillAction = ({ updater, onSuccess }: TUseDeleteBillActionProps) => {
  const [showModal, setShowModal] = useState<DeleteBillModalProps['isOpen']>(false);
  const [billToDelete, setBillToDelete] = useState<TBillToDelete>();
  const [deleteBill, { loading: isDeleteBillLoading }] = useDeleteBillMutation({
    refetchQueries: [GetBillsDocument],
    awaitRefetchQueries: true,
  });
  const { addToast } = useToastContext();

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setBillToDelete(undefined);
  }, []);

  const handleOpenModal = useCallback((billToDelete: TBillToDelete) => {
    setShowModal(true);
    setBillToDelete(billToDelete);
  }, []);

  const handleDeleteBill = useCallback(async () => {
    try {
      const response = await deleteBill({
        variables: {
          id: billToDelete?.id,
        },
        update: updater(billToDelete),
      });
      const {
        data: {
          deleteBill: { success },
        },
      } = response;
      if (success) {
        handleCloseModal();
        if (onSuccess) {
          onSuccess();
        }
        return;
      }
      throw response;
    } catch (error) {
      trackBAActionEvent('bill_error', error.message);
      addToast('An error occurred while deleting the bill', Cross);
    }
  }, [addToast, billToDelete, deleteBill, handleCloseModal, onSuccess, updater]);

  return {
    modal: (
      <DeleteBillModal
        billerName={billToDelete?.billerName}
        isOpen={showModal}
        onClose={handleCloseModal}
        primaryButtonProps={{
          onClick: handleDeleteBill,
          loading: isDeleteBillLoading,
          disabled: isDeleteBillLoading,
        }}
        secondaryButtonProps={{ onClick: handleCloseModal }}
      />
    ),
    deleteBill: handleOpenModal,
  };
};
