import { type FC } from 'react';
import { Table as TableComponent } from 'reactstrap';

import classNames from 'classnames';

import styles from './Table.module.scss';
import { type TableProps } from './types';

export const Table: FC<TableProps> = ({
  children,
  hasShadow,
  wrapperClassName,
  className,
  ...props
}) => {
  return (
    <div className={classNames(hasShadow ? styles.TableShadowContainer : '', wrapperClassName)}>
      <TableComponent className={classNames(styles.Table, className)} {...props}>
        {children}
      </TableComponent>
    </div>
  );
};

Table.defaultProps = {
  responsive: true,
  hasShadow: true,
};

Table.displayName = 'table';
