import classNames from 'classnames';

import { ExpandedContent } from '@all-in-one-web/common-ui';

import { SECTION_HEADING } from '@constants';
import { getDashConnectedStr, trackBAActionEvent } from '@utils';

import styles from './LoanDetails.module.scss';
import type { LoanDetailsItem, LoanDetailsProps } from './types';

export const LoanDetails = ({
  headLabel = SECTION_HEADING.SEE_DETAILS,
  loanDetails,
  footerText,
  className,
  toggler,
}: LoanDetailsProps) => {
  const renderItems = (items: LoanDetailsItem[]) => (
    <div className={styles.LoanDetailsSet} key={items.map(i => i.label).join('-')}>
      {items.map(({ label, value, comment, subsection }) => (
        <div key={label} className={styles.LoanDetailsRow} data-label={getDashConnectedStr(label)}>
          <div className={styles.LoanDetailsFlexCol}>
            <span className="pds-text-semibold">{label}</span>
            <span className="pds-text">{value}</span>
          </div>
          <div className={styles.LoanDetailsCol}>
            <span className={classNames(styles.LoanDetailsComment, 'pds-caption')}>
              {comment || ''}
            </span>
          </div>
          {subsection ? (
            <div className={styles.LoanDetailsSubSet}>
              <span className={classNames('pds-text-semibold', styles.LoanDetailsSubSetTitle)}>
                {subsection.title}
              </span>
              {subsection.items.map(items => renderItems(items))}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
  if (!loanDetails) return null;
  return (
    <ExpandedContent
      headLabel={headLabel}
      testId="expanded-loan-detail"
      onToggleClick={() => trackBAActionEvent('product_see_details')}
      className={className}
      toggler={toggler}
    >
      <div data-testid="loan-details-content">
        <div className={styles.LoanDetailsContainer}>
          {loanDetails.map(items => renderItems(items))}
        </div>
        {footerText && (
          <p className={classNames(styles.LoanDetailsFooter, 'pds-caption')}>{footerText}</p>
        )}
      </div>
    </ExpandedContent>
  );
};
