import { useContext } from 'react';

import { SettingKeyToFeatureFlagsKey } from '@models';
import { isBillPayEntitledUser } from '@utils';

import { AppContext } from '../../contexts';
import { config } from './config';
import { type TestGroupConfig, TestGroups } from './types';

export const useBillPaySplitTesting = (): TestGroupConfig => {
  const { customerProducts, featureFlags } = useContext(AppContext);
  if (!isBillPayEntitledUser(customerProducts)) return null;

  return config[featureFlags[SettingKeyToFeatureFlagsKey.BILL_PAY] ? TestGroups.A : TestGroups.B];
};
