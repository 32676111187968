import { WebAlert as AlertIcon } from '@prospa/icons';

import { Modal } from '@components';

import './CancelScheduledPaymentsModal.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  recipientName?: string;
}

export const CancelScheduledPaymentsModal = ({
  isOpen,
  onClose,
  handleSubmit,
  recipientName = '',
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      showCloseModal
      primaryCTALabel="Confirm"
      size="medium"
      primaryCTAOnClick={() => {
        handleSubmit();
      }}
      body={
        <div className="cancel-scheduled-payments-modal">
          <AlertIcon className="cancel-scheduled-payments-modal__icon" />
          <h4 className="cancel-scheduled-payments-modal__title">Cancel scheduled payment?</h4>
          <p className="pds-text">
            Are you sure you want to cancel this upcoming payment to <b>{recipientName}</b>?
          </p>
        </div>
      }
    />
  );
};
