import { useMemo } from 'react';

import { MicroilloDocument as DocumentIcon } from '@prospa/icons';

import { Banner } from '@components';

import { REVIEW_LOC_AGREEMENT_BANNER } from '@constants';
import { trackBAActionEvent } from '@utils';

import type { ReviewLocAgreementBannersProps } from './types';

export const ReviewLocAgreementBanners = ({
  pendingLocLoans,
  onClick,
}: ReviewLocAgreementBannersProps) => {
  const locBanners = useMemo(() => {
    return pendingLocLoans.map(({ id, productType }) => (
      <Banner
        key={id}
        icon={<DocumentIcon />}
        title={REVIEW_LOC_AGREEMENT_BANNER.TITLE}
        subtitle={REVIEW_LOC_AGREEMENT_BANNER.SUBTITLE}
        onClick={() => {
          trackBAActionEvent('loc_review_agreement');
          onClick(productType, id);
        }}
        buttonText={REVIEW_LOC_AGREEMENT_BANNER.BUTTON}
      />
    ));
  }, [pendingLocLoans, onClick]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{locBanners}</>;
};
