import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import { upperCaseWordAtIdx } from '@utils';

import type { CardAddress } from '../containers/Cards/CardSettings/types';

export const formatAddressToAddressObject = (addressToFormat: string): CardAddress => {
  // Matches the 4 last digits and returns the first element representing the match
  const postcode = addressToFormat.match(/\d{4}$/)[0];

  const address = addressToFormat.split(', ');
  // Suburb, state and postal code string is the last element in address
  const suburbStateZipIdx = address.length - 1;
  const suburbStateZip = address[suburbStateZipIdx].split(' ');

  // State is the second to last element in suburbStateZip
  const stateIdx = suburbStateZip.length - 2;
  const state = suburbStateZip[stateIdx];

  // Suburb is every element in suburbStateZip before state
  const suburb = suburbStateZip.slice(0, stateIdx).join(' ');

  // Line 1 is every element in address before suburbStateZip
  const line1 = address.slice(0, suburbStateZipIdx).join(', ');

  return {
    country: 'AUS',
    line1,
    postalCode: postcode,
    townOrCity: suburb,
    administrativeRegion: state,
  };
};

export const formatAddress = (addressToFormat: string) => {
  const formattedAddress = startCase(toLower(addressToFormat || ''));
  return upperCaseWordAtIdx(formattedAddress, 2);
};

export const formatAddressToString = (
  address: Pick<CardAddress, 'line1' | 'townOrCity' | 'administrativeRegion' | 'postalCode'> | null,
) => {
  if (address == null) {
    return;
  }

  return `${address.line1}, ${address.townOrCity} ${address.administrativeRegion} ${address.postalCode}`;
};
