import {
  type BillBPayPaymentInput,
  type BillBankTransferPaymentInput,
  Country,
} from '@generated-fg';

import { BillConfirmFormErrorMessage } from '@constants';

import styles from '../../styles.module.scss';
import { type PaymentMethodDetailsProps } from './types';

export const PaymentMethodDetails = ({
  bankTransferPayment,
  bpayPayment,
  countryCode,
  paymentTypes,
}: PaymentMethodDetailsProps) => {
  const isAuAccount = countryCode === Country.Au;
  const BankDetailCard = ({ accountName, bsb, accountNumber }: BillBankTransferPaymentInput) => {
    return (
      <div className={styles.ConfirmBillFormPaymentMethodDetails}>
        <p>
          <span className={styles.DetailsLabel}>Account name</span>
          <span className={styles.DetailsValue}>{accountName}</span>
        </p>
        {isAuAccount && (
          <p>
            <span className={styles.DetailsLabel}>BSB</span>
            <span className={styles.DetailsValue}>{bsb}</span>
          </p>
        )}
        <p>
          <span className={styles.DetailsLabel}>Account number</span>
          <span className={styles.DetailsValue}>{accountNumber}</span>
        </p>
      </div>
    );
  };

  const BPayCard = ({ billerCode, reference }: BillBPayPaymentInput) => {
    return (
      <div className={styles.ConfirmBillFormPaymentMethodDetails}>
        <p>
          <span className={styles.DetailsLabel}>Biller Code</span>
          <span className={styles.DetailsValue}>{billerCode}</span>
        </p>
        <p>
          <span className={styles.DetailsLabel}>Reference</span>
          <span className={styles.DetailsValue}>{reference}</span>
        </p>
      </div>
    );
  };

  // NOTE: with the data mapper function only return allowed payment types for pre-scan result
  // For user-confirmed result, it will return the confirmed payment type only
  if (!paymentTypes?.length) {
    return (
      <p className={styles.ConfirmBillFormPaymentMethodSectionNoPayee}>
        {BillConfirmFormErrorMessage.NoBillerPayeeFound}
      </p>
    );
  }

  // NOTE: bpay will be prioritized over bank transfer only when it is available [AUS + BA only for now]
  if (bpayPayment?.billerCode || bpayPayment?.reference) {
    return <BPayCard {...bpayPayment} />;
  }

  if (bankTransferPayment?.accountName || bankTransferPayment?.accountNumber) {
    return <BankDetailCard {...bankTransferPayment} />;
  }

  return null;
};
