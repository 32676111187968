/**
 * NOTE: will fully removed this modal once using braze notifications
 */
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Badge, Modal as ReactModal } from '@components';
import { type NewFeatureModalProps } from '@components/types';

import { breakpoints } from '@constants';
import { trackBAActionEvent } from '@utils';

import styles from './NewFeatureModal.module.scss';

export const NewFeatureModal = ({
  isOpen,
  onClose,
  onPrimaryCTA,
  title,
  body,
  media,
  ctaLabel,
  newFeatureViewAction,
  badge = <Badge className={styles.NewFeatureModalBadge}>NEW</Badge>,
}: NewFeatureModalProps) => {
  const isMobile = useMediaQuery({ query: breakpoints.mobile });
  const imageUrl = `url(${isMobile ? media.backgroundMobile : media.background})`;

  // NOTE: trigger at render and only once
  useEffect(() => {
    if (newFeatureViewAction && isOpen) {
      trackBAActionEvent(newFeatureViewAction);
    }
  }, [newFeatureViewAction, isOpen]);

  return (
    <ReactModal
      data-testid="new-feature-modal"
      size="large"
      customStyle={{ content: { padding: 0 } }}
      isOpen={isOpen}
      onClose={onClose}
      showCloseModal
      primaryCTALabel={ctaLabel}
      primaryCTAOnClick={onPrimaryCTA}
      hideFooterBorder
      footerClassName={styles.NewFeatureModalFooter}
      headerClassName={styles.NewFeatureModalHeader}
      heading={
        <div
          className={styles.NewFeatureModalHeaderBackground}
          style={{ backgroundImage: imageUrl }}
        />
      }
      body={
        <div>
          <div className={styles.NewFeatureModalBody}>
            {badge}
            <h4 className={styles.NewFeatureModalBodyHeading}>{title}</h4>
            <span className={styles.NewFeatureModalBodyText}>{body}</span>
          </div>
        </div>
      }
    />
  );
};
