import { type FC, useCallback, useState } from 'react';

import { useActivateBaPhysicalCardMutation, useVerifyBaCardNumberMutation } from '@generated-fg';

import { CodeInput, Modal } from '@components';

import { trackBAActionEvent, validationConfig } from '@utils';

import { ReactComponent as CardBack } from '../../../../assets/card-back.svg';
import Api from '../../../services/Api';
import type { CodeInputProps } from '../../CodeInput/types';
import './CardActivateModal.scss';
import type { CardActivateModalProps } from './types';

const CARD_NUMBER_LENGTH = 4;
export const VALIDATION_ERROR = 'Check the last 4 digits of your card and try again';

export const CardActivateModal: FC<CardActivateModalProps> = ({
  isOpen,
  cardId,
  accountId,
  closeModal,
  onSuccess,
  onError,
  ...rest
}) => {
  const [cardNumber, setCardNumber] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const [activateBAPhysicalCard, { loading: activationLoading }] =
    useActivateBaPhysicalCardMutation({
      variables: {
        input: {
          cardId,
          lastFourDigits: cardNumber,
          accountId: accountId,
          stepUpToken: Api.prospaStepUpToken,
        },
      },
    });
  const [verifyBACardNumber, { loading: verificationLoading }] = useVerifyBaCardNumberMutation({
    variables: {
      input: {
        cardId,
        lastFourDigits: cardNumber,
        accountId: accountId,
        stepUpToken: Api.prospaStepUpToken,
      },
    },
  });
  const loading = activationLoading || verificationLoading;
  const confirm = async () => {
    if (!cardNumber || !validationConfig.fourDigitNumber.pattern.test(cardNumber)) {
      setError(VALIDATION_ERROR);
      return;
    }

    setError(null);

    try {
      const { data, errors } = await verifyBACardNumber();
      if (!data?.verifyBACardNumber?.success) throw errors;
      trackBAActionEvent('card_verify');
    } catch (error) {
      setError(VALIDATION_ERROR);
      return;
    }

    try {
      const { data, errors } = await activateBAPhysicalCard();

      if (!data?.activateBAPhysicalCard?.success) throw errors;

      onSuccess();
      trackBAActionEvent('card_activated');
      return;
    } catch (error) {
      onError();
    }

    setCardNumber(null);
  };

  const changeHandler: CodeInputProps['onChange'] = useCallback(value => {
    setError(null);

    if (value.length === CARD_NUMBER_LENGTH) {
      setCardNumber(value);
    }
  }, []);

  const onClose = useCallback(() => {
    setError(null);
    setCardNumber(null);
    closeModal();
  }, [closeModal]);

  return (
    <Modal
      heading="Activate card"
      isOpen={isOpen}
      showCloseModal
      onClose={onClose}
      primaryCTALabel="Next"
      primaryCTAOnClick={confirm}
      isLoading={loading}
      body={
        <div className="activate-card__body">
          <p className="pds-text">Please enter the last 4 digits of your card</p>
          <CardBack title="Prospa Card" />
          <div className="activate-card__card-number-form">
            <CodeInput
              fields={CARD_NUMBER_LENGTH}
              onChange={changeHandler}
              isValid={!error}
              error={error}
              autoFocus={isOpen}
            />
          </div>
        </div>
      }
      {...rest}
    />
  );
};
