import type { ComponentProps, RefObject } from 'react';

import { ButtonType } from '../../constants';
import { useHover } from '../../hooks/useHover';
import type { Spinner } from '../Spinner';

type SpinnerColor = ComponentProps<typeof Spinner>['color'];

export const useSpinnerColor = (
  type: ButtonType,
): [RefObject<HTMLButtonElement> | null, SpinnerColor] => {
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>();

  let spinnerColor: SpinnerColor = 'black';
  if (type === ButtonType.TERTIARY || (type === ButtonType.SECONDARY && isHovered)) {
    spinnerColor = 'white';
  } else if (type === ButtonType.ICON_LINK) {
    spinnerColor = 'default';
  }

  return [hoverRef, spinnerColor];
};
