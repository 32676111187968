import { useMemo } from 'react';

import { supportEmails, supportPhoneNumbers } from '@all-in-one-web/common-ui';

import { NEED_HELP } from '@constants';

import './NeedHelpContact.scss';
import type { ContactDetails } from './types';

export const NeedHelpContact = ({ countryCode = '' }) => {
  const contacts = useMemo<ContactDetails[]>(() => {
    if (countryCode)
      return [
        {
          countryCode,
          countryName: '',
          phoneNumber: supportPhoneNumbers[countryCode],
          email: supportEmails[countryCode],
        },
      ];
    else
      return [
        {
          countryCode: 'AU',
          countryName: 'Australia',
          phoneNumber: supportPhoneNumbers['AU'],
          email: supportEmails['AU'],
        },
        {
          countryCode: 'NZ',
          countryName: 'New Zealand',
          phoneNumber: supportPhoneNumbers['NZ'],
          email: supportEmails['NZ'],
        },
      ];
  }, [countryCode]);

  return (
    <div className="need-help">
      {NEED_HELP}{' '}
      {contacts.map(({ countryCode, countryName, phoneNumber, email }) => (
        <div className="need-help__contact" key={countryCode}>
          {countryName && <div className="need-help__country">{countryName}</div>}
          <a className="pds-link" href={phoneNumber.url}>
            {phoneNumber.text}
          </a>{' '}
          or{' '}
          <a className="pds-link" href={email.url}>
            {email.text}
          </a>
        </div>
      ))}
    </div>
  );
};
