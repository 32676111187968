import { type ReactElement } from 'react';

import { MicroilloHandDiamond as RefinanceCalculatorIcon } from '@prospa/icons';

import { Banner } from '@components';

import { REFINANCE_CALC_BANNER } from '@constants';
import { externalRedirect, trackBAActionEvent } from '@utils';

import environments from '../../../environments';
import type { RefinanceEligibleBannerProps } from './type';

export const RefinanceEligibleBanner = ({
  isRefinanceEligible,
}: RefinanceEligibleBannerProps): ReactElement => {
  if (!isRefinanceEligible) {
    return null;
  }
  const redirectToRefinanceCalculator = () => {
    trackBAActionEvent('sbl_refi_calculate');
    return externalRedirect({
      url: environments.customerPortal.refinanceCalculator,
      openInNewTab: false,
    });
  };
  return (
    <Banner
      icon={<RefinanceCalculatorIcon />}
      title={REFINANCE_CALC_BANNER.TITLE}
      subtitle={REFINANCE_CALC_BANNER.SUBTITLE}
      buttonProps={{
        label: REFINANCE_CALC_BANNER.BUTTON,
        onClick: redirectToRefinanceCalculator,
      }}
    />
  );
};
