import { useCallback, useEffect, useMemo, useState } from 'react';

import { compareAsc, endOfMonth, format, subMonths, subYears } from 'date-fns';
import { Field, Form, useFormikContext } from 'formik';

import { Cross } from '@prospa/icons';

import {
  StatementFileType,
  StatementProductType,
  StatementType,
  useGetStatementLazyQuery,
} from '@generated-fg';

import { DownloadButtons, FormikDatePicker } from '@components';

import { PRODUCT_TYPES, dateFormatAPI } from '@constants';
import { useAppLogger, useDownloadOptions, useToastContext } from '@hooks';
import {
  type EventNameType,
  isCoreProduct as isCoreProductFn,
  parseISODateStringToLocalDate,
  saveAs,
  trackBAActionEvent,
} from '@utils';

import type { DownloadButtonsProps } from '../DownloadButtons/types';
import { Errors } from './ErrorMessage';
import './FormContent.scss';
import type { ButtonRowProps, DatesRangeProps, FormContentProps, FormType } from './types';

const today = new Date();

export const FormContent = ({ account, customDateRange }: FormContentProps) => {
  const { addToast } = useToastContext();
  const {
    values: { fromDate, toDate },
    errors,
    submitForm,
    setFieldValue,
  } = useFormikContext<FormType>();
  const { logMessage } = useAppLogger();
  const isCoreProduct = useMemo(() => isCoreProductFn(account), [account]);

  useEffect(() => {
    if (!customDateRange) {
      return;
    }

    //reset field value when selected loan changed and value & range not matches.
    if (fromDate && compareAsc(fromDate, parseISODateStringToLocalDate(customDateRange.min)) < 0) {
      setFieldValue('fromDate', null);
    }

    if (toDate && compareAsc(toDate, parseISODateStringToLocalDate(customDateRange.max)) > 0) {
      setFieldValue('toDate', null);
    }
  }, [customDateRange, setFieldValue, fromDate, toDate]);

  const [getStatement] = useGetStatementLazyQuery({
    fetchPolicy: 'network-only',
  });

  const handleDownload: ButtonRowProps['onClick'] = async fileType => {
    submitForm(); // triggers validation for the initial state

    if (errors.toDate) {
      trackBAActionEvent('bank_statements_custom_statement_error', 'end_date_missing');
    } else if (
      errors.fromDate &&
      errors.fromDate === 'Please enter or select a statement start date'
    ) {
      trackBAActionEvent('bank_statements_custom_statement_error', 'start_date_missing');
    } else if (errors.fromDate && errors.fromDate === 'Start date should be before end date') {
      trackBAActionEvent('bank_statements_custom_statement_error', 'start_date_after_end_date');
    }

    if (!fromDate || !toDate || errors.toDate || errors.fromDate) {
      return false;
    }

    trackBAActionEvent(
      'bank_statements_custom-statement',
      `${format(fromDate, dateFormatAPI)}_${format(toDate, dateFormatAPI)}`,
    );

    let type;
    switch (account.name) {
      case PRODUCT_TYPES.SBL:
        type = StatementProductType['SmallBusinessLoan'];
        break;
      case PRODUCT_TYPES.LOC:
        type = StatementProductType['LineOfCredit'];
        break;
      default:
        type = StatementProductType['BusinessAccount'];
    }

    try {
      const { data: { user: { statement: { url, filename } = {} } = {} } = {}, error } =
        await getStatement({
          variables: {
            id: type === StatementProductType['BusinessAccount'] ? undefined : account.id,
            startDate: format(fromDate, dateFormatAPI),
            endDate: format(toDate, dateFormatAPI),
            statementType: StatementType.Custom,
            fileType,
            type,
          },
          context: {
            ignoreNetworkErrors: true,
            ignoreGraphQLErrors: true,
          },
        });

      if (error) throw error;

      trackBAActionEvent(
        `bank_statements_custom_statement_${fileType.toLowerCase()}` as EventNameType,
        'success',
      );

      await saveAs(url, filename);
    } catch (error) {
      addToast('Something went wrong! Please try again', Cross);
      trackBAActionEvent('bank_statements_custom_statement_pdf', 'failure');
      logMessage('[getStatement]: Download Custom Statement Failed', {
        extra: { error },
      });
    }
  };

  return (
    <>
      <Form className="date-form">
        <DatesRange fromDate={fromDate} {...customDateRange} isCoreProduct={isCoreProduct} />
        <DownloadButton onClick={handleDownload} isCoreProduct={isCoreProduct} />
      </Form>
      <Errors />
    </>
  );
};

const DatesRange = ({ fromDate, min, max, isCoreProduct }: DatesRangeProps) => {
  const defaultMinDate = subYears(today, 1),
    defaultMaxDate = isCoreProduct ? endOfMonth(subMonths(today, 1)) : today;

  const minDate = min ? parseISODateStringToLocalDate(min) : defaultMinDate,
    maxDate = max ? parseISODateStringToLocalDate(max) : defaultMaxDate;

  return (
    <div className="dates">
      <Field
        name="fromDate"
        label="From"
        key="fromDate"
        minDate={minDate}
        maxDate={maxDate}
        showErrors={false}
        component={FormikDatePicker}
        testId="statement-start-date"
      />
      <div className="hyphen">-</div>
      <Field
        name="toDate"
        label="To"
        key="toDate"
        minDate={fromDate || minDate}
        maxDate={maxDate}
        showErrors={false}
        component={FormikDatePicker}
        testId="statement-end-date"
      />
    </div>
  );
};

const DownloadButton = ({ onClick, isCoreProduct }: ButtonRowProps) => {
  const [selectedFileType, setSelectedFiletype] = useState<StatementFileType>();

  const handleClick: DownloadButtonsProps['onClick'] = useCallback(
    async type => {
      const fileType = type as StatementFileType;
      setSelectedFiletype(fileType);
      await onClick(fileType);
      setSelectedFiletype(undefined);
    },
    [onClick],
  );

  const options = useDownloadOptions({
    loaders: {
      pdf: selectedFileType === StatementFileType['Pdf'],
      csv: selectedFileType === StatementFileType['Csv'],
    },
    selectedFileType,
    showCsv: !isCoreProduct,
  });

  return (
    <div className="button-row">
      <DownloadButtons onClick={handleClick} options={options} />
    </div>
  );
};
