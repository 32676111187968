import { MicroilloSloth as SlothIcon } from '@prospa/icons';

import { Modal } from '@components';

import './TooManyReplacementCardsModal.scss';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const TooManyReplacementCardsModal = ({ isOpen, toggle }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      showCloseModal
      size="large"
      primaryCTALabel="OK, thanks"
      primaryCTAOnClick={toggle}
      body={
        <div className="too-many-replacement-cards-modal">
          <SlothIcon className="too-many-replacement-cards-modal__icon" />
          <h4>{'Uh oh, you\u2019ve reached the limit'}</h4>
          <p className="pds-text">
            {
              'It looks like you\u2019ve ordered too many replacement cards over the past month. To prevent unauthorised use, toggle on \u2018Freeze card\u2019 in your cards settings.'
            }
          </p>
          <p className="pds-text">
            {'You can try again and order a replacement card in a month\u2019s time.'}
          </p>
        </div>
      }
    />
  );
};
