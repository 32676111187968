import type { ChangeEvent } from 'react';

import { Country } from '@generated-fg';

import { isNZLOCMambu } from './loc';

export const formatNZAccountDetails = ({ bankAccountNumber }: { bankAccountNumber: string }) => {
  const cleanedAccountNumber = bankAccountNumber.replace(/\D/g, '');
  return {
    bank: cleanedAccountNumber.substring(0, 2),
    branch: cleanedAccountNumber.substring(2, 6),
    account: cleanedAccountNumber.substring(6, 13),
    suffix: cleanedAccountNumber.substring(13, 16),
  };
};

// Add automatic hyphens on change to account number text for NZ
export const handleNZChange = (e: ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value.replace(/\D/g, '');

  let formattedValue = '';
  if (value.length <= 2) {
    formattedValue = value;
  } else if (value.length <= 6) {
    formattedValue = value.slice(0, 2) + '-' + value.slice(2, 6);
  } else if (value.length <= 13) {
    formattedValue = value.slice(0, 2) + '-' + value.slice(2, 6) + '-' + value.slice(6, 13);
  } else {
    formattedValue =
      value.slice(0, 2) +
      '-' +
      value.slice(2, 6) +
      '-' +
      value.slice(6, 13) +
      '-' +
      value.slice(13, 16);
  }
  return formattedValue;
};

export const isNZAccount = (id: string, countryCode?: Country) => {
  return isNZLOCMambu(id) || countryCode === Country.Nz;
};
