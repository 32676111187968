import { DrawdownStatus } from '@generated-fg';

import DeferredAccountAlert from '../DeferredAccountAlert/DeferredAccountAlert';
import LockedAccountAlert from '../LockedAccountAlert/LockedAccountAlert';
import SuspendedAccountAlert from '../SuspendedAccountAlert/SuspendedAccountAlert';
import type { LineOfCreditStatusAlertProps } from './types';

function LineOfCreditStatusAlert({ details, className }: LineOfCreditStatusAlertProps) {
  const { text: alertMessage } = details || {};
  const commonProps = { alertMessage };

  if (!alertMessage) return null;

  switch (details.status) {
    case DrawdownStatus.Suspended:
      return <SuspendedAccountAlert {...commonProps} className={className} />;
    case DrawdownStatus.Deferred:
      return <DeferredAccountAlert {...commonProps} className={className} />;
    case DrawdownStatus.Locked:
      return <LockedAccountAlert {...commonProps} className={className} />;
    default:
      return null;
  }
}
export default LineOfCreditStatusAlert;
