import { type FC, type ReactElement, type ReactNode, useState } from 'react';
import { Tabs as ReactTabs, Tab, TabList, TabPanel } from 'react-tabs';

import classNames from 'classnames';

import './Tabs.scss';

type Props = {
  tabIndex?: number;
  tabs: { tabHeader: ReactNode; tab: ReactElement }[];
  type: 'primary' | 'primary-fitted' | 'secondary-small' | 'secondary-large';
  onTabSelect?: (tabIndex: number) => void;
  showTabs?: boolean;
  testId?: string;
  className?: string;
};
export const Tabs: FC<Props> = ({
  tabIndex = 0,
  tabs,
  type,
  onTabSelect,
  showTabs = true,
  testId,
  className,
}) => {
  const [index, setIndex] = useState<number>(tabIndex);

  const tabHeaders = [];
  const tabContent = [];

  tabs.forEach((tab, index) => {
    tabHeaders.push(
      <Tab key={`tab-header-${index}`}>
        <span className={type === 'secondary-small' ? 'pds-caption' : 'pds-text'}>
          {tab.tabHeader}
        </span>
      </Tab>,
    );
    tabContent.push(<TabPanel key={`tab-panel-${index}`}>{tab.tab}</TabPanel>);
  });

  return (
    <ReactTabs
      className={classNames('react-tabs', type, className)}
      selectedIndex={index}
      onSelect={index => {
        setIndex(index);
        onTabSelect && onTabSelect(index);
      }}
      data-testid={testId}
    >
      {showTabs && <TabList>{tabHeaders}</TabList>}
      {tabContent}
    </ReactTabs>
  );
};
