import { BoxCheck } from '@prospa/salt-illustrations';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { externalRedirect } from '@utils';

import styles from './Contract.module.scss';
import { type AllSignedContractProps } from './types';

export const AllSignedContract = ({ showButton = true }: AllSignedContractProps) => {
  return (
    <div className={styles.Contract} data-testid="all-signed-contract">
      <BoxCheck className={styles.ContractIllustration} />
      <h2>Agreement received</h2>
      <p className={styles.ContractSubtitle}>
        Thank you for signing your agreement. <br></br> We’ve sent a copy of your signed contract to
        your email
      </p>
      {showButton && (
        <Button
          className={styles.ContractConfirmButton}
          size={ButtonSize.MEDIUM}
          type={ButtonType.PRIMARY}
          label="Return to dashboard"
          onClick={() => externalRedirect({ url: '/', openInNewTab: false })}
        />
      )}
    </div>
  );
};
