import { ExpandedContent } from '@all-in-one-web/common-ui';

import { SECTION_HEADING } from '@constants';

import { trackBAActionEvent } from '../../utils';
import styles from './OverdraftDetail.module.scss';
import type { OverdraftDetailProps } from './types';

export function OverdraftDetail({ details }: OverdraftDetailProps) {
  if (details.length === 0) {
    return null;
  }

  return (
    <ExpandedContent
      headLabel={SECTION_HEADING.SEE_DETAILS}
      testId="expanded-overdraft-detail"
      onToggleClick={() => trackBAActionEvent('product_see_details')}
    >
      <div className={styles.OverdraftDetailContainer}>
        {details.map(({ heading, value }, index) => (
          <p className={styles.OverdraftDetailRow} key={`detail-line-${index + 1}`}>
            <span className={styles.OverdraftDetailHeading}>{heading}</span>
            <span className={styles.OverdraftDetailValue}>{value}</span>
          </p>
        ))}
      </div>
    </ExpandedContent>
  );
}
