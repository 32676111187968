/**
 * This utils function contains general helper functions and
 * constants for bill pay feature
 */
import {
  type BillDetails,
  BillStatus,
  BillTransactionStatus,
  BillTransactionType,
  Country,
  CurrencyCode,
  type LinkedTransaction,
} from '@generated-fg';

import { type CustomerProducts } from '@models';

import { isValidBankTransferPayment, isValidBpayPayment } from './billConfirmForm';
import { BillPayStatus } from './constants';

export function getRecentTransaction({
  status,
  linkedTransactions,
}: {
  status?: BillStatus;
  linkedTransactions?: LinkedTransaction[];
}): LinkedTransaction | undefined {
  let transaction = undefined;
  switch (status) {
    case BillStatus.Scheduled:
      transaction = getScheduledTransaction(linkedTransactions);
      break;
    case BillStatus.Failed:
      transaction = getTransactionByStatus(linkedTransactions, BillTransactionStatus.Failed);
      break;
    case BillStatus.Paid:
      transaction = getTransactionByStatus(linkedTransactions, BillTransactionStatus.Success);
      break;
    default:
      break;
  }
  return transaction;
}

export const getScheduledTransaction = (transactions: LinkedTransaction[]): LinkedTransaction => {
  const scheduledTransaction = transactions?.find(
    transaction =>
      transaction.paymentType === BillTransactionType.Scheduled &&
      transaction.paymentStatus === BillTransactionStatus.Pending,
  );
  return scheduledTransaction;
};

export const getTransactionByStatus = (
  transactions: LinkedTransaction[],
  paymentStatus: BillTransactionStatus,
): LinkedTransaction => {
  const scheduledTransaction = transactions?.find(
    transaction => transaction.paymentStatus === paymentStatus,
  );
  return scheduledTransaction;
};

export const mapBillDisplayStatus = (status: BillStatus): string => {
  return status === BillStatus.Reviewed ? 'Not scheduled' : status;
};

export const isIncompleteScannedBill = (bill: BillDetails): boolean => {
  const countryCode = bill?.amount?.currency === CurrencyCode.Aud ? Country.Au : Country.Nz;

  return (
    !bill?.billerName &&
    !bill?.dueDate &&
    (!bill?.amount || bill?.amount?.amount === '0' || !bill?.amount?.amount) &&
    !bill?.description &&
    !isValidBankTransferPayment(bill?.paymentMethods, countryCode) &&
    !isValidBpayPayment(bill?.paymentMethods)
  );
};

type BillStatusHasMessageWithDate = BillStatus.Scheduled | BillStatus.Failed | BillStatus.Paid;
export const BillAlertMessageWithDate: Record<BillStatusHasMessageWithDate, (date) => string> = {
  [BillStatus.Scheduled]: scheduledDate =>
    `Bill is scheduled to be paid on ${scheduledDate}. Payment can take up to 2 business days to process.`,
  [BillStatus.Failed]: transactionFailedDate =>
    `We attempted to pay this bill on ${transactionFailedDate} but payment failed. Please check your account and try again.`,
  [BillStatus.Paid]: paidDate => `This bill was paid on ${paidDate}.`,
};

export const getBillsReferenceIdsMap = (
  bills: Pick<BillDetails, 'id' | 'linkedTransactions'>[],
) => {
  const referenceIdsMap = new Map<string, string>();
  bills.forEach(({ linkedTransactions, id: billId }) =>
    linkedTransactions?.forEach(({ referenceId }) => referenceIdsMap.set(referenceId, billId)),
  );
  return referenceIdsMap;
};

export const calculateBillPayStatus = ({
  email,
  bills,
}: {
  email: string;
  bills: Pick<BillDetails, 'status'>[];
}) => {
  if (!email) return BillPayStatus.EmailNotInit;

  if (bills.length === 0) return BillPayStatus.NoBills;

  if (bills.filter(item => item.status === BillStatus.Received).length)
    return BillPayStatus.HasReceivedBills;

  return null;
};

export const isBillPayEntitledUser = ({ BA, LOCM }: CustomerProducts): boolean => {
  return BA || LOCM;
};
