export const prod = {
  identityServer: {
    authority: 'https://identity.prospa.com',
  },
  hay: {
    apiUrl: 'https://api.prod.hay.co',
    authUrl: 'https://auth.prod.hay.co',
  },
  mobileGateway: {
    apiUrl: 'https://mobile-gateway.prospa.com',
  },
  feGateway: {
    apiUrl: 'https://frontend-gateway-api.apps.prospa.com',
  },
  fileGateway: {
    apiUrl: 'https://live-eng2-documents-docmgmt-file-gateway-api.apps.prospa.com',
  },
  sentry: {
    url: 'https://89d1b45d989a42b58d426b585682ec28@o246285.ingest.sentry.io/5880419',
    environment: 'production',
  },
  customerPortal: {
    dashboardUrl: 'https://my.prospa.com/',
    refinanceCalculator: 'https://my.prospa.com/sbl-refinance-calculator',
  },
  addressFinderKey: 'PCWHTGYFB7VX83E4U6MA',
  repaymentSchedule: {
    baseUri: 'https://sbl-api.prospa.com/v1/api/repaymentschedules/pdf',
  },
  lineOfCreditManagement: {
    loanAccountBaseUrl: 'https://live-api-loc-management.prospa.com',
  },
  businessAccountOnboarding: {
    baseUrl: 'https://ba-application.prospa.com',
  },
  cxApplicationWeb: {
    AU: {
      baseUrl: 'https://application.prospa.com',
    },
    NZ: {
      baseUrl: 'https://application.prospa.co.nz',
    },
  },
  limits: {
    BPAYSpend: 10000,
  },
  xero: {
    authenticationEndpoint: 'https://login.xero.com/identity/connect/authorize',
    scopes: ['offline_access', 'bankfeeds', 'accounting.settings'],
    clientId: 'B6FB9314167C49F6840997AA3D63279C',
  },
  clarity: {
    projectId: 'od37a7jrd5',
  },
};
