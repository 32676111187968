import { useEffect, useState } from 'react';

import { type SettingKeyValue, type SettingValue, useConfigCatClient } from 'configcat-react';

import { type FeatureFlags } from '@models';

import { type UseFeatureFlagsProps } from './types';
import { settingKeyValuesToFeatureFlags } from './utils';

/**
 * Why do we have two separate hooks to retrieve 1x FF vs all FFs?
 * (useFeatureFlag and useFeatureFlags)
 *
 * So that we have the option to retrieve a FF without setting up a user object
 * (since the user object is initialised during app context initialisation)
 */
export function useFeatureFlags({ audience, skip }: UseFeatureFlagsProps): {
  flags: FeatureFlags;
  loading: boolean;
  error?: unknown;
} {
  const client = useConfigCatClient();
  const [loading, setLoading] = useState(true);
  const [flags, setFlags] = useState<FeatureFlags>({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!loading || skip) return;
    try {
      if (audience) client.setDefaultUser(audience);
      client
        .getAllValuesAsync()
        .then((settingKeyValues: SettingKeyValue<SettingValue>[]) =>
          settingKeyValuesToFeatureFlags(settingKeyValues),
        )
        .then(flags => setFlags(flags))
        .catch(e => setError(e))
        .finally(() => setLoading(false));
    } catch {
      setLoading(false);
    }
  }, [audience, client, loading, skip]);

  if (skip) return { flags: {}, loading: false };

  return { flags, loading, error };
}
