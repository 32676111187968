import { useCallback, useState } from 'react';

import {
  type BankPayeeV2,
  type BpayPayeeV2,
  Country,
  CreateBPayErrorStatusV2,
  type CreatePayeeV2Input,
  type CreatePayeeV2Output,
  InputPaymentType,
  PaymentType,
  useCreatePayeeV2Mutation,
} from '@generated-fg';

import { PayAnyoneNewBpayErrorStrings } from '@constants';
import { formatNZAccountDetails, isNZAccount, transformBSBtoV2 } from '@utils';

import { type SecurityCodeModalProps } from '../SecurityCodeModal/types';
import {
  TABINDEX,
  type TUseSaveToAddressBookAction,
  type TUseSaveToAddressBookProps,
} from './types';

export const useSaveToAddressBookAction = ({
  onSuccess,
  payeeType,
  countryCode,
  onClose,
  onError,
  newPayeeRef,
  payFromAccount,
  currentTab,
}: TUseSaveToAddressBookProps): TUseSaveToAddressBookAction => {
  const [createPayeeV2, { loading: isSavingPayeeToAddressBook }] = useCreatePayeeV2Mutation();
  const [showSecurityCodeModal, setShowSecurityCodeModal] =
    useState<SecurityCodeModalProps['isCheck2FAStarted']>(false);

  const handleCloseModal = useCallback(() => {
    setShowSecurityCodeModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setShowSecurityCodeModal(true);
  }, []);

  const getNewBankPayeeInput = useCallback(
    (token: string): CreatePayeeV2Input => {
      if (!newPayeeRef || !newPayeeRef.current || !newPayeeRef.current.values) return null;

      const {
        values: {
          accountName: bankAccountName,
          BSB: bankStateBranch = '',
          accountNumber: bankAccountNumber,
        } = {},
      } = newPayeeRef.current;

      return isNZAccount(payFromAccount?.id, countryCode)
        ? {
            ...formatNZAccountDetails({ bankAccountNumber }),
            accountName: bankAccountName,
            countryCode: Country.Nz,
            paymentType: InputPaymentType.BankTransfer,
            stepUpToken: token,
          }
        : {
            account: bankAccountNumber,
            accountName: bankAccountName,
            bsb: transformBSBtoV2(bankStateBranch),
            countryCode: Country.Au,
            paymentType: InputPaymentType.BankTransfer,
            stepUpToken: token,
          };
    },
    [countryCode, newPayeeRef, payFromAccount?.id],
  );

  const onCreateBankPayeeSuccess = useCallback(
    (createPayeeV2: CreatePayeeV2Output) => {
      const { resetForm } = newPayeeRef.current;
      const { __typename: typeName } = createPayeeV2;

      if (typeName === 'CreatePayeeV2ErrorOutput') {
        onError();
        return;
      }

      if (typeName === 'CreatePayeeV2SuccessOutput') {
        const { payee: newPayee } = createPayeeV2;
        const payee = newPayee as BankPayeeV2;
        onSuccess(payee, true);
        onClose();
        resetForm();
      }
    },
    [newPayeeRef, onSuccess, onClose, onError],
  );

  const getNewBpayPayeeInput = useCallback(
    (token: string): CreatePayeeV2Input => {
      if (!newPayeeRef || !newPayeeRef.current || !newPayeeRef.current.values) return null;

      const {
        values: {
          billerCode: bPayBillerCode,
          referenceNumber: bPayReferenceNumber,
          nickname: bPayAccountName,
        } = {},
      } = newPayeeRef.current;

      return {
        accountName: bPayAccountName,
        bPayBillerCode: bPayBillerCode,
        bPayReference: bPayReferenceNumber,
        countryCode: Country.Au,
        paymentType: InputPaymentType.Bpay,
        stepUpToken: token,
      };
    },
    [newPayeeRef],
  );

  const onCreateBpayPayeeSuccess = useCallback(
    (createPayeeV2: CreatePayeeV2Output) => {
      const { resetForm, setFieldError } = newPayeeRef.current;
      const { __typename: typeName } = createPayeeV2;

      if (typeName === 'CreatePayeeV2ErrorOutput') {
        const { error } = createPayeeV2;
        switch (error) {
          case CreateBPayErrorStatusV2.NicknameAlreadyExists:
            setFieldError('nickname', PayAnyoneNewBpayErrorStrings.NicknameDuplicateError);
            break;
          case CreateBPayErrorStatusV2.InvalidBillerCode:
            setFieldError('billerCode', PayAnyoneNewBpayErrorStrings.BillerCodeNotFound);
            break;
          case CreateBPayErrorStatusV2.InvalidReference:
            setFieldError('referenceNumber', PayAnyoneNewBpayErrorStrings.ReferenceNumberInvalid);
            break;
          case CreateBPayErrorStatusV2.InvalidNickname:
            setFieldError('nickname', PayAnyoneNewBpayErrorStrings.NicknameInvalid);
            break;
        }
      }

      if (typeName === 'CreatePayeeV2SuccessOutput') {
        const { payee: newPayee } = createPayeeV2;
        const payee = newPayee as BpayPayeeV2;
        onSuccess(payee, false);
        onClose();
        resetForm();
      }
    },
    [newPayeeRef, onClose, onSuccess],
  );

  const onSaveToAddressBook = useCallback(
    token => {
      setShowSecurityCodeModal(false);

      if (!token) {
        console.error('onSaveToAddressBook(): 2FA token is missing.');
        return;
      }

      const isCreateBpayPayee = payeeType === PaymentType.Bpay || currentTab === TABINDEX.BPAY;
      const input: CreatePayeeV2Input = isCreateBpayPayee
        ? getNewBpayPayeeInput(token)
        : getNewBankPayeeInput(token);

      const onCreatePayeeV2Success: (createPayeeV2) => void = isCreateBpayPayee
        ? onCreateBpayPayeeSuccess
        : onCreateBankPayeeSuccess;

      createPayeeV2({ variables: { input } })
        .then(data => onCreatePayeeV2Success(data?.data?.createPayeeV2))
        .catch(onError);
    },
    [
      payeeType,
      currentTab,
      getNewBpayPayeeInput,
      getNewBankPayeeInput,
      onCreateBpayPayeeSuccess,
      onCreateBankPayeeSuccess,
      createPayeeV2,
      onError,
    ],
  );

  return {
    isSavingPayeeToAddressBook,
    showSecurityCodeModal,
    handleCloseModal,
    onSaveToAddressBook,
    addPayeeToAddressBook: handleOpenModal,
  };
};
