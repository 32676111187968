import { useCallback, useState } from 'react';

import { StatementFileType } from '@generated-fg';

import { DownloadButtons } from '@components';

import { useDownloadOptions } from '@hooks';

import type { DownloadButtonsProps } from '../DownloadButtons/types';
import styles from './PastStatements.module.scss';
import type { StatementRowProps } from './types';

export const StatementRow = ({ title, onClick, showCsv = true }: StatementRowProps) => {
  const [selectedFileType, setSelectedFiletype] = useState<StatementFileType>();

  const handleClick: DownloadButtonsProps['onClick'] = useCallback(
    async fileType => {
      setSelectedFiletype(fileType as StatementFileType);
      await onClick(fileType);
      setSelectedFiletype(undefined);
    },
    [onClick],
  );

  const options = useDownloadOptions({
    loaders: {
      pdf: selectedFileType === StatementFileType['Pdf'],
      csv: selectedFileType === StatementFileType['Csv'],
    },
    selectedFileType,
    showCsv,
  });

  return (
    <div className={styles.PastStatementsRow}>
      <p className="pds-text">{title}</p>
      <span className={styles.ButtonRow}>
        <DownloadButtons onClick={handleClick} options={options} />
      </span>
    </div>
  );
};
