import { env } from '../environments';

const IDENTITY_BASE_URL =
  env === 'prod' ? 'https://identity.prospa.com' : 'https://staging-identity.prospa.com';

const CUSTOMER_PORTAL_URL =
  env === 'prod' ? 'https://my.prospa.com/' : 'https://staging-my.prospa.com/';

export const OVERDRAFT_PORTAL_URL =
  env === 'prod'
    ? 'https://overdraft-application.prospa.com/'
    : 'https://staging-overdraft-application.prospa.com/';

const CLIENT_ID = 'prospa_businessaccount_portal';

type AccountSetting = 'phone-number' | 'email' | 'password';

export const getIdentityRoute = (setting: AccountSetting) =>
  `${IDENTITY_BASE_URL}/account/${setting}/change?clientId=${CLIENT_ID}`;

export const getCustomerPortalRoute = (product: string, id: string): string =>
  `${CUSTOMER_PORTAL_URL}${product}/${id}`;

export const isMobileAppSession = (amr: unknown) => {
  if (Array.isArray(amr))
    return amr.some(authMethod => authMethod.toLowerCase() === 'mobilewebsso');
  return undefined;
};
