import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import { PAYMENT_LAG_TIME } from '@constants';
import {
  delay,
  evictBusinessAccountRelatedCache,
  evictLocRelatedCache,
  evictSBLMRelatedCache,
} from '@utils';

import { ProductTypes } from '../../models/Account';
import { type TUseRefetchQueriesData } from './types';

export const useRefetchQueries = (): TUseRefetchQueriesData => {
  const client = useApolloClient();

  const afterPaymentRefetch = useCallback(
    async ({ payFromAccount, productId }) => {
      // BA balance data has lag when refetching after payment.
      // This is to increase the chance of getting back latest balance.
      await delay(PAYMENT_LAG_TIME);
      client.refetchQueries({
        updateCache: cache => {
          payFromAccount?.productType === ProductTypes.BA
            ? evictBusinessAccountRelatedCache({ cache, productId })
            : evictLocRelatedCache({ cache, productId });
        },
      });
    },
    [client],
  );

  const afterRepaymentRefetch = useCallback(
    async ({ isLocMRepayment, productId }) => {
      // LOCM repayment data has lag when refetching after payment.
      // This is to increase the chance of getting back latest balance.
      await delay(PAYMENT_LAG_TIME);
      client.refetchQueries({
        updateCache: cache => {
          isLocMRepayment
            ? evictLocRelatedCache({ cache, productId })
            : evictSBLMRelatedCache({ cache, productId });
        },
      });
    },
    [client],
  );

  return { afterPaymentRefetch, afterRepaymentRefetch };
};
