/**
 * This utils file contains all functions related to
 * confirm bill form data converting
 */
import { format } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';

import {
  type BankPayeeV2,
  type BillDetails,
  type BillPaymentMethodInput,
  BillPaymentType,
  BillStatus,
  type BpayPayeeV2,
  Country,
  PaymentType,
  type UpdateBillInput,
} from '@generated-fg';

import { type ConfirmBillFormInput } from '@components/types';

import { formatBsb, isValidBsbFormat } from '@utils';

import { type BillContextType } from '../../contexts/BillContext';
import { type NewPayeeValues, type PayeeV2 } from '../../models/PayAnyone';
import { formatNZAccountDetails } from '../payees';

export const EMPTY_BANK_TRANSFER_PAYMENT = {
  accountName: '',
  accountNumber: '',
  bsb: '',
  reference: '',
};

export const EMPTY_BPAY_PAYMENT = {
  billerCode: '',
  reference: '',
};

export const EMPTY_NEW_PAYEE_VALUES = {
  accountName: '',
  accountNumber: '',
  BSB: '',
  billerCode: '',
  nickname: '',
  referenceNumber: '',
  saveToAddressBook: false,
};

// NOTE: use it in convertConfirmBillFormInputToUpdateBillInput
export const setBillBankTransferReference = (
  reference: string,
  { bankTransferPayment, ...paymentMethod }: BillPaymentMethodInput,
): BillPaymentMethodInput => {
  return {
    ...paymentMethod,
    bankTransferPayment: {
      ...bankTransferPayment,
      reference,
    },
  };
};

export const normalisePaymentMethods = (
  paymentMethods?: BillDetails['paymentMethods'],
): BillDetails['paymentMethods'] | null => {
  if (!paymentMethods) return null;

  const normalisedPaymentMethods = cloneDeep(paymentMethods);

  const bsb = paymentMethods?.bankTransferPayment?.bsb;
  if (bsb && !isValidBsbFormat(bsb)) {
    normalisedPaymentMethods.bankTransferPayment.bsb = formatBsb(bsb);
  }

  return normalisedPaymentMethods;
};

export const getPreScannedPaymentMethodInput = (
  paymentMethods?: BillDetails['paymentMethods'],
  countryCode = Country.Au,
  isBpayAllowed = true,
): BillPaymentMethodInput | null => {
  // NOTE: only prefill two payment methods if the bill is not reviewed
  const {
    bpayPayment = EMPTY_BPAY_PAYMENT,
    bankTransferPayment = EMPTY_BANK_TRANSFER_PAYMENT,
    paymentTypes = [],
  } = paymentMethods || {};
  const isNzAccount = countryCode === Country.Nz;
  const allowedPaymentTypes = isBpayAllowed
    ? paymentTypes
    : paymentTypes.filter(type => type !== BillPaymentType.Bpay);

  return {
    paymentTypes: allowedPaymentTypes,
    bpayPayment: isBpayAllowed
      ? {
          billerCode: bpayPayment?.billerCode || '',
          reference: bpayPayment?.reference || '',
        }
      : EMPTY_BPAY_PAYMENT,
    bankTransferPayment: {
      accountName: bankTransferPayment?.accountName || '',
      accountNumber: isNzAccount
        ? formatNzAccountNumber(bankTransferPayment?.accountNumber || '')
        : bankTransferPayment?.accountNumber || '',
      bsb: isNzAccount ? '' : bankTransferPayment?.bsb,
      reference: bankTransferPayment?.reference,
    },
  };
};

// NOTE: use it in getPreFillConfirmBillFormInput
export const getConfirmedPaymentMethodInput = (
  paymentMethods?: BillDetails['paymentMethods'],
  countryCode = Country.Au,
): BillPaymentMethodInput => {
  const normalisedPaymentMethods: BillDetails['paymentMethods'] =
    normalisePaymentMethods(paymentMethods);
  paymentMethods = { ...normalisedPaymentMethods };

  const {
    bpayPayment = EMPTY_BPAY_PAYMENT,
    bankTransferPayment = EMPTY_BANK_TRANSFER_PAYMENT,
    paymentTypes = [],
  } = paymentMethods || {};

  if (isValidBankTransferPayment(paymentMethods, countryCode)) {
    const { accountName, accountNumber, bsb, reference } = bankTransferPayment || {};
    return {
      paymentTypes,
      bankTransferPayment: {
        accountName,
        accountNumber,
        bsb: countryCode === Country.Au ? bsb : '',
        reference,
      },
    };
  }

  if (isValidBpayPayment(paymentMethods)) {
    const { billerCode, reference } = bpayPayment || {};
    return {
      paymentTypes,
      bpayPayment: {
        billerCode,
        reference,
      },
    };
  }
  return null;
};

// NOTE: use it in convertPayeeV2ToBillPaymentMethodInput
export const getNzBankAccountNumber = (
  payee: Pick<BankPayeeV2, 'account' | 'bank' | 'suffix' | 'branch'>,
): string => {
  const { account, bank, suffix, branch } = payee || {};

  if (!account || !bank || !suffix || !branch) return '';

  return `${bank}-${branch}-${account}-${suffix}`;
};

export const formatNzAccountNumber = (bankAccountNumber: string): string => {
  const nzBankAccountDetail = formatNZAccountDetails({ bankAccountNumber });
  return getNzBankAccountNumber(nzBankAccountDetail);
};

// NOTE: use it in convertConfirmBillFormInputToBillPayContext
export const convertBillPaymentMethodToPayeeV2 = (
  paymentMethod: BillPaymentMethodInput,
  billerName: string,
  countryCode = Country.Au,
): PayeeV2 => {
  const { bpayPayment, bankTransferPayment } = paymentMethod;
  if (isValidBankTransferPayment(paymentMethod, countryCode)) {
    const { accountName, accountNumber, bsb } = bankTransferPayment || {};
    if (countryCode === Country.Nz) {
      const { bank, branch, account, suffix } = formatNZAccountDetails({
        bankAccountNumber: accountNumber,
      });
      return {
        account,
        accountName,
        bank,
        branch,
        suffix,
        countryCode,
        paymentType: PaymentType.BankTransfer,
      } as BankPayeeV2;
    } else {
      return {
        account: accountNumber,
        accountName,
        bsb,
        countryCode,
        paymentType: PaymentType.BankTransfer,
      } as BankPayeeV2;
    }
  }

  if (isValidBpayPayment(paymentMethod)) {
    const { billerCode, reference } = bpayPayment || {};
    return {
      bPayBillerCode: billerCode,
      bPayReference: reference,
      countryCode,
      billerName,
      paymentType: PaymentType.Bpay,
    } as BpayPayeeV2;
  }
};

export const isValidBpayPayment = (
  paymentMethods: BillPaymentMethodInput | BillDetails['paymentMethods'],
): boolean => {
  return (
    paymentMethods?.paymentTypes &&
    paymentMethods?.paymentTypes.includes(BillPaymentType.Bpay) &&
    !!paymentMethods?.bpayPayment?.billerCode &&
    !!paymentMethods?.bpayPayment?.reference
  );
};

export const isValidBankTransferPayment = (
  paymentMethods: BillPaymentMethodInput | BillDetails['paymentMethods'],
  countryCode = Country.Au,
): boolean => {
  const isPaymentBankTransfer = paymentMethods?.paymentTypes?.includes(
    BillPaymentType.BankTransfer,
  );
  const isAccountNumberValid = !!paymentMethods?.bankTransferPayment?.accountNumber;
  const isAccountNameValid = !!paymentMethods?.bankTransferPayment?.accountName;
  const isBsbValid =
    countryCode === Country.Au
      ? !!paymentMethods?.bankTransferPayment?.bsb &&
        isValidBsbFormat(paymentMethods?.bankTransferPayment?.bsb)
      : !paymentMethods?.bankTransferPayment?.bsb;

  return isPaymentBankTransfer && isAccountNumberValid && isAccountNameValid && isBsbValid;
};

export const getPreFillConfirmBillFormInput = (
  bill?: BillDetails,
  isBpayAllowed = true,
  countryCode = Country.Au,
): ConfirmBillFormInput => {
  const {
    amount: { amount = '0' } = {},
    description = '',
    dueDate,
    billerABN,
    billerName = '',
    paymentMethods,
    status,
  } = bill || {};

  const paymentMethodInput =
    status === BillStatus.Received
      ? getPreScannedPaymentMethodInput(paymentMethods, countryCode, isBpayAllowed)
      : getConfirmedPaymentMethodInput(paymentMethods, countryCode);

  return {
    amount: !amount || amount !== '0' ? amount : null,
    reference: paymentMethods?.bankTransferPayment?.reference || '',
    description,
    dueDate: dueDate ? new Date(dueDate) : null,
    billerABN: billerABN || '',
    billerName,
    paymentMethod: paymentMethodInput,
  };
};

// NOTE: when user confirm the bill, only one payment method will be submitted
// if there are two payment types scanned, user need to choose one and in request body
// another one will be cleared as null value, the selected payee type get from payee modal
export const convertPayeeV2ToBillPaymentMethodInput = (
  payeeV2: PayeeV2,
): BillPaymentMethodInput => {
  const { countryCode, paymentType } = payeeV2 || {};
  const isBpayPayee = paymentType === PaymentType.Bpay;
  const isNzPayee = countryCode === Country.Nz;
  const paymentTypes = isBpayPayee ? [BillPaymentType.Bpay] : [BillPaymentType.BankTransfer];
  if (isBpayPayee) {
    const { bPayBillerCode, bPayReference } = payeeV2 as BpayPayeeV2;
    return {
      paymentTypes,
      bpayPayment: {
        billerCode: bPayBillerCode,
        reference: bPayReference,
      },
      bankTransferPayment: EMPTY_BANK_TRANSFER_PAYMENT,
    };
  } else {
    const { accountName, bsb, account, bank, suffix, branch } = payeeV2 as BankPayeeV2;
    return {
      paymentTypes,
      bankTransferPayment: {
        accountName,
        accountNumber: isNzPayee
          ? getNzBankAccountNumber({ account, bank, suffix, branch })
          : account,
        bsb,
        reference: '',
      },
      bpayPayment: EMPTY_BPAY_PAYMENT,
    };
  }
};

export const convertPreScannedBillPaymentMethodToNewPayeeValues = (
  paymentMethod: BillPaymentMethodInput,
  billerName = '',
  countryCode = Country.Au,
) => {
  if (paymentMethod?.paymentTypes?.includes(BillPaymentType.Bpay)) {
    return {
      ...EMPTY_NEW_PAYEE_VALUES,
      billerCode: paymentMethod?.bpayPayment.billerCode || '',
      referenceNumber: paymentMethod?.bpayPayment.reference || '',
      nickname: billerName,
    };
  } else {
    return {
      ...EMPTY_NEW_PAYEE_VALUES,
      accountName: paymentMethod?.bankTransferPayment.accountName || '',
      accountNumber: paymentMethod?.bankTransferPayment.accountNumber || '',
      BSB: countryCode === Country.Au ? paymentMethod?.bankTransferPayment.bsb || '' : '',
    };
  }
};

export const convertConfirmedBillPaymentMethodInputToNewPayeeValues = (
  paymentMethod: BillPaymentMethodInput,
  billerName = '',
  countryCode = Country.Au,
): NewPayeeValues => {
  const { bpayPayment = null, bankTransferPayment = null } = paymentMethod || {};

  let payeeValues = EMPTY_NEW_PAYEE_VALUES;

  if (isValidBpayPayment(paymentMethod)) {
    const { billerCode, reference } = bpayPayment || {};
    payeeValues = {
      ...payeeValues,
      billerCode,
      nickname: billerName,
      referenceNumber: reference,
    };
  }

  if (isValidBankTransferPayment(paymentMethod, countryCode)) {
    const { accountName, accountNumber, bsb } = bankTransferPayment || {};
    payeeValues = {
      ...payeeValues,
      accountName,
      accountNumber,
      BSB: bsb === null ? '' : bsb,
    };
  }

  return payeeValues;
};

export const convertConfirmBillFormInputToUpdateBillInput = (
  formInput: ConfirmBillFormInput,
): UpdateBillInput => {
  const { billerABN, billerName, reference, amount, description, dueDate, paymentMethod } =
    formInput;
  const paymentMethodInput = setBillBankTransferReference(reference, paymentMethod);
  return {
    amount: parseFloat(amount),
    description,
    dueDate: format(dueDate, 'yyyy-MM-dd'),
    paymentMethod: paymentMethodInput,
    billerABN,
    billerName,
  };
};

export const convertConfirmBillFormInputToBillPayContext = (
  formInput: ConfirmBillFormInput,
  countryCode: Country,
): BillContextType => {
  const { billerName, paymentMethod, amount, reference, description, dueDate } = formInput;
  const payee = convertBillPaymentMethodToPayeeV2(paymentMethod, billerName, countryCode);
  return {
    payee,
    values: {
      amount,
      reference,
      description,
      payLaterScheduledDate: dueDate,
    },
  };
};
