import {
  AnalyticsBrowser,
  type AnalyticsBrowserSettings,
  type InitOptions,
  type UserTraits,
} from '@segment/analytics-next';

import { type AnalyticsService, type LogEvent, type UserProperties } from '../../types';
import { transformTrackingStringToParamsObject } from './utils';

const analytics = new AnalyticsBrowser();

export class SegmentService implements AnalyticsService {
  private segmentClient: AnalyticsBrowser;
  private traits: UserTraits;

  constructor(config: AnalyticsBrowserSettings, options: InitOptions = {}) {
    this.segmentClient = analytics.load(config, options);
    this.traits = {};
  }

  logPageView = async (pageName?: string, category?: string) => {
    await this.segmentClient.page(
      category,
      pageName,
      {},
      {
        context: { traits: { ...this.traits } },
      },
    );
  };

  logEvent: LogEvent = async (
    { eventName: name, eventParams: params },
    options = { includeTraits: true },
  ) => {
    // segment
    let segmentEventName = name;

    // if it is a string format params, transform it to an params object
    let segmentParams =
      typeof params === 'string' ? transformTrackingStringToParamsObject(params) : params;

    if (segmentEventName) {
      await this.segmentClient.track(
        segmentEventName,
        {
          ...(segmentParams || {}),
        },
        { context: { traits: options.includeTraits ? { ...this.traits } : {} } },
      );
    } else {
      console.warn(
        'SegmentService.logEvent called without an event. Please make sure this is intentional or add the right event to send.',
      );
    }
  };

  identifyUser = async (properties: UserProperties) => {
    if (properties?.cdpId) {
      this.traits = {
        id: properties.cdpId,
        prospa_identity_id: properties.userId,
        platform: properties.platform,
        first_name: properties.firstName,
        last_name: properties.lastName,
        email: properties.email,
        products: properties.products,
        hay_account_id: properties.hayAccountId,
        session_id: properties.sessionId,
        ...properties.extras,
      };
      Object.keys(this.traits).forEach(
        key => this.traits[key] === undefined && delete this.traits[key],
      );
      await this.segmentClient.identify(properties.cdpId, { ...this.traits });
    } else {
      console.warn('cdp_id is missing for segment tracking');
    }
  };

  reset = async () => {
    await this.segmentClient.reset();
    this.traits = {};
  };
}
