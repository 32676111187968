import { type FC } from 'react';

import { Question as QuestionMarkIcon } from '@prospa/icons';

import { Modal } from '@components';

import './LogoutModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  logout: () => void;
};
export const LogoutModal: FC<Props> = ({ isOpen, onClose, logout }) => {
  return (
    <Modal
      size="large"
      isOpen={isOpen}
      onClose={onClose}
      showCloseModal
      body={
        <div className="logout-modal">
          <QuestionMarkIcon className="logout-modal__icon" />
          <h4 className="logout-modal__heading">Are you sure you want to log out?</h4>
        </div>
      }
      primaryCTALabel="Log out"
      primaryCTAOnClick={logout}
      secondaryCTALabel="Cancel"
      secondaryCTAOnClick={onClose}
    />
  );
};
