import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { type ClarityClient } from '@all-in-one-web/clarity';

import { isMobileAppSession, setClarityUser, setSentryUser } from '@utils';

export const useSetIntegrationsUserWithAuth = ({ clarity }: { clarity: ClarityClient }) => {
  const { user, isLoading } = useAuth();
  const userId = user?.profile?.sub;
  const amr = user?.profile?.amr;

  const isMobileAppUser = String(isMobileAppSession(amr));

  useEffect(() => {
    if (userId && !isLoading) {
      setSentryUser({ userId, extras: { isMobileAppUser } });
      setClarityUser({ userId, extras: { isMobileAppUser } }, clarity);
    }
  }, [amr, userId, isMobileAppUser, clarity, isLoading]);
};
