import { useSessionStorage } from '@all-in-one-web/common-ui';

import { SESSION_STORAGE_STATE } from '@constants';

import type { SessionStorageStatesInApp } from '../models/AppData';

export type SessionStorageState = {
  displayMobileAppBanner: boolean;
  displayMobileAppModal: boolean;
};

type SessionStorageStateTypes = SessionStorageState['displayMobileAppBanner'];

const initState = {
  displayMobileAppBanner: true,
  displayMobileAppModal: true,
};

export function useStateInSessionStorage(): SessionStorageStatesInApp {
  const [sessionStorageState, setSessionStorageStates] = useSessionStorage(
    SESSION_STORAGE_STATE,
    initState,
  );

  const setSessionStorageState =
    (key: keyof SessionStorageState) => (value: SessionStorageStateTypes) => {
      const state = sessionStorageState;
      let updatedState = state;
      switch (key) {
        case 'displayMobileAppBanner': {
          updatedState = { ...state, [key]: value };
          break;
        }
        case 'displayMobileAppModal': {
          updatedState = { ...state, [key]: value };
          break;
        }
      }
      return setSessionStorageStates(updatedState);
    };

  const { displayMobileAppBanner, displayMobileAppModal } = sessionStorageState;

  return {
    appBanner: {
      displayMobileAppBanner,
      setDisplayMobileAppBanner: setSessionStorageState('displayMobileAppBanner'),
    },
    mobileAppModal: {
      displayMobileAppModal,
      setDisplayMobileAppModal: setSessionStorageState('displayMobileAppModal'),
    },
  };
}
