import { BlockLoader } from '@components';

import styles from './InformationTooltipLoader.module.scss';

export const InformationTooltipLoader = () => {
  return (
    <section data-testid="tooltip-loader" className={styles.TooltipLoader}>
      <div data-testid="tooltip-content" className={styles.TooltipContent}>
        <BlockLoader />
      </div>
    </section>
  );
};
