import {
  CurrencyCode,
  PaymentFrequencyType,
  ScheduledTransferDocument,
  type ScheduledTransferInput,
} from '@generated-fg';

import { PayAnyoneTabs } from '@constants';
import {
  currencyFormatter,
  dateToString,
  dateToStringDisplay,
  formatDateInDayMonthYear,
  isAuBankPayeeV2,
  isBPayPayeeV2,
  isNZBankPayeeV2,
} from '@utils';

import { type AccountItem, ProductTypes } from '../../../models/Account';
import {
  type PayAnyoneFormValues,
  type PayeeV2,
  RecurringDurationType,
} from '../../../models/PayAnyone';
import { type FormattedPayAnyoneRequest, type PayAnyoneDisplayData } from './PayAnyoneHelper';

function getScheduledTransferRequestPayload(
  formData: PayAnyoneFormValues,
  payee: PayeeV2,
  paymentFrequencyType: PayAnyoneTabs,
  payFromAccount: AccountItem,
): Omit<ScheduledTransferInput, 'verificationCode' | 'correlationId'> {
  const isPayLater = paymentFrequencyType === PayAnyoneTabs.PAY_LATER;
  const isPayRecurring = paymentFrequencyType === PayAnyoneTabs.RECURRING;

  const hasSpecificEndDate = formData.duration === RecurringDurationType.SPECIFIC;
  const hasSpecificNumberOfPayments =
    formData.duration === RecurringDurationType.EXACT && formData?.numberOfPayments !== '';

  const paymentDetailsData = {
    productId: payFromAccount.id,
    amount: {
      amount: Number(formData.amount),
      currency: isNZBankPayeeV2(payee) ? CurrencyCode.Nzd : CurrencyCode.Aud,
    },
    description: formData.description,
    reference: formData.reference,
  };

  const payeeData = {
    ...(isNZBankPayeeV2(payee) && {
      nzPayee: {
        accountName: payee.accountName,
        accountNumber: payee.account,
        bankId: payee.bank,
        branch: payee.branch,
        suffix: payee.suffix,
      },
    }),
    ...(isAuBankPayeeV2(payee) && {
      auPayee: {
        accountName: payee.accountName,
        accountNumber: payee.account,
        bsb: payee.bsb,
      },
    }),
    ...(isBPayPayeeV2(payee) && {
      bPayPayee: {
        accountName: payee.accountName || payee.billerName,
        bPayBillerCode: payee.bPayBillerCode,
        bPayReference: payee.bPayReference,
      },
    }),
  };

  const payLaterData = {
    ...(isPayLater && {
      paymentFrequencyType: PaymentFrequencyType.Once,
      startDate: dateToString(formData.payLaterScheduledDate),
    }),
  };

  const payRecurringData = {
    ...(isPayRecurring && {
      startDate: dateToString(formData.startDate),
      paymentFrequencyType: PaymentFrequencyType[formData.paymentFrequency],
      ...(hasSpecificEndDate && {
        endDate: dateToString(formData.endDate),
      }),
      ...(hasSpecificNumberOfPayments && {
        numberOfPayments: Number(formData.numberOfPayments),
      }),
    }),
  };

  const requestPayload: Omit<ScheduledTransferInput, 'verificationCode' | 'correlationId'> = {
    ...paymentDetailsData,
    ...payeeData,
    ...payLaterData,
    ...payRecurringData,
  };

  return requestPayload;
}

function formatPaymentDateText(
  formData: PayAnyoneFormValues,
  paymentFrequencyType: PayAnyoneTabs,
): string {
  switch (true) {
    case paymentFrequencyType === PayAnyoneTabs.PAY_LATER: {
      return `Scheduled for ${formatDateInDayMonthYear(
        dateToStringDisplay(formData.payLaterScheduledDate),
      )}`;
    }
    case paymentFrequencyType === PayAnyoneTabs.RECURRING &&
      formData.duration === RecurringDurationType.EXACT: {
      return `Start on ${formatDateInDayMonthYear(
        dateToStringDisplay(formData.startDate),
      )}\nDuration of ${formData.numberOfPayments} Payments`;
    }
    case paymentFrequencyType === PayAnyoneTabs.RECURRING &&
      formData.duration === RecurringDurationType.SPECIFIC: {
      return `Start on ${formatDateInDayMonthYear(
        dateToStringDisplay(formData.startDate),
      )}\nEnds on ${formatDateInDayMonthYear(dateToStringDisplay(formData.endDate))}`;
    }
    case paymentFrequencyType === PayAnyoneTabs.RECURRING &&
      formData.duration === RecurringDurationType.NONE: {
      return `Start on ${formatDateInDayMonthYear(
        dateToStringDisplay(formData.startDate),
      )}\nNo end date`;
    }
    default:
      return '';
  }
}

function getDisplayData(
  formData: PayAnyoneFormValues,
  payee: PayeeV2,
  paymentFrequencyType: PayAnyoneTabs,
): PayAnyoneDisplayData {
  const newPayeeName = payee.accountName;

  return {
    amount: currencyFormatter(formData.amount),
    description: formData.description,
    reference: formData.reference,
    payeeName: newPayeeName,
    paymentDateText: formatPaymentDateText(formData, paymentFrequencyType),
  };
}

export const formatPayAnyoneRecurringPayload = (
  formData: PayAnyoneFormValues,
  payee: PayeeV2,
  paymentFrequencyType: PayAnyoneTabs,
  payFromAccount: AccountItem,
): FormattedPayAnyoneRequest => {
  switch (payFromAccount?.productType) {
    case ProductTypes.BA:
    case ProductTypes.LOC:
    case ProductTypes.LOCM: {
      return {
        mutation: ScheduledTransferDocument,
        payload: getScheduledTransferRequestPayload(
          formData,
          payee,
          paymentFrequencyType,
          payFromAccount,
        ),
        displayData: getDisplayData(formData, payee, paymentFrequencyType),
      };
    }
  }
};
