import lottie from 'lottie-web/build/player/lottie_light.min';

import type { GetLottieParam } from './types';

export const getLottie = ({
  containerRef,
  loop,
  animationData,
  autoplay = true,
}: GetLottieParam) => {
  return lottie.loadAnimation({
    container: containerRef.current,
    renderer: 'svg',
    loop: !!loop,
    autoplay,
    animationData: animationData,
    rendererSettings: {},
  });
};
