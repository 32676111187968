import { useCallback, useContext, useMemo } from 'react';

import { type CaptureContext } from '@sentry/types';

import { captureException, captureMessage } from '@utils';

import { AppContext } from '../../contexts';

type TLogMessage = (message: string, context?: CaptureContext) => string;
type TLogException = (exception: unknown, context?: CaptureContext) => string;

export type TUseAppLoggerReturn = {
  logMessage: TLogMessage;
  logException: TLogException;
};

export const useAppLogger = (): TUseAppLoggerReturn => {
  const { identityDetails, accountId: hayAccountId, signInInfo } = useContext(AppContext);
  const { id: identityId, givenName: username } = identityDetails || {};

  const user = useMemo(
    () => ({
      ...signInInfo,
      id: identityId, // there should be a field called `id` https://docs.sentry.io/platforms/node/guides/connect/enriching-events/identify-user/
      hayAccountId,
      username,
    }),
    [signInInfo, identityId, hayAccountId, username],
  );

  /**
   * logMessage does not support a stack trace but uses the first argument as the title of the issue.
   * To attach an error, use extra.error
   */
  const logMessage: TLogMessage = useCallback(
    (message, context) => {
      return captureMessage(message, {
        level: 'error',
        ...context,
        user,
        extra: {
          appLogger: true,
          ...context['extra'],
        },
      });
    },
    [user],
  );

  /**
   * logException can display stack trace for Error instances. However, setting issue title is not possible
   */
  const logException: TLogException = useCallback(
    (exception, context) => {
      return captureException(exception, {
        level: 'error',
        ...context,
        user,
        extra: {
          appLogger: true,
          ...context['extra'],
        },
      });
    },
    [user],
  );

  return {
    logMessage,
    logException,
  };
};
