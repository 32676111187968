import { type ReactNode } from 'react';

import { type ModalProps } from '@components/types';

import { type TNewFeatureFF } from '@models';

export type NewFeatureModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  title?: string;
  body?: string;
  media?: {
    background?: string;
    backgroundMobile?: string;
  };
  onPrimaryCTA?: () => void;
  ctaLabel?: string;
  newFeatureViewAction?: TNewFeatureFF['featureViewAction'];
  badge?: ReactNode;
};

export type NewFeatureModalStyleProps = Pick<NewFeatureModalProps, 'media'>;

export enum ReleasedNewFeatures {
  NULL = '',
  XERO = 'xero',
  BILL_PAY = 'bill-pay',
  XERO_LOC = 'xero-loc',
}
