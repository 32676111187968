import {
  type BankPayeeV2,
  type BpayPayeeV2,
  type Mutation as MutationsFrontendGateway,
  TransferError,
} from '@generated-fg';

export enum PaymentFrequencyType {
  NOW = 'Now',
  LATER = 'Later',
  RECURRING = 'Recurring',
}

export enum RecurringFrequencyType {
  WEEKLY = 'Weekly',
  FORTNIGHTLY = 'Fortnightly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}

export enum RecurringDurationType {
  NONE = 'No end date',
  SPECIFIC = 'End on a specific date',
  EXACT = 'Exact number of payments',
}
export interface PayAnyoneFormValues {
  amount: string;
  description: string;
  reference: string;
  payLaterScheduledDate?: Date;
  paymentFrequency: RecurringFrequencyType;
  startDate?: Date;
  duration: RecurringDurationType;
  endDate?: Date;
  numberOfPayments?: string | '';
}

export interface NewBankAccountFormValues {
  bsbNumber: string;
  bankAccountNumber: string;
  bankAccountName: string;
}

export interface NewBpayFormValues {
  billerCode: string;
  referenceNumber: string;
  nickName: string;
}
export enum PayAnyoneMutation {
  BANK_TRANSFER_MUTATION,
  PAY_ID_TRANSFER_MUTATION,
  BPAY_TRANSFER_MUTATION,
}

export interface CustomPayee {
  id?: string;
  name: string;
  createDate?: string;
  bankAccountName?: string;
  bankStateBranch?: string;
  bankAccountNumber?: string;
  bPayBillerCode?: string;
  bPayReference?: string;
  bPayBillerName?: string;
  paymentType?: PaymentTypeString;
  sortedName?: string;
  maskedAccountNumber?: string;
}
export type PayeeV2 = BankPayeeV2 | BpayPayeeV2;

export enum PaymentTypeString {
  BankTransfer = 'BankTransfer',
  BPay = 'BPay',
  PayId = 'PayId',
}

export interface NewPayee {
  bankAccountName: string;
  bankAccountNumber: string;
  bsbNumber: string;
  billerCodeNumber: string;
  referenceNumber: string;
  nickname: string;
}

export type PayAnyoneMutations = Pick<MutationsFrontendGateway, 'transfer' | 'scheduledTransfer'>;

export interface Notification {
  transactionEvent: TransactionEvent;
}

export interface TransactionEvent {
  transactionHayId: string;
  accountHayId: string;
}

export type NewPayeeValues = {
  billerCode: string;
  referenceNumber: string;
  nickname: string; // bpay
  accountNumber: string;
  BSB: string;
  accountName: string; // bankaccount
  saveToAddressBook: boolean;
};

export const initialFormValues: PayAnyoneFormValues = {
  amount: '',
  description: '',
  reference: '',
  duration: '' as RecurringDurationType,
  paymentFrequency: '' as RecurringFrequencyType,
  numberOfPayments: '',
};

export enum TransferErrorLevel {
  InternalError = TransferError.InternalError,
}

export enum BpayTransferWarningLevel {
  RefusedBpayInvalidBillerCode = TransferError.RefusedBpayInvalidBillerCode,
  RefusedBpayInvalidPayment = TransferError.RefusedBpayInvalidPayment,
  RefusedBpayInvalidReference = TransferError.RefusedBpayInvalidReference,
  RefusedBpayRejected = TransferError.RefusedBpayRejected,
  RefusedDailyBpayLimitBreached = TransferError.RefusedDailyBpayLimitBreached,
}

export enum BankTransferWarningLevel {
  Fraud = TransferError.Fraud,
  RefusedDailyTransfersOutLimitBreached = TransferError.RefusedDailyTransfersOutLimitBreached,
}

export const TransferWarningLevel = { ...BankTransferWarningLevel, ...BpayTransferWarningLevel };

export enum ScheduledTransferErrorLevel {
  InvalidPayment = TransferError.InvalidPayment,
}

export enum ScheduledBankTransferWarningLevel {
  LimitError = TransferError.LimitError,
}

export const ScheduledTransferWarningLevel = {
  ...ScheduledBankTransferWarningLevel,
  ...BpayTransferWarningLevel,
};

export enum SecurityCodeState {
  INACTIVE = 0,
  CONFIRM_PAYMENT = 1,
  NEW_PAYEE = 2,
}

export enum PaymentRequestSource {
  BILLS = 'bills',
  ADD_FUNDS = 'add-funds',
  PAY_ANYONE = 'pay-anyone',
}
