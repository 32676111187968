import classNames from 'classnames';

import { Alert } from '@all-in-one-web/common-ui';

import styles from './LockedAccountAlert.module.scss';
import type { LockedAccountAlertProps } from './types';

function LockedAccountAlert({ className, alertMessage }: LockedAccountAlertProps) {
  return (
    <Alert alertType="Info" className={classNames(styles.LockedAccountAlert, className)}>
      <span data-testid="locked-account-alert">{alertMessage}</span>
    </Alert>
  );
}
export default LockedAccountAlert;
