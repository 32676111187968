import { type FC } from 'react';
import ReactCodeInput from 'react-code-input';

import { inputStyles, invalidInputStyles } from '@constants';

import './CodeInput.scss';
import type { CodeInputProps } from './types';

export const CodeInput: FC<CodeInputProps> = ({ fields, onChange, isValid, autoFocus, error }) => {
  return (
    <div data-testid="code-input" className="code-input">
      <ReactCodeInput
        name="code-input__wrapper"
        inputMode="numeric"
        fields={fields}
        onChange={onChange}
        isValid={isValid}
        aria-describedby="security-code-error"
        inputStyle={inputStyles}
        inputStyleInvalid={invalidInputStyles}
        autoFocus={autoFocus}
        type="number"
      />
      {error && <span className="code-input-error">{error}</span>}
    </div>
  );
};
