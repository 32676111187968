import { useCallback, useContext, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { Info } from '@prospa/icons';
import {
  BusinessAccount as BusinessAccountIcon,
  DocumentCalculator as DocumentIcon,
  LOC as LOCIcon,
  SBL as SBLIcon,
  SBLPlus as SBLPlusIcon,
} from '@prospa/salt-illustrations';

import { DrawdownStatus, LineOfCreditStatus, useGetProductsPageDataQuery } from '@generated-fg';

import { BusinessLoanOfferBanner, ProductBanner } from '@components';

import { HEADER_BUTTON_ROUTES, TOOL_BANNERS } from '@constants';
import { PRODUCT_BANNERS } from '@constants';
import { useBusinessLoanOffer } from '@hooks';
import { EventActions, insertIf, trackBAActionEvent } from '@utils';

import { AppContext } from '../../contexts';
import { type RepaymentCalculatorPageProps } from '../RepaymentCalculator/types';
import styles from './Products.module.scss';
import { type ProductsPageData } from './types';
import { getAlertText, getProductCVPPageProps, openProductUrl } from './utils';

export function useProductsPageData(): ProductsPageData {
  const {
    customerProducts: { BA },
    signInInfo: { countryCode },
    cdpId,
    businessAccountDetails,
    featureFlags,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const baAccountStatus = businessAccountDetails?.accountStatus;

  const onQueryError = useCallback(() => {
    navigate('/error');
  }, [navigate]);

  // this hook makes a call to the backend to get the products data
  const {
    data: { user: { smallBusinessLoans: loans = [], linesOfCredit: creditLines = [] } = {} } = {},
    loading: productsPageDataLoading,
  } = useGetProductsPageDataQuery({ onError: onQueryError });

  const {
    user: { id_token: token },
  } = useAuth();

  const hasNZProducts = useMemo(() => countryCode === 'NZ', [countryCode]);

  const isCapitalProductsInArrears = useMemo(
    () =>
      creditLines.some(({ isInArrears }) => isInArrears) ||
      loans.some(({ isInArrears }) => isInArrears),
    [creditLines, loans],
  );

  const isLOCSuspended = useMemo(
    () => creditLines.some(({ status }) => status === LineOfCreditStatus.LineSuspended),
    [creditLines],
  );

  const deferredLoc = useMemo(
    () => creditLines.find(({ drawdown: { status } }) => status === DrawdownStatus.Deferred),
    [creditLines],
  );

  const isLOCDeferred = !!deferredLoc;
  const deferralPeriodEndDate = deferredLoc?.deferralDetails?.deferralPeriodEndDate;

  const { isEntitledBusinessLoanOffer } = useBusinessLoanOffer();

  const handleBusinessLoanBannerClick = useCallback(() => {
    trackBAActionEvent('nz_promo_banner__click');
    navigate(HEADER_BUTTON_ROUTES.SMALL_BUSINESS_LOAN_PRODUCT);
  }, [navigate]);

  const capitalProductsBannerSectionProps = useMemo(
    () => ({
      container: 'products-page',
      banners: [
        ...insertIf(isEntitledBusinessLoanOffer, {
          Banner: () => <BusinessLoanOfferBanner onClick={handleBusinessLoanBannerClick} />,
        }),
        {
          Banner: () => (
            <ProductBanner
              icon={<LOCIcon />}
              title={PRODUCT_BANNERS.LOC.TITLE}
              subtitle={PRODUCT_BANNERS.LOC.SUBTITLE}
              buttonProps={{
                label: PRODUCT_BANNERS.BUTTON_TEXT,
                onClick: () => {
                  trackBAActionEvent('loc_product_banner_click');
                  navigate(HEADER_BUTTON_ROUTES.LINE_OF_CREDIT_PRODUCT);
                },
              }}
              id="line-of-credit-banner"
            />
          ),
        },
        {
          Banner: () => (
            <ProductBanner
              icon={<SBLIcon />}
              title={PRODUCT_BANNERS.SBL.TITLE}
              subtitle={PRODUCT_BANNERS.SBL.SUBTITLE}
              buttonProps={{
                label: PRODUCT_BANNERS.BUTTON_TEXT,
                onClick: () => {
                  trackBAActionEvent('sbl_product_banner_click');
                  navigate(HEADER_BUTTON_ROUTES.SMALL_BUSINESS_LOAN_PRODUCT);
                },
              }}
              id="small-business-loan-banner"
            />
          ),
        },
        {
          Banner: () => (
            <ProductBanner
              icon={<SBLPlusIcon />}
              title={PRODUCT_BANNERS.SBLP.TITLE}
              subtitle={PRODUCT_BANNERS.SBLP.SUBTITLE}
              buttonProps={{
                label: PRODUCT_BANNERS.BUTTON_TEXT,
                onClick: () => {
                  trackBAActionEvent('blp_product_banner_click');
                  navigate(HEADER_BUTTON_ROUTES.BUSINESS_LOAN_PLUS_PRODUCT);
                },
              }}
              id="business-loan-plus-banner"
            />
          ),
        },
      ],
    }),
    [isEntitledBusinessLoanOffer, handleBusinessLoanBannerClick, navigate],
  );

  const businessAccountBannerSectionProps = useMemo(
    () => ({
      container: 'products-page',
      banners: [
        ...insertIf(!hasNZProducts && !BA, {
          Banner: () => (
            <ProductBanner
              icon={<BusinessAccountIcon />}
              title={PRODUCT_BANNERS.BA.TITLE}
              subtitle={PRODUCT_BANNERS.BA.SUBTITLE}
              buttonProps={{
                label: PRODUCT_BANNERS.BUTTON_TEXT,
                onClick: () => {
                  trackBAActionEvent('ba_product_banner_click');
                  navigate(HEADER_BUTTON_ROUTES.BUSINESS_ACCOUNT_PRODUCT);
                },
              }}
              id="business-account-banner"
            />
          ),
        }),
      ],
    }),
    [hasNZProducts, BA, navigate],
  );

  const productCVPPageProps = useMemo(
    () =>
      getProductCVPPageProps({
        productsPageDataLoading,
        baAccountStatus,
        isCapitalProductsInArrears,
        isLOCSuspended,
        isLOCDeferred,
        deferralPeriodEndDate,
        countryCode,
        token,
        cdpId,
        openProductUrl,
      }),
    [
      productsPageDataLoading,
      baAccountStatus,
      isCapitalProductsInArrears,
      isLOCSuspended,
      isLOCDeferred,
      deferralPeriodEndDate,
      countryCode,
      token,
      cdpId,
    ],
  );

  const toolsBannerSectionProps = useMemo(
    () => ({
      container: 'products-page',
      banners: [
        ...insertIf(featureFlags.repaymentCalculator, {
          Banner: () => {
            return (
              <ProductBanner
                icon={<DocumentIcon />}
                title={TOOL_BANNERS.REPAYMENT_CALCULATOR.TITLE}
                subtitle={TOOL_BANNERS.REPAYMENT_CALCULATOR.SUBTITLE}
                buttonProps={{
                  id: 'repayment-calculator-banner-button',
                  label: TOOL_BANNERS.REPAYMENT_CALCULATOR.BUTTON_TEXT,
                  onClick: () => {
                    trackBAActionEvent(EventActions.REPAYMENT_CALCULATOR_PRODUCT_BANNER_CLICKED);
                    navigate(HEADER_BUTTON_ROUTES.REPAYMENT_CALCULATOR);
                  },
                }}
                id="repayment-calculator-banner"
              />
            );
          },
        }),
      ],
    }),
    [featureFlags.repaymentCalculator, navigate],
  );

  const repaymentCalculatorPageProps: RepaymentCalculatorPageProps = useMemo(
    () => ({
      alertProps: {
        alertType: 'Info',
        icon: <Info width={24} height={24} />,
        children: getAlertText({
          isCapitalProductsInArrears,
          isLOCDeferred,
          deferralPeriodEndDate,
          isLOCSuspended,
        }),
        className: styles.ProductsModalAlert,
        show: isCapitalProductsInArrears || isLOCDeferred || isLOCSuspended,
      },
    }),
    [deferralPeriodEndDate, isCapitalProductsInArrears, isLOCDeferred, isLOCSuspended],
  );

  return {
    capitalProductsBannerSectionProps,
    businessAccountBannerSectionProps,
    productCVPPageProps,
    toolsBannerSectionProps,
    repaymentCalculatorPageProps,
    loading: productsPageDataLoading,
  };
}
