import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { LayoutContext } from '@components';

import { breakpoints } from '@constants';
import { isSubnavActive } from '@utils';

import { AppContext } from '../../contexts';
import { type NavBarData } from './types';

export const useNavData = (): NavBarData => {
  const { isIntersectingScrollableArea } = useContext(LayoutContext);
  const {
    signInInfo: { isMobileAppUser },
  } = useContext(AppContext);
  const { pathname = '' } = useLocation();
  const isMobile = useMediaQuery({ query: breakpoints.mobile });
  const isTablet = useMediaQuery({ query: breakpoints.tablet });
  const isSmall = useMediaQuery({ query: breakpoints.small });
  const isLarge = useMediaQuery({ query: breakpoints.large });

  const [isOpen, setIsOpen] = useState(isLarge ? true : false);
  const [isSubNavOpen, setIsSubNavOpen] = useState(false);

  const isInitialRender = useRef(true);

  const closeMenu = useCallback(() => {
    if (!isLarge) {
      setIsSubNavOpen(false);
      setIsOpen(false);
    }
  }, [isLarge]);

  // NOTE: May need to revisit when add product pages into navbar
  const toggleNavOpen = useCallback(() => {
    if (!isOpen) {
      if (isSubnavActive(pathname)) {
        setIsSubNavOpen(true);
      }
    } else {
      setIsSubNavOpen(false);
    }
    setIsOpen(!isOpen);
  }, [isOpen, pathname]);

  useEffect(() => {
    // dont hide menu on first render
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      setIsOpen(false);
      setIsSubNavOpen(false);
    }
  }, [isMobile, isTablet, isSmall, isLarge]);

  return {
    isIntersectingScrollableArea,
    isMobileAppUser,
    isMobile,
    isOpen,
    isSubNavOpen,
    toggleNavOpen,
    closeMenu,
    setIsSubNavOpen,
  };
};
