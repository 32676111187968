/**
 * A component hold the html head meta data with react-helmet
 */
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { getDocumentTitleByPathname } from '@utils';

export const HtmlMetaHelmet = () => {
  const location = useLocation();
  const title = useMemo(() => {
    return getDocumentTitleByPathname(location);
  }, [location]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
