import { supportPhoneNumbers } from '@all-in-one-web/common-ui';
import { Document } from '@prospa/salt-illustrations';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { externalRedirect } from '@utils';

import styles from './Contract.module.scss';
import { type ExpiredContractProps } from './types';

export const ExpiredContract = ({ showButton = true, countryCode }: ExpiredContractProps) => {
  const phone = supportPhoneNumbers?.[countryCode] ?? supportPhoneNumbers['AU'];

  return (
    <div className={styles.Contract} data-testid="expired-contract">
      <Document className={styles.ContractIllustration} />
      <h2>Contract expired</h2>
      <p className={styles.ContractSubtitle}>
        To review your contract please call us on <a href={phone.url}>{phone.text}</a>.
      </p>
      {showButton && (
        <Button
          className={styles.ContractConfirmButton}
          size={ButtonSize.MEDIUM}
          type={ButtonType.PRIMARY}
          label="Return to dashboard"
          onClick={() => externalRedirect({ url: '/', openInNewTab: false })}
        />
      )}
    </div>
  );
};
