import { IceCreamFlop } from '@prospa/salt-illustrations';

import { Modal } from '@components';

import styles from './PayeeErrorModal.module.scss';
import type { PayeeErrorModalProps } from './types';

export const PayeeErrorModal = ({ isOpen, onClose, body }: PayeeErrorModalProps) => (
  <Modal
    size="large"
    isOpen={isOpen}
    onClose={onClose}
    showCloseModal
    primaryCTALabel="OK, got it"
    primaryCTAOnClick={onClose}
    body={
      <div className={styles.ErrorModal}>
        <IceCreamFlop />
        <h4 className={styles.ErrorModalHeading}>Whoops, something wasn’t right</h4>
        {body}
      </div>
    }
  />
);
