import { type LineOfCreditRepaymentCalculatorFormValues } from './types';

type ScheduledRepayment = {
  week: number;
  weeklyRepayment: number;
  interest: number;
  principal: number;
  weeklyServiceFee: number;
  startingBalance: number;
  balanceWithdrawn: number;
};

export type RepaymentsScheduleData = Array<ScheduledRepayment>;

export class LineOfCreditRepaymentCalculator {
  private readonly DEFAULT_APR = 31;
  private readonly DAYS_PER_YEAR = 365;
  private readonly SCHEDULE_LENGTH_WEEKS = 10;
  private readonly NUM_DAYS_IN_WEEK = 7;
  private readonly MIN_PRINCIPAL = 50;
  private readonly WEEKLY_SERVICE_FEE_RATE = 0.00046;

  constructor(
    private readonly amount: LineOfCreditRepaymentCalculatorFormValues['amount'],
    private readonly expectedRepaymentAmount: LineOfCreditRepaymentCalculatorFormValues['expectedDrawdownAmount'],
    private readonly apr?: number,
  ) {
    this.amount = amount;
    this.expectedRepaymentAmount = expectedRepaymentAmount;
    this.apr = apr || this.DEFAULT_APR;
  }

  calculateDailyRate(): number {
    return this.apr / 100 / this.DAYS_PER_YEAR;
  }

  calculateInterest(principal: number): number {
    const dailyRate = this.calculateDailyRate();
    const weeklyInterest = (Math.pow(1 + dailyRate, this.NUM_DAYS_IN_WEEK) - 1) * principal;
    return weeklyInterest;
  }

  calculatePrincipal(repaymentAmount: number): number {
    const principal = Math.max(repaymentAmount * 0.01, this.MIN_PRINCIPAL);
    return principal;
  }

  calculateWeeklyServiceFee(): number {
    const weeklyServiceFee = this.amount * this.WEEKLY_SERVICE_FEE_RATE;
    return weeklyServiceFee;
  }

  calculateWeeklyRepayment(): number {
    return (
      this.calculateInterest(this.expectedRepaymentAmount) +
      this.calculatePrincipal(this.expectedRepaymentAmount) +
      this.calculateWeeklyServiceFee()
    );
  }

  buildRepaymentsSchedule(): RepaymentsScheduleData {
    const weeklyServiceFee = this.calculateWeeklyServiceFee();
    let startingBalance = this.expectedRepaymentAmount;

    const schedule: RepaymentsScheduleData = [];
    for (let week = 1; week <= this.SCHEDULE_LENGTH_WEEKS; week++) {
      const interest = this.calculateInterest(startingBalance);
      const principal = this.calculatePrincipal(startingBalance);
      const weeklyRepayment = interest + principal + weeklyServiceFee;
      const balanceWithdrawn = Math.max(startingBalance - principal, 0);

      schedule.push({
        week,
        weeklyRepayment,
        interest,
        principal,
        weeklyServiceFee,
        startingBalance,
        balanceWithdrawn,
      });

      if (balanceWithdrawn === 0) break;

      startingBalance -= principal;
    }

    return schedule;
  }
}
