import { type FC, Fragment, type PropsWithChildren, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import classNames from 'classnames';

import {
  ChevronDown as ArrowDownIcon,
  Bills as BillsIcon,
  Card as CardsIcon,
  Home as HomeIcon,
  Products as ProductsIcon,
  Settings as SettingsIcon,
} from '@prospa/salt-icons/react';

import { Badge, Tooltip } from '@components';

import { DASHBOARD_ROUTES } from '@constants';
import { trackBAActionEvent } from '@utils';

import { AppContext } from '../../../contexts';
import './NavOptions.scss';
import type { NavLabelProps, NavOptionProps, NavOptionsProps, NavSubOptionProps } from './types';
import { useNavOptions } from './useNavOptions';

const today = new Date();

const NavIcons = {
  Home: <HomeIcon />,
  Cards: <CardsIcon />,
  Settings: <SettingsIcon />,
  Products: <ProductsIcon />,
  Bills: <BillsIcon />,
};

export const NAV_LABEL_BADGE_TEXT = 'NEW';

export const NavLabel: FC<PropsWithChildren<NavLabelProps>> = ({
  children,
  to,
  navBadgeFeatureFlag,
  badgeText,
  isBadgeLoading = false,
}) => {
  const showNewBadge =
    !isBadgeLoading &&
    !badgeText &&
    navBadgeFeatureFlag &&
    to === navBadgeFeatureFlag.path &&
    today < new Date(navBadgeFeatureFlag.endDate);

  return (
    <>
      <span className="left-nav__label__text">{children}</span>
      {!!badgeText && <Badge className="left-nav__text__badge">{badgeText}</Badge>}
      {showNewBadge && <Badge className="left-nav__new__badge">{NAV_LABEL_BADGE_TEXT}</Badge>}
    </>
  );
};

const NavOption = ({
  label,
  icon,
  isOpen,
  subNavOnClick,
  isSubNavOpen,
  tooltipLabel,
}: NavOptionProps) => (
  <Tooltip className="left-nav__menu" text={tooltipLabel || label} hide={isOpen}>
    <div className="left-nav__trigger">
      <div className="left-nav__trigger-icon">{icon}</div>
      <span className="left-nav__page-name left-nav--label pds-lead">
        {label}
        {subNavOnClick && (
          <ArrowDownIcon
            className={isSubNavOpen ? 'flip' : ''}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              subNavOnClick();
            }}
            size={24}
          />
        )}
      </span>
    </div>
  </Tooltip>
);

export const NavSubOption = ({ label, isSubNavOpen, onClick, to, pathname }: NavSubOptionProps) => (
  <CSSTransition
    in={isSubNavOpen}
    timeout={200}
    className={classNames('left-nav__link', { 'left-nav__link--active': pathname === to })}
    unmountOnExit
  >
    <NavLink to={to} caseSensitive={true} onClick={onClick}>
      <div className={classNames('left-nav__sub', { 'subnav-active': pathname === to })}>
        <span className="left-nav__page-name left-nav--label pds-lead">{label}</span>
      </div>
    </NavLink>
  </CSSTransition>
);

export const NavOptions = ({
  closeMenu,
  isOpen,
  isSubNavOpen,
  setIsSubNavOpen,
}: NavOptionsProps) => {
  const {
    featureFlags: { navBadge },
  } = useContext(AppContext);

  const { navConfigs, isDashboardActive } = useNavOptions({ isSubNavOpen });

  const navLinkOnClick = (to: string) => {
    switch (to) {
      case DASHBOARD_ROUTES.PRODUCTS:
        trackBAActionEvent('product_tab_click');
        break;
      case DASHBOARD_ROUTES.DRAWDOWN:
        trackBAActionEvent('drawdown_button', `drawdown-select_type:tab`);
        break;
      case DASHBOARD_ROUTES.PAY_ANYONE:
        trackBAActionEvent('pay-anyone_select', `pay_anyone-select_type:tab`);
        break;
      default:
        break;
    }
    closeMenu();
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {navConfigs?.map(
        ({
          label,
          to,
          tooltipLabel,
          navSubConfigs,
          caseSensitive = true,
          badgeText,
          isBadgeLoading = false,
        }) => {
          const hasSubNav = navSubConfigs?.length > 0;
          return (
            <Fragment key={label}>
              <NavLink
                to={to}
                className={
                  label === 'Home'
                    ? classNames('left-nav__link', {
                        'left-nav__link--active': isDashboardActive,
                      })
                    : navData => (navData.isActive ? 'left-nav__link--active' : 'left-nav__link')
                }
                data-testid={label}
                caseSensitive={caseSensitive}
                onClick={() => navLinkOnClick(to)}
              >
                <NavOption
                  key={label}
                  label={
                    <NavLabel
                      to={to}
                      navBadgeFeatureFlag={navBadge}
                      badgeText={badgeText}
                      isBadgeLoading={isBadgeLoading}
                    >
                      {label}
                    </NavLabel>
                  }
                  tooltipLabel={tooltipLabel}
                  icon={NavIcons[label]}
                  isOpen={isOpen}
                  subNavOnClick={
                    hasSubNav
                      ? () => {
                          setIsSubNavOpen(!isSubNavOpen);
                        }
                      : null
                  }
                  isSubNavOpen={isSubNavOpen}
                />
              </NavLink>
              {hasSubNav &&
                navSubConfigs.map(({ label, to, pathname }) => (
                  <NavSubOption
                    key={label}
                    label={
                      <NavLabel
                        to={to}
                        navBadgeFeatureFlag={navBadge}
                        badgeText={badgeText}
                        isBadgeLoading={isBadgeLoading}
                      >
                        {label}
                      </NavLabel>
                    }
                    to={to}
                    pathname={pathname}
                    isSubNavOpen={isSubNavOpen}
                    onClick={() => navLinkOnClick(to)}
                  />
                ))}
            </Fragment>
          );
        },
      )}
    </>
  );
};
