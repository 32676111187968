import { useMemo } from 'react';

import classNames from 'classnames';

import { AddFundsFooter } from '@components';
import { Divider } from '@components';
import { FreezeAccountAlert, IntegrationErrorAlert } from '@components';

import { PRODUCT_TYPES } from '@constants';
import { formatAccountNumber, formatBsb } from '@utils';

import { ProductTypes } from '../../../models/Account';
import { AccountLoader } from '../../BlockLoader';
import styles from '../AccountList.module.scss';
import AccountListItem from '../AccountListItem';
import {
  type BusinessAccountListCardAlertsProps,
  type BusinessAccountListCardFooterProps,
  type BusinessAccountListCardProps,
} from './types';

export const BusinessAccountListCardAlerts = ({
  integrationSyncError,
  displayStatus,
  hasFooter,
}: BusinessAccountListCardAlertsProps): JSX.Element => {
  return (
    <>
      <FreezeAccountAlert
        accountStatus={displayStatus}
        className={styles.AccountListItemFreezeAccountAlert}
      />
      {!!integrationSyncError?.show && (
        <IntegrationErrorAlert
          integrationStatus={integrationSyncError.integrationStatus}
          onClose={integrationSyncError.remove}
          className={classNames(styles.AccountListItemConnectionErrorAlert, {
            'mb-0': hasFooter,
          })}
        />
      )}
    </>
  );
};

export const BusinessAccountListCardFooter = ({
  addFundsFooter,
}: BusinessAccountListCardFooterProps): JSX.Element => {
  return <AddFundsFooter {...addFundsFooter} />;
};

export function BusinessAccountListCard({
  accountDetail,
  onClick,
  loading,
  alerts,
  footer,
}: BusinessAccountListCardProps): JSX.Element {
  const businessAccountSubTitle = useMemo(
    () =>
      accountDetail
        ? `${formatBsb(accountDetail?.bsb)} ${formatAccountNumber(accountDetail?.accountNumber)}`
        : null,
    [accountDetail],
  );

  return loading ? (
    <AccountLoader className={classNames(styles.AccountListItem, styles.Skeleton)} />
  ) : accountDetail ? (
    <AccountListItem
      id={accountDetail?.id}
      productType={ProductTypes.BA}
      subTitle={businessAccountSubTitle}
      title={PRODUCT_TYPES.BA}
      balance={accountDetail?.balance?.total}
      available={accountDetail?.balance?.available}
      accountStatus={accountDetail?.businessAccountStatus}
      onClick={onClick}
      className={classNames({
        [styles.AccountListItemWithFooter]: !!footer,
      })}
    >
      {alerts}
      {!!footer && (
        <Divider className={classNames(styles.Divider, { [styles.DividerWithAlert]: !!alerts })} />
      )}
      {footer}
    </AccountListItem>
  ) : null;
}
