import { Cross } from '@prospa/salt-icons';

import { Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';

import styles from './FullPageCampaign.module.scss';
import { useFullPageCampaign } from './hooks';
import type { FullPageCampaignProps } from './types';

export const FullPageCampaign = ({ campaignCode, children }: FullPageCampaignProps) => {
  const [showCampaign, onCampaignClose, CampaignBody] = useFullPageCampaign(campaignCode);

  return showCampaign && CampaignBody ? (
    <div className={styles.FullPageCampaign} data-testid="campaign-body">
      <CampaignBody />
      <Button
        size={ButtonSize.LARGE}
        type={ButtonType.ICON_LINK}
        className={styles.FullPageCampaign__CloseBtn}
        onClick={onCampaignClose}
      >
        <Cross size={24} />
      </Button>
    </div>
  ) : (
    children
  );
};
