import type { FC } from 'react';

import { UtilitiesShield } from '@prospa/icons';

import { ButtonSize } from '@constants';

import { Modal } from '../Modal';
import styles from './LoanAwaitingModal.module.scss';
import type { LoanAwaitingModalProps } from './types';

export const LoanAwaitingModal: FC<LoanAwaitingModalProps> = ({ isOpen, onClose }) => (
  <Modal
    customStyle={{ overlay: { zIndex: 999 } }}
    isOpen={isOpen}
    onClose={onClose}
    showCloseModal
    size="medium"
    body={
      <div data-testid="loan-awaiting-modal">
        <UtilitiesShield className={styles.LoanAwaitingModalIcon} />
        <h3 className={styles.LoanAwaitingModalTitle}>Almost there</h3>
        <p className={styles.LoanAwaitingModalText}>
          We’ve emailed you a signed copy of your loan agreement for your records. If we need to
          confirm anything, we’ll call you. Otherwise, the funds will be available in your account
          within one business day. You’ll be able to view your account here shortly.
        </p>
      </div>
    }
    primaryButtonProps={{
      onClick: onClose,
      label: 'OK',
      size: ButtonSize.LARGE,
    }}
  />
);
