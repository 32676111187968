import { type ApolloCache } from '@apollo/client';

import type { Account, LoanActivity } from '@generated-mg';

import type { TransactionRecord } from '@components/types';

import { MAMBU_LOAN_DETAIL_LABELS as LOAN_DETAILS, PRODUCT_TYPES } from '@constants';
import { currencyFormatter } from '@utils';

import { type ClientCacheType } from '../apolloClient';
import type { LoanButtonOption } from '../hooks/useLoanButtonOptions';
import { formatDateInDayMonthYear } from './date';

export const loanDetailsFormatter = ({
  totalBalance,
  principalPaid,
  interestPaid,
  feesPaid,
  balance,
  loanAmount,
  termInMonths,
  startDate,
  repaymentFrequency,
  installmentAmount,
  lastDirectDebitDate,
}: Partial<Account>) => {
  return [
    [
      {
        label: LOAN_DETAILS.TOTAL_REPAID,
        value: currencyFormatter(principalPaid + interestPaid + feesPaid),
        comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
      },
      {
        label: LOAN_DETAILS.OUTSTANDING,
        value: currencyFormatter(balance),
        comment: LOAN_DETAILS.PRINCIPAL_AMOUNT_ONLY,
      },
      {
        label: LOAN_DETAILS.TOTAL_OUTSTANDING_WITH_ASTERISK,
        value: currencyFormatter(totalBalance),
        comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
      },
      {
        label: `${repaymentFrequency} ${LOAN_DETAILS.PAYMENT_FREQUENCY}`,
        value: currencyFormatter(installmentAmount),
        comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
      },
    ],
    [
      {
        label: LOAN_DETAILS.LOAN_AMOUNT,
        value: currencyFormatter(loanAmount),
        comment: LOAN_DETAILS.INCLUDES_ORIGINATION_FEE,
      },
      {
        label: LOAN_DETAILS.LOAN_TERM,
        value: `${termInMonths} months`,
      },
      {
        label: LOAN_DETAILS.START_DATE,
        value: startDate ? formatDateInDayMonthYear(startDate) : null,
      },

      {
        label: LOAN_DETAILS.FINAL_REPAYMENT_DATE,
        value: lastDirectDebitDate ? formatDateInDayMonthYear(lastDirectDebitDate) : null,
        comment: LOAN_DETAILS.SUBJECT_TO_MEETING_FUTURE_PAYMENTS,
      },
    ],
  ];
};

const TRANSACTION_DISPLAYED_AS_NEGATIVE_NUMBERS_SET = new Set([
  'Dishonour fee',
  'Default interest',
  'Early payout fee',
]);

export const mapGqlTransactions = (
  transactionData: LoanActivity[] = [],
  transactionDetailsClassName?: string,
): TransactionRecord[] =>
  transactionData.map(
    ({
      id,
      transactionStatus,
      transactionDate,
      description,
      transactionTitle,
      displayAmount,
      transactionDetails,
      reference,
    }) => {
      const additionalDetails =
        transactionDetails.length > 0 ? (
          <div>
            {!!reference && <div className="pds-text">{`Reference: ${reference}`}</div>}
            <ul className={transactionDetailsClassName}>
              {transactionDetails.map((transactionDetail, index) => {
                return (
                  <li key={index}>
                    <div className="pds-text">{transactionDetail.title}</div>
                    <div className="pds-text">{transactionDetail.value}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null;

      return {
        id,
        amount: TRANSACTION_DISPLAYED_AS_NEGATIVE_NUMBERS_SET.has(transactionTitle)
          ? -displayAmount
          : displayAmount,
        // eslint-disable-next-line no-template-curly-in-string
        description: description.replace('${productName}', PRODUCT_TYPES.SBL),
        date: transactionDate,
        displayName: transactionTitle,
        pending: transactionStatus === 'Pending',
        dateGroup: transactionDate,
        isFailure: (transactionStatus || '').toLowerCase() === 'declined',
        additionalDetails: additionalDetails,
        reference,
      };
    },
  );

export const getRepaymentProgressPercentage = (account?: Partial<Account>): number => {
  const totalRepaid = account?.principalPaid + account?.interestPaid + account?.feesPaid;
  const percentage = (totalRepaid / (totalRepaid + account?.totalBalance)) * 100;
  if (percentage > 0 && percentage <= 1) {
    return 1;
  }
  const flooredPercentage = Math.floor(percentage);
  return Number.isNaN(flooredPercentage) ? 0 : flooredPercentage;
};

const AVAILABLE_DASHBOARD_BUTTONS_IN_MOBILE_APP = new Set([]);

export const filterButtonOptionsInMobileApp = (
  buttonOptions: LoanButtonOption[],
  isMobileAppUser: boolean,
) => {
  return isMobileAppUser
    ? buttonOptions.filter(item => {
        return AVAILABLE_DASHBOARD_BUTTONS_IN_MOBILE_APP.has(item.label);
      })
    : buttonOptions;
};

const SBLM_PREFIXES = ['SBLMNZ'];

export function isSBLNZMambu(id: string): boolean {
  if (!id) return false;

  if (id.search(SBLM_PREFIXES[0]) !== -1) return true;

  return false;
}

export const evictSBLMRelatedCache = ({
  productId,
  cache,
}: {
  productId: string;
  cache: ApolloCache<ClientCacheType | object>;
}): void => {
  cache.evict({
    id: `Account:${productId}`,
  });
  cache.evict({
    id: `SmallBusinessLoan:${productId}`,
  });
};
