import { AppStoreRating } from '@components';

import AppMockup from '../../../../assets/app-mockup.png';
import styles from './MobileAppModal.module.scss';
import type { TMobileAppModalBody } from './types';

export const MobileAppModalBody = ({ rating = 0, numStars = 5 }: TMobileAppModalBody) => {
  return (
    <>
      <img
        src={AppMockup}
        alt="Prospa Mobile App Mockup"
        className={styles.MobileAppModalBodyMockup}
      />
      <div className={styles.MobileAppModalBody}>
        <p className={styles.MobileAppModalBodyTitle}>Faster login with the Prospa App</p>
        <p>It’s easier to manage your cash flow and business finances with the Prospa App.</p>
        <AppStoreRating rating={rating} numStars={numStars} text="Apple App Store" />
      </div>
    </>
  );
};
