import { useEffect, useState } from 'react';

interface Props {
  initialMinute: number;
  initialSeconds: number;
  onCountdownEnd: () => void;
}

export const TimerCountdown = ({
  initialMinute = 0,
  initialSeconds = 0,
  onCountdownEnd,
}: Props) => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          onCountdownEnd();
          clearInterval(timerInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [minutes, onCountdownEnd, seconds]);

  return (
    <span>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </span>
  );
};
