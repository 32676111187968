import type { ReactNode } from 'react';

import {
  Country,
  type ScheduledTransferInput,
  TransferError,
  type TransferInput,
} from '@generated-fg';

import { PayAnyoneTabs } from '@constants';
import { PaymentRequestSource, ProductTypes } from '@models';
import type { AccountItem, PayAnyoneFormValues, PayAnyoneMutations, PayeeV2 } from '@models';

import {
  type FormattedPayAnyoneRequest,
  formatPayAnyonePayload,
} from '../containers/PayAnyone/PayAnyoneForm/PayAnyoneHelper';
import { formatPayAnyoneRecurringPayload } from '../containers/PayAnyone/PayAnyoneForm/PayAnyoneRecurringHelper';
import {
  PayAnyoneFormValidator,
  type TPayAnyoneFormValidatorOptions,
} from '../containers/PayAnyone/PayAnyoneValidators/PayAnyoneValidators';

export const validatePaymentValues = ({
  values,
  currentTab,
  currentPayee,
  payFromAccount,
}: {
  values: PayAnyoneFormValues;
  currentTab: PayAnyoneTabs;
  currentPayee: PayeeV2;
  payFromAccount: AccountItem;
}) => {
  const options: Partial<TPayAnyoneFormValidatorOptions> = {};
  switch (payFromAccount?.productType) {
    case ProductTypes.LOC:
      options.minAmount = 10;
      options.dailyLimit = 10000;
      options.maxReferenceLength = 18;
      break;
    case ProductTypes.LOCM:
      options.minAmount = 10;
      options.maxDescriptionLength = 18;
      options.referenceRequired = true;
      options.maxReferenceLength = payFromAccount.countryCode === Country.Au ? 18 : 12;
      options.dailyLimit = payFromAccount.countryCode === Country.Au ? 10000 : 5000;
      break;

    case ProductTypes.BA:
      options.dailyLimit = 50000;
      break;
    default:
  }

  return PayAnyoneFormValidator(
    values,
    payFromAccount,
    currentTab,
    currentPayee?.paymentType,
    options,
  );
};

export const getPayAnyonePaymentPayload = ({
  payFromAccount,
  currentTab,
  values,
  currentPayee,
}: {
  payFromAccount: AccountItem;
  currentTab: PayAnyoneTabs;
  values: PayAnyoneFormValues;
  currentPayee: PayeeV2;
}) => {
  switch (payFromAccount?.productType) {
    case ProductTypes.BA:
    case ProductTypes.LOC:
    case ProductTypes.LOCM: {
      if (currentTab === PayAnyoneTabs.PAY_NOW) {
        const payload = formatPayAnyonePayload(values, currentPayee, payFromAccount);
        return payload;
      } else {
        const payload = formatPayAnyoneRecurringPayload(
          values,
          currentPayee,
          currentTab,
          payFromAccount,
        );
        return payload;
      }
    }
    default:
      throw Error(`Unexpected productType: ${payFromAccount?.productType}`);
  }
};

export const handlePaymentRequestCompleted = ({
  payFromAccount,
  data,
  onComplete,
}: {
  payFromAccount: AccountItem;
  data: PayAnyoneMutations;
  onComplete: (data: PayAnyoneMutations) => void;
}) => {
  switch (payFromAccount?.productType) {
    case ProductTypes.BA:
    case ProductTypes.LOC:
    case ProductTypes.LOCM:
      if ('transfer' in data || 'bpayTransfer' in data || 'scheduledTransfer' in data) {
        onComplete(data);
      }
      break;
    default:
      throw Error(`Unexpected productType: ${payFromAccount?.productType}`);
  }
};

type GetPayAnyoneTitleProps = {
  currentTab: PayAnyoneTabs;
  showPaymentConfirmation: boolean;
  showPaymentConfirmed: boolean;
  defaultTitle: ReactNode;
};

export const getPayAnyoneTitle = ({
  currentTab,
  showPaymentConfirmation,
  showPaymentConfirmed,
  defaultTitle = 'Pay',
}: GetPayAnyoneTitleProps): ReactNode => {
  return showPaymentConfirmation
    ? `Confirm ${currentTab === PayAnyoneTabs.PAY_NOW ? 'payment' : 'schedule'}`
    : showPaymentConfirmed
      ? `Payment ${currentTab === PayAnyoneTabs.PAY_NOW ? 'submitted' : 'scheduled'}`
      : defaultTitle;
};

export const getPayAnyoneConfirmationFooter = ({
  isPayFromAccountLOC,
  currentTab,
  paymentRequestSource,
}: {
  isPayFromAccountLOC: boolean;
  currentTab: PayAnyoneTabs;
  paymentRequestSource: PaymentRequestSource;
}): ReactNode => {
  const defaultMessage = `Please check these details are correct as we may not be able to recover incorrect payments.`;

  if (paymentRequestSource === PaymentRequestSource.ADD_FUNDS) {
    return defaultMessage;
  }

  return `${
    isPayFromAccountLOC
      ? 'Funds may take up to 2 business days to clear from your Line of Credit. '
      : ''
  }${defaultMessage}${
    currentTab !== PayAnyoneTabs.PAY_NOW
      ? ' Scheduled payments can’t be edited, but can be cancelled prior to the payment date.'
      : ''
  }`;
};

export const getPayAnyoneConfirmedCTALabel = (
  paymentRequestSource: PaymentRequestSource,
): string => {
  switch (paymentRequestSource) {
    case PaymentRequestSource.BILLS:
      return 'Return to bills';
    case PaymentRequestSource.ADD_FUNDS:
      return 'Return home';
    case PaymentRequestSource.PAY_ANYONE:
    default:
      return 'Make a new payment';
  }
};

export const isScheduledTransferPayload = (
  payload: FormattedPayAnyoneRequest['payload'],
): payload is ScheduledTransferInput => {
  const hasValidPayeePropertyInPayload =
    'bPayPayee' in payload || 'auPayee' in payload || 'nzPayee' in payload;
  return hasValidPayeePropertyInPayload && 'startDate' in payload && !!payload.startDate;
};

export const isTransferPayLoad = (
  payload: FormattedPayAnyoneRequest['payload'],
): payload is TransferInput => {
  const hasValidPayeePropertyInPayload =
    'bPayPayee' in payload || 'auPayee' in payload || 'nzPayee' in payload;
  return hasValidPayeePropertyInPayload && !('startDate' in payload);
};

type TransferErrorIcons = 'cash_cross' | 'search' | 'account_suspended' | 'technical_error';
export const transferErrorIcons: Record<TransferErrorIcons, TransferError[]> = {
  cash_cross: [
    TransferError.InsufficientFunds,
    TransferError.RefusedBpayRejected,
    TransferError.RefusedDailyBpayLimitBreached,
    TransferError.Fraud,
    TransferError.RefusedCustomerPreferences,
    TransferError.RefusedDailyTransfersOutLimitBreached,
    TransferError.LimitError,
  ],
  search: [
    TransferError.RefusedBpayInvalidBillerCode,
    TransferError.RefusedBpayInvalidReference,
    TransferError.InvalidBsb,
    TransferError.InvalidPayee,
    TransferError.BadInput,
  ],
  account_suspended: [TransferError.InvalidPayment, TransferError.RefusedBpayInvalidPayment],
  technical_error: [TransferError.InternalError],
};

type TransferErrorHeadings =
  | 'not_enough_funds'
  | 'daily_transfer_limit_reached'
  | 'check_payment_details';
export const transferErrorHeadings: Record<TransferErrorHeadings, TransferError[]> = {
  not_enough_funds: [TransferError.InsufficientFunds],
  daily_transfer_limit_reached: [
    TransferError.RefusedDailyBpayLimitBreached,
    TransferError.RefusedDailyTransfersOutLimitBreached,
    TransferError.LimitError,
  ],
  check_payment_details: [
    TransferError.RefusedBpayInvalidBillerCode,
    TransferError.RefusedBpayInvalidReference,
    TransferError.InvalidBsb,
    TransferError.InvalidPayee,
    TransferError.BadInput,
    TransferError.InvalidPayment,
    TransferError.RefusedBpayInvalidPayment,
  ],
};
