import { AnalyticsService, LogEvent, UserProperties } from './types';

export class Analytics implements AnalyticsService {
  private services: AnalyticsService[];
  constructor(...args: AnalyticsService[]) {
    this.services = [...args];
  }

  logPageView = async (pageName?: string) => {
    await Promise.all(this.services.map(service => service.logPageView(pageName)));
  };

  logEvent: LogEvent = async (event, params) => {
    await Promise.all(this.services.map(service => service.logEvent(event, params)));
  };

  identifyUser = async (properties: UserProperties) => {
    await Promise.all(this.services.map(service => service.identifyUser(properties)));
  };

  reset = async () => {
    await Promise.all(this.services.map(service => service.reset()));
  };
}
