import { LottieAnimation, Modal } from '@components';

import animationData from '../../../../../assets/animations/successAnimation.json';
import styles from './AccountSyncSuccessModal.module.scss';
import type { AccountSyncSuccessModalProps } from './types';

export const AccountSyncSuccessModal = ({
  isOpen,
  onClose,
  primaryButtonProps,
  integrationProduct,
  prospaProduct,
}: AccountSyncSuccessModalProps) => (
  <Modal
    size="medium"
    isOpen={isOpen}
    onClose={onClose}
    showCloseModal
    primaryButtonProps={{
      onClick: primaryButtonProps?.onClick,
      label: primaryButtonProps?.label || 'Got it',
      testId: 'account-sync-primary-button',
    }}
    footerClassName={styles.AccountSyncSuccessModalFooter}
    body={
      <div className={styles.AccountSyncSuccessModal} data-testid="sync-success-modal">
        <LottieAnimation animationData={animationData} height={128} width={128} />
        <h4 className={styles.AccountSyncSuccessModalHeading}>Sync switched on</h4>
        <span className="pds-text mb-4">
          All <b>{prospaProduct}</b> transactions made from now will automatically sync to{' '}
          {integrationProduct}. It may take up to 24 hours for transactions to appear.
        </span>
      </div>
    }
  />
);
