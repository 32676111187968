import { MicroilloDocument as DocumentIcon } from '@prospa/icons';

import { Banner } from '@components';

import { REVIEW_AGREEMENT_BANNER } from '@constants';

import { trackBAActionEvent } from '../../../utils';
import type { ReviewAgreementBannersProps } from './types';

export const ReviewAgreementBanners = ({
  pendingSblLoans,
  onClick,
}: ReviewAgreementBannersProps) => {
  const sblBanners = pendingSblLoans.map(({ id, productType }) => (
    <Banner
      key={id}
      icon={<DocumentIcon />}
      title={REVIEW_AGREEMENT_BANNER.TITLE}
      subtitle={REVIEW_AGREEMENT_BANNER.SUBTITLE}
      onClick={() => {
        trackBAActionEvent('sbl_review_agreement');
        onClick(productType, id);
      }}
      buttonText={REVIEW_AGREEMENT_BANNER.BUTTON}
    />
  ));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{sblBanners}</>;
};
