import { Field } from 'formik';

import { BSB, Checkbox, Input } from '@components';

import { PayAnyoneNewBankAccountErrorStrings } from '@constants';
import { formatBsb, handleNZChange } from '@utils';

import { type BankTabProps } from './types';

export const BankTab = ({ isNZAccount, hideSaveToAddressBook = false }: BankTabProps) => {
  return (
    <div className="add-new-payee-modal__form" data-testid="bank-tab">
      {isNZAccount ? (
        <Field
          name="accountNumber"
          label="Account number"
          key="accountNumber"
          type="text"
          inputMode="numeric"
          pattern="[0-9-]*"
          maxLength={19}
          component={Input}
          clearErrorOnChange
          onChange={handleNZChange}
        />
      ) : (
        <>
          <Field
            name="BSB"
            label="BSB"
            key="BSB"
            type="tel"
            pattern="[0-9\-]*"
            formatValue={formatBsb}
            component={BSB}
            errorMessage={PayAnyoneNewBankAccountErrorStrings.BsbInvalidError}
            clearErrorOnChange
          />
          <Field
            name="accountNumber"
            label="Account number"
            key="accountNumber"
            type="tel"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={14}
            component={Input}
            clearErrorOnChange
          />
        </>
      )}
      <Field
        name="accountName"
        label="Account name"
        key="accountName"
        type="text"
        maxLength={32}
        component={Input}
        clearErrorOnChange
      />
      {!hideSaveToAddressBook && (
        <Field
          type="checkbox"
          name="saveToAddressBook"
          label="Save to address book"
          key="saveToAddressBook"
          component={Checkbox}
        />
      )}
    </div>
  );
};
