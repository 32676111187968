import classNames from 'classnames';

import { Cash as PayIcon } from '@prospa/salt-icons';

import {
  AccountSelector,
  RenderAccountItemOption,
  RenderAccountItemSelected,
  Spinner,
} from '@components';

import type { AccountSelectorProps } from '../AccountSelector/types';
import styles from './PayAnyoneAccountSelector.module.scss';

export const PayAnyoneAccountSelector = ({
  accountList,
  setSelectedAccount,
  selectedAccount,
  loading = false,
  setSelectedAccountIndex,
}: AccountSelectorProps) => {
  if (loading) {
    return (
      <div className={styles.PayAnyoneSpinner}>
        <Spinner />
      </div>
    );
  }

  const accountSelectorOptionProps = {
    optionOnClick: setSelectedAccount,
    renderSelectorOption: RenderAccountItemOption,
    setSelectedAccountIndex,
  };

  return (
    <AccountSelector
      accountList={accountList}
      setSelectedAccount={setSelectedAccount}
      selectedAccount={selectedAccount}
      className={styles.PayAnyonePadding}
      renderSelectedAccount={RenderAccountItemSelected}
      icon={
        <PayIcon
          className={
            selectedAccount
              ? 'account-selector__icon'
              : classNames('account-selector__icon', 'account-selector__icon-center')
          }
          size={24}
        />
      }
      accountSelectorOptionProps={accountSelectorOptionProps}
    />
  );
};
