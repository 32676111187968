import { Alert } from '@components';

import { useBillDetailsAlert } from '@hooks';

import { type BillDetailsAlertProps } from './types';

export const BillDetailAlert = ({
  className,
  status,
  recentTransaction,
  isBillScanIncomplete,
}: BillDetailsAlertProps) => {
  const {
    message,
    alertType,
    icon: Icon,
  } = useBillDetailsAlert({ status, isBillScanIncomplete, recentTransaction }) || {};
  if (!message) return null;
  return (
    <Alert alertType={alertType} icon={<Icon />} className={className}>
      {message}
    </Alert>
  );
};
