import { type ReactElement, useCallback, useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';

import { Country } from '@generated-fg';

import { Banner } from '@components';

import { MOBILE_LINKS, MobileOSTypes, breakpoints } from '@constants';
import { externalRedirect, getMobileOperatingSystem } from '@utils';

import MobileAppDownloadAndroidSmallScreenMockUp from '../../../../assets/Android-mockup-Mobile.png';
import ProspaQRCodeAU from '../../../../assets/au-mobile-app-QR-code.svg';
import MobileAppDownloadIOSSmallScreenMockUp from '../../../../assets/iPhone-mockup-Mobile.png';
import MobileAppDownloadMockUp from '../../../../assets/iPhone-mockup-large.png';
import ProspaQRCodeNZ from '../../../../assets/nz-mobile-app-QR-code.png';
import AppStoreDownload from '../../../../assets/symbols/AppStore.svg';
import GoogleStoreDownload from '../../../../assets/symbols/GooglePlayStore.svg';
import { AppContext } from '../../../contexts/AppContext';
import styles from './MobileAppsBanner.module.scss';
import type { ProductBannerProps } from './types';

export const MobileAppsBanner = ({
  title,
  subtitle,
  leftIcon,
  onClose,
  className,
}: ProductBannerProps): ReactElement => {
  const {
    signInInfo: { countryCode },
  } = useContext(AppContext);
  const isMobile = useMediaQuery({ query: breakpoints.mobile });
  const mobileOS = getMobileOperatingSystem();

  const leftIconSrc = useMemo(() => {
    if (!isMobile) return MobileAppDownloadMockUp;
    switch (mobileOS) {
      case MobileOSTypes.IOS:
        return MobileAppDownloadIOSSmallScreenMockUp;
      case MobileOSTypes.Android:
        return MobileAppDownloadAndroidSmallScreenMockUp;
      case MobileOSTypes.Unknown:
      default:
        return MobileAppDownloadIOSSmallScreenMockUp;
    }
  }, [isMobile, mobileOS]);

  const ProspaQRCode = useMemo(
    () => (countryCode === Country.Nz ? ProspaQRCodeNZ : ProspaQRCodeAU),
    [countryCode],
  );
  const rightIconSrc = useMemo(() => {
    if (!isMobile) return ProspaQRCode;
    switch (mobileOS) {
      case MobileOSTypes.IOS:
        return AppStoreDownload;
      case MobileOSTypes.Android:
        return GoogleStoreDownload;
      case MobileOSTypes.Unknown:
      default:
        return ProspaQRCode;
    }
  }, [isMobile, mobileOS, ProspaQRCode]);

  const mobileDownloadIconClass = useMemo(() => {
    if (!isMobile) return styles.MobileDownloadIconCode;
    switch (mobileOS) {
      case MobileOSTypes.IOS:
        return styles.MobileDownloadIconIos;
      case MobileOSTypes.Android:
        return styles.MobileDownloadIconAndroid;
      case MobileOSTypes.Unknown:
      default:
        return styles.MobileDownloadIconCode;
    }
  }, [isMobile, mobileOS]);

  const rightIconOnClick = useCallback(() => {
    switch (mobileOS) {
      case MobileOSTypes.IOS:
        return externalRedirect({ url: MOBILE_LINKS.IOS_APP_STORE_URL });
      case MobileOSTypes.Android:
        return externalRedirect({ url: MOBILE_LINKS.ANDROID_GOOGLE_PLAY_STORE_URL });
      case MobileOSTypes.Unknown:
      default:
        return;
    }
  }, [mobileOS]);

  return (
    <Banner
      icon={leftIcon || <img src={leftIconSrc} alt="MobileAppDownloadMockUp" />}
      infoIcon={
        <img
          src={rightIconSrc}
          className={mobileDownloadIconClass}
          alt="mobileDownloadIcon"
          onClick={rightIconOnClick}
        />
      }
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      className={classNames(className, styles['MobileAppsBanner'], 'banner')}
    />
  );
};
