import { getClaritySessionUrl } from '../utils';
import clarityInit from './clarity';
import { type ClarityClient } from './types';

export class Clarity implements ClarityClient {
  projectId: string;
  userId?: string;
  sessionId?: string;

  constructor(projectId: string) {
    this.init(projectId);
    this.projectId = projectId;
  }

  init(id: string): void {
    if (!this.hasStarted()) {
      clarityInit(id);
    }
  }

  hasStarted(): boolean {
    return typeof window.clarity === 'function';
  }

  identify(
    userId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string,
  ): void {
    this.userId = userId;
    this.sessionId = customSessionId;
    window.clarity?.('identify', userId, customSessionId, customPageId, friendlyName);
  }

  consent(): void {
    window.clarity?.('consent');
  }

  setTags(tags: Record<string, string | string[]>): void {
    Object.entries(tags).forEach(([key, value]) => {
      window.clarity?.('set', key, value);
    });
  }

  upgrade(reason: string): void {
    window.clarity?.('upgrade', reason);
  }

  getSessionUrl(): string {
    return getClaritySessionUrl({
      sessionId: this.sessionId,
      projectId: this.projectId,
      userId: this.userId,
    });
  }
}
