import classNames from 'classnames';
import { Field } from 'formik';

import { Card, CurrencyInput, FormikDatePicker, Input } from '@components';
import { type InputToolTipProps } from '@components/types';

import styles from '../../styles.module.scss';

export type BillPaymentFieldsSectionProps = {
  isNonEditable: boolean;
  isReferenceFieldRequired: boolean;
};

export const BillPaymentFieldsSection = ({
  isNonEditable = false,
  isReferenceFieldRequired = false,
}: BillPaymentFieldsSectionProps) => {
  const fieldTooltipProps: Partial<InputToolTipProps> = {
    position: 'top',
    alwaysVisible: true,
    className: classNames(styles.ConfirmBillFormFieldToolTip),
  };
  return (
    <Card className={styles.ConfirmBillFormPaymentFieldsSection}>
      <Field
        name="billerName"
        label="Biller"
        key="billerName"
        type="text"
        component={Input}
        disabled={isNonEditable}
      />
      <Field
        name="amount"
        label="Amount"
        key="amount"
        type="number"
        maxLength={13}
        component={CurrencyInput}
        disabled={isNonEditable}
      />
      <Field
        name="dueDate"
        label="Due date"
        key="dueDate"
        component={FormikDatePicker}
        disabled={isNonEditable}
      />
      <Field
        name="description"
        label="Invoice number or description"
        placeholder="Description"
        key="description"
        type="text"
        toolTipProps={{
          ...fieldTooltipProps,
          text: 'Visible to the biller',
        }}
        component={Input}
        disabled={isNonEditable}
      />
      {!isNonEditable && (
        <Field
          name="reference"
          label={`Reference ${isReferenceFieldRequired ? '' : '(optional)'}`}
          toolTipProps={{
            ...fieldTooltipProps,
            text: 'For your reference only',
          }}
          key="reference"
          type="text"
          component={Input}
          disabled={isNonEditable}
          required={isReferenceFieldRequired}
        />
      )}
    </Card>
  );
};
