import { Field } from 'formik';

import { Checkbox, Input } from '@components';

import { type BPayTabProps } from './types';

export const BPayTab = ({ setFieldError, hideSaveToAddressBook = false }: BPayTabProps) => {
  return (
    <div className="add-new-payee-modal__form" data-testid="bpay-tab">
      <Field
        name="billerCode"
        label="Biller code"
        key="billerCode"
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        component={Input}
        onFocus={() => {
          setFieldError('billerCode', '');
        }}
      />
      <Field
        name="referenceNumber"
        label="Reference"
        key="referenceNumber"
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        component={Input}
        onFocus={() => {
          setFieldError('referenceNumber', '');
        }}
      />
      <Field
        name="nickname"
        label="Nickname"
        key="nickname"
        type="text"
        maxLength={32}
        component={Input}
        onFocus={() => {
          setFieldError('nickname', '');
        }}
      />
      {!hideSaveToAddressBook && (
        <Field
          type="checkbox"
          name="saveToAddressBook"
          label="Save to address book"
          key="saveToAddressBook"
          component={Checkbox}
          disabled
        />
      )}
    </div>
  );
};
