import cloneDeep from 'lodash/cloneDeep';

import { type SmallBusinessLoan, SmallBusinessLoanContractStatus } from '@generated-fg';
import type { ApplicationStatusType } from '@generated-mg';

import type { AccountListProps, LoanDetailsItem } from '@components/types';

import { LOAN_DETAILS, SBL, activeSBLStatusesSet, unsettledSblStatuses } from '@constants';
import { currencyFormatter } from '@utils';

import { formatDateInDayMonthYear } from './date';

type LoanDetailFormatterParam = {
  totalAmountPaid: number;
  totalOutstandingAmount: number;
  paymentFrequency: string;
  paymentAmount: number;
  loanStartDate: string;
  termInMonth: string;
  loanAmount: number;
};

export const loanDetailFormatter = ({
  totalAmountPaid,
  totalOutstandingAmount,
  paymentFrequency,
  paymentAmount,
  loanStartDate,
  termInMonth,
  loanAmount,
}: LoanDetailFormatterParam): LoanDetailsItem[][] => {
  const totalRepaymentAmount = totalAmountPaid + totalOutstandingAmount;
  const loanDetails = [
    [
      {
        label: LOAN_DETAILS.TOTAL_OUTSTANDING,
        value: currencyFormatter(totalOutstandingAmount),
        comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
      },
      {
        label: `${paymentFrequency} ${LOAN_DETAILS.PAYMENT_FREQUENCY}`,
        value: currencyFormatter(paymentAmount),
        comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
      },
      {
        label: LOAN_DETAILS.TOTAL_REPAID,
        value: currencyFormatter(totalAmountPaid),
        comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
      },
    ],
    [
      {
        label: LOAN_DETAILS.START_DATE,
        value: loanStartDate ? formatDateInDayMonthYear(loanStartDate) : null,
      },
      {
        label: LOAN_DETAILS.LOAN_TERM,
        value: `${termInMonth} months`,
      },
    ],
    [
      {
        label: LOAN_DETAILS.LOAN_AMOUNT,
        value: currencyFormatter(loanAmount),
        comment: LOAN_DETAILS.INCLUDES_ORIGINATION_FEE,
      },
      {
        label: LOAN_DETAILS.TOTAL_REPAY_AMOUNT,
        value: currencyFormatter(totalRepaymentAmount),
        comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
      },
    ],
  ];
  return loanDetails;
};

/**
 * update transaction description for failure cases
 * @param {string} desc Transaction description
 */
export const transactionDescFormatter = (desc?: string): string => {
  if (isFailureTransaction(desc)) {
    return SBL.UNSUCCESSFUL_TRANSACTION_LABEL;
  }
  return desc || '';
};

/**
 * detect failure transaction based on transaction.data.description
 * @param {string} desc Transaction description
 */
export const isFailureTransaction = (desc?: string): boolean => {
  return (desc || '').toLowerCase().includes('dishonoured');
};

/**
 * update transaction type for failure cases
 * @param {string} desc Transaction description
 * @param {string} desc Transaction type
 */
export const transactionTypeFormatter = (transactionType: string, desc?: string): string => {
  if (isFailureTransaction(desc)) {
    return SBL.UNSUCCESSFUL_TRANSACTION_DESC;
  }
  return transactionType;
};

const redirectOrPopupSBLStatusesSet = new Set(unsettledSblStatuses);

export const filterSBLDashboardItems = (
  contractStatus: SmallBusinessLoanContractStatus,
): boolean => {
  return (
    contractStatus === SmallBusinessLoanContractStatus.Active ||
    contractStatus === SmallBusinessLoanContractStatus.WrittenOff ||
    redirectOrPopupSBLStatusesSet.has(contractStatus)
  );
};

export const getInternalPortalRouteUrl = (product: string, id: string): string =>
  `/${product}/${id}`;

export function getPostDecisionApprovedApplication(
  applications: ApplicationStatusType[] = [],
  loans: AccountListProps['loans']['list'] = [],
): string {
  const approvedDigitalApplication = cloneDeep(applications)
    .sort(
      ({ status: { decisionDate: decisionDate1 } }, { status: { decisionDate: decisionDate2 } }) =>
        new Date(decisionDate2).getTime() - new Date(decisionDate1).getTime(),
    )
    .find(({ status: { decision } }) => decision === 'Approved');

  const hasSignedApprovedApplication = loans.some(
    ({ accountDetail: { id, contractStatus } }) =>
      id === approvedDigitalApplication?.applicationId &&
      contractStatus !== SmallBusinessLoanContractStatus.Pending,
  );

  return (!hasSignedApprovedApplication && approvedDigitalApplication?.applicationId) || null;
}

export const isSblRefinanceEligible = (
  loans: Array<Pick<SmallBusinessLoan, 'id' | 'isRefinanceEligible' | 'displayStatus'>>,
  applicationId?: string,
) => {
  if (!loans) return false;

  return loans.some(({ id, isRefinanceEligible, displayStatus }) => {
    const isEligible = isRefinanceEligible && activeSBLStatusesSet.has(displayStatus);
    return applicationId ? id === applicationId && isEligible : isEligible;
  });
};
