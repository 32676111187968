import { MicroilloParty as PartyIcon } from '@prospa/icons';

import { Banner } from '@components';

import { APPROVED_REFINANCE_APPLICATION_BANNER } from '@constants';
import { externalRedirect, getCustomerPortalRoute, trackBAActionEvent } from '@utils';

import type { ApprovedRefinanceBannerProps } from './type';

const redirectToApprovedAgreement = (approvedDigitalApplicationId?: string) => {
  trackBAActionEvent('sbl_refi_review_the_offer');
  return (
    approvedDigitalApplicationId &&
    externalRedirect({
      url: `${getCustomerPortalRoute('sbl', approvedDigitalApplicationId)}/approved`,
      openInNewTab: false,
    })
  );
};

export const ApprovedRefinanceBanner = ({
  approvedDigitalApplicationId,
}: ApprovedRefinanceBannerProps) => {
  if (!approvedDigitalApplicationId) {
    return null;
  }
  return (
    <Banner
      className="overdraft banner"
      icon={<PartyIcon />}
      title={APPROVED_REFINANCE_APPLICATION_BANNER.TITLE}
      subtitle={APPROVED_REFINANCE_APPLICATION_BANNER.SUBTITLE}
      onClick={() => redirectToApprovedAgreement(approvedDigitalApplicationId)}
      buttonText={APPROVED_REFINANCE_APPLICATION_BANNER.BUTTON}
    />
  );
};
