import { type FC } from 'react';

import classNames from 'classnames';
import { type FieldProps } from 'formik';

import { MicroiconTickSharp as CheckboxTick } from '@prospa/icons';

import './Checkbox.scss';

interface Props {
  label: string;
  disabled?: boolean;
}

export const Checkbox: FC<FieldProps & Props> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  disabled = false,
}) => {
  return (
    <div
      className={classNames('checkbox__container', {
        error: !!touched[field.name] && errors[field.name],
      })}
    >
      <label className={classNames('checkbox', { disabled: disabled })} htmlFor={field.name}>
        <span className="checkbox__input">
          <input
            type="checkbox"
            {...field}
            id={field.name}
            data-error={!!touched[field.name] && errors[field.name]}
          />
          <span
            className={classNames('checkbox__control', {
              error: !!touched[field.name] && errors[field.name],
            })}
          >
            <CheckboxTick width={20} height={20} />
          </span>
        </span>
        <span className={classNames('checkbox__label', 'pds-text')}>{label}</span>
      </label>
      {}
    </div>
  );
};
