import { Suspense } from 'react';

import {
  Card,
  LoanTransactions,
  ScheduledPayments,
  Section,
  Tabs,
  TransactionsLoader,
} from '@components';

import './LoanActivity.scss';
import { type LoanActivityProps } from './types';

export enum OpenModalType {
  CancelPaymentConfirm,
  SecurityModal,
  Error,
}

const LoanActivity = ({
  transactionsList,
  scheduledPaymentsList,
  onCancelScheduledPayment,
  loading,
}: LoanActivityProps) => {
  return (
    <Card className="loan-activity">
      <Section>
        <Tabs
          type="primary"
          tabs={[
            {
              tabHeader: 'Transactions',
              tab: (
                <LoanTransactions
                  transactions={transactionsList}
                  loading={loading}
                  showTitle={false}
                />
              ),
            },
            scheduledPaymentsList
              ? {
                  tabHeader: `Scheduled (${scheduledPaymentsList?.length || 0})`,
                  tab: (
                    <Suspense fallback={<TransactionsLoader />}>
                      <ScheduledPayments
                        loading={loading}
                        scheduledPaymentsData={scheduledPaymentsList}
                        useProspaStepUpFlow={false}
                        onCancelScheduledPayment={onCancelScheduledPayment}
                      />
                    </Suspense>
                  ),
                }
              : null,
          ].filter(item => item !== null)}
        />
      </Section>
    </Card>
  );
};

export default LoanActivity;
