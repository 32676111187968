import { type ReactNode } from 'react';

import { type FormikProps } from 'formik';

import { type BankPayeeV2, type BpayPayeeV2, type Country, type PaymentType } from '@generated-fg';

import type { AccountItem } from '../../../models/Account';
import type { NewPayeeValues, PayeeV2, SecurityCodeState } from '../../../models/PayAnyone';

export interface AddNewPayeeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onError?: () => void;
  onResend?: () => void;
  primaryOnClick: (payee: PayeeV2) => void;
  payFromAccount?: AccountItem;
  securityCodeState?: SecurityCodeState;
  setSecurityCodeState?: (state: SecurityCodeState) => void;
  securityCodeSuccess?: boolean;
  setSecurityCodeSuccess?: (success: boolean) => void;
  hideSaveToAddressBook?: boolean;
  newPayee?: NewPayeeValues;
  payeeType?: PaymentType;
  countryCode?: Country;
  footer?: ReactNode;
  resetFormBeforeClose?: boolean;
}

export enum TABINDEX {
  BANK = 0,
  BPAY = 1,
}

export type TUseSaveToAddressBookProps = Pick<
  AddNewPayeeModalProps,
  'onError' | 'countryCode' | 'payeeType' | 'onClose' | 'payFromAccount'
> & {
  onSuccess: (payee: BankPayeeV2 | BpayPayeeV2, isBankPayee: boolean) => void;
  newPayeeRef: React.MutableRefObject<FormikProps<NewPayeeValues>>;
  currentTab: TABINDEX;
};

export type TUseSaveToAddressBookAction = {
  isSavingPayeeToAddressBook: boolean;
  showSecurityCodeModal: boolean;
  handleCloseModal: () => void;
  onSaveToAddressBook: (token?: string) => void;
  addPayeeToAddressBook: () => void;
};
