import { LogEvent } from '../../types';

/**
 * Transforms a tracking object into a valid tracking string.
 */
export const transformTrackingParamsObjectToString = (
  trackingParams?: Parameters<LogEvent>[0]['eventParams'],
): string => {
  if (!trackingParams || typeof trackingParams === 'string') {
    return '';
  }

  // Remove legacy event name/label from GA tracking as this is for Segment link only
  if (Object.hasOwn(trackingParams, 'legacy_event_name')) {
    delete trackingParams.legacy_event_name;
  }
  if (Object.hasOwn(trackingParams, 'legacy_event_label')) {
    delete trackingParams.legacy_event_label;
  }

  let result = '';

  Object.entries(trackingParams).forEach(([key, value]) => {
    let valueStr = '';
    if (typeof value === 'object' && !Array.isArray(value)) {
      valueStr = JSON.stringify(value);
    } else {
      valueStr = value?.toString() || '';
    }
    result += `${result ? '_' : ''}${key}:${valueStr || 'null'}`;
  });

  return result;
};
