import { useEffect, useRef } from 'react';

import classNames from 'classnames';

import { getLottie } from './LottieUtil';
import type { LottieAnimationProps } from './types';

export const LottieAnimation = ({
  animationData,
  loop = false,
  className,
  width,
  height,
}: LottieAnimationProps) => {
  const containerRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const anim = getLottie({ containerRef, loop, animationData });
    return () => {
      anim.destroy();
    };
  }, [containerRef, animationData, loop]);

  return (
    <div
      style={{ ...(width ? { width } : {}), ...(height ? { height } : {}) }}
      className={classNames('lottie-animation', className)}
      ref={containerRef}
    />
  );
};
