import type { ReactNode } from 'react';

import type { DocumentNode } from 'graphql';

import {
  AccountSuspended,
  CashCross,
  IceCreamFlop,
  Search,
  TechnicalError,
} from '@prospa/salt-illustrations';

import {
  Country,
  CurrencyCode,
  type ScheduledTransferInput,
  TransferDocument,
  type TransferError,
  type TransferInput,
} from '@generated-fg';

import {
  type AccountItem,
  type PayAnyoneFormValues,
  type PayeeV2,
  ScheduledTransferErrorLevel,
  ScheduledTransferWarningLevel,
  TransferErrorLevel,
  TransferWarningLevel,
} from '@models';
import {
  currencyFormatter,
  transferErrorHeadings as errorHeadings,
  transferErrorIcons as errorIcons,
  isAuBankPayeeV2,
  isBPayPayeeV2,
  isNZBankPayeeV2,
} from '@utils';

export type VerificationDetails = {
  verificationCode?: string;
  correlationId?: string;
};

export interface FormattedPayAnyoneRequest {
  mutation: DocumentNode;
  payload:
    | (Omit<TransferInput, 'verificationCode' | 'correlationId'> & VerificationDetails)
    | (Omit<ScheduledTransferInput, 'verificationCode' | 'correlationId'> & VerificationDetails);
  displayData: PayAnyoneDisplayData;
}

export interface ErrorConfig {
  errorBody: string;
  errorTitle: string;
  confirmMessage?: string;
  errorIcon?: ReactNode;
}

export interface PayAnyoneDisplayData {
  amount: string;
  payeeName: string;
  description: string;
  paymentDateText: string;
  reference?: string;
}

const getRequestPayload = (
  formData: PayAnyoneFormValues,
  payee: PayeeV2,
  { id: productId, countryCode }: Pick<AccountItem, 'id' | 'countryCode'>,
): Omit<TransferInput, 'verificationCode' | 'correlationId'> => {
  const requestPayload: Omit<TransferInput, 'verificationCode' | 'correlationId'> = {
    productId,
    amount: {
      currency: countryCode === Country.Au ? CurrencyCode.Aud : CurrencyCode.Nzd,
      amount: Number(formData.amount),
    },
    description: formData.description,
    reference: formData.reference,
    ...(isAuBankPayeeV2(payee) && {
      auPayee: {
        accountName: payee.accountName,
        accountNumber: payee.account,
        bsb: payee.bsb,
      },
    }),
    ...(isNZBankPayeeV2(payee) && {
      nzPayee: {
        accountName: payee.accountName,
        accountNumber: payee.account,
        bankId: payee.bank,
        branch: payee.branch,
        suffix: payee.suffix,
      },
    }),
    ...(isBPayPayeeV2(payee) && {
      bPayPayee: {
        bPayBillerCode: payee.bPayBillerCode,
        bPayReference: payee.bPayReference,
        accountName: payee.accountName || payee.billerName,
      },
    }),
  };

  return requestPayload;
};

const getDisplayData = (formData: PayAnyoneFormValues, payee: PayeeV2): PayAnyoneDisplayData => {
  const newPayeeName = payee.accountName;

  return {
    amount: currencyFormatter(formData.amount),
    description: formData.description,
    reference: formData.reference,
    paymentDateText: 'Now',
    payeeName: newPayeeName,
  };
};

export const formatPayAnyonePayload = (
  formData: PayAnyoneFormValues,
  payee: PayeeV2,
  payFromAccount: Pick<AccountItem, 'id' | 'productType' | 'countryCode'>,
): FormattedPayAnyoneRequest => ({
  mutation: TransferDocument,
  payload: getRequestPayload(formData, payee, payFromAccount),
  displayData: getDisplayData(formData, payee),
});

export enum TransferErrorTypes {
  transfer = 'transfer',
  scheduledTransfer = 'scheduledTransfer',
}

const errorLevels = {
  [TransferErrorTypes.transfer]: {
    errors: TransferErrorLevel,
    warnings: TransferWarningLevel,
  },
  [TransferErrorTypes.scheduledTransfer]: {
    errors: ScheduledTransferErrorLevel,
    warnings: ScheduledTransferWarningLevel,
  },
};

export const getLogLevelByErrorCode = ({
  type,
  code,
}: {
  type: TransferErrorTypes;
  code: string;
}) => {
  const levelsByType = errorLevels[type];
  return levelsByType.errors[code] ? 'error' : levelsByType.warnings[code] ? 'warning' : 'info';
};

export const getTransferErrorIcon = (errorCode: TransferError): JSX.Element => {
  switch (true) {
    case errorIcons['cash_cross'].includes(errorCode):
      return <CashCross />;
    case errorIcons['account_suspended'].includes(errorCode):
      return <AccountSuspended />;
    case errorIcons['search'].includes(errorCode):
      return <Search />;
    case errorIcons['technical_error'].includes(errorCode):
      return <TechnicalError />;
    default:
      return <IceCreamFlop />;
  }
};

export const getTransferErrorHeading = (errorCode: TransferError): string => {
  switch (true) {
    case errorHeadings['not_enough_funds'].includes(errorCode):
      return 'Not enough funds';
    case errorHeadings['daily_transfer_limit_reached'].includes(errorCode):
      return 'Daily transfer limit reached';
    case errorHeadings['check_payment_details'].includes(errorCode):
      return 'Check payment details';
    default:
      return 'Couldn’t complete payment';
  }
};

export const defaultTransferErrorBody =
  'Sorry, we ran into a technical problem while processing your payment. Please try again.';
