export const supportPhoneNumbers = {
  AU: {
    url: 'tel:1300882867',
    text: '1300 882 867',
  },
  NZ: {
    url: 'tel:0800005797',
    text: '0800 005 797',
  },
};

export const contactUsUrl = {
  AU: 'https://www.prospa.com/contact-us',
  NZ: 'https://www.prospa.co.nz/contact-us',
};

export const supportLOCPhoneNumbers = {
  AU: {
    url: 'tel:1300653742',
    text: '1300 653 742',
  },
};
export const supportSBLPhoneNumbers = {
  NZ: {
    url: 'tel:0800134933',
    text: '0800 134 933',
  },
};

export const supportEmails = {
  AU: {
    url: 'mailto:help@prospa.com',
    text: 'help@prospa.com',
  },
  NZ: {
    url: 'mailto:help@prospa.co.nz',
    text: 'help@prospa.co.nz',
  },
};

// See supported colours schemas at `styles/_colors.scss`
export enum Colors {
  primary = 'Primary',
  error = 'Error',
}

export const MissedRepaymentPhoneNumber = {
  AU: {
    url: 'tel:1300864810',
    text: '1300 864 810',
  },
  NZ: {
    url: 'tel:0800134933',
    text: '0800 134 933',
  },
};

export const PDFViewerConstants = {
  width: 578,
  padding: 16,
  refreshRate: 128,
};

export enum ButtonSize {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SECONDARY_OUTLINE = 'secondary-outline',
  LINK = 'link',
  ICON_LINK = 'iconlink',
  ICON_LINK_UNDERLINE = 'iconlinkunderline',
}

export const SWITCH_BOX_SHADOW = `0px 2px 2px 0px rgba(0, 0, 0, 0.3)`;

export const breakpoints = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1024px)',
};
