import { type FC, type FocusEvent, type ReactNode, useState } from 'react';

import classNames from 'classnames';
import { type FieldProps } from 'formik';

import { InfoCircle } from '@prospa/salt-icons';

import { Tooltip } from '@components';

import './Input.scss';
import { type InputFieldProps } from './types';

export const Input: FC<FieldProps & InputFieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldError }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  type,
  onFocus,
  formatValue,
  maxLength,
  className,
  children,
  pattern,
  inputMode,
  clearErrorOnChange = false,
  toolTipProps,
  disabled,
  onChange,
}) => {
  const [showError, setShowError] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const hasValue = field.value || field.value === 0;

  const isError = !!touched[field.name] && errors[field.name] && showError;

  const {
    text,
    className: toolTipClassName,
    alwaysVisible,
    ...inputToolTipProps
  } = toolTipProps || {};

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  return (
    <div className={classNames('input-wrapper', className)}>
      <div className="input-container">
        <input
          inputMode={inputMode}
          pattern={pattern}
          type={type}
          maxLength={maxLength}
          value={formatValue ? formatValue(field.value) : field.value}
          id={field.name}
          className={classNames(hasValue && 'pds-text', {
            error: isError,
          })}
          {...field}
          onChange={e => {
            if (pattern) {
              const val = e.target.value;
              // If the current value passes the validity test then apply that to state
              if (e.target.validity.valid) {
                setFieldValue(field.name, val);
              } else if (val === '') {
                setFieldValue(field.name, val);
              }

              if (onChange) {
                setFieldValue(field.name, onChange(e));
              }
            } else {
              field.onChange(e);
            }
            setShowError(false);
            clearErrorOnChange && setFieldError(field.name, '');
          }}
          onBlur={e => {
            setShowError(true);
            setIsFocused(false);
            field.onBlur(e);
          }}
          onFocus={handleFocus}
          disabled={disabled}
        />
        <label
          className={classNames(hasValue && 'filled', 'pds-text', 'label')}
          htmlFor={field.name}
        >
          <p>{label}</p>
          {text && (alwaysVisible || isFocused || hasValue) && (
            <Tooltip
              text={text}
              className={classNames('tooltip', toolTipClassName)}
              {...inputToolTipProps}
            >
              <InfoCircle size={20} />
            </Tooltip>
          )}
        </label>
        {children}
      </div>
      {isError && (
        <div className={classNames('input__error', 'pds-caption')}>
          {errors[field.name] as ReactNode}
        </div>
      )}
    </div>
  );
};
