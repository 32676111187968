import { FullScreenAnimation, useTimeout } from '@all-in-one-web/common-ui';

import XeroConnectionAnimation from '../../../../assets/animations/xeroConnection.json';
import { LottieAnimation } from '../../LottieAnimation';
import { type TimeoutAnimationProps } from '../types';

export const OpenXeroAnimation = ({ duration = 2000, timeoutCallback }: TimeoutAnimationProps) => {
  useTimeout(() => {
    timeoutCallback();
  }, duration);

  return (
    <FullScreenAnimation
      animation={<LottieAnimation animationData={XeroConnectionAnimation} loop />}
      animationTitle="Opening Xero..."
    />
  );
};
