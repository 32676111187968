import type { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './Card.module.scss';
import type { CardProps } from './types';

export const Card = ({
  header,
  footer,
  showBoxShadow = false,
  showGreyBackground = false,
  children,
  className,
  testId = 'card',
  ...props
}: PropsWithChildren<CardProps>) => (
  <div
    data-testid={testId}
    className={classNames(
      styles.Card,
      { [styles.Shadow]: showBoxShadow, [styles.Gray]: showGreyBackground },
      className,
    )}
    {...props}
  >
    {header && (
      <>
        {header}
        <hr className={styles.Divider} />
      </>
    )}
    {children}
    {footer && (
      <>
        <hr className={styles.Divider} />
        {footer}
      </>
    )}
  </div>
);
