import { useMemo } from 'react';

import classNames from 'classnames';

import { IntegrationStatus } from '@generated-fg';

import styles from './ConnectionStatus.module.scss';
import { type ConnectionStatusProps } from './types';

// Note: only need to show connected or nothing otherwise
// -> will show connected if authorised with xero

export const ConnectionStatus = ({ status, displayStatus }: ConnectionStatusProps) => {
  const statusClassName = useMemo(() => {
    switch (status) {
      case IntegrationStatus.Connected:
        return styles.ConnectionConnected;
      case IntegrationStatus.SyncError:
        return styles.ConnectionError;
      case IntegrationStatus.NotConnected:
      case IntegrationStatus.AuthError:
        return styles.ConnectionUnconnected;
      default:
        return '';
    }
  }, [status]);

  return (
    !!displayStatus && (
      <div className={classNames(styles.ConnectionStatus, statusClassName)}>{displayStatus}</div>
    )
  );
};
