import { formatDistanceToNowStrict } from 'date-fns';

import { type IntegrationProductInfo, IntegrationStatus, ProspaProduct } from '@generated-fg';

import { type AppData, ProductTypes } from '@models';

import { formatDateInDayMonthYearTime } from './date';

export const getXeroGetStartedTracking = ({
  customerProducts,
  identityDetails,
}: Pick<AppData, 'customerProducts' | 'identityDetails'>) => {
  return {
    date: new Date().toISOString(),
    products: [
      customerProducts.BA ? ProductTypes.BA : '',
      customerProducts.LOCM ? ProductTypes.LOCM : '',
    ].filter(Boolean),
    prospa_identity_id: identityDetails?.id,
  };
};

export const isXeroConnected = (status: IntegrationStatus) => {
  const statusImpliesConnected = new Set([
    IntegrationStatus.Connected,
    IntegrationStatus.SyncError,
  ]);
  return statusImpliesConnected.has(status);
};

export const calculateSyncTimeByProductType = ({
  syncDateTime,
  productType,
}: {
  syncDateTime: IntegrationProductInfo['syncDateTime'];
  productType: IntegrationProductInfo['productType'];
}) => {
  if (!syncDateTime) return '';
  if (productType === ProspaProduct.LineOfCreditMambu)
    return `Last sync: ${formatDateInDayMonthYearTime(syncDateTime)}`;
  return `Last sync: ${formatDistanceToNowStrict(new Date(syncDateTime))} ago`;
};
