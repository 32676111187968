import { Spinner } from '@components';

import styles from './DefaultSpinner.module.scss';
import { type DefaultSpinnerProps } from './types';

export function DefaultSpinner({ caller = '' }: DefaultSpinnerProps) {
  return (
    <div
      className={styles.DefaultSpinner}
      data-caller={process.env.NODE_ENV === 'development' && caller}
    >
      <Spinner />
    </div>
  );
}
