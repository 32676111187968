import { type BusinessLoanRepaymentCalculatorFormValues } from './types';

export class BusinessLoanRepaymentCalculator {
  private readonly DEFAULT_APR = 31;
  private readonly WEEKS_PER_YEAR = 52;

  constructor(
    private readonly amount: BusinessLoanRepaymentCalculatorFormValues['amount'],
    private readonly loanTermYears: BusinessLoanRepaymentCalculatorFormValues['loanTermYears'],
    private readonly apr?: number,
  ) {
    this.amount = amount;
    this.loanTermYears = loanTermYears;
    this.apr = apr || this.DEFAULT_APR;
  }

  calculateAnnualApr(): number {
    return this.apr / 100;
  }

  calculateWeeklyRepayment(): number {
    const annualApr = this.calculateAnnualApr();
    const weeklyInterestRate = Math.pow(1 + annualApr, 1 / this.WEEKS_PER_YEAR) - 1;
    const weeklyInstalments = this.calculateWeeklyInstalments();

    const weeklyRepayment =
      (this.amount * (weeklyInterestRate * Math.pow(1 + weeklyInterestRate, weeklyInstalments))) /
      (Math.pow(1 + weeklyInterestRate, weeklyInstalments) - 1);
    return weeklyRepayment;
  }

  calculatePaybackAmount(): number {
    const weeklyRepayment = this.calculateWeeklyRepayment();
    const weeklyInstalments = this.calculateWeeklyInstalments();

    const paybackAmount = weeklyRepayment * weeklyInstalments;
    return paybackAmount;
  }

  getLoanTerm(): number {
    return this.loanTermYears;
  }

  calculateWeeklyInstalments(): number {
    return this.loanTermYears * this.WEEKS_PER_YEAR;
  }
}
