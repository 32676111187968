import { type FC, useCallback, useMemo } from 'react';

import { Modal } from '@components';

import { externalRedirect, trackBAActionEvent } from '@utils';

import { MissedRepaymentModalBody } from './MissedRepaymentModalBody/MissedRepaymentModalBody';
import type { MissedRepaymentModalProps } from './types';

export const MissedRepaymentModal: FC<MissedRepaymentModalProps> = ({
  isOpen,
  onClose,
  inArrearsAmount,
  productType,
  countryCode,
  collectionsPaymentUrl,
}) => {
  const hideFooterBorder = useMemo(() => !!collectionsPaymentUrl, [collectionsPaymentUrl]);
  const primaryCTALabel = collectionsPaymentUrl ? 'Make payment' : 'Got it';

  const primaryCTAOnClick = useCallback(() => {
    if (!collectionsPaymentUrl) {
      return onClose();
    }
    trackBAActionEvent('sbl_repayment__cta');
    return externalRedirect({
      url: collectionsPaymentUrl,
      openInNewTab: true,
    });
  }, [collectionsPaymentUrl, onClose]);

  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onClose={onClose}
      showCloseModal
      body={
        <MissedRepaymentModalBody
          productType={productType}
          countryCode={countryCode}
          inArrearsAmount={inArrearsAmount}
          collectionsPaymentUrl={collectionsPaymentUrl}
        />
      }
      hideFooterBorder={hideFooterBorder}
      primaryCTALabel={primaryCTALabel}
      primaryCTAOnClick={primaryCTAOnClick}
    />
  );
};
