export const GTM_SCRIPT_ID = 'gtmScript';

export const initialiseDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
};

export const loadGoogleTagManagerScript = (
  GTM_ID: string,
  setError?: (error: boolean) => void,
  onLoadCallback?: () => void,
) => {
  const id = GTM_ID;
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtm.js?id=' + id;
  script.async = true;
  script.id = GTM_SCRIPT_ID;
  script.onerror = () => setError?.(true);
  script.onload = () => onLoadCallback?.();
  document.body.appendChild(script);
};

export const initialiseGoogleTagManager = (
  GTM_ID: string,
  setError?: (error: boolean) => void,
  onLoadCallback?: () => void,
) => {
  const existingGtmScript = document.getElementById(GTM_SCRIPT_ID);
  if (!existingGtmScript) {
    initialiseDataLayer();
    loadGoogleTagManagerScript(GTM_ID, setError, onLoadCallback);
  } else {
    onLoadCallback?.();
  }
};

export const isGoogleTagManagerInitialised = () => {
  const existingGtmScript = document.getElementById(GTM_SCRIPT_ID);
  return !!existingGtmScript;
};
