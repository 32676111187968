import type { ContentCard, ContentCardPlatforms } from './BrazeProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

/**
 * Filter content cards received from Braze for only the
 * ones relevant to this platform.
 *
 * Note: if platform is not set, or the values are not an
 * exact match (case-sensitive) we will not show the card.
 */
export function filterContentCards(cards: Array<ContentCard>, platform: ContentCardPlatforms) {
  return cards?.filter(c => !!c.extras?.platform?.split(',')?.includes(platform)) ?? [];
}

/**
 * Filter to optimistically remove the card to avoid a
 * delay is removing a card when a user dismisses.
 */
export function removeCardById(cards: Array<ContentCard>, cardId?: string): Array<ContentCard> {
  return cards.filter(card => card.id !== cardId);
}

/**
 * Sort content cards by "location" key value in descending order.
 */
export function sortContentCards(
  cardA: Pick<ContentCard, 'extras'>,
  cardB: Pick<ContentCard, 'extras'>,
) {
  let priorityA = Number(cardA.extras?.priority ?? 0);
  let priorityB = Number(cardB.extras?.priority ?? 0);

  if (isNaN(priorityA)) {
    priorityA = 0;
  }
  if (isNaN(priorityB)) {
    priorityB = 0;
  }

  return priorityB - priorityA;
}
