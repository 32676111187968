import axios from 'axios';
import { ajax } from 'rxjs/ajax';

import environments from '../environments';
import { newGuid } from '../utils/guid';

class Api {
  public verificationCode: string;
  public token: string;
  public prospaStepUpToken: string;
  public hayToken: string;
  public correlationId: string;
  private identityUrl = environments.identityServer.authority;

  /**
   * This method won't be required anymore as the FE Gateway exchanges the token under the hood (HayApiDataSource.withHayAuth).
   *  Can be removed when useGetBankAccountsQuery, usePersonalQuery, useBusinessAccountQuery are migrated to the new gateway
   *
   * @deprecated
   * @returns Promise<string>
   */
  public getHayBearerToken = async () => {
    const { data } = await axios.post<{ access_token: string }>(
      `${environments.hay.authUrl}/exchange`,
      null,
      {
        headers: { Authorization: `Bearer ${this.token}` },
      },
    );
    const { access_token } = data;
    this.hayToken = access_token;
    return access_token;
  };

  public postMobileVerifyEmail = (code: string) =>
    ajax.post(
      `${this.identityUrl}/account/mobile-setup/verify-email`,
      {
        verificationCode: code,
        markOpenOnly: true,
      },
      this.getHeader(),
    );

  private getHeader = (correlationId?: string) => {
    let header = {
      'X-Correlation-ID': correlationId || newGuid(),
      'Content-Type': 'application/json',
    };
    if (this.token) {
      header = Object.assign(header, {
        Authorization: `Bearer ${this.token}`,
      });
    }

    // IE <=11 only.
    // When user login out and login in with different account, they will still see old data which is cached by IE
    // for reference:
    // https://cherniavskii.com/internet-explorer-requests-caching/
    // So we add below header to avoid cache
    if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
      header = Object.assign(header, { Pragma: 'no-cache' });
    }
    return header;
  };
}

export default new Api();
