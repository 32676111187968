import classNames from 'classnames';
import { format } from 'date-fns';

import { currencyFormatter } from '@utils';

import { type TransactionRecord, TransactionSign } from './types';

export const getExpandedTransactionDetails = (transaction: TransactionRecord, hideTime = false) => {
  if (!transaction) {
    return null;
  }

  return (
    <>
      {!!transaction.reference && (
        <div className="pds-text">{`Reference: ${transaction.reference}`}</div>
      )}
      <div className="pds-text">
        {format(new Date(transaction.date), `E, dd MMM yyyy ${hideTime ? '' : 'h:mm aaa'}`)}
      </div>
      {!!transaction.initiatedBy && <div className="pds-text">by {transaction.initiatedBy}</div>}
    </>
  );
};

export const formatTransactionAmount = (amount: number, sign?: TransactionSign) => {
  const isAmountPositive = Math.sign(amount) === 1;
  const isPositiveColor = sign ? sign !== TransactionSign.Negative : isAmountPositive;

  let amountSign = '';
  if (!sign) {
    amountSign = isAmountPositive ? '+' : '-';
  } else {
    switch (sign) {
      case TransactionSign.Positive:
        amountSign = '+';
        break;
      case TransactionSign.Negative:
        amountSign = '-';
        break;
      case TransactionSign.Neutral:
      default:
        amountSign = '';
        break;
    }
  }

  const formattedAmount = currencyFormatter(Math.abs(amount));
  return (
    <div className="transaction-item__amount">
      <span className={classNames('transaction-item__amount__sign', { positive: isPositiveColor })}>
        {amountSign}
      </span>
      {formattedAmount}
    </div>
  );
};
