import { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';

import { breakpoints } from '@constants';

import desktopAnimation from '../../../../../assets/animations/Confetti/Confetti_Desktop.json';
import mobileAnimation from '../../../../../assets/animations/Confetti/Confetti_Mobile.json';
import tabletAnimation from '../../../../../assets/animations/Confetti/Confetti_Tablet.json';
import { getLottie } from '../../../LottieAnimation';
import styles from './NzMobileCampaign.module.scss';

type NzMobileCampaignAnimationProps = {
  play: boolean;
};
const NzMobileCampaignAnimation = ({ play }: NzMobileCampaignAnimationProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const animRef = useRef(null);
  const isMobile = useMediaQuery({ query: breakpoints.mobile });
  const isTablet = useMediaQuery({ query: breakpoints.tablet });
  const [hidden, setHidden] = useState(false);
  const animationData = useMemo(() => {
    if (isMobile) {
      return mobileAnimation;
    } else if (isTablet) {
      return tabletAnimation;
    } else {
      return desktopAnimation;
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    // document ready
    if (play) {
      animRef.current = getLottie({
        containerRef,
        loop: false,
        animationData,
        autoplay: true,
      });
      animRef.current.addEventListener('complete', () => {
        setHidden(true);
      });
    }
    return () => {
      animRef?.current?.destroy();
    };
  }, [animationData, play]);

  return (
    <div
      data-testid="animation-container"
      className={classNames(styles.NzMobileCampaign__Animation__Container, {
        [`${styles.NzMobileCampaign__Animation__Container__Hidden}`]: hidden,
      })}
    >
      <div ref={containerRef}></div>
    </div>
  );
};

export default NzMobileCampaignAnimation;
