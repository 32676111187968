// sort-imports-ignore
import './polyfills';

import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import { AuthProvider } from 'react-oidc-context';

import { ApolloProvider } from '@apollo/client';

import App from './app/App';
import { initLogger } from './app/utils/logger';
import { createApolloClient } from './app/apolloClient';
import oidcConfig from './app/oidcConfig';
import { SaltProvider } from '@prospa/salt-react-beta';
import './index.scss';
import '@prospa/salt-react-beta/reset';

initLogger();

const apolloClient = createApolloClient();

const root = createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={apolloClient}>
    <AuthProvider {...oidcConfig}>
      <SaltProvider>
        <App />
      </SaltProvider>
    </AuthProvider>
  </ApolloProvider>,
);

ReactModal.setAppElement('#root');
