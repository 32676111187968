import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import { DocumentViewer, type DocumentViewerProps } from '@all-in-one-web/common-ui';
import { BrokenPot } from '@prospa/salt-illustrations';

import { breakpoints } from '@constants';
import { externalRedirect, trackBAActionEvent } from '@utils';

import styles from './styles.module.scss';

export const BillViewer = ({ fileLink }: Pick<DocumentViewerProps, 'fileLink'>) => {
  const isMobile = useMediaQuery({ query: breakpoints.mobile });

  const onHeaderLinkClick = useCallback(() => {
    trackBAActionEvent('bill_open_preview__click');
    externalRedirect({ url: fileLink, openInNewTab: true });
  }, [fileLink]);

  return (
    <DocumentViewer
      fileLink={fileLink}
      showDocument={!isMobile || !fileLink}
      LoadingComponent={<div className={styles.BillViewerLoadingContainer} />}
      OnErrorComponent={
        <div className={styles.BillViewerOnErrorContainer}>
          <BrokenPot size={64} />
          <p>We are unable to preview your bill</p>
        </div>
      }
      headerProps={{
        title: 'View bill in new tab',
        onClick: onHeaderLinkClick,
      }}
    />
  );
};
