import { Bank, Star } from '@prospa/salt-icons';

import { Country, PaymentType } from '@generated-fg';

import { formatBsb } from '@utils';

import BpayBlackBg from '../../../assets/BpayBlackBg.svg';
import BpayGreyBg from '../../../assets/BpayGreyBg.svg';
import type { PayeeV2 } from '../../models/PayAnyone';
import { type PayeeDetailsProps } from '../PayeeDetails/types';
import type { PaymentConfirmationProps } from '../PaymentConfirmation/types';

export const getPayeeIcon = (paymentType: string) => {
  if (paymentType === PaymentType.BusinessAccount) {
    return <Star size={24} />;
  }
  if (paymentType === PaymentType.Bpay) {
    return <img src={BpayGreyBg} alt="bpay-grey icon" role="presentation" />;
  }
  return <Bank size={24} />;
};

export const getActivePayeeIcon = (paymentType: string) => {
  if (paymentType === PaymentType.BusinessAccount) {
    return <Star size={24} style={{ transform: 'translate(0, 2px)' }} />;
  }
  if (paymentType === PaymentType.Bpay) {
    return <img src={BpayBlackBg} alt="bpay-black icon" role="presentation" />;
  }
  return <Bank size={24} />;
};

export const getPayeeHeader = (payee: PayeeDetailsProps['currentPayee']) => {
  if ('paymentType' in payee && payee.paymentType === PaymentType.BusinessAccount) {
    return 'Business Account';
  }
  return payee.accountName;
};

export const getPayeeSubheader = (payee: PaymentConfirmationProps['payee']) => {
  if ('bPayBillerCode' in payee) {
    return `${payee.bPayBillerCode} ${payee.bPayReference} ${payee.billerName || ''}`;
  }
  if ('countryCode' in payee) {
    if (payee.bank) return `${payee.bank}-${payee.branch}-${payee.account}-${payee.suffix}`;
    if (payee.bsb) return `${formatBsb(payee.bsb)} ${payee.account}`;
  }
  return '';
};

export const getFuzzyPayeeMatch = (payee: PayeeV2, searchTerm: string) => {
  if ('bPayBillerCode' in payee) {
    return (
      payee.accountName +
      (payee.billerName || '') +
      payee.bPayBillerCode +
      payee.bPayReference
    )
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  }
  if ('countryCode' in payee) {
    if (payee.countryCode === Country.Nz) {
      return (payee.accountName + `${payee.bank}-${payee.branch}-${payee.account}-${payee.suffix}`)
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    }
  }
  return (getPayeeHeader(payee) + payee.account + payee.bsb)
    .toLowerCase()
    .includes(searchTerm.toLowerCase());
};
