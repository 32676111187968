import { type FC } from 'react';

import classNames from 'classnames';

import styles from './TableCell.module.scss';
import { type TableDataCellProps } from './types';

export const TableDataCell: FC<TableDataCellProps> = ({ className, children, ...props }) => {
  return (
    <td className={classNames(className, styles.TableDataCell)} {...props}>
      {children}
    </td>
  );
};
