import { type FC } from 'react';

import { Status } from '../Status/Status';
import styles from './TitleWithStatus.module.scss';
import { type TitleWithStatusProps } from './types';

const TitleWithStatus: FC<TitleWithStatusProps> = ({
  title,
  statusProps,
}: TitleWithStatusProps) => {
  return (
    <div className={styles.TitleWithStatus}>
      <h2 className={styles.TitleWithStatusTitle}>{title}</h2>
      {statusProps && <Status {...statusProps} />}
    </div>
  );
};

export default TitleWithStatus;
