import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';

import { Button, ButtonSize, ButtonType, CVPCard, CVPCardBody } from '@all-in-one-web/common-ui';

import { BUSINESS_ACCOUNT_CVP_CARD_COPY, MOBILE_LINKS, breakpoints } from '@constants';

import BA_CVP_QR_CODE from '../../../assets/prospa-app-QR-code-BA-page.svg';
import AppStoreDownload from '../../../assets/symbols/AppStore.svg';
import GoogleStoreDownload from '../../../assets/symbols/GooglePlayStore.svg';
import styles from './BusinessAccountCVPCard.module.scss';
import { type BusinessAccountCVPCardProps } from './types';

const BusinessAccountCVPCardDisclaimer = () => {
  const isMobile = useMediaQuery({ query: breakpoints.mobile });

  return (
    <div className={styles.BusinessAccountCVPCardDisclaimer}>
      <h6 className={styles.BusinessAccountCVPCardDisclaimerTitle}>
        Apply in minutes on the Prospa App
      </h6>
      <p className={styles.BusinessAccountCVPCardDisclaimerDescription}>
        Open the Business Account via the Prospa App and start spending straight away by adding a
        Prospa Card to your digital wallet.
      </p>
      <hr className={styles.Divider} />

      <div className={styles.BusinessAccountCVPCardDisclaimerCodeContainer}>
        {!isMobile && (
          <img
            className={classNames(styles.BusinessAccountCVPCardDisclaimerCode)}
            src={BA_CVP_QR_CODE}
            alt="https://on.prospa.com/cvpaio"
          />
        )}
        <div>
          <p className={classNames(styles.BusinessAccountCVPCardDisclaimerCodeText)}>
            {isMobile
              ? 'Visit the App store, Google Play to download instantly.'
              : 'Scan the QR code, or visit the App store, Google Play to download instantly'}
          </p>
          <div>
            <a
              href={MOBILE_LINKS.IOS_APP_STORE_URL}
              className={styles.BusinessAccountCVPCardDisclaimerDownloadLink}
            >
              <img
                src={AppStoreDownload}
                alt="Download Prospa Mobile App from Apple App Store"
                className="download"
                role="presentation"
              />
            </a>
            <a
              href={MOBILE_LINKS.ANDROID_GOOGLE_PLAY_STORE_URL}
              className={styles.BusinessAccountCVPCardDisclaimerDownloadLink}
            >
              <img
                src={GoogleStoreDownload}
                alt="Download Prospa Mobile App from Google Play Store"
                className="download"
                role="presentation"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BusinessAccountCVPCard = ({ buttonProps }: BusinessAccountCVPCardProps) => {
  const isMobile = useMediaQuery({ query: breakpoints.mobile });

  return (
    <CVPCard
      title="Business Account"
      body={
        <div data-testid="product-cvp-card-BA">
          <CVPCardBody
            mediaUrl={
              isMobile
                ? BUSINESS_ACCOUNT_CVP_CARD_COPY.MEDIA.MOBILE
                : BUSINESS_ACCOUNT_CVP_CARD_COPY.MEDIA.DESKTOP
            }
            heading={BUSINESS_ACCOUNT_CVP_CARD_COPY.HEADING}
            description={BUSINESS_ACCOUNT_CVP_CARD_COPY.DESCRIPTION}
            valuePoints={[
              BUSINESS_ACCOUNT_CVP_CARD_COPY.POINT_1,
              BUSINESS_ACCOUNT_CVP_CARD_COPY.POINT_2,
              BUSINESS_ACCOUNT_CVP_CARD_COPY.POINT_3,
            ]}
            disclaimer={<BusinessAccountCVPCardDisclaimer />}
          />
          {!!buttonProps && (
            <div className={styles.BusinessAccountCVPCardButtonContainer}>
              <Button
                className={styles.BusinessAccountCVPCardButton}
                label="No, continue applying on web"
                onClick={buttonProps.onClick}
                type={ButtonType.SECONDARY_OUTLINE}
                size={ButtonSize.MEDIUM}
              />
            </div>
          )}
        </div>
      }
    />
  );
};
