import { type TUnderMaintenanceFF } from '@models';

import { formatDateInFullWeekDayMonth, isNowInATimePeriod } from './date';

// NOTE: change the default value if the launch darkly not proceed
const nextMaintenance = {
  startTime: '2023-05-12T00:00',
  endTime: '2023-05-12T02:00',
};

export function isUnderMaintenance(underMaintenance: TUnderMaintenanceFF = nextMaintenance) {
  if (!underMaintenance) {
    return false;
  }
  const { startTime, endTime } = underMaintenance;
  return isNowInATimePeriod(startTime, endTime);
}

export function getUnderMaintenanceNotification(
  underMaintenance: TUnderMaintenanceFF = nextMaintenance,
) {
  let message = undefined;
  if (!underMaintenance) {
    return message;
  }
  const { endTime, startTime } = underMaintenance;
  const now = new Date().getTime();
  const endUTCTime = new Date(endTime).getTime();
  if (now <= endUTCTime && !!startTime) {
    message = `Your account will be unavailable on ${formatDateInFullWeekDayMonth(
      startTime,
    )} until ${formatDateInFullWeekDayMonth(endTime)} AEST for maintenance.`;
  }
  return message;
}

export function getUnderMaintenancePageMessage(
  underMaintenance: TUnderMaintenanceFF = nextMaintenance,
) {
  let message = undefined;
  if (!underMaintenance) {
    return message;
  }
  const { endTime, startTime } = underMaintenance;

  message = `This page has maintenance scheduled between ${formatDateInFullWeekDayMonth(
    startTime,
  )} and ${formatDateInFullWeekDayMonth(endTime)} AEST.`;

  return message;
}
