export enum TestGroups {
  A = 'A',
  B = 'B',
}

export type TestGroupConfig = {
  group: TestGroups;
  getStartedBanner: {
    title: string;
    description: string;
  };
  valuePropositionPage: {
    title: string;
    description: string;
    pointOne: string;
    pointTwo: string;
    pointThree: string;
  };
  billCVPModal: {
    title: string;
    description: string;
    images: {
      mobile: string;
      desktop: string;
    };
  };
};
