import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { AnalyticsService } from '../types';

export const CONTEXT_INITIAL_VALUE = {
  analytics: undefined,
};
interface AnalyticsProviderState {
  analytics?: AnalyticsService;
}

interface AnalyticsProviderProps {
  analytics: AnalyticsService;
}

const AnalyticsContext = createContext<AnalyticsProviderState>(CONTEXT_INITIAL_VALUE);

export const AnalyticsProvider = (props: PropsWithChildren<AnalyticsProviderProps>) => {
  return (
    <AnalyticsContext.Provider
      value={{
        analytics: props.analytics,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
