import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Cross as CrossButton } from '@prospa/salt-icons';

import { breakpoints } from '../../../constants';
import styles from './ImageOnlyBanner.module.scss';
import { type ImageOnlyBannerProps } from './types';

export const ImageOnlyBanner = ({
  contentCard,
  onClick,
  onDismiss,
  onView,
}: ImageOnlyBannerProps) => {
  const isMobile = useMediaQuery({ query: breakpoints.mobile });
  const isTablet = useMediaQuery({ query: breakpoints.tablet });
  const imageUrl = useMemo(() => {
    if (isMobile) {
      return contentCard.imageUrl;
    } else if (isTablet) {
      return contentCard.extras?.image_tablet;
    } else {
      return contentCard.extras?.image_desktop;
    }
  }, [isMobile, isTablet, contentCard]);

  useEffect(() => {
    if (imageUrl) {
      onView?.(contentCard);
    }
  }, [onView, contentCard, imageUrl]);

  if (!imageUrl) {
    return null;
  }

  return (
    <div className={styles.ImageOnlyBanner}>
      <img
        src={imageUrl}
        alt="content card banner"
        className={styles.ImageOnlyBannerBackgroundImage}
        onClick={() => {
          onClick?.(contentCard);
        }}
      />

      {/* close button */}
      {contentCard.dismissible && (
        <div
          className={styles.ImageOnlyBannerDismissButton}
          role="button"
          onClick={() => onDismiss?.(contentCard)}
        >
          <CrossButton size={24} />
        </div>
      )}
    </div>
  );
};
