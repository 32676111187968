import { useEffect, useState } from 'react';

import { initialiseGoogleTagManager } from '@all-in-one-web/analytics';

import { BA_GTM_ID } from '@constants';

export const useGtmLoader = () => {
  const [isGtmLoaded, setIsGtmLoaded] = useState(undefined);
  const [googleTagManagerError, setGoogleTagManagerError] = useState(false);

  useEffect(() => {
    initialiseGoogleTagManager(BA_GTM_ID, setGoogleTagManagerError, () => {
      setIsGtmLoaded(true);
    });
  }, []);

  useEffect(() => {
    googleTagManagerError && setIsGtmLoaded(false);
  }, [googleTagManagerError]);

  return {
    isGtmLoaded,
  };
};
