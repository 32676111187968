import { type AppData, SettingKeyToFeatureFlagsKey } from '@models';

/**
 * Experiment flags are also known as A/B testing flags.
 *
 * Please use the following template: experiment-{experiment_name}: {cohort_name}-{variation_name}]
 *
 * How to set up A/B testing: https://prospa.atlassian.net/wiki/x/BYBHww
 */
export type ExperimentFlags = {
  'experiment-billpay_cvp': 'ba_loc-a' | 'ba_loc-b';
};

export const buildExperimentFlags = (featureFlags?: AppData['featureFlags']): ExperimentFlags => {
  return {
    'experiment-billpay_cvp':
      featureFlags && featureFlags[SettingKeyToFeatureFlagsKey.BILL_PAY] ? 'ba_loc-a' : 'ba_loc-b',
  };
};
