import { WebAlert as AlertIcon } from '@prospa/icons';

import { Modal } from '@components';

import './StolenCardModal.scss';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  handleSubmit: () => void;
}

export const StolenCardModal = ({ isOpen, toggle, handleSubmit }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      size="medium"
      showCloseModal
      primaryCTALabel="Next"
      primaryCTAOnClick={handleSubmit}
      body={
        <div className="stolen-card-modal">
          <AlertIcon className="stolen-card-modal__icon" />
          <h4>Lost or stolen card</h4>
          <p className="pds-text">
            {
              'Your current card will be reported as lost or stolen. We\u2019ll cancel it immediately and send you a replacement card.'
            }
          </p>
        </div>
      }
    />
  );
};
