import * as Sentry from '@sentry/react';

import { type UserProperties, isGoogleTagManagerInitialised } from '@all-in-one-web/analytics';
import { type ClarityClient } from '@all-in-one-web/clarity';

import { type AppData } from '@models';
import { SESSION_ID, buildExperimentFlags, trackUser } from '@utils';

type IntegrationsUserSetter = (userProperties?: UserProperties, clarity?: ClarityClient) => void;

export const setSentryUser: IntegrationsUserSetter = (userProperties?: UserProperties) => {
  if (!Sentry.isInitialized() || !userProperties) return;
  const {
    userId,
    firstName,
    email,
    extras: { isMobileAppUser },
    sessionId,
  } = userProperties;
  Sentry.setUser({ id: userId, username: firstName, email, isMobileAppUser, sessionId });
};

export const setClarityUser: IntegrationsUserSetter = (
  userProperties?: UserProperties,
  clarity?: ClarityClient,
) => {
  if (!clarity || !userProperties) return;
  const { userId, sessionId } = userProperties;
  const { extras, ...userPropertiesNoExtras } = userProperties;
  clarity.identify(userId, sessionId);
  clarity.setTags({ ...userPropertiesNoExtras, ...extras });
};

export const setGAAndSegmentUser: IntegrationsUserSetter = (userProperties?: UserProperties) => {
  if (!userProperties || !isGoogleTagManagerInitialised()) return;
  trackUser(userProperties);
};

export const setIntegrationsUser: IntegrationsUserSetter = (
  userProperties?: UserProperties,
  clarity?: ClarityClient,
) => {
  const integrationsUserSetters: Array<IntegrationsUserSetter> = [
    setSentryUser,
    setClarityUser,
    setGAAndSegmentUser,
  ];
  integrationsUserSetters.forEach(setter => setter(userProperties, clarity));
};

export const buildUserProducts = (customerProducts: AppData['customerProducts']): string => {
  if (!customerProducts) return '';
  return Object.keys(customerProducts)
    .filter(key => customerProducts[key])
    .join(',')
    .toLowerCase();
};

export const buildUserProperties = (appData: Partial<AppData>): UserProperties | null => {
  if (!appData) return null;

  const {
    identityDetails: { id: userId, givenName: firstName, familyName: lastName, email } = {},
    signInInfo: { isMobileAppUser = false } = {},
    customerProducts,
    cdpId,
    accountId: hayAccountId = '',
    featureFlags,
  } = appData;

  const userProducts = buildUserProducts(customerProducts);
  const experimentFlags = buildExperimentFlags(featureFlags);

  return {
    cdpId,
    userId,
    hayAccountId,
    sessionId: SESSION_ID,
    firstName,
    lastName,
    email,
    platform: 'web',
    products: userProducts,
    extras: { isMobileAppUser: String(!!isMobileAppUser), ...experimentFlags },
  };
};
