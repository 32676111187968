import { lazy, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { getFileType, isPdf } from '../../utils';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import styles from './DocumentViewer.module.scss';
import { type DocumentViewerProps } from './types';

const PDFViewer = lazy(() => import('./PDFViewer/PDFViewer'));
export const DocumentViewer = ({
  fileLink,
  headerProps,
  showControl,
  LoadingComponent,
  NoDataComponent,
  OnErrorComponent,
  onLoadError,
  className,
  showDocument = true,
}: DocumentViewerProps) => {
  const [imageLoadError, setImageLoadError] = useState<boolean>(false);
  const fileName = useMemo(() => {
    const urlParts = fileLink.split('?')[0].split('/');
    return urlParts[urlParts.length - 1];
  }, [fileLink]);

  useEffect(() => {
    setImageLoadError(false);
  }, [fileLink]);

  const isPdfFile = useMemo(() => isPdf(getFileType(fileName)), [fileName]);

  return (
    <div className={classNames(className, styles['DocumentViewContainer'])}>
      <ExternalLink {...headerProps} />
      {showDocument && isPdfFile ? (
        <PDFViewer
          fileLink={fileLink}
          LoadingComponent={LoadingComponent}
          NoDataComponent={NoDataComponent}
          OnErrorComponent={OnErrorComponent}
          onLoadError={onLoadError}
          showControl={showControl}
          className={styles['DocumentViewerImage']}
        />
      ) : null}
      {showDocument && !isPdfFile && !imageLoadError ? (
        <img
          src={fileLink}
          alt={fileName}
          className={styles['DocumentViewerImage']}
          onError={() => setImageLoadError(true)}
        />
      ) : null}
      {imageLoadError && <div>Failed to load image</div>}
    </div>
  );
};
