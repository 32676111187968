import { xor } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

export function insertIf(condition, ...elements) {
  return condition ? elements : [];
}

export function hasSameValues(array1, array2) {
  return isEmpty(xor(array1, array2));
}

export function isValueInArray(value: unknown, array: unknown[]) {
  const arrayAsSet = new Set(array);
  return arrayAsSet.has(value);
}
