import { type ReactElement } from 'react';

import cx from 'classnames';

import { Dropdown } from '@components';

import { useBillDropdown } from '@hooks';

import styles from './BillDropdownMenu.module.scss';
import { type BillDropdownMenuProps } from './types';

export const BillDropdownMenu = (
  props: BillDropdownMenuProps,
): ReactElement<BillDropdownMenuProps> => {
  const { dropdownItems = [], toggle, popupNotification = null } = useBillDropdown(props) || {};
  if (dropdownItems.length === 0) return null;
  return (
    <>
      <Dropdown
        menuProps={{ cssModule: styles, className: cx(styles.BillDropdownMenu) }}
        toggleProps={{ 'data-testid': 'bills-dropdown-menu' }}
        toggle={toggle}
        items={dropdownItems}
      />
      {popupNotification}
    </>
  );
};
