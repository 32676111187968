import { StarRatings } from '@all-in-one-web/common-ui';

import styles from './AppStoreRating.module.scss';
import type { TAppStoreRating } from './types';

export const AppStoreRating = ({ rating = 0, numStars = 5, text = '' }: TAppStoreRating) => {
  return rating ? (
    <div className={styles.AppStoreRating}>
      <span className={styles.AppStoreRatingText}>{rating}</span>
      <StarRatings numStars={numStars} rating={rating} className={styles.AppStoreRatingStars} />
      <span className={styles.AppStoreRatingStore}>{text}</span>
    </div>
  ) : null;
};
