import { isAfter, isEqual } from 'date-fns';
import { type FormikErrors } from 'formik';

import { PayAnyoneRecurringPaymentStrings, PayAnyoneTabs } from '@constants';
import { type PayAnyoneFormValues } from '@models';

import { type Validator } from '../types';

export const payLaterScheduledDate: Validator = (
  { payLaterScheduledDate },
  { paymentFrequencyType },
) => {
  const errors: FormikErrors<PayAnyoneFormValues> = {};

  if (paymentFrequencyType !== PayAnyoneTabs.PAY_LATER) {
    return errors;
  }

  if (!payLaterScheduledDate) {
    errors.payLaterScheduledDate = PayAnyoneRecurringPaymentStrings.PaymentDateEmptyError;
  } else if (!isAfter(payLaterScheduledDate, new Date())) {
    errors.payLaterScheduledDate = PayAnyoneRecurringPaymentStrings.PaymentPastDateError;
  } else if (isEqual(payLaterScheduledDate, new Date())) {
    errors.payLaterScheduledDate = PayAnyoneRecurringPaymentStrings.PaymentPastDateError;
  }

  return errors;
};
